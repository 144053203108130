import {atom, RecoilValueReadOnly, selector} from 'recoil'

import {standardTableAtomFamily} from '@d1g1t/shared/containers/standard-table/atoms'
import {IGenerateExcelSheetParams} from '@d1g1t/shared/wrappers/generate-excel'

import {TRADE_PREVIEW_TABLE_ID} from '@d1g1t/advisor/containers/trade-preview'

import {ACCOUNT_ALLOCATION_TABLE_ID} from './components/account-allocation'
import {TARGET_ALLOCATION_TABLE_ID} from './components/target-allocation'
import {filterAllocationTableByCheckedAccounts} from './lib'

/**
 * A selector to get the three tables necessary to generate
 * the Trade Directive Approval Report as an Excel file.
 */
export const tradeDirectiveApprovalReportSelector = selector({
  key: 'tradeDirectiveApprovalReportState',
  get: ({get}) => {
    const checkedAccountsIds = get(checkedAccountsIdsAtom)
    const allocationTable = get(
      standardTableAtomFamily(ACCOUNT_ALLOCATION_TABLE_ID)
    )

    // remove not-checked accounts from the generated report
    const filteredAllocationTable = filterAllocationTableByCheckedAccounts(
      allocationTable,
      checkedAccountsIds
    )

    return [
      {
        title: 'Target Allocation',
        data: get(standardTableAtomFamily(TARGET_ALLOCATION_TABLE_ID))
      },
      {
        title: 'Allocation per Account',
        data: filteredAllocationTable
      },
      {
        title: 'Recommended Trades',
        data: get(standardTableAtomFamily(TRADE_PREVIEW_TABLE_ID))
      }
    ]
  }
}) as RecoilValueReadOnly<IGenerateExcelSheetParams[]>

/**
 * Array containing the AccountIds checked to be part of the trade recommendations
 * atomized to be accessible by the tradeDirectiveApprovalReportSelector
 * when generating the report file content
 */
export const checkedAccountsIdsAtom = atom<string[]>({
  key: 'checkedAccountsIds',
  default: []
})
