import {createContext} from 'react'

import {
  IContributionDimention,
  IContributionDimentionOption
} from '@d1g1t/api/endpoints'
import {
  IViewMetricItem,
  VIEWMETRICITEM_TRANSACTION_AS_OF_DATE
} from '@d1g1t/api/models'

import {CONDITION_RESULTS_KEY} from '@d1g1t/lib/metrics'

import {ISearchResult} from '@d1g1t/shared/containers/search'

import {ICategory} from '../../typings'

interface IMetricsFormContextValue {
  metricItem: IViewMetricItem
  displayTotalWhenExpanded1: boolean
  displayTotalWhenExpanded2: boolean
  conditionResults1: boolean
  conditionResults2: boolean
  expandableColumn: boolean
  latestAvailableData: string
  relatedEntity: ISearchResult
  setRelatedEntity(searchResult: ISearchResult): void
  onLevelChange(level: number, value: ICategory): any
  onDisplayNameChange(value: string): any
  oncontributionDimensionChange(value: number): any
  onContributionDimension2Change(value: number): any
  onShowExpandableColumnChange(isInputChecked?: boolean): any
  onDisplayTotalWhenExpanded1Change(isInputChecked?: boolean): any
  onDisplayTotalWhenExpanded2Change(isInputChecked?: boolean): any
  onShowConditionResults1Change(isInputChecked?: boolean): any
  onShowConditionResults2Change(isInputChecked?: boolean): any
  onStartDateChange(date: string): void
  onEndDateChange(date: string): void
  onDateRangeChange(startDate: string, endDate: string): void
  onRelatedEntityChange(searchResult: ISearchResult): any
  onTransactionAsOfDateChange(
    transactionAsOfDate: VIEWMETRICITEM_TRANSACTION_AS_OF_DATE
  ): void
  createOnConditionOptionCheck(
    url: string,
    selectedUrls: string[],
    key: CONDITION_RESULTS_KEY
  ): (isInputChecked: boolean) => void
  contributionDimensions: IContributionDimention[]
  contributionDimensionOptions: {
    assetClassOptions: IContributionDimentionOption[]
    currencyOptions: IContributionDimentionOption[]
    strategyOptions: IContributionDimentionOption[]
  }
}

export const MetricsFormContext = createContext<IMetricsFormContextValue>(null)

export const MetricsFormContextProvider = MetricsFormContext.Provider
