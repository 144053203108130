import React, {useMemo, useState} from 'react'

import {ApiError} from 'fairlight'
import produce from 'immer'

import ErrorIcon from '@material-ui/icons/Error'

import {useInternalTheme} from '@d1g1t/config/theme/internal-theme'

import {CHART_VALUE_TYPE} from '@d1g1t/api/models'

import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'

import {Flex, FlexChild} from '@d1g1t/shared/components/flex'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Alert} from '@d1g1t/shared/components/mui/alert'
import {
  IconMenu,
  IIconMenuMenuItemProps
} from '@d1g1t/shared/components/mui/icon-menu'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H2, Text} from '@d1g1t/shared/components/typography'
import {StandardTable} from '@d1g1t/shared/containers/standard-table'

import {CountToggleFilter} from '@d1g1t/advisor/components/count-toggle-filter'

import {IEditedValueErrorsBySecurityAndAccount} from '../../lib'
import {getAccountAllocationFilterCounts} from './lib'
import {AccountAllocationFilterKey} from './typings'

interface IAccountAllocationProps {
  chart: Loadable<StandardResponse>
  warningsByAccountId: Dictionary<string[]>
  isChangedByAccountId: Dictionary<boolean>
  editedValueErrorsByAccountAndSecurityId: IEditedValueErrorsBySecurityAndAccount
  excludingPendingTrades: boolean
  excludePendingTrades()
  includePendingTrades()
  excludingCashEquivalents: boolean
  excludeCashEquivalents()
  includeCashEquivalents()
  onValueChange(payload: {
    accountId: string
    categoryId: string
    value: number | null
  }): void
  checkedAccountsIds: string[]
  onAccountChecked: (checkedAccountsIds: string[]) => void
}

export const ACCOUNT_ALLOCATION_TABLE_ID = 'account-allocation-table'

export const AccountAllocation: React.FC<IAccountAllocationProps> = React.memo(
  (props) => {
    const internalTheme = useInternalTheme()
    const [toggleFilterState, onToggleFilterChange] = useState<
      AccountAllocationFilterKey[]
    >([
      AccountAllocationFilterKey.unchanged,
      AccountAllocationFilterKey.changed,
      AccountAllocationFilterKey.issues
    ])

    const countsByFilterKey = useMemo(
      () =>
        getAccountAllocationFilterCounts(
          props.chart.data,
          props.isChangedByAccountId,
          props.warningsByAccountId
        ),
      [props.chart.data, props.isChangedByAccountId, props.warningsByAccountId]
    )

    const filteredChart = useMemo(() => {
      if (!props.chart.data) {
        return null
      }

      return produce(props.chart.data, (draftChart: StandardResponse) => {
        const enabledItems = new Set(toggleFilterState)

        draftChart.applyItemFilter((item, categories) => {
          return (
            (enabledItems.has(AccountAllocationFilterKey.unchanged) &&
              !props.isChangedByAccountId[item.id]) ||
            (enabledItems.has(AccountAllocationFilterKey.changed) &&
              props.isChangedByAccountId[item.id]) ||
            (enabledItems.has(AccountAllocationFilterKey.issues) &&
              props.warningsByAccountId[item.id]?.length > 0)
          )
        })

        for (const draftItem of draftChart) {
          if (draftItem.getItemContext()?.warnings.includes('not tradable')) {
            draftItem.setOptions({editable: false})
          }
        }
      })
    }, [
      props.chart.data,
      toggleFilterState,
      props.isChangedByAccountId,
      props.warningsByAccountId
    ])

    const colourCallout = (item: StandardResponseItem) => {
      const colours = []

      if (props.isChangedByAccountId[item.id]) {
        colours.push(internalTheme.accountAllocationChartColours.changed)
      }

      if (props.warningsByAccountId[item.id]?.length > 0) {
        colours.push(internalTheme.accountAllocationChartColours.issues)
      }

      return colours
    }

    const overflowMenuOptions: IIconMenuMenuItemProps[] = [
      {
        label: 'Include Pending Trades',
        onClick: () =>
          props.excludingPendingTrades
            ? props.includePendingTrades()
            : props.excludePendingTrades(),
        isChecked: !props.excludingPendingTrades
      },
      {
        label: 'Include Cash Equivalents',
        onClick: () =>
          props.excludingCashEquivalents
            ? props.includeCashEquivalents()
            : props.excludeCashEquivalents(),
        isChecked: !props.excludingCashEquivalents
      }
    ]

    return (
      <>
        <div>
          <Flex toolbar>
            <Flex alignCenter>
              <H2>Allocation per Account</H2>

              <Spacer vertical lg />
              <CountToggleFilter<AccountAllocationFilterKey>
                state={toggleFilterState}
                onChange={(values) => onToggleFilterChange(values)}
                countsByKey={countsByFilterKey}
                buttons={[
                  {
                    key: AccountAllocationFilterKey.unchanged,
                    label: 'Unchanged'
                  },
                  {
                    key: AccountAllocationFilterKey.changed,
                    label: 'Changed',
                    iconColor:
                      internalTheme.accountAllocationChartColours.changed
                  },
                  {
                    key: AccountAllocationFilterKey.issues,
                    label: 'Issues',
                    iconColor:
                      internalTheme.accountAllocationChartColours.issues
                  }
                ]}
              />
            </Flex>
            <Flex alignCenter>
              <IconMenu options={overflowMenuOptions} />
            </Flex>
          </Flex>
          <FlexChild>
            <LoadingContainer loading={props.chart.loading}>
              {props.chart.error && (
                <Alert severity='error'>
                  {(props.chart.error as ApiError).responseBody[0]}
                </Alert>
              )}
              {props.chart.data && (
                <StandardTable
                  id={ACCOUNT_ALLOCATION_TABLE_ID}
                  table={filteredChart}
                  maxHeight={800}
                  defaultColumnWidthTypes={{
                    [CHART_VALUE_TYPE.DECIMAL]: 144
                  }}
                  allowZeroForCell={() => true}
                  colourCallout={colourCallout}
                  noResultsText='No results. Try applying a different filter.'
                  nameAdornment={(item) => {
                    const warnings = props.warningsByAccountId[item.id]

                    if (warnings?.length > 0) {
                      return (
                        <Tooltip
                          interactive
                          placement='right'
                          title={
                            <>
                              {warnings.map((warning, idx) => (
                                <Text key={idx}>{warning}</Text>
                              ))}
                            </>
                          }
                        >
                          <ErrorIcon />
                        </Tooltip>
                      )
                    }
                    return null
                  }}
                  categoryIsHiddenWhenExpanded
                  onCellValueChange={(item, category, value) => {
                    props.onValueChange({
                      value,
                      categoryId: category.id,
                      accountId: item.id
                    })
                  }}
                  getCellError={({item, category}) => {
                    const [securityId, editableFieldName] =
                      category.id.split(':')
                    const errorByAccountAndSecurityId =
                      props.editedValueErrorsByAccountAndSecurityId[item.id]?.[
                        securityId
                      ]

                    if (
                      editableFieldName &&
                      errorByAccountAndSecurityId?.editableFieldName ===
                        editableFieldName
                    ) {
                      return errorByAccountAndSecurityId.errorMessage
                    }
                  }}
                  checkedRows={props.checkedAccountsIds}
                  onCheckboxedRows={props.onAccountChecked}
                />
              )}
            </LoadingContainer>
          </FlexChild>
        </div>
      </>
    )
  }
)
