let scrollbarWidth: number

export function getScrollbarWidth(): number {
  if (scrollbarWidth) {
    return scrollbarWidth
  }

  const scrollDiv = document.createElement('div')
  scrollDiv.style.width = '100px'
  scrollDiv.style.height = '100px'
  scrollDiv.style.overflow = 'scroll'
  scrollDiv.style.position = 'absolute'
  scrollDiv.style.top = '-9999px'

  document.body.appendChild(scrollDiv)
  scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)

  return scrollbarWidth
}

export const getElementDimensions = (
  element: HTMLElement
): {width: number; height: number} => {
  if (process.env.NODE_ENV === 'test') {
    return {
      width: 800,
      height: 450
    }
  }

  const computedStyle = window.getComputedStyle(element)

  if (computedStyle.boxSizing === 'border-box') {
    return {
      width:
        element.clientWidth -
        parseInt(computedStyle.paddingLeft, 10) -
        parseInt(computedStyle.paddingRight, 10),
      height:
        element.clientHeight -
        parseInt(computedStyle.paddingTop, 10) -
        parseInt(computedStyle.paddingBottom, 10)
    }
  }

  return {
    width: element.clientWidth,
    height: element.clientHeight
  }
}

export const getChildren = (n: Node, skipMe: Element): Element[] => {
  const children = []
  for (; n; n = n.nextSibling as Element) {
    if (n.nodeType === 1 && n !== skipMe) {
      children.push(n)
    }
  }

  return children
}

export const getSiblings = (n: Element): Element[] =>
  getChildren(n.parentNode.firstChild, n)

export const scrollToTop = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
}
