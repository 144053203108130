import {differenceBy} from 'lodash'

import {IChartTableCategory} from '@d1g1t/api/models'

export function initializeExpandedCategoryIds(
  initializeColumnsFullyExpanded:
    | boolean
    | ((category: IChartTableCategory) => boolean),
  expansionCategories: IChartTableCategory[],
  prevExpansionCategories?: IChartTableCategory[],
  prevExpandedCategoryIds?: string[]
): string[] {
  const removedCategories = differenceBy(
    prevExpansionCategories || [],
    expansionCategories,
    'id'
  )

  const removedCategoryIdsSet = new Set(removedCategories.map(({id}) => id))

  const nextExpandedCategoryIds = (prevExpandedCategoryIds || []).filter(
    (categoryId) => !removedCategoryIdsSet.has(categoryId)
  )

  const newCategories = differenceBy(
    expansionCategories,
    prevExpansionCategories || [],
    'id'
  )

  return nextExpandedCategoryIds.concat(
    initializeColumnsFullyExpanded
      ? (typeof initializeColumnsFullyExpanded === 'boolean'
          ? newCategories
          : newCategories.filter(initializeColumnsFullyExpanded)
        ).map((category) => category.id)
      : []
  )
}
