import '@d1g1t/shared/bootstrap'

import React from 'react'
import {DndProvider} from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import {HelmetProvider} from 'react-helmet-async'
import {Provider} from 'react-redux'
import {Router} from 'react-router-dom'

// import * as OfflinePluginRuntime from '@lcdp/offline-plugin/runtime'
import {ApiProvider} from 'fairlight'
import {RecoilRoot} from 'recoil'
import {SagaProvider} from 'use-saga-reducer'

import {MuiThemeProvider} from '@material-ui/core/styles'

import {config} from '@d1g1t/config/config'
import {ConfigContext, IConfigContext} from '@d1g1t/config/context'
import {muiLightThemeAdvisor} from '@d1g1t/config/theme/mui-theme'

import {history} from '@d1g1t/lib/history'
import {createSagaMiddleware} from '@d1g1t/lib/sagas'

import {initializeAuth} from '@d1g1t/shared/api'
import {ConfirmationModal} from '@d1g1t/shared/components/confirmation'
import {SnackbarProvider} from '@d1g1t/shared/containers/snackbar'
import {UserActivityTracker} from '@d1g1t/shared/containers/user-activity'
import {resolveRequiredPolyfills} from '@d1g1t/shared/polyfill'
import {DatadogCustomPropertyContextProvider} from '@d1g1t/shared/wrappers/datadog/context'
import {UrlQueryParamsProvider} from '@d1g1t/shared/wrappers/url-query-params'

import {NavigationSectionProvider} from '@d1g1t/advisor/wrappers/navigation-section'

import {api} from './api'
import {BaseRoutes} from './base-routes'
import {entityLocations, modelPath} from './locations'
import {createStore} from './store'

import '@d1g1t/shared/assets/source-sans-font/sourcesanspro.css'
import './reset.scss'

if (__DEVELOPMENT__) {
  // Used for easy access to Api in browser console
  window.jsonDiff = require('json-diff')
}

if (__PRODUCTION__) {
  // OfflinePluginRuntime.install()
}

if (console.table) {
  console.table(config)
}

const appContainerEl = document.createElement('div')

appContainerEl.style.height = '100%'
document.body.appendChild(appContainerEl)

const advisorConfigContextValue: IConfigContext = {
  enableAzureRedirect: true,
  entityPath: entityLocations.default,
  enablePrimedDates: true,
  isAdvisorApp: true,
  modelPath,
  pageTitleBarHelmetPrefix: 'd1g1t: ',
  useReportingCurrencyForDefaultCalculationSettings: false
}

const sagaContext = {
  api
}

const store = createStore(sagaContext)
const reduxMiddleware = createSagaMiddleware(store)

initializeAuth(api, store)

const App: React.SFC = () => (
  <>
    <RecoilRoot>
      <Provider store={store}>
        <UserActivityTracker />
        <ApiProvider
          api={api}
          defaults={{
            useApiQuery: {
              fetchPolicy: 'no-cache',
              useErrorBoundary: true
            }
          }}
        >
          <SagaProvider
            context={sagaContext}
            effectMiddlewares={[reduxMiddleware]}
          >
            <MuiThemeProvider theme={muiLightThemeAdvisor}>
              <DndProvider backend={HTML5Backend}>
                <HelmetProvider>
                  <Router history={history}>
                    <SnackbarProvider>
                      <NavigationSectionProvider>
                        <UrlQueryParamsProvider>
                          <ConfigContext.Provider
                            value={advisorConfigContextValue}
                          >
                            <DatadogCustomPropertyContextProvider>
                              <BaseRoutes />
                              <ConfirmationModal />
                            </DatadogCustomPropertyContextProvider>
                          </ConfigContext.Provider>
                        </UrlQueryParamsProvider>
                      </NavigationSectionProvider>
                    </SnackbarProvider>
                  </Router>
                </HelmetProvider>
              </DndProvider>
            </MuiThemeProvider>
          </SagaProvider>
        </ApiProvider>
      </Provider>
    </RecoilRoot>
  </>
)

App.displayName = 'App'

const render = () => {
  if (__DEVELOPMENT__) {
    ReactDOM.render(
      <React.Profiler id='Application' onRender={NOOP}>
        <App />
      </React.Profiler>,
      appContainerEl
    )
  } else {
    ReactDOM.render(<App />, appContainerEl)
  }
}

resolveRequiredPolyfills().then(() => {
  render()
})
