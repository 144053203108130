import React from 'react'

import FilterIcon from '@material-ui/icons/FilterList'

import {classNames} from '@d1g1t/lib/class-names'

import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'

import * as css from './style.scss'

interface IPopoutFilterToggleProps {
  /**
   * Height of the table's header cells. This is used to position the toggle filter.
   */
  tableHeaderHeight: number
  /**
   * If true, the column filter should be visible.
   */
  columnFilterVisible: boolean
  /**
   * If true, column filters are enabled.
   */
  columnFilterActive?: boolean
  /**
   * Toggles column filters between active and inactive.
   */
  toggleColumnFilterActive(): void
  /**
   * Called when the toggle filter button is hovered over
   */
  onMouseEnter(): void
  /**
   * Called whe nthe toggle filter button is hovered off of
   */
  onMouseLeave(): void
}

/**
 * On click, will toggle column filters to be shown or hidden for the table.
 */
export const PopoutColumnFilterToggle: React.FC<IPopoutFilterToggleProps> = (
  props
) => {
  return (
    <div
      className={css.toggleFilterContainer}
      style={{height: props.tableHeaderHeight}}
    >
      <div
        className={classNames(css.toggleFilter, {
          [css.toggleFilterVisible]: props.columnFilterVisible
        })}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        <Tooltip title='Show/Hide Column Filters' placement='top-start'>
          <IconButton
            small
            className={classNames({
              [css.toggleFilterActive]: props.columnFilterActive
            })}
            onClick={props.toggleColumnFilterActive}
            data-testid='select-column-filter-popout-row'
          >
            <FilterIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}
