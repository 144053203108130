import React from 'react'

export const AscendingIcon = (props: {className?: string}) => (
  <svg
    width='12'
    height='15'
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={props.className}
  >
    <path
      d='M4.14634 4.39025V3.41464H8.04878V4.39025H4.14634ZM4.14634 1.46342V0.487807H6.09756V1.46342H4.14634ZM4.14634 7.31708V6.34147H10V7.31708H4.14634ZM2.19512 6.34147H3.41463L1.70732 8.04878L0 6.34147H1.21951V1.66893e-06H2.19512V6.34147Z'
      fill='black'
    />
  </svg>
)
