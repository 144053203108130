import {isValid, parse} from 'date-fns'
import {isNil} from 'lodash'
import * as Yup from 'yup'

import {Formats, Types} from '@d1g1t/api/endpoints'
import {IFilterValueItem} from '@d1g1t/typings/general'

import {nodeIdFromFilterItem} from '@d1g1t/shared/containers/filter-editor/lib'
import {IFilterCriteria} from '@d1g1t/shared/wrappers/filter-criteria/typings'

import {ISO_DATE_FORMAT} from './constants'
import {testDateString} from './yup-validators'

export const filterItemSchema =
  (filterCriteria: IFilterCriteria) => (filterValueItem: IFilterValueItem) => {
    const {paths} = filterCriteria
    const {type, format} = paths[nodeIdFromFilterItem(filterValueItem)]

    const generateSchema = (schema: Yup.Schema<any>) =>
      Yup.object({
        value: [Types.oneOf, Types.in].includes(type)
          ? Yup.array().of(schema).min(1)
          : schema
      })

    if (type === Types.isEmpty || type === Types.isNotEmpty) {
      return generateSchema(Yup.mixed())
    }

    switch (format) {
      case Formats.string:
        return generateSchema(Yup.string().required('Required field'))
      case Formats.decimal:
      case Formats.percentage:
      case Formats.int: {
        if (type === Types.between) {
          const [start, end] = filterValueItem.value as number[]

          const numberErrorMessage = (number: number, prefix: string) => {
            if (isNil(number)) {
              return `${prefix} is a required field`
            }

            if (prefix === 'Start' && start >= end) {
              return 'Start must be less than end'
            }

            return ''
          }

          const message = `${numberErrorMessage(
            start,
            'Start'
          )}|${numberErrorMessage(end, 'End')}`

          return generateSchema(
            Yup.mixed().test({
              test: () => message === '|',
              message
            })
          )
        }

        return generateSchema(
          Yup.number().required('Required field').nullable()
        )
      }
      case Formats.enum:
        return generateSchema(
          Yup.mixed().test('required', 'Required Field', (value) => {
            if (Array.isArray(value)) {
              return value.length !== 0
            }

            return !!value
          })
        )
      case Formats.date: {
        if (filterValueItem.value && type === Types.dateBetween) {
          const [start, end] = filterValueItem.value as string[]

          const dateErrorMessage = (date: string, prefix: string) => {
            if (!date) {
              return `${prefix} is a required field`
            }

            if (!isValid(parse(date, ISO_DATE_FORMAT, new Date()))) {
              return `${prefix} is not a valid date`
            }

            if (prefix === 'Start date' && new Date(start) >= new Date(end)) {
              return 'Start date must come before end date'
            }

            return ''
          }

          const message = `${dateErrorMessage(
            start,
            'Start date'
          )}|${dateErrorMessage(end, 'End date')}`

          return generateSchema(
            Yup.mixed().test({
              test: () => message === '|',
              message
            })
          )
        }

        return generateSchema(
          Yup.string()
            .test(...testDateString())
            .required('Required field')
            .label('Date')
        )
      }
      default:
        return generateSchema(Yup.mixed().required('Required field'))
    }
  }
