import React from 'react'

import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon'

export const MinimizeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='945 649 71 71' {...props}>
    <path
      d='M 990.1543 717.2817 L 994.1424 717.2817 L 994.1566 701.485 L 1011.3675 718.696 L 1014.196 715.8675 L 996.985 698.6566 L 1012.7818 698.6424 L 1012.7818 694.6543 L 990.1543 694.6543 Z'
      fill='#616161'
    />
    <path
      d='M 990.1543 717.2817 L 994.1424 717.2817 L 994.1566 701.485 L 1011.3675 718.696 L 1014.196 715.8675 L 996.985 698.6566 L 1012.7818 698.6424 L 1012.7818 694.6543 L 990.1543 694.6543 Z'
      stroke='#616161'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
    <path
      d='M 947.7279 694.6543 L 947.7279 698.6424 L 963.5246 698.6566 L 946.3137 715.8675 L 949.1421 718.696 L 966.3531 701.485 L 966.3672 717.2817 L 970.3553 717.2817 L 970.3553 694.6543 Z'
      fill='#616161'
    />
    <path
      d='M 947.7279 694.6543 L 947.7279 698.6424 L 963.5246 698.6566 L 946.3137 715.8675 L 949.1421 718.696 L 966.3531 701.485 L 966.3672 717.2817 L 970.3553 717.2817 L 970.3553 694.6543 Z'
      stroke='#616161'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
    <path
      d='M 970.3553 652.22794 L 966.3672 652.22794 L 966.3531 668.0247 L 949.1421 650.8137 L 946.3137 653.64214 L 963.5246 670.85314 L 947.7279 670.86724 L 947.7279 674.8553 L 970.3553 674.8553 Z'
      fill='#616161'
    />
    <path
      d='M 970.3553 652.22794 L 966.3672 652.22794 L 966.3531 668.0247 L 949.1421 650.8137 L 946.3137 653.64214 L 963.5246 670.85314 L 947.7279 670.86724 L 947.7279 674.8553 L 970.3553 674.8553 Z'
      stroke='#616161'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
    <path
      d='M 1012.7818 674.8553 L 1012.7818 670.86724 L 996.985 670.85314 L 1014.196 653.64214 L 1011.3675 650.8137 L 994.1566 668.0247 L 994.1424 652.22794 L 990.1543 652.22794 L 990.1543 674.8553 Z'
      fill='#616161'
    />
    <path
      d='M 1012.7818 674.8553 L 1012.7818 670.86724 L 996.985 670.85314 L 1014.196 653.64214 L 1011.3675 650.8137 L 994.1566 668.0247 L 994.1424 652.22794 L 990.1543 652.22794 L 990.1543 674.8553 Z'
      stroke='#616161'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
  </SvgIcon>
)
