import React from 'react'

import {useFormikContext} from 'formik'

import {FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE} from '@d1g1t/api/models'

import {Alert} from '@d1g1t/shared/components/mui/alert'
import {P} from '@d1g1t/shared/components/typography'

import {TradeStats} from '@d1g1t/advisor/components/trade-stats'

import {useSyncSelectEntitiesFormAccountUrl} from '../hook'
import {TradeOperationPanel} from '../trade-operation-panel'
import {MutualFundsForm} from './form'
import {IMutualFundFormValues, MUTUAL_FUND_FIELD_NAMES} from './typings'

export interface IMutualFundsProps {
  /**
   * Returns `true` for the tradable account
   */
  isTradable?: boolean
}

export const MutualFunds: React.FC<IMutualFundsProps> = ({isTradable}) => {
  useSyncSelectEntitiesFormAccountUrl(MUTUAL_FUND_FIELD_NAMES.accountUrl)

  const {values} = useFormikContext<IMutualFundFormValues>()

  return (
    <>
      {!values.accountUrl && (
        <Alert severity='warning'>
          <P>Please select an account to create trade orders.</P>
        </Alert>
      )}
      <TradeOperationPanel
        data-testid='mutual-funds-trading'
        operation={values.operation}
      >
        <MutualFundsForm isTradable={isTradable} />
        <TradeStats
          tradeType={FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND}
          headerTitle='Fund Name'
          operation={values.operation}
          instrument={values.instrumentUrl}
          instrumentName={values.instrumentName}
          account={values.accountUrl}
          quantityChange={values.qty}
          qtyType={values.qtyType}
          commission={values.commission}
          commissionType={values.commissionType}
          limitPrice={undefined}
          type={undefined}
          isSellAll={values.isSellAll}
          expectedPrice={values.expectedPrice}
        />
      </TradeOperationPanel>
    </>
  )
}
