import React, {useLayoutEffect} from 'react'

import {chunk, range} from 'lodash'

import * as css from './style.scss'

interface IPrintableTableProps {
  columnCount: number
  rowCount: number
  header: any
  footer: any
  renderCell: any
  didMount(htmlEl: HTMLElement): void
}

export const PrintableTable: React.FC<IPrintableTableProps> = (props) => {
  const tableRef = React.createRef<HTMLDivElement>()

  useLayoutEffect(() => {
    const imgSources = new Set<string>()
    const imgElements = tableRef.current.querySelectorAll('img')

    for (const imgEl of Array.from(imgElements)) {
      imgSources.add(imgEl.src)
    }

    let remainingImgs = imgSources.size

    if (imgSources.size === 0) {
      props.didMount(tableRef.current)

      return
    }

    // Preload images to ensure they are ready when the PDF is generated
    for (const imgSrc of imgSources) {
      const imgPreload = new Image()
      // eslint-disable-next-line no-loop-func
      imgPreload.onload = () => {
        remainingImgs--

        if (remainingImgs === 0) {
          props.didMount(tableRef.current)
        }
      }
      imgPreload.src = imgSrc
    }
  }, [])

  const renderTable = (range: number[], index: number) => {
    return (
      <table
        key={index}
        style={{pageBreakAfter: 'always', width: '100%', padding: 15}}
        cellPadding={0}
        cellSpacing={0}
      >
        <thead style={{display: 'table-header-group'}}>
          <tr>
            <th colSpan={props.columnCount} align='left'>
              {props.header}
            </th>
          </tr>
          <tr style={{verticalAlign: 'bottom'}}>
            {[...Array(props.columnCount)].map((_, index) => (
              <th key={index}>
                {props.renderCell({
                  key: index,
                  rowIndex: 0,
                  columnIndex: index,
                  style: {}
                })}
              </th>
            ))}
          </tr>
        </thead>
        <tfoot style={{display: 'table-footer-group'}}>
          <tr>
            <td colSpan={props.columnCount}>{props.footer}</td>
          </tr>
        </tfoot>
        <tbody>
          {range.map((rowIndex) => (
            <tr
              className={css.cellHeight}
              style={{verticalAlign: 'bottom'}}
              key={rowIndex}
            >
              {[...Array(props.columnCount)].map((_, index) => (
                <td key={index}>
                  {props.renderCell({
                    key: index,
                    rowIndex: rowIndex + 1,
                    columnIndex: index,
                    style: {}
                  })}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const indexes = range(0, props.rowCount - 1)
  const ranges = chunk(indexes, 15)

  return <div ref={tableRef}>{ranges.map(renderTable)}</div>
}
