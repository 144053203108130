import {COMPONENT_SPECIFIC_TRANSLATION_KEYS} from '@d1g1t/investor/i18n/constants'
import * as FrenchTranslations from '@d1g1t/investor/i18n/translations/fr'

/**
 * Returns left and right titles for 'trend_gain_n_loss' chart based on the active lines
 * @param activeLegends - array of active chart lines
 */
export const getAxisTitles = (
  activeLegends: Array<string>
): [string, string] => {
  let leftLabel = ''
  let rightLabel = ''

  if (activeLegends.includes(COMPONENT_SPECIFIC_TRANSLATION_KEYS.TOTAL_GAIN)) {
    leftLabel = COMPONENT_SPECIFIC_TRANSLATION_KEYS.TOTAL_GAIN

    if (activeLegends.length > 1) {
      rightLabel = activeLegends[1]
    }
  } else {
    rightLabel = activeLegends[0]
  }

  if (
    activeLegends.length > 2 ||
    activeLegends.includes(
      FrenchTranslations.default[
        COMPONENT_SPECIFIC_TRANSLATION_KEYS.BEGINNING_VALUE_AND_NET_ADDITIONS
      ]
    ) ||
    activeLegends.includes(
      COMPONENT_SPECIFIC_TRANSLATION_KEYS.BEGINNING_VALUE_AND_NET_ADDITIONS
    ) ||
    (!activeLegends.includes(COMPONENT_SPECIFIC_TRANSLATION_KEYS.TOTAL_GAIN) &&
      activeLegends.length > 1)
  ) {
    rightLabel = COMPONENT_SPECIFIC_TRANSLATION_KEYS.VALUE_AND_ADDITIONS
  }

  return [leftLabel, rightLabel]
}
