import React, {forwardRef} from 'react'

import MuiSnackbarContent, {
  SnackbarContentProps
} from '@material-ui/core/SnackbarContent'

export interface ISnackbarContentProps extends SnackbarContentProps {}

export const SnackbarContent: React.FC<ISnackbarContentProps> = forwardRef(
  (props, ref) => {
    return <MuiSnackbarContent ref={ref} {...props} />
  }
)
