import React from 'react'

import AddIcon from '@material-ui/icons/AddCircleOutline'
import EyeIcon from '@material-ui/icons/RemoveRedEye'

import {Flex} from '@d1g1t/shared/components/flex'
import {MaximizeIconButton} from '@d1g1t/shared/components/maximize-icon-button'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {IconMenu} from '@d1g1t/shared/components/mui/icon-menu'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {StateBlock} from '@d1g1t/shared/components/state-block'
import {H2} from '@d1g1t/shared/components/typography'
import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'
import {ViewOptions} from '@d1g1t/shared/containers/view-options'

import {TableSearchControl} from './components/table-search-control'
import {IWidgetTitleBarProps} from './typings'

import * as css from './style.scss'

export * from './typings'

/**
 * A title bar component meant to standardize rendering of controls at the top
 * of all of the widgets (starting with the CPHTable).
 */
export const WidgetTitleBar: React.FC<IWidgetTitleBarProps> = (props) => {
  const heading = (() => {
    if ('ViewOptionsProps' in props.heading) {
      return (
        <>
          {props.heading.prefixText && (
            <>
              <H2>{props.heading.prefixText}</H2>
              <Spacer vertical xxs />
            </>
          )}
          <ViewOptions headerStyleSelect {...props.heading.ViewOptionsProps} />
          {props.heading.StateBlockProps && (
            <StateBlock {...props.heading.StateBlockProps} />
          )}
        </>
      )
    }
    if ('ValueLabelSelectProps' in props.heading) {
      return (
        <>
          {props.heading.prefixText && <H2>{props.heading.prefixText}</H2>}
          <ValueLabelSelect
            noBorder
            whiteBackground
            data-testid='widget-toolbar-header-select'
            {...props.heading.ValueLabelSelectProps}
            size='small'
            greyBackground={false}
            className={css.headerStyle}
          />
          {props.heading.StateBlockProps && (
            <StateBlock {...props.heading.StateBlockProps} />
          )}
        </>
      )
    }
    return (
      <>
        {props.heading.icon}
        <H2>{props.heading.title}</H2>
        {props.heading.StateBlockProps && (
          <StateBlock {...props.heading.StateBlockProps} />
        )}
      </>
    )
  })()

  const renderAddButton = () => {
    const button = (
      <IconButton
        onClick={props.addButton.onClick}
        data-testid={
          props.addButton.data_testid || 'widget-title-bar-add-button'
        }
      >
        <AddIcon />
      </IconButton>
    )

    if (props.addButton.tooltip) {
      return <Tooltip title={props.addButton.tooltip}>{button}</Tooltip>
    }

    return button
  }

  return (
    <Flex
      toolbar={!props.noToolbarFixedHeight}
      alignCenter={props.noToolbarFixedHeight}
      justifySpaceBetween={props.noToolbarFixedHeight}
      data-testid={
        props.testIdName
          ? `widget-${props.testIdName}-toolbar`
          : 'widget-toolbar'
      }
    >
      <Flex alignCenter grow>
        {heading}
        {props.leftAlignedSecondaryControls}
      </Flex>
      <Flex alignCenter>
        {props.SearchProps && <TableSearchControl {...props.SearchProps} />}
        {props.secondaryControls}
      </Flex>
      <Flex alignCenter>
        {props.showLookThroughIcon && (
          <Tooltip title='Look-through enabled'>
            <IconButton disableFocusRipple disableTouchRipple disableRipple>
              <EyeIcon />
            </IconButton>
          </Tooltip>
        )}
        <Spacer vertical xxs />
        {props.addButton && renderAddButton()}
        {props.MaximizeButtonProps && (
          <MaximizeIconButton {...props.MaximizeButtonProps} />
        )}
        {props.overflowMenuOptions && (
          <IconMenu options={props.overflowMenuOptions} />
        )}
      </Flex>
    </Flex>
  )
}
