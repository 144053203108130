import React from 'react'

import {isFunction} from 'lodash'

import {JoinOperator} from '@d1g1t/typings/general'

import {Button} from '@d1g1t/shared/components/mui/button'

import * as css from './style.scss'

interface IConditionProps {
  joinOperator: JoinOperator
  onChange?(value: JoinOperator)
}

export const Condition: React.FC<IConditionProps> = (props) => {
  const handleSelectAnd = () => {
    props.onChange('AND')
  }

  const handleSelectOr = () => {
    props.onChange('OR')
  }

  const displayOnly = !isFunction(props.onChange)

  return (
    <div className={css.wrapper}>
      <div className={css.condition}>
        <div className={css.buttons}>
          {(!displayOnly || props.joinOperator === 'AND') && (
            <Button // eslint-disable-line react/button-has-type
              data-and
              contained
              primary={props.joinOperator === 'AND'}
              spaceRight={!displayOnly}
              disabled={displayOnly}
              onClick={!displayOnly ? handleSelectAnd : undefined}
            >
              AND
            </Button>
          )}
          {(!displayOnly || props.joinOperator === 'OR') && (
            <Button // eslint-disable-line react/button-has-type
              data-or
              contained
              primary={props.joinOperator === 'OR'}
              disabled={displayOnly}
              onClick={!displayOnly ? handleSelectOr : undefined}
            >
              OR
            </Button>
          )}
        </div>
      </div>
      <hr />
    </div>
  )
}
