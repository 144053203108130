import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './style.scss'

interface IStatusBoxProps {
  /**
   *  Sets the border to green colour when `true`, red otherwise
   */
  active: boolean
}

/**
 *  Wraps the children in a coloured border box with shadow and
 * accepts the prop `active` to indicate whether it is green or red.
 * Example in account group single page indentification section.
 */
export const StatusBox: React.FC<IStatusBoxProps> = function StatusBox({
  active,
  children
}) {
  return (
    <div
      className={classNames(css.accountStatusBox, {
        [css.active]: active,
        [css.closed]: !active
      })}
    >
      {children}
    </div>
  )
}
