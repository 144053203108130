import React, {useContext} from 'react'
import {Helmet} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {LinkProps} from 'react-router-dom'

import {format} from 'date-fns'
import * as locales from 'date-fns/locale'

import BackArrowIcon from '@material-ui/icons/ArrowBack'

import {ConfigContext} from '@d1g1t/config/context'

import {classNames} from '@d1g1t/lib/class-names'
import {parseDate} from '@d1g1t/lib/formatters/date-formatter'

import {Flex} from '@d1g1t/shared/components/flex'
import {ButtonLink} from '@d1g1t/shared/components/router-link'
import {H1, H2, Text} from '@d1g1t/shared/components/typography'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'

import {
  INavigationTabsProps,
  NavigationTabs
} from '@d1g1t/advisor/components/navigation-tabs'

import {BackToListButton} from './components/back-to-list-navigation'
import {
  FocusNavigationControlBox,
  IFocusNavigationControlBoxProps
} from './components/focus-navigation'
import {
  ISettingsProps,
  PageCalculationSettings
} from './components/page-calculation-settings'
import {PageNavigation} from './components/page-navigation'

import * as css from './style.scss'

export interface IPageTitleBarProps {
  /**
   * Title of the page.
   */
  title: string
  /**
   * Applies styling that is used in investor portal
   */
  investor?: boolean
  /**
   * Displays the title using <H2> tag instead of <H1> tag
   */
  h2Title?: boolean
  /**
   * Shows a back button to left of title, redirects to the provided link on click.
   */
  backLink?: LinkProps['to']
  /**
   * Show the calculation settings related UI elements (as of date + currency selectors).
   */
  showSettings?: boolean
  /**
   * Offers a back-to-list navigation button in the top-right corner.
   */
  showBackToListNavigationButton?: boolean
  /**
   * On hover over the page title, offers the ability to move between specific SelectEntities based pages.
   * @remarks WARNING: Should not be used on pages that do not use the `SelectEntities` container.
   */
  showSelectEntitiesPageNavigation?: boolean
  /**
   *  Shows Latest available date on right
   */
  asOfDateText?: string
  /**
   * Sets the PageTitleBar as sticky.
   */
  stickyTopBar?: boolean
  /**
   * Disables calculation settings selector for currency.
   * Passed down to the Settings component.
   */
  disableCurrency?: boolean
  /**
   * Hides calculation settings selector for currency.
   * Passed down to the Settings component.
   */
  hideCurrency?: boolean
  /**
   * Disables calculation settings selector for as-of-date.
   * Passed down to the Settings component.
   */
  disableDate?: boolean
  /**
   * Removes the bottom margin from underneath PageTitleBar component.
   */
  noBottomMargin?: boolean

  /**
   * Tooltip title for the toggle button.
   */
  toggleButtonHintText?: string
  /**
   * Used to toggle visibility of the SelectEntities left drawer.
   */
  leftDrawerToggle?(): void
  /**
   * When provided, renders a middle hovering control box which allows you to navigate between selected entities.
   * Essentially changes the `focused` value in URL Query Params. SelectEntities should respond to that change.
   */
  FocusNavigationControlBoxProps?: IFocusNavigationControlBoxProps
  /**
   *  Pass-through props for `PageCalculationSettings` component.
   */
  PageCalculationSettingsOptionProps?: ISettingsProps
  /**
   * When provided, renders grey navigation tabs near top right of page.
   */
  NavigationTabsProps?: INavigationTabsProps
}

/**
 * Provides a title bar to show the page title.
 *
 * Optionally offers sticky title bar, page navigation (for moving between SelectEntities pages),
 * back-to-list navigation, and calculation settings UI elements.
 */
export const PageTitleBar: React.FC<IPageTitleBarProps> = (props) => {
  const config = useContext(ConfigContext)
  const {t, i18n} = useTranslation()

  return (
    <div
      className={classNames(css.filtersToolbar, {
        [css.noBottomMargin]: props.noBottomMargin,
        [css.stickyTopBar]: props.stickyTopBar
      })}
      data-testid='container-settings-toolbar'
    >
      <Flex
        grow
        toolbarWithSettings={!props.investor}
        alignCenter={!!props.investor}
        className={css.pageTitleBar}
      >
        <Flex shrink>
          {props.backLink && (
            <ButtonLink
              style={{width: 34, marginRight: 32}}
              noMinWidth
              noSVGSpacing
              contained
              primary
              to={props.backLink}
            >
              <BackArrowIcon />
            </ButtonLink>
          )}
        </Flex>

        <Flex grow>
          <Flex grow>
            <ErrorBoundary resetId={props.title}>
              <Helmet
                title={`${config.pageTitleBarHelmetPrefix || ''}${props.title}`}
              />
              {
                // eslint-disable-next-line no-nested-ternary
                props.showSelectEntitiesPageNavigation ? (
                  <PageNavigation
                    data-testid='page-navigation-title'
                    title={props.title}
                  />
                ) : props.h2Title ? (
                  <H2 data-testid='page-h2-title'>{props.title}</H2>
                ) : (
                  <H1 data-testid='page-title' noMargin={!!props.investor}>
                    {props.title}
                  </H1>
                )
              }
            </ErrorBoundary>
          </Flex>
          {props.FocusNavigationControlBoxProps && (
            <>
              <Flex alignCenter justifyCenter>
                <FocusNavigationControlBox
                  {...props.FocusNavigationControlBoxProps}
                />
              </Flex>
              <Flex grow />
            </>
          )}
        </Flex>
        <Flex shrink>
          {props.showBackToListNavigationButton && <BackToListButton />}
          {props.showSettings && (
            <ErrorBoundary resetId={props.title}>
              <PageCalculationSettings
                {...props.PageCalculationSettingsOptionProps}
              />
            </ErrorBoundary>
          )}
          {props.asOfDateText && (
            <Text>
              {t('As Of')}{' '}
              {format(parseDate(props.asOfDateText), 'MMMM dd, yyyy', {
                locale: locales[i18n.language ?? 'en']
              })}
            </Text>
          )}
          <div>
            {props.NavigationTabsProps && (
              <NavigationTabs {...props.NavigationTabsProps} />
            )}
            {props.children}
          </div>
        </Flex>
      </Flex>
    </div>
  )
}
