import React from 'react'

import {UI_PERMISSIONS} from '@d1g1t/api/models'

import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'
import {withPermissions} from '@d1g1t/shared/wrappers/permissions'

import SecuritiesList from '@d1g1t/advisor/containers/securities-list'

const SecuritiesPageContent: React.FC = () => {
  return (
    <ErrorBoundary resetId='no-reset'>
      <SecuritiesList />
    </ErrorBoundary>
  )
}

export const SecuritiesPage = withPermissions(UI_PERMISSIONS.SECURITIES)(
  SecuritiesPageContent
)
