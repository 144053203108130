import React from 'react'

import {
  TRADEORDER_OPERATION,
  TRADEORDER_OPERATION_OPTIONS
} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {StandardResponseItem} from '@d1g1t/lib/standard-response'

import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'

import {displaySellAllForItem} from '@d1g1t/advisor/pages/trade/manage-orders/lib'

import * as css from './styles.scss'

interface IOperationCellProps extends IDataCellProps {}

const getColorClass = (operation: TRADEORDER_OPERATION) => {
  switch (operation) {
    case TRADEORDER_OPERATION.SELL:
    case TRADEORDER_OPERATION.SELL_ALL:
    case TRADEORDER_OPERATION.SELL_SHORT:
      return css.sell
    case TRADEORDER_OPERATION.BUY:
      return css.buy
    case TRADEORDER_OPERATION.SWITCH:
    case TRADEORDER_OPERATION.SWITCH_ALL:
      return css.switchBackground
    default:
      break
  }
}

export const OperationCell: React.SFC<IOperationCellProps> = (
  props: IOperationCellProps
) => {
  const standardItem = new StandardResponseItem(props.item)

  const operation: TRADEORDER_OPERATION = (() => {
    const isSellAll = displaySellAllForItem(props.item)
    const value =
      standardItem.getKey(props.category.id) ??
      standardItem.getValue(props.category.id)
    if (isSellAll) {
      if (value === TRADEORDER_OPERATION.SELL) {
        return TRADEORDER_OPERATION.SELL_ALL
      }
      if (value === TRADEORDER_OPERATION.SWITCH) {
        return TRADEORDER_OPERATION.SWITCH_ALL
      }
    }
    return value
  })()

  return (
    <div className={classNames(getColorClass(operation), css.cell)}>
      {
        TRADEORDER_OPERATION_OPTIONS.find(
          (option) => option.value === operation
        )?.label
      }
    </div>
  )
}
