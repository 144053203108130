import {ITradeOrder} from '@d1g1t/api/models'

import {FIXED_INCOME_FIELD_NAMES, IFixedIncomeFormValues} from './typings'

export function getInitialEditFixedIncomeFormInitialValues(
  tradeOrder: ITradeOrder,
  accountUrl: string
): IFixedIncomeFormValues {
  return {
    [FIXED_INCOME_FIELD_NAMES.instrumentUrl]: tradeOrder.instrument,
    [FIXED_INCOME_FIELD_NAMES.accountUrl]: accountUrl,
    [FIXED_INCOME_FIELD_NAMES.qty]: tradeOrder.qty ?? null,
    [FIXED_INCOME_FIELD_NAMES.operation]: tradeOrder.operation,
    [FIXED_INCOME_FIELD_NAMES.qtyType]: tradeOrder.qtyType,
    [FIXED_INCOME_FIELD_NAMES.notes]: tradeOrder.notes || ''
  }
}
