import React from 'react'

import grey from '@material-ui/core/colors/grey'
import GroupingsIcon from '@material-ui/icons/ClearAll'
import RowsIcon from '@material-ui/icons/Dehaze'
import FilterIcon from '@material-ui/icons/FilterList'
import SettingsIcon from '@material-ui/icons/Settings'
import ColumnsIcon from '@material-ui/icons/ViewColumn'
import DisplayDataIcon from '@material-ui/icons/ViewCompact'

import {IViewGroupingItem, IViewMetricItem} from '@d1g1t/api/models'
import {IFilterValue} from '@d1g1t/typings/general'

import {classNames} from '@d1g1t/lib/class-names'

import {Flex} from '@d1g1t/shared/components/flex'
import {Badge} from '@d1g1t/shared/components/mui/badge'
import {Text} from '@d1g1t/shared/components/typography'
import {IViewOptionsConfiguration} from '@d1g1t/shared/containers/view-options/typings'
import {usePermissions} from '@d1g1t/shared/wrappers/permissions'

import * as css from './style.scss'

export enum DISPLAY_OPTIONS_TAB {
  ROWS = 'rows',
  GROUP = 'group',
  FILTER = 'filter',
  METRICS = 'metrics',
  DISPLAY_DATA = 'displayData',
  OPTIONS = 'options'
}

interface IDisplayOptionsTabsProps {
  selectedTab: DISPLAY_OPTIONS_TAB
  configuration?: IViewOptionsConfiguration
  filter: IFilterValue
  selectedGroupings: IViewGroupingItem[]
  selectedMetrics: IViewMetricItem[]
  onTabSelected(selectedTab: DISPLAY_OPTIONS_TAB): void
}

export const DisplayOptionsTabs: React.FC<IDisplayOptionsTabsProps> = (
  props
) => {
  const permissions = usePermissions()

  const getClassNames = (tab: DISPLAY_OPTIONS_TAB) => {
    if (tab === props.selectedTab) {
      return classNames(css.tab, css.selectedTab)
    }

    return css.tab
  }

  const showDisplayDataTab: boolean =
    props.configuration.showDisplayData ||
    props.configuration.showBenchmarksControl ||
    props.configuration.showHideEmptyZeroValueRowsControl ||
    props.configuration.showLookThrough ||
    props.configuration.showInternalTransactionsControl ||
    props.configuration.showCancelledTransactionsControl ||
    props.configuration.showPendingTransactionsControl ||
    props.configuration.showTradesAsUngroupedItems

  return (
    <Flex column className={css.tabsContainer}>
      {props.configuration.controlFilter && (
        <div
          className={getClassNames(DISPLAY_OPTIONS_TAB.ROWS)}
          onClick={() => props.onTabSelected(DISPLAY_OPTIONS_TAB.ROWS)}
          data-testid='display-options-rows'
        >
          <RowsIcon htmlColor={grey[400]} />
          <Text style={{color: grey[400]}}>ROWS</Text>
        </div>
      )}
      {props.configuration.metrics && (
        <div
          className={getClassNames(DISPLAY_OPTIONS_TAB.METRICS)}
          onClick={() => props.onTabSelected(DISPLAY_OPTIONS_TAB.METRICS)}
          data-testid='display-options-metrics'
        >
          <Badge
            color='primary'
            badgeContent={
              props.selectedMetrics ? props.selectedMetrics.length : 0
            }
            overlap='rectangular'
          >
            <ColumnsIcon htmlColor={grey[400]} />
          </Badge>
          <Text style={{color: grey[400]}}>COLUMNS</Text>
        </div>
      )}
      {props.configuration.groups && (
        <div
          className={getClassNames(DISPLAY_OPTIONS_TAB.GROUP)}
          onClick={() => props.onTabSelected(DISPLAY_OPTIONS_TAB.GROUP)}
          data-testid='display-options-group'
        >
          <Badge
            color='primary'
            badgeContent={
              props.selectedGroupings ? props.selectedGroupings.length : 0
            }
            overlap='rectangular'
          >
            <GroupingsIcon className={css.flipIcon} htmlColor={grey[400]} />
          </Badge>
          <Text style={{color: grey[400]}}>GROUP</Text>
        </div>
      )}
      {props.configuration.filters && (
        <div
          className={getClassNames(DISPLAY_OPTIONS_TAB.FILTER)}
          onClick={() => props.onTabSelected(DISPLAY_OPTIONS_TAB.FILTER)}
          data-testid='display-options-filter'
        >
          <Badge
            color='primary'
            badgeContent={props.filter ? props.filter.items.length : 0}
            overlap='rectangular'
          >
            <FilterIcon htmlColor={grey[400]} />
          </Badge>
          <Text style={{color: grey[400]}}>FILTER</Text>
        </div>
      )}
      {showDisplayDataTab && (
        <div
          className={getClassNames(DISPLAY_OPTIONS_TAB.DISPLAY_DATA)}
          onClick={() => props.onTabSelected(DISPLAY_OPTIONS_TAB.DISPLAY_DATA)}
          data-testid='display-options-display-data'
        >
          <DisplayDataIcon htmlColor={grey[400]} />
          <Text style={{color: grey[400]}}>LAYOUT</Text>
        </div>
      )}
      {permissions.isAdministrator() && (
        <div
          className={getClassNames(DISPLAY_OPTIONS_TAB.OPTIONS)}
          onClick={() => props.onTabSelected(DISPLAY_OPTIONS_TAB.OPTIONS)}
          data-testid='display-options-admin-options'
        >
          <SettingsIcon htmlColor={grey[400]} />
          <Text style={{color: grey[400]}}>OPTIONS</Text>
        </div>
      )}
    </Flex>
  )
}
