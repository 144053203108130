import {
  METRIC_A_ENTITY_ACCOUNT_NAME,
  METRIC_Q_SPOT_HOLDING_VALUES_VALUE
} from '@d1g1t/api/models'
import {GROUPING_CRITERIA_SLUG} from '@d1g1t/api/models/slugs'

import {IMetricRequestSelection} from '@d1g1t/lib/metrics'

export enum ACCOUNT_GROUPING_OPTION {
  ACCOUNT = 'typeName',
  ACCOUNT_NAME = 'name',
  INVESTMENT_MANDATE = 'investmentMandate.name',
  INVESTMENT_STRATEGY = 'investmentStrategy',
  CLIENT = 'client.printName'
}

export const METRICS: IMetricRequestSelection[] = [
  METRIC_A_ENTITY_ACCOUNT_NAME,
  METRIC_Q_SPOT_HOLDING_VALUES_VALUE
]

// Position name is set to a constant so that it does not break the groups filter if this constant value is changed at any time
export const POSITION_NAME = GROUPING_CRITERIA_SLUG.POSITION_NAME

// NOTE: Can't group by account name - API returns
// model_name = account when multiple accounts are grouped

export const GROUPBY_ACCOUNT_TYPE = [
  GROUPING_CRITERIA_SLUG.ACCOUNT_TYPE,
  GROUPING_CRITERIA_SLUG.ACCOUNT_ID,
  POSITION_NAME
]

export const GROUPBY_ACCOUNT_NAME = [
  GROUPING_CRITERIA_SLUG.ACCOUNT_ID,
  POSITION_NAME
]

export const GROUPBY_ACCOUNT_MANDATE = [
  GROUPING_CRITERIA_SLUG.ACCOUNT_MANDATE,
  GROUPING_CRITERIA_SLUG.ACCOUNT_ID,
  POSITION_NAME
]

// To save last used grouping in AccountSelect modal
export const DEFAULT_HOLDINGS_FILTER_SETTNIGS = {
  accountGrouping: ACCOUNT_GROUPING_OPTION.ACCOUNT
}
