import {ALL_MODELS} from '@d1g1t/api/models'

/**
 * This may be overridden by the score of the top-n search results.
 */
export const MODEL_DISPLAY_GROUP_ORDER = [
  ALL_MODELS.TAG,
  ALL_MODELS.DATASET,
  ALL_MODELS.INDIVIDUAL,
  ALL_MODELS.HOUSEHOLD,
  ALL_MODELS.CORPORATION,
  ALL_MODELS.PERSON,
  ALL_MODELS.PERSONGROUP,
  ALL_MODELS.PORTFOLIO,
  ALL_MODELS.INSTRUMENT,
  ALL_MODELS.CLASSSERIES,
  ALL_MODELS.PROFILE,
  ALL_MODELS.FOUNDATION,
  ALL_MODELS.TRUST,
  ALL_MODELS.ACCOUNT,
  ALL_MODELS.ACCOUNTGROUP,
  ALL_MODELS.INVESTMENTMANDATE
]

export const MODEL_DISPLAY_ORDER = MODEL_DISPLAY_GROUP_ORDER.reduce(
  (order, model, index) => ({
    ...order,
    [model]: index
  }),
  {}
)
