import React, {useEffect, useMemo, useState} from 'react'

import {useApi} from 'fairlight'
import {useSetRecoilState} from 'recoil'
import useSagaReducer from 'use-saga-reducer'

import {PortfolioRebalanceEndpoints} from '@d1g1t/api/endpoints'
import {
  GROUPING_NODE_TYPE,
  IAccountsRebalancingResponse
} from '@d1g1t/api/models'

import {getControlId} from '@d1g1t/lib/control'
import {useToggleState} from '@d1g1t/lib/hooks'
import {getCurrentValue} from '@d1g1t/lib/rebalance'
import {StandardResponse} from '@d1g1t/lib/standard-response'
import {mapUrlsToIds} from '@d1g1t/lib/url'

import {Card} from '@d1g1t/shared/components/card'
import {Flex, FlexChild} from '@d1g1t/shared/components/flex'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Button} from '@d1g1t/shared/components/mui/button'
import {LabeledCheckbox} from '@d1g1t/shared/components/mui/checkbox'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {ConcentrationChart} from '@d1g1t/shared/components/standard-chart/concentration'
import {DriftChart} from '@d1g1t/shared/components/standard-chart/drift'
import {H3, H5} from '@d1g1t/shared/components/typography'
import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'
import {useSelectedEntities} from '@d1g1t/shared/containers/select-entities'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {StandardTable} from '@d1g1t/shared/containers/standard-table'
import {
  useSelectedView,
  ViewOptions
} from '@d1g1t/shared/containers/view-options'
import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'

import {ChartSideContainer} from '@d1g1t/advisor/components/chart-side-container'
import {Page} from '@d1g1t/advisor/components/page'
import {PageContent} from '@d1g1t/advisor/components/page-content'
import {RecommendedTrades} from '@d1g1t/advisor/containers/recommended-trades'
import {RebalanceTotalCell} from '@d1g1t/advisor/containers/standard-table/components/custom-types/rebalance-total'
import {useAccountsData} from '@d1g1t/advisor/wrappers/accounts'

import {actions} from './actions'
import {accountsDetailsAtom} from './atoms'
import {RebalanceAccount} from './components/account'
import {CATEGORY_IDS} from './components/account/constants'
import {TargetPortfolio} from './components/target-portfolio'
import {
  ACTION,
  CHART_OPTIONS,
  REBALANCE_TABLE_ID,
  REBALANCE_VIEWS_SAVE_ID,
  WEIGHTS_SPECIFICATION_OPTIONS
} from './constants'
import {useAccountUrls} from './hooks'
import {
  getTargetValuesForAccounts,
  previewRequestDataIsValid,
  rebalanceAccountStandardTableId
} from './lib'
import {reducer} from './reducers'
import {rebalanceDataFetchingSagas} from './sagas'

export const Rebalance: React.FC = () => {
  const api = useApi()
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()

  const {control} = useSelectedEntities()
  const [calculationSettings] = useCalculationSettings()
  const [{displayOptions}] = useSelectedView(REBALANCE_VIEWS_SAVE_ID)

  const [accountWeightsSpecification, setAccountWeightsSpecification] =
    useState(false)
  const [showGraphs, toggleShowGraphs] = useToggleState(true)
  const [referencePortfolio, setReferencePortfolio] = useState<string>(null)
  const [selectedChart, setSelectedChart] = useState(CHART_OPTIONS.TARGET_DRIFT)
  const [accountUrls] = useAccountUrls(control?.selectedEntities)
  const [state, dispatch] = useSagaReducer(rebalanceDataFetchingSagas, reducer)

  const listOfAccountEntities = useMemo(
    () =>
      accountUrls.data && {
        accountsOrPositions: [mapUrlsToIds(accountUrls.data)]
      },
    [accountUrls.data]
  )
  const [accounts] = useAccountsData(listOfAccountEntities)

  const handleFetchData = (newResponse: IAccountsRebalancingResponse) => {
    dispatch(
      actions.rebalanceAccountsRequest({
        accountsData: getTargetValuesForAccounts(newResponse, accountUrls.data),
        groupings: displayOptions.groups.map(
          (group) => group.groupingCriterion
        ),
        referencePortfolio,
        isPercentOfTotal: accountWeightsSpecification,
        asOfDate: calculationSettings.date.date
      })
    )
  }

  const setAccountDetails = useSetRecoilState(accountsDetailsAtom)

  // Updates the atom w/ the keys passed down to RebalanceAccount and name of the account (for sheet title)
  useEffect(() => {
    if (accounts.data?.length > 0) {
      const accountDetails = {}
      for (const account of accounts.data) {
        accountDetails[rebalanceAccountStandardTableId(account.entityId)] =
          account.name
            ? `${account.name} (${account.firmProvidedKey})`
            : account.firmProvidedKey
      }

      setAccountDetails(accountDetails)
    }
  }, [accounts.data])

  useEffect(() => {
    // Cleanup on dismount
    return () => {
      setAccountDetails({})
    }
  }, [])

  /**
   * fetch new rebalance data if request params are changed
   */
  useEffect(() => {
    if (accountUrls.data?.length > 0 && displayOptions) {
      handleFetchData(null)
    }
  }, [
    accountUrls.data,
    referencePortfolio,
    displayOptions?.groups,
    calculationSettings?.date?.date,
    accountWeightsSpecification
  ])

  const getRequestData = (action: ACTION = null) => ({
    accountsData: getTargetValuesForAccounts(
      state.rebalance.data,
      accountUrls.data,
      action
    ),
    groupings: displayOptions?.groups.map((group) => group.groupingCriterion),
    referencePortfolio,
    isPercentOfTotal: accountWeightsSpecification,
    asOfDate: calculationSettings.date.date
  })

  const previewRequestData = (() => {
    if (
      !state?.rebalance.data ||
      !accountUrls.data ||
      state?.rebalance.data.accountTables.length !== accountUrls.data.length
    ) {
      return null
    }

    return getRequestData(ACTION.PREVIEW)
  })()

  const overviewChart = useMemo(() => {
    const chart = referencePortfolio
      ? selectedChart
      : CHART_OPTIONS.TARGET_WEIGHTS

    if (!state?.rebalance.data) {
      return
    }

    if (chart === CHART_OPTIONS.TARGET_WEIGHTS) {
      return (
        <ConcentrationChart
          chart={new StandardResponse(
            state?.rebalance.data.overviewTable
          ).concentrationTable({
            weightCategoryId: CATEGORY_IDS.TARGET_WEIGHT
          })}
          name='Concentration'
        />
      )
    }

    return (
      <DriftChart
        chart={new StandardResponse(
          state.rebalance.data.overviewTable
        ).concentrationTable({
          weightCategoryId:
            selectedChart === CHART_OPTIONS.TARGET_DRIFT
              ? 'target_drift'
              : 'current_drift',
          filterZeroValues: false
        })}
        name='Drift'
      />
    )
  }, [state?.rebalance.data?.overviewTable, referencePortfolio, selectedChart])

  const overviewTable = useMemo(() => {
    if (!state?.rebalance.data) {
      return
    }

    const table = new StandardResponse(state.rebalance.data.overviewTable)

    table.updateCategoryOptions(
      [
        CATEGORY_IDS.CURRENT_WEIGHT,
        CATEGORY_IDS.TARGET_DRIFT,
        CATEGORY_IDS.CURRENT_DRIFT
      ],
      {
        decimals: 2
      }
    )

    return table
  }, [state?.rebalance.data?.overviewTable])

  const rebalanceAccountTables = useMemo(() => {
    const nonTradableAccountsTables: JSX.Element[] = []
    const tradableAccountsTables: JSX.Element[] = []

    if (!state?.rebalance.data || !accountUrls.data || !accounts.data) {
      return null
    }

    for (const [
      index,
      accountTable
    ] of state.rebalance.data.accountTables.entries()) {
      if (
        getCurrentValue(state.rebalance.data.accountTables[index]) <= 0 ||
        !accounts.data[index]
      ) {
        continue
      }

      const rebalanceAccountTableComponent = (
        <ErrorBoundary resetId={getControlId(control)}>
          <RebalanceAccount
            data-account-table-card
            id={rebalanceAccountStandardTableId(accounts.data[index].entityId)}
            key={accounts.data[index].entityId}
            index={index}
            showGraphs={showGraphs}
            overviewTotalCurrentValue={
              accountWeightsSpecification &&
              getCurrentValue(state.rebalance.data.overviewTable)
            }
            accountTable={new StandardResponse(accountTable)}
            accountChart={
              new StandardResponse(state.rebalance.data.accountCharts[index])
            }
            account={accounts.data[index]}
            selectedChart={selectedChart}
            referencePortfolio={referencePortfolio}
            onSelectChart={setSelectedChart}
            onUpdateData={(data) => dispatch(actions.updateData(data))}
            rebalanceResponse={state.rebalance.data}
            onFetchData={handleFetchData}
            onCancelRebalanceRequest={() =>
              dispatch(actions.rebalanceAccountsCancel())
            }
          />
        </ErrorBoundary>
      )

      if (accounts.data[index].isTradable) {
        tradableAccountsTables.push(rebalanceAccountTableComponent)

        continue
      }

      nonTradableAccountsTables.push(rebalanceAccountTableComponent)
    }

    return [...tradableAccountsTables, ...nonTradableAccountsTables]
  }, [
    state?.rebalance.data,
    accounts.data,
    selectedChart,
    referencePortfolio,
    showGraphs,
    accountWeightsSpecification
  ])

  const handleSaveDraftClick = async () => {
    const requestData = getRequestData()

    try {
      await api.request(PortfolioRebalanceEndpoints.saveDraft(requestData))

      showSnackbar({
        variant: 'success',
        message: 'Draft was successfully saved.'
      })
    } catch (error) {
      handleUnexpectedError(
        error,
        'An unexpected error occurred while saving the draft.'
      )
    }
  }

  if (!control) {
    return null
  }

  return (
    <Page style={{marginTop: '20px'}}>
      <PageContent recommendedTradesBarBottomPadding>
        <Card flexColumn>
          <Flex toolbar>
            <Flex alignCenter>
              <TargetPortfolio
                referencePortfolio={referencePortfolio}
                onReferencePortfolioChange={setReferencePortfolio}
                onClearReferencePortfolio={() => setReferencePortfolio(null)}
              />
            </Flex>
            <Flex alignCenter>
              <LabeledCheckbox
                label='Show Graphs'
                checked={showGraphs}
                onChangeValue={toggleShowGraphs}
              />
              <Spacer vertical xxs />
              <ViewOptions
                whiteBackground
                configuration={{
                  groups: {types: [GROUPING_NODE_TYPE.SECURITY]}
                }}
                id={REBALANCE_VIEWS_SAVE_ID}
              />
            </Flex>
          </Flex>
          <Spacer xxs />
          <Flex>
            <H3>Overview</H3>
          </Flex>
          <Spacer xxs />
          <Flex grow={1}>
            <FlexChild grow={1}>
              <LoadingContainer
                loading={
                  state?.rebalance.loading ||
                  accounts.loading ||
                  accountUrls.loading
                }
              />
              {state?.rebalance.data && (
                <StandardTable
                  id={REBALANCE_TABLE_ID}
                  table={overviewTable}
                  defaultNameColumnWidth={160}
                  defaultColumnWidth={100}
                  minColumnWidth={80}
                  categoriesTotalOverride={{
                    [CATEGORY_IDS.TARGET_WEIGHT]: RebalanceTotalCell,
                    [CATEGORY_IDS.WEIGHT_CHANGE]: RebalanceTotalCell,
                    [CATEGORY_IDS.CURRENT_WEIGHT]: RebalanceTotalCell
                  }}
                />
              )}
            </FlexChild>
            {showGraphs && (
              <ChartSideContainer>
                {referencePortfolio && (
                  <Flex justifyCenter>
                    <Button
                      contained={selectedChart === CHART_OPTIONS.TARGET_WEIGHTS}
                      onClick={() =>
                        setSelectedChart(CHART_OPTIONS.TARGET_WEIGHTS)
                      }
                    >
                      Post Weights
                    </Button>
                    <Spacer xxs vertical />
                    <Button
                      contained={selectedChart === CHART_OPTIONS.CURRENT_DRIFT}
                      onClick={() =>
                        setSelectedChart(CHART_OPTIONS.CURRENT_DRIFT)
                      }
                    >
                      Current Drift
                    </Button>
                    <Spacer xxs vertical />
                    <Button
                      contained={selectedChart === CHART_OPTIONS.TARGET_DRIFT}
                      onClick={() =>
                        setSelectedChart(CHART_OPTIONS.TARGET_DRIFT)
                      }
                    >
                      Post Drift
                    </Button>
                  </Flex>
                )}
                {state?.rebalance.data && (
                  <FlexChild grow={1}>{overviewChart}</FlexChild>
                )}
              </ChartSideContainer>
            )}
          </Flex>
        </Card>
        <Card dark flexColumn minHeight={250}>
          <Flex toolbar>
            <Flex>
              <H3>Accounts</H3>
              <Flex alignCenter>
                <Spacer vertical xs />
                <H5>All Weights are expressed as</H5>
                <Spacer vertical xxs />
                <ValueLabelSelect
                  size='small'
                  noBorder
                  whiteBackground
                  value={accountWeightsSpecification}
                  options={WEIGHTS_SPECIFICATION_OPTIONS}
                  onChange={setAccountWeightsSpecification}
                />
              </Flex>
            </Flex>
          </Flex>
          <FlexChild grow={1}>
            <LoadingContainer
              loading={
                !(accountUrls.data && accounts.data && state?.rebalance.data) &&
                (accountUrls.loading ||
                  accounts.loading ||
                  state?.rebalance.loading)
              }
            >
              {rebalanceAccountTables}
            </LoadingContainer>
          </FlexChild>
          <Flex justifyFlexEnd>
            <Button
              onClick={() =>
                dispatch(
                  actions.clearDraftRequest(getRequestData(ACTION.CLEAR))
                )
              }
            >
              Clear All
            </Button>
            <Spacer vertical xxs />
            <Button onClick={handleSaveDraftClick}>Save Weights</Button>
            <Spacer vertical xxs />
          </Flex>
        </Card>
      </PageContent>
      <RecommendedTrades
        requestParams={
          previewRequestDataIsValid(previewRequestData?.accountsData)
            ? PortfolioRebalanceEndpoints.preview(previewRequestData)
            : null
        }
        loadingChartData={state?.rebalance.loading}
      />
    </Page>
  )
}
