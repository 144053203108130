import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'

import {Checkbox, Divider} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import {classNames} from '@d1g1t/lib/class-names'

import {
  IconButton,
  IIconButtonProps
} from '@d1g1t/shared/components/mui/icon-button'
import {ListItemIcon} from '@d1g1t/shared/components/mui/list-item-icon'
import {ListItemText} from '@d1g1t/shared/components/mui/list-item-text'
import {IMenuProps, Menu} from '@d1g1t/shared/components/mui/menu'
import {
  IMenuItemLinkProps,
  IMenuItemProps,
  MenuItem,
  MenuItemLink
} from '@d1g1t/shared/components/mui/menu-item'
import {ITooltipProps, Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {NestedMenuItem} from '@d1g1t/shared/components/nested-menu-item'
import {H2} from '@d1g1t/shared/components/typography'

import {
  createActionAndDropdownOptionsArrays,
  formatDropdownOptions
} from './lib'

import * as css from './styles.scss'

export interface IMenuItemOption {
  icon?: React.ReactElement
  label: React.ReactNode
  tooltip?: Omit<ITooltipProps, 'children'>
  testid?: string
}

export interface IMenuItemActionOption
  extends Omit<IMenuItemProps, 'children' | 'onClick'>,
    IMenuItemOption {
  onClick?: (event?: React.MouseEvent) => void
  /**
   * If passed, menu option will show a checkbox
   */
  isChecked?: boolean
  /**
   * label that will group items to select
   */
  nestedMenuLabel?: string
  /**
   * If passed, the menu option will be rendered as a nested menu
   */
  nestedOptions?: IMenuItemActionOption[]
}
export interface IMenuItemLinkOption
  extends Omit<IMenuItemLinkProps, 'children' | 'onClick' | 'to'>,
    IMenuItemOption {
  link?: IMenuItemLinkProps['to']
  keyboardShortcutLetter?: string
  addDivider?: boolean
  nestedOptions?: IMenuItemLinkOption[]
}

export type IIconMenuMenuItemProps =
  | Partial<IMenuItemActionOption>
  | Partial<IMenuItemLinkOption>

function isParentElement(props: IIconMenuMenuItemProps) {
  return 'nestedOptions' in props
}

function isClickAction(
  props: IIconMenuMenuItemProps
): props is IMenuItemActionOption {
  return 'onClick' in props
}

function isLinkAction(
  props: IIconMenuMenuItemProps
): props is IMenuItemLinkOption {
  return 'link' in props
}

export interface IIconMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * IconMenu component `Select` icon or button.
   */
  icon?: React.ReactNode
  /**
   * The passed node will be rendered before the `options` are rendered.
   */
  firstOptionNode?: React.ReactNode
  options: IIconMenuMenuItemProps[]
  title?: string
  children?: never
  MenuProps?: Omit<IMenuProps, 'children' | 'open' | 'onClose' | 'anchorEl'> & {
    children?: never
  }
  IconButtonProps?: IIconButtonProps
  /**
   * Used to style PageCalculationSettings' icon on the right with 3 dots.
   */
  threeDotMenuCalculation?: boolean
}

export const IconMenu: React.FC<IIconMenuProps> = ({
  icon = <MoreVertIcon />,
  options,
  title,
  onClick,
  MenuProps,
  IconButtonProps,
  threeDotMenuCalculation,
  ...props
}) => {
  /**
   * A performance optimization, to allow deferring the render of the menu.
   * Useful in StandardTable where any extra renders are expensive.
   *
   * When `false`, the initially invisible menu will not be rendered. `active`
   * becomes `true` on hover/focus of the icon/title.
   */
  const [active, setActive] = useState(false)
  const [anchorEl, setAchorEl] = useState(null)
  const location = useLocation()

  const [actionOptions, dropdownOptions] =
    createActionAndDropdownOptionsArrays(options)
  const nestedOptions = formatDropdownOptions(dropdownOptions)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAchorEl(event.currentTarget)
    if (onClick) {
      onClick(event)
    }
  }

  const handleActivate = (event: React.SyntheticEvent) => {
    if (active) {
      return
    }

    setActive(true)
  }

  const handleClose = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    setAchorEl(null)
  }

  const createOnClickAction =
    (onClick: IMenuItemActionOption['onClick']) =>
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      handleClose(event)
      if (anchorEl) {
        onClick(event)
      }
    }

  const getOptionElements = (
    option: Partial<IIconMenuMenuItemProps>,
    index: number
  ) => {
    if (isParentElement(option)) {
      const {label, icon, nestedOptions, ...menuItemProps} = option
      const childEls = nestedOptions.map(getOptionElements)
      return (
        <NestedMenuItem
          key={index}
          {...menuItemProps}
          title={option.title}
          label={label}
          icon={icon}
          data-dd-action={`${location.pathname} - ${label}`}
        >
          {childEls}
        </NestedMenuItem>
      )
    }

    if (isClickAction(option)) {
      const {label, onClick, icon, nestedOptions, ...menuItemProps} = option

      return (
        <MenuItem
          button
          key={index}
          onClick={
            option.isChecked !== undefined
              ? option.onClick
              : createOnClickAction(onClick)
          }
          {...menuItemProps}
          title={option.title || (typeof label === 'string' && label)}
        >
          {option.isChecked !== undefined && (
            <Checkbox checked={option.isChecked} color='primary' />
          )}
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      )
    }

    if (isLinkAction(option)) {
      const {label, link, icon, nestedOptions, addDivider, ...menuItemProps} =
        option

      return (
        <div key={index}>
          <MenuItemLink onClick={handleClose} to={link} {...menuItemProps}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText>{label}</ListItemText>
          </MenuItemLink>
          {addDivider && (
            <Divider style={{marginTop: '1rem', marginBottom: '1rem'}} />
          )}
        </div>
      )
    }

    return null
  }

  return (
    <>
      <div
        onMouseDown={handleClick}
        onMouseEnter={handleActivate}
        onFocus={handleActivate}
        className={classNames(css.iconMenuContainer, {
          [css.threeDotMenuCalculation]: threeDotMenuCalculation
        })}
      >
        <IconButton {...IconButtonProps} data-testid='menu-button'>
          {icon}
          {IconButtonProps?.children ?? null}
        </IconButton>
        {title && <H2 style={{fontSize: 24}}>{title}</H2>}
      </div>

      {active && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          {...MenuProps}
        >
          {props.firstOptionNode}
          {actionOptions.map(({tooltip, ...option}, index) => {
            const optionElements = getOptionElements(option, index)

            return tooltip ? (
              <Tooltip key={index} {...tooltip}>
                <div>{optionElements}</div>
              </Tooltip>
            ) : (
              optionElements
            )
          })}

          {Object.entries(nestedOptions).map(([label, options]) => (
            <NestedMenuItem key={label} label={label}>
              {options.map((item: IMenuItemActionOption) => (
                <MenuItem
                  button
                  key={label}
                  onClick={createOnClickAction(item.onClick)}
                  title={label}
                >
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
              ))}
            </NestedMenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}
