import {useCallback, useEffect, useMemo, useReducer, useRef} from 'react'

import produce from 'immer'
import {debounce, isEmpty, range} from 'lodash'
import {useRecoilState} from 'recoil'

import {IChartTable} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'

import {useDebouncedValue} from '@d1g1t/lib/hooks'
import {StandardResponse} from '@d1g1t/lib/standard-response'

import {snackbarActions} from '@d1g1t/shared/containers/snackbar'
import {
  IColumnFilterState,
  IItemPageId,
  ISortColumnOptions
} from '@d1g1t/shared/containers/standard-table'

import {paginatorActions} from './actions'
import {chartCategoriesAtom} from './atoms'
import {
  findRangesToLoad,
  generateFullTextSearch,
  generatePaginationFilter,
  IRangeToLoad
} from './lib'
import {paginatorReducer} from './reducer'
import {IFilterGenerator, IPaginatorResponseReducerState} from './typings'

const FILTER_DEBOUNCE_DURATION_MS = 500
const PAGINATION_LOAD_SIZE = 150

/**
 * when posible use `useCalculationData` instead
 * Hook to simplify the pagination of chart table data
 */
export function useChartPaginator(
  /**
   * Given pagination options, resolves with the chart data for the given page
   */
  loadChart: (pagination: IPaginationOptions) => Promise<IChartTable>,
  opts: {
    /**
     * When provided, the hook will store the response categories in Recoil. This will
     * allow it to persist the column filters for the specific table. The filters
     * will be restored when switching to another view.
     */
    id?: string
    /**
     * Overrides default debounce time for filters.
     */
    customDebounceDuration?: number
    /**
     * If true will not debounce value on column filter and update on every
     * change. e.g. Used for quick drawer filters.
     */
    disableDebounceColumnFilter?: boolean
    sortOptions: ISortColumnOptions
    /**
     * Filters items by checking if all categoryId search expressions
     * match. Search text in each field is split by comma and acts as an OR
     * operator. Search text in different fields act as an AND operator
     */
    columnFilter?: IColumnFilterState
    /**
     * Filters items using full text filter
     */
    fullTextFilter?: string
    /**
     * Any additional filters to be applied for a category
     */
    additionalFiltersForCategoryId?: Dictionary<IFilterGenerator>
  }
): [
  IPaginatorResponseReducerState,
  {
    /**
     * Function to fetch items for a given range. This can be called
     * many times for the same indices, due to debouncing optimizations.
     * Can specify unloaded ranges (e.g. when trying to update a single row)
     */
    fetchRanges(
      itemPageIds: IItemPageId[],
      specifyUnloadedRanges?: IRangeToLoad[]
    ): void
    /**
     * Manually set the standard response (ie. due to a quick drawer update)
     */
    setResponse(data: StandardResponse): void
    /**
     * Refreshes the data by re-loading the initial page
     */
    refresh(): void
  }
] {
  const [state, dispatch] = useReducer(paginatorReducer, undefined, () =>
    paginatorReducer(undefined, paginatorActions.initialize())
  )

  /**
   * Track reference to latest load to avoid race conditions
   */
  const loaderReference = useRef<symbol>(null)

  const {response, loadingIndexMap} = state

  // Store the categories by id to enable persistent filters
  const [categoriesState, setCategoriesState] =
    useRecoilState(chartCategoriesAtom)
  useEffect(() => {
    const categories = response.data?.categories
    const id = opts.id

    if (!categories || !id) {
      return
    }
    setCategoriesState(
      produce(categoriesState, (draft) => {
        draft[id] = categories
      })
    )
  }, [response.data, opts.id])

  // Effect to sync changes to filters, debounced to avoid excess requests
  const debouncedColumnFilter = useDebouncedValue(
    opts.columnFilter,
    opts.disableDebounceColumnFilter
      ? 0
      : opts.customDebounceDuration || FILTER_DEBOUNCE_DURATION_MS
  )
  const debouncedFullTextFilter = useDebouncedValue(
    opts.fullTextFilter,
    opts.customDebounceDuration || FILTER_DEBOUNCE_DURATION_MS
  )

  /**
   * Returns API pagination options object given page size & offset
   */
  const generatePaginationOptions = useCallback(
    (
      pageOpts: Pick<IPaginationOptions, 'offset' | 'size'>
    ): IPaginationOptions => {
      let orderByValue = null

      if (opts.sortOptions && opts.sortOptions.categoryId) {
        orderByValue = opts.sortOptions.categoryId

        if (opts.sortOptions.sortOrder === SORT_ORDER.DESC) {
          orderByValue = `-${orderByValue}`
        }
      }

      // Pull categories from Recoil Atom or from the local state
      const categoriesResponse = categoriesState[opts.id]
        ? new StandardResponse({
            items: [],
            categories: categoriesState[opts.id]
          })
        : response.data
      const filter = generatePaginationFilter(categoriesResponse, {
        columnFilter: debouncedColumnFilter,
        additionalFiltersForCategoryId: opts.additionalFiltersForCategoryId
      })

      const search = generateFullTextSearch(response.data, {
        searchString: debouncedFullTextFilter
      })

      return {
        ...pageOpts,
        parentPath: 'root',
        filtering: isEmpty(filter) ? undefined : filter,
        search: isEmpty(search) ? undefined : search,
        orderBy: orderByValue ? [orderByValue] : []
      }
    },
    [
      opts.id,
      opts.sortOptions,
      debouncedColumnFilter,
      debouncedFullTextFilter,
      opts.additionalFiltersForCategoryId
    ]
  )

  const loadInitialPage = async () => {
    if (!loadChart) {
      return
    }

    loaderReference.current = Symbol()

    dispatch(paginatorActions.loadInitialPageRequest())
    try {
      const chart = await loadChart(
        generatePaginationOptions({
          offset: 0,
          size: PAGINATION_LOAD_SIZE
        })
      )
      dispatch(paginatorActions.loadInitialPageSuccess(chart))
    } catch (error) {
      dispatch(paginatorActions.loadInitialPageFailure(error))
    }
  }

  // (re)-load first page when the loadChart function changes
  useEffect(() => {
    loadInitialPage()
  }, [loadChart, generatePaginationOptions])

  const fetchRanges = useMemo(() => {
    let latestLoaderReference = loaderReference.current

    // Debounce this method to avoid creating a backlog of network requests
    // as we are limited to 6 multiplexed requests using XHR.
    return debounce(
      (itemPageIds: IItemPageId[], specifyUnloadedRanges?: IRangeToLoad[]) => {
        if (
          !response.data ||
          response.data.size === 0 ||
          // ensure that a fresh reload hasn't started since debouncing
          latestLoaderReference !== loaderReference.current
        ) {
          return
        }

        latestLoaderReference = loaderReference.current

        const unloadedRanges =
          specifyUnloadedRanges ||
          findRangesToLoad({
            itemPageIds,
            loadingIndexMap,
            // This minimumBatchSize value was chosen arbitrarily, seems like a decent
            // minimum. Can be adjusted to see if we can improve loading time.
            minimumBatchSize: PAGINATION_LOAD_SIZE,
            loadedItems: response.data.toChartTable().items
          })

        if (!unloadedRanges.length) {
          return
        }

        // fetch ranges here
        Promise.all(
          unloadedRanges.map(async (unloadedRange) => {
            if (specifyUnloadedRanges) {
              dispatch(
                paginatorActions.updatingItemsRequest(
                  // list of item ids (rows) that are updating in table
                  range(
                    unloadedRange.startIndex,
                    unloadedRange.stopIndex + 1
                  ).map((index) => response.data.itemAtIndex(index).id)
                )
              )
            }
            dispatch(paginatorActions.loadRangeRequest(unloadedRange))
            try {
              const chart = await loadChart(
                generatePaginationOptions({
                  offset: unloadedRange.startIndex,
                  size: unloadedRange.stopIndex - unloadedRange.startIndex + 1
                })
              )

              // ensure that a fresh initial load hasn't started since making api request
              if (latestLoaderReference !== loaderReference.current) {
                return dispatch(
                  paginatorActions.loadRangeFailure({
                    range: unloadedRange,
                    error: new Error(
                      'Cancelling range load due to fresh initial load'
                    )
                  })
                )
              }
              if (specifyUnloadedRanges) {
                dispatch(paginatorActions.updatingItemsSuccess())
              }
              dispatch(
                paginatorActions.loadRangeSuccess({range: unloadedRange, chart})
              )
            } catch (error) {
              if (specifyUnloadedRanges) {
                dispatch(paginatorActions.updatingItemsFailure())
                dispatch(
                  snackbarActions.show({
                    variant: 'error',
                    message: `Failed to update row${
                      unloadedRange.startIndex !== unloadedRange.stopIndex
                        ? 's'
                        : ''
                    }`
                  })
                )
              }
              dispatch(
                paginatorActions.loadRangeFailure({range: unloadedRange, error})
              )
              console.error(`Could not fetch range ${error.message}`)
            }
          })
        )

        // 30ms debounce time was chosen from some playing around. Longer times
        // will cause fewer requests and less data loaded that the user has scrolled
        // past. Shorter times will make loading feel faster at the expense of
        // loading more irrelevant data.
      },
      30
    )
  }, [state, generatePaginationOptions])

  const setResponse = (data: StandardResponse) => {
    dispatch(paginatorActions.setResponseData(data))
  }

  return [response, {fetchRanges, setResponse, refresh: loadInitialPage}]
}
