import {Store} from 'redux'
import {EffectMiddleware} from 'redux-saga'

import {makeCustomEffect} from 'use-saga-reducer'

export enum CUSTOM_EFFECT_TYPES {
  REDUX_PUT = 'REDUX_PUT',
  REDUX_SELECT = 'REDUX_SELECT'
}

/**
 * Custom saga effect, when `yield`-ed, dispatched the passed action to the redux
 * store
 * @param action - action to dispatch to redux store
 *
 * @example
 * ```ts
 * try {
 *   // make API call
 * } catch (error) {
 *   yield reduxPut(
 *     errorHandlerActions.handleError({
 *       error,
 *       snackbarMessage: 'Oh noooo'
 *     })
 *   )
 * }
 * ```
 */
export function reduxPut(action) {
  return makeCustomEffect(CUSTOM_EFFECT_TYPES.REDUX_PUT, {action})
}

const identity = (v) => v
/**
 * Custom saga effect, when `yield`-ed, returns the result of the selector method
 * or the global store if called without params
 *
 * @param selector - selector to apply to the global redux state
 * @param args - arguments passed to selector method
 *
 * @example
 * ```ts
 * const getDomain = (state) => state[domain]
 * // ...
 * const domain: IDomain = yield select(getDomain)
 * ```
 */
export function reduxSelect(selector = identity, ...args) {
  return makeCustomEffect(CUSTOM_EFFECT_TYPES.REDUX_SELECT, {selector, args})
}

/**
 * Creates middleware required to enable `reduxPut` and `reduxSelect`
 * @param store - redux store instance
 */
export function createSagaMiddleware(store: Store): EffectMiddleware {
  return (next) => (effect) => {
    if (effect.type === CUSTOM_EFFECT_TYPES.REDUX_PUT) {
      store.dispatch(effect.payload.action)
      next(effect)
    } else if (effect.type === CUSTOM_EFFECT_TYPES.REDUX_SELECT) {
      const state = effect.payload.selector(
        store.getState(),
        ...effect.payload.args
      )
      next(state)
    } else {
      next(effect)
    }
  }
}
