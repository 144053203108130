import {createAction} from 'typesafe-actions'

import {
  IBulkRebalanceChartParams,
  IRebalancingDraftResponse
} from '@d1g1t/api/endpoints'
import {
  BULKUPDATEREQUEST_DESIRED_ACTION,
  COMMISSION_TYPE,
  IFxRate,
  IInstrument
} from '@d1g1t/api/models'

import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'

import {ISearchResult} from '@d1g1t/shared/containers/search'
import {IStandardTableCategory} from '@d1g1t/shared/containers/standard-table'

import {IInitialMarketPrices} from './typings'

export const changeAllocationActions = {
  initialize: createAction('INITIALIZE')(),
  loadSecuritiesForPortfolioRequest: createAction(
    'LOAD_SECURITIES_FOR_PORTFOLIO_REQUEST'
  )<ISearchResult>(),
  loadSecuritiesForPortfolioSuccess: createAction(
    'LOAD_SECURITIES_FOR_PORTFOLIO_SUCCESS'
  )<{draft: IRebalancingDraftResponse; instruments: IInstrument[]}>(),
  loadSecuritiesForPortfolioFailure: createAction(
    'LOAD_SECURITIES_FOR_PORTFOLIO_FAILURE'
  )(),
  /**
   * Add a security manually from the search selection, defaults to `null`
   * quantity and must be set to a value by the user to have any trades recommended
   */
  addSecurity: createAction('ADD_SECURITY')<ISearchResult>(),
  clearSecurities: createAction('CLEAR_SECURITIES')(),
  recommendTrades: createAction('RECOMMEND_TRADES')<{
    desiredAction: BULKUPDATEREQUEST_DESIRED_ACTION
    rebalanceRule: string // id or slug of rebalance rule
    asOfDate: string
    instruments: Array<{
      instrument: string // url
      lowerBound: number
      targetWeight: number
      marketPriceOverride: number
      upperBound: number
      allowTrading: boolean
      commission: number
      commissionType: COMMISSION_TYPE
    }>
  }>(),
  setDirectiveInputValue: createAction('SET_DIRECTIVE_INPUT_VALUE')<{
    item: StandardResponseItem
    category: IStandardTableCategory
    value: any
    key: any
  }>(),
  setInitialMarketPrices: createAction(
    'SET_INITIAL_MARKET_PRICES'
  )<IInitialMarketPrices>(),
  setCheckedSecurityUrls: createAction('SET_CHECKED_SECURITY_URLS')<string[]>(),
  loadAccountAllocationChart: createAction(
    'LOAD_ACCOUNT_ALLOCATION_CHART'
  )<IBulkRebalanceChartParams>(),
  loadAccountAllocationChartRequest: createAction(
    'LOAD_ACCOUNT_ALLOCATION_CHART_REQUEST'
  )(),
  loadAccountAllocationChartSuccess: createAction(
    'LOAD_ACCOUNT_ALLOCATION_CHART_SUCCESS'
  )<{response: StandardResponse; fxRates: IFxRate[]; baseCurrency: string}>(),
  loadAccountAllocationChartFailure: createAction(
    'LOAD_ACCOUNT_ALLOCATION_CHART_FAILURE'
  )<Error>(),
  /**
   * Updates the editable value in the state and request the chart data
   * from backend with updated values.
   */
  updateAccountAllocationChartValueRequest: createAction(
    'ON_ACCOUNT_ALLOCATION_CHART_CHANGE'
  )<{
    accountId: string
    categoryId: string
    value: number | null
    includePending: boolean
    includeCashEquivalents: boolean
    checkedAccountsIds: string[]
  }>(),
  updateAccountAllocationChart: createAction(
    'UPDATE_ACCOUNT_ALLOCATION_CHART'
  )<StandardResponse>()
}
