import React from 'react'

import FilterIcon from '@material-ui/icons/FilterList'

import {Flex, FlexChild} from '@d1g1t/shared/components/flex'
import {
  ToggleButton,
  ToggleSegment
} from '@d1g1t/shared/components/mui/toggle-button'
import {ToggleButtonGroup} from '@d1g1t/shared/components/mui/toggle-button-group'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {Span} from '@d1g1t/shared/components/typography'
import {
  SmallCircleIcon,
  SmallFullIcon
} from '@d1g1t/shared/containers/standard-table/components/custom-types/lib'

type Option = string | number

interface ICountToggleFilterProps<TOption extends Option = Option> {
  buttons: Array<{
    key: TOption
    label: string
    testId?: string
    iconColor?: string
    hideIcon?: boolean
  }>
  countsByKey: Record<TOption, number>
  state: TOption[]
  onChange(state: TOption[])
  testId?: string
}

const FONT_SIZE = 10

export const CountToggleFilter: <TOption extends Option>(
  props: ICountToggleFilterProps<TOption>
) => React.ReactElement<ICountToggleFilterProps<TOption>> =
  function CountToggleFilter<TOption extends Option = Option>({
    state,
    onChange,
    buttons,
    countsByKey,
    testId
  }) {
    return (
      <ToggleButtonGroup
        rounded
        data-testid={testId}
        value={state}
        onChange={(event, nextState: string[]) => {
          onChange(nextState as TOption[])
        }}
        size='small'
      >
        <ToggleSegment size='medium'>
          <FilterIcon />
        </ToggleSegment>
        {buttons.map(({key, label, iconColor, hideIcon, testId}, idx) => {
          return (
            <ToggleButton
              key={key}
              data-testid={testId}
              value={key}
              style={{
                // Fixes issue where the buttons can collapse slightly and cause
                // the text to wrap in odd ways
                whiteSpace: 'nowrap'
              }}
              data-dd-action={testId}
            >
              <ToggleButtonChildren
                text={label}
                count={countsByKey[key]}
                iconColor={iconColor}
                hideIcon={hideIcon}
              />
              {idx === buttons.length - 1 && <Spacer vertical xxs />}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
    )
  }

/**
 * Shows the text, icon, and count.
 */
const ToggleButtonChildren: React.FC<{
  text: string
  count?: number
  hideIcon?: boolean
  iconColor?: string
}> = (props) => {
  return (
    <Flex column>
      <FlexChild grow>
        <Span preventHighlight fontSize={FONT_SIZE}>
          {props.text}
        </Span>
      </FlexChild>

      <Flex grow alignCenter justifyCenter>
        {!props.hideIcon &&
          (props.iconColor ? (
            <SmallFullIcon size='large' color={props.iconColor} />
          ) : (
            <SmallCircleIcon size='large' />
          ))}
        <Spacer vertical custom={3} />
        <Span semiBold preventHighlight fontSize={FONT_SIZE}>
          {props.count}
        </Span>
      </Flex>
    </Flex>
  )
}
