import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './style.scss'

interface IFieldLabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  label: string
  children?: never
}

export const FieldLabel: React.SFC<IFieldLabelProps> = ({
  className,
  label,
  ...props
}) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className={classNames(css.fieldLabel, className)} {...props}>
    {label}
  </label>
)
