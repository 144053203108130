import {ITradingApprovalsInstrumentInput} from '@d1g1t/api/models'

import {StandardResponseItem} from '@d1g1t/lib/standard-response'

export interface IStandardResponseItemGrouped {
  [url: string]: (ITradingApprovalsInstrumentInput & {quantityChange: number})[]
}

/**
 * Converts array of standard items to object with account url
 * as the key, and array of all the trading approvals instrument
 * inputs with this account url as a value.
 * @param items - array of standard items
 */
export const groupByAccount = (
  items: StandardResponseItem[]
): IStandardResponseItemGrouped => {
  const result: IStandardResponseItemGrouped = {}
  for (const item of items) {
    const key = item.getValue('account_url')
    result[key] = (result[key] || []).concat({
      instrument: item.getValue('instrument_url'),
      quantityChange:
        item.getValue('operation') === 'Buy'
          ? item.getValue('quantity')
          : item.getValue('quantity') * -1,
      quantityType: item.getValue('quantity_type'),
      targetWeight: null
    })
  }
  return result
}
