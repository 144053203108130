import React from 'react'

import {useFormikContext} from 'formik'

import {FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE} from '@d1g1t/api/models'

import {Alert} from '@d1g1t/shared/components/mui/alert'
import {P} from '@d1g1t/shared/components/typography'

import {TradeStats} from '@d1g1t/advisor/components/trade-stats'

import {useSyncSelectEntitiesFormAccountUrl} from '../hook'
import {TradeOperationPanel} from '../trade-operation-panel'
import {EquitiesEtfsForm} from './form'
import {
  EQUITIES_ETFS_FIELD_NAMES,
  IBuySellEquitiesProps,
  IEquitiesEtfsFormValues
} from './typings'

export const BuySellEquities: React.FC<IBuySellEquitiesProps> = (props) => {
  // sync account id with form state (required for submission)

  useSyncSelectEntitiesFormAccountUrl(EQUITIES_ETFS_FIELD_NAMES.accountUrl)

  const {values} = useFormikContext<IEquitiesEtfsFormValues>()

  return (
    <>
      {!values.accountUrl && (
        <Alert severity='warning'>
          <P>Please select an account to create trade orders.</P>
        </Alert>
      )}
      {props.preTradeValidationMessages.length > 0 && (
        <Alert severity='error'>
          {props.preTradeValidationMessages.map((msg) => (
            <P key={msg}>{msg}.</P>
          ))}
        </Alert>
      )}
      <TradeOperationPanel
        data-testid='equities-etfs-trading'
        operation={values.operation}
      >
        <EquitiesEtfsForm isTradable={props.isTradable} />
        <TradeStats
          tradeType={FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY}
          headerTitle='Security Name'
          operation={values.operation}
          instrument={values.instrumentUrl}
          instrumentName={values.instrumentName}
          account={values.accountUrl}
          quantityChange={values.qty}
          qtyType={values.qtyType}
          commission={values.commission}
          commissionType={values.commissionType}
          limitPrice={values.limitPrice}
          type={values.type}
          isSellAll={values.isSellAll}
          expectedPrice={values.expectedPrice}
        />
      </TradeOperationPanel>
    </>
  )
}
