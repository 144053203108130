import React from 'react'

import {useFormikContext} from 'formik'

import {confirm} from '@d1g1t/shared/components/confirmation'
import {Flex} from '@d1g1t/shared/components/flex'
import {FormErrorCount} from '@d1g1t/shared/components/form-error-count'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {Text} from '@d1g1t/shared/components/typography'

export interface IFormActionsProps {
  /**
   * @defaultValue 'edit'
   * - Changes the label of the save button eg: Create/Creating... if type is `edit`
   * - Shows a 'Discard Changes' button if type is 'edit'
   * - Shows unsaved changes text if type is `edit` (can be disabled with `hideDirtyText` prop)
   */
  formType?: 'create' | 'edit'
  /**
   * Text to display if there are unsaved changes. Only displayed if `formType === 'edit'`
   */
  dirtyText?: string
  /**
   * If `formType === 'edit'` and the form has unsaved changes, it will not
   * show the unsaved changes text.
   */
  hideDirtyText?: boolean
  /**
   * If true, shows how many errors are in the form. This is useful for large forms,
   * where the user might need to scroll or open expansion panels to see errors.
   */
  showFormErrorCount?: boolean
  /**
   * If true, ignores whether field is touched for displaying error count
   */
  ignoreTouchedForErrorCount?: boolean
  enableSubmitWhenPristine?: boolean
  /**
   * Overrides submit button text. `default` is the regular text, and
   * `submitting` text is shown when the form is submitting.
   */
  submitButtonText?: {
    default: string
    submitting?: string
  }
  /**
   * When the prop is set to a function, a `Cancel` button will be shown,
   * and the callback will be called when the button is pressed.
   *
   * For 'edit' forms, this will replace the 'Discard Changes' reset button.
   */
  onCancel?(): void
  /**
   * `onDiscard` will be called after the Formik form reset function is called.
   */
  onDiscard?(): void
  /**
   * If `onCancel` prop is specified, customizes the cancel button text.
   */
  cancelButtonText?: string
  /**
   * If `true`, adds space between the buttons.
   */
  justifySpaceBetween?: boolean
  /**
   * If `true`, disables the submit button.
   */
  disableSubmit?: boolean
  /**
   * Values that FormAction will use as nextState together with initialValues
   * when resetting the form.
   * If not provided FormAction will use only initialValues
   */
  resetValues?: Object
}

/**
 * Displays a set of form actions, including:
 *
 * - A 'Submit' button. It will either read 'Save' or 'Create' depending on the `formType` prop.
 * - A 'Discard Changes' button for edit forms, which will reset the form.
 * - An optional `Cancel` button, which calls `onCancel` prop. This replaces the 'Discard Changes' button.
 * - Text to display number of errors.
 * - Text to indicate that there are unsaved changes (ie. the form is 'dirty').
 */
export const FormActions: React.FC<IFormActionsProps> = ({
  formType = 'edit',
  dirtyText = 'You have unsaved changes',
  hideDirtyText,
  enableSubmitWhenPristine,
  showFormErrorCount,
  ignoreTouchedForErrorCount,
  submitButtonText,
  cancelButtonText = 'Cancel',
  justifySpaceBetween,
  disableSubmit,
  onCancel,
  onDiscard,
  resetValues,
  children
}) => {
  const {dirty, isSubmitting, resetForm, initialValues} = useFormikContext()

  const handleReset = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    if (
      await confirm({
        title: 'Confirm Discard Changes',
        content: 'Do you want to discard all unsaved changes?',
        confirmLabel: 'Discard Changes'
      })
    ) {
      if (resetValues) {
        resetForm({
          values: Object.assign(initialValues, resetValues)
        })
      } else {
        resetForm()
      }

      if (onDiscard) {
        onDiscard()
      }
    }
  }

  const saveLabel = (() => {
    if (submitButtonText) {
      return isSubmitting && submitButtonText.submitting
        ? submitButtonText.submitting
        : submitButtonText.default
    }

    switch (formType) {
      case 'create':
        return isSubmitting ? 'Creating...' : 'Create'
      case 'edit':
      default:
        return isSubmitting ? 'Saving...' : 'Save'
    }
  })()

  const dirtyTextNode =
    (formType === 'edit' && dirty && !hideDirtyText && !isSubmitting && (
      <>
        <Text>{dirtyText}</Text>
        <Spacer vertical xs />
      </>
    )) ||
    null

  return (
    <Flex
      style={{height: '100%'}}
      alignCenter
      justifyFlexEnd={!justifySpaceBetween}
      justifySpaceBetween={justifySpaceBetween}
    >
      {showFormErrorCount ? (
        <>
          <Flex column alignEnd>
            {dirtyTextNode}
            {showFormErrorCount && (
              <FormErrorCount ignoreTouched={ignoreTouchedForErrorCount} />
            )}
          </Flex>
          <Spacer vertical xs />
        </>
      ) : (
        dirtyTextNode
      )}
      {onCancel && (
        <>
          <Button outlined onClick={onCancel} data-testid='button-cancel'>
            {cancelButtonText}
          </Button>

          <Spacer xs vertical />
        </>
      )}
      {!onCancel && (
        <>
          <Button
            type='reset'
            outlined
            disabled={!dirty}
            onClick={handleReset}
            data-testid='button-discard'
          >
            Discard Changes
          </Button>
          <Spacer xs vertical />
        </>
      )}
      {children}
      <Button
        primary
        contained
        type='submit'
        disabled={
          disableSubmit ||
          isSubmitting ||
          (formType === 'edit' && !dirty && !enableSubmitWhenPristine)
        }
        data-testid='button-submit'
      >
        {saveLabel}
      </Button>
    </Flex>
  )
}
