import React, {useEffect, useState} from 'react'

import {useApiQuery} from 'fairlight'

import SettingsIcon from '@material-ui/icons/Settings'
import TuneIcon from '@material-ui/icons/Tune'

import {RebalancingRuleEndpoints} from '@d1g1t/api/endpoints'

import {classNames} from '@d1g1t/lib/class-names'

import {ISelectProps} from '@d1g1t/shared/components/mui/select'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {
  IValueLabelSelectOption,
  ValueLabelSelect
} from '@d1g1t/shared/components/value-label-select'

import {ManageRebalanceRulesModal} from './manage-rebalance-rules-modal'

import * as css from './style.scss'

enum MANAGING_REBALANCE_RULES {
  CLOSED,
  LIST_OPEN,
  SINGLE_OPEN
}

interface IRebalanceRuleProps extends Pick<ISelectProps, 'size'> {
  /**
   * Disables the dropdown + gear.
   */
  disabled?: boolean
  /**
   * Used to disable the tooltip.
   * @defaultValue `false`
   */
  hideTooltip?: true
  /**
   * Displays an pre-defined icon on the left side of the component
   */
  showIcon?: boolean
  /**
   * Initially selected rebelance rule URL.
   */
  selectedRebalanceRuleUrl: string
  /**
   * Initializes dropdown with first rule selected
   */
  initializeWithFirstRule?: boolean
  /**
   * Trade directive page should not show Cash Management or Maximum Trade Size rules
   */
  isTradeDirectivePage?: boolean
  /**
   * Overrides the dropdown menu style
   */
  ValueLabelSelectStyle?: {
    width: string
  }
  /**
   * Fired when selected Rebalance Rule is changed.
   */
  onChange(
    /**
     * Newly selected rebalance rule URL.
     */
    value: string
  ): void
}

export const RebalanceRules: React.FC<IRebalanceRuleProps> = (props) => {
  const [rebalancingRules] = useApiQuery(RebalancingRuleEndpoints.list(), {
    fetchPolicy: 'cache-and-fetch'
  })

  // Manage Rebalance Rules modal
  const [managingRebalanceRules, setManagingRebalanceRules] = useState(
    MANAGING_REBALANCE_RULES.CLOSED
  )

  const MANAGE_REBALANCE_RULES_OPTION: IValueLabelSelectOption = {
    icon: <SettingsIcon />,
    label: 'Manage Rules',
    value: '__MANAGE_RULES__'
  }

  useEffect(() => {
    if (
      props.initializeWithFirstRule &&
      rebalancingRules.data &&
      rebalancingRules.data.results.length &&
      !props.selectedRebalanceRuleUrl
    ) {
      props.onChange(rebalancingRules.data.results[0].url)
    }
  })

  if (rebalancingRules.loading) {
    return null
  }

  return (
    <>
      <Tooltip
        title='Rebalancing Rules'
        placement='top'
        open={props.hideTooltip ? false : undefined}
      >
        <div
          className={classNames(css.inputWrapper, css.whiteBackground)}
          onClick={(e) => {
            // to avoid triggering the panel expansion and collapse of the account table
            e.stopPropagation()
          }}
        >
          <ValueLabelSelect
            whiteBackground
            fullWidth
            spaceRight
            id='rebalance-rule'
            name='rebalanceRule'
            size={props.size || 'small'}
            style={{height: '100%', ...props.ValueLabelSelectStyle}}
            disabled={props.disabled}
            onChange={(value: string, child, event) => {
              event.stopPropagation()

              if (value === MANAGE_REBALANCE_RULES_OPTION.value) {
                setManagingRebalanceRules(MANAGING_REBALANCE_RULES.LIST_OPEN)

                return
              }

              props.onChange(value)
            }}
            value={props.selectedRebalanceRuleUrl || ''}
            options={(rebalancingRules.data?.results || [])
              .map<IValueLabelSelectOption>((rebalancingRule) => ({
                label: rebalancingRule.name,
                value: rebalancingRule.url
              }))
              .concat(MANAGE_REBALANCE_RULES_OPTION)}
            dividerIndexes={
              rebalancingRules.data?.results.length > 0
                ? [rebalancingRules.data?.results.length]
                : undefined
            }
            startAdornment={props.showIcon && <TuneIcon />}
          />
        </div>
      </Tooltip>

      {!!managingRebalanceRules && (
        <ManageRebalanceRulesModal
          isTradeDirectivePage={props.isTradeDirectivePage}
          initialSelectedRuleUrl={
            /**
             * only pass selectedRuleUrl when opening with gear icon
             */
            managingRebalanceRules === MANAGING_REBALANCE_RULES.SINGLE_OPEN &&
            props.selectedRebalanceRuleUrl
          }
          onClose={() =>
            setManagingRebalanceRules(MANAGING_REBALANCE_RULES.CLOSED)
          }
          onChangeSelectedRuleUrl={(url) => {
            props.onChange(url)
          }}
        />
      )}
    </>
  )
}
