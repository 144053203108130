import React from 'react'

import {merge} from 'lodash'

import {BarChart} from '@d1g1t/shared/components/charts/bar-chart'
import {useNumberFormatter} from '@d1g1t/shared/wrappers/formatter'

import {withStandardChart} from '../'
import {IChartProps, IStandardChartProps} from '../typings'

const DriftStandardChart: React.SFC<IChartProps> = React.memo((props) => {
  const percentageFormatter = useNumberFormatter({
    style: 'percent',
    allowZero: true,
    decimalPlaces: 2
  })

  const defaultDriftConfig: Highcharts.Options = {
    legend: {
      enabled: false
    },
    xAxis: {
      title: {
        text: null
      },
      type: 'category'
    },
    yAxis: {
      labels: {
        format: '{value} %'
      },
      title: {
        text: null
      }
    },
    plotOptions: {
      series: {
        tooltip: {
          pointFormatter() {
            const stringValue = percentageFormatter.format(this.y / 100)

            return `<span style="color:${this.color}">\u25CF</span> ${this.name}: <b>${stringValue}</b><br/>`
          }
        },
        colorByPoint: true
        // color: green800,
        // negativeColor: deepOrangeA700
      } as any
    }
  }

  return (
    <BarChart
      config={merge(
        {
          series: props.series
        },
        defaultDriftConfig,
        props.config
      )}
    />
  )
})

export const DriftChart = withStandardChart(
  DriftStandardChart
) as React.ComponentClass<IStandardChartProps>
