import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './style.scss'

interface ISearchChild extends React.HTMLAttributes<HTMLDivElement> {
  text?: never
  onClear?: never
  pointer?: boolean
  hoverable?: boolean
  top?: boolean
}

export const SearchChild: React.SFC<ISearchChild> = ({
  text,
  onClear,
  className,
  pointer,
  hoverable,
  top,
  ...props
}) => (
  <div
    className={classNames(css.divider, className, {
      [css.top]: top,
      [css.pointer]: pointer,
      [css.hoverable]: hoverable
    })}
    {...props}
  />
)
