import {useMemo, useState} from 'react'

import {omit} from 'lodash'

import {
  IMarketPriceHistoryChartTableBody,
  MarketPriceEndpoints
} from '@d1g1t/api/endpoints'
import {MARKETPRICE_SOURCE} from '@d1g1t/api/models'

import {IDateRange} from '@d1g1t/lib/date-range'
import {useToggleState} from '@d1g1t/lib/hooks'
import {StandardResponse} from '@d1g1t/lib/standard-response'

import {UI_DATE_RANGES} from '@d1g1t/shared/components/range-selector/typings'
import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {useExportExcel} from '@d1g1t/shared/wrappers/export-excel'
import {useStandardResponseQuery} from '@d1g1t/shared/wrappers/standard-response-query'

import {useCalculationOptionsOverride} from '../calculation-options'

/**
 * Hook to fetch market price history data
 * @param securityId - entity id of security that needs markets price history data
 * @param dateRange - date range of data eg/ 1 month, 1 year etc.
 * @param source - data source of data eg. custodian or firm
 */
export function useMarketPriceHistory(
  securityId: string,
  dateRange: IDateRange,
  source: MARKETPRICE_SOURCE
): LoadableHook<
  StandardResponse,
  {
    exportExcel: () => void
    refresh: () => void
    temporaryUpdateMarketPrices: (marketPrices: StandardResponse) => void
  }
> {
  const [calculationSettings] = useCalculationSettings()
  const [calculationOptions] = useCalculationOptionsOverride()
  const requestBody: IMarketPriceHistoryChartTableBody = useMemo(
    () => ({
      control: {
        selectedEntities: {
          instrument: securityId
        }
      },
      settings: omit(calculationSettings, 'date'),
      options: {
        singleResult: true,
        investmentMandateAccounts: false,
        compare: false,
        portfolioRebalancing: false,
        dateRange:
          dateRange.value === UI_DATE_RANGES.PAGE_PERIOD
            ? calculationOptions?.pageLevelDateRange
            : dateRange,
        source
      }
    }),
    [
      securityId,
      dateRange,
      source,
      calculationSettings,
      calculationOptions?.pageLevelDateRange
    ]
  )

  const [marketPriceHistory, marketPriceHistoryQueryActions] =
    useStandardResponseQuery(
      securityId && MarketPriceEndpoints.chart(requestBody)
    )

  const exportExcel = useExportExcel(
    MarketPriceEndpoints.getExcel(requestBody),
    'marketprices-chart'
  )

  return [
    marketPriceHistory,
    {
      exportExcel,
      refresh: marketPriceHistoryQueryActions.refetch,
      temporaryUpdateMarketPrices: marketPriceHistoryQueryActions.setData
    }
  ]
}

interface IMarketPriceHistoryState {
  marketPriceHistoryOpen: boolean
  securityId: string
  securityName: string
  /**
   * Indicates the modal should open in edit mode.
   */
  openInEditMode: boolean
}

interface IMarketPriceHistoryActions {
  showMarketPriceHistory(
    securityId: string,
    securityName: string,
    options?: {
      /**
       * When passed, the modal will open in edit market price mode.
       */
      editMode?: boolean
    }
  ): void
  hideMarketPriceHistory(): void
}

export function useMarketPriceHistoryModalControl(): [
  IMarketPriceHistoryState,
  IMarketPriceHistoryActions
] {
  const [securityId, setSecurityId] = useState('')
  const [securityName, setSecurityName] = useState('')
  const [openInEditMode, setOpenInEditMode] = useState(false)

  const [
    marketPriceHistoryOpen,
    ,
    showMarketPriceHistory,
    hideMarketPriceHistory
  ] = useToggleState(false)

  return [
    {marketPriceHistoryOpen, securityId, securityName, openInEditMode},
    {
      showMarketPriceHistory: (
        securityId: string,
        securityName: string,
        options: {
          /**
           * Indicates the modal should open in edit mode.
           */
          editMode: boolean
        } = {editMode: false}
      ) => {
        setSecurityId(securityId)
        setSecurityName(securityName)
        setOpenInEditMode(options.editMode)
        showMarketPriceHistory()
      },
      hideMarketPriceHistory: () => {
        hideMarketPriceHistory()
        setSecurityId('')
        setSecurityName('')
        setOpenInEditMode(false)
      }
    }
  ]
}
