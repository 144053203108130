import * as Yup from 'yup'

import {IModelPortfolioFormValues} from './typings'

export const TABLE_ID = 'model-portfolio-holdings'
export const PREFIX = 'EDIT-HOLDINGS'

export enum ACTION_TYPES {
  SET_TARGET_VALUES = 'SET_TARGET_VALUES'
}

export enum MODEL_PORTFOLIO_CATEGORY_IDS {
  NAME = 'name',
  SYMBOL = 'symbol',
  CURRENT_DRIFT = 'current_drift',
  CURRENT_VALUE = 'current_value',
  CURRENT_WEIGHT = 'current_weight',
  HAS_DRAFT = 'has_draft',
  INSTRUMENT_URL = 'instrument_url',
  MARKET_PRICE = 'market_price',
  MAX_BOUND = 'upper_limit',
  MIN_BOUND = 'lower_limit',
  MIN_THRESHOLD = 'min_threshold',
  MAX_THRESHOLD = 'max_threshold',
  QUANTITY = 'quantity',
  TARGET_DRIFT = 'target_drift',
  TARGET_VALUE = 'target_value',
  TARGET_WEIGHT = 'target_weight',
  TRADING_CURRENCY = 'trading_currency',
  VALUE_CHANGE = 'value_change',
  WEIGHT_CHANGE = 'weight_change',
  TARGET_MIN_WEIGHT = 'target_min_weight',
  TARGET_MAX_WEIGHT = 'target_max_weight',
  CURRENT_MIN_WEIGHT = 'current_min_weight',
  CURRENT_MAX_WEIGHT = 'current_max_weight'
}

export enum FORM_FIELDS {
  name = 'name',
  firmProvidedKey = 'firmProvidedKey',
  currency = 'currency',
  targetValues = 'targetValues',
  scaleDownWeightByCash = 'scaleDownByCashTarget',
  removedPositions = 'removedPositions'
}

export const FORM_VALIDATION_SCHEMA = Yup.object<IModelPortfolioFormValues>({
  [FORM_FIELDS.name]: Yup.string().required().label('Security Model name'),
  [FORM_FIELDS.firmProvidedKey]: Yup.string()
    .required()
    .label('Security Model ID')
})
