import React, {useState} from 'react'

import {find, map, max, remove} from 'lodash'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import LockIcon from '@material-ui/icons/Lock'
import WebIcon from '@material-ui/icons/Web'

import {IUserView} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {confirm} from '@d1g1t/shared/components/confirmation'
import {DraggableList} from '@d1g1t/shared/components/draggable-list'
import {Flex} from '@d1g1t/shared/components/flex'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {P, Text} from '@d1g1t/shared/components/typography'
import {IViewListItem} from '@d1g1t/shared/containers/view-options/typings'
import {usePermissions} from '@d1g1t/shared/wrappers/permissions'

import {ViewIcons} from '../view-icons'

import * as css from '../../styles.scss'

interface IDraggableItem {
  id: number
  child: React.ReactElement<any>
}

interface IViewsListProps {
  viewCountExceeded?: boolean
  viewList: IUserView[]
  onReorder(orderedSelectedItems: IViewListItem[]): void
  createDuplicateView(view: IUserView): Promise<IUserView>
  removeView(view: IUserView): void
  onViewClick(viewId: string): void
}

/**
 * List of views component, rendered inside the "Manage Views" modal.
 */
export const ViewsList: React.FC<IViewsListProps> = function ViewsList(props) {
  const permissions = usePermissions()

  const [viewList, setViewList] = useState<IViewListItem[]>(() =>
    props.viewList
      ? map(
          props.viewList,
          (model, index): IViewListItem => ({
            model,
            id: index
          })
        )
      : []
  )

  const removeItem = (removedId: number) => {
    try {
      props.removeView(find<IViewListItem>(viewList, {id: removedId}).model)
      setViewList(remove(viewList, (view) => view.id !== removedId))
    } catch {}
  }

  const createDuplicateItem = async (duplicatedId: number) => {
    try {
      const view = await props.createDuplicateView(
        find<IViewListItem>(viewList, {id: duplicatedId}).model
      )
      const index = max(map(viewList, 'id')) + 1
      viewList.push({model: view, id: index})
      setViewList(viewList)
    } catch {}
  }

  const handleReorder = (itemsReordered: IDraggableItem[]) => {
    const items = viewList.reduce(
      (result, next) => ({
        ...result,
        [next.id]: next
      }),
      {}
    )

    const orderedSelectedItems = itemsReordered.map((item) => items[item.id])

    setViewList(orderedSelectedItems)

    props.onReorder(orderedSelectedItems)
  }

  const onViewClick = (clickedIndex: number) => {
    props.onViewClick(extractIdFromUrl(viewList[clickedIndex].model.url))
  }

  const views = (): IDraggableItem[] => {
    const items: {[id: number]: IViewListItem} = viewList.reduce(
      (result, next) => ({
        ...result,
        [next.id]: next
      }),
      {}
    )

    return viewList.map(({id}, i) => {
      const isGlobalView = items[id].model.isGlobal
      const isNonGlobalViewOrIsAdmin =
        permissions.isAdministrator() || !isGlobalView

      return {
        id,
        disableDrag: isGlobalView,
        child: (
          <Flex
            alignCenter
            justifySpaceBetween
            grow
            key={id}
            className={css.viewListItem}
            data-testid='list-view-item'
          >
            <Flex>
              <WebIcon style={{marginRight: '0.4rem'}} />
              <Text>{items[id].model.name}</Text>
              <ViewIcons view={items[id].model} />
            </Flex>
            <Flex className={css.viewListControls}>
              <Tooltip
                placement='top'
                title={
                  isNonGlobalViewOrIsAdmin ? 'Edit view' : 'Read-Only view'
                }
              >
                <div>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation()
                      onViewClick(id)
                    }}
                    disabled={!isNonGlobalViewOrIsAdmin}
                    data-testid='button-edit-view'
                  >
                    {isNonGlobalViewOrIsAdmin ? <EditIcon /> : <LockIcon />}
                  </IconButton>
                </div>
              </Tooltip>
              {!props.viewCountExceeded && (
                <Tooltip placement='top' title='Duplicate view'>
                  <div>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation()
                        createDuplicateItem(id)
                      }}
                      data-testid='button-duplicate-view'
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
              {isNonGlobalViewOrIsAdmin && (
                <Tooltip placement='top' title='Delete view'>
                  <div>
                    <IconButton
                      onClick={async (event) => {
                        event.stopPropagation()
                        if (
                          !(await confirm({
                            title: 'Remove View',
                            content:
                              'Do you want to permanently remove this view? This action cannot be undone.',
                            confirmLabel: 'Remove View'
                          }))
                        ) {
                          return
                        }
                        removeItem(id)
                      }}
                      data-testid='button-delete-view'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        )
      }
    })
  }

  return (
    <div>
      <DraggableList
        items={views()}
        onReorderDrop={handleReorder}
        emptyText={<P indent={2}>You haven't created a view yet.</P>}
      />
    </div>
  )
}
