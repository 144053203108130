import React from 'react'

import {SvgIconProps} from '@material-ui/core/SvgIcon'
import DescriptionIcon from '@material-ui/icons/Description'
import DomainIcon from '@material-ui/icons/Domain'
import HomeIcon from '@material-ui/icons/Home'
import PersonIcon from '@material-ui/icons/Person'

import {ALL_MODELS} from '@d1g1t/api/models'

import {FoundationIcon} from '@d1g1t/shared/components/icons/foundation'
import {TrustIcon} from '@d1g1t/shared/components/icons/trust'
import {Spacer} from '@d1g1t/shared/components/spacer'

interface INameCellIconProps {
  model: ALL_MODELS
}

function getSvgIcon(model: ALL_MODELS): React.ComponentType<SvgIconProps> {
  switch (model) {
    case ALL_MODELS.HOUSEHOLD:
      return HomeIcon
    case ALL_MODELS.PERSON:
    case ALL_MODELS.INDIVIDUAL:
      return PersonIcon
    case ALL_MODELS.CORPORATION:
      return DomainIcon
    case ALL_MODELS.TRUST:
      return TrustIcon
    case ALL_MODELS.FOUNDATION:
      return FoundationIcon
    case ALL_MODELS.ACCOUNT:
      return DescriptionIcon
    default:
      return null
  }
}

export const NameCellIcon: React.FC<INameCellIconProps> = ({model}) => {
  const Icon = getSvgIcon(model)

  if (!Icon) {
    return null
  }

  return (
    <>
      <Icon fontSize='small' />
      <Spacer vertical xxs />
    </>
  )
}
