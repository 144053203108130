import React from 'react'

import {TRADEORDER_TYPE} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'

import {EditableCell} from '@d1g1t/shared/containers/standard-table/components/custom-types/editable-cell'
import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'

import {CATEGORY_IDS} from '@d1g1t/advisor/containers/trade-preview/constants'

import * as dataCellCss from '@d1g1t/shared/containers/standard-table/components/data-cell/styles.scss'
import * as commonCss from '@d1g1t/shared/containers/standard-table/styles.scss'

export const LimitPriceCell: React.FC<IDataCellProps> = (props) => {
  const isMarketOrder =
    props.item.getClosestKey(CATEGORY_IDS.ORDER_TYPE) ===
      TRADEORDER_TYPE.MARKET ||
    props.item.getClosestValue(CATEGORY_IDS.ORDER_TYPE) ===
      TRADEORDER_TYPE.MARKET

  if (isMarketOrder) {
    return null
  }

  const cellData = props.item.getDatum(props.category.id)
  const cellValue = props.item.getClosestValue(props.category.id)

  // Checks whether the cell, item row or category has
  // editable set to true
  if (
    !(
      cellData.options?.editable ||
      props.category.options?.editable ||
      props.item.options?.editable
    )
  ) {
    return (
      <div className={classNames(commonCss.innerCell, dataCellCss.number)}>
        {cellValue}
      </div>
    )
  }

  return <EditableCell disabled={isMarketOrder} value={cellValue} {...props} />
}
