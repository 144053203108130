import {useEffect, useMemo, useState} from 'react'

import {isEqual} from 'lodash'

import {IChartTableCategory} from '@d1g1t/api/models'

import {usePrevious} from '@d1g1t/lib/hooks'

import {useGlobalSettings} from '@d1g1t/shared/wrappers/global-settings'

import {initializeExpandedCategoryIds} from './lib'

const DEFAULT_STATE = {
  saved: false,
  expandedCategories: [] as string[]
}

const EMPTY_SET = new Set<string>()

function getExpansionCategories(
  categories: IChartTableCategory[]
): IChartTableCategory[] {
  const expansionCategories = []

  for (const category of categories) {
    if (category.options?.hidden) {
      continue
    }

    if (category.categories) {
      expansionCategories.push(
        category,
        ...getExpansionCategories(category.categories)
      )
    }
  }

  return expansionCategories
}

/**
 * Returns a state and a setter function to keep track of expanded/collapsed columns.
 * If `id` is provided, it will likely return global-settings based state and setter.
 * If `id` is NOT provided, it will return local-state based state and setter.
 * If `initializeColumnsFullyExpanded` is a requirement, it will always return local-state based state and setter.
 *
 * @param id - ID of the standard table component
 * @param categories - list of categories to keep track of as expanded/collapsed
 * @param initializeColumnsFullyExpanded - boolean or function to determine which columns (or all cols if bool) to fully expanded at start
 *
 * @returns a state and a setter function to keep track of expanded/collapsed columns.
 */
export function useColumnExpansionControl(
  id: string,
  categories: IChartTableCategory[],
  initializeColumnsFullyExpanded:
    | boolean
    | ((category: IChartTableCategory) => boolean)
) {
  const allExpansionCategories = useMemo(() => {
    return getExpansionCategories(categories)
  }, [categories])
  const prevExpansionCategories = usePrevious(allExpansionCategories)

  const [localState, setLocalState] = useState(DEFAULT_STATE)

  const [globalSettingsState, {updateGlobalSettings}] = useGlobalSettings(
    `TABLE-EXPANDED-COLUMNS-${id}`,
    DEFAULT_STATE,
    [id]
  )

  const usingGlobalSettingsState = id && !initializeColumnsFullyExpanded

  const state = usingGlobalSettingsState ? globalSettingsState : localState

  useEffect(() => {
    if (allExpansionCategories.length === 0 || !state) {
      return
    }

    const newExpandedCategories = initializeExpandedCategoryIds(
      initializeColumnsFullyExpanded,
      allExpansionCategories,
      prevExpansionCategories,
      state.expandedCategories || []
    )

    if (isEqual(state.expandedCategories, newExpandedCategories)) {
      return
    }

    if (usingGlobalSettingsState) {
      updateGlobalSettings({
        saved: false,
        expandedCategories: newExpandedCategories
      })
    } else {
      setLocalState({
        saved: false,
        expandedCategories: newExpandedCategories
      })
    }
  }, [allExpansionCategories, !!state])

  const expandedCategories = useMemo(() => {
    if (!state) {
      return null
    }

    if (allExpansionCategories.length === 0) {
      return EMPTY_SET
    }

    // Remove IDs not present in the current expansion set which be saved
    // from other views sharing this table ID
    const allExpansionCategoriesIds = allExpansionCategories.map(
      (category) => category.id
    )
    const filteredExpandedCategories = state.expandedCategories.filter((id) =>
      allExpansionCategoriesIds.includes(id)
    )

    return new Set(filteredExpandedCategories.sort())
  }, [state])

  const toggleExpandedCategory = (categoryId: string) => {
    if (allExpansionCategories.length === 0 || !state) {
      return
    }

    if (expandedCategories.has(categoryId)) {
      expandedCategories.delete(categoryId)
    } else {
      expandedCategories.add(categoryId)
    }

    const nextValue = Array.from(expandedCategories)

    if (usingGlobalSettingsState) {
      updateGlobalSettings({
        saved: true,
        expandedCategories: nextValue
      })
    } else {
      setLocalState({
        saved: true,
        expandedCategories: nextValue
      })
    }
  }

  return {
    expandedCategories,
    toggleExpandedCategory
  }
}
