import {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from 'react'

import produce from 'immer'
import {isEmpty} from 'lodash'
import {useRecoilState} from 'recoil'

import {useIsFirstRender, usePrevious, useToggleState} from '@d1g1t/lib/hooks'

import {columnFilterAtom} from './atoms'

export interface IColumnFilterState {
  [categoryId: string]: string | any[]
}

/**
 * Creates control state which can be passed directly to `<StandardTable>` for
 * column filters
 * @param viewKey - optional: the viewKey for the currently selected view. the filters will reset
 * if this changes
 */
export function useTableColumnFilterControl(viewKey?: string) {
  const [columnFilterState, setColumnFilterState] =
    useState<IColumnFilterState>(EMPTY_OBJECT)

  const [showColumnFilter, toggleShowColumnFilter] = useToggleState(false)

  /**
   * Updates the filter string for a column
   */
  const handleSetColumnFilterState =
    (key: string, enumerator: boolean) =>
    (eventOrValue: React.ChangeEvent<HTMLInputElement> | any[]) => {
      setColumnFilterState(
        produce(columnFilterState, (draft) => {
          draft[key] = enumerator
            ? (eventOrValue as any[])
            : (eventOrValue as React.ChangeEvent<HTMLInputElement>).target.value
        })
      )
    }

  /**
   * Resets filters to an empty object
   */
  const clearColumnFilter = () => {
    if (columnFilterState !== EMPTY_OBJECT) {
      setColumnFilterState(EMPTY_OBJECT)
    }
  }

  /**
   * Clears filters when the viewKey changes
   */
  useEffect(() => {
    clearColumnFilter()
  }, [viewKey])

  return {
    columnFilterState,
    overwriteColumnFilterState: setColumnFilterState,
    setColumnFilterState: handleSetColumnFilterState,
    clearColumnFilter,
    showColumnFilter,
    toggleShowColumnFilter
  }
}

/**
 * ***IMPORTANT NOTE***: This will store the column filter in global recoil state
 * and should only be used if column filter needs to remembered between route
 * changes.
 *
 * Creates control state which can be passed directly to `<StandardTable>` for
 * column filters
 * @param id - id of standard table to derive its column filter state
 * prefer to use same standard table id constant here
 * @param viewKey - optional: the viewKey for the currently selected view. the filters will reset
 * if this changes
 */
export function useTableColumnFilterControlRecoil(
  id: string,
  viewKey?: string
) {
  const [columnFilterState, setColumnFilterState] =
    useRecoilState(columnFilterAtom)

  const previousId = usePrevious(id)
  const isFirstRender = useIsFirstRender()

  const handleOverwriteColumnFilterState = (
    newColumnFilterState: IColumnFilterState,
    toggleOffColumnFilter?: boolean
  ) => {
    setColumnFilterState(
      produce(columnFilterState, (draft) => {
        draft[id].state = newColumnFilterState

        if (toggleOffColumnFilter) {
          draft[id].showColumnFilter = false
        }
      })
    )
  }

  /**
   * Updates the filter string for a column
   */
  const setColumnFilterStateAtKey =
    (key: string, enumerator: boolean) =>
    (eventOrValue: React.ChangeEvent<HTMLInputElement> | any[]) => {
      setColumnFilterState(
        produce(columnFilterState, (draft) => {
          draft[id].state[key] = enumerator
            ? (eventOrValue as any[])
            : (eventOrValue as React.ChangeEvent<HTMLInputElement>).target.value
        })
      )
    }

  /**
   * Resets filters to an empty object
   */
  const clearFilters = () => {
    if (columnFilterState[id] && !isEmpty(columnFilterState[id].state)) {
      setColumnFilterState(
        produce(columnFilterState, (draft) => {
          draft[id].state = EMPTY_OBJECT
        })
      )
    }
  }

  const toggleShowColumnFilter = () => {
    setColumnFilterState(
      produce(columnFilterState, (draft) => {
        // Current id's column filter needs to be initialized
        if (!columnFilterState[id]) {
          draft[id] = {showColumnFilter: true, state: EMPTY_OBJECT}

          return
        }

        draft[id].showColumnFilter = !columnFilterState[id].showColumnFilter
      })
    )
  }

  /**
   * Clears filters when the viewKey changes
   */
  useEffect(() => {
    // Added to ensure column filter is not reset when first loading the component.
    // When component is first loaded `viewKey` is `undefined` then becomes a value after the view has loaded.
    // `previousId === id` added as a check as sometimes hook is not on its first render but the id may have changed.
    // This prevent filters from being cleared when changing to a new table where the `viewKey` will be different.
    // (e.g. Switching from Client Portfolios and Account Holdings)
    if (!isFirstRender && previousId === id) {
      clearFilters()
    }
  }, [viewKey])

  return {
    columnFilterState: columnFilterState[id]?.state,
    overwriteColumnFilterState: handleOverwriteColumnFilterState,
    setColumnFilterStateAtKey,
    clearFilters,
    showColumnFilter: columnFilterState[id]?.showColumnFilter,
    toggleShowColumnFilter
  }
}

export function useIntermediateColumnFilterControl(
  initialState: IColumnFilterState
): [
  IColumnFilterState,
  (
    key: string,
    enumerator: boolean
  ) => (eventOrValue: any[] | ChangeEvent<HTMLInputElement>) => void,
  Dispatch<SetStateAction<IColumnFilterState>>
] {
  const [intermediateColumnFilterState, setIntermediateColumnFilterState] =
    useState(initialState)

  const handleSetIntermediateColumnFilterState =
    (key: string, enumerator: boolean) =>
    (eventOrValue: React.ChangeEvent<HTMLInputElement> | any[]) => {
      setIntermediateColumnFilterState(
        produce(intermediateColumnFilterState, (draft) => {
          draft[key] = enumerator
            ? (eventOrValue as any[])
            : (eventOrValue as React.ChangeEvent<HTMLInputElement>).target.value
        })
      )
    }

  return [
    intermediateColumnFilterState,
    handleSetIntermediateColumnFilterState,
    setIntermediateColumnFilterState
  ]
}
