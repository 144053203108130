import React from 'react'

import {useApiQuery} from 'fairlight'

import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'

import {EntityEndpoints} from '@d1g1t/api/endpoints'
import {IEntityRelationsResponse} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'

import {ComboKeyListener} from '@d1g1t/shared/components/combo-key-listener'
import {Flex} from '@d1g1t/shared/components/flex'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {H1, H4, P} from '@d1g1t/shared/components/typography'
import {ISearchResult} from '@d1g1t/shared/containers/search'
import {ISelectedEntity} from '@d1g1t/shared/containers/select-entities'

import * as css from './style.scss'

export interface ISelectEntitiesLeftDrawerProps {
  title: string
  hintText: string
  /**
   * These should be the entity chips that are normally rendered by the SelectEntities Bar component.
   */
  rows: Omit<ISelectEntitiesLeftDrawerRowProps, 'entityRelationsResults'>[]
  onClose(): void
}

export const SelectEntitiesLeftDrawer: React.FC<
  ISelectEntitiesLeftDrawerProps
> = (props) => {
  // Request is made several times to get names for single account rows in
  // left drawer. This bulk request is used above to get all the data required
  // in one request.
  const [entityRelationsResults] = useApiQuery(
    EntityEndpoints.entitiesRelationships({
      entities: props.rows
        .filter(({selected}) => selected.accounts?.length === 1)
        .map(({selected}) => selected.entityId)
    })
  )

  const selectedIndex = props.rows.findIndex((row) => row.focused)

  return (
    <div className={css.leftDrawer}>
      <Flex justifySpaceBetween>
        <H1 className={css.contentPadding}>{props.title}</H1>
        <div>
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </Flex>
      <P className={css.contentPadding}>{props.hintText}</P>
      <div className={css.rowsBox}>
        <LoadingContainer loading={entityRelationsResults.loading}>
          {selectedIndex > 0 && (
            <ComboKeyListener
              combo
              leaderKey='shift'
              actionKey='left'
              onAction={() => {
                props.rows[selectedIndex - 1].onClick(
                  props.rows[selectedIndex - 1].onFocusEntity
                )
              }}
            />
          )}
          {entityRelationsResults.data &&
            props.rows.map((row, index) => (
              <SelectEntitiesLeftDrawerRow
                key={index}
                entityRelationsResults={
                  entityRelationsResults.data[row.selected.entityId]
                }
                {...row}
              />
            ))}
          {selectedIndex < props.rows.length - 1 && (
            <ComboKeyListener
              combo
              leaderKey='shift'
              actionKey='right'
              onAction={() => {
                props.rows[selectedIndex + 1].onClick(
                  props.rows[selectedIndex + 1].onFocusEntity
                )
              }}
            />
          )}
        </LoadingContainer>
      </div>
    </div>
  )
}

interface ISelectEntitiesLeftDrawerRowProps {
  entitySearchResult: ISearchResult<any>
  selected?: ISelectedEntity
  focused: boolean
  entityRelationsResults: IEntityRelationsResponse
  onFocusEntity(): void
  onClick(onFocusEntity: () => void): void
}

const SelectEntitiesLeftDrawerRow: React.FC<
  ISelectEntitiesLeftDrawerRowProps
> = (props) => {
  const entityDisplayName = (() => {
    if (props.selected.accounts) {
      if (props.selected.accounts.length === 1) {
        const accountDisplayName = props.entityRelationsResults?.accounts.find(
          (account) => account.entityId === props.selected.accounts[0]
        )?.name

        if (accountDisplayName) {
          return accountDisplayName
        }

        const custodianAccountDisplayName =
          props.entityRelationsResults.custodianAccounts.find(
            (account) => account.ownerEntityId === props.selected.accounts[0]
          )?.name

        if (custodianAccountDisplayName) {
          return custodianAccountDisplayName
        }
      }
      return `${
        props.entitySearchResult.printName || props.entitySearchResult.name
      } (${props.selected.accounts.length} accounts)`
    }
    return props.entitySearchResult.printName || props.entitySearchResult.name
  })()

  const handleClick = () => {
    if (props.focused) {
      return
    }
    if (!props.onClick) {
      props.onFocusEntity()
      return
    }
    props.onClick(props.onFocusEntity)
  }

  return (
    <Flex
      alignCenter
      onClick={handleClick}
      className={classNames(css.row, {
        [css.focused]: props.focused
      })}
    >
      <Flex grow>
        {entityDisplayName ? (
          <H4 normalWeight={!props.focused}>{entityDisplayName}</H4>
        ) : (
          <CircularProgress size={18} thickness={2} />
        )}
      </Flex>
      {props.focused && <div className={css.focusedRowArrow} />}
    </Flex>
  )
}
