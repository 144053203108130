import React from 'react'

import MuiPaper, {PaperProps} from '@material-ui/core/Paper'

export interface IPaperProps extends PaperProps {
  ref?: React.Ref<HTMLDivElement>
}

export const Paper: React.FC<IPaperProps> = React.forwardRef((props, ref) => (
  <MuiPaper ref={ref} {...props} />
))
