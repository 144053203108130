import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    actionIcon: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        transition: 'none'
      }
    }
  }
})
