import React from 'react'

import CloseIcon from '@material-ui/icons/Close'
import FilterListIcon from '@material-ui/icons/FilterList'
import GroupIcon from '@material-ui/icons/Group'

import {
  ALL_MODELS,
  IEntityRelation,
  IEntityRelationAccount
} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'

import {Flex} from '@d1g1t/shared/components/flex'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {ListItemIcon} from '@d1g1t/shared/components/mui/list-item-icon'
import {IMenuItemProps, MenuItem} from '@d1g1t/shared/components/mui/menu-item'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {NestedMenuItem} from '@d1g1t/shared/components/nested-menu-item'
import {parseEntityName} from '@d1g1t/shared/containers/select-entities/components/entity-chip/lib'

import {useEntityChipContext} from '../..'
import {IEntityChipProps} from '../../typings'
import {AccountFiltersMenu} from '../dropdown-menu/components/accounts-filter-submenu'

import * as css from './style.scss'

interface IDropdownItemProps
  extends Omit<IMenuItemProps, 'onClick'>,
    Pick<IEntityChipProps, 'singleAccount'> {
  entityRelation: IEntityRelation
  /**
   * Offers a submenu to quickly filter accounts of a client.
   */
  allAccountRelations?: IEntityRelationAccount[]
  /**
   * Highlights the item if true.
   */
  selected: boolean
  /**
   * Shown under the selected item.
   */
  holdingsCountSuffix?: string
  /**
   * Removes 'Filter holdings' and the filter icon.
   */
  disableHoldingsFiltering: boolean
  /**
   * Opens the HoldingsFilter modal for a specific entity
   */
  onFilterModalOpen(
    entityId: string,
    entityModel: ALL_MODELS,
    entityName: string
  ): void
  onClose(): void
  icon: React.ReactElement
}

interface IDropdownFilterItemProps {
  /**
   * Text to display
   */
  filterText: string

  /**
   * When passed, the filter row item gets a `GroupIcon` to indicate it is a shared filter.
   */
  sharedFilterItem?: boolean

  /**
   * Callback used on X button click.
   */
  onRemoveFilter?(): void
}

/**
 * Each MenuItem of the dropdown Menu for Household and Clients.
 */
export const DropdownItem: React.FC<IDropdownItemProps> = React.forwardRef(
  (props, ref) => {
    // Show when item is selected AND there is account/position filter applied
    const shouldShowFilterItem = props.selected && props.holdingsCountSuffix

    const {entity, actions} = useEntityChipContext()

    const filteredAccounts =
      props.allAccountRelations.filter((account) =>
        [account.ownerEntityId, account.mandateEntityId].includes(
          props.entityRelation.entityId
        )
      ) ?? []

    const itemName = parseEntityName(props.entityRelation)

    return (
      <>
        <NestedMenuItem
          ref={ref}
          selected={props.selected}
          className={css.menuItemWidth}
          disabled={props.singleAccount && !filteredAccounts.length}
          onClick={() => {
            if (props.singleAccount) {
              return null
            }
            actions.replaceEntity(
              entity.entityId,
              props.entityRelation.entityId,
              {
                accounts: [],
                positions: [],
                ruleFilters: entity.ruleFilters
              }
            )
          }}
          label={
            <>
              {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
              {itemName}
            </>
          }
        >
          {!props.disableHoldingsFiltering && props.allAccountRelations && (
            <AccountFiltersMenu
              singleAccount={props.singleAccount}
              ownerEntityId={props.entityRelation.entityId}
              accountRelations={filteredAccounts}
              onClose={props.onClose}
              onFilterHoldingsClick={() => {
                props.onClose()
                props.onFilterModalOpen(
                  props.entityRelation.entityId,
                  props.entityRelation.model,
                  props.entityRelation.name
                )
              }}
            />
          )}
        </NestedMenuItem>
        {shouldShowFilterItem && (
          <DropdownFilterItem
            filterText={props.holdingsCountSuffix}
            onRemoveFilter={
              props.singleAccount
                ? undefined
                : () =>
                    actions.applyEntityFilters(props.entityRelation.entityId, {
                      accounts: [],
                      positions: []
                    })
            }
          />
        )}
      </>
    )
  }
)

/**
 * Menu items of the dropdown Menu to show applied filters.
 */
export const DropdownFilterItem: React.FC<IDropdownFilterItemProps> = (
  props
) => {
  return (
    <MenuItem
      selected
      className={classNames(css.showIconOnHover, css.menuItemWidth)}
      title={props.filterText}
    >
      <Flex className={css.filterItem} grow alignCenter>
        <ListItemIcon>
          <FilterListIcon fontSize='small' />
        </ListItemIcon>
        {props.filterText}
        {props.sharedFilterItem && <GroupIcon className={css.globalIcon} />}
      </Flex>
      {props.onRemoveFilter && (
        <Tooltip title='Remove filter'>
          <Flex alignEnd>
            <IconButton
              size='small'
              onClick={props.onRemoveFilter}
              className={css.icon}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </Flex>
        </Tooltip>
      )}
    </MenuItem>
  )
}
