import * as invariant from 'invariant'
import {find, map} from 'lodash'

import {IChartTableItem} from '@d1g1t/api/models'

import {StandardResponse} from '@d1g1t/lib/standard-response'

/**
 * Maps a value to a label given a list of value label options.
 * Returns null for values that are not found
 * @param value - value used to find label
 * @param options  - list of value label options
 */
export const mapValueToLabel = <V,>(
  value: V,
  options: IValueLabelOption<V>[]
) => {
  const mappedOption = find(options, (option) => option.value === value)
  if (mappedOption) {
    return mappedOption.label
  }

  return null
}

export interface IBaseModel {
  url?: string
  name?: string
}

/**
 * Returns object with value and label
 *
 * @param model - Object to modify
 */

export const modelToValueLabel = (model: Nullable<IBaseModel>) => {
  if (!model) {
    return null
  }

  return {value: model.url, label: model.name}
}

/**
 * Sorts alphabetically an array of objects by the specific key.
 * @param key - key of the object to sort.
 */
export const sortAlphabetically = <T extends object>(property: keyof T) => {
  return (a: T, b: T): number => {
    if (typeof a[property] === 'string' && typeof b[property] === 'string') {
      return (a[property] as unknown as string).localeCompare(
        b[property] as unknown as string
      )
    }
  }
}

/**
 * Maps a list of models into a list of value labels using the value
 * corresponding to the `name` key as the label and the value
 * corresponding to the `url` key for the value.
 * Returns `[]` if models is `null` or undefined
 * @param models - list of models that contains a `name` and `url` key
 * @param disableSort - Disables sorting of list, defaults to false
 */
export const mapModelToValueLabel = (
  models: Nullable<IBaseModel[]>,
  disableSort = false
): IValueLabelOption[] => {
  if (process.env.NODE_ENV === 'development' && models && models[0]) {
    invariant(
      models[0].url && models[0].name,
      'Both name and url are required to map models to value labels'
    )
  }
  const list = map(models, modelToValueLabel)

  if (disableSort) {
    return list
  }

  return list.sort(sortAlphabetically('label'))
}

/**
 * Maps an enumerator or Object into a value label list
 * @param e  - enumerator or Object to map
 */
export const mapEnumToValueLabel = (e: {[s: number]: string}) => {
  return Object.keys(e).map((key) => {
    return {
      value: e[key],
      label: e[key]
    }
  })
}

/**
 * Maps a list of models into a list of value labels using the value
 * corresponding to the `name` key as the label and value.
 * Returns `[]` if models is `null` or `undefined`
 * @param models - list of models that contains a `name` key
 */
export const mapModelNameToValueLabel = (
  models: Nullable<IBaseModel[]>
): IValueLabelOption[] =>
  map(models, (model) => ({
    value: model.name,
    label: model.name
  }))

/**
 * Maps a standard response chart/table into a value label list using
 * the value of the data item corresponding to the `url` and `name`
 * category ids
 * @param chartTable - standard repsonse chart/table
 * @param resolver - optional resolver function that can be passed in to
 * return a custom value label option
 */
export const mapChartTableToValueLabel = (
  standardResponse: StandardResponse,
  resolver?: (item: IChartTableItem) => IValueLabelOption
): IValueLabelOption[] => {
  return standardResponse.allItems().map((item) => {
    if (resolver) {
      return resolver(item.item)
    }

    return {
      value: item.getValue('url'),
      label: item.getValue('name')
    }
  })
}

/**
 * Injects an empty first option into a value label option list
 * @param options - value label option list to inject empty option into
 * @param value - value of empty option that is being injected first,
 * defaults to `null`
 * @param label - label of empty option that is being injected first,
 * default to `-`
 */
export const injectEmptyFirst = (
  options: IValueLabelOption[],
  value = null,
  label = '-'
): IValueLabelOption[] => {
  const cloned = [...options]

  cloned.unshift({
    value,
    label
  })

  return cloned
}

export const getLabelWithValue = (
  value: any,
  valueLabelOptions: IValueLabelOption<any, string>[]
) => {
  const valueLabelOption = valueLabelOptions.find(
    (valueLabelOption) => valueLabelOption.value === value
  )

  return valueLabelOption?.label
}

export const mapBooleanToValueLabel = (
  trueLabel: string,
  falseLabel: string
): IValueLabelOption<boolean, string>[] => [
  {value: true, label: trueLabel},
  {value: false, label: falseLabel}
]
