import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'
import {StandardResponseItem} from '@d1g1t/lib/standard-response'

import {FlagIcon} from '@d1g1t/shared/components/flag-icon'
import {Flex} from '@d1g1t/shared/components/flex'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'

import * as css from './styles.scss'

interface ICurrencyCellProps extends IDataCellProps {
  categoryKey?: string
}

export const CurrencyCell: React.SFC<ICurrencyCellProps> = (
  props: ICurrencyCellProps
) => {
  const standardItem = new StandardResponseItem(props.item)

  const currency =
    standardItem.getValue(props.category.id) ||
    standardItem.getKey(props.category.id)

  return (
    <div className={classNames(css.cell)}>
      {currency && (
        <Flex alignCenter>
          <FlagIcon currency={currency.toLowerCase()} />
          <Spacer vertical xxs />
          {currency}
        </Flex>
      )}
    </div>
  )
}
