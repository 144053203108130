import {useEffect, useRef} from 'react'

/**
 * This hook allows the user to pass a callback function and an interval time in milliseconds.
 * The interval will clear when the component is unmounted.
 * For more information on how this hook works visit: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param callback - Ensure passing a useCallback hook. Fired on mount and when time interval is reached.
 * @param time - Length of interval between callback firing (in milliseconds)
 */
export function useInterval(callback: () => void, time: number) {
  const callbackRef = useRef(callback)
  const interval = useRef<NodeJS.Timeout>(null)

  const handleCreateInterval = () => {
    interval.current = setInterval(() => {
      callbackRef.current()
    }, time)
  }

  // On mounting, ensure we initiate the callback once
  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current()
    }
    return () => clearInterval(interval.current)
  }, [])

  // start interval whenver we have a new time interval
  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current)
    }
    handleCreateInterval()
  }, [time])

  // update internal callback ref whenever useCallback updates internally
  useEffect(() => {
    if (typeof callback === 'function') {
      callbackRef.current = callback

      // Only start a new interval if none are in progress
      if (!interval.current) {
        handleCreateInterval()
      }
    } else {
      callbackRef.current = NOOP
      clearInterval(interval.current)
      interval.current = null
    }
  }, [callback])
}
