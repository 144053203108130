import React, {useContext, useEffect} from 'react'

import {VIEWMETRICITEM_TRANSACTION_AS_OF_DATE_OPTIONS} from '@d1g1t/api/models'

import {Label} from '@d1g1t/shared/components/typography'
import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'

import {MetricsFormContext} from '../../context'

interface IMetricTransactionAsOfDateSelectProps {
  label: string
}

export const MetricTransactionAsOfDateSelect: React.FC<
  IMetricTransactionAsOfDateSelectProps
> = (props) => {
  const {metricItem, onTransactionAsOfDateChange} =
    useContext(MetricsFormContext)

  useEffect(() => {
    if (!metricItem.transactionAsOfDate) {
      onTransactionAsOfDateChange(
        VIEWMETRICITEM_TRANSACTION_AS_OF_DATE_OPTIONS[0].value
      )
    }
  }, [metricItem.transactionAsOfDate])

  return (
    <>
      <Label>{props.label}</Label>
      <ValueLabelSelect
        size='small'
        fullWidth
        mutedBorder
        value={metricItem.transactionAsOfDate}
        options={VIEWMETRICITEM_TRANSACTION_AS_OF_DATE_OPTIONS}
        onChange={onTransactionAsOfDateChange}
      />
    </>
  )
}
