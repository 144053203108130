import invariant from 'invariant'
import {round} from 'lodash'

import {
  IChartTableCategory,
  METRIC_S_FIRM_PROVIDED_KEY,
  METRIC_S_INSTRUMENT_NAME,
  METRIC_S_PRODUCT_TYPE
} from '@d1g1t/api/models'

import {NumberFormatter} from '@d1g1t/lib/formatters/number-formatter'

import {
  IExposureVector,
  VECTORIZED_EXPOSURE_KEYS
} from './components/edit-vectorized-property-modal/typings'

export function validateSumsToHundred(): boolean {
  const weights = this.parent
    .map((vector: IExposureVector) => vector.weight)
    .filter((weight: number) => !!weight)

  // use lodash round to fix floating point precision problem
  const total = round(
    weights.reduce((prev: number, curr: number) => prev + curr, 0),
    8
  )
  return total === 1
}

export function getVectorPropertyDisplayText(
  propertyList: {
    name?: string // Not optional but interface requirements are off
    url?: string // Not optional but interface requirements are off
  }[] = [],
  vectorizedExposures: IExposureVector[] = [],
  propertyUrlAccessKey: VECTORIZED_EXPOSURE_KEYS
): string {
  if (!propertyList.length || !vectorizedExposures.length) {
    return ''
  }

  if (!Object.keys(vectorizedExposures[0]).includes(propertyUrlAccessKey)) {
    // To confirm a valid key is provided
    return ''
  }

  const percentFormatter = new NumberFormatter({
    style: 'percent',
    allowZero: true,
    decimalPlaces: 1
  })

  return vectorizedExposures
    .map((vector) => {
      const vectorProperties = propertyList.find((property) => {
        return property.url === vector[propertyUrlAccessKey]
      })

      invariant(vectorProperties, 'An exposure property was not found')

      return `${vectorProperties.name} (${percentFormatter.format(
        vector.weight
      )})`
    })
    .join(', ')
}

/**
 * Method to get all categories that may not be in table but will always show as fields in
 * Quick Drawer. Otherwise they would not be populated in quick drawer unless they existed
 * in the table.
 */
export const getCategories = (
  categories: IChartTableCategory[]
): IChartTableCategory[] => {
  const missingMandatoryCategories = new Set([
    METRIC_S_INSTRUMENT_NAME.slug,
    METRIC_S_FIRM_PROVIDED_KEY.slug,
    METRIC_S_PRODUCT_TYPE.slug
  ])

  for (const {id} of categories) {
    if (missingMandatoryCategories.has(id)) {
      missingMandatoryCategories.delete(id)
    }
  }

  return [
    ...categories,
    ...Array.from(missingMandatoryCategories).map((id) => ({id}))
  ]
}
