import {IUserView} from '@d1g1t/api/models'

import {extractIdFromUrl, mapUrlsToIds} from '@d1g1t/lib/url'

import {IDisplayOptions} from './components/display-options/typings'

/**
 * Generates a unique key for a `IUserView` and its associated `IDisplayOptions`.
 * The key is based on the current view's id, the metric's slugs, and the controlFilter
 *
 * `controlFilter` is used to determining if the view is an account or mandate view
 *
 * @param selectedViewUrl - a url from the `IUserView` to generate a key for
 * @param displayOptions - the `selectedView`'s associated `IDisplayOptions`
 *
 * @returns A unique key
 */
export function generateUniqueViewKey(
  selectedViewUrl: string,
  displayOptions: IDisplayOptions
): string {
  const slugs = mapUrlsToIds(
    displayOptions.metrics.map((metric) => metric.url)
  ).join('_')

  return `${extractIdFromUrl(selectedViewUrl)}${!!slugs ? '_' : ''}${slugs}_${
    displayOptions.displayData.controlFilter ?? ''
  }`
}

export const getParams = (
  nextSettings: Dictionary,
  allViews: IUserView[]
): {viewId: string; viewName: string} => ({
  viewId: nextSettings?.selectedViewId,
  viewName: allViews.find(
    (view) => extractIdFromUrl(view.url) === nextSettings?.selectedViewId
  )?.name
})
