import React, {useContext, useEffect, useMemo, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {AutoSizer} from 'react-virtualized'

import {useApiQuery} from 'fairlight'
import Highcharts, {AxisOptions, Options} from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import Highstock from 'highcharts/highstock'
import {merge} from 'lodash'

import {ConfigContext} from '@d1g1t/config/context'
import {useInternalTheme} from '@d1g1t/config/theme/internal-theme'

import {FirmConfigurationEndpoints} from '@d1g1t/api/endpoints'

import {AVAILABLE_LANGUAGES} from '@d1g1t/shared/wrappers/localization-settings/typings'

import './styles.scss'

HighchartsMore(Highcharts)

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  window.Highcharts = Highcharts
  // @ts-ignore
  window.Highstock = Highstock
}

interface IBaseChartProps {
  className?: string
  config: Options
}

const DEFAULT_CONFIG: Options = {
  chart: {
    backgroundColor: 'transparent',
    animation: true
  },
  credits: {
    enabled: false
  },
  title: {
    text: null
  },
  plotOptions: {
    line: {
      animation: false,
      dataLabels: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px'
        }
      }
    },
    bar: {
      dataLabels: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px'
        }
      }
    },
    column: {
      dataLabels: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px'
        }
      }
    },
    columnrange: {
      dataLabels: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px'
        }
      }
    },
    series: {
      animation: {
        duration: 300
      },
      states: {
        // Sets the state of elements that are not being hovered
        inactive: {
          opacity: 1
        }
      },
      dataLabels: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px'
        }
      }
    }
  },
  xAxis: {
    lineWidth: 0
  },
  legend: {
    itemStyle: {
      color: '#616161',
      fontWeight: 'normal'
    },
    maxHeight: 64,
    itemMarginTop: 1,
    itemMarginBottom: 4
  }
}

const DEFAULT_Y_AXIS: AxisOptions = {
  labels: {
    /**
     * Highcharts default: 3
     * Highstock default: -2
     *
     * Highstock places this value above the axis tick, highcharts plces it
     * in the middle.
     */
    y: 3
  },
  /**
   * Highcharts default: true
   * Highstock default: false
   *
   * Ensures the top and bottom values of the axis are shown.
   */
  showFirstLabel: true,
  showLastLabel: true
}

export const BaseChart: React.FC<IBaseChartProps> = (props) => {
  const {isAdvisorApp} = useContext(ConfigContext)

  const {chartColors} = useInternalTheme()
  const {i18n, t} = useTranslation()

  const [firmPreConfiguration] = useApiQuery(
    FirmConfigurationEndpoints.preLogin(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  useEffect(() => {
    highcharts.setOptions({
      lang: {
        decimalPoint: i18n.language === AVAILABLE_LANGUAGES.FRENCH ? ',' : '.',
        resetZoom: t('Reset zoom')
      },
      yAxis: {
        title: {
          text: t(Highcharts.getOptions()?.yAxis?.[0]?.title?.text || 'Values')
        }
      }
    })
  }, [i18n.language])

  const orderedColours = (() => {
    /**
     * Added for Crestone 4.0 theme that was added using a temp method.
     * Crestone wants a specific colour for the area line graph. That
     * colour is not to be included for other graphs. Hence this is necessary.
     * Can be used for any other firm that has special areaChartColor preferences
     */
    if (
      !isAdvisorApp &&
      firmPreConfiguration.data?.themeInvestor?.charts?.areaChartColors &&
      props.config?.chart?.type === 'area'
    ) {
      return [
        ...firmPreConfiguration.data.themeInvestor.charts.areaChartColors,
        ...chartColors.slice()
      ]
    }
    return chartColors.slice()
  })()

  const config = useMemo(() => {
    const options = merge(
      {
        colors: orderedColours
      },
      DEFAULT_CONFIG,
      props.config
    )

    if (options.yAxis) {
      if (!Array.isArray(options.yAxis)) {
        options.yAxis = [options.yAxis]
      }
      for (let i = 0; i < options.yAxis.length; i++) {
        options.yAxis[i] = merge({}, DEFAULT_Y_AXIS, options.yAxis[i])
      }
    }

    for (const axis of ['yAxis', 'xAxis'] as Array<'yAxis' | 'xAxis'>) {
      if (options[axis]) {
        if (!Array.isArray(options[axis])) {
          options[axis] = [options[axis]] as [AxisOptions]
        }

        // always explicitly set axis default formatter, since
        // there's a bug in `react-highcharts-official`:
        // setting a formatter, and then removing the formatter in a
        // subsequent render does not reset back to the default formatter
        options[axis] = (options[axis] as AxisOptions[]).map(
          (axisOptions: AxisOptions) => {
            return {
              ...axisOptions,
              labels: {
                ...(axisOptions.labels || {}),
                formatter:
                  axisOptions.labels?.formatter ||
                  function defaultFormatter() {
                    return this.axis.defaultLabelFormatter.call(this)
                  }
              }
            }
          }
        )
      }
    }

    return options
  }, [props.config, chartColors])

  const isStock = ['area', 'line'].includes(config.chart.type)

  const highcharts = isStock ? Highstock : Highcharts
  const constructorType = isStock ? 'stockChart' : 'chart'

  if (config.chart.height && config.chart.width) {
    return (
      <HighchartsReact
        highcharts={highcharts}
        constructorType={constructorType}
        className={props.className}
        options={config}
      />
    )
  }

  return (
    <AutoSizer>
      {({height, width}) =>
        height > 0 && (
          <HighChartsContainer
            highcharts={highcharts}
            constructorType={constructorType}
            className={props.className}
            width={width}
            height={height}
            options={config}
          />
        )
      }
    </AutoSizer>
  )
}

interface IHighChartsContainerProps extends HighchartsReact.Props {
  className: string
  width: number
  height: number
}

const HighChartsContainer: React.FC<IHighChartsContainerProps> = (props) => {
  const chartRef = useRef(null)

  // Needed to fix FRON-4915 Highcharts resize issue where chart does not fill the
  // width of its parent container
  useEffect(() => {
    if (
      chartRef.current &&
      document.querySelector('.highcharts-container')?.getClientRects()[0]
        .width !== props.width
    ) {
      chartRef.current.chart.reflow()
    }
  })

  const containerProps = useMemo(
    () => ({
      style: {
        width: props.width,
        height: props.height
      }
    }),
    [props.width, props.height]
  )

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={props.highcharts}
      constructorType={props.constructorType}
      className={props.className}
      containerProps={containerProps}
      options={props.options}
    />
  )
}
