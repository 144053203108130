import React, {memo, useCallback, useContext, useMemo} from 'react'
import {useDrop} from 'react-dnd'
import {DraggableData} from 'react-draggable'

import ExpandIcon from '@material-ui/icons/AddBox'
import EyeIcon from '@material-ui/icons/RemoveRedEye'

import {ConfigContext} from '@d1g1t/config/context'

import {CHART_VALUE_TYPE} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {partialIsEqual} from '@d1g1t/lib/partial-is-equal'
import {isNumberCell} from '@d1g1t/lib/standard-response'

import {Flex} from '@d1g1t/shared/components/flex'
import {AscendingIcon} from '@d1g1t/shared/components/icons/ascending'
import {DescendingIcon} from '@d1g1t/shared/components/icons/descending'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {Text} from '@d1g1t/shared/components/typography'
import {StandardTableCheckbox} from '@d1g1t/shared/containers/standard-table/components/checkbox'
import {ResizeHandle} from '@d1g1t/shared/containers/standard-table/components/resize-handle'
import {
  APPLY_COLUMN_FILTER_BUTTON_COLUMN,
  CHECK_ROW_TYPE,
  DRAG_ROW_TYPE,
  EXPAND_COLOURS
} from '@d1g1t/shared/containers/standard-table/constants'
import {
  cellStyleIsEqual,
  isFirstExpandedCategory
} from '@d1g1t/shared/containers/standard-table/lib'

import {
  IColumnTitleWithSubnameProps,
  IHeaderCellFillerProps,
  IHeaderCellProps
} from './typings'

import * as css from './styles.scss'

export const isEqualHeader = (
  props: IHeaderCellProps,
  nextProps: IHeaderCellProps
): boolean => {
  if (!cellStyleIsEqual(props.style, nextProps.style)) {
    return false
  }

  const rootProps: (keyof IHeaderCellProps)[] = [
    'columnIndex',
    'rowIndex',
    'dropTarget',
    'resizingColumn',
    'sortOrder',
    'hideColumnIndicator',
    'checkedAllRows',
    'hideCheckAll'
  ]

  if (!partialIsEqual(props, nextProps, rootProps)) {
    return false
  }

  const categoryProps: (keyof IHeaderCellProps['category'])[] = [
    'expanded',
    'id',
    'level',
    'name',
    'valueType'
  ]

  if (!partialIsEqual(props.category, nextProps.category, categoryProps)) {
    return false
  }

  if (props.category.options && !nextProps.category.options) {
    return false
  }

  if (props.category.options) {
    const categoryOptionProps: (keyof IHeaderCellProps['category']['options'])[] =
      ['hidden', 'delta']

    if (
      !partialIsEqual(
        props.category.options,
        nextProps.category.options,
        categoryOptionProps
      )
    ) {
      return false
    }
  }

  return true
}

export const HeaderCell: React.FC<IHeaderCellProps> = memo((props) => {
  const config = useContext(ConfigContext)
  const isInvestorApp = !config.isAdvisorApp

  const showLookThrough = props.category.options?.isLookThroughEnabled
  const handleSortClick = useCallback(() => {
    props.onSortClick(props.category)
  }, [props.onSortClick, props.category])

  const handleExpandClick = useCallback(() => {
    props.onExpandClick(props.category)
  }, [props.onExpandClick, props.category])

  const handleDrag = useCallback(
    (data: DraggableData) => {
      props.onColumnResize(props.category, data)
    },
    [props.onColumnResize, props.category]
  )

  const handleDragStart = useCallback(() => {
    props.onColumnResizeStart(props.columnIndex, props.category)
  }, [props.onColumnResizeStart, props.columnIndex, props.category])

  const handleDragEnd = useCallback(() => {
    props.onColumnResizeEnd(props.columnIndex, props.category)
  }, [props.onColumnResizeEnd, props.columnIndex, props.category])

  const handleRequestAutoResize = useCallback(() => {
    props.onRequestAutoResize(props.columnIndex, props.category)
  }, [props.onRequestAutoResize, props.columnIndex, props.category])

  const numberCell = useMemo(
    () =>
      !props.isFirstVisibleDataColumn &&
      isNumberCell(props.category.valueType as CHART_VALUE_TYPE),
    [props.category.valueType]
  )

  const isExpandableColumn = useMemo(() => {
    return !!props.category.categories?.some(
      (childCategory) => !childCategory.options?.hidden
    )
  }, [props.category.categories])

  const columnTitle = useMemo(() => {
    if (props.category.id === CHECK_ROW_TYPE) {
      if (props.hideCheckAll) {
        return null
      }

      if (props.checkedColumnHeaderOverride) {
        return props.checkedColumnHeaderOverride
      }
      return (
        <Tooltip title='Filter data to select all' placement='top-start'>
          <Flex justifyCenter>
            <StandardTableCheckbox
              onChange={props.onCheckAllRows}
              checked={props.checkedAllRows}
            />
            {props.checkedColumnTitle && (
              <div
                className={classNames(css.columnTitle, {
                  [css.columnTitleCompactMode]: props.isCompactMode,
                  [css.investorCell]: isInvestorApp
                })}
              >
                {props.checkedColumnTitle}
              </div>
            )}
          </Flex>
        </Tooltip>
      )
    }

    const titleName = (() => {
      if (props.category.expanded && props.category.valueType === 'string') {
        return props.category.name
      }

      if (props.category.expanded) {
        return 'Total'
      }

      if (props.category.level === 0) {
        return props.category.name
      }

      const parts = props.category.name.split(':')

      return parts[parts.length - 1]
    })()

    if (props.categoryTooltipText) {
      return (
        <Tooltip title={props.categoryTooltipText} placement='top-start'>
          <Flex
            className={classNames(css.columnTitle, {
              [css.columnTitleCompactMode]: props.isCompactMode,
              [css.investorCell]: isInvestorApp
            })}
          >
            {titleName}
          </Flex>
        </Tooltip>
      )
    }

    return titleName
  }, [
    props.category.id,
    props.checkedColumnHeaderOverride,
    props.hideCheckAll,
    props.onCheckAllRows,
    props.checkedAllRows,
    props.category.expanded,
    props.category.valueType,
    props.category.name,
    props.category.level
  ])

  const expandedStyle = (() => {
    if (isFirstExpandedCategory(props.category)) {
      const level =
        !(props.category.expanded && !props.category.hiddenWhenExpanded) &&
        props.category.parent?.expanded &&
        props.category.parent.hiddenWhenExpanded
          ? props.category.parent.level
          : props.category.level

      return {
        borderLeftColor: EXPAND_COLOURS[level].border,
        borderLeftWidth: 3,
        borderLeftStyle: 'solid' as any
      }
    }

    return null
  })()

  const sortingEnabled =
    props.sortable &&
    props.category.id !== CHECK_ROW_TYPE &&
    !props.category.options?.editable

  const sortIcon = (
    <IconButton tiny onClick={handleSortClick}>
      {props.sortOrder === SORT_ORDER.DESC ? (
        <DescendingIcon className={css.active} />
      ) : (
        <AscendingIcon className={css.active} />
      )}
    </IconButton>
  )

  const [, dropRef] = useDrop({
    accept: DRAG_ROW_TYPE,
    hover() {
      props.onDragHover(props.rowIndex)
    },
    drop() {
      props.onDrop(props.rowIndex)
    }
  })

  return (
    <div
      className={classNames(css.headerCell, {
        [css.dropTargetRow]: props.dropTarget,
        [css.resizingColumn]: props.resizingColumn,
        [css.checkboxCell]: props.category.id === CHECK_ROW_TYPE,
        [css.flexEnd]: numberCell
      })}
      style={{
        ...props.style,
        ...expandedStyle
      }}
      ref={dropRef}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <Flex
        alignEnd
        className={classNames(css.name, {
          [css.number]: numberCell,
          [css.compactName]: props.isCompactMode,
          [css.investorCell]: isInvestorApp
        })}
      >
        {!!props.category.subName ? (
          <ColumnTitleWithSubname
            onClick={sortingEnabled ? handleSortClick : undefined}
            isExpandableColumn={isExpandableColumn}
            columnTitle={columnTitle}
            isSortingEnabled={sortingEnabled && !!props.sortOrder}
            subName={props.category.subName}
            description={props.category.description}
            startAdornment={props.startAdornment}
            isCompactMode={props.isCompactMode}
            isInvestorApp={isInvestorApp}
          />
        ) : (
          <div
            onClick={sortingEnabled ? handleSortClick : undefined}
            className={classNames(css.columnTitle, {
              [css.columnTitleCompactMode]: props.isCompactMode,
              [css.investorCell]: isInvestorApp
            })}
          >
            {showLookThrough && (
              <Tooltip title='Look-through enabled'>
                <EyeIcon fontSize='small' className={css.visibleIcon} />
              </Tooltip>
            )}
            {columnTitle}
          </div>
        )}
        <Flex alignEnd>
          {sortingEnabled && props.sortOrder && sortIcon}
          <Spacer custom={2} vertical />
          {isExpandableColumn && !props.category.expanded && (
            <Tooltip title='Expand'>
              <IconButton tiny onClick={handleExpandClick}>
                <ExpandIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          )}
        </Flex>
      </Flex>
      {![CHECK_ROW_TYPE, APPLY_COLUMN_FILTER_BUTTON_COLUMN].includes(
        props.category.id
      ) && (
        <ResizeHandle
          hideIndicator={props.hideColumnIndicator}
          onDrag={handleDrag}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDoubleClick={handleRequestAutoResize}
        />
      )}
    </div>
  )
}, isEqualHeader)

/**
 * Filler Header Cells
 */

export const isEqualHeaderFiller = (
  props: IHeaderCellFillerProps,
  nextProps: IHeaderCellFillerProps
): boolean => {
  if (!cellStyleIsEqual(props.style, nextProps.style)) {
    return false
  }

  const rootProps: (keyof IHeaderCellFillerProps)[] = [
    'columnIndex',
    'rowIndex',
    'dropTarget'
  ]

  if (!partialIsEqual(props, nextProps, rootProps)) {
    return false
  }

  return true
}

export const HeaderCellFiller: React.FC<IHeaderCellFillerProps> = memo(
  (props) => {
    const [, dropRef] = useDrop({
      accept: DRAG_ROW_TYPE,
      hover() {
        props.onDragHover(props.rowIndex)
      },
      drop() {
        props.onDrop(props.rowIndex)
      }
    })

    return (
      <div
        style={props.style}
        className={classNames(css.headerCell, css.filler, {
          [css.dropTargetRow]: props.dropTarget
        })}
        ref={dropRef}
      />
    )
  },
  isEqualHeaderFiller
)

/**
 * Column Title With Subname.
 * Used in the `<HeaderCell />` component when the `IStandardTableCategory`  has a subName available
 */
const ColumnTitleWithSubname: React.FC<IColumnTitleWithSubnameProps> = (
  props
) => {
  // sortIcon is 18px
  // ExpandIcon is 24px
  const computedWidth = (() => {
    if (props.isExpandableColumn && props.isSortingEnabled) {
      return 'calc(100% - 24px - 18px)'
    }

    if (props.isSortingEnabled) {
      return 'calc(100% - 18px)'
    }

    if (props.isExpandableColumn) {
      return 'calc(100% - 24px)'
    }
    return undefined
  })()

  return (
    <>
      <Flex
        column
        style={{
          width: computedWidth,
          height: 'inherit'
        }}
        onClick={props.onClick}
      >
        <Flex fullWidth className={css.alignEnd}>
          {props.startAdornment && (
            <>
              {props.startAdornment}
              <Spacer custom={4} vertical />
            </>
          )}
          <Text
            className={classNames(css.columnTitleWithSubname, {
              [css.columnTitleWithSubnameCompactMode]: props.isCompactMode,
              [css.investorCell]: props.isInvestorApp
            })}
          >
            {props.columnTitle}
          </Text>
        </Flex>
        <Tooltip title={props.description}>
          <Text normalWeight className={css.subName}>
            {props.subName}
          </Text>
        </Tooltip>
      </Flex>
    </>
  )
}
