import {useMemo} from 'react'

import {useApiQuery} from 'fairlight'

import {CurrencyEndpoints} from '@d1g1t/api/endpoints'

import {
  mapModelNameToValueLabel,
  mapModelToValueLabel
} from '@d1g1t/lib/value-label'

export const useCurrencyList = () => {
  const [currencies] = useApiQuery(CurrencyEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const currencyOptions = useMemo(
    () => mapModelToValueLabel(currencies.data?.results),
    [currencies.data?.results]
  )

  const currencyOptionsName = useMemo(
    () => mapModelNameToValueLabel(currencies.data?.results),
    [currencies.data?.results]
  )

  return {currencies, currencyOptions, currencyOptionsName}
}
