import {DropTargetCollector, DropTargetSpec} from 'react-dnd'
import {VisibleCellRange} from 'react-virtualized'

import {isEmpty, map} from 'lodash'

import {
  IChartTable,
  IChartTableCategory,
  IChartTableItem
} from '@d1g1t/api/models'

import {partialIsEqual} from '@d1g1t/lib/partial-is-equal'
import {
  performanceMarkStart,
  performanceMeasureFromStartMark
} from '@d1g1t/lib/performance'
import {
  itemLeafIds,
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'

import {CHECKED_STATE, KNOWN_CATEGORY_IDS} from './constants'
import {
  CategoryIsHiddenWhenExpanded,
  IDropSpecProps,
  IDropTargetCollect,
  IItemPageId,
  IStandardTableCategory,
  IStandardTableState,
  StandardTableItem
} from './typings'

export const getAllTableItemIds = (items: IChartTableItem[]): string[] =>
  items.reduce((ids, item) => {
    if (!item) {
      return ids
    }
    ids.push(item.id)
    if (item.items) {
      return ids.concat(getAllTableItemIds(item.items))
    }

    return ids
  }, [])

/**
 * Returns a count of all rows in the array, including nested rows.
 */
export const getTableRowCount = (items: IChartTableItem[]): number => {
  let count = items.length
  for (const item of items) {
    if (item && item.items) {
      count += getTableRowCount(item.items)
    }
  }
  return count
}

/**
 * Flattens a tree of items for rendering
 */
export const generateTableItems = (
  items: StandardResponseItem[],
  expandedRows: Set<StrNum>
): Pick<IStandardTableState, 'items' | 'itemPageIds'> => {
  performanceMarkStart('Generate Table Items')

  const fullyExpandedItems: Map<string, boolean> = new Map()
  const generatedTableItems: StandardTableItem[] = []
  const itemPageIds: IItemPageId[] = []

  // inner function mutates fullyExpandedItems and generatedTableItems
  const innerGenerateTableItems = (
    items: StandardResponseItem[],
    level: number,
    parent?: Nullable<StandardTableItem>
  ): void => {
    for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
      const item = items[itemIndex]

      itemPageIds.push({
        indexInLevel: itemIndex,
        parentPath: ((): string => {
          let currentParent = parent
          let parentPath = ''
          while (currentParent) {
            parentPath = `/${currentParent.id}${parentPath}`
            currentParent = currentParent.parent
          }
          return `root${parentPath}`
        })()
      })

      // Handle case of sparse array data for incrementally loaded data
      if (!item) {
        generatedTableItems.push(null)
        continue
      }

      const group = !isEmpty(item.items)

      const addItem = ({
        expanded
      }: Pick<StandardTableItem, 'expanded'>): StandardTableItem => {
        // Adding each property of IChartTable manually as spreading (...item)
        // is slow by comparision (~2x slower)
        const standardTableItem = new StandardTableItem(item)

        standardTableItem.group = group
        standardTableItem.level = level
        standardTableItem.parent = parent
        standardTableItem.expanded = expanded

        generatedTableItems.push(standardTableItem)

        return standardTableItem
      }

      // "Redundant" size check here to appease the mysterious V8 compiler gods
      // seems that Map.has is quite slow event for empty Maps
      if (expandedRows.size > 0 && expandedRows.has(item.id) && item.items) {
        if (
          parent &&
          (!fullyExpandedItems.has(parent.id) ||
            fullyExpandedItems.get(parent.id))
        ) {
          fullyExpandedItems.set(parent.id, true)
        }

        const standardTableItem = addItem({expanded: true})

        innerGenerateTableItems(item.items, level + 1, standardTableItem)
      } else {
        if (item.items) {
          let currentParent = parent
          while (currentParent) {
            fullyExpandedItems.set(currentParent.id, false)
            currentParent = currentParent.parent
          }
        }

        // Adding each property of IChartTable manually as spreading (...item)
        // is slow by comparision (~2x slower)
        addItem({expanded: false})
      }
    }
  }

  // call the innner function and start the recursion
  innerGenerateTableItems(items, 0)

  // Avoid extra iteration when there are no expanded items
  if (fullyExpandedItems.size > 0) {
    for (const item of generatedTableItems) {
      // Handle case of sparse array data for incrementally loaded data
      if (item) {
        item.fullyExpanded = !!fullyExpandedItems.get(item.id)
      }
    }
  }

  performanceMeasureFromStartMark('Generate Table Items')

  return {
    itemPageIds,
    items: generatedTableItems
  }
}

export const isNameCategory = (category: IStandardTableCategory): boolean =>
  category.id === KNOWN_CATEGORY_IDS.NAME

export const isTopLevelCategory = (
  category: IChartTableCategory,
  allCategories: IChartTableCategory[]
): boolean => map(allCategories, 'id').includes(category.id)

export const createStandardTableCategories = (
  categories: IChartTableCategory[],
  expandedCategories: Set<string>,
  categoryIsHiddenWhenExpanded?: CategoryIsHiddenWhenExpanded,
  level = 0,
  parent: IStandardTableCategory = null
): IStandardTableCategory[] => {
  return categories?.map((category) => {
    const standardTableCategory: IStandardTableCategory = {
      ...category,
      parent,
      level,
      expanded:
        category.options?.alwaysExpanded ||
        (expandedCategories.has(category.id) &&
          category.categories?.some(
            (childCategory) => !childCategory.options?.hidden
          ))
    }

    standardTableCategory.categories = createStandardTableCategories(
      category.categories,
      expandedCategories,
      categoryIsHiddenWhenExpanded,
      level + 1,
      standardTableCategory
    )

    standardTableCategory.hiddenWhenExpanded =
      typeof categoryIsHiddenWhenExpanded === 'function'
        ? categoryIsHiddenWhenExpanded(standardTableCategory)
        : !!categoryIsHiddenWhenExpanded

    return standardTableCategory
  })
}

/**
 * Flattens a category tree into a flat list for rendering and
 * creates references to parent nodes to allow traversing back up
 */
export const getFlattenedCategoriesToRender = (
  categories: IStandardTableCategory[]
): IStandardTableCategory[] => {
  const result: IStandardTableCategory[] = []

  for (const category of categories) {
    if (category.options?.hidden) {
      continue
    }

    if (!(category.hiddenWhenExpanded && category.expanded)) {
      result.push(category)
    }

    if (category.expanded) {
      result.push(...getFlattenedCategoriesToRender(category.categories))
    }
  }

  return result
}

export const createDropSpec_DEPRECATED = <
  P extends IDropSpecProps
>(): DropTargetSpec<P> => ({
  hover(props, monitor): void {
    props.onDragHover(props.rowIndex)
  },
  drop(props, monitor): void {
    props.onDrop(props.rowIndex)
  }
})

export const dropTargetCollector_DEPRECATED: DropTargetCollector<any, any> = (
  connect,
  monitor
): IDropTargetCollect => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

export const cellStyleIsEqual = (
  styleProps: React.CSSProperties,
  nextStyleProps: React.CSSProperties
): boolean => {
  const compareKeys: (keyof React.CSSProperties)[] = [
    'width',
    'height',
    'top',
    'left'
  ]

  return partialIsEqual(styleProps, nextStyleProps, compareKeys)
}

/**
 * Called when a row is checked, returns the leafs ids of the all checked rows
 * without their ancestors
 */
export const getCheckedIds = (
  itemClicked: StandardResponseItem,
  renderedItems: StandardResponseItem[],
  rowsCheckedState: Map<string, CHECKED_STATE>,
  options: {shiftKey: boolean; singleSelect?: boolean}
): string[] => {
  // Get a list of entity IDs that are checked
  const checkedIdSet = new Set<string>()
  if (!options.singleSelect) {
    for (const [id, state] of rowsCheckedState) {
      if (state === CHECKED_STATE.CHECKED) {
        checkedIdSet.add(id)
      }
    }
  }

  // If nothing was selected, the clicked item becomes the only selection
  if (checkedIdSet.size === 0) {
    return itemLeafIds([itemClicked])
  }

  // If only 1 row was checked, and it was clicked again, deselect it
  if (checkedIdSet.size === 1 && checkedIdSet.has(itemClicked.id)) {
    return []
  }

  if (options.shiftKey) {
    return checkedItemsForShiftKey(
      checkedIdSet,
      renderedItems,
      rowsCheckedState,
      itemClicked
    )
  }

  return checkedItemsAfterClick(
    checkedIdSet,
    itemClicked,
    renderedItems,
    rowsCheckedState
  )
}

/**
 * Returns the leaf node IDs of selectedRows without the ancestors.
 */
const expandEntityIds = (
  selectedRows: string[],
  renderItems: IChartTableItem[]
): string[] => {
  const expanded = new Set<string>()

  for (const checkedRow of selectedRows) {
    const stdItem = findItemByIdWithNestedIndex(checkedRow, renderItems)

    if (stdItem.item?.items) {
      const children = stdItem.item.items
      if (children.length === 0) {
        expanded.add(checkedRow)
      } else {
        for (const childRow of children) {
          if (childRow.items) {
            for (const id of expandEntityIds([childRow.id], renderItems)) {
              expanded.add(id)
            }
          } else {
            expanded.add(childRow.id)
          }
        }
      }
    } else {
      expanded.add(checkedRow)
    }
  }

  return [...expanded]
}

/**
 * Returns the item with `item.id` matching `searchItemId` from `items`.
 * Also returns the item's nested index.
 * @param searchItemId - the item to search
 * @param items - the items to search in
 */
export const findItemByIdWithNestedIndex = (
  searchItemId: string,
  items: IChartTableItem[],
  traversalIndex = -1
): {item: Nullable<IChartTableItem>; index: number} => {
  for (const item of items) {
    traversalIndex++

    if (item.id === searchItemId) {
      return {item, index: traversalIndex}
    }

    if (item.items) {
      const nestedSearchResults = findItemByIdWithNestedIndex(
        searchItemId,
        item.items,
        traversalIndex
      )

      if (nestedSearchResults.item && nestedSearchResults.index >= 0) {
        // searchItem found in nested items
        return {
          item: nestedSearchResults.item,
          index: nestedSearchResults.index
        }
      }
      traversalIndex = nestedSearchResults.index
    }
  }
  return {item: null, index: traversalIndex}
}

/**
 * Called when advisor uses Shift key to multi-select rows.
 */
export const checkedItemsForShiftKey = (
  checkedIdSet: Set<string>,
  renderItems: StandardResponseItem[],
  rowsCheckedState: Map<string, CHECKED_STATE>,
  clickedItem: StandardResponseItem
): string[] => {
  const tableItems = generateTableItems(
    renderItems,
    new Set(getAllTableItemIds(renderItems))
  )

  // Clicked row
  const clickedRowIndex = tableItems.items.findIndex(
    (tableItem) => tableItem.id === clickedItem.id
  )

  // Existing selection
  const checkedRowIndexes = []
  for (const id of checkedIdSet) {
    checkedRowIndexes.push(findItemByIdWithNestedIndex(id, renderItems).index)
  }
  const maxCheckedIndex = Math.max(...checkedRowIndexes)
  const minCheckedIndex = Math.min(...checkedRowIndexes)

  // New selection
  const checkedStdItems: StandardResponseItem[] = []

  const pushNonNullTableItemsIntoCheckedStdItems = (
    item: StandardTableItem
  ): void => {
    if (item.item) {
      checkedStdItems.push(item)
    }
  }

  if (
    minCheckedIndex < clickedRowIndex &&
    !(maxCheckedIndex < clickedRowIndex)
  ) {
    for (let i = minCheckedIndex; i <= clickedRowIndex; i++) {
      if (!tableItems.items[i].items || clickedRowIndex === i) {
        pushNonNullTableItemsIntoCheckedStdItems(tableItems.items[i])
      }
    }
  } else if (maxCheckedIndex < clickedRowIndex) {
    for (const index of checkedRowIndexes.filter(
      (value) => value < maxCheckedIndex
    )) {
      pushNonNullTableItemsIntoCheckedStdItems(tableItems.items[index])
    }

    for (let i = maxCheckedIndex; i <= clickedRowIndex; i++) {
      if (!tableItems.items[i].items || clickedRowIndex === i) {
        pushNonNullTableItemsIntoCheckedStdItems(tableItems.items[i])
      }
    }
  } else {
    for (let i = clickedRowIndex; i <= maxCheckedIndex; i++) {
      if (!tableItems.items[i].items || clickedRowIndex === i) {
        pushNonNullTableItemsIntoCheckedStdItems(tableItems.items[i])
      }
    }
  }

  if (clickedItem.items) {
    for (const childItem of clickedItem.items) {
      checkedStdItems.push(childItem)
    }
  }

  return uniqueLeafIdsFromItems(checkedStdItems)
}

const removeParentCheckedIds = (
  item: StandardResponseItem,
  checkedIds: Set<string>,
  nextCheckedIds: Set<string>
): void => {
  if (
    item.itemParent.items
      .map((item) => item.id)
      .every((id) => checkedIds.has(id))
  ) {
    nextCheckedIds.delete(item.itemParent.id)
  }

  if (item.itemParent.itemParent) {
    removeParentCheckedIds(item.itemParent, checkedIds, nextCheckedIds)
  }
}

/**
 * Given a set of checked rows, figure out which other rows to add to
 * the set given the last clicked row.
 */
export const checkedItemsAfterClick = (
  checkedIds: Set<string>,
  itemClicked: StandardResponseItem,
  renderItems: IChartTableItem[],
  rowsCheckedState: Map<string, CHECKED_STATE>
): string[] => {
  const expandedItemClicked = new Set<string>()
  for (const id of expandEntityIds([itemClicked.id], renderItems)) {
    expandedItemClicked.add(id)
  }

  // is group clicked?
  const groupClicked = expandedItemClicked.size > 1
  const groupClickedState =
    rowsCheckedState.get(itemClicked.id) || CHECKED_STATE.EMPTY

  const expandedCheckedIdSet = new Set<string>()
  for (const id of expandEntityIds([...checkedIds], renderItems)) {
    expandedCheckedIdSet.add(id)
  }
  const nextCheckedIds = new Set(expandedCheckedIdSet)

  for (const id of expandedItemClicked) {
    if (
      groupClicked &&
      (groupClickedState === CHECKED_STATE.EMPTY ||
        groupClickedState === CHECKED_STATE.PARTIAL)
    ) {
      nextCheckedIds.add(id)
    } else if (groupClicked && groupClickedState === CHECKED_STATE.CHECKED) {
      nextCheckedIds.delete(id)
    } else if (expandedCheckedIdSet.has(id)) {
      nextCheckedIds.delete(id)
    } else {
      nextCheckedIds.add(id)
    }
  }

  if (expandedCheckedIdSet.has(itemClicked.id)) {
    nextCheckedIds.delete(itemClicked.id)
  }

  if (itemClicked.itemParent) {
    removeParentCheckedIds(itemClicked, expandedCheckedIdSet, nextCheckedIds)
  }

  return Array.from(nextCheckedIds.values())
}

export const uniqueLeafIdsFromItems = (
  items: StandardResponseItem[]
): string[] => {
  const itemIds: Set<string> = new Set()
  for (const item of items) {
    for (const id of itemLeafIds([item])) {
      itemIds.add(id)
    }
  }

  return Array.from(itemIds)
}

/**
 * Updates `checkedIds` param with nodes for which all children are selected
 */
const updateCheckedIdsWithHierarchyForNode = (
  leafSet: Set<string>,
  item: StandardResponseItem,
  checkedIds: string[],
  /**
   * Used to select which items to include, and/or which ancestors are collapsed.
   */
  predicate?: (item: StandardResponseItem) => boolean
): void => {
  // Check if this node has children
  if (!item.isLeafNode) {
    // Check if every node lead if is part of the checked id set
    if (item.leafIds.every((nodeLeafId) => leafSet.has(nodeLeafId))) {
      // If predicate is true for item, push the item; otherwise push all child leaf IDs
      if (predicate ? predicate(item) : true) {
        checkedIds.push(item.id)
      } else {
        checkedIds.push(...item.leafIds)
      }

      return
    }

    // Dive into tree and check each child
    for (const childItem of item.children) {
      updateCheckedIdsWithHierarchyForNode(
        leafSet,
        childItem,
        checkedIds,
        predicate
      )
    }

    return
  }

  // Add remaining leaf ids to checked ids
  if (leafSet.has(item.id)) {
    checkedIds.push(item.id)
  }
}

/**
 * Given a list leaf node ids, replaces leafs ids with their ancestors where all
 * children are present
 */
export const getCheckedIdsWithHierarchy = (
  leafIds: string[],
  table: IChartTable,
  /**
   * Used to select which items to include, and/or which ancestors are collapsed.
   */
  predicate?: (item: StandardResponseItem) => boolean
): string[] => {
  const leafSet = new Set(leafIds)
  const standardResponse = new StandardResponse(table)

  if (leafSet.size === 0) {
    return []
  }

  const checkedIds = []
  for (const item of standardResponse.items) {
    updateCheckedIdsWithHierarchyForNode(
      leafSet,
      item,
      checkedIds,
      predicate || null
    )
  }

  return checkedIds
}

/**
 * Because expandable categories can be hidden when expanded,
 * the first expandable category may be either the expandable category
 * or one of its children.
 *
 * To help simplify, this function returns whether or not a function
 * is the first expanded category in its level.
 */
export const isFirstExpandedCategory = (
  category: IStandardTableCategory
): boolean => {
  return (
    (category.expanded && !category.hiddenWhenExpanded) ||
    (category.parent &&
      category.parent.hiddenWhenExpanded &&
      category.parent.categories.find((category) => !category.options?.hidden)
        ?.id === category.id)
  )
}

interface IVisibleColumnExpansion {
  /**
   * Level of the expansion (0 is the root and is highest in the UI)
   */
  level?: number
  /**
   * The root expandable category (for rendering expand/collapse buttons)
   */
  controlCategory?: IStandardTableCategory
  /**
   * Column index where the control category should be rendered
   */
  controlColumnIndex?: number
  /**
   * The column where the expandable section stops
   */
  endIndex?: number
}

/**
 * Returns a mapping of category keys to expansion definitions
 */
export const findVisibleColumnExpansions = (
  visibleColumnIndices: VisibleCellRange,
  getRenderCategory: (columnIndex: number) => IStandardTableCategory,
  fixedColumnCount: number
): Dictionary<IVisibleColumnExpansion> => {
  const expansions: Dictionary<IVisibleColumnExpansion> = {}

  for (
    let currentColumnIndex = visibleColumnIndices.start;
    currentColumnIndex < visibleColumnIndices.stop;
    currentColumnIndex++
  ) {
    const categoryForColumn = getRenderCategory(
      currentColumnIndex + fixedColumnCount
    )

    if (!categoryForColumn) {
      // skip if category is at root level and is not expanded
      continue
    }

    // track expansions for category at current column and its parents
    let currentCategory = categoryForColumn
    while (currentCategory) {
      const isRenderedControlCategory =
        currentCategory.expanded && !currentCategory.hiddenWhenExpanded

      if (
        currentCategory.expanded ||
        currentCategory.level > 0 ||
        isRenderedControlCategory
      ) {
        const controlCategory =
          !isRenderedControlCategory && currentCategory.parent
            ? currentCategory.parent
            : currentCategory

        if (!expansions[controlCategory.id]) {
          expansions[controlCategory.id] = {
            level: controlCategory.level,
            controlCategory
          }

          if (isFirstExpandedCategory(currentCategory)) {
            expansions[controlCategory.id].controlColumnIndex =
              currentColumnIndex
          }
        }

        expansions[controlCategory.id].endIndex = currentColumnIndex + 1
      }

      currentCategory = currentCategory.parent
    }
  }

  return expansions
}
