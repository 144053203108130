import React from 'react'

import DeleteIcon from '@material-ui/icons/Close'
import DragIcon from '@material-ui/icons/DragHandle'

import {classNames} from '@d1g1t/lib/class-names'

import {IconButton} from '@d1g1t/shared/components/mui/icon-button'

import css from './styles.scss'

interface IOrderableListItemProps {
  onClick()
  onDelete()
  translucent?: boolean
  isSelected?: boolean
  draggable?: boolean
}

export const OrderableListItem: React.FC<IOrderableListItemProps> =
  function OrderableListItem(props) {
    return (
      <div
        className={classNames(css.container, {
          [css.selected]: props.isSelected,
          [css.draggable]: props.draggable
        })}
        style={{opacity: props.translucent ? 0.5 : 1}}
        onClick={props.onClick}
      >
        {props.draggable && (
          <div className={css.dragNDropIcon}>
            <DragIcon />
          </div>
        )}
        <div className={css.children}>{props.children}</div>
        {props.onDelete && (
          <div className={css.deleteButton}>
            <IconButton
              data-testid='button-delete-item'
              onClick={(event) => {
                event.stopPropagation()
                props.onDelete()
              }}
            >
              <DeleteIcon fontSize='small' />
            </IconButton>
          </div>
        )}
      </div>
    )
  }
