import React from 'react'

import TextField from '@material-ui/core/TextField'

import {FlexGridItem, FlexGridRow} from '@d1g1t/shared/components/flex'
import {Spacer} from '@d1g1t/shared/components/spacer'

import {StatusBox} from '@d1g1t/advisor/components/status-box'

interface IUserStatusFormProps {
  status: boolean
}

export const UserStatusForm: React.FC<IUserStatusFormProps> = ({status}) => {
  const userStatus = status === true ? 'Active' : 'Disabled'
  return (
    <StatusBox active={status}>
      <FlexGridRow>
        <FlexGridItem col='1/2'>
          <TextField
            id='outlined-select-currency-native'
            select
            label='Status'
            value={userStatus}
            SelectProps={{
              native: true
            }}
            disabled
            variant='outlined'
          >
            <option>{userStatus}</option>
          </TextField>
        </FlexGridItem>

        <FlexGridItem col='1/2'>
          <TextField
            id='outlined-select-currency-native'
            select
            label='Type'
            value={userStatus}
            SelectProps={{
              native: true
            }}
            disabled
            variant='outlined'
          >
            <option>Internal User</option>
          </TextField>
        </FlexGridItem>
      </FlexGridRow>
      <Spacer xs />
    </StatusBox>
  )
}
