import React from 'react'

import {useApi} from 'fairlight'
import {Formik, FormikHelpers} from 'formik'
import * as Yup from 'yup'

import {RebalancingRuleEndpoints} from '@d1g1t/api/endpoints'
import {
  IRebalancingRule,
  REBALANCINGRULE_TRADE_RECOMMENDATION_RULE
} from '@d1g1t/api/models'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {FormActions} from '@d1g1t/shared/components/form-actions'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {Modal, ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'

enum ADD_RULE_FIELD_NAME {
  name = 'name',
  tradeRecommendationRule = 'tradeRecommendationRule'
}

interface IAddRuleFormValues {
  [ADD_RULE_FIELD_NAME.name]: string
  [ADD_RULE_FIELD_NAME.tradeRecommendationRule]: REBALANCINGRULE_TRADE_RECOMMENDATION_RULE
}

const INITIAL_VALUES = {
  name: '',
  tradeRecommendationRule: REBALANCINGRULE_TRADE_RECOMMENDATION_RULE.TARGET
}

const ADD_RULE_VALIDATION_SCHEMA = Yup.object({
  [ADD_RULE_FIELD_NAME.name]: Yup.string().required().label('Rule Name'),
  [ADD_RULE_FIELD_NAME.tradeRecommendationRule]: Yup.string()
    .required()
    .label('Trade Recommendation Target')
})

interface IAddRuleProps {
  onCreate(rule: IRebalancingRule)
  onClose()
}

export const AddRule: React.FC<IAddRuleProps> = function AddView(props) {
  const api = useApi()
  const {handleFormError} = useErrorHandler()
  const {showSnackbar} = useSnackbar()

  const handleSubmit = async (
    values: IAddRuleFormValues,
    formikBag: FormikHelpers<IAddRuleFormValues>
  ) => {
    try {
      const rule = await api.request(RebalancingRuleEndpoints.create(values))
      showSnackbar({
        variant: 'success',
        message: `Rebalancing rule '${rule.name}' has been created.`
      })
      props.onClose()
      props.onCreate(rule)
    } catch (error) {
      handleFormError(
        error,
        formikBag,
        INITIAL_VALUES,
        'An unexpected error occurred while creating the rule.'
      )
    }
  }

  return (
    <Modal open narrow noMinHeight title='Add Rule' onClose={props.onClose}>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={ADD_RULE_VALIDATION_SCHEMA}
      >
        {({handleSubmit, isSubmitting}) => (
          <form onSubmit={handleSubmit} style={{display: 'contents'}}>
            <ControlStateProvider loading={isSubmitting}>
              <ModalContent>
                <OutlinedInputField
                  name={ADD_RULE_FIELD_NAME.name}
                  label='Rule Name'
                  outlinedInputProps={{
                    autoFocus: true
                  }}
                />
              </ModalContent>
              <ModalActions>
                <FormActions formType='create' onCancel={props.onClose} />
              </ModalActions>
            </ControlStateProvider>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
