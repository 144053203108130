import {TRADEORDER_OPERATION} from '@d1g1t/api/models'

export interface ITradeSelectFieldProps {
  name: string
  showSwitch?: boolean
  buySellOnly?: boolean
  disabled?: boolean
}

export const BUY_SELL_OPTIONS: IValueLabelOption<TRADEORDER_OPERATION>[] = [
  {
    value: TRADEORDER_OPERATION.BUY,
    label: 'Buy'
  },
  {
    value: TRADEORDER_OPERATION.SELL,
    label: 'Sell'
  }
]

export const BUY_SELL_ALL_OPTIONS: IValueLabelOption<TRADEORDER_OPERATION>[] = [
  {
    value: TRADEORDER_OPERATION.BUY,
    label: 'Buy'
  },
  {
    value: TRADEORDER_OPERATION.SELL,
    label: 'Sell'
  },
  {
    value: TRADEORDER_OPERATION.SELL_ALL,
    label: 'Sell All'
  }
]

export const BUY_SELL_SWITCH_OPTIONS: IValueLabelOption<TRADEORDER_OPERATION>[] =
  [
    ...BUY_SELL_ALL_OPTIONS,
    {
      value: TRADEORDER_OPERATION.SWITCH,
      label: 'Switch'
    },
    {
      value: TRADEORDER_OPERATION.SWITCH_ALL,
      label: 'Switch All'
    }
  ]
