import React from 'react'

import * as css from './styles.scss'

/**
 * Empty state for list editors (metrics, filters, groups, etc.)
 */
export const ListEditorEmptyState: React.FC = (props) => {
  return <div className={css.emptyStateText}>{props.children}</div>
}

interface IListEditorEmptyStateButtonProps {
  onClick(): void
}

/**
 * Empty state button (stlyed like a link with a text underline)
 */
export const ListEditorEmptyStateButton: React.FC<
  IListEditorEmptyStateButtonProps
> = (props) => {
  return (
    <button
      type='button'
      className={css.emptyStateButton}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}
