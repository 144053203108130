import React, {useMemo} from 'react'

import {useApiQuery} from 'fairlight'

import {
  AccountEndpoints,
  InstrumentEndpoints,
  TradingEndpoints
} from '@d1g1t/api/endpoints'
import {
  TRADEORDER_DIVIDEND_TREATMENT_OPTIONS,
  TRADEORDER_EXPIRY_TYPE,
  TRADEORDER_EXPIRY_TYPE_OPTIONS,
  TRADEORDER_OPERATION,
  TRADEORDER_OPERATION_OPTIONS,
  TRADEORDER_TYPE
} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {extractIdFromUrl} from '@d1g1t/lib/url'
import {getLabelWithValue} from '@d1g1t/lib/value-label'

import {Flex, FlexGridItem, FlexGridRow} from '@d1g1t/shared/components/flex'
import {Alert} from '@d1g1t/shared/components/mui/alert'
import {P} from '@d1g1t/shared/components/typography'
import {FormattedNumber} from '@d1g1t/shared/wrappers/formatter'

import {ITradeOrderFormValues} from '../'
import {IEquitiesEtfsFormValues} from '../equities-etfs/typings'
import {isDSCFree, qtyChangeIsNumber} from '../lib'
import {IMutualFundFormValues} from '../mutual-funds/typings'
import {getCircleFontSize, unitsOrAmount} from './lib'

import css from './style.scss'

export interface ITradeConfirmationContentProps {
  data: ITradeOrderFormValues
  /**
   * Trade validation error messages.
   */
  tradingValidationMessages: []
}

export const TradeConfirmationContent: React.FC<
  ITradeConfirmationContentProps
> = (props) => {
  const {accountUrl, operation, instrumentUrl, qty, qtyType} = props.data

  const {
    type,
    limitPrice,
    market,
    expiryType,
    expiryDate,
    broker: brokerUrl
  } = props.data as IEquitiesEtfsFormValues

  const {dividendTreatment, fundCode, cusip} =
    props.data as IMutualFundFormValues

  const [marketsList] = useApiQuery(TradingEndpoints.markets(), {
    fetchPolicy: 'cache-first'
  })

  const [brokers] = useApiQuery(TradingEndpoints.brokers(), {
    fetchPolicy: 'cache-first'
  })

  const [account] = useApiQuery(
    AccountEndpoints.findById(extractIdFromUrl(accountUrl)),
    {
      fetchPolicy: 'cache-and-fetch'
    }
  )

  const [instrument] = useApiQuery(
    instrumentUrl &&
      InstrumentEndpoints.findById(extractIdFromUrl(instrumentUrl)),
    {
      fetchPolicy: 'cache-and-fetch'
    }
  )

  const brokerName = useMemo(() => {
    if (!brokers.data) {
      return null
    }

    const broker = brokers.data.results.find((b) => b.url === brokerUrl)

    if (!!broker) {
      return `${broker.name} (${broker.cuid})`
    }

    return null
  }, [brokers, brokerUrl])

  const isOperation = {
    BUY: operation === TRADEORDER_OPERATION.BUY,
    SELL: [TRADEORDER_OPERATION.SELL, TRADEORDER_OPERATION.SELL_ALL].includes(
      operation
    ),
    SWITCH: [
      TRADEORDER_OPERATION.SWITCH,
      TRADEORDER_OPERATION.SWITCH_ALL
    ].includes(operation)
  }

  const marketOrDividendTreatment = (() => {
    if (market && marketsList.data) {
      const marketCountry = marketsList.data.results.find(
        (mkt) => mkt.url === market
      ).country

      return (
        <>
          <div className={css.miniHeader}>Market</div>
          <div
            data-market
            className={classNames(css.dateMarketBroker, css.semiBold)}
          >
            {marketCountry}
          </div>
        </>
      )
    }

    if (dividendTreatment) {
      return (
        <>
          <div className={css.miniHeader}>Dividend Treatment</div>
          <div
            data-dividend-treatment
            className={classNames(css.dateMarketBroker, css.semiBold)}
          >
            {
              TRADEORDER_DIVIDEND_TREATMENT_OPTIONS.find((option) => {
                return option.value === dividendTreatment
              }).label
            }
          </div>
        </>
      )
    }

    return null
  })()

  const accountLimitSwitchInto = (() => {
    if (type === TRADEORDER_TYPE.LIMIT && !accountUrl) {
      return (
        <>
          <div className={css.miniHeader}>Limit</div>
          <div
            data-limit-price-top-middle
            className={classNames(css.tickerAmount, css.semiBold, {
              [css.buy]: isOperation.BUY,
              [css.sell]: isOperation.SELL
            })}
          >
            {limitPrice}
          </div>
          {instrumentUrl && (
            <div className={css.underDetail}>
              {instrument.data?.currency.name}
            </div>
          )}
        </>
      )
    }

    if (accountUrl && !isOperation.SWITCH) {
      return (
        <>
          <div className={css.miniHeader}>Account ID</div>
          <div
            className={classNames(css.tickerAmount, css.semiBold, {
              [css.buy]: isOperation.BUY,
              [css.sell]: isOperation.SELL
            })}
          >
            {account.data?.firmProvidedKey}
          </div>
        </>
      )
    }

    if (fundCode && isOperation.SWITCH) {
      return (
        <>
          <div className={css.miniHeader}>Switch into:</div>
          <div
            className={classNames(
              css.tickerAmount,
              css.semiBold,
              css.switchColor
            )}
          >
            {fundCode}
          </div>
        </>
      )
    }

    return null
  })()

  const securityOrMutualFund = (() => {
    if (instrumentUrl) {
      return (
        <>
          <div className={css.miniHeader}>Security</div>
          <div
            data-ticker
            className={classNames(css.tickerAmount, css.semiBold, {
              [css.buy]: isOperation.BUY,
              [css.sell]: isOperation.SELL
            })}
          >
            {instrument.data?.tickerShort}
          </div>
          {instrumentUrl && (
            <div data-instrument-name className={css.underDetail}>
              {instrument.data?.name}
            </div>
          )}
        </>
      )
    }

    if (fundCode) {
      return (
        <>
          <div className={css.miniHeader}>
            {isOperation.SWITCH ? 'Switch out of:' : 'Fund Code'}
          </div>
          <div
            data-fund-code-cusip
            className={classNames(css.tickerAmount, css.semiBold, {
              [css.buy]: isOperation.BUY,
              [css.sell]: isOperation.SELL,
              [css.switchColor]: isOperation.SWITCH
            })}
          >
            {isOperation.SWITCH ? cusip : fundCode}
          </div>
        </>
      )
    }

    return null
  })()

  const accountOrLimitPrice = (() => {
    if (isOperation.SWITCH && accountUrl) {
      return (
        <>
          <div className={css.miniHeader}>Account ID</div>
          <div
            data-account
            className={classNames(
              css.tickerAmount,
              css.semiBold,
              css.switchColor
            )}
          >
            {account.data?.firmProvidedKey}
          </div>
        </>
      )
    }

    if (accountUrl && limitPrice && type === TRADEORDER_TYPE.LIMIT) {
      return (
        <>
          <div className={css.miniHeader}>Limit</div>
          <div
            data-limit-price-top-right
            className={classNames(css.tickerAmount, css.semiBold, {
              [css.buy]: isOperation.BUY,
              [css.sell]: isOperation.SELL
            })}
          >
            {limitPrice}
          </div>
          {instrumentUrl && (
            <div className={css.underDetail}>
              {instrument.data?.currency.name}
            </div>
          )}
        </>
      )
    }

    return null
  })()

  const unitsLabel = (() => {
    if (isDSCFree(qtyType)) {
      return 'DSC Free'
    }

    if (qtyChangeIsNumber(qty)) {
      return <FormattedNumber value={qty} />
    }
    return qty
  })()

  return (
    <div className={css.confirmModalContent}>
      {props.tradingValidationMessages.length > 0 && (
        <Alert severity='error'>
          {props.tradingValidationMessages.map((msg) => (
            <P key={msg}>{msg}</P>
          ))}
        </Alert>
      )}
      <Flex
        alignCenter
        className={classNames(
          css.centreTitleDivider,
          css.bold,
          css.capitalize,
          {
            [css.buy]: isOperation.BUY,
            [css.sell]: isOperation.SELL,
            [css.switchColor]: isOperation.SWITCH,
            [css.buyBorder]: isOperation.BUY,
            [css.sellBorder]: isOperation.SELL,
            [css.switchBorder]: isOperation.SWITCH
          }
        )}
      >
        {`${getLabelWithValue(operation, TRADEORDER_OPERATION_OPTIONS)} ORDER`}
      </Flex>
      <FlexGridRow className={css.confirmOrderDetails}>
        <FlexGridItem col='1/3'>
          <Flex
            data-testid='units_circle'
            justifyCenter
            column
            className={classNames(css.unitsCircle, {
              [css.buyCircle]: isOperation.BUY,
              [css.sellCircle]: isOperation.SELL,
              [css.switchCircle]: isOperation.SWITCH
            })}
          >
            <div
              style={{
                fontSize: getCircleFontSize(
                  qtyChangeIsNumber(qty) ? (qty as number) : null,
                  qtyType
                )
              }}
              className={css.bold}
            >
              {unitsLabel}
            </div>
            <div className={classNames(css.unitsCurrency, css.semiBold)}>
              {instrument.data && unitsOrAmount(props.data, instrument.data)}
            </div>
          </Flex>
        </FlexGridItem>
        <FlexGridItem col='2/3'>
          <FlexGridRow className={css.confirmInfoTopRow}>
            <FlexGridItem col='2/4'>
              <Flex column>{securityOrMutualFund} </Flex>
            </FlexGridItem>
            <FlexGridItem col='1/4'>{accountLimitSwitchInto}</FlexGridItem>
            <FlexGridItem col='1/4'>{accountOrLimitPrice} </FlexGridItem>
          </FlexGridRow>
          <FlexGridRow className={css.confirmInfoBottomRow}>
            <FlexGridItem col='1/4'>{marketOrDividendTreatment}</FlexGridItem>
            <FlexGridItem col='1/4'>
              {expiryType && (
                <>
                  <div className={css.miniHeader}>Duration</div>
                  <div
                    data-good-until
                    className={classNames(css.dateMarketBroker, css.semiBold)}
                  >
                    {expiryType === TRADEORDER_EXPIRY_TYPE.GTD
                      ? expiryDate
                      : TRADEORDER_EXPIRY_TYPE_OPTIONS.find((option) => {
                          return option.value === expiryType
                        })?.label || ''}
                  </div>
                </>
              )}
            </FlexGridItem>
            <FlexGridItem col='2/4'>
              {brokerUrl && brokerName && (
                <>
                  <div className={css.miniHeader}>Broker</div>
                  <div
                    data-broker
                    className={classNames(css.dateMarketBroker, css.semiBold)}
                  >
                    {brokerName}
                  </div>
                </>
              )}
            </FlexGridItem>
          </FlexGridRow>
        </FlexGridItem>
      </FlexGridRow>
      <Flex
        alignCenter
        className={classNames(css.lineDivider, {
          [css.buyBorder]: isOperation.BUY,
          [css.sellBorder]: isOperation.SELL,
          [css.switchBorder]: isOperation.SWITCH
        })}
      />
    </div>
  )
}
