import React from 'react'

import {isEmpty} from 'lodash'

import {Icon} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import {classNames} from '@d1g1t/lib/class-names'

import {IconMenu} from '@d1g1t/shared/components/mui/icon-menu'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Text} from '@d1g1t/shared/components/typography'
import {ROW_STYLE_STATE} from '@d1g1t/shared/containers/standard-table'
import {IStandardTableAction} from '@d1g1t/shared/containers/standard-table/typings'
import {useStyles} from '@d1g1t/shared/containers/standard-table/use-styles'

import * as css from './styles.scss'

export interface IActionDropdownProps {
  items: IStandardTableAction[]
  rowIndex?: number
  columnIndex?: number
  itemId?: string
  categoryId?: string
}

export const ActionDropdown: React.FC<IActionDropdownProps> = (props) => {
  const classes = useStyles()
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  return (
    <IconMenu
      IconButtonProps={{
        style: {
          // anything larger than 8px will exceed the height of the cell
          // and cause weird jumpy behaviour in the table when focused
          padding: '8px'
        }
      }}
      data-testid='button-standard-table-action-menu'
      onClick={handleClick}
      icon={
        <MoreHorizIcon
          fontSize='small'
          className={classNames(classes.actionIcon, css.icon)}
        />
      }
      options={props.items}
      MenuProps={
        {
          'data-row': props.rowIndex,
          'data-column': props.columnIndex,
          'data-item-id': props.itemId,
          'data-category-id': props.categoryId
        } as any
      }
    />
  )
}

export interface IQuickActionsProps {
  items: IStandardTableAction[]
  hoverRow: boolean
  rowStyleState: ROW_STYLE_STATE
  allActionsPrimary: boolean
}

export const QuickActions: React.FC<IQuickActionsProps> = (props) => {
  if (isEmpty(props.items)) {
    return null
  }

  return (
    <>
      {props.items.map((action, index) => {
        const {tooltip, label, linkedRowStyleState, ...iconProps} = action

        const alwaysShowIcon =
          linkedRowStyleState && props.rowStyleState === linkedRowStyleState

        return (
          <Tooltip
            key={`quick_access_${Date.now}_${index}`}
            interactive
            placement='bottom'
            title={<Text>{action.label}</Text>}
          >
            <Icon
              {...iconProps}
              className={classNames({[css.iconOpacity]: !alwaysShowIcon})}
              style={{
                visibility:
                  props.allActionsPrimary || alwaysShowIcon || props.hoverRow
                    ? undefined
                    : 'hidden'
              }}
            >
              {action.icon}
            </Icon>
          </Tooltip>
        )
      })}
    </>
  )
}
