import React from 'react'

import MuiFormHelperText, {
  FormHelperTextProps
} from '@material-ui/core/FormHelperText'

export interface IFormHelperTextProps extends FormHelperTextProps {}

export const FormHelperText: React.FC<IFormHelperTextProps> = (props) => (
  <MuiFormHelperText {...props} />
)
