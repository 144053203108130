import {useEffect} from 'react'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {useUserProfile} from '@d1g1t/shared/wrappers/user-profile'

export const useDefaultUserSettingReportingCurrency = () => {
  const [{profile}] = useUserProfile()
  const [, {updateCalculationSettings}] = useCalculationSettings()
  useEffect(() => {
    if (profile?.defaultCurrency) {
      updateCalculationSettings({
        currency: extractIdFromUrl(profile?.defaultCurrency)
      })
    }
  }, [profile?.defaultCurrency])
}
