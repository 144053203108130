import React, {useState} from 'react'
import {DraggableCore, DraggableData} from 'react-draggable'

import DragHandleIcon from '@material-ui/icons/DragHandle'

import {Card} from '@d1g1t/shared/components/card'
import {Flex} from '@d1g1t/shared/components/flex'
import {Divider} from '@d1g1t/shared/components/mui/divider'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'

import {
  ITradePreviewProps,
  TradePreview
} from '@d1g1t/advisor/containers/trade-preview'

import * as css from './style.scss'

interface IRecommendedTradesProps extends ITradePreviewProps {}
const FIXED_BOTTOM_BAR_MIN_HEIGHT = 85
const FIXED_BOTTOM_BAR_MAX_HEIGHT = 750
const FIXED_BOTTOM_BAR_SNAP_HEIGHT = 550

/**
 * Recommended Trades drawer component.
 */
export const RecommendedTrades: React.FC<IRecommendedTradesProps> = (props) => {
  const [recommendedTradesHeight, setRecommendedTradesHeight] = useState(
    FIXED_BOTTOM_BAR_MIN_HEIGHT
  )

  const [draggedBetweenMouseDownAndUp, setDraggedBetweenMouseDownAndUp] =
    useState(false) // Used to distinguish drag and click event

  /**
   * Adds `deltaY` to `recommendedTradesHeight` and applies constraints.
   *
   * @param deltaY - the change in Y direction
   * @param scale - positive or negative depending on the edge dragged
   * @param maxHeight - max height
   * @param minHeight - min height
   */
  const getConstrainedHeight = (
    deltaY: number,
    constraints: {
      scale: number
      maxHeight?: number
      minHeight?: number
    }
  ) => {
    const {scale, maxHeight, minHeight} = constraints
    let height = recommendedTradesHeight + deltaY * scale

    if (minHeight) {
      height = Math.max(minHeight, height)
    }
    if (maxHeight) {
      height = Math.min(maxHeight, height)
    }

    return height
  }

  const handleDrag = (e, data: DraggableData) => {
    const newHeight = getConstrainedHeight(data.deltaY, {
      scale: -1, // top edge is dragged
      maxHeight: FIXED_BOTTOM_BAR_MAX_HEIGHT,
      minHeight: FIXED_BOTTOM_BAR_MIN_HEIGHT
    })
    setRecommendedTradesHeight(newHeight)
  }

  return (
    <DraggableCore handle='.handle' grid={[10, 10]} onDrag={handleDrag}>
      <div
        style={{height: recommendedTradesHeight, zIndex: 1000}}
        className={css.recommendedTradesBar}
      >
        <Flex
          className='handle'
          style={{cursor: 'row-resize'}}
          grow
          justifyCenter
          onMouseDown={() => {
            setDraggedBetweenMouseDownAndUp(false) // reset it
          }}
          onMouseMove={() => {
            setDraggedBetweenMouseDownAndUp(true) // dragging
          }}
          onMouseUp={() => {
            if (!draggedBetweenMouseDownAndUp) {
              // Snap it open/close if did not drag
              setRecommendedTradesHeight(
                recommendedTradesHeight === FIXED_BOTTOM_BAR_MIN_HEIGHT
                  ? FIXED_BOTTOM_BAR_SNAP_HEIGHT
                  : FIXED_BOTTOM_BAR_MIN_HEIGHT
              )
            }
          }}
        >
          <DragHandleIcon
            data-testid='drag-handle-recommended-trades'
            fontSize='small'
          />
        </Flex>
        <Divider />
        <Card fixedHeight style={{height: recommendedTradesHeight + 1}}>
          <ErrorBoundary resetId={`${props.loadingChartData}`}>
            <TradePreview {...props} />
          </ErrorBoundary>
        </Card>
      </div>
    </DraggableCore>
  )
}
