import React, {CSSProperties, useMemo} from 'react'

import {EXPAND_COLOURS} from '@d1g1t/shared/containers/standard-table/constants'

import {isFirstExpandedCategory} from '../../lib'
import {IStandardTableCategory} from '../../typings'
import {IExpandedHeaderCellProps} from './typings'

import * as css from './styles.scss'

/**
 * ExpandedHeaderCell is responsible for displaying borders indicating category expansions
 */
export const ExpandedHeaderCell: React.FC<IExpandedHeaderCellProps> =
  React.memo((props) => {
    const borderStyle = useMemo(
      () => getBorderStyles(props.category, props.level),
      [props.category, props.level]
    )

    return (
      <div
        className={css.expandedHeaderCell}
        style={{
          ...props.style,
          ...borderStyle
        }}
      />
    )
  })

function getBorderStyles(
  columnCategory: IStandardTableCategory,
  level: number
): CSSProperties {
  const style: CSSProperties = {}

  const borderLeftColor = getLeftBorderColor(columnCategory, level)

  if (borderLeftColor) {
    Object.assign(style, {
      borderLeftWidth: '3px',
      borderLeftStyle: 'solid',
      borderLeftColor
    })
  }

  const borderBottomColor = getBottomBorderColor(columnCategory, level)

  if (borderBottomColor) {
    Object.assign(style, {
      borderBottomWidth: '3px',
      borderBottomStyle: 'solid',
      borderBottomColor
    })
  }

  return style
}

function getLeftBorderColor(
  columnCategory: IStandardTableCategory,
  level: number
): string {
  if (
    !columnCategory ||
    level === 0 ||
    !isFirstExpandedCategory(columnCategory)
  ) {
    return null
  }

  const expansionCategory = getCategoryForLevel(columnCategory, level)

  if (!isFirstExpandedCategory(expansionCategory)) {
    return null
  }

  if (
    expansionCategory.level === level &&
    expansionCategory.parent &&
    !expansionCategory.hiddenWhenExpanded &&
    expansionCategory.parent.categories.find(
      (category) => !category.options?.hidden
    )?.id !== expansionCategory.id
  ) {
    return null
  }

  const parentExpansionCategory = getCategoryForLevel(
    columnCategory.parent ? columnCategory.parent : columnCategory,
    level - 1
  )

  if (
    parentExpansionCategory &&
    (isExpandedAndHidden(parentExpansionCategory)
      ? expansionCategory.level - 1
      : expansionCategory.level) < level
  ) {
    return EXPAND_COLOURS[parentExpansionCategory.level].border
  }

  return null
}

function isExpandedAndHidden(category: IStandardTableCategory) {
  return category.expanded && category.hiddenWhenExpanded
}

function getBottomBorderColor(
  columnCategory: IStandardTableCategory,
  level: number
): string {
  if (!columnCategory) {
    return null
  }

  const categoryForLevel = getControlCategoryForLevel(columnCategory, level)

  if (
    categoryForLevel?.expanded &&
    categoryForLevel.categories?.some(
      (childCategory) => !childCategory.options?.hidden
    )
  ) {
    return EXPAND_COLOURS[level].border
  }

  return null
}

function getCategoryForLevel(
  category: IStandardTableCategory,
  targetExpansionLevel: number
): IStandardTableCategory {
  if (!category) {
    return null
  }

  if (targetExpansionLevel >= category.level) {
    return category
  }

  return getCategoryForLevel(category.parent, targetExpansionLevel)
}

function getControlCategoryForLevel(
  category: IStandardTableCategory,
  targetExpansionLevel: number
): IStandardTableCategory {
  if (!category) {
    return null
  }

  if (targetExpansionLevel === category.level) {
    return category
  }

  return getControlCategoryForLevel(category.parent, targetExpansionLevel)
}
