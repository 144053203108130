import React from 'react'

import {round} from 'lodash'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {Flex} from '@d1g1t/shared/components/flex'

import {Chip} from '@d1g1t/advisor/components/chip'

import {
  IDirectiveInputsBySecurityEntityId,
  ILoadedSecurity
} from '../../typings'

interface IExpansionPanelSummaryChipsProps {
  directiveInputsBySecurityEntityId: IDirectiveInputsBySecurityEntityId
  securities: ILoadedSecurity[]
}

export const ExpansionPanelSummaryChips: React.FC<
  IExpansionPanelSummaryChipsProps
> = ({securities, directiveInputsBySecurityEntityId}) => {
  // Remove securities without target values
  const filteredSecurities = securities?.filter((item) => {
    const input = directiveInputsBySecurityEntityId[extractIdFromUrl(item.url)]
    return input.target_weight != null
  })

  const overflowTooltip = (() => {
    if (!filteredSecurities || filteredSecurities.length <= 5) {
      return null
    }

    return filteredSecurities
      .slice(5, filteredSecurities.length)
      .map((item) => {
        const input =
          directiveInputsBySecurityEntityId[extractIdFromUrl(item.url)]
        const targetWeight = round(input.target_weight * 100, 2)

        // Overridden or initial market price of the instrument
        const marketPrice =
          round(input.market_price, 2) || round(item.initialMarketPrice, 2)

        return `${item.label} ${marketPrice} @ ${targetWeight}%`
      })
      .toString()
  })()

  return (
    <>
      <Flex style={{marginLeft: '16px'}}>
        {filteredSecurities?.slice(0, 5).map((item) => {
          const input =
            directiveInputsBySecurityEntityId[extractIdFromUrl(item.url)]
          const targetWeight = round(input.target_weight * 100, 2)
          // Overridden or initial market price of the instrument
          const marketPrice =
            round(input.market_price, 2) || round(item.initialMarketPrice, 2)

          return (
            <Chip
              key={item.url}
              text={`${item.label} ${marketPrice} @ ${targetWeight}%`}
            />
          )
        })}

        {filteredSecurities?.length > 5 && (
          <Chip
            tooltip={overflowTooltip}
            text={`+${filteredSecurities?.length - 5} more`}
          />
        )}
      </Flex>
    </>
  )
}
