import React, {useMemo, useState} from 'react'

import {useApi} from 'fairlight'
import produce from 'immer'
import {isEmpty} from 'lodash'

import AddIcon from '@material-ui/icons/AddCircleOutline'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import EditIcon from '@material-ui/icons/Edit'
import GraphIcon from '@material-ui/icons/InsertChart'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import LogScaleChartIcon from '@material-ui/icons/ShowChart'
import TableIcon from '@material-ui/icons/TableChart'

import {FEATURE_FLAGS} from '@d1g1t/config/feature-flags'

import {InstrumentEndpoints, MarketPriceEndpoints} from '@d1g1t/api/endpoints'
import {
  IMarketPrice,
  MARKETPRICE_SOURCE,
  MARKETPRICE_SOURCE_OPTIONS
} from '@d1g1t/api/models'

import {DATE_OPTION_DEFAULT} from '@d1g1t/lib/date-range'
import {useToggleState} from '@d1g1t/lib/hooks'
import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'

import {confirm} from '@d1g1t/shared/components/confirmation'
import {Flex, FlexChild} from '@d1g1t/shared/components/flex'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Modal, ModalContent} from '@d1g1t/shared/components/modal'
import {Button} from '@d1g1t/shared/components/mui/button'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {IconMenu} from '@d1g1t/shared/components/mui/icon-menu'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {DateRangeSelector} from '@d1g1t/shared/components/range-selector'
import {StateBlock} from '@d1g1t/shared/components/state-block'
import {P, Text} from '@d1g1t/shared/components/typography'
import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'
import {ReturnsAreaChart} from '@d1g1t/shared/containers/calculation-widgets/trend-analysis/components/returns-area-chart'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {StandardTable} from '@d1g1t/shared/containers/standard-table'
import {IStandardTableCategory} from '@d1g1t/shared/containers/standard-table/typings'
import {
  ErrorBoundary,
  ModalContentsErrorFallback
} from '@d1g1t/shared/wrappers/error-boundary'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useDateFormatter} from '@d1g1t/shared/wrappers/formatter'
import {useMarketPriceHistory} from '@d1g1t/shared/wrappers/market-price-history'
import {useUserProfile} from '@d1g1t/shared/wrappers/user-profile'

import * as css from '@d1g1t/shared/containers/view-options/components/view-icons/styles.scss'

export interface IMarketPriceHistoryModalProps {
  onClose()
  securityId: string
  securityName: string
  /**
   * If true, modal will open in edit mode.
   */
  editMode?: boolean
}

export enum MARKET_PRICE_HISTORY_CATEGORY_IDS {
  DATE = 'date',
  MARKET_PRICE = 'market_price'
}

enum MARKET_PRICE_KEYS {
  DATE = 'date',
  CLOSE = 'close'
}

const MARKET_PRICE_CATEGORY_ID_TO_KEY_MAP = {
  [MARKET_PRICE_HISTORY_CATEGORY_IDS.DATE]: MARKET_PRICE_KEYS.DATE,
  [MARKET_PRICE_HISTORY_CATEGORY_IDS.MARKET_PRICE]: MARKET_PRICE_KEYS.CLOSE
}

export const NEW_MARKET_PRICE_ITEM_PREFIX = 'new-market-price'

const confirmation = () =>
  confirm({
    title: 'Cancel Changes',
    content: (
      <P>
        If you choose to cancel your changes then all modifications to the
        market prices that you have made since your last save will be reverted.
        Are you sure you wish to cancel your changes?
      </P>
    ),
    confirmLabel: 'Yes',
    cancelLabel: 'No'
  })

export const MarketPriceHistoryChart: React.FC<
  IMarketPriceHistoryModalProps
> = (props) => {
  return (
    <Modal fullscreen open title={props.securityName} onClose={props.onClose}>
      <ErrorBoundary
        resetId='no-reset'
        fallback={<ModalContentsErrorFallback onClose={props.onClose} />}
      >
        <MarketPriceHistoryModalContents {...props} />
      </ErrorBoundary>
    </Modal>
  )
}

const MarketPriceHistoryModalContents: React.FC<
  IMarketPriceHistoryModalProps
> = (props) => {
  const api = useApi()
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()

  const dateFormatter = useDateFormatter()
  const [userProfile] = useUserProfile()

  const [source, setSource] = useState(MARKETPRICE_SOURCE.CUSTODIAN)
  const [dateRange, setDateRange] = useState(DATE_OPTION_DEFAULT)

  const [showAsChart, toggleTableChartView] = useToggleState(!props.editMode)

  const [editMode, toggleEditMode] = useToggleState(!!props.editMode)

  const [showInLogScale, toggleChartScale] = useToggleState(false)

  const [sendingUpdatedMarketPrices, toggleSendingUpdatedMarketPrices] =
    useToggleState(false)

  const [
    marketPriceHistory,
    {exportExcel, refresh, temporaryUpdateMarketPrices}
  ] = useMarketPriceHistory(props.securityId, dateRange, source)

  const [updatedMarketPrices, setUpdatedMarketPrices] = useState<{
    [id: string]: Partial<IMarketPrice>
  }>({})

  const handleToggleEditMode = () => {
    if (
      editMode &&
      marketPriceHistory.data.items.find((item) =>
        item.id.includes(NEW_MARKET_PRICE_ITEM_PREFIX)
      )
    ) {
      temporaryUpdateMarketPrices(
        marketPriceHistory.data.replaceItems(
          marketPriceHistory.data.items.filter(
            (item) => !item.id.includes(NEW_MARKET_PRICE_ITEM_PREFIX)
          )
        )
      )
    }

    toggleEditMode()
  }

  const handleAddRowClick = () => {
    let newMarketPriceCount = 0

    for (const item of marketPriceHistory.data.items) {
      if (!item.id.includes(NEW_MARKET_PRICE_ITEM_PREFIX)) {
        break
      }

      newMarketPriceCount++
    }

    temporaryUpdateMarketPrices(
      marketPriceHistory.data.replaceItems([
        {
          id: `${NEW_MARKET_PRICE_ITEM_PREFIX}-${newMarketPriceCount - 1}`,
          data: [
            {
              categoryId: MARKET_PRICE_HISTORY_CATEGORY_IDS.DATE,
              value: null
            },
            {
              categoryId: MARKET_PRICE_HISTORY_CATEGORY_IDS.MARKET_PRICE,
              value: null
            }
          ]
        },
        ...marketPriceHistory.data.items
      ])
    )
  }

  const handleSaveChanges = async () => {
    try {
      const promises = Object.keys(updatedMarketPrices).map((id) => {
        if (id.includes(NEW_MARKET_PRICE_ITEM_PREFIX)) {
          return api.request(
            MarketPriceEndpoints.create(updatedMarketPrices[id] as IMarketPrice)
          )
        }
        return api.request(
          MarketPriceEndpoints.partialUpdate(id, updatedMarketPrices[id])
        )
      })

      await Promise.all(promises)

      showSnackbar({
        variant: 'success',
        message: 'Successfully updated market prices'
      })

      toggleEditMode()
      setUpdatedMarketPrices({})
      refresh()
    } catch (error) {
      handleUnexpectedError(error, 'Cannot update market prices')
    } finally {
      toggleSendingUpdatedMarketPrices(false)
    }
  }

  const tableData: StandardResponse = useMemo(() => {
    if (!marketPriceHistory.data) {
      return null
    }

    if (!editMode) {
      return marketPriceHistory.data
    }

    return marketPriceHistory.data.updateCategoryOptions(
      [
        MARKET_PRICE_HISTORY_CATEGORY_IDS.DATE,
        MARKET_PRICE_HISTORY_CATEGORY_IDS.MARKET_PRICE
      ],
      {
        editable: editMode,
        decimals: 6
      }
    )
  }, [marketPriceHistory.data, editMode])

  const handleUpdateMarketPrices = (
    item: StandardResponseItem,
    category: IStandardTableCategory,
    value
  ) => {
    setUpdatedMarketPrices(
      produce(updatedMarketPrices, (draft) => {
        draft[item.id] = draft[item.id] || {}
        draft[item.id].source = source
        draft[item.id][MARKET_PRICE_CATEGORY_ID_TO_KEY_MAP[category.id]] =
          MARKET_PRICE_CATEGORY_ID_TO_KEY_MAP[category.id] ===
          MARKET_PRICE_HISTORY_CATEGORY_IDS.DATE
            ? dateFormatter.format(value)
            : value

        if (
          !draft[item.id].url &&
          !item.id.includes(NEW_MARKET_PRICE_ITEM_PREFIX)
        ) {
          draft[item.id].url = api.buildUrl(
            MarketPriceEndpoints.pathToResource(item.id)
          )
        }

        if (item.id.includes(NEW_MARKET_PRICE_ITEM_PREFIX)) {
          draft[item.id].instrument = api.buildUrl(
            InstrumentEndpoints.pathToResource(props.securityId)
          )
        }
      })
    )

    temporaryUpdateMarketPrices(
      marketPriceHistory.data.updateValue(category.id, item.id, value)
    )
  }

  const handleCancelChanges = async () => {
    if (!(await confirmation())) {
      return
    }

    refresh()
    handleToggleEditMode()
    setUpdatedMarketPrices({})
  }

  const handleToggleTableChartView = async () => {
    if (editMode && !showAsChart && !(await confirmation())) {
      return
    }

    if (!showAsChart && editMode) {
      handleToggleEditMode()
    }

    toggleTableChartView()
  }

  const renderAreaChartOrTable = useMemo(() => {
    if (!marketPriceHistory.data || !tableData) {
      return null
    }

    if (marketPriceHistory.data.items.length === 0) {
      return <Text>This security does not have market price history</Text>
    }

    if (showAsChart) {
      return (
        <ReturnsAreaChart
          name={{value: null, label: null}}
          showInLogScale={showInLogScale}
          series={[
            {
              name: marketPriceHistory.data.categories[1].name,
              data: marketPriceHistory.data,
              id: 'dataseries'
            }
          ]}
        />
      )
    }

    return (
      <StandardTable
        id='market-price-history'
        table={tableData}
        minColumnWidth={160}
        debounceDelayTime={250}
        disableSorting={editMode}
        onCellValueChange={handleUpdateMarketPrices}
      />
    )
  }, [
    editMode,
    marketPriceHistory.data,
    showAsChart,
    showInLogScale,
    tableData
  ])

  return (
    <LoadingContainer loading={marketPriceHistory.loading}>
      <ModalContent>
        <Flex column fullHeight>
          <Flex toolbar>
            <Flex alignCenter>
              {editMode && !showAsChart && (
                <>
                  <StateBlock text='EDIT MODE' />
                  <Button outlined spaceLeft onClick={handleAddRowClick}>
                    <AddIcon />
                    Add Row
                  </Button>
                </>
              )}
            </Flex>
            <Flex alignCenter justifyFlexEnd>
              {userProfile?.profile?.isAdmin &&
                !showAsChart &&
                isEmpty(updatedMarketPrices) && (
                  <IconButton spaceRight onClick={handleToggleEditMode}>
                    <EditIcon />
                  </IconButton>
                )}
              {!showAsChart && editMode && !isEmpty(updatedMarketPrices) && (
                <>
                  <Button
                    disabled={sendingUpdatedMarketPrices}
                    outlined
                    spaceRight
                    onClick={handleCancelChanges}
                  >
                    Cancel Changes
                  </Button>
                  <Button
                    disabled={sendingUpdatedMarketPrices}
                    primary
                    contained
                    spaceRight
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </Button>
                </>
              )}
              <Text>Data Source:</Text>
              <ValueLabelSelect
                spaceLeft
                spaceRight
                size='small'
                greyBackground
                noBorder
                value={source}
                options={MARKETPRICE_SOURCE_OPTIONS}
                onChange={setSource}
              />
              <DateRangeSelector
                dateRange={dateRange}
                onDateRangeChange={setDateRange}
              />
              <IconMenu
                options={[
                  {
                    icon: showAsChart ? <TableIcon /> : <GraphIcon />,
                    label: showAsChart ? 'Show as Table' : 'Show as Graph',
                    onClick: handleToggleTableChartView
                  },
                  {
                    icon: <DownloadIcon />,
                    label: 'Export Data to Excel',
                    onClick: exportExcel
                  },
                  ...(FEATURE_FLAGS.SHOW_IN_LOG_SCALE
                    ? [
                        {
                          icon: <LogScaleChartIcon />,
                          label: (
                            <span>
                              {showInLogScale
                                ? 'Show on linear scale'
                                : 'Show in log scale'}
                              <Tooltip
                                arrow
                                title='Only available in d1g1t environments'
                              >
                                <NewReleasesIcon className={css.globalIcon} />
                              </Tooltip>
                            </span>
                          ),
                          onClick: () => toggleChartScale()
                        }
                      ]
                    : [])
                ]}
              />
            </Flex>
          </Flex>
          <FlexChild>{renderAreaChartOrTable}</FlexChild>
        </Flex>
      </ModalContent>
    </LoadingContainer>
  )
}
