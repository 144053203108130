import React, {useMemo} from 'react'

import {merge} from 'lodash'

import {BaseChart} from '@d1g1t/shared/components/charts/base-chart'

interface IBarChartProps {
  config: Highcharts.Options
}

const DEFAULT_CONFIG: Highcharts.Options = {
  chart: {
    type: 'bar'
  },
  title: {
    y: 0
  }
}

export const BarChart: React.FC<IBarChartProps> = (props) => {
  const config = useMemo(() => {
    return merge({}, DEFAULT_CONFIG, props.config)
  }, [props.config])

  return <BaseChart config={config} />
}
