import React from 'react'

import {useApiQuery} from 'fairlight'
import {keyBy} from 'lodash'

import {TradingEndpoints, TradingOrderEndpoints} from '@d1g1t/api/endpoints'
import {
  COUNTRIES_OPTIONS,
  ITradeOrder,
  TRADEORDER_COMMISSION_TYPE_OPTIONS,
  TRADEORDER_DIVIDEND_TREATMENT_OPTIONS,
  TRADEORDER_EXPIRY_TYPE_OPTIONS,
  TRADEORDER_OPERATION_OPTIONS,
  TRADEORDER_PRO_STATUS_OPTIONS,
  TRADEORDER_QTY_TYPE_OPTIONS,
  TRADEORDER_STATUS,
  TRADEORDER_TYPE_OPTIONS
} from '@d1g1t/api/models'

import {mapModelToValueLabel} from '@d1g1t/lib/value-label'

import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Modal} from '@d1g1t/shared/components/modal'
import {
  ErrorBoundary,
  IModalContentsErrorFallbackProps,
  ModalContentsErrorFallback
} from '@d1g1t/shared/wrappers/error-boundary'
import {reportError} from '@d1g1t/shared/wrappers/error-handler'

import {UnsupportedTradeTypeError} from '@d1g1t/advisor/containers/trade-order-form'
import {useStandardResponseUpdater} from '@d1g1t/advisor/wrappers/standard-response-updater'

import {
  TRADE_ORDER_KEY_TO_CATEGORY_ID_MAP,
  TRADE_ORDER_KEYS
} from '../../constants'
import {BulkEditTradeModalContents} from './bulk'
import {SingleEditOpenTradeModalContents} from './single'
import {IEditTradeModalProps} from './typings'

export const EditTradeModal: React.FC<IEditTradeModalProps> = (props) => {
  const bulkTitle = props.isAggregated
    ? `Bulk Edit Aggregated Trades (${props.selectedItemIds.length})`
    : `Bulk Edit Trades (${props.selectedItemIds.length})`
  return (
    <Modal
      open
      title={props.selectedItemIds.length > 1 ? bulkTitle : 'Edit Trade'}
      onBack={props.onClose}
      fullscreen
    >
      <ErrorBoundary
        resetId='no-reset'
        fallback={<EditTradeModalFallback onClose={props.onClose} />}
        handler={(error) => {
          return error instanceof UnsupportedTradeTypeError ? error : null
        }}
      >
        <EditTradeModalSwitch {...props} />
      </ErrorBoundary>
    </Modal>
  )
}

const EditTradeModalFallback: React.FC<IModalContentsErrorFallbackProps> = (
  props
) => {
  if (props.error instanceof UnsupportedTradeTypeError) {
    return (
      <ModalContentsErrorFallback
        {...props}
        message='This trade order cannot be edited.'
        severity='info'
      />
    )
  }

  return <ModalContentsErrorFallback {...props} />
}

const EditTradeModalSwitch: React.FC<IEditTradeModalProps> = (props) => {
  const singleEdit = props.selectedItemIds.length === 1

  const [singleTradeOrder] = useApiQuery(
    singleEdit && TradingOrderEndpoints.findById(props.selectedItemIds[0])
  )

  const [markets] = useApiQuery(TradingEndpoints.markets(), {
    fetchPolicy: 'cache-first'
  })
  const [brokers] = useApiQuery(TradingEndpoints.brokers(), {
    fetchPolicy: 'cache-first'
  })

  const brokersOptions = mapModelToValueLabel(brokers.data?.results)

  const {
    updateStandardResponseValue,
    updateStandardResponseOption,
    updateStandardResponseBoolean
  } = useStandardResponseUpdater({
    itemIds: props.selectedItemIds,
    standardResponse: props.manageOrdersTable,
    updateStandardResponse: props.onUpdate,
    keyToCategoryIdMap: TRADE_ORDER_KEY_TO_CATEGORY_ID_MAP
  })

  const handleSuccess = (updatedOrders: Partial<ITradeOrder>[]) => {
    const countriesByValue = keyBy(COUNTRIES_OPTIONS, 'value')

    const KEY_TO_VALUE_LABEL_OPTIONS = {
      [TRADE_ORDER_KEYS.COMMISSION_TYPE]: TRADEORDER_COMMISSION_TYPE_OPTIONS,
      [TRADE_ORDER_KEYS.DIVIDEND_TREATMENT]:
        TRADEORDER_DIVIDEND_TREATMENT_OPTIONS,
      [TRADE_ORDER_KEYS.EXPIRY_TYPE]: TRADEORDER_EXPIRY_TYPE_OPTIONS,
      [TRADE_ORDER_KEYS.OPERATION]: TRADEORDER_OPERATION_OPTIONS,
      [TRADE_ORDER_KEYS.ORDER_TYPE]: TRADEORDER_TYPE_OPTIONS,
      [TRADE_ORDER_KEYS.QUANTITY_TYPE]: TRADEORDER_QTY_TYPE_OPTIONS,
      [TRADE_ORDER_KEYS.MARKET]: markets.data.results.map(({url, country}) => ({
        value: url,
        label: countriesByValue[country]?.label
      })),
      [TRADE_ORDER_KEYS.BROKER]: brokersOptions,
      [TRADE_ORDER_KEYS.PRO_STATUS]: TRADEORDER_PRO_STATUS_OPTIONS
    }

    try {
      /**
       * for bulk edits we want to handle totalCommission separately
       * the remaining values are supposed to be the same for all orders in the array
       * so we only use the first set of value
       */
      const {totalCommission, entityId, ...values} = updatedOrders[0]

      for (const [key, value] of Object.entries(values) as Array<
        [keyof ITradeOrder, ITradeOrder[keyof ITradeOrder]]
      >) {
        switch (key) {
          case TRADE_ORDER_KEYS.QUANTITY:
            updateStandardResponseValue(Math.abs(value), key)
            break
          case TRADE_ORDER_KEYS.COMMISSION:
          case TRADE_ORDER_KEYS.EXPIRY_DATE:
          case TRADE_ORDER_KEYS.INSTRUCTIONS:
          case TRADE_ORDER_KEYS.LIMIT:
          case TRADE_ORDER_KEYS.NOTES:
          case TRADE_ORDER_KEYS.TRADED:
          case TRADE_ORDER_KEYS.SETTLED:
          case TRADE_ORDER_KEYS.MODIFIED:
            updateStandardResponseValue(value, key)
            break
          case TRADE_ORDER_KEYS.IS_ALL_OR_NONE:
          case TRADE_ORDER_KEYS.IS_ANONYMOUS:
          case TRADE_ORDER_KEYS.ICEBERG:
          case TRADE_ORDER_KEYS.INSIDER:
          case TRADE_ORDER_KEYS.ODD_LOT:
            updateStandardResponseBoolean(value, key, {
              true: 'Yes',
              false: 'No'
            })
            break
          case TRADE_ORDER_KEYS.COMMISSION_TYPE:
          case TRADE_ORDER_KEYS.DIVIDEND_TREATMENT:
          case TRADE_ORDER_KEYS.EXPIRY_TYPE:
          case TRADE_ORDER_KEYS.OPERATION:
          case TRADE_ORDER_KEYS.ORDER_TYPE:
          case TRADE_ORDER_KEYS.QUANTITY_TYPE:
          case TRADE_ORDER_KEYS.MARKET:
          case TRADE_ORDER_KEYS.BROKER:
          case TRADE_ORDER_KEYS.PRO_STATUS:
            updateStandardResponseOption(
              value,
              key,
              KEY_TO_VALUE_LABEL_OPTIONS[key]
            )
            break
        }
      }
    } catch (error) {
      reportError(error)
    }

    for (const order of updatedOrders) {
      /**
       * Total Commission is a calculated value and we cant assume that it will be the same for all the edited orders
       */
      if (order.totalCommission !== undefined) {
        updateStandardResponseValue(
          order.totalCommission,
          TRADE_ORDER_KEYS.TOTAL_COMMISSION,
          undefined,
          order.entityId
        )
      }
    }
  }

  if (singleEdit && !singleTradeOrder.data && singleTradeOrder.loading) {
    return <LoadingContainer loading />
  }

  if (
    singleEdit &&
    [TRADEORDER_STATUS.OPEN, TRADEORDER_STATUS.PENDING_APPROVAL].includes(
      singleTradeOrder.data.status
    )
  ) {
    return (
      <SingleEditOpenTradeModalContents
        {...props}
        tradeOrder={singleTradeOrder.data}
        onSuccess={handleSuccess}
      />
    )
  }

  return (
    <BulkEditTradeModalContents
      {...props}
      brokersOptions={brokersOptions}
      onSuccess={handleSuccess}
    />
  )
}
