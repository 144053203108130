import React from 'react'

import {Checkbox} from '@d1g1t/shared/components/mui/checkbox'
import {FormControlLabel} from '@d1g1t/shared/components/mui/form-control-label'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {Label} from '@d1g1t/shared/components/typography'
import {
  IValueLabelSelectOption,
  ValueLabelSelect
} from '@d1g1t/shared/components/value-label-select'

import {ContributionDimensionKey} from '../../../typings'
import {SelectConditionResults} from './select-condition-results'

export const ContributionDimension: React.FC<{
  label: string
  contributionDimensionId: number
  onContributionDimensionChange: (value: number) => void
  options: IValueLabelSelectOption[]
  displayTotalWhenExpanded: boolean
  onDisplayTotalWhenExpandedChanged: (value: boolean) => void
  conditionResults: boolean
  onConditionResultsChanged: (value: boolean) => void
  contributionDimensionKey: ContributionDimensionKey
}> = (props) => {
  return (
    <>
      <Label>{props.label}</Label>
      <ValueLabelSelect
        size='small'
        fullWidth
        mutedBorder
        value={props.contributionDimensionId}
        options={props.options}
        onChange={props.onContributionDimensionChange}
      />
      {!!props.contributionDimensionId && (
        <>
          <Spacer xxs />
          <FormControlLabel
            label='Display Total When Expanded'
            control={
              <Checkbox
                checked={props.displayTotalWhenExpanded}
                onChangeValue={props.onDisplayTotalWhenExpandedChanged}
              />
            }
          />
          <Spacer xxs />
          <FormControlLabel
            label='Condition Results'
            control={
              <Checkbox
                checked={props.conditionResults}
                onChangeValue={props.onConditionResultsChanged}
              />
            }
          />
          {props.conditionResults && (
            <SelectConditionResults
              contributionDimensionKey={props.contributionDimensionKey}
            />
          )}
        </>
      )}
    </>
  )
}
