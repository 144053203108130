import {useEffect, useState} from 'react'

import {useApiQuery} from 'fairlight'
import {groupBy, uniqBy} from 'lodash'

import {SearchEndpoints} from '@d1g1t/api/endpoints'

import {useDebouncedValue} from '@d1g1t/lib/hooks'

import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'

import {getSearchInputValueQuery} from './lib'
import {IUseSearchParams, IUseSearchReturnValue} from './typings'

export function useSearch(
  params: IUseSearchParams = {}
): IUseSearchReturnValue {
  const [inputValue, setInputValue] = useState(params.initialValue ?? '')
  const debouncedInputValue = useDebouncedValue(inputValue, 200)

  const waiting = params.waitFor?.length && !params.waitFor?.every(Boolean)

  const [searchValue, searchValueQueryActions] = useApiQuery(
    !waiting &&
      (params.valueUrl || params.initialValue) &&
      SearchEndpoints.search(
        getSearchInputValueQuery(
          params.valueUrl,
          params.models,
          params.initialValue
        )
      ),
    {
      dontReinitialize: true,
      useErrorBoundary: true
    }
  )

  const [search, searchQueryActions] = useApiQuery(
    !waiting &&
      SearchEndpoints.search({
        q: debouncedInputValue,
        models: params.models,
        entityIds: params.entityIds,
        limit: 25,
        isTradable: params.isTradable,
        filters: params.filters
      }),
    {
      dontReinitialize: true,
      useErrorBoundary: false
    }
  )

  const searchError = useDebouncedValue(search.error, 1000)
  const {handleUnexpectedError} = useErrorHandler()
  useEffect(() => {
    if (searchError) {
      handleUnexpectedError(
        search.error,
        'The search unexpectedly failed. Please try again later.'
      )
    }
  }, [searchError])

  let options = uniqBy(
    [...(search.data?.results || []), ...(searchValue.data?.results || [])],
    'url'
  )

  if (params.filterBy) {
    options = options.filter(params.filterBy)
  }

  if (params.groupByModelName) {
    options = Object.values(
      groupBy(
        options.sort((optionA, optionB) => optionB.score - optionA.score),
        (option) => option.modelName
      )
    ).flat()
  }

  return {
    loading: search.loading || inputValue !== debouncedInputValue,
    inputValue,
    onInputValueChange: setInputValue,
    options,
    refetch: () => {
      searchQueryActions.refetch()
      searchValueQueryActions.refetch()
    }
  }
}
