import React from 'react'

import {FieldArray, useField} from 'formik'
import produce from 'immer'

import {Formats, IFilterCriteriaOperator, Types} from '@d1g1t/api/endpoints'
import {FILTERCRITERION_NODE_TYPE} from '@d1g1t/api/models'
import {
  ComparisonValue,
  IFilterValueItem,
  JoinOperator
} from '@d1g1t/typings/general'

import {Flex} from '@d1g1t/shared/components/flex'
import {
  ListEditorEmptyState,
  ListEditorEmptyStateButton
} from '@d1g1t/shared/components/list-editor-empty-state'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {FilterItem} from '@d1g1t/shared/containers/filter-editor/components/item'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'
import {useFilterCriteria} from '@d1g1t/shared/wrappers/filter-criteria'
import {HIDE_OPTIONS} from '@d1g1t/shared/wrappers/filter-criteria/typings'

import {
  computeNewOperator,
  getFilterOptions,
  nodeIdFromFilterItem,
  nodeIdFromFilterOperator
} from './lib'

export interface IFilterEditorProps {
  types?: FILTERCRITERION_NODE_TYPE[]
  hideOptions?: HIDE_OPTIONS[]
  isGlobal?: boolean
}

interface IFilterEditorContainerProps extends IFilterEditorProps {
  name: string
}

interface ICommonFilterFormValues {
  name: string
  id?: string
  joinOperator: JoinOperator
  items: IFilterValueItem[]
}

enum COMMON_FILTERS_FIELD_NAMES {
  joinOperator = 'joinOperator',
  items = 'items'
}

export const FilterEditorContainer: React.FC<IFilterEditorContainerProps> = (
  props
) => {
  const [field] = useField<ICommonFilterFormValues>(props.name)

  const [filterCriteria] = useFilterCriteria(
    props.types,
    props.hideOptions,
    props.isGlobal
  )

  const handleOperatorChange = (
    operator: IFilterCriteriaOperator,
    indexOfItem: number,
    previous: {type: Types; format: Formats}
  ) => {
    field.onChange({
      target: {
        name: props.name,
        value: produce(field.value, (draft) => {
          draft.items[indexOfItem] = {
            id: operator.id,
            roleId: operator.roleId,
            teamId: operator.teamId,
            value: getDefaultValueFromOperator(operator, {
              ...previous,
              value: draft.items[indexOfItem].value
            }),
            filterCriterion: operator.url
          }
        })
      }
    })
  }

  const addFilterItem = () => {
    field.onChange({
      target: {
        name: props.name,
        value: produce(field.value, (draft) => {
          draft.items.push({
            id: filterCriteria.data.defaultOperator.id,
            roleId: filterCriteria.data.defaultOperator.roleId,
            teamId: filterCriteria.data.defaultOperator.teamId,
            value: getDefaultValueFromOperator(
              filterCriteria.data.defaultOperator
            ),
            filterCriterion: filterCriteria.data.defaultOperator.url
          })
        })
      }
    })
  }

  const getDefaultValueFromOperator = (
    operator: IFilterCriteriaOperator,
    previous?: {format: Formats; type: Types; value: ComparisonValue}
  ) => {
    const computedPath =
      filterCriteria.data.paths[nodeIdFromFilterOperator(operator)]

    if (!computedPath) {
      return ''
    }

    const isMultiple = (type) =>
      [Types.oneOf, Types.dateBetween, Types.in, Types.between].includes(type)

    // Check if new operator should require an input value reset.
    // e.g. Switching from `is` to `contains` or `is not` should not reset
    // input value.
    const shouldResetValue = (() => {
      if (computedPath.format !== previous?.format) {
        return true
      }

      if (isMultiple(previous.type) || isMultiple(computedPath.type)) {
        return true
      }

      if (
        computedPath.type === Types.isEmpty ||
        computedPath.type === Types.isNotEmpty
      ) {
        return true
      }

      return false
    })()

    switch (computedPath.format) {
      case Formats.decimal:
      case Formats.percentage:
      case Formats.int: {
        if (computedPath.type === Types.in) {
          return [null]
        }

        if (computedPath.type === Types.between) {
          return [null, null]
        }

        return shouldResetValue ? null : previous.value
      }
      case Formats.enum:
      case Formats.string:
      case Formats.date:
      default: {
        if ([Types.oneOf, Types.in].includes(computedPath.type)) {
          return computedPath.format === Formats.enum ? [] : ['']
        }

        if (computedPath.type === Types.dateBetween) {
          return ['', '']
        }

        return shouldResetValue ? '' : previous.value
      }
    }
  }

  const getFilterItemOptions = (id: string, index: number) => {
    const derivedValues = filterCriteria.data?.paths[id]

    if (!derivedValues) {
      return null
    }

    const filterOptions = getFilterOptions(
      filterCriteria.data.items,
      derivedValues.path
    )

    return {
      ...filterOptions,
      ...derivedValues
    }
  }

  const handleComputeNewOperator = (fragment: StrNum[], index: number) =>
    computeNewOperator(filterCriteria.data.items, fragment, index)

  const handleJoinOperatorChange = (value: JoinOperator) => {
    field.onChange({
      target: {
        name: `${props.name}.${COMMON_FILTERS_FIELD_NAMES.joinOperator}`,
        value
      }
    })
  }

  if (!field.value) {
    return null
  }

  const buttonCaption =
    field.value.items.length === 0 ? 'Add a rule' : 'Add Another Rule'

  return (
    <Flex column justifySpaceBetween>
      <ErrorBoundary resetId='no-reset'>
        <div>
          {field.value.items.length === 0 ? (
            <ListEditorEmptyState>
              {props.isGlobal ? 'At least one filter required' : 'No filters'},{' '}
              <ListEditorEmptyStateButton onClick={addFilterItem}>
                add a rule
              </ListEditorEmptyStateButton>{' '}
              to get started
            </ListEditorEmptyState>
          ) : (
            <FieldArray
              name={`${props.name}.${COMMON_FILTERS_FIELD_NAMES.items}`}
            >
              {(arrayHelpers) => {
                const removeFilterItem = (indexOfItem: number) => {
                  arrayHelpers.remove(indexOfItem)
                }

                return field.value.items.map((item, index) => (
                  <FilterItem
                    key={index}
                    index={index}
                    name={`${arrayHelpers.name}[${index}]`}
                    joinOperator={field.value.joinOperator}
                    {...item}
                    {...getFilterItemOptions(nodeIdFromFilterItem(item), index)}
                    computeNewOperator={handleComputeNewOperator}
                    onOperatorChange={handleOperatorChange}
                    onJoinOperatorChange={handleJoinOperatorChange}
                    onRemove={removeFilterItem}
                  />
                ))
              }}
            </FieldArray>
          )}
        </div>
        <Button
          outlined
          fullWidth
          onClick={addFilterItem}
          data-testid='button-add-rule'
        >
          {buttonCaption}
        </Button>
        <Spacer xs />
      </ErrorBoundary>
    </Flex>
  )
}
