import {useCallback, useEffect, useMemo} from 'react'
import {bindActionCreators} from 'redux'

import {useApi} from 'fairlight'
import produce from 'immer'
import {flatten, uniq} from 'lodash'
import {useRecoilState} from 'recoil'
import useAsyncCall from 'use-async-call'
import useSagaReducer from 'use-saga-reducer'

import {AccountEndpoints, EntityEndpoints} from '@d1g1t/api/endpoints'
import {IBulkUpdateRequest} from '@d1g1t/api/models'
import {IControl} from '@d1g1t/typings/general'

import {StandardResponse} from '@d1g1t/lib/standard-response'

import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {useGlobalSettings} from '@d1g1t/shared/wrappers/global-settings'

import {changeAllocationActions} from './actions'
import {checkedAccountsIdsAtom} from './atoms'
import {
  applyEditedValues,
  calculateUpdateChartPayload,
  getEditedValueErrorsBySecurityAndAccount,
  getIsChangedByAccountId,
  getWarningsByAccountId,
  IEditedValueErrorsBySecurityAndAccount,
  someEditedAllocationValueErrors
} from './lib'
import {changeAllocationReducer} from './reducer'
import {changeAllocationSaga} from './sagas'
import {
  IBulkRebalanceParams,
  IDirectiveInputsBySecurityEntityId,
  ILoadedSecurity
} from './typings'

const CHANGE_ALLOCATION_SETTINGS_KEY = 'REBALANCE_CHANGE_ALLOCATION'
const CHANGE_ALLOCATION_DEFAULT_SETTINGS = {
  excludePendingTrades: false,
  excludeCashEquivalents: false
}

/**
 * Given `control`, returns a `Loadable` containing an array
 * of account ids derived from the selected entities
 */
export function useSelectedAccountIds(control: IControl): [Loadable<string[]>] {
  const api = useApi()

  const [selectedAccountsIds] = useAsyncCall(
    useCallback(async (): Promise<string[]> => {
      if (!control?.selectedEntities) {
        return null
      }

      const {clients, households, accountsOrPositions} =
        control.selectedEntities

      const clientEntityIds = (clients || []).concat(households || [])

      const derivedAccountIdsForClientEntityIds: string[] = flatten(
        await Promise.all(
          clientEntityIds.map(async (clientId): Promise<string[]> => {
            const propertiesForClientEntity = await api.request(
              EntityEndpoints.clientRelationships(clientId)
            )

            return propertiesForClientEntity.accounts || []
          })
        )
      )

      const explicitlyaccountIds: string[] = flatten(accountsOrPositions || [])

      return uniq(
        derivedAccountIdsForClientEntityIds.concat(explicitlyaccountIds)
      )
    }, [control?.selectedEntities])
  )

  return [selectedAccountsIds]
}

export function useChangeAllocation(
  /**
   * Note - this can't pull directly from `useSelectedEntities` because
   * this is a modified version which expands the account groups' accounts.
   */
  control: IControl
): [
  {
    changes: IBulkUpdateRequest
    accountAllocationChart: Loadable<StandardResponse>
    warningsByAccountId: Dictionary<string[]>
    isChangedByAccountId: Dictionary<boolean>
    bulkRebalanceParams: IBulkRebalanceParams
    securities: ILoadedSecurity[]
    checkedSecurityUrls: string[]
    checkedAccountsIds: string[]
    directiveInputsBySecurityEntityId: IDirectiveInputsBySecurityEntityId
    loadingSecuritiesForModelPortfolio: boolean
    selectedModelPortfolio: boolean
    excludingPendingTrades: boolean
    excludingCashEquivalents: boolean
    editedValueErrorsByAccountAndSecurityId: IEditedValueErrorsBySecurityAndAccount
  },
  Omit<
    typeof changeAllocationActions,
    'updateAccountAllocationChartValueRequest'
  > & {
    includePendingTrades()
    excludePendingTrades()
    includeCashEquivalents()
    excludeCashEquivalents()
    updateAccountAllocationChartValueRequest(payload: {
      accountId: string
      categoryId: string
      value: number | null
    })
    setCheckedAccountsIds(checkedAccountsIds: string[]): void
  }
] {
  const api = useApi()
  const [selectedAccountIds] = useSelectedAccountIds(control)
  const [calculationSettings] = useCalculationSettings()
  const asOfDate = calculationSettings.date?.date

  const [state, dispatch] = useSagaReducer(
    changeAllocationSaga,
    changeAllocationReducer,
    undefined,
    () =>
      changeAllocationReducer(undefined, changeAllocationActions.initialize())
  )
  const [settings, {updateGlobalSettings}] = useGlobalSettings(
    CHANGE_ALLOCATION_SETTINGS_KEY,
    CHANGE_ALLOCATION_DEFAULT_SETTINGS
  )
  const {
    bulkRebalanceParams,
    accountAllocationChart,
    editedValuesBySecurityAndAccount,
    securities,
    checkedSecurityUrls,
    directiveInputsBySecurityEntityId,
    loadingSecuritiesForModelPortfolio,
    selectedModelPortfolio
  } = state

  const [checkedAccountsIds, setCheckedAccountsIds] = useRecoilState(
    checkedAccountsIdsAtom
  )
  const chartWithEditedValues: Loadable<StandardResponse> = useMemo(() => {
    if (!accountAllocationChart.data) {
      return accountAllocationChart
    }

    return {
      ...accountAllocationChart,
      data: applyEditedValues(
        accountAllocationChart.data,
        editedValuesBySecurityAndAccount
      )
    }
  }, [accountAllocationChart, editedValuesBySecurityAndAccount])

  const warningsByAccountId: Dictionary<string[]> = useMemo(() => {
    if (!chartWithEditedValues.data) {
      return null
    }

    return getWarningsByAccountId(chartWithEditedValues.data)
  }, [chartWithEditedValues.data])

  const isChangedByAccountId: Dictionary<boolean> = useMemo(() => {
    if (!chartWithEditedValues.data) {
      return null
    }

    return getIsChangedByAccountId(chartWithEditedValues.data)
  }, [chartWithEditedValues.data, editedValuesBySecurityAndAccount])

  const editedValueErrorsByAccountAndSecurityId = useMemo(() => {
    if (!accountAllocationChart.data) {
      return null
    }

    return getEditedValueErrorsBySecurityAndAccount(state)
  }, [state])

  const someEditedValueErrors = useMemo(
    () =>
      someEditedAllocationValueErrors(editedValueErrorsByAccountAndSecurityId),
    [editedValueErrorsByAccountAndSecurityId]
  )

  const changes = useMemo((): IBulkUpdateRequest => {
    if (!accountAllocationChart.data || someEditedValueErrors) {
      return null
    }
    return calculateUpdateChartPayload(
      state,
      !settings.excludePendingTrades,
      !settings.excludeCashEquivalents,
      api,
      checkedAccountsIds
    )
  }, [
    state.accountAllocationChart?.data,
    state.bulkRebalanceParams,
    state.editedValuesBySecurityAndAccount,
    someEditedValueErrors,
    state.baseCurrency,
    state.fxRates,
    checkedAccountsIds
  ])

  useEffect(() => {
    if (control && bulkRebalanceParams?.instruments) {
      dispatch(changeAllocationActions.clearSecurities())
    }
  }, [control])

  useEffect(() => {
    if (
      !selectedAccountIds.data ||
      !bulkRebalanceParams ||
      !asOfDate ||
      !settings
    ) {
      return
    }

    // load the chart anytime parameters change

    // Set target_weight to `null` unless `shouldRecommend` is true for
    // the security
    const instruments = produce(bulkRebalanceParams.instruments, (draft) => {
      for (const item of draft) {
        if (
          !securities.find((security) => security.url === item.instrument)
            ?.shouldRecommend
        ) {
          item.targetWeight = null
        }
      }
    })

    dispatch(
      changeAllocationActions.loadAccountAllocationChart({
        instruments,
        desiredAction: bulkRebalanceParams.desiredAction,
        rebalanceRule: bulkRebalanceParams.rebalanceRule,
        asOfDate,
        accounts: selectedAccountIds.data.map((id) =>
          api.buildUrl(AccountEndpoints.pathToResource(id))
        ),
        includePending: !settings.excludePendingTrades,
        includeCashEquivalents: !settings.excludeCashEquivalents
      })
    )
  }, [selectedAccountIds, asOfDate, bulkRebalanceParams, settings])

  useEffect(() => {
    setCheckedAccountsIds(selectedAccountIds.data || [])
  }, [selectedAccountIds])

  const updateAccountAllocationChartValueRequest = (payload: {
    accountId: string
    categoryId: string
    value: number | null
  }) => {
    dispatch(
      changeAllocationActions.updateAccountAllocationChartValueRequest({
        ...payload,
        includePending: !settings.excludePendingTrades,
        includeCashEquivalents: !settings.excludeCashEquivalents,
        checkedAccountsIds
      })
    )
  }

  return [
    {
      changes,
      accountAllocationChart: chartWithEditedValues,
      warningsByAccountId,
      isChangedByAccountId,
      bulkRebalanceParams,
      securities,
      checkedSecurityUrls,
      checkedAccountsIds,
      directiveInputsBySecurityEntityId,
      loadingSecuritiesForModelPortfolio,
      selectedModelPortfolio,
      excludingPendingTrades: settings?.excludePendingTrades,
      excludingCashEquivalents: settings?.excludeCashEquivalents,
      editedValueErrorsByAccountAndSecurityId
    },
    {
      ...bindActionCreators(changeAllocationActions, dispatch as any),
      updateAccountAllocationChartValueRequest,
      includePendingTrades: () =>
        updateGlobalSettings({excludePendingTrades: false}),
      excludePendingTrades: () =>
        updateGlobalSettings({excludePendingTrades: true}),
      includeCashEquivalents: () =>
        updateGlobalSettings({excludeCashEquivalents: false}),
      excludeCashEquivalents: () =>
        updateGlobalSettings({excludeCashEquivalents: true}),
      setCheckedAccountsIds
    }
  ]
}
