import React from 'react'

import GroupsIcon from '@material-ui/icons/Group'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import PublicIcon from '@material-ui/icons/Public'

import {IUserView} from '@d1g1t/api/models'

import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'

import * as css from './styles.scss'

/**
 * Clearly shows which icons are global, which ones are
 * shared to investors, and which ones are both.
 */
export const ViewIcons: React.FC<{view: IUserView}> = ({view}) => {
  return (
    <>
      {view.isGlobal && <GlobalViewIcon />}
      {view.profileGroup && <UserGroupViewIcon />}
      {view.isAvailableToExternalProfiles && <InvestorViewIcon />}
    </>
  )
}

const GlobalViewIcon: React.FC = () => (
  <Tooltip arrow title='Global view'>
    <PublicIcon className={css.globalIcon} />
  </Tooltip>
)

const InvestorViewIcon: React.FC = () => (
  <Tooltip arrow title='Investors portal view'>
    <GroupsIcon className={css.globalIcon} />
  </Tooltip>
)

const UserGroupViewIcon: React.FC = () => (
  <Tooltip arrow title='User Group View'>
    <GroupWorkIcon className={css.globalIcon} />
  </Tooltip>
)
