import React, {useMemo, useState} from 'react'

import {useApi, useApiQuery} from 'fairlight'
import {Formik, FormikHelpers} from 'formik'
import invariant from 'invariant'
import {compact, pickBy, uniqBy} from 'lodash'

import {InstrumentEndpoints, IUpdateInstrument} from '@d1g1t/api/endpoints'
import {
  COUNTRIES_OPTIONS,
  FIRMSECURITYTYPE_INSTRUMENT_MODEL_TYPE_OPTIONS,
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE_OPTIONS,
  INSTRUMENT_CAPITAL_APPRECIATION_LEVEL_OPTIONS,
  INSTRUMENT_DEFAULT_COMMISSION_TYPE_OPTIONS,
  INSTRUMENT_DIVIDEND_LEVEL_OPTIONS,
  INSTRUMENT_GEOGRAPHY_OPTIONS,
  INSTRUMENT_INTEREST_INCOME_LEVEL_OPTIONS,
  INSTRUMENT_ISSUER_CREDIT_RATING_OPTIONS,
  INSTRUMENT_LEGAL_STRUCTURE_OPTIONS,
  INSTRUMENT_STATUS_OPTIONS
} from '@d1g1t/api/models'

import {deepDiffObject} from '@d1g1t/lib/deep-diff-object'
import {useToggleState} from '@d1g1t/lib/hooks'
import {mapModelToValueLabel} from '@d1g1t/lib/value-label'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {FormActions} from '@d1g1t/shared/components/form-actions'
import {CheckboxField} from '@d1g1t/shared/components/form-field/checkbox-field'
import {KeyboardDatePickerField} from '@d1g1t/shared/components/form-field/keyboard-date-picker-field'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {ValueLabelAutocompleteField} from '@d1g1t/shared/components/form-field/value-label-autocomplete-field'
import {FormUnsavedPrompt} from '@d1g1t/shared/components/form-unsaved-prompt'
import {
  DecimalNumberInput,
  FourDecimalPercentageInput,
  SixDecimalPercentageMaxLimitedInput,
  WholeNumberInput
} from '@d1g1t/shared/components/formatted-input'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {
  QuickLookDrawerActions,
  QuickLookDrawerContent
} from '@d1g1t/shared/components/quick-look-drawer'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'

import {
  applyObjectOverrides,
  useModelOverrides
} from '@d1g1t/advisor/wrappers/model-overrides'
import {
  DEFAULT_BOOLEAN_LABEL_MAP,
  useStandardResponseUpdater
} from '@d1g1t/advisor/wrappers/standard-response-updater'

import {VectorizedPropertyEditModal} from './components/edit-vectorized-property-modal'
import {
  IExposureVector,
  INSTRUMENT_PROPERTY_KEY_TO_EXPOSURE_KEYS_MAP
} from './components/edit-vectorized-property-modal/typings'
import {
  CATEGORY_INSTRUMENT_KEY_MAP,
  EDIT_SECURITY_VALIDATION_SCHEMA,
  METRIC_INSTRUMENT_OPTIONS_MAP,
  METRIC_SLUG_TO_SECURITY_KEY_MAP,
  SCALING_FACTOR_BOOLEAN_MAP,
  SCALING_FACTOR_OPERATIONS,
  SCALING_FACTOR_VALUES_MAP,
  SECURITY_KEY_PUBLIC_BOOLEAN_MAP,
  SECURITY_KEY_TO_METRIC_COLUMN_TITLE_MAP,
  SECURITY_KEY_TO_METRIC_SLUG_MAP,
  SECURITY_KEYS
} from './constants'
import {useEditSecuritiesDropdownOptions} from './hook'
import {getCategories, getVectorPropertyDisplayText} from './lib'
import {IEditSecuritiesProps, IEditSecurityFormValues} from './typings'

export const EditSecurities: React.FC<IEditSecuritiesProps> = (props) => {
  const api = useApi()
  const {showSnackbar} = useSnackbar()
  const {handleFormError} = useErrorHandler()

  const [vectorizedPropertyEditKey, setVectorizedPropertyEditKey] =
    useState(null)
  const [
    vectorizedPropertyEditModalOpen,
    ,
    openVectorizedPropertyEditModal,
    closeVectorizedPropertyEditModal
  ] = useToggleState(false)

  const [security, securityQueryActions] = useApiQuery(
    InstrumentEndpoints.findById(props.selectedItemId)
  )

  const [securityData, {serializeValuesWithOverrides}] = useModelOverrides(
    security.data
  )

  const {
    markets,
    marketOptions,
    exchanges,
    instrumentStrategies,
    instrumentSubStrategies,
    assetClasses,
    assetClassesL2,
    assetClassesL3,
    assetCategories,
    assetSubCategories,
    sectors,
    regions,
    liquidityScores,
    instrumentRegions,
    instrumentIndustries,
    currencies,
    firmSecurityTypes,
    industries,
    riskReturns,
    securityRiskRatings
  } = useEditSecuritiesDropdownOptions()

  const vectorizedDropdownOptions = {
    [SECURITY_KEYS.ASSET_CLASS_VECTORIZED]: assetClasses.data?.results,
    [SECURITY_KEYS.ASSET_CLASS_L2_VECTORIZED]: assetClassesL2.data?.results,
    [SECURITY_KEYS.ASSET_CLASS_L3_VECTORIZED]: assetClassesL3.data?.results,
    [SECURITY_KEYS.SECTOR_VECTORIZED]: sectors.data?.results,
    [SECURITY_KEYS.STRATEGY_VECTORIZED]: instrumentStrategies.data?.results,
    [SECURITY_KEYS.REGION_VECTORIZED]: instrumentRegions.data?.results,
    [SECURITY_KEYS.INDUSTRY_VECTORIZED]: instrumentIndustries.data?.results,
    [SECURITY_KEYS.CURRENCY_VECTORIZED]: currencies.data?.results
  }

  const {
    updateStandardResponseValue,
    updateStandardResponseAssociatedUrl,
    updateStandardResponseBoolean,
    updateStandardResponseOption
  } = useStandardResponseUpdater({
    itemIds: [props.selectedItemId],
    standardResponse: props.data,
    updateStandardResponse: props.onUpdate,
    keyToCategoryIdMap: SECURITY_KEY_TO_METRIC_SLUG_MAP
  })

  const {editableSecurityProperties, initialValues} = useMemo(() => {
    if (!securityData) {
      return {editableSecurityProperties: null, initialValues: null}
    }

    const initialValues: IEditSecurityFormValues = {} // populated as they're mapped to input fields

    const editableSecurityProperties = uniqBy(
      getCategories(props.categories),
      'id'
    ).map((category) => {
      const securityKey = METRIC_SLUG_TO_SECURITY_KEY_MAP[category.id]

      if (!securityKey) {
        return null
      }

      const metricColumnTitle =
        SECURITY_KEY_TO_METRIC_COLUMN_TITLE_MAP[securityKey]

      // TODO: Remove the disableClearable for the categories that can be deleted
      switch (securityKey) {
        case SECURITY_KEYS.CALCULATED_SYMBOL: {
          const dataKey =
            CATEGORY_INSTRUMENT_KEY_MAP[securityKey] || securityKey
          initialValues[dataKey] = securityData[dataKey]
          return (
            <OutlinedInputField
              key={securityKey}
              name={dataKey}
              label={metricColumnTitle}
              disabled
            />
          )
        }
        case SECURITY_KEYS.CREDIT_RATING:
        case SECURITY_KEYS.CUSIP:
        case SECURITY_KEYS.DESCRIPTION:
        case SECURITY_KEYS.FUND_CODE:
        case SECURITY_KEYS.FUND_NAME:
        case SECURITY_KEYS.INVESMENT_CODE:
        case SECURITY_KEYS.ISIN:
        case SECURITY_KEYS.ISSUER:
        case SECURITY_KEYS.TICKER_SHORT:
        case SECURITY_KEYS.TICKER:
        case SECURITY_KEYS.FUND_MANAGER:
        case SECURITY_KEYS.FUND_MANDATE:
        case SECURITY_KEYS.FUND_STRUCTURE:
        case SECURITY_KEYS.FUND_TYPE:
        case SECURITY_KEYS.FUND_VEHICLE:
        case SECURITY_KEYS.REPORTING_NAME:
        case SECURITY_KEYS.SEDOL:
        case SECURITY_KEYS.NOTES:
        case SECURITY_KEYS.USER_DEFINED_1:
        case SECURITY_KEYS.USER_DEFINED_2:
        case SECURITY_KEYS.USER_DEFINED_3:
        case SECURITY_KEYS.USER_DEFINED_4:
        case SECURITY_KEYS.USER_DEFINED_5: {
          const dataKey =
            CATEGORY_INSTRUMENT_KEY_MAP[securityKey] || securityKey
          initialValues[dataKey] = securityData[dataKey]
          return (
            <OutlinedInputField
              key={securityKey}
              name={dataKey}
              label={metricColumnTitle}
            />
          )
        }
        case SECURITY_KEYS.LIQUIDITY: {
          const liquidity = securityData[securityKey]
          initialValues[securityKey] = liquidity?.url
          return (
            liquidityScores.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(liquidityScores.data?.results)
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.APPLY_BUY_TAX:
        case SECURITY_KEYS.APPLY_SELL_TAX:
        case SECURITY_KEYS.IS_CASH_EQUIVALENT:
        case SECURITY_KEYS.IS_DEFERRED_SALES_CHARGE:
        case SECURITY_KEYS.PUBLIC:
        case SECURITY_KEYS.IS_MARGIN:
        case SECURITY_KEYS.VALIDATION_PER_POSITION:
        case SECURITY_KEYS.IS_TRADABLE:
        case SECURITY_KEYS.METRIC_S_DISPLAY_QUANTITY: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <CheckboxField
              key={securityKey}
              label={metricColumnTitle}
              name={securityKey}
            />
          )
        }
        case SECURITY_KEYS.METRIC_S_MULT_DIV_INDICATOR: {
          initialValues[securityKey] =
            SCALING_FACTOR_BOOLEAN_MAP[securityData[securityKey].toString()]
          return (
            <ValueLabelAutocompleteField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              ValueLabelAutocompleteProps={{
                disableClearable: true,
                options: SCALING_FACTOR_OPERATIONS
              }}
            />
          )
        }
        case SECURITY_KEYS.PRIMARY_MARKET:
        case SECURITY_KEYS.SECONDARY_MARKET: {
          const market = securityData[securityKey]
          initialValues[securityKey] = market?.url
          return (
            <ValueLabelAutocompleteField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              ValueLabelAutocompleteProps={{
                disableClearable: true,
                options: marketOptions
              }}
            />
          )
        }
        case SECURITY_KEYS.DIVIDEND_YIELD: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <OutlinedInputField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              outlinedInputProps={{
                inputComponent: FourDecimalPercentageInput
              }}
            />
          )
        }
        case SECURITY_KEYS.COUPON_RATE: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <OutlinedInputField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              outlinedInputProps={{
                inputComponent: SixDecimalPercentageMaxLimitedInput
              }}
            />
          )
        }
        case SECURITY_KEYS.UNIT_OF_MEASURE:
        case SECURITY_KEYS.LIQUIDITY_SCORE:
        case SECURITY_KEYS.COMMISSION: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <OutlinedInputField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              ignore1Password
              outlinedInputProps={{
                inputComponent: DecimalNumberInput
              }}
            />
          )
        }
        case SECURITY_KEYS.RISK_SCORE: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <OutlinedInputField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              outlinedInputProps={{
                inputComponent: WholeNumberInput
              }}
            />
          )
        }
        case SECURITY_KEYS.SECURITY_RISK_RATING: {
          const securityRiskRating = securityData[securityKey]
          initialValues[securityKey] = securityRiskRating
          return (
            securityRiskRatings.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(
                    securityRiskRatings.data.results
                  )
                }}
              />
            )
          )
        }

        case SECURITY_KEYS.RISK_RETURN: {
          const riskReturn = securityData[securityKey]
          initialValues[securityKey] = riskReturn?.url

          return (
            riskReturns.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(riskReturns.data.results)
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.STRATEGY: {
          const strategy = securityData[securityKey]
          initialValues[securityKey] = strategy?.url

          return (
            instrumentStrategies.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(
                    instrumentStrategies.data.results
                  )
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.SUB_STRATEGY: {
          const subStrategy = securityData[securityKey]
          initialValues[securityKey] = subStrategy?.url

          return (
            instrumentSubStrategies.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  options: mapModelToValueLabel(
                    instrumentSubStrategies.data.results
                  )
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.ASSET_CLASS: {
          const assetClass = securityData[securityKey]
          initialValues[securityKey] = assetClass?.url
          return (
            assetClasses.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(assetClasses.data.results)
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.ASSET_CLASS_L2: {
          const assetClassL2 = securityData[securityKey]
          initialValues[securityKey] = assetClassL2?.url
          return (
            assetClassesL2.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(assetClassesL2.data.results)
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.ASSET_CATEGORY: {
          const assetCategory = securityData[securityKey]
          initialValues[securityKey] = assetCategory?.url
          return (
            assetCategories.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(assetCategories.data.results)
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.ASSET_SUB_CATEGORY: {
          const assetSubcategory = securityData[securityKey]
          initialValues[securityKey] = assetSubcategory?.url
          return (
            assetSubCategories.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(assetSubCategories.data.results)
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.SECTOR: {
          const sector = securityData[securityKey]
          initialValues[securityKey] = sector?.url
          return (
            sectors.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(sectors.data.results)
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.REGION: {
          const region = securityData[securityKey]
          initialValues[securityKey] = region?.url
          return (
            regions.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(regions.data.results)
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.INDUSTRY: {
          const industry = securityData[securityKey]
          initialValues[securityKey] = industry?.url
          return (
            industries.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(industries.data?.results)
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.EXCHANGE: {
          const exchange = securityData[securityKey]
          initialValues[securityKey] = exchange?.url
          return (
            exchanges.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(exchanges.data.results)
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.SECTOR_VECTORIZED:
        case SECURITY_KEYS.STRATEGY_VECTORIZED:
        case SECURITY_KEYS.REGION_VECTORIZED:
        case SECURITY_KEYS.INDUSTRY_VECTORIZED:
        case SECURITY_KEYS.CURRENCY_VECTORIZED:
        case SECURITY_KEYS.ASSET_CLASS_VECTORIZED:
        case SECURITY_KEYS.ASSET_CLASS_L2_VECTORIZED:
        case SECURITY_KEYS.ASSET_CLASS_L3_VECTORIZED: {
          initialValues[securityKey] = securityData[
            securityKey
          ] as Required<IExposureVector>[]
          return (
            <OutlinedInputField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              fieldValueDisplayText={(fieldValue) =>
                getVectorPropertyDisplayText(
                  vectorizedDropdownOptions[securityKey],
                  fieldValue,
                  INSTRUMENT_PROPERTY_KEY_TO_EXPOSURE_KEYS_MAP[securityKey]
                )
              }
              outlinedInputProps={{
                showPointerOnHover: true
              }}
              onClick={() => {
                setVectorizedPropertyEditKey(securityKey)
                openVectorizedPropertyEditModal()
              }}
            />
          )
        }
        case SECURITY_KEYS.COMMISSION_TYPE: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <ValueLabelAutocompleteField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              ValueLabelAutocompleteProps={{
                disableClearable: true,
                options: INSTRUMENT_DEFAULT_COMMISSION_TYPE_OPTIONS
              }}
            />
          )
        }
        case SECURITY_KEYS.STATUS: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <ValueLabelAutocompleteField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              ValueLabelAutocompleteProps={{
                disableClearable: true,
                options: INSTRUMENT_STATUS_OPTIONS
              }}
            />
          )
        }
        case SECURITY_KEYS.INTEREST_INCOME_LEVEL: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <ValueLabelAutocompleteField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              ValueLabelAutocompleteProps={{
                disableClearable: true,
                options: INSTRUMENT_INTEREST_INCOME_LEVEL_OPTIONS
              }}
            />
          )
        }
        case SECURITY_KEYS.CAPITAL_APPRECIATION_LEVEL: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <ValueLabelAutocompleteField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              ValueLabelAutocompleteProps={{
                disableClearable: true,
                options: INSTRUMENT_CAPITAL_APPRECIATION_LEVEL_OPTIONS
              }}
            />
          )
        }
        case SECURITY_KEYS.DIVIDEND_LEVEL: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <ValueLabelAutocompleteField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              ValueLabelAutocompleteProps={{
                disableClearable: true,
                options: INSTRUMENT_DIVIDEND_LEVEL_OPTIONS
              }}
            />
          )
        }
        case SECURITY_KEYS.COUNTRY: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <ValueLabelAutocompleteField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              ValueLabelAutocompleteProps={{
                disableClearable: true,
                options: COUNTRIES_OPTIONS
              }}
            />
          )
        }
        case SECURITY_KEYS.GEOGRAPHY: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <ValueLabelAutocompleteField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              ValueLabelAutocompleteProps={{
                disableClearable: true,
                options: INSTRUMENT_GEOGRAPHY_OPTIONS
              }}
            />
          )
        }
        case SECURITY_KEYS.LEGAL_STRUCTURE: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <ValueLabelAutocompleteField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              ValueLabelAutocompleteProps={{
                disableClearable: true,
                options: INSTRUMENT_LEGAL_STRUCTURE_OPTIONS
              }}
            />
          )
        }
        case SECURITY_KEYS.ISSUER_CREDIT_RATING:
          initialValues[securityKey] = securityData[securityKey]
          return (
            <ValueLabelAutocompleteField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              ValueLabelAutocompleteProps={{
                disableClearable: true,
                options: INSTRUMENT_ISSUER_CREDIT_RATING_OPTIONS
              }}
            />
          )
        case SECURITY_KEYS.TRADING_CURRENCY: {
          const currency: string = securityData[securityKey]
          initialValues[securityKey] = currency
          return (
            currencies.data && (
              <ValueLabelAutocompleteField
                key={securityKey}
                name={securityKey}
                label={metricColumnTitle}
                ValueLabelAutocompleteProps={{
                  disableClearable: true,
                  options: mapModelToValueLabel(currencies.data.results)
                }}
              />
            )
          )
        }
        case SECURITY_KEYS.MATURITY_DATE: {
          initialValues[securityKey] = securityData[securityKey]
          return (
            <KeyboardDatePickerField
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
              keyboardDatePickerProps={{
                allowFuture: true
              }}
            />
          )
        }
        // the following aren't editable, and should NOT be shown as a field
        case SECURITY_KEYS.INSTRUMENT_MODEL_TYPE:
        case SECURITY_KEYS.TRADING_TYPE:
        case SECURITY_KEYS.FIRM_SECURITY_TYPE:
        case SECURITY_KEYS.FIRM_PROVIDED_KEY:
        case SECURITY_KEYS.NAME: {
          initialValues[securityKey] = securityData[securityKey]
          return null
        }
        case SECURITY_KEYS.CURRENCY: {
          // Is not editable; so it should be VISIBLE, but disabled
          initialValues[securityKey] = securityData[securityKey].name
          return (
            <OutlinedInputField
              disabled
              key={securityKey}
              name={securityKey}
              label={metricColumnTitle}
            />
          )
        }
        default: {
          if (__DEVELOPMENT__) {
            invariant(
              false,
              `Should take care of each security key as a case. Missing input field for ${securityKey}.`
            )
          }
          return null
        }
      }
    })

    return {
      editableSecurityProperties: compact(editableSecurityProperties),
      initialValues
    }
  }, [
    props.categories,
    securityData,
    assetClasses.data,
    assetClassesL2.data,
    currencies.data,
    sectors.data,
    exchanges.data,
    industries.data,
    regions.data,
    instrumentSubStrategies.data,
    instrumentStrategies.data,
    liquidityScores.data,
    firmSecurityTypes.data
  ])

  const handleFormSubmit = async (
    values: IEditSecurityFormValues,
    formikBag: FormikHelpers<IEditSecurityFormValues>
  ) => {
    const {
      assetClass,
      secondaryMarket,
      strategy,
      currency, // not allowing edits at the moment
      firmProvidedKey, // not allowing edits at the moment
      ...rest
    } = values

    const serializedValues: IUpdateInstrument = {
      ...rest,
      riskReturn: values.riskReturn ? {url: values.riskReturn} : undefined,
      industry: values.industry ? {url: values.industry} : undefined,
      strategy: values.strategy ? {url: values.strategy} : undefined,
      subStrategy: values.subStrategy ? {url: values.subStrategy} : undefined,
      assetClass:
        values.assetClass === undefined ? undefined : {url: values.assetClass},
      assetClassL2:
        values.assetClassL2 === undefined
          ? undefined
          : {url: values.assetClassL2},
      assetCategory:
        values.assetCategory === undefined
          ? undefined
          : {url: values.assetCategory},
      assetSubcategory:
        values.assetSubcategory === undefined
          ? undefined
          : {url: values.assetSubcategory},
      sector: values.sector === undefined ? undefined : {url: values.sector},
      primaryMarket:
        values.primaryMarket === undefined
          ? undefined
          : markets.data?.results.find(
              (market) => market.url === values.primaryMarket
            ),
      secondaryMarket:
        values.secondaryMarket === undefined
          ? undefined
          : markets.data?.results.find(
              (market) => market.url === values.secondaryMarket
            ),
      region: values.region ? {url: values.region} : undefined,
      liquidity: values.liquidity ? {url: values.liquidity} : undefined,
      exchange: values.exchange ? {url: values.exchange} : undefined,
      [SECURITY_KEYS.METRIC_S_MULT_DIV_INDICATOR]:
        SCALING_FACTOR_VALUES_MAP[
          values[SECURITY_KEYS.METRIC_S_MULT_DIV_INDICATOR]
        ]
    }

    // Use override properties, and transform to create a valid IUpdateInstrument type object
    const serializedValuesWithOverrides =
      serializeValuesWithOverrides(serializedValues)

    try {
      const updatedSecurity = await api.request(
        InstrumentEndpoints.partialUpdate(
          props.selectedItemId,
          serializedValuesWithOverrides
        )
      )

      securityQueryActions.setData(updatedSecurity)

      const clearedChangedValues: IEditSecurityFormValues = pickBy(
        deepDiffObject(initialValues, values),
        (value) => value !== undefined
      )
      if (updatedSecurity) {
        // Direct update of data table to avoid full refresh
        for (const [key, value] of Object.entries(clearedChangedValues)) {
          switch (key as SECURITY_KEYS) {
            case SECURITY_KEYS.CALCULATED_SYMBOL:
            case SECURITY_KEYS.CREDIT_RATING:
            case SECURITY_KEYS.COUPON_RATE:
            case SECURITY_KEYS.CUSIP:
            case SECURITY_KEYS.DESCRIPTION:
            case SECURITY_KEYS.FUND_CODE:
            case SECURITY_KEYS.FUND_NAME:
            case SECURITY_KEYS.INVESMENT_CODE:
            case SECURITY_KEYS.ISIN:
            case SECURITY_KEYS.ISSUER:
            case SECURITY_KEYS.LIQUIDITY_SCORE:
            case SECURITY_KEYS.TICKER_SHORT:
            case SECURITY_KEYS.TICKER:
            case SECURITY_KEYS.NAME:
            case SECURITY_KEYS.RISK_SCORE:
            case SECURITY_KEYS.COMMISSION:
            case SECURITY_KEYS.DIVIDEND_YIELD:
            case SECURITY_KEYS.FUND_MANAGER:
            case SECURITY_KEYS.FUND_MANDATE:
            case SECURITY_KEYS.FUND_STRUCTURE:
            case SECURITY_KEYS.FUND_TYPE:
            case SECURITY_KEYS.FUND_VEHICLE:
            case SECURITY_KEYS.REPORTING_NAME:
            case SECURITY_KEYS.SEDOL:
            case SECURITY_KEYS.MATURITY_DATE:
            case SECURITY_KEYS.NOTES:
            case SECURITY_KEYS.USER_DEFINED_1:
            case SECURITY_KEYS.USER_DEFINED_2:
            case SECURITY_KEYS.USER_DEFINED_3:
            case SECURITY_KEYS.USER_DEFINED_4:
            case SECURITY_KEYS.USER_DEFINED_5:
            case SECURITY_KEYS.UNIT_OF_MEASURE:
            case SECURITY_KEYS.METRIC_S_MULT_DIV_INDICATOR: {
              updateStandardResponseValue(value, key)
              break
            }
            case SECURITY_KEYS.APPLY_BUY_TAX:
            case SECURITY_KEYS.APPLY_SELL_TAX:
            case SECURITY_KEYS.IS_CASH_EQUIVALENT:
            case SECURITY_KEYS.IS_DEFERRED_SALES_CHARGE:
            case SECURITY_KEYS.IS_MARGIN:
            case SECURITY_KEYS.VALIDATION_PER_POSITION:
            case SECURITY_KEYS.IS_TRADABLE:
            case SECURITY_KEYS.PUBLIC:
            case SECURITY_KEYS.METRIC_S_DISPLAY_QUANTITY: {
              updateStandardResponseBoolean(
                !!value,
                key,
                key === SECURITY_KEYS.PUBLIC
                  ? SECURITY_KEY_PUBLIC_BOOLEAN_MAP
                  : DEFAULT_BOOLEAN_LABEL_MAP
              )
              break
            }
            case SECURITY_KEYS.SECURITY_RISK_RATING: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                securityRiskRatings.data.results
              )
              break
            }
            case SECURITY_KEYS.RISK_RETURN: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                riskReturns.data.results
              )
              break
            }
            case SECURITY_KEYS.ASSET_CLASS_L2: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                assetClassesL2.data.results
              )
              break
            }
            case SECURITY_KEYS.SECTOR: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                sectors.data.results
              )
              break
            }
            case SECURITY_KEYS.REGION: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                regions.data.results
              )
              break
            }
            case SECURITY_KEYS.STRATEGY: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                instrumentStrategies.data.results
              )
              break
            }
            case SECURITY_KEYS.SUB_STRATEGY: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                instrumentSubStrategies.data.results
              )
              break
            }
            case SECURITY_KEYS.LIQUIDITY: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                liquidityScores.data.results
              )
              break
            }
            case SECURITY_KEYS.EXCHANGE: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                exchanges.data.results
              )
              break
            }
            case SECURITY_KEYS.INDUSTRY: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                industries.data.results
              )
              break
            }
            case SECURITY_KEYS.ASSET_CATEGORY: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                assetCategories.data.results
              )
              break
            }
            case SECURITY_KEYS.ASSET_SUB_CATEGORY: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                assetSubCategories.data.results
              )
              break
            }
            case SECURITY_KEYS.TRADING_CURRENCY: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                currencies.data.results
              )
              break
            }
            case SECURITY_KEYS.ASSET_CLASS: {
              updateStandardResponseAssociatedUrl(
                value as string,
                key,
                assetClasses.data.results
              )
              break
            }
            case SECURITY_KEYS.PRIMARY_MARKET:
            case SECURITY_KEYS.SECONDARY_MARKET: {
              if (value) {
                const marketDisplayName = markets.data.results.find(
                  (market) => market.url === value
                ).country
                updateStandardResponseValue(marketDisplayName, key)
              }

              break
            }
            case SECURITY_KEYS.FIRM_SECURITY_TYPE: {
              const newFirmSecurityType = firmSecurityTypes.data.results.find(
                (firmSecurityType) => firmSecurityType.url === value
              )

              if (newFirmSecurityType) {
                const {instrumentModelType, instrumentTradingType} =
                  newFirmSecurityType

                updateStandardResponseOption(
                  instrumentModelType,
                  SECURITY_KEYS.INSTRUMENT_MODEL_TYPE,
                  FIRMSECURITYTYPE_INSTRUMENT_MODEL_TYPE_OPTIONS
                )
                updateStandardResponseOption(
                  instrumentTradingType,
                  SECURITY_KEYS.TRADING_TYPE,
                  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE_OPTIONS
                )
                updateStandardResponseValue(newFirmSecurityType.name, key)
              } else {
                updateStandardResponseValue(null, key)
              }
              break
            }
            case SECURITY_KEYS.STATUS:
            case SECURITY_KEYS.INTEREST_INCOME_LEVEL:
            case SECURITY_KEYS.DIVIDEND_LEVEL:
            case SECURITY_KEYS.CAPITAL_APPRECIATION_LEVEL:
            case SECURITY_KEYS.ISSUER_CREDIT_RATING:
            case SECURITY_KEYS.GEOGRAPHY:
            case SECURITY_KEYS.COMMISSION_TYPE:
            case SECURITY_KEYS.LEGAL_STRUCTURE:
            case SECURITY_KEYS.COUNTRY: {
              const options: IValueLabelOption[] =
                METRIC_INSTRUMENT_OPTIONS_MAP[key]

              updateStandardResponseOption(value as string, key, options)
              break
            }
            case SECURITY_KEYS.INSTRUMENT_MODEL_TYPE:
            case SECURITY_KEYS.TRADING_TYPE:
            case SECURITY_KEYS.FIRM_PROVIDED_KEY:
            case SECURITY_KEYS.CURRENCY: {
              // Not editable
              break
            }
            case SECURITY_KEYS.REGION_VECTORIZED:
            case SECURITY_KEYS.SECTOR_VECTORIZED:
            case SECURITY_KEYS.CURRENCY_VECTORIZED:
            case SECURITY_KEYS.INDUSTRY_VECTORIZED:
            case SECURITY_KEYS.STRATEGY_VECTORIZED:
            case SECURITY_KEYS.ASSET_CLASS_VECTORIZED:
            case SECURITY_KEYS.ASSET_CLASS_L2_VECTORIZED:
            case SECURITY_KEYS.ASSET_CLASS_L3_VECTORIZED: {
              updateStandardResponseValue(
                getVectorPropertyDisplayText(
                  vectorizedDropdownOptions[key],
                  value,
                  INSTRUMENT_PROPERTY_KEY_TO_EXPOSURE_KEYS_MAP[key]
                ),
                key
              )
              break
            }
            default: {
              if (__DEVELOPMENT__) {
                invariant(
                  false,
                  `Should take care of each security key as a case. Missing StandardTable updater for ${key}.`
                )
              }
              break
            }
          }
        }
      }

      showSnackbar({
        variant: 'success',
        message: `Security '${
          applyObjectOverrides(updatedSecurity).name
        }' was successfully updated.`
      })
    } catch (error) {
      handleFormError(
        error,
        formikBag,
        initialValues,
        'An unexpected error occurred while updating the security.'
      )
    }
  }

  return (
    <LoadingContainer loading={security.loading}>
      {security.data && (
        <Formik
          enableReinitialize
          validationSchema={EDIT_SECURITY_VALIDATION_SCHEMA}
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
        >
          {({handleSubmit, isSubmitting}) => {
            return (
              <form onSubmit={handleSubmit} style={{display: 'contents'}}>
                <FormUnsavedPrompt />
                <ControlStateProvider loading={isSubmitting}>
                  <QuickLookDrawerContent>
                    <Grid item xs={12}>
                      <OutlinedInputField
                        disabled
                        key={SECURITY_KEYS.FIRM_PROVIDED_KEY}
                        name={SECURITY_KEYS.FIRM_PROVIDED_KEY}
                        label={
                          SECURITY_KEY_TO_METRIC_COLUMN_TITLE_MAP[
                            SECURITY_KEYS.FIRM_PROVIDED_KEY
                          ]
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <OutlinedInputField
                        key={SECURITY_KEYS.NAME}
                        name={SECURITY_KEYS.NAME}
                        label={
                          SECURITY_KEY_TO_METRIC_COLUMN_TITLE_MAP[
                            SECURITY_KEYS.NAME
                          ]
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ValueLabelAutocompleteField
                        key={SECURITY_KEYS.FIRM_SECURITY_TYPE}
                        name={SECURITY_KEYS.FIRM_SECURITY_TYPE}
                        label='Security Type'
                        ValueLabelAutocompleteProps={{
                          disableClearable: true,
                          options: mapModelToValueLabel(
                            firmSecurityTypes.data?.results
                          )
                        }}
                      />
                    </Grid>
                    {editableSecurityProperties}
                  </QuickLookDrawerContent>
                  <QuickLookDrawerActions>
                    <FormActions hideDirtyText />
                  </QuickLookDrawerActions>
                </ControlStateProvider>
                {vectorizedPropertyEditModalOpen && (
                  <VectorizedPropertyEditModal
                    instrument={security.data}
                    vectorizedPropertyEditKey={vectorizedPropertyEditKey}
                    onClose={closeVectorizedPropertyEditModal}
                  />
                )}
              </form>
            )
          }}
        </Formik>
      )}
    </LoadingContainer>
  )
}
