import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  Autocomplete,
  IAutocompleteProps
} from '@d1g1t/shared/components/mui/autocomplete'
import {IOutlinedInputProps} from '@d1g1t/shared/components/mui/outlined-input'
import {
  ITextFieldProps,
  TextField
} from '@d1g1t/shared/components/mui/text-field'

export interface IValueLabelAutocompleteProps
  extends Omit<
    IAutocompleteProps<any, boolean, boolean, boolean>,
    'options' | 'getOptionLabel' | 'value' | 'onChange' | 'renderInput'
  > {
  options: IValueLabelOption[]
  /**
   * The `value` string of the selected option, or `null` if nothing is selected.
   */
  value: string
  /**
   * `value` is the `value` of the selected option, or `null` if nothing is selected.
   */
  onChange(event: React.ChangeEvent<{}>, value: string): void
  TextFieldProps?: Omit<ITextFieldProps, 'variant' | 'fullWidth'>
  InputProps?: IOutlinedInputProps

  /**
   * Removes padding in custom autocomplete cell for standard table (`SearchableCell`)
   */
  isStandardTableCell?: boolean

  /**
   * Used for autocomplete cells ONLY inside the standard table(when `isStandardTableCell` prop is true).
   * If compact mode is `true` will make size of custom cell smaller, to match compact mode size of other cells in the standard table.
   */
  isCompactMode?: boolean
}

/**
 * Similar to `<ValueLabelSelect>`, but for `Autocomplete`.
 *
 * That is - given an array of `{ label: string, value: string }` objects,
 * will render an `<Autocomplete>`.
 */
export const ValueLabelAutocomplete: React.FC<IValueLabelAutocompleteProps> =
  React.forwardRef((props, ref?: React.Ref<HTMLDivElement>) => {
    const {
      value,
      onChange,
      isStandardTableCell,
      isCompactMode,
      options,
      TextFieldProps = {},
      InputProps = {},
      ...AutocompleteProps
    } = props

    const {t} = useTranslation()

    const translatedOptions = useMemo(
      () =>
        options?.map((option) => ({
          ...option,
          label: t(option.label)
        })),
      [options]
    )

    const valueOption = useMemo<IValueLabelOption>(() => {
      if (!value || !translatedOptions) {
        return null
      }

      return translatedOptions.find((option) => option.value === value) || null
    }, [value, translatedOptions])

    return (
      <Autocomplete
        options={translatedOptions || []}
        loading={options === undefined || options === null}
        getOptionLabel={(option: IValueLabelOption) => option.label}
        value={valueOption}
        onChange={(e: React.ChangeEvent<{}>, option: IValueLabelOption) => {
          onChange(e, option?.value || null)
        }}
        {...AutocompleteProps}
        ref={ref}
        renderInput={(params) => (
          <TextField
            {...params}
            {...(TextFieldProps || {})}
            InputProps={{
              ...params.InputProps,
              ...(InputProps || {})
            }}
            /* eslint-disable react/jsx-no-duplicate-props */
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
              style: {
                padding: isStandardTableCell ? 0 : undefined,
                margin:
                  isStandardTableCell && isCompactMode ? '-5px' : undefined
              }
            }}
            variant='outlined'
            fullWidth
          />
        )}
      />
    )
  })
