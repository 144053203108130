import {ICategory} from '../../../typings'

/**
 * Finds the category for an ID, with additional validation check
 */
export const findCategory = (
  children: ICategory[],
  id: Nullable<string>
): ICategory => {
  if (!id) {
    return children.find((child) => child.default) || children[0]
  }

  const category = children.find((child) => child.id === id)
  if (category) {
    return category
  }

  throw new Error(
    `Invalid id "${id}", if this is happening there is an issue with the stored path`
  )
}
