import React from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {isValid, parse, parseISO} from 'date-fns'

import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

import {classNames} from '@d1g1t/lib/class-names'
import {FNS_DATE_FORMATS, ISO_DATE_FORMAT} from '@d1g1t/lib/constants'

import {useControlState} from '@d1g1t/shared/components/control-state'
import {useDateFormatter} from '@d1g1t/shared/wrappers/formatter'
import {useUserLocalizationPreferences} from '@d1g1t/shared/wrappers/localization-settings/hook'

import * as css from './styles.scss'

export interface IKeyboardDatePickerProps
  extends Omit<KeyboardDatePickerProps, 'value' | 'onChange'> {
  /**
   * A date string in ISO date format yyyy-MM-dd or Unix Epoch
   */
  value: string | number
  /**
   * A date string in ISO date format yyyy-MM-dd
   */
  maxDate?: string
  /**
   * A date string in ISO date format yyyy-MM-dd
   */
  minDate?: string
  /**
   * Smaller height for input field to fit in tables
   */
  smallHeight?: boolean
  /**
   * Adds additional border-radius to give select a "pill" shape
   */
  rounded?: boolean
  /**
   * Updates the border to a muted colour
   */
  mutedBorder?: boolean
  /**
   * Enables future date selection
   */
  allowFuture?: boolean
  /**
   * Hide error message
   */
  hideError?: boolean
  /**
   * Styling of text if multiple values
   */
  multipleValues?: boolean
  /**
   * @param value - A date string in ISO date format YYYY-MM-DD
   */
  onChange(value: string): void
  /**
   * If `true` will use compact mode style for for standard table date pickers. Currently used for standard table compact mode.
   */
  isCompactMode?: boolean
}

export const KeyboardDatePicker: React.FC<IKeyboardDatePickerProps> = ({
  onChange,
  value,
  minDate,
  maxDate,
  allowFuture,
  smallHeight,
  rounded,
  mutedBorder,
  hideError,
  multipleValues,
  disabled,
  isCompactMode,
  ...props
}) => {
  const controlState = useControlState()
  const dateFormatter = useDateFormatter()
  const [dateFormat] = useUserLocalizationPreferences()

  const inputValue = (() => {
    if (!value) {
      return null
    }

    const dateString = (() => {
      if (typeof value === 'number') {
        return dateFormatter.format(value)
      }

      return value
    })()

    if (isValid(parseISO(dateString))) {
      return parseISO(dateString)
    }

    return parseDateString(dateString)
  })()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiKeyboardDatePicker
        disableFuture={!allowFuture}
        disabled={disabled || controlState.loading}
        allowKeyboardControl
        inputVariant='outlined'
        margin='none'
        InputLabelProps={{className: css.noWrap}}
        InputProps={{
          classes: {
            root: classNames(css.root, {
              [css.smallHeight]: smallHeight,
              [css.compactMode]: isCompactMode,
              [css.rounded]: rounded,
              [css.mutedBorder]: mutedBorder,
              [css.multipleValuesText]: multipleValues
            })
          }
        }}
        InputAdornmentProps={{
          classes: {
            positionEnd: css.positionEnd
          }
        }}
        invalidDateMessage={hideError && ''}
        value={inputValue}
        format={FNS_DATE_FORMATS[dateFormat]}
        onChange={(date, value) => {
          onChange(value ? dateFormatter.format(date) : '')
        }}
        maxDate={maxDate ? parseDateString(maxDate) : undefined}
        minDate={minDate ? parseDateString(minDate) : undefined}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}

function parseDateString(value: string): Date {
  return value ? parse(value, ISO_DATE_FORMAT, new Date()) : null
}
