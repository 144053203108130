import {StandardResponse} from '@d1g1t/lib/standard-response'

import {AccountAllocationFilterKey} from './typings'

export function getAccountAllocationFilterCounts(
  chart: StandardResponse,
  isChangedByAccountId: Dictionary<boolean>,
  warningsByAccountId: Dictionary<string[]>
): Record<AccountAllocationFilterKey, number | null> {
  if (!chart) {
    return {
      [AccountAllocationFilterKey.unchanged]: null,
      [AccountAllocationFilterKey.changed]: null,
      [AccountAllocationFilterKey.issues]: null
    }
  }

  return chart.items.reduce<Record<AccountAllocationFilterKey, number>>(
    (prev, {id: accountId}) => {
      if (isChangedByAccountId[accountId]) {
        prev[AccountAllocationFilterKey.changed]++
      } else {
        prev[AccountAllocationFilterKey.unchanged]++
      }

      if (warningsByAccountId[accountId]?.length > 0) {
        prev[AccountAllocationFilterKey.issues]++
      }

      return prev
    },
    {
      [AccountAllocationFilterKey.unchanged]: 0,
      [AccountAllocationFilterKey.changed]: 0,
      [AccountAllocationFilterKey.issues]: 0
    }
  )
}
