import React from 'react'

import MuiBadge, {BadgeProps} from '@material-ui/core/Badge'
import {makeStyles} from '@material-ui/styles'

import {BASIC_COLOURS} from '@d1g1t/config/theme/internal-theme'

export interface IBadgeProps extends BadgeProps {
  /**
   * ACTIVE = GREEN,
   * INACTIVE = YELLOW,
   * PENDING = RED,
   * CLOSED = BLACK
   */
  status?: BADGE_STATUS
}

/**
 * ACTIVE = GREEN,
 * INACTIVE = YELLOW,
 * PENDING = RED,
 * CLOSED = BLACK
 */
export enum BADGE_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  CLOSED = 'closed'
}

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: BASIC_COLOURS.GREEN
  },
  inactive: {
    backgroundColor: BASIC_COLOURS.YELLOW
  },
  pending: {
    backgroundColor: BASIC_COLOURS.RED
  },
  closed: {
    backgroundColor: BASIC_COLOURS.BLACK
  }
}))

export const Badge: React.FC<IBadgeProps> = (props) => {
  const classes = useStyles()

  const getStatusColor = () => {
    switch (props.status) {
      case BADGE_STATUS.ACTIVE:
        return {badge: classes.active}
      case BADGE_STATUS.INACTIVE:
        return {badge: classes.inactive}
      case BADGE_STATUS.PENDING:
        return {badge: classes.pending}
      case BADGE_STATUS.CLOSED:
        return {badge: classes.closed}
      default:
        return null
    }
  }

  return <MuiBadge {...props} classes={getStatusColor()} />
}
