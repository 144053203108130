import React from 'react'

import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon'

export const MaximizeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='827 554 93 94' {...props}>
    <path
      d='M 907.196 612.06854 L 903.2079 612.06854 L 903.1937 627.8653 L 885.9828 610.6543 L 883.1543 613.48276 L 900.3653 630.6937 L 884.5685 630.7079 L 884.5685 634.69596 L 907.196 634.69596 Z'
      fill='#616161'
    />
    <path
      d='M 907.196 612.06854 L 903.2079 612.06854 L 903.1937 627.8653 L 885.9828 610.6543 L 883.1543 613.48276 L 900.3653 630.6937 L 884.5685 630.7079 L 884.5685 634.69596 L 907.196 634.69596 Z'
      stroke='#616161'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
    <path
      d='M 861.9411 634.69596 L 861.9411 630.7079 L 846.1444 630.6937 L 863.3553 613.48276 L 860.5269 610.6543 L 843.3159 627.8653 L 843.3018 612.06854 L 839.3137 612.06854 L 839.3137 634.69596 Z'
      fill='#616161'
    />
    <path
      d='M 861.9411 634.69596 L 861.9411 630.7079 L 846.1444 630.6937 L 863.3553 613.48276 L 860.5269 610.6543 L 843.3159 627.8653 L 843.3018 612.06854 L 839.3137 612.06854 L 839.3137 634.69596 Z'
      stroke='#616161'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
    <path
      d='M 839.3137 589.4411 L 843.3018 589.4411 L 843.3159 573.64436 L 860.5269 590.85534 L 863.3553 588.0269 L 846.1444 570.8159 L 861.9411 570.8018 L 861.9411 566.8137 L 839.3137 566.8137 Z'
      fill='#616161'
    />
    <path
      d='M 839.3137 589.4411 L 843.3018 589.4411 L 843.3159 573.64436 L 860.5269 590.85534 L 863.3553 588.0269 L 846.1444 570.8159 L 861.9411 570.8018 L 861.9411 566.8137 L 839.3137 566.8137 Z'
      stroke='#616161'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
    <path
      d='M 884.5685 566.8137 L 884.5685 570.8018 L 900.3653 570.8159 L 883.1543 588.0269 L 885.9828 590.85534 L 903.1937 573.64436 L 903.2079 589.4411 L 907.196 589.4411 L 907.196 566.8137 Z'
      fill='#616161'
    />
    <path
      d='M 884.5685 566.8137 L 884.5685 570.8018 L 900.3653 570.8159 L 883.1543 588.0269 L 885.9828 590.85534 L 903.1937 573.64436 L 903.2079 589.4411 L 907.196 589.4411 L 907.196 566.8137 Z'
      stroke='#616161'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
  </SvgIcon>
)
