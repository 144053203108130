import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './style.scss'

interface IBarComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * When set to 'fluid', removes the min-width restriction
   * @defaultValue 'expandable'
   */
  width?: 'expandable' | 'fluid'
  /**
   * Positions at the top and keeps it above other elements.
   */
  stickyTopBar?: boolean
  /**
   * Attaches the bar to the bottom. Used for Save/Cancel button bar at the bottom.
   */
  fixedBottomBar?: boolean
  /**
   * Will increase the space on the top for the banner with emulator information
   */
  stickyTopBarEmulatorMode?: boolean
}

export type IBarProps = RequireOnlyOne<
  IBarComponentProps,
  'stickyTopBar' | 'fixedBottomBar'
>

/**
 * Bars are sticky top bars (e.g. `SelectEntities` ' top bar where the `EntityChips` are),
 * or sticky bottom bars (e.g. various bottom save bars where Save and Cancel buttons are).
 */
export const Bar: React.FC<IBarProps> = ({
  className,
  stickyTopBar,
  fixedBottomBar,
  width,
  stickyTopBarEmulatorMode,
  ...props
}) => {
  const elClassName = classNames(css.container, className, {
    [css.fluid]: width === 'fluid',
    [css.stickyTopBar]: stickyTopBar,
    [css.fixedBottomBar]: fixedBottomBar,
    [css.stickyTopBarEmulatorMode]: stickyTopBarEmulatorMode
  })

  return <div className={elClassName} {...props} />
}
