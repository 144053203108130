import {IEntityRelation} from '@d1g1t/api/models'

export function parseEntityName(
  entityRelation: IEntityRelation,
  showFirmProvidedKey = true
): string {
  const {name, firmProvidedKey} = entityRelation

  const idString = `(${firmProvidedKey})`
  if (!showFirmProvidedKey || !firmProvidedKey || name.includes(idString)) {
    return name
  }

  return `${name} ${idString}`
}
