import React from 'react'
import {useTranslation} from 'react-i18next'

import {Checkbox, ICheckboxProps} from '@d1g1t/shared/components/mui/checkbox'
import {FormControl} from '@d1g1t/shared/components/mui/form-control'
import {
  FormControlLabel,
  IFormControlLabelProps
} from '@d1g1t/shared/components/mui/form-control-label'
import {FormHelperText} from '@d1g1t/shared/components/mui/form-helper-text'

import {useFormFieldControl} from '../hook'
import {getFormFieldErrorState} from '../lib'
import {IFormFieldProps} from '../typings'

import * as css from './style.scss'

interface ICheckboxFieldProps extends IFormFieldProps {
  checkboxProps?: ICheckboxProps
  disableErrorText?: boolean
  disableHelperText?: boolean
  disabled?: boolean
  formControlProps?: Omit<IFormControlLabelProps, 'label' | 'control'>
}

/**
 * `formik`-compatible `Checkbox` field
 */
export const CheckboxField: React.FC<ICheckboxFieldProps> = (props) => {
  const {label, checkboxProps = {}, formControlProps = {}} = props
  const [field, meta] = useFormFieldControl(props)
  const {t} = useTranslation()
  const {hasError, errorText} = getFormFieldErrorState(meta)

  const id = props.id || props.name
  const errorTextId = `${id}-error-text`

  return (
    <FormControl error={hasError} fullWidth disabled={props.disabled}>
      <FormControlLabel
        {...formControlProps}
        label={t(label)}
        control={
          <Checkbox
            id={id}
            aria-describedby={errorTextId}
            {...checkboxProps}
            {...field}
            checked={!!field.value}
          />
        }
      />
      {!props?.disableErrorText && (
        <FormHelperText id={errorTextId} className={css.checkboxErrorMessage}>
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  )
}
