import {ITradeOrder, TRADEORDER_QTY_TYPE} from '@d1g1t/api/models'

export enum QTY_TYPE_DSC_FREE {
  DSC_FREE = 'dsc_free'
}

export type QTY_TYPE = TRADEORDER_QTY_TYPE | QTY_TYPE_DSC_FREE
export const QTY_TYPE = {...TRADEORDER_QTY_TYPE, ...QTY_TYPE_DSC_FREE}

export enum MUTUAL_FUND_FIELD_NAMES {
  accountUrl = 'accountUrl', // must not change
  instrumentUrl = 'instrumentUrl', // must not change
  operation = 'operation', // must not change
  instrumentName = 'instrumentName',
  fundCodeUrl = 'fundCodeUrl',
  fundCode = 'fundCode',
  qty = 'qty', // must not change
  qtyType = 'qtyType',
  cusip = 'cusip',
  notes = 'notes',
  instructions = 'instructions',
  dividendTreatment = 'dividendTreatment',
  type = 'type',
  isSellAll = 'isSellAll', // must not change
  commission = 'commission',
  commissionType = 'commissionType',
  expectedPrice = 'expectedPrice',
  proStatus = 'proStatus',
  isSolicited = 'isSolicited'
}

export interface IMutualFundFormValues {
  [MUTUAL_FUND_FIELD_NAMES.instrumentUrl]: ITradeOrder['instrument']
  [MUTUAL_FUND_FIELD_NAMES.instrumentName]: string
  [MUTUAL_FUND_FIELD_NAMES.accountUrl]: string
  [MUTUAL_FUND_FIELD_NAMES.fundCodeUrl]: ITradeOrder['incomingInstrument']
  [MUTUAL_FUND_FIELD_NAMES.fundCode]: ITradeOrder['fundCode']
  [MUTUAL_FUND_FIELD_NAMES.qty]: ITradeOrder['qty'] | 'ALL' | 'DSC Free'
  [MUTUAL_FUND_FIELD_NAMES.qtyType]: ITradeOrder['qtyType'] | QTY_TYPE
  [MUTUAL_FUND_FIELD_NAMES.cusip]: ITradeOrder['cusip']
  [MUTUAL_FUND_FIELD_NAMES.notes]: ITradeOrder['notes']
  [MUTUAL_FUND_FIELD_NAMES.instructions]: ITradeOrder['instructions']
  [MUTUAL_FUND_FIELD_NAMES.dividendTreatment]: ITradeOrder['dividendTreatment']
  [MUTUAL_FUND_FIELD_NAMES.operation]: ITradeOrder['operation']
  [MUTUAL_FUND_FIELD_NAMES.type]: ITradeOrder['type']
  [MUTUAL_FUND_FIELD_NAMES.isSellAll]: ITradeOrder['isSellAll']
  [MUTUAL_FUND_FIELD_NAMES.commission]: ITradeOrder['commission']
  [MUTUAL_FUND_FIELD_NAMES.commissionType]: ITradeOrder['commissionType']
  [MUTUAL_FUND_FIELD_NAMES.expectedPrice]: ITradeOrder['expectedPrice']
  [MUTUAL_FUND_FIELD_NAMES.isSolicited]: ITradeOrder['isSolicited']
  [MUTUAL_FUND_FIELD_NAMES.proStatus]: ITradeOrder['proStatus']
}
