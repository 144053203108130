import invariant from 'invariant'
import {find, get, isEqual} from 'lodash'

import {
  IFilterCriteriaItem,
  IFilterCriteriaOperator
} from '@d1g1t/api/endpoints'
import {FILTERCRITERION_NODE_TYPE} from '@d1g1t/api/models'
import {IFilterValueItem, IGlobalFilterValueItem} from '@d1g1t/typings/general'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {
  FragmentLabelOption,
  HIDE_OPTIONS,
  IComputedPath,
  IFilterOptions,
  IFilterOptionsValues
} from '@d1g1t/shared/wrappers/filter-criteria/typings'

export const findDefaultId = (
  node: IFilterCriteriaItem
): IFilterCriteriaOperator => {
  if (node.items) {
    return findDefaultId(
      node.items.find((childNode) => childNode.default) || node.items[0]
    )
  }

  if (node.operators?.length) {
    // default or first
    return (
      node.operators.find((childNode) => childNode.default) ?? node.operators[0]
    )
  }

  invariant(false, `No operator exists for filter criteria item '${node.name}'`)
}

export const computeNewOperator = (
  criteriaItems: IFilterCriteriaItem[],
  pathFragment: Nullable<string> | Nullable<StrNum[]>,
  index: number
): IFilterCriteriaOperator => {
  const node: Partial<IFilterCriteriaItem> = pathFragment
    ? get(criteriaItems, pathFragment)
    : {items: criteriaItems}

  if (node.items) {
    const newNode = node.items[index]

    return findDefaultId(newNode)
  }

  return node.operators[index]
}

/**
 *  Creates nodeId from Filter Criteria Operator
 *  Used for creating filter criteria paths
 */
export const nodeIdFromFilterOperator = (
  node: IFilterCriteriaOperator
): string => {
  let id = String(node.slug)

  if (node.roleId) {
    id += `-role:${node.roleId}`
  }

  if (node.teamId) {
    id += `-team:${node.teamId}`
  }

  return id
}

/**
 *  Gets nodeId from Filter Value Item
 *  Used to find the corresponding path in Filter Criteria
 */
export const nodeIdFromFilterItem = (node: IFilterValueItem): string => {
  let id = extractIdFromUrl(node.filterCriterion)

  if (node.roleId) {
    id += `-role:${node.roleId}`
  }

  if (node.teamId) {
    id += `-team:${node.teamId}`
  }

  return id
}

/**
 *  Gets nodeId from Filter Value Item
 *  Used to find the corresponding path in Filter Criteria
 */
export const nodeIdFromGlobalFilterItem = (
  node: IGlobalFilterValueItem
): string => {
  let id = extractIdFromUrl(node.filterCriterion)

  if (node.role) {
    id += `-role:${extractIdFromUrl(node.role)}`
  }

  if (node.team) {
    id += `-team:${extractIdFromUrl(node.team)}`
  }

  return id
}

/**
 * Returns paths: an object with computed filter path objects (containg type, format and
 * allowed values) keyed by slug-role:roleId-team:teamId
 * defaultOperator: the default operator when a new filter is created
 * items: filter criteria items tree after filter has been applied
 * @param criteriaItems - filter criteria tree
 * @param useCustodianAccounts - boolean to filter custodian account filters
 * @param nodeTypes - filter criteria node types to filter (inclusive) if provided
 * @param hideOptions - hide metric or property options
 */
export const preComputedPaths = (
  criteriaItems: IFilterCriteriaItem[],
  useCustodianAccounts: boolean,
  nodeTypes?: FILTERCRITERION_NODE_TYPE[],
  hideOptions?: HIDE_OPTIONS[]
): {
  paths: {[id: string]: IComputedPath}
  defaultOperator: IFilterCriteriaOperator
  items: IFilterCriteriaItem[]
} => {
  if (!useCustodianAccounts) {
    // hide all custodian account filters if not visible
    criteriaItems = criteriaItems.filter(
      (criteriaItem) =>
        criteriaItem.name !== FILTERCRITERION_NODE_TYPE.CUSTODIAN_ACCOUNT
    )
  }

  if (nodeTypes) {
    const nodeTypeNames = nodeTypes.map((nodeType) => {
      return nodeType.charAt(0) === '_' ? nodeType.slice(1) : nodeType
    })

    criteriaItems = criteriaItems.filter((criteriaItem) => {
      return nodeTypeNames.includes(criteriaItem.name)
    })

    if (!find(criteriaItems, {default: true})) {
      criteriaItems[0].default = true
    }

    if (hideOptions) {
      for (const criteriaItem of criteriaItems) {
        criteriaItem.items = criteriaItem.items.filter(
          (item) => !hideOptions.includes(item.name as any)
        )
      }
    }
  }

  const paths: {[id: string]: IComputedPath} = {}
  const defaultPath: StrNum[] = []
  let defaultOperator: IFilterCriteriaOperator

  const crawlPath = (
    currentPath: StrNum[],
    node: IFilterCriteriaItem
  ): void => {
    if (node.items) {
      const newPath = [...currentPath, 'items']
      for (const [i, childNode] of node.items.entries()) {
        if (childNode.default && isEqual(defaultPath, currentPath)) {
          defaultPath.push('items')
          defaultPath.push(i)
        }
        crawlPath([...newPath, i], childNode)
      }
    } else {
      const newPath = [...currentPath, 'operators']
      for (const [i, childNode] of node.operators.entries()) {
        if (childNode.default && isEqual(defaultPath, currentPath)) {
          defaultPath.push('operators')
          defaultPath.push(i)
        }

        const finalPath = [...newPath, i]
        if (isEqual(finalPath, defaultPath)) {
          defaultOperator = childNode
        }

        paths[nodeIdFromFilterOperator(childNode)] = {
          path: finalPath,
          type: childNode.key as any,
          format: node.format
        }

        if (node.allowedValues) {
          paths[nodeIdFromFilterOperator(childNode)].allowedValues =
            node.allowedValues
        }
      }
    }
  }

  for (const [i, node] of criteriaItems.entries()) {
    if (node.default) {
      defaultPath.push(i)
    }
    crawlPath([i], node)
  }

  return {
    paths,
    defaultOperator,
    items: criteriaItems
  }
}

/**
 * Returns select options given a path
 * @param criteriaItems - filter criteria tree
 * @param path - path down tree via keys and indexes in a list
 */
export const getFilterOptions = (
  criteriaItems: IFilterCriteriaItem[],
  path: StrNum[]
): {options: IFilterOptions; values: IFilterOptionsValues} => {
  const values: IFilterOptionsValues = {
    subjects: null,
    propertiesOrMetrics: null,
    submetrics: null,
    operators: null
  }

  values.subjects = {
    fragment: null,
    index: Number(path[0])
  }

  const subjects: FragmentLabelOption[] = criteriaItems.map((item, i) => ({
    value:
      i === values.subjects.index
        ? values.subjects
        : {
            fragment: null,
            index: i
          },
    label: item.name
  }))

  values.propertiesOrMetrics = {
    fragment: path.slice(0, 3),
    index: Number(path[4])
  }

  const propertiesFragment = [...path.slice(0, 2), 0]
  const filterCriteriaPropertiesItems: IFilterCriteriaItem[] = get(
    criteriaItems,
    [...propertiesFragment, 'items']
  )

  const properties: FragmentLabelOption[] = filterCriteriaPropertiesItems.map(
    (item, i) => ({
      value:
        i === values.propertiesOrMetrics.index &&
        isEqual(propertiesFragment, values.propertiesOrMetrics.fragment)
          ? values.propertiesOrMetrics
          : {
              fragment: propertiesFragment,
              index: i
            },
      label: item.name
    })
  )

  const metricsFragment = [...path.slice(0, 2), 1]
  const hasMetrics = !!get(criteriaItems, metricsFragment)

  const filterCriteriaMetricsItems: IFilterCriteriaItem[] = get(criteriaItems, [
    ...metricsFragment,
    'items'
  ])

  const metrics: FragmentLabelOption[] = hasMetrics
    ? filterCriteriaMetricsItems.map((item, i) => ({
        value:
          i === values.propertiesOrMetrics.index &&
          isEqual(metricsFragment, values.propertiesOrMetrics.fragment)
            ? values.propertiesOrMetrics
            : {
                fragment: metricsFragment,
                index: i
              },
        label: item.name
      }))
    : []

  const submetrics: FragmentLabelOption[][] = []
  values.submetrics = []

  let remainingFragment = path.slice(0, 5)
  const getRemainingOptions = (
    node: IFilterCriteriaItem
  ): FragmentLabelOption[] => {
    if (node.items) {
      const submetricValue = {
        fragment: remainingFragment,
        index: Number(path[remainingFragment.length + 1])
      }
      submetrics.push(
        node.items.map((item, i) => ({
          value:
            i === submetricValue.index
              ? submetricValue
              : {
                  fragment: remainingFragment,
                  index: i
                },
          label: item.name
        }))
      )
      values.submetrics.push(submetricValue)
      remainingFragment = path.slice(0, remainingFragment.length + 2)

      return getRemainingOptions(get(criteriaItems, remainingFragment))
    }

    values.operators = {
      fragment: remainingFragment,
      index: Number(path[path.length - 1])
    }

    return node.operators.map((item, i) => ({
      value:
        i === values.operators.index
          ? values.operators
          : {
              fragment: remainingFragment,
              index: i
            },
      label: item.name
    }))
  }

  const operators = getRemainingOptions(get(criteriaItems, remainingFragment))

  return {
    values,
    options: {
      subjects,
      properties,
      metrics,
      submetrics,
      operators
    }
  }
}
