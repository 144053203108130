import React, {useContext} from 'react'

import {TextInput} from '@d1g1t/shared/components/form/text-input'
import {Label} from '@d1g1t/shared/components/typography'

import {MetricsFormContext} from '../../context'

export const MetricsProperties: React.FC = () => {
  const {metricItem, onDisplayNameChange} = useContext(MetricsFormContext)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onDisplayNameChange(event.target.value)
  }

  return (
    <>
      <Label>Display Name</Label>
      <TextInput
        standard
        value={metricItem.displayName}
        onChange={handleChange}
      />
    </>
  )
}
