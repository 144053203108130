/* eslint-disable lines-between-class-members */

import {ALL_MODELS} from '@d1g1t/api/models'

import {assertNever} from '@d1g1t/lib/assert'
import {createRootPath, UrlSelection} from '@d1g1t/lib/url'

import {ENTITY_SUPPORTED_MODELS} from './constants'
import {ManageFundLocations} from './manage-fund-locations'
import {SecurityModelLocations} from './security-model-locations'
import {SingleClientLocations} from './single-client-locations'

export {AdministrationLocations} from './administration-locations'
export {BillingLocations} from './billing-locations'
export {ClientsLocations} from './clients-locations'
export {ContactsLocations} from './contacts-locations'
export {ContactGroupsLocations} from './contact-groups-locations'
export {DMSDocumentsLocations} from './dms-documents-locations'
export {DocumentsLocations} from './documents-locations'
export {ExploreLocations} from './explore-locations'
export {GlobalFilterLocations} from './global-filter-locations'
export {InvestmentProgramLocations} from './investment-program-locations'
export {ManageFundLocations} from './manage-fund-locations'
export {SecurityModelLocations} from './security-model-locations'
export {SingleClientLocations} from './single-client-locations'
export {TrackComplianceLocations} from './track-compliance-locations'
export {TradeLocations} from './trade-locations'

export {ENTITY_EXTENDED_SUPPORTED_MODELS} from './constants'

export type DrilldownSection = 'past' | 'present' | 'future' | 'activity'

export const modelPath = (
  modelNameCaseSensitive: ALL_MODELS,
  selection: UrlSelection
) => {
  const modelName = modelNameCaseSensitive.toLowerCase() as ALL_MODELS
  switch (modelName) {
    case ALL_MODELS.ACCOUNT:
    case ALL_MODELS.CORPORATION:
    case ALL_MODELS.FOUNDATION:
    case ALL_MODELS.HOUSEHOLD:
    case ALL_MODELS.INDIVIDUAL:
    case ALL_MODELS.INVESTMENTMANDATE:
    case ALL_MODELS.PERSON:
    case ALL_MODELS.PRIVATEEQUITYHOLDING:
    case ALL_MODELS.TRUST:
      return SingleClientLocations.default(selection)
    case ALL_MODELS.PORTFOLIO:
      return SecurityModelLocations.default(selection)
    case ALL_MODELS.CLASSSERIES:
      return ManageFundLocations.overview(selection)
    case ALL_MODELS.ACCOUNTGROUP:
    case ALL_MODELS.ADVISOR:
    case ALL_MODELS.CASHPOSITION:
    case ALL_MODELS.CURRENCY:
    case ALL_MODELS.CUSTODIANACCOUNT:
    case ALL_MODELS.DATASET:
    case ALL_MODELS.ENTITYCOLLECTION:
    case ALL_MODELS.FUND:
    case ALL_MODELS.INSTRUMENT:
    case ALL_MODELS.INVESTMENTMANDATEGUIDELINELIMIT:
    case ALL_MODELS.INVESTMENTPROGRAM:
    case ALL_MODELS.PERSONADDRESS:
    case ALL_MODELS.PERSONEMAIL:
    case ALL_MODELS.PERSONGROUP:
    case ALL_MODELS.POSITIONHOLDING:
    case ALL_MODELS.PROFILE:
    case ALL_MODELS.PROFILEGROUP:
    case ALL_MODELS.S3FILE:
    case ALL_MODELS.SUBADVISOR:
    case ALL_MODELS.TAG:
    case ALL_MODELS.TRADELOCATIONRULESET:
      return null
    default:
      assertNever(
        modelName,
        `Invalid modelName <${modelName}> given to modelPath()`
      )
  }
}

// Paths

export const activatePath = '/activate'

export const monitorPath = (
  drilldown: DrilldownSection | 'overview' = 'overview'
) => `/monitor/${drilldown}`

export const overviewPath = monitorPath
export const drilldownPastPath = () => monitorPath('past')
export const drilldownPresentPath = () => monitorPath('present')
export const drilldownFuturePath = () => monitorPath('future')
export const monitorActivityPath = () => monitorPath('activity')

export const searchPath = (searchText: string, section?: ALL_MODELS) => {
  const query = `?q=${searchText}`

  if (section) {
    return `/search/${section}${query}`
  }

  return `/search${query}`
}

// Securities
export const securitiesListPath = () => '/securities'

// Used in redirects to determin which URL to create
export enum ENTITY_SECTIONS {
  DEFAULT = 'default',
  MONITOR = 'monitor',
  REBALANCE = 'rebalance',
  REBALANCE_PORTFOLIOS = 'rebalanceStrategies',
  ACCOUNT_DETAILS = 'accountProfile',
  CLIENT_PROFILE = 'profile',
  COMPARE = 'compare',
  MODEL = 'model',
  TRADE_DIRECTIVE = 'tradeDirective'
}

const entityRoot = (...paths: string[]) => createRootPath('redirect', paths)

export const entityLocations = {
  /**
   * Default location for redirects, most models default to client monitor.
   * Checks for supported models before returning if present.
   */
  default(
    entityId: string,
    modelName?: ALL_MODELS,
    supportedModels = ENTITY_SUPPORTED_MODELS
  ) {
    if (!entityId || (modelName && !supportedModels.has(modelName))) {
      return null
    }

    return entityRoot(entityId, ENTITY_SECTIONS.DEFAULT)
  },
  /**
   * Redirect to client monitor path
   */
  monitor(entityId: string) {
    return entityRoot(entityId, ENTITY_SECTIONS.MONITOR)
  },
  /**
   * Redirect to rebalance path
   */
  rebalance(entityId: string) {
    return entityRoot(entityId, ENTITY_SECTIONS.REBALANCE)
  },
  /**
   * Redirect to client profile subpage of the Manage Client page.
   */
  clientProfile(entityId: string) {
    return entityRoot(entityId, ENTITY_SECTIONS.CLIENT_PROFILE)
  },
  /**
   * Redirect to single account detail subpage of the Manage Client page.
   */
  accountDetails(entityId: string) {
    return entityRoot(entityId, ENTITY_SECTIONS.ACCOUNT_DETAILS)
  },
  /**
   * Redirect to compare path
   */
  compare(entityId: string) {
    return entityRoot(entityId, ENTITY_SECTIONS.COMPARE)
  },
  /**
   * Redirect to the specific models path, IE single mandate
   */
  model(entityId: string) {
    return entityRoot(entityId, ENTITY_SECTIONS.MODEL)
  },
  /**
   * Redirect to trade directive path
   */
  tradeDirective(entityId: string) {
    return entityRoot(entityId, ENTITY_SECTIONS.TRADE_DIRECTIVE)
  },
  /**
   * Redirect to rebalance-portfolios path
   */
  rebalancePortfolios(entityId: string) {
    return entityRoot(entityId, ENTITY_SECTIONS.REBALANCE_PORTFOLIOS)
  }
}
