import React, {useCallback, useEffect, useRef} from 'react'

import {datadogRum} from '@datadog/browser-rum'

import {ALL_MODELS} from '@d1g1t/api/models'

import {isKeyDown} from '@d1g1t/lib/keyboard'

import {Flex} from '@d1g1t/shared/components/flex'
import {Search} from '@d1g1t/shared/containers/search'

import * as css from './style.scss'

export const SecondaryMenuSearchBar: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  const focusInput = useCallback((event: KeyboardEvent) => {
    if (event.code !== 'Slash' || isKeyDown(event)) {
      return
    }

    if (document.activeElement === document.body) {
      event.preventDefault()

      inputRef.current.focus()
      datadogRum.addAction('searchBox', {
        trigger: 'shortcut',
        value: 'Slash'
      })
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', focusInput)
    return () => {
      window.removeEventListener('keydown', focusInput)
    }
  }, [focusInput])

  return (
    <Flex grow alignCenter className={css.searchContainer}>
      <Search
        inline
        showDetails
        showSearchIcon
        linkToResource
        clearOnResultSelect
        limit={50}
        groupAccountsByClient={false}
        searchBy={[
          ALL_MODELS.ACCOUNT,
          ALL_MODELS.INDIVIDUAL,
          ALL_MODELS.HOUSEHOLD,
          ALL_MODELS.PORTFOLIO,
          ALL_MODELS.FOUNDATION,
          ALL_MODELS.TRUST,
          ALL_MODELS.CORPORATION,
          ALL_MODELS.CLASSSERIES,
          ALL_MODELS.INVESTMENTMANDATE
        ]}
        placeholder='Search for account, client, fund or portfolio'
        inputRef={inputRef}
        isAccountSpecificNot
        data-testid='input-global-search'
      />
    </Flex>
  )
}
