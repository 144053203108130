import React from 'react'

import EmptyIcon from '@material-ui/icons/Adjust'
import FullIcon from '@material-ui/icons/Brightness1'
import SemiFullIcon from '@material-ui/icons/RadioButtonChecked'
import CircleIcon from '@material-ui/icons/RadioButtonUnchecked'

interface ISmallIconProps {
  color?: string
  /**
   * ```
   * "default": 9
   * "large": 14
   * ```
   */
  size?: 'default' | 'large'
}

function sizeFromProp(size: 'default' | 'large'): number {
  // small-switch to allow easy addition of new options
  switch (size) {
    case 'large':
      return 12
    default:
      return 9
  }
}

export const createSmallIcon =
  (Icon: React.ComponentType<any>): React.FC<ISmallIconProps> =>
  ({color, size = 'default'}) => {
    const dimention = sizeFromProp(size)

    return (
      <Icon
        data-testid='small-icon'
        style={{color, width: dimention, height: dimention}}
      />
    )
  }

export const SmallFullIcon = createSmallIcon(FullIcon)
export const SmallEmptyIcon = createSmallIcon(EmptyIcon)
export const SmallSemiFullIcon = createSmallIcon(SemiFullIcon)
export const SmallCircleIcon = createSmallIcon(CircleIcon)
