import {ALL_MODELS} from '@d1g1t/api/models'

import {StandardResponse} from '@d1g1t/lib/standard-response'

import {IEntityChipSeparatedHoldings} from './typings'

/**
 * Returns a list of item IDs corresponding to a given list of StandardResponseItem model IDs.
 *
 * @param response - StandardResponse to search
 * @param modelIds - list of StandardResponseItem model IDs
 */
export const findItemIdsFromModelIds = (
  response: StandardResponse,
  modelIds: string[]
): string[] => {
  const itemIds: string[] = []

  for (const item of response.itemsDeep()) {
    if (modelIds.includes(item.modelId)) {
      itemIds.push(item.id)
    }
  }

  return itemIds
}

/**
 * Separate model IDs into account model IDs and position model IDs.
 * Partial accounts are used for ClientChip text count when positions are selected.
 *
 * @param response -  StandardResponse to search
 * @param modelIds - list of StandardResponseItem model IDs
 */
export const separateAccountsAndPositions = (
  response: StandardResponse,
  modelIds: string[]
): IEntityChipSeparatedHoldings => {
  let selectedPositions = 0
  let selectedAccounts = 0
  let totalPositions = 0
  let totalAccounts = 0

  const accounts: string[] = []
  const partialAccounts = new Set<string>()
  const positions: string[] = []

  for (const item of response.itemsDeep()) {
    if (item.modelName === ALL_MODELS.ACCOUNT) {
      totalAccounts++
    } else if (
      item.modelName === ALL_MODELS.CASHPOSITION ||
      item.modelName === ALL_MODELS.POSITIONHOLDING
    ) {
      totalPositions++
    }

    if (modelIds.includes(item.modelId)) {
      if (item.modelName === ALL_MODELS.ACCOUNT) {
        accounts.push(item.modelId)
        selectedAccounts++
        selectedPositions += item.children ? item.children.length : 0
      } else {
        positions.push(item.modelId)
        selectedPositions++
        partialAccounts.add(item.itemParent?.id)
      }
    }
  }

  return {
    accounts,
    partialAccounts,
    positions,
    totalAccounts,
    totalPositions,
    selectedPositions,
    selectedAccounts
  }
}
