import React, {useContext} from 'react'

import {Checkbox} from '@d1g1t/shared/components/mui/checkbox'
import {FormControlLabel} from '@d1g1t/shared/components/mui/form-control-label'

import {MetricsFormContext} from '../../../../context'
import {conditionResults} from '../../../../lib'
import {ContributionDimensionKey} from '../../../../typings'
import {urlsFromConditionResultValue} from './lib'

interface ISelectConditionResultsProps {
  contributionDimensionKey: ContributionDimensionKey
}

export const SelectConditionResults: React.FC<ISelectConditionResultsProps> = (
  props
) => {
  const {contributionDimensionKey} = props

  const {
    metricItem,
    createOnConditionOptionCheck,
    contributionDimensions,
    contributionDimensionOptions
  } = useContext(MetricsFormContext)

  const {options, value, conditionResultsKey} = conditionResults(
    metricItem,
    contributionDimensions,
    contributionDimensionKey,
    contributionDimensionOptions
  )

  const urls = urlsFromConditionResultValue(value)

  return (
    <>
      {options.map((option) => (
        <div key={option.url} style={{paddingLeft: '1rem'}}>
          <FormControlLabel
            label={option.name}
            control={
              <Checkbox
                checked={urls.includes(option.url)}
                onChangeValue={createOnConditionOptionCheck(
                  option.url,
                  urls,
                  conditionResultsKey
                )}
              />
            }
          />
        </div>
      ))}
    </>
  )
}
