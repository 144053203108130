import React, {useRef, useState} from 'react'
import {matchPath, useLocation} from 'react-router-dom'

import {useApiQuery} from 'fairlight'
import pluralize from 'pluralize'

import CloseIcon from '@material-ui/icons/Close'
import ExpandIcon from '@material-ui/icons/ExpandMore'
import FilterListIcon from '@material-ui/icons/FilterList'

import {EntityEndpoints, RuleFilterEndpoints} from '@d1g1t/api/endpoints'
import {ALL_MODELS} from '@d1g1t/api/models'

import {useToggleState} from '@d1g1t/lib/hooks'

import {ChipGrey, IChipGreyBitProps} from '@d1g1t/shared/components/chip-grey'
import {
  mapClientStatusToBadgeStatus,
  mapMandateIsActiveStrategyToBadgeStatus
} from '@d1g1t/shared/components/client-icon/lib'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Menu} from '@d1g1t/shared/components/mui/menu'
import {RuleFilterModal} from '@d1g1t/shared/containers/rule-filter-modal'

import {shouldApplyDefaultRuleFilters} from '../../lib'
import {HoldingsFilter} from '../holdings-filter'
import {DropdownMenu} from './components/dropdown-menu'
import {AccountFiltersMenu} from './components/dropdown-menu/components/accounts-filter-submenu'
import {RuleFiltersMenu} from './components/dropdown-menu/components/rules-filter-submenu'
import {useEntityChipContext} from './context'
import {IEntityChipProps} from './typings'

export * from './typings'
export * from './context'
export * from './components/dropdown-menu/constants'

export const EntityChip: React.FC<IEntityChipProps> = (props) => {
  // Main EntityChip dropdown visibility
  const [dropdownOpen, , setDropdownOpen, setDropdownClosed] =
    useToggleState(false)

  const location = useLocation()

  const chipRef = useRef(null)

  // Rule filter dropdown that opens on popout click
  const [
    ruleFilterPopoutDropdownOpen,
    ,
    setRuleFilterPopoutDropdownOpen,
    setRuleFilterPopoutDropdownClosed
  ] = useToggleState(false)

  // Account filter dropdown that opens on account filter greyBit click
  const [
    accountFilterPopoutDropdownOpen,
    ,
    setAccountFilterPopoutDropdownOpen,
    setAccountFilterPopoutDropdownClosed
  ] = useToggleState(false)

  // For HoldingsFilter modal visibility
  const [holdingsFilterOpen, , setHoldingsFilterOpen, setHoldingsFilterClosed] =
    useToggleState(false)

  // For RuleFilter modal visibility
  const [ruleFilterOpen, , setRuleFilterOpen, setRuleFilterClosed] =
    useToggleState(false)

  // The entity to open HoldingsFilter for
  const [holdingsFilterTargetEntity, setHoldingsFilterTargetEntity] = useState<{
    entityId: string
    entityModel: ALL_MODELS
    entityName: string
  }>(null)

  // Opens the HoldingsFilter modal component
  const handleHoldingsFilterModalOpen = (
    entityId: string,
    entityModel: ALL_MODELS,
    entityName: string,
    options?: {noDropdownToggle?: boolean}
  ) => {
    if (!options?.noDropdownToggle) {
      setDropdownClosed()
    }
    setHoldingsFilterTargetEntity({
      entityId,
      entityModel,
      entityName
    })
    setHoldingsFilterOpen()
  }

  const [allRuleFilters] = useApiQuery(RuleFilterEndpoints.list(), {
    fetchPolicy: 'cache-and-fetch'
  })

  const {
    entity: entitySelection,
    entitySearchResult,
    defaultRuleFilters,
    focused,
    actions,
    readOnly
  } = useEntityChipContext()

  const [entityRelationsResults] = useApiQuery(
    EntityEndpoints.entityRelationships(entitySearchResult.entityId)
  )

  const selectedAccountsCount = entitySelection?.accounts?.length ?? 0
  const selectedPositionsCount = entitySelection?.positions?.length ?? 0

  // Automatically apply the default rule filter(s) if advisor has not modified filters
  if (
    shouldApplyDefaultRuleFilters(
      props,
      defaultRuleFilters,
      entitySelection,
      entitySearchResult
    )
  ) {
    actions.applyEntityFilters(
      entitySearchResult.entityId,
      {
        ruleFilters: defaultRuleFilters.map((rule) => rule.url)
      },
      {settingDefaultRuleFilters: true}
    )
  }

  const holdingsCountSuffix: string = (() => {
    const parts: string[] = []

    if (selectedAccountsCount === 1) {
      // When only 1 account is selected, show its name + firm provided key

      const account = entityRelationsResults.data?.accounts.find(
        (acc) => acc.entityId === entitySelection.accounts[0]
      )
      parts.push(account ? `${account.name}` : '1 account')
    } else if (selectedAccountsCount > 0) {
      parts.push(
        `${selectedAccountsCount} ${pluralize(
          'account',
          selectedAccountsCount
        )}`
      )
    }

    if (selectedPositionsCount > 0) {
      parts.push(
        `${selectedPositionsCount} ${pluralize(
          'position',
          selectedPositionsCount
        )}`
      )
    }

    return parts.join(', ')
  })()

  const ruleFiltersSuffix = (() => {
    if (!entitySelection?.ruleFilters || !allRuleFilters.data) {
      return ''
    }

    if (entitySelection.ruleFilters.length > 1) {
      return `${entitySelection.ruleFilters.length} rule filters`
    }

    return (
      allRuleFilters.data.results.find(
        (rule) => rule.url === entitySelection.ruleFilters[0]
      )?.name ?? '1 rule filter'
    )
  })()

  const ruleFiltersTooltipText = (() => {
    if (!entitySelection?.ruleFilters || !allRuleFilters.data) {
      return ''
    }

    return allRuleFilters.data.results
      .filter((rule) => entitySelection.ruleFilters.includes(rule.url))
      .map((activeRule) => activeRule.name)
      .join(', ')
  })()

  const greyBits: IChipGreyBitProps[] = (() => {
    const bits: IChipGreyBitProps[] = []

    if (holdingsCountSuffix) {
      bits.push({
        text: holdingsCountSuffix,
        tooltipText: holdingsCountSuffix,
        leftIcon: <FilterListIcon fontSize='small' />,
        onTextClick: readOnly ? undefined : setAccountFilterPopoutDropdownOpen,
        onDismiss:
          readOnly || props.singleAccount
            ? undefined
            : () => {
                // Does not modify applied rule filters
                actions.applyEntityFilters(entitySearchResult.entityId, {
                  accounts: [],
                  positions: []
                })
              }
      })
    }

    if (ruleFiltersSuffix !== '' && ruleFiltersTooltipText !== '') {
      bits.push({
        text: ruleFiltersSuffix,
        tooltipText: ruleFiltersTooltipText,
        strikethrough: !!matchPath(
          location.pathname,
          props.strikethroughLocations
        ),
        leftIcon: <FilterListIcon fontSize='small' />,
        onTextClick: setRuleFilterPopoutDropdownOpen,
        onDismiss: () => {
          // Does not modify filtered accounts/positions
          actions.applyEntityFilters(entitySearchResult.entityId, {
            ruleFilters: []
          })
        }
      })
    }
    return bits
  })()

  // Dropdown unavailable, the rightIcon is the dismiss icon
  const noDropdown =
    readOnly ||
    [
      ALL_MODELS.CLASSSERIES,
      ALL_MODELS.PORTFOLIO,
      ALL_MODELS.ACCOUNTGROUP
    ].includes(entitySearchResult.modelName)

  const selectedEntityStatus = (() => {
    if (
      [
        ALL_MODELS.INDIVIDUAL,
        ALL_MODELS.CORPORATION,
        ALL_MODELS.FOUNDATION,
        ALL_MODELS.TRUST
      ].includes(entitySearchResult.modelName)
    ) {
      return mapClientStatusToBadgeStatus(
        entityRelationsResults.data?.clients.find(
          (client) => client.entityId === entitySearchResult.entityId
        )?.status
      )
    }

    if (entitySearchResult.modelName === ALL_MODELS.INVESTMENTMANDATE) {
      return mapMandateIsActiveStrategyToBadgeStatus(
        entityRelationsResults.data?.mandates.find(
          (mandate) => mandate.entityId === entitySearchResult.entityId
        )?.isActiveStrategy
      )
    }
  })()

  const filteredAccounts =
    entityRelationsResults.data?.accounts.filter((account) =>
      [account.ownerEntityId, account.mandateEntityId].includes(
        entitySearchResult.entityId
      )
    ) ?? []

  return (
    <>
      <ChipGrey
        ref={chipRef}
        focused={focused}
        loading={entityRelationsResults.loading}
        text={`${entitySearchResult.printName || entitySearchResult.name} (${
          entitySearchResult.firmProvidedKey
        })`}
        tooltipText={entitySearchResult.printName || entitySearchResult.name}
        model={entitySearchResult.modelName}
        status={selectedEntityStatus}
        onClick={noDropdown ? undefined : setDropdownOpen}
        greyBits={greyBits}
        popout={{
          enablePopout:
            !readOnly &&
            !props.disableRuleBasedFiltering &&
            ruleFiltersSuffix === '' &&
            ![ALL_MODELS.ACCOUNTGROUP, ALL_MODELS.PORTFOLIO].includes(
              entitySearchResult.modelName
            ),
          onPopoutClick: setRuleFilterPopoutDropdownOpen,
          popoutTooltipText: 'Filter Rules',
          popoutIcon: <FilterListIcon fontSize='small' />
        }}
        rightIcon={
          !readOnly && (
            <IconButton
              small
              onClick={() => {
                if (noDropdown) {
                  actions.removeEntity(entitySearchResult.entityId)
                }
              }}
            >
              {noDropdown ? (
                <CloseIcon fontSize='small' />
              ) : (
                <ExpandIcon fontSize='small' />
              )}
            </IconButton>
          )
        }
      >
        <DropdownMenu
          open={dropdownOpen}
          anchorEl={chipRef.current}
          entityRelations={entityRelationsResults.data}
          singleAccount={props.singleAccount}
          disableHoldingsFiltering={props.disableHoldingsFiltering}
          disableRuleBasedFiltering={props.disableRuleBasedFiltering}
          hideInvestmentMandatesSubmenu={props.hideInvestmentMandatesSubmenu}
          holdingsCountSuffix={holdingsCountSuffix}
          onClose={setDropdownClosed}
          onFilterModalOpen={handleHoldingsFilterModalOpen}
          onRuleFilterModalOpen={setRuleFilterOpen}
        />
        {/* Popout RuleFilter */}
        <Menu
          open={ruleFilterPopoutDropdownOpen}
          onClose={setRuleFilterPopoutDropdownClosed}
          anchorEl={chipRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom'
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
        >
          <RuleFiltersMenu
            onClose={setRuleFilterPopoutDropdownClosed}
            onManageRuleFiltersClick={() => {
              setRuleFilterPopoutDropdownClosed()
              setRuleFilterOpen()
            }}
          />
        </Menu>
        {/* Account Filter dropdown menu. Opens on GreyBit click. */}
        <Menu
          open={accountFilterPopoutDropdownOpen}
          onClose={setAccountFilterPopoutDropdownClosed}
          anchorEl={chipRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom'
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
        >
          <AccountFiltersMenu
            ownerEntityId={entitySearchResult.entityId}
            singleAccount={props.singleAccount}
            accountRelations={filteredAccounts}
            onClose={setAccountFilterPopoutDropdownClosed}
            onFilterHoldingsClick={() => {
              setAccountFilterPopoutDropdownClosed()
              handleHoldingsFilterModalOpen(
                entitySearchResult.entityId,
                entitySearchResult.modelName,
                entitySearchResult.name
              )
            }}
          />
        </Menu>
      </ChipGrey>
      {!props.disableHoldingsFiltering &&
        holdingsFilterOpen &&
        holdingsFilterTargetEntity && (
          <HoldingsFilter
            entityId={holdingsFilterTargetEntity.entityId}
            entityModel={holdingsFilterTargetEntity.entityModel}
            entityPrintName={holdingsFilterTargetEntity.entityName}
            filterByAccountsOnly={props.filterByAccountsOnly}
            accountsEntityIds={entityRelationsResults.data.accounts.map(
              (account) => account.entityId
            )}
            onClose={setHoldingsFilterClosed}
          />
        )}
      {ruleFilterOpen && <RuleFilterModal onClose={setRuleFilterClosed} />}
    </>
  )
}
