import React, {useEffect, useMemo, useState} from 'react'

import {ApiError, useApiMutation, useApiQuery} from 'fairlight'
import FileSaver from 'file-saver'
import {FormikProvider, useFormik} from 'formik'
import produce from 'immer'
import {get, invert, mapValues} from 'lodash'
import * as Yup from 'yup'

import EditIcon from '@material-ui/icons/Edit'

import {
  IOrderAllocationSubmissionPayload,
  TradingOrderEndpoints
} from '@d1g1t/api/endpoints'
import {
  METRIC_T_ACCOUNT_FIRM_PROVIDED_KEY,
  METRIC_T_ACCOUNT_PRINT_NAME,
  METRIC_T_AVERAGE_PRICE,
  METRIC_T_BROKER,
  METRIC_T_CLIENT_PRINT_NAME,
  METRIC_T_COMMISSION,
  METRIC_T_COMMISSION_TYPE,
  METRIC_T_EXPIRY_DATE,
  METRIC_T_EXPIRY_TYPE,
  METRIC_T_FX_RATE,
  METRIC_T_INSTRUMENT_NAME,
  METRIC_T_IS_ALL_OR_NONE,
  METRIC_T_IS_ANONYMOUS,
  METRIC_T_IS_ICEBERG,
  METRIC_T_IS_INSIDER,
  METRIC_T_IS_ODD_LOT,
  METRIC_T_IS_PRO,
  METRIC_T_LIMIT_PRICE,
  METRIC_T_MARKET,
  METRIC_T_NEGO_ID,
  METRIC_T_NOTES,
  METRIC_T_PROPOSED_ALLOCATION,
  METRIC_T_QTY,
  METRIC_T_QTY_FILLED,
  METRIC_T_QTY_TYPE,
  METRIC_T_QTY_UNALLOCATED,
  METRIC_T_SYMBOL,
  METRIC_T_TRADE_VALUE_GROSS,
  METRIC_T_TRADE_VALUE_NET,
  METRIC_T_TYPE,
  METRIC_T_UNALLOCATED_AVERAGE_PRICE,
  TRADEORDER_COMMISSION_TYPE,
  TRADEORDER_DISPLAY_STATUS,
  TRADEORDER_EXPIRY_TYPE,
  TRADEORDER_PRO_STATUS,
  TRADEORDER_QTY_TYPE,
  TRADEORDER_STATUS,
  TRADEORDER_TYPE
} from '@d1g1t/api/models'

import {MIME_TYPE} from '@d1g1t/lib/constants'
import {deepDiffObject} from '@d1g1t/lib/deep-diff-object'
import {useToggleState} from '@d1g1t/lib/hooks'
import {IMetricRequestSelection} from '@d1g1t/lib/metrics'
import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {Flex} from '@d1g1t/shared/components/flex'
import {FormActions} from '@d1g1t/shared/components/form-actions'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Modal, ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Alert} from '@d1g1t/shared/components/mui/alert'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {
  TableGrid,
  TableGridBody,
  TableGridHeader
} from '@d1g1t/shared/components/table-grid'
import {P} from '@d1g1t/shared/components/typography'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {
  IStandardTableCategory,
  StandardTable
} from '@d1g1t/shared/containers/standard-table'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'
import {ModalContentsErrorFallback} from '@d1g1t/shared/wrappers/error-boundary/modal-contents-fallback'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useStandardResponseQuery} from '@d1g1t/shared/wrappers/standard-response-query'

import {
  CheckedItemsActions,
  useSelectedIds
} from '@d1g1t/advisor/components/checked-items-actions'

import {EditTradeModal} from '../components/edit-trade-modal'
import {tradeOrderNameAdornment} from '../lib'

interface IReviewAggregatedTradeModalProps {
  /**
   * id of the aggregated trade order
   */
  tradeOrderId: string
  onClose(): void
  onSuccess(): void
}

export const ReviewAggregatedTradeModal: React.FC<
  IReviewAggregatedTradeModalProps
> = (props) => {
  const [
    aggregatedTradeModalCanBeClosed,
    toggleAggregatedTradeModalCanBeClosed
  ] = useState(true)

  return (
    <Modal
      open
      title='Review Aggregated Trade'
      onClose={aggregatedTradeModalCanBeClosed && props.onClose}
      fullscreen
    >
      <ErrorBoundary
        resetId={props.tradeOrderId}
        fallback={<ModalContentsErrorFallback onClose={props.onClose} />}
      >
        <ReviewAggregatedTradeModalContents
          {...props}
          onChangeAggregatedTradeModalStatus={
            toggleAggregatedTradeModalCanBeClosed
          }
        />
      </ErrorBoundary>
    </Modal>
  )
}

enum REVIEW_AGGREGATED_TRADE_FIELD_NAMES {
  qty = 'qty',
  isInsider = 'isInsider',
  notes = 'notes',
  market = 'market',
  type = 'type',
  expiryType = 'expiryType',
  expiryDate = 'expiryDate',
  qtyType = 'qtyType',
  limitPrice = 'limitPrice',
  isOddLot = 'isOddLot',
  isAllOrNone = 'isAllOrNone',
  isIceberg = 'isIceberg',
  proposedAllocation = 'proposedAllocation',
  isAnonymous = 'isAnonymous',
  proStatus = 'proStatus',
  broker = 'broker',
  commission = 'commission',
  commissionType = 'commissionType',
  securityToSettlementFxRate = 'securityToSettlementFxRate',
  negoId = 'negoId'
}

const CATEGORY_ID_BY_FIELD_NAME: Record<
  REVIEW_AGGREGATED_TRADE_FIELD_NAMES,
  string
> = {
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.qty]: METRIC_T_QTY.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.isInsider]: METRIC_T_IS_INSIDER.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.notes]: METRIC_T_NOTES.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.market]: METRIC_T_MARKET.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.type]: METRIC_T_TYPE.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.expiryType]: METRIC_T_EXPIRY_TYPE.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.expiryDate]: METRIC_T_EXPIRY_DATE.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.qtyType]: METRIC_T_QTY_TYPE.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.limitPrice]: METRIC_T_LIMIT_PRICE.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.isAllOrNone]:
    METRIC_T_IS_ALL_OR_NONE.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.isOddLot]: METRIC_T_IS_ODD_LOT.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.isIceberg]: METRIC_T_IS_ICEBERG.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.proposedAllocation]:
    METRIC_T_PROPOSED_ALLOCATION.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.isAnonymous]: METRIC_T_IS_ANONYMOUS.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.broker]: METRIC_T_BROKER.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.commission]: METRIC_T_COMMISSION.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.commissionType]:
    METRIC_T_COMMISSION_TYPE.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.securityToSettlementFxRate]:
    METRIC_T_FX_RATE.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.negoId]: METRIC_T_NEGO_ID.slug,
  [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.proStatus]: METRIC_T_IS_PRO.slug
}

const FIELD_NAME_BY_CATEGORY_ID = invert(CATEGORY_ID_BY_FIELD_NAME) as Record<
  string,
  REVIEW_AGGREGATED_TRADE_FIELD_NAMES
>

const VALUES_BY_ORDER_ID_KEY = 'byOrderId'

interface IReviewAggregatedTradeFormValues {
  [VALUES_BY_ORDER_ID_KEY]: {
    [id: string]: {
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.qty]?: number
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.isInsider]?: boolean
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.notes]?: string
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.market]?: string
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.type]?: TRADEORDER_TYPE
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.expiryType]?: TRADEORDER_EXPIRY_TYPE
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.expiryDate]?: string
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.qtyType]?: TRADEORDER_QTY_TYPE
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.limitPrice]?: number
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.isOddLot]?: boolean
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.isAllOrNone]?: boolean
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.isIceberg]?: boolean
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.proposedAllocation]?: number
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.isAnonymous]?: boolean
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.proStatus]?: TRADEORDER_PRO_STATUS
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.broker]?: string
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.commission]?: number
      [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.commissionType]?: TRADEORDER_COMMISSION_TYPE
    }
  }
}

const VALIDATION_SCHEMA = Yup.lazy(
  (values: IReviewAggregatedTradeFormValues): Yup.ObjectSchema =>
    Yup.object({
      [VALUES_BY_ORDER_ID_KEY]: Yup.object(
        mapValues(values[VALUES_BY_ORDER_ID_KEY], () =>
          Yup.object({
            [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.limitPrice]: Yup.number()
              .nullable()
              .label('Limit price')
              .when(
                REVIEW_AGGREGATED_TRADE_FIELD_NAMES.type,
                (type: TRADEORDER_TYPE, schema: Yup.NumberSchema) => {
                  if (type === TRADEORDER_TYPE.LIMIT) {
                    return schema.min(0).required()
                  }

                  return schema
                }
              ),
            [REVIEW_AGGREGATED_TRADE_FIELD_NAMES.expiryDate]: Yup.date()
              .nullable()
              .typeError('Invalid date')
              .label('Expiry Date')
              .when(
                REVIEW_AGGREGATED_TRADE_FIELD_NAMES.expiryType,
                (
                  expiryType: TRADEORDER_EXPIRY_TYPE,
                  schema: Yup.NumberSchema
                ) => {
                  if (expiryType === TRADEORDER_EXPIRY_TYPE.GTD) {
                    return schema.required()
                  }

                  return schema
                }
              )
          })
        )
      )
    })
)

const WORKING_STATES = [
  TRADEORDER_DISPLAY_STATUS.CLOSED,
  TRADEORDER_DISPLAY_STATUS.WORKING_CONFIRMED
]
interface IReviewAggregatedTradeModalContentsProps
  extends IReviewAggregatedTradeModalProps {
  /**
   * Escape button should not close all modals
   */
  onChangeAggregatedTradeModalStatus?: (
    aggregatedTradeModalOpen: boolean
  ) => void
}

const ReviewAggregatedTradeModalContents: React.FC<
  IReviewAggregatedTradeModalContentsProps
> = (props) => {
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()

  const [tradingOrder] = useApiQuery(
    TradingOrderEndpoints.findById(props.tradeOrderId)
  )

  const canEditAllocations = WORKING_STATES.includes(
    tradingOrder.data?.displayStatus
  )

  const selectedMetrics: IMetricRequestSelection[] = (() => {
    if (!tradingOrder.data) {
      return null
    }

    if (canEditAllocations) {
      return [
        METRIC_T_SYMBOL,
        METRIC_T_INSTRUMENT_NAME,
        METRIC_T_ACCOUNT_PRINT_NAME,
        METRIC_T_CLIENT_PRINT_NAME,
        METRIC_T_QTY,
        METRIC_T_QTY_FILLED,
        METRIC_T_AVERAGE_PRICE,
        METRIC_T_QTY_UNALLOCATED,
        METRIC_T_UNALLOCATED_AVERAGE_PRICE,
        METRIC_T_COMMISSION,
        METRIC_T_COMMISSION_TYPE,
        METRIC_T_TRADE_VALUE_GROSS,
        METRIC_T_TRADE_VALUE_NET,
        METRIC_T_BROKER,
        METRIC_T_NOTES,
        METRIC_T_FX_RATE,
        METRIC_T_NEGO_ID
      ]
    }

    switch (tradingOrder.data.status) {
      case TRADEORDER_STATUS.OPEN:
      case TRADEORDER_STATUS.PENDING_APPROVAL:
        return [
          METRIC_T_INSTRUMENT_NAME,
          METRIC_T_ACCOUNT_FIRM_PROVIDED_KEY,
          METRIC_T_ACCOUNT_PRINT_NAME,
          METRIC_T_CLIENT_PRINT_NAME,
          METRIC_T_TYPE,
          METRIC_T_QTY_TYPE,
          METRIC_T_QTY,
          METRIC_T_MARKET,
          METRIC_T_LIMIT_PRICE,
          METRIC_T_EXPIRY_DATE,
          METRIC_T_EXPIRY_TYPE,
          METRIC_T_IS_ALL_OR_NONE,
          METRIC_T_IS_ICEBERG,
          METRIC_T_IS_ODD_LOT,
          METRIC_T_IS_INSIDER,
          METRIC_T_IS_ANONYMOUS,
          METRIC_T_IS_PRO,
          METRIC_T_BROKER,
          METRIC_T_COMMISSION,
          METRIC_T_COMMISSION_TYPE,
          METRIC_T_NOTES,
          METRIC_T_FX_RATE,
          METRIC_T_NEGO_ID
        ]
      default:
        return [
          METRIC_T_SYMBOL,
          METRIC_T_INSTRUMENT_NAME,
          METRIC_T_ACCOUNT_FIRM_PROVIDED_KEY,
          METRIC_T_ACCOUNT_PRINT_NAME,
          METRIC_T_CLIENT_PRINT_NAME,
          METRIC_T_TYPE,
          METRIC_T_QTY_TYPE,
          METRIC_T_QTY,
          METRIC_T_QTY_FILLED,
          METRIC_T_AVERAGE_PRICE,
          METRIC_T_COMMISSION,
          METRIC_T_COMMISSION_TYPE,
          METRIC_T_TRADE_VALUE_GROSS,
          METRIC_T_TRADE_VALUE_NET,
          METRIC_T_BROKER,
          METRIC_T_NOTES,
          METRIC_T_FX_RATE,
          METRIC_T_NEGO_ID
        ]
    }
  })()

  const [chart, chartQueryActions] = useStandardResponseQuery(
    tradingOrder.data &&
      TradingOrderEndpoints.aggregateChart(props.tradeOrderId, {
        metrics: {
          selected: selectedMetrics
        }
      })
  )

  /**
   * Arrange or hide 'Proposed Allocation' column (which is always returned as the first category)
   *
   * This is used to determine initial form values.
   */
  const chartWithProposedAllocation = useMemo(() => {
    if (!chart.data) {
      return null
    }

    if (chart.data.findCategoryById(METRIC_T_PROPOSED_ALLOCATION.slug)) {
      if (canEditAllocations) {
        // insert proposed allocation category

        const orderedCategoryIds = selectedMetrics.map(({slug}) => slug)

        orderedCategoryIds.splice(
          orderedCategoryIds.indexOf(METRIC_T_COMMISSION.slug),
          0,
          METRIC_T_PROPOSED_ALLOCATION.slug
        )

        return chart.data.reorderVisibleCategories(orderedCategoryIds)
      }

      // else, hide proposed allocation category
      return chart.data.filterCategories(
        ({id}) => id !== METRIC_T_PROPOSED_ALLOCATION.slug
      )
    }

    return chart.data
  }, [chart.data, canEditAllocations])

  const initialValues = useMemo((): IReviewAggregatedTradeFormValues => {
    if (!chartWithProposedAllocation) {
      return null
    }

    return {
      byOrderId: chartWithProposedAllocation.items.reduce<
        IReviewAggregatedTradeFormValues[typeof VALUES_BY_ORDER_ID_KEY]
      >((prev, item) => {
        prev[item.id] = {}

        for (const fieldName of Object.values(
          REVIEW_AGGREGATED_TRADE_FIELD_NAMES
        )) {
          const categoryId = CATEGORY_ID_BY_FIELD_NAME[fieldName]
          // only add editable fields to form state
          if (item.isEditable(categoryId)) {
            ;(prev[item.id][fieldName] as any) =
              item.getKey(categoryId) ?? item.getValue(categoryId)
          }
        }

        return prev
      }, {})
    }
  }, [chartWithProposedAllocation])

  const [handleSave] = useApiMutation({
    mutation: (formValues: IReviewAggregatedTradeFormValues) => (api) => {
      const changedValues = canEditAllocations
        ? formValues
        : deepDiffObject(initialValues, formValues)

      return api.request(
        TradingOrderEndpoints.bulkUpdate(
          Object.entries(changedValues[VALUES_BY_ORDER_ID_KEY] || {}).map(
            ([entityId, values]) => ({...values, entityId})
          )
        )
      )
    },
    onSuccess: () => {
      showSnackbar({
        variant: 'success',
        message: 'The aggregated trade has been saved.'
      })
      props.onSuccess()
      props.onClose()
    },

    onError: (error) => {
      if (error instanceof ApiError && error.status === 400) {
        showSnackbar({
          variant: 'error',
          message: Object.values(error.responseBody)[0]
        })
      } else {
        handleUnexpectedError(
          error,
          'An unexpected error occurred while saving.'
        )
      }
    }
  })

  const [handleSubmit] = useApiMutation({
    mutation: (formValues: IReviewAggregatedTradeFormValues) => async (api) => {
      const changedValues = canEditAllocations
        ? formValues
        : deepDiffObject(initialValues, formValues)

      if (canEditAllocations) {
        const allocations = await api.request(
          TradingOrderEndpoints.submitAllocations(
            Object.entries(changedValues[VALUES_BY_ORDER_ID_KEY])
              // only update the child orders
              .filter(([orderId]) => orderId !== props.tradeOrderId)
              .map<IOrderAllocationSubmissionPayload>(([orderId, values]) => {
                return {
                  tradeOrder: orderId,
                  qtyFilled:
                    values[
                      REVIEW_AGGREGATED_TRADE_FIELD_NAMES.proposedAllocation
                    ]
                }
              })
          )
        )
        FileSaver.saveAs(
          new Blob([allocations[0].data], {type: MIME_TYPE.csv}),
          'Allocations.csv'
        )
        return allocations
      }
    },
    onSuccess: () => {
      showSnackbar({
        variant: 'success',
        message: 'The aggregated trade has been submitted.'
      })
      props.onSuccess()
      props.onClose()
    },

    onError: (error) => {
      if (error instanceof ApiError && error.status === 400) {
        showSnackbar({
          variant: 'error',
          message: Object.values(error.responseBody)[0]
        })
      } else {
        handleUnexpectedError(
          error,
          'An unexpected error occurred while submitting allocations.'
        )
      }
    }
  })

  const formik = useFormik<IReviewAggregatedTradeFormValues>({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  })

  // Clear limit price when trade order type changes to market
  const selectedTradeOrderType =
    formik.values?.[VALUES_BY_ORDER_ID_KEY][props.tradeOrderId].type
  useEffect(() => {
    if (
      get(formik.touched, [
        VALUES_BY_ORDER_ID_KEY,
        props.tradeOrderId,
        REVIEW_AGGREGATED_TRADE_FIELD_NAMES.type
      ]) &&
      selectedTradeOrderType !== TRADEORDER_TYPE.LIMIT
    ) {
      formik.setFieldValue(
        `${VALUES_BY_ORDER_ID_KEY}.${props.tradeOrderId}.${REVIEW_AGGREGATED_TRADE_FIELD_NAMES.limitPrice}`,
        null
      )
    }
  }, [selectedTradeOrderType])

  // Clear expiry date if trade order expiry type changes
  const selectedExpiryType =
    formik.values?.[VALUES_BY_ORDER_ID_KEY][props.tradeOrderId].expiryType
  useEffect(() => {
    if (
      get(formik.touched, [
        VALUES_BY_ORDER_ID_KEY,
        props.tradeOrderId,
        REVIEW_AGGREGATED_TRADE_FIELD_NAMES.expiryType
      ]) &&
      selectedExpiryType !== TRADEORDER_EXPIRY_TYPE.GTD
    ) {
      formik.setFieldValue(
        `${VALUES_BY_ORDER_ID_KEY}.${props.tradeOrderId}.${REVIEW_AGGREGATED_TRADE_FIELD_NAMES.expiryDate}`,
        null
      )
    }
  }, [selectedExpiryType])

  /**
   * Modify chart:
   * - Replace values with form state values
   * - Hide categories if the trade order/expiry type doesn't support them
   * - Update the total parent QTY to equal the sum of its children
   */
  const chartWithValues = useMemo(() => {
    if (!chartWithProposedAllocation || !formik.values) {
      return null
    }

    return produce(
      chartWithProposedAllocation,
      (draftChart: StandardResponse) => {
        const categoriesToFilter = []

        if (
          formik.values[VALUES_BY_ORDER_ID_KEY][props.tradeOrderId].type !==
          TRADEORDER_TYPE.LIMIT
        ) {
          categoriesToFilter.push(METRIC_T_LIMIT_PRICE.slug)
        }

        if (
          formik.values[VALUES_BY_ORDER_ID_KEY][props.tradeOrderId]
            .expiryType !== TRADEORDER_EXPIRY_TYPE.GTD
        ) {
          categoriesToFilter.push(METRIC_T_EXPIRY_DATE.slug)
        }

        if (categoriesToFilter.length) {
          draftChart.applyCategoryFilter(
            (category) => !categoriesToFilter.includes(category.id)
          )
        }

        for (const item of draftChart) {
          const values = formik.values[VALUES_BY_ORDER_ID_KEY][item.id]
          for (const [fieldName, value] of Object.entries(values)) {
            const categoryId = CATEGORY_ID_BY_FIELD_NAME[fieldName]
            const selectValue = item
              .getAllowedValues(categoryId)
              ?.find((allowedValue) => allowedValue.key === value)

            const data = item.getDatum(categoryId)
            data.value = selectValue ? selectValue.value : value

            if (selectValue?.key !== undefined) {
              data.key = selectValue?.key
            }
          }
        }

        const getTotalValue = (categoryId: string) =>
          draftChart.items.reduce(
            (prev, item) =>
              item.id === props.tradeOrderId
                ? prev
                : prev + item.getValue(categoryId) || 0,
            0
          )

        // set parent order's QTY to sum of children
        if (draftChart.findCategoryById(METRIC_T_QTY.slug)) {
          draftChart.setValue(
            METRIC_T_QTY.slug,
            props.tradeOrderId,
            getTotalValue(METRIC_T_QTY.slug)
          )
        }

        // set parent order's proposed qty to match children
        if (draftChart.findCategoryById(METRIC_T_PROPOSED_ALLOCATION.slug)) {
          draftChart.setValue(
            METRIC_T_PROPOSED_ALLOCATION.slug,
            props.tradeOrderId,
            getTotalValue(METRIC_T_PROPOSED_ALLOCATION.slug)
          )
        }
      }
    )
  }, [chartWithProposedAllocation, formik.values?.[VALUES_BY_ORDER_ID_KEY]])

  // chartWithProposedAllocation.items[1] is a first child item, all items have
  // same categories, we need to know which ones are editable
  const editableCategories = chartWithProposedAllocation?.items[1].item.data
    .filter((item) => item.options?.editable === true)
    .map((item) => item.categoryId)

  const sumProposedAllocations = useMemo((): number => {
    if (!chartWithValues) {
      return null
    }

    return chartWithValues
      .findItemById(props.tradeOrderId)
      .getValue(METRIC_T_PROPOSED_ALLOCATION.slug)
  }, [chartWithValues])

  const parentQuantityUnallocated = useMemo(() => {
    if (!chartWithValues) {
      return null
    }

    return chartWithValues
      .findItemById(props.tradeOrderId)
      .getValue(METRIC_T_QTY_UNALLOCATED.slug)
  }, [chartWithValues])

  const proposedQuantitiesTooLarge =
    sumProposedAllocations > parentQuantityUnallocated

  const handleCellValueChange = (
    item: StandardResponseItem,
    category: IStandardTableCategory,
    value: any,
    key: any
  ) => {
    const fieldName = FIELD_NAME_BY_CATEGORY_ID[category.id]

    if (fieldName) {
      formik.setFieldValue(
        `${VALUES_BY_ORDER_ID_KEY}.${item.id}.${fieldName}`,
        key ?? value
      )
      formik.setFieldTouched(
        `${VALUES_BY_ORDER_ID_KEY}.${item.id}.${fieldName}`
      )
    } else {
      throw new Error(
        `No field name mapping defined for editable cell with category ${category.id}`
      )
    }
  }
  // only child trade can be selected for bulk editting
  const childTradesChart = useMemo(() => {
    if (!chartWithValues) {
      return null
    }

    return chartWithValues.filter((item) => item.parentPath !== 'root', {
      keepFamily: false
    })
  }, [chartWithValues])

  // List of ids of checked items in the review aggregated orders table
  const [selectedIds, setSelectedIds] = useSelectedIds(childTradesChart)

  const [
    bulkAggregatedTradeModalOpen,
    ,
    openAggregatedTradeBulkModal,
    closeAggregatedTradeBulkModal
  ] = useToggleState(false)

  const handleBulkAggregatedTradeModalOpen = () => {
    openAggregatedTradeBulkModal()
    props.onChangeAggregatedTradeModalStatus(bulkAggregatedTradeModalOpen)
  }
  const handleBulkAggregatedTradeModalClosed = () => {
    closeAggregatedTradeBulkModal()
    props.onChangeAggregatedTradeModalStatus(bulkAggregatedTradeModalOpen)
  }

  return (
    <LoadingContainer
      loading={[tradingOrder, chart].some((query) => query.loading)}
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} style={{display: 'contents'}}>
          <ControlStateProvider loading={formik.isSubmitting}>
            <ModalContent>
              {chartWithValues && (
                <TableGrid>
                  <TableGridHeader>
                    <Flex alignCenter>
                      {selectedIds.length > 1 && (
                        <CheckedItemsActions
                          disabled={formik.isSubmitting}
                          count={selectedIds.length}
                          actions={[
                            {
                              label: 'Bulk Edit',
                              icon: <EditIcon />,
                              onClick: handleBulkAggregatedTradeModalOpen
                            }
                          ]}
                          buttonTestIdPrefix='button-aggregated-trade-'
                        />
                      )}
                    </Flex>
                    <Flex alignCenter>
                      {canEditAllocations && (
                        <>
                          <P>
                            {sumProposedAllocations} out of{' '}
                            {parentQuantityUnallocated} units have been
                            allocated.{' '}
                            {parentQuantityUnallocated -
                              sumProposedAllocations >=
                            0
                              ? `${
                                  parentQuantityUnallocated -
                                  sumProposedAllocations
                                } still need to be allocated.`
                              : `${
                                  sumProposedAllocations -
                                  parentQuantityUnallocated
                                } units are over alloced.`}
                          </P>
                          {proposedQuantitiesTooLarge && (
                            <Alert severity='error'>
                              The proposed allocations exceed the parent trade's
                              '{METRIC_T_QTY_UNALLOCATED.columnTitle}' of{' '}
                              {parentQuantityUnallocated}.
                            </Alert>
                          )}
                        </>
                      )}
                      <Spacer vertical xxs />
                    </Flex>
                  </TableGridHeader>
                  <TableGridBody>
                    <StandardTable
                      table={chartWithValues}
                      nameAdornment={tradeOrderNameAdornment(
                        'This trade is aggregated from the below trades.'
                      )}
                      onCellValueChange={handleCellValueChange}
                      getCellError={({item, category}) =>
                        get(formik.errors, [
                          VALUES_BY_ORDER_ID_KEY,
                          item.id,
                          FIELD_NAME_BY_CATEGORY_ID[category.id]
                        ])
                      }
                      maxHeight={400}
                      checkedRows={selectedIds}
                      onCheckboxedRows={setSelectedIds}
                    />
                  </TableGridBody>
                  {bulkAggregatedTradeModalOpen && (
                    <EditTradeModal
                      isAggregated
                      manageOrdersTable={chartWithValues}
                      onClose={() => {
                        handleBulkAggregatedTradeModalClosed()
                        setSelectedIds([])
                      }}
                      selectedItemIds={selectedIds}
                      onUpdate={() => {
                        chartQueryActions.refetch()
                      }}
                      editableCategories={editableCategories}
                    />
                  )}
                </TableGrid>
              )}
            </ModalContent>
            <ModalActions>
              <FormActions
                formType='edit'
                enableSubmitWhenPristine={canEditAllocations}
                disableSubmit={
                  canEditAllocations &&
                  (proposedQuantitiesTooLarge || !parentQuantityUnallocated)
                }
                onCancel={props.onClose}
                submitButtonText={{
                  default: 'Submit Allocations',
                  submitting: 'Submiting...'
                }}
                showFormErrorCount
              >
                <>
                  <Button
                    primary
                    contained
                    disabled={
                      canEditAllocations &&
                      (proposedQuantitiesTooLarge || !parentQuantityUnallocated)
                    }
                    onClick={() => {
                      handleSave(formik.values)
                    }}
                  >
                    Save
                  </Button>
                  <Spacer xs vertical />
                </>
              </FormActions>
            </ModalActions>
          </ControlStateProvider>
        </form>
      </FormikProvider>
    </LoadingContainer>
  )
}
