import React, {useContext} from 'react'

import {isNil} from 'lodash'

import TimeLine from '@material-ui/icons/Timeline'

import {ConfigContext} from '@d1g1t/config/context'

import {
  CHART_VALUE_TYPE,
  IChartTableData,
  IChartTableOptions
} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {NumberFormatter} from '@d1g1t/lib/formatters/number-formatter'

import {RouterWrappedLink} from '@d1g1t/shared/components/router-link'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {KNOWN_CATEGORY_IDS} from '@d1g1t/shared/containers/standard-table/constants'
import {StandardTableItem} from '@d1g1t/shared/containers/standard-table/typings'
import {IAdditionalChartValueFormatterOptions} from '@d1g1t/shared/wrappers/formatter'

import {EditableCell} from '../../custom-types/editable-cell'
import {isCurrentSingleCellEditActive, itemDetails} from '../lib'
import {IDataCellProps} from '../typings'
import {IDataCellValueProps} from './typings'

import * as commonCss from '@d1g1t/shared/containers/standard-table/styles.scss'
import * as css from '../styles.scss'

export const DataCellValue: React.FC<IDataCellValueProps> = (props) => {
  if (!props.category || !props.item) {
    return null
  }

  const currentSingleCellEditActive = isCurrentSingleCellEditActive(
    {categoryId: props.category.id, itemId: props.item.id},
    props.activeEditingCellLocation
  )

  if (props.component) {
    return <props.component {...props} isCompactMode={props.isCompactMode} />
  }

  if (
    props.category.id === KNOWN_CATEGORY_IDS.NAME &&
    !currentSingleCellEditActive
  ) {
    return (
      <NameCell
        item={props.item}
        benchmarkSubrow={props.benchmarkSubrow}
        linkOverride={props.linkOverride}
        isCompactMode={props.isCompactMode}
      />
    )
  }

  const data = props.item?.getDatum(props.category.id)

  if (!data) {
    return <div className={commonCss.innerCell} />
  }

  if (
    props.item.isEditable(props.category.id) &&
    (!props.setActiveEditingCellLocation || currentSingleCellEditActive) &&
    !props.totalRow && // Total row should be never editable
    [
      CHART_VALUE_TYPE.PERCENTAGE,
      CHART_VALUE_TYPE.DECIMAL,
      CHART_VALUE_TYPE.INTEGER,
      CHART_VALUE_TYPE.DECIMAL_LONG,
      CHART_VALUE_TYPE.DECIMAL_LONG_LONG,
      CHART_VALUE_TYPE.MONEY_ABBREVIATED,
      CHART_VALUE_TYPE.DATE,
      CHART_VALUE_TYPE.INTEGER,
      CHART_VALUE_TYPE.STRING,
      CHART_VALUE_TYPE.BOOLEAN
    ].includes(props.category.valueType as CHART_VALUE_TYPE)
  ) {
    return (
      <EditableCell
        textAlignRight={props.category.valueType !== CHART_VALUE_TYPE.STRING}
        {...props}
      />
    )
  }

  const renderDataCellValue = (data: IChartTableData) => {
    const valueType = data.valueType || props.category.valueType
    const valueOptions = {...props.category.options, ...data.options}

    const value = (() => {
      if (!props.getCellValue) {
        return data.value
      }

      const result = props.getCellValue({
        data,
        item: props.item,
        category: props.category
      })

      // Explicitly checking for `undefined` before defaulting back to normal value
      if (result === undefined) {
        return data.value
      }

      return result
    })()

    if (isNil(value) && valueOptions && valueOptions.status) {
      return null
    }

    if (props.benchmarkSubrow && !value) {
      /**
       * Explictly checking for empty benchmark cells. Normally this is
       * handled by StandardTable, and a filler cell is rendered but
       * this has to be done here because we render benchmark cells
       * within a cell rendered by Standard Table component.
       */

      return (
        <div
          className={classNames(commonCss.innerCell, css.subrowCell, {
            [commonCss.compactModeInnerCell]: props.isCompactMode
          })}
        >
          &nbsp;
        </div>
      )
    }

    return (
      <DataCellValueFormatted
        value={value}
        valueType={valueType}
        valueOptions={valueOptions}
        benchmarkSubrow={props.benchmarkSubrow}
        additionalOptions={{
          allowZero:
            props.allowZeroForCell && props.allowZeroForCell(props.item),
          currencyDisplay: 'code'
        }}
        getFormatter={props.getFormatter}
        isCompactMode={props.isCompactMode}
        isFirstVisibleDataColumn={props.isFirstVisibleDataColumn}
      />
    )
  }

  if (Array.isArray(data.value)) {
    return (
      <>
        {data.value.map((dataValueItem: IChartTableData, i) => (
          <React.Fragment key={i}>
            {renderDataCellValue(dataValueItem)}
          </React.Fragment>
        ))}
      </>
    )
  }

  return renderDataCellValue(data)
}

interface INameCellProps
  extends Pick<
    IDataCellProps,
    'benchmarkSubrow' | 'linkOverride' | 'isCompactMode'
  > {
  item: StandardTableItem
}

const NameCell: React.FC<INameCellProps> = (props) => {
  const config = useContext(ConfigContext)
  const isInvestorApp = !config.isAdvisorApp

  const {name, entityId, clientId, model, parentModel, parentEntityId} =
    itemDetails(props.item)

  // link to parent model if present.
  // at the time of writing, parent model only exists
  // for a position linked to a class series fund (so we can link to it)
  const [linkModel, linkEntityId] =
    parentModel && parentEntityId
      ? [parentModel, parentEntityId]
      : [model, entityId]

  let link: string = null

  /**
   * The "Total" item should not have a link
   */
  const shouldDisplayLink = !props.item.isTotalItem

  if (shouldDisplayLink && linkModel) {
    try {
      if (props.linkOverride) {
        link = props.linkOverride(props.item)
      } else {
        link = config.entityPath(linkEntityId, linkModel)
      }

      if (!link) {
        link = clientId
          ? config.modelPath(linkModel, clientId, linkEntityId)
          : config.modelPath(linkModel, linkEntityId)
      }
    } catch {
      link = null
    }
  }

  if (link) {
    return (
      <CellLink
        to={link}
        label={name}
        benchmarkSubrow={props.benchmarkSubrow}
        isInvestorApp={isInvestorApp}
        isCompactMode={props.isCompactMode}
      />
    )
  }

  return (
    <div
      className={classNames(commonCss.innerCell, {
        [css.subrowCell]: props.benchmarkSubrow,
        [commonCss.compactModeInnerCell]: props.isCompactMode,
        [commonCss.investorCell]: isInvestorApp
      })}
    >
      {props.benchmarkSubrow && <BenchmarksIcon />}
      {name}
    </div>
  )
}

interface ICellLinkProps extends Pick<IDataCellProps, 'benchmarkSubrow'> {
  to: string
  label: React.ReactNode
  /**
   * If `true` will use compact mode style for cell links. Currently used for standard table compact mode.
   */
  isCompactMode: boolean
  isInvestorApp: boolean
}

const CellLink: React.FC<ICellLinkProps> = React.memo((props) => (
  <RouterWrappedLink
    className={classNames(css.link, {
      [css.subrowCell]: props.benchmarkSubrow,
      [css.compactModeLink]: props.isCompactMode,
      [css.investorCell]: props.isInvestorApp
    })}
    to={props.to}
  >
    {props.benchmarkSubrow && <BenchmarksIcon />}
    {props.label}
  </RouterWrappedLink>
))

interface IDataCellValueFormatted
  extends Pick<
      IDataCellProps,
      'getFormatter' | 'benchmarkSubrow' | 'isCompactMode'
    >,
    Pick<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  value: StrNum
  valueType: string
  valueOptions?: IChartTableOptions
  additionalOptions?: IAdditionalChartValueFormatterOptions
  /**
   * Sometimes first non hidden data category is not the name column
   * (e.g. ID column)
   * This boolean is passed to avoid right aligning the first column even if
   * it is a number.
   */
  isFirstVisibleDataColumn?: boolean
}

export const DataCellValueFormatted: React.FC<IDataCellValueFormatted> = ({
  getFormatter,
  value,
  valueType,
  valueOptions,
  additionalOptions,
  benchmarkSubrow,
  className,
  isCompactMode,
  isFirstVisibleDataColumn
}) => {
  const config = useContext(ConfigContext)
  const isInvestorApp = !config.isAdvisorApp

  const formatter = getFormatter({
    valueType,
    valueOptions,
    additionalOptions
  })

  return (
    <div
      className={classNames(
        commonCss.innerCell,
        {
          [css.number]:
            !isFirstVisibleDataColumn && formatter instanceof NumberFormatter,
          [css.subrowCell]: benchmarkSubrow,
          [commonCss.compactModeInnerCell]: isCompactMode,
          [commonCss.investorCell]: isInvestorApp
        },
        className
      )}
    >
      {formatter.formatJSX(value)}
    </div>
  )
}

const BenchmarksIcon: React.FC = () => (
  <>
    <Spacer vertical custom={12} />
    <TimeLine fontSize='small' className={css.subrowCellIcon} />
    <Spacer vertical custom={12} />
  </>
)
