import React, {useEffect, useMemo, useRef} from 'react'

import {useApi, useApiQuery} from 'fairlight'
import * as Yup from 'yup'

import {
  InstrumentEndpoints,
  IRebalancingDraftResponse,
  PortfolioRebalanceEndpoints,
  RebalancingRuleEndpoints
} from '@d1g1t/api/endpoints'
import {
  ALL_MODELS,
  BULK_TRADING_ACTIONS,
  BULK_TRADING_ACTIONS_OPTIONS,
  BULKUPDATEREQUEST_DESIRED_ACTION,
  COMMISSION_TYPE,
  COMMISSION_TYPE_OPTIONS,
  IInstrument,
  IRebalancingRule,
  PORTFOLIO_TYPE
} from '@d1g1t/api/models'

import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ComboKeyListener} from '@d1g1t/shared/components/combo-key-listener'
import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {Flex} from '@d1g1t/shared/components/flex'
import {LoaderDisplay} from '@d1g1t/shared/components/loader-display'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {Text} from '@d1g1t/shared/components/typography'
import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'
import {
  ISearchResult,
  Search,
  SearchWrapper
} from '@d1g1t/shared/containers/search'
import {SecuritySearchFilter} from '@d1g1t/shared/containers/security-search-filter'
import {
  IStandardTableCategory,
  StandardTable,
  StandardTableComponent
} from '@d1g1t/shared/containers/standard-table'
import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {useGlobalSettings} from '@d1g1t/shared/wrappers/global-settings'
import {useStandardResponseQuery} from '@d1g1t/shared/wrappers/standard-response-query'

import {RebalanceRules} from '@d1g1t/advisor/containers/rebalance-rules'
import {SecuritySearchOption} from '@d1g1t/advisor/containers/security-search-option'
import {SecuritySearchOptionHeader} from '@d1g1t/advisor/containers/security-search-option/header'
import {CATEGORY_IDS} from '@d1g1t/advisor/containers/trade-preview'

import {
  IBulkRebalanceParams,
  IDirectiveInputs,
  IDirectiveInputsBySecurityEntityId,
  IInitialMarketPrices,
  ILoadedSecurity,
  ITradeDirectivePageSelectedClientsProps
} from '../../typings'

import css from './styles.scss'

export const TARGET_ALLOCATION_TABLE_ID = 'target-allocation-table'

enum TARGET_ALLOCATION_FIELD_NAMES {
  desiredAction = 'desiredAction',
  rebalanceRule = 'rebalanceRule'
}

interface ITargetAllocationFormValues {
  [TARGET_ALLOCATION_FIELD_NAMES.desiredAction]: BULK_TRADING_ACTIONS
  [TARGET_ALLOCATION_FIELD_NAMES.rebalanceRule]: string
}

export interface ITargetAllocationProps
  extends Pick<
    ITradeDirectivePageSelectedClientsProps,
    'setModelPortfolioSelected'
  > {
  onInitializeTradeParameters(params: IBulkRebalanceParams): void
  onRecommendTrades(params: IBulkRebalanceParams): void
  /**
   * Called when an instrument is selected in the search
   * @param searchResult - searchResult
   */
  onAddSecurity(searchResult: ISearchResult): void
  clearSecurities(): void
  bulkRebalanceParams: IBulkRebalanceParams
  /**
   * List of selected securities, used to call `/directive` chart endpoint
   * to create a basic table
   */
  securities: ILoadedSecurity[]
  checkedSecurityUrls: string[]
  directiveInputsBySecurityEntityId: IDirectiveInputsBySecurityEntityId
  loadingSecuritiesForModelPortfolio: boolean
  selectedModelPortfolio: boolean
  loadSecuritiesForPortfolioRequest(searchResult: ISearchResult): void
  loadSecuritiesForPortfolioSuccess(payload: {
    instruments: IInstrument[]
    draft: IRebalancingDraftResponse
  }): void
  loadSecuritiesForPortfolioFailure(): void
  setCheckedSecurityUrls(securityIds: string[]): void
  setInitialMarketPrices(initialMarketPrices: IInitialMarketPrices): void
  setDirectiveInputValue(value: {
    item: StandardResponseItem
    category: IStandardTableCategory
    value: any
    key: any
  }): void
}

const BULK_TRADING_ACTIONS_TO_DESIRED_ACTIONS: Record<
  BULK_TRADING_ACTIONS,
  BULKUPDATEREQUEST_DESIRED_ACTION
> = {
  [BULK_TRADING_ACTIONS.BUY]: BULKUPDATEREQUEST_DESIRED_ACTION.BUY,
  [BULK_TRADING_ACTIONS.SELL]: BULKUPDATEREQUEST_DESIRED_ACTION.SELL,
  [BULK_TRADING_ACTIONS.BUY_SELL]: BULKUPDATEREQUEST_DESIRED_ACTION.BUY_SELL
}

const DIRECTIVE_INPUTS_VALIDATION_SCHEMA = Yup.object<IDirectiveInputs>({
  lower_bound: Yup.number()
    .min(0)
    .max(1)
    .nullable()
    .required()
    .label('Lower bound'),
  target_weight: Yup.number()
    .test(
      'in-bounds',
      'Must be between lower and upper bounds',
      function validateTargetWeight(value) {
        const {upper_bound, lower_bound} = this.parent
        return value <= upper_bound && value >= lower_bound
      }
    )
    .nullable()
    .label('Target Weight'),
  commission: Yup.number().min(0).required().label('Commission'),
  upper_bound: Yup.number()
    .min(0)
    .max(1)
    .nullable()
    .required()
    .label('Lower bound'),
  market_price: Yup.number().typeError('Must be a number').label('Market price')
})

/**
 * Because previous trade parameters are saved to global settings,
 * we need to ensure that they are still valid (ie. the rebalance rule
 * hasn't been deleted and the desired action is still a valid choice)
 */
function getValidTradeParameters(
  initialTradeParameters: ITargetAllocationFormValues,
  rebalancingRules: IRebalancingRule[]
): ITargetAllocationFormValues {
  return {
    rebalanceRule:
      initialTradeParameters.rebalanceRule &&
      rebalancingRules.some(
        (rebalancingRule) =>
          rebalancingRule.url === initialTradeParameters.rebalanceRule
      )
        ? initialTradeParameters.rebalanceRule
        : rebalancingRules[0]?.url || null,
    desiredAction:
      initialTradeParameters.desiredAction &&
      Object.values(BULK_TRADING_ACTIONS).some(
        (action) => action === initialTradeParameters.desiredAction
      )
        ? (initialTradeParameters.desiredAction as BULK_TRADING_ACTIONS)
        : BULK_TRADING_ACTIONS.BUY_SELL
  }
}

export const TargetAllocation: React.FC<ITargetAllocationProps> = React.memo(
  (props) => {
    const api = useApi()
    const {handleUnexpectedError} = useErrorHandler()
    const tableRef = useRef<StandardTableComponent>(null)

    const {firmConfiguration} = useFirmConfiguration()
    const [calculationSettings] = useCalculationSettings()
    const [rebalancingRules] = useApiQuery(RebalancingRuleEndpoints.list(), {
      fetchPolicy: 'cache-and-fetch'
    })

    const [tradeParameters, {updateGlobalSettings: setTradeParameters}] =
      useGlobalSettings<ITargetAllocationFormValues>('TARGET_ALLOCATION', {
        desiredAction: '' as BULK_TRADING_ACTIONS,
        rebalanceRule: ''
      } as ITargetAllocationFormValues)

    useEffect(() => {
      if (
        props.bulkRebalanceParams ||
        !rebalancingRules.data ||
        !tradeParameters
      ) {
        return
      }

      // initialize trade parameters once initial rebalancing rule loads
      const validTradeParameters = getValidTradeParameters(
        tradeParameters,
        rebalancingRules.data.results
      )

      setTradeParameters(validTradeParameters)

      props.onInitializeTradeParameters({
        asOfDate: calculationSettings.date?.date,
        rebalanceRule: validTradeParameters.rebalanceRule,
        desiredAction:
          BULK_TRADING_ACTIONS_TO_DESIRED_ACTIONS[
            validTradeParameters.desiredAction
          ],
        instruments: []
      })
    }, [rebalancingRules.data, tradeParameters])

    const handleSelectModelPortfolio = async (searchResult: ISearchResult) => {
      props.loadSecuritiesForPortfolioRequest(searchResult)
      try {
        const [draft, {results: instrumentsForModelPortfolio}] =
          await Promise.all([
            api.request(
              PortfolioRebalanceEndpoints.draft({
                portfolioId: searchResult.entityId
              })
            ),
            api.request(
              InstrumentEndpoints.list({
                portfolio: searchResult.entityId,
                holding_as_of_date: firmConfiguration.data.latestDataAvailable
              })
            )
          ])

        props.setModelPortfolioSelected(searchResult.url)
        props.loadSecuritiesForPortfolioSuccess({
          draft,
          instruments: instrumentsForModelPortfolio
        })
      } catch (error) {
        handleUnexpectedError(
          error,
          'An unknown error occurred setting model portfolio'
        )
        props.loadSecuritiesForPortfolioFailure()
      }
    }

    const directiveErrorsBySecurityEntityId: {
      [entityId: string]: {
        [entityId in keyof IDirectiveInputs]?: string
      }
    } = useMemo(() => {
      const errorsBySecurityEntityId = {}

      for (const [entityId, directiveInputs] of Object.entries(
        props.directiveInputsBySecurityEntityId
      )) {
        const errors = {}

        try {
          DIRECTIVE_INPUTS_VALIDATION_SCHEMA.validateSync(directiveInputs, {
            abortEarly: false
          })
        } catch (error) {
          if (!(error instanceof Yup.ValidationError)) {
            throw error
          }

          for (const fieldError of error.inner) {
            errors[fieldError.path] = fieldError.message
          }

          errorsBySecurityEntityId[entityId] = errors
        }
      }

      return errorsBySecurityEntityId
    }, [props.directiveInputsBySecurityEntityId])

    const [bulkDirectiveChart] = useStandardResponseQuery(
      PortfolioRebalanceEndpoints.bulkDirectiveChart(
        props.securities.map((security) => ({
          instrument: security.url
        }))
      ),
      {dontReinitialize: true, useErrorBoundary: false}
    )

    const handleTradeParameterChange = (
      value,
      child,
      e: React.ChangeEvent<HTMLSelectElement>
    ) => {
      setTradeParameters({
        [e.target.name]: e.target.value
      })
    }

    const handleRecommendTrades = () => {
      if (
        Object.keys(directiveErrorsBySecurityEntityId).length > 0 ||
        props.securities.length === 0
      ) {
        return
      }

      props.onRecommendTrades({
        asOfDate: calculationSettings.date?.date,
        desiredAction:
          BULK_TRADING_ACTIONS_TO_DESIRED_ACTIONS[
            tradeParameters.desiredAction
          ],
        rebalanceRule: tradeParameters.rebalanceRule || null,
        instruments: props.securities.map((security) => {
          // make sure that directiveInputsBySecurityEntityId has markerPrice
          // directiveInputsBySecurityEntityId -> targetAllocationInputsBySecurityEntityId
          const inputs =
            props.directiveInputsBySecurityEntityId[
              extractIdFromUrl(security.url)
            ]
          return {
            instrument: security.url,
            lowerBound: Number(inputs.lower_bound),
            targetWeight:
              inputs.target_weight == null
                ? null
                : Number(inputs.target_weight),
            upperBound: Number(inputs.upper_bound),
            allowTrading: props.checkedSecurityUrls.includes(security.url),
            marketPriceOverride: inputs.market_price ?? null,
            commission: inputs.commission ?? null,
            /**
             * This poor logic is a result of mixing up `IDirectiveInputs` and
             * `IBulkRebalanceChartParams` somewhere... ideally we want to not pass around
             * both `key` and `value`, but doing that will take too long.
             * Therefore, this is a temp fix.
             */
            commissionType:
              (typeof inputs.commission_type === 'object' &&
              // @ts-ignore
              'key' in inputs.commission_type
                ? (
                    inputs.commission_type as {
                      key: COMMISSION_TYPE
                      value: string
                    }
                  ).key
                : inputs.commission_type) ?? null
          }
        })
      })
    }

    // Takes the base chart, created from securities and adds all the edited values
    const tableWithEdits: StandardResponse = useMemo(() => {
      if (!bulkDirectiveChart.data) {
        return null
      }

      let standardResponse = bulkDirectiveChart.data.updateCategoryOptions(
        ['allow_trading'],
        {hidden: true}
      )

      for (const itemId of Object.keys(
        props.directiveInputsBySecurityEntityId
      )) {
        const valuesByCategoryId =
          props.directiveInputsBySecurityEntityId[itemId]
        for (const categoryId of Object.keys(valuesByCategoryId)) {
          const value = valuesByCategoryId[categoryId]
          if (value !== undefined) {
            const [cellValue, key] = (() => {
              if (!!value && typeof value === 'object') {
                return [value.value, value.key]
              }
              return [value, undefined]
            })()

            standardResponse = standardResponse.updateValue(
              categoryId,
              itemId,
              cellValue,
              key
            )
          }
        }
      }

      return standardResponse
    }, [bulkDirectiveChart.data, props.directiveInputsBySecurityEntityId])

    const securityPricesBySecurityIDs: IInitialMarketPrices = useMemo(() => {
      if (!tableWithEdits) {
        return null
      }
      const pricesByIds: IInitialMarketPrices = {}
      for (const item of tableWithEdits.items) {
        const value = item.getValue('market_price')
        pricesByIds[item.id] = value || null
      }
      return pricesByIds
    }, [tableWithEdits])

    useEffect(() => {
      // To keep track of (initial + override) prices specifically for the chips
      props.setInitialMarketPrices(securityPricesBySecurityIDs)
    }, [securityPricesBySecurityIDs])

    return (
      <ControlStateProvider loading={props.loadingSecuritiesForModelPortfolio}>
        <div className={css.addSecurityContainer}>
          <SearchWrapper>
            <Search
              standard
              focusOnMount
              clearOnResultSelect
              disabled={props.loadingSecuritiesForModelPortfolio}
              isAccountSpecificNot
              extraFilters={SecuritySearchFilter}
              optionRenderOverride={{
                [ALL_MODELS.INSTRUMENT]: SecuritySearchOption
              }}
              filterBy={(result) => {
                // Remove current selection from the results, remove non-tradable instruments too
                if (result.modelName === ALL_MODELS.INSTRUMENT) {
                  return (
                    result.isTradable !== false &&
                    !props.securities
                      .map((securityUrl) => extractIdFromUrl(securityUrl.url))
                      .includes(result.lookupId)
                  )
                }

                // Do not allow selection of funds, only model portfolios
                return result.portfolioType === PORTFOLIO_TYPE.MODEL
              }}
              placeholder='Add a security or a model portfolio'
              searchBy={[ALL_MODELS.INSTRUMENT, ALL_MODELS.PORTFOLIO]}
              onResultSelect={(searchResult) => {
                if (searchResult.modelName === ALL_MODELS.INSTRUMENT) {
                  props.onAddSecurity(searchResult)
                } else {
                  handleSelectModelPortfolio(searchResult)
                }
              }}
              groupedHeader={{
                [ALL_MODELS.INSTRUMENT]: <SecuritySearchOptionHeader />
              }}
            />
          </SearchWrapper>
        </div>
        <Spacer xxs />
        <div>
          <LoaderDisplay
            loaderProps={{
              size: 40
            }}
            {...bulkDirectiveChart}
            loading={
              bulkDirectiveChart.loading ||
              props.loadingSecuritiesForModelPortfolio
            }
          />
          {tableWithEdits && (
            <StandardTable
              id={TARGET_ALLOCATION_TABLE_ID}
              ref={tableRef}
              table={tableWithEdits}
              maxHeight={500}
              checkedRows={props.checkedSecurityUrls.map(extractIdFromUrl)}
              onCheckboxedRows={(ids) =>
                props.setCheckedSecurityUrls(
                  ids.map((id) =>
                    api.buildUrl(InstrumentEndpoints.pathToResource(id))
                  )
                )
              }
              onCellValueChange={(item, category, value: any, key: any) => {
                props.setDirectiveInputValue({
                  item,
                  category,
                  value:
                    category.id === CATEGORY_IDS.COMMISSION_TYPE
                      ? COMMISSION_TYPE_OPTIONS.find(
                          (item) => item.label === value
                        ).value
                      : value,
                  key
                })
              }}
              getCellError={({item, category}) =>
                directiveErrorsBySecurityEntityId[item.id]?.[category.id]
              }
              noResultsText={
                props.selectedModelPortfolio &&
                !props.loadingSecuritiesForModelPortfolio
                  ? 'The selected model portfolio does not have any securities. Select a different model portfolio, or manually add a securities below.'
                  : 'Select a model portfolio or manually add a security below.'
              }
            />
          )}
        </div>
        <Flex justifySpaceBetween alignCenter>
          <Button
            type='button'
            contained
            data-testid='button-clear-securities'
            disabled={!props.bulkRebalanceParams}
            onClick={props.clearSecurities}
          >
            Clear Securities
          </Button>

          <Flex justifyFlexEnd alignCenter className={css.actions}>
            <Text alignRight lineHeight={1}>
              Allow trading type(s)
            </Text>
            <ValueLabelSelect
              name={TARGET_ALLOCATION_FIELD_NAMES.desiredAction}
              onChange={handleTradeParameterChange}
              value={
                tradeParameters?.[
                  TARGET_ALLOCATION_FIELD_NAMES.desiredAction
                ] || ''
              }
              id='desired-action'
              className={css.desiredAction}
              options={BULK_TRADING_ACTIONS_OPTIONS}
              size='small'
            />
            <Text alignRight lineHeight={1}>
              with rebalance rules for
            </Text>

            <RebalanceRules
              isTradeDirectivePage
              hideTooltip
              selectedRebalanceRuleUrl={
                tradeParameters?.[TARGET_ALLOCATION_FIELD_NAMES.rebalanceRule]
              }
              onChange={(url) => {
                setTradeParameters({rebalanceRule: url})
              }}
            />
            <Button
              type='button'
              onClick={handleRecommendTrades}
              contained
              primary
              spaceRight
              data-testid='button-recommend-trades'
              disabled={
                !props.bulkRebalanceParams ||
                !!Object.keys(directiveErrorsBySecurityEntityId).length ||
                props.securities.length === 0
              }
            >
              Recommend Trades
            </Button>
            {props.bulkRebalanceParams && (
              <ComboKeyListener
                combo
                leaderKey='shift'
                actionKey='r'
                onAction={handleRecommendTrades}
              />
            )}
          </Flex>
        </Flex>
      </ControlStateProvider>
    )
  }
)
