import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'

import {Api, useApi} from 'fairlight'

import {
  EntityEndpoints,
  PeopleInvestmentMandateEndpoints
} from '@d1g1t/api/endpoints'
import {ALL_MODELS} from '@d1g1t/api/models'

import {history} from '@d1g1t/lib/history'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ISelectEntitiesState} from '@d1g1t/shared/containers/select-entities'
import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {reportError} from '@d1g1t/shared/wrappers/error-handler'

import {
  ENTITY_SECTIONS,
  ExploreLocations,
  modelPath,
  SingleClientLocations,
  TradeLocations
} from '@d1g1t/advisor/locations'

/**
 * Contruct the redirect URL for a given entity ID by using the properties
 * from client relationships.
 *
 * Supports:
 * - Clients (monitor/rebalance/profile/compare)
 *     - Accounts
 *     - Investment Mandate \>  selects multiple accounts
 *     - Positions
 * - Model Portfolios
 */
export const redirectUrlForEntity = async (params: {
  api: Api
  entityId: string
  section?: ENTITY_SECTIONS
  asOfDate: string
}): Promise<string> => {
  const {api, entityId, section = ENTITY_SECTIONS.DEFAULT, asOfDate} = params
  try {
    const entityProperties = await api.request(
      EntityEndpoints.clientRelationships(entityId)
    )

    if (
      [ALL_MODELS.PORTFOLIO, ALL_MODELS.CLASSSERIES].includes(
        entityProperties.model
      )
    ) {
      return modelPath(entityProperties.model, entityId)
    }

    // Assume models which have a client relatiships should redirect to a
    // manage clients page
    if (entityProperties.clientEntityId) {
      // Mandates default to their own single pages
      if (
        entityProperties.model === ALL_MODELS.INVESTMENTMANDATE &&
        section === ENTITY_SECTIONS.MODEL
      ) {
        // Mandate lookup, this is currently the *only* way to get the ID of
        // a mandate from its entity ID
        const investmentMandateResponse = await api.request(
          PeopleInvestmentMandateEndpoints.list({
            entityId,
            clientEntityId: entityProperties.clientEntityId,
            date: asOfDate
          })
        )

        // Find the Mandate selected on the Mandates list page and grab it from the results to be displayed on a single madate page
        // If for any reason that mandate cannot be found, use the first in the array
        const investmentMandate =
          investmentMandateResponse.results.find((result) =>
            result.url.includes(entityId)
          ) || investmentMandateResponse.results[0]

        // Default to "0", will render a 404 page
        const investmentMandateId = investmentMandate
          ? extractIdFromUrl(investmentMandate.url)
          : '0'

        return SingleClientLocations.mandate(
          investmentMandateId,
          entityProperties.clientEntityId
        )
      }

      // Mandates can be passed in payload.control.selected_entities directly
      const clientEntityId =
        entityProperties.model === ALL_MODELS.INVESTMENTMANDATE
          ? entityProperties.entityId
          : entityProperties.clientEntityId

      // Account filtering is only necessary for some models, other models either
      // don't support account filtering or don't need it
      const shouldFilterByAccounts = [
        ALL_MODELS.ACCOUNTGROUP,
        ALL_MODELS.ACCOUNT
      ].includes(entityProperties.model)

      const selection: ISelectEntitiesState = {
        selected: [
          {
            entityId: clientEntityId,
            accounts: shouldFilterByAccounts
              ? entityProperties.accounts
              : undefined,
            positions: entityProperties.positions
          }
        ]
      }

      switch (section) {
        case ENTITY_SECTIONS.DEFAULT:
          return SingleClientLocations.default(selection)
        case ENTITY_SECTIONS.MONITOR:
          return SingleClientLocations.monitorOverview(selection)
        case ENTITY_SECTIONS.REBALANCE:
          return TradeLocations.rebalance(selection)
        case ENTITY_SECTIONS.REBALANCE_PORTFOLIOS:
          return TradeLocations.rebalancePortfolios(selection)
        case ENTITY_SECTIONS.CLIENT_PROFILE:
          return SingleClientLocations.profile(selection)
        case ENTITY_SECTIONS.ACCOUNT_DETAILS:
          return SingleClientLocations.account(entityId, selection)
        case ENTITY_SECTIONS.COMPARE:
          return ExploreLocations.compare(selection)
        case ENTITY_SECTIONS.TRADE_DIRECTIVE:
          return TradeLocations.bulkChangeAllocation(selection)
      }
    }

    if (__DEVELOPMENT__) {
      console.error(
        `Unrecognized entity type which creating redirect URL for entityId=${entityId}, section=${section} falling back to "modelPath"`
      )
      console.error(entityProperties)
    }

    return modelPath(entityProperties.model, entityId)
  } catch (error) {
    if (__DEVELOPMENT__) {
      console.error(`Error building redirect URL for entityId=${entityId}`)
    }

    reportError(error)

    return null
  }
}

/**
 * To be called on-mount, replaces the current URL with next redirect URL.
 *
 * Supports:
 * - Clients (monitor/rebalance/profile/compare)
 *     - Accounts
 *     - Investment Mandate \> selects multiple accounts
 *     - Positions
 * - Model Portfolios
 * - Fund
 */
const redirectEntity = async (params: {
  api: Api
  entityId: string
  section?: ENTITY_SECTIONS
  asOfDate: string
}): Promise<void> => {
  const nextUrl = await redirectUrlForEntity(params)

  if (nextUrl) {
    history.replace(nextUrl)
  }
}

export const ENTITY_REDIRECT_PATH = '/redirect/:entityId/:section?'

interface IEntityRedirectParams {
  entityId: string
  /**
   * Section of entity to redirect to
   *
   * This should be `ENTITY_SECTIONS` but `RouteComponentProps`
   * requires that all values be `string` type
   */
  section?: string
}

/**
 * On mount, fetches information about the entity in URL params and redirects
 * to the correct path.
 *
 * Supports:
 * - Clients (monitor/rebalance/profile/compare)
 *     - Accounts
 *     - Investment Mandate \>  selects multiple accounts
 *     - Positions
 * - Model Portfolios
 * - Fund
 */
export const EntityRedirect: React.FC = () => {
  const api = useApi()
  const {entityId, section} = useParams<IEntityRedirectParams>()
  const [calculationSettings] = useCalculationSettings()

  useEffect(() => {
    redirectEntity({
      api,
      entityId,
      section: section as ENTITY_SECTIONS,
      asOfDate: calculationSettings.date.date
    })
  }, [])

  return null
}
