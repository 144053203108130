import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {Options, XAxisOptions, YAxisOptions} from 'highcharts'

import {cyan} from '@material-ui/core/colors'

import {
  CALCULATION_CODES,
  CHART_VALUE_TYPE,
  DRILLDOWN_DIMENSION,
  IChartTableOptions
} from '@d1g1t/api/models'

import {getActiveLegends} from '@d1g1t/lib/charts'
import {HIGHCHARTS_DATE_FORMATS} from '@d1g1t/lib/constants'
import {StandardResponse} from '@d1g1t/lib/standard-response'

import {BaseChart} from '@d1g1t/shared/components/charts/base-chart'
import {
  useChartValueFormatter,
  useNumberFormatter
} from '@d1g1t/shared/wrappers/formatter'

import {IGraphSeries} from '../chart'
import {getAxisTitles} from './lib'

interface IReturnsAreaChartProps {
  series: IGraphSeries[]
  name: IValueLabelOption<CALCULATION_CODES>
  drilldownDimension?: DRILLDOWN_DIMENSION
  /**
   * will only show as active passed names of series. If nothing is passed, all series will be active.
   */
  visibleSeries?: string[]
  showInLogScale?: boolean
  enableDateFormatting?: boolean
}

const getValueTypeOptions = (standardResponse: StandardResponse) => {
  const options: IChartTableOptions =
    standardResponse.categories[1].options || {}

  return {
    options,
    valueType: standardResponse.categories[1].valueType
  }
}

export const ReturnsAreaChart: React.FC<IReturnsAreaChartProps> = (
  props: IReturnsAreaChartProps
) => {
  const plotOptionsSeries: any = {
    showInNavigator: false
  }

  const labelSettings: YAxisOptions['labels'] = {
    enabled: true,
    align: 'right'
  }
  const xAxisLabelSettings: XAxisOptions['labels'] = {}
  let xAxisTooltipFormat = '%b %e, %Y'

  const standardResponse = props.series[0].data
  const {t} = useTranslation()
  const series = useMemo(() => {
    return standardResponse.asSeries().map((item, index) => {
      if (
        index === 0 ||
        !props.visibleSeries ||
        props.visibleSeries.includes(item.name)
      ) {
        return {...item, name: t(item.name)}
      }
      return {...item, name: t(item.name), visible: false}
    })
  }, [standardResponse, props.visibleSeries])

  const {valueType, options} = getValueTypeOptions(standardResponse)

  const defaultValueFormatter = useChartValueFormatter(valueType, options)
  const abbrevCurrencyFormatter = useNumberFormatter({
    style: 'currency',
    abbreviate: true,
    decimalPlaces: 1
  })

  function legendItemClick() {
    if (props.name.value === CALCULATION_CODES.TREND_GAIN_N_LOSS) {
      queueMicrotask(() => {
        const activeLegends = getActiveLegends(this.chart)
        const [leftLabel, rightLabel] = getAxisTitles(activeLegends)

        this.chart.yAxis[0].update({
          title: {
            text: t(leftLabel)
          }
        })
        this.chart.yAxis[1].update({
          title: {
            text: t(rightLabel)
          }
        })
      })
    }
  }

  if (
    [
      CALCULATION_CODES.TREND_CUMUL_RET,
      CALCULATION_CODES.TREND_REALIZD_VOLAT,
      CALCULATION_CODES.TREND_VAR,
      CALCULATION_CODES.TREND_CUMUL_RET_CONTRIB
    ].includes(props.name.value)
  ) {
    labelSettings.formatter = function trendFormatter() {
      return defaultValueFormatter.format(this.value)
    }
  } else if (props.name.value === CALCULATION_CODES.TREND_AUM) {
    labelSettings.formatter = function trendAumFormatter() {
      return abbrevCurrencyFormatter.format(this.value)
    }
  } else if (
    props.enableDateFormatting &&
    props.name.value === CALCULATION_CODES.TREND_GAIN_N_LOSS
  ) {
    const defaultXValueFormatter = useChartValueFormatter(CHART_VALUE_TYPE.DATE)
    const formatterOptions = defaultXValueFormatter.resolvedOptions()
    xAxisLabelSettings.formatter = function trendGainNLossFormatter() {
      return defaultXValueFormatter.format(this.value)
    }
    xAxisTooltipFormat = HIGHCHARTS_DATE_FORMATS[formatterOptions.dateFormat]
  }

  const legendEnabled = props.series[0].data.categories.length > 1

  const yAxis: YAxisOptions[] = useMemo(() => {
    const options: YAxisOptions = {
      opposite: false,
      type: props.showInLogScale ? 'logarithmic' : 'linear',
      visible: true,
      title: {
        text: null
      },
      labels: labelSettings
    }

    if (props.name.value === CALCULATION_CODES.TREND_GAIN_N_LOSS) {
      const rightYAxisLabelSettings = {...labelSettings}

      const [rightLabel, leftLabel] = getAxisTitles(props.visibleSeries)

      rightYAxisLabelSettings.align = 'left'
      options.title.text = t(rightLabel)

      return [
        options,
        {
          opposite: true,
          type: 'linear',
          visible: true,
          title: {
            text: t(leftLabel)
          },
          labels: rightYAxisLabelSettings
        }
      ]
    }

    return [options]
  }, [series, props.showInLogScale])

  const config: Options = {
    series,
    yAxis,
    chart: {
      type:
        props.name.value === CALCULATION_CODES.TREND_AUM &&
        props.drilldownDimension !== DRILLDOWN_DIMENSION.TOPLEVEL
          ? 'area'
          : 'line',
      zoomType: 'x'
    },
    legend: {
      enabled: legendEnabled
    },
    scrollbar: {
      enabled: false
    },
    navigator: {
      enabled: false
    },
    xAxis: {
      crosshair: {
        color: cyan[500],
        dashStyle: 'Dash',
        zIndex: 100
      },
      labels: xAxisLabelSettings,
      type: 'datetime',
      title: {
        text: null
      }
    },
    rangeSelector: {
      enabled: false
    },
    tooltip: {
      pointFormatter() {
        return `<span style="color:${this.color}">●</span> ${
          this.series.name
        }: <b>${defaultValueFormatter.format(this.y)}</b><br/>`
      },
      valueDecimals: 2,
      headerFormat: `{point.x:${xAxisTooltipFormat}}`
    },
    plotOptions: {
      area: {
        stacking: 'normal'
      },
      line: {
        events: {
          legendItemClick
        }
      },
      series: plotOptionsSeries
    }
  }

  return <BaseChart config={config} />
}
