import {useMemo} from 'react'

import produce from 'immer'
import {isNil} from 'lodash'

const OVERRIDE_POSTFIX = 'Override'

/**
 * Applies object overrides
 * e.g. `{ name: "Test", nameOverrides: "Overridden" } => { name: "Overridden" }`
 */
export function applyObjectOverrides<TModel extends {}>(
  object: TModel
): TModel {
  if (isNil(object)) {
    return object
  }

  return Object.entries(object).reduce<TModel>((prev, [key, value]) => {
    const keyHasOverridePostfix = key.endsWith(OVERRIDE_POSTFIX)
    const originalKey = keyHasOverridePostfix
      ? key.slice(0, key.length - OVERRIDE_POSTFIX.length)
      : key

    const isOverrideKey = keyHasOverridePostfix && originalKey in object

    if ((isOverrideKey && isNil(value)) || value === '' || !isNil(prev[key])) {
      return prev
    }

    return {
      ...prev,
      [isOverrideKey ? originalKey : key]: value
    }
  }, {} as TModel)
}

/**
 * React hook to simplify handling of objects with overrides
 */
export function useModelOverrides<TModel extends {}>(
  /**
   * Original object to transform
   */
  object: TModel
): [
  /**
   * Object with overridden properties
   * e.g. `{ name: "Test", nameOverrides: "Overridden" } => { name: "Overridden" }`
   */
  TModel,
  {
    /**
     * Given an object of values, applies `getUpdateKey` to each
     * property and returns a new object with all overrides applied
     */
    serializeValuesWithOverrides: <
      TValues extends Partial<Record<keyof TModel, any>>
    >(
      values: TValues
    ) => TValues
  }
] {
  const objectWithOverridesApplied = useMemo(
    () => applyObjectOverrides(object),
    [object]
  )

  const getUpdateKey = (originalKey: string): string => {
    if (!object) {
      return originalKey
    }

    const overrideKey = `${originalKey}${OVERRIDE_POSTFIX}`

    if (overrideKey in object) {
      return overrideKey
    }

    return originalKey
  }

  function serializeValuesWithOverrides<
    TValues extends Partial<Record<keyof TModel, any>>
  >(values: TValues) {
    return produce(values, (draft) => {
      for (const [key, value] of Object.entries(draft)) {
        delete draft[key]
        draft[getUpdateKey(key)] = value
      }
    })
  }

  return [objectWithOverridesApplied as TModel, {serializeValuesWithOverrides}]
}
