import {UseApiQueryActions} from 'fairlight'

import {
  AssetClassEndpoints,
  AssetClassL2Endpoints,
  AssetClassL2VectorsEndpoints,
  AssetClassL3Endpoints,
  AssetClassL3VectorsEndpoints,
  AssetClassVectorsEndpoints,
  CurrencyEndpoints,
  CurrencyVectorsEndpoints,
  InstrumentIndustryEndpoints,
  InstrumentIndustryVectorsEndpoints,
  InstrumentRegionEndpoints,
  InstrumentRegionVectorsEndpoints,
  InstrumentSectorEndpoints,
  InstrumentSectorVectorsEndpoints,
  InstrumentStrategyEndpoints,
  InstrumentStrategyVectorsEndpoints
} from '@d1g1t/api/endpoints'
import {
  IAssetClassExposureVector,
  IAssetClassL2ExposureVector,
  IAssetClassL3ExposureVector,
  ICurrencyExposureVector,
  IIndustryExposureVector,
  IRegionExposureVector,
  ISectorExposureVector,
  IStrategyExposureVector
} from '@d1g1t/api/models'

import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'

import {IStandardTableCategory} from '@d1g1t/shared/containers/standard-table'

import {SECURITY_KEYS} from '../../constants'

export enum VECTORIZED_EXPOSURE_COMMON_KEYS {
  NAME = 'name',
  WEIGHT = 'weight'
}

// The variable keys from GET /instruments/{id} response's exposure objects' keys
export enum VECTORIZED_EXPOSURE_KEYS {
  ASSET_CLASS = 'assetClass',
  ASSET_CLASS_L2 = 'assetClassL2',
  ASSET_CLASS_L3 = 'assetClassL3',
  CURRENCY = 'currency',
  STRATEGY = 'strategy',
  REGION = 'region',
  INDUSTRY = 'industry',
  SECTOR = 'sector'
}

/**
 * Attempts to unify the various Exposure interfaces exported by PS.
 * PS should change the variable key in the exposure interfaces to `propertyUrl`...
 */
export type IExposureVector = {
  url?: string
  weight: number
} & Partial<
  RequireOnlyOne<{
    [VECTORIZED_EXPOSURE_KEYS.ASSET_CLASS]?: string
    [VECTORIZED_EXPOSURE_KEYS.ASSET_CLASS_L2]?: string
    [VECTORIZED_EXPOSURE_KEYS.ASSET_CLASS_L3]?: string
    [VECTORIZED_EXPOSURE_KEYS.CURRENCY]?: string
    [VECTORIZED_EXPOSURE_KEYS.INDUSTRY]?: string
    [VECTORIZED_EXPOSURE_KEYS.REGION]?: string
    [VECTORIZED_EXPOSURE_KEYS.STRATEGY]?: string
    [VECTORIZED_EXPOSURE_KEYS.SECTOR]?: string
  }>
>

export type IUseVectorEditorDataHookReturnType = [
  Loadable<StandardResponse>,
  /**
   * Count of total number of options
   */
  number,
  {
    onAddVector(): void
    onRemoveVector(item: StandardResponseItem): void
    onCellValueChange(
      item: StandardResponseItem,
      category: IStandardTableCategory,
      value: any
    ): void
    refetchVectorChart(): void
    setVectorChartData: UseApiQueryActions<StandardResponse>['setData']
  }
]

export interface IEditVectorizedPropertyFormValues {
  [SECURITY_KEYS.ASSET_CLASS_VECTORIZED]?: IAssetClassExposureVector[]
  [SECURITY_KEYS.ASSET_CLASS_L2_VECTORIZED]?: IAssetClassL2ExposureVector[]
  [SECURITY_KEYS.ASSET_CLASS_L3_VECTORIZED]?: IAssetClassL3ExposureVector[]
  [SECURITY_KEYS.CURRENCY_VECTORIZED]?: ICurrencyExposureVector[]
  [SECURITY_KEYS.STRATEGY_VECTORIZED]?: IStrategyExposureVector[]
  [SECURITY_KEYS.REGION_VECTORIZED]?: IRegionExposureVector[]
  [SECURITY_KEYS.INDUSTRY_VECTORIZED]?: IIndustryExposureVector[]
  [SECURITY_KEYS.SECTOR_VECTORIZED]?: ISectorExposureVector[]
}

export interface IVectorizedPropertyEditorFormikContext {
  [SECURITY_KEYS.ASSET_CLASS_VECTORIZED]: IExposureVector[]
  [SECURITY_KEYS.ASSET_CLASS_L2_VECTORIZED]: IExposureVector[]
  [SECURITY_KEYS.ASSET_CLASS_L3_VECTORIZED]: IExposureVector[]
  [SECURITY_KEYS.CURRENCY_VECTORIZED]: IExposureVector[]
  [SECURITY_KEYS.STRATEGY_VECTORIZED]: IExposureVector[]
  [SECURITY_KEYS.REGION_VECTORIZED]: IExposureVector[]
  [SECURITY_KEYS.INDUSTRY_VECTORIZED]: IExposureVector[]
  [SECURITY_KEYS.SECTOR_VECTORIZED]: IExposureVector[]
}

export const INSTRUMENT_PROPERTY_KEY_TO_ENDPOINT_CLASS_MAP = {
  [SECURITY_KEYS.ASSET_CLASS_VECTORIZED]: {
    vectorEndpointClass: AssetClassVectorsEndpoints,
    listEndpointClass: AssetClassEndpoints
  },
  [SECURITY_KEYS.ASSET_CLASS_L2_VECTORIZED]: {
    vectorEndpointClass: AssetClassL2VectorsEndpoints,
    listEndpointClass: AssetClassL2Endpoints
  },
  [SECURITY_KEYS.ASSET_CLASS_L3_VECTORIZED]: {
    vectorEndpointClass: AssetClassL3VectorsEndpoints,
    listEndpointClass: AssetClassL3Endpoints
  },
  [SECURITY_KEYS.CURRENCY_VECTORIZED]: {
    vectorEndpointClass: CurrencyVectorsEndpoints,
    listEndpointClass: CurrencyEndpoints
  },
  [SECURITY_KEYS.STRATEGY_VECTORIZED]: {
    vectorEndpointClass: InstrumentStrategyVectorsEndpoints,
    listEndpointClass: InstrumentStrategyEndpoints
  },
  [SECURITY_KEYS.REGION_VECTORIZED]: {
    vectorEndpointClass: InstrumentRegionVectorsEndpoints,
    listEndpointClass: InstrumentRegionEndpoints
  },
  [SECURITY_KEYS.INDUSTRY_VECTORIZED]: {
    vectorEndpointClass: InstrumentIndustryVectorsEndpoints,
    listEndpointClass: InstrumentIndustryEndpoints
  },
  [SECURITY_KEYS.SECTOR_VECTORIZED]: {
    vectorEndpointClass: InstrumentSectorVectorsEndpoints,
    listEndpointClass: InstrumentSectorEndpoints
  }
}

export const INSTRUMENT_PROPERTY_KEY_TO_EXPOSURE_KEYS_MAP = {
  [SECURITY_KEYS.ASSET_CLASS_VECTORIZED]: VECTORIZED_EXPOSURE_KEYS.ASSET_CLASS,
  [SECURITY_KEYS.ASSET_CLASS_L2_VECTORIZED]:
    VECTORIZED_EXPOSURE_KEYS.ASSET_CLASS_L2,
  [SECURITY_KEYS.ASSET_CLASS_L3_VECTORIZED]:
    VECTORIZED_EXPOSURE_KEYS.ASSET_CLASS_L3,
  [SECURITY_KEYS.CURRENCY_VECTORIZED]: VECTORIZED_EXPOSURE_KEYS.CURRENCY,
  [SECURITY_KEYS.STRATEGY_VECTORIZED]: VECTORIZED_EXPOSURE_KEYS.STRATEGY,
  [SECURITY_KEYS.REGION_VECTORIZED]: VECTORIZED_EXPOSURE_KEYS.REGION,
  [SECURITY_KEYS.INDUSTRY_VECTORIZED]: VECTORIZED_EXPOSURE_KEYS.INDUSTRY,
  [SECURITY_KEYS.SECTOR_VECTORIZED]: VECTORIZED_EXPOSURE_KEYS.SECTOR
}
