import React, {useEffect, useMemo, useState} from 'react'

import {sortBy} from 'lodash'

import {
  GROUPING_CRITERIA_SLUG,
  GROUPING_NODE_TYPE,
  IGrouping,
  IViewGroupingItem
} from '@d1g1t/api/models'

import {useIsFirstRender} from '@d1g1t/lib/hooks'
import {pickFromObj} from '@d1g1t/lib/pick-from-obj'

import {DraggableAutocomplete} from '@d1g1t/shared/components/draggable-autocomplete'
import {DraggableList} from '@d1g1t/shared/components/draggable-list'
import {ListEditorEmptyStateButton} from '@d1g1t/shared/components/list-editor-empty-state'
import {Button} from '@d1g1t/shared/components/mui/button'

interface IGroupingsListProps {
  availableItems: IGrouping[]
  selectedItems?: IViewGroupingItem[]
  onUpdateSelectedItems(items: IViewGroupingItem[]): void
}

export interface IGroupingListItem {
  id: number
  value: IViewGroupingItem
  child: React.ReactNode
}

function incrementItemId(items: IGroupingListItem[]): number {
  if (items.length === 0) {
    return 0
  }

  const itemIds = items.map((item) => item.id)
  const largestNumber = Math.max(...itemIds)
  return largestNumber + 1
}

function getGroupingCriterionLabel(
  groupingCriterion: IViewGroupingItem | IGrouping
): `${GROUPING_NODE_TYPE} ${string}` {
  return `${groupingCriterion.nodeType} ${groupingCriterion.displayName}`
}

function getGroupingCriterionSlug(
  groupingCriterion: IViewGroupingItem | IGrouping
): GROUPING_CRITERIA_SLUG {
  return groupingCriterion?.slug as GROUPING_CRITERIA_SLUG
}

export const GroupingsList: React.FC<IGroupingsListProps> = (props) => {
  const [items, setItems] = useState<IGroupingListItem[]>(() => {
    return (props.selectedItems || []).map((selectedItem, index) => ({
      id: index,
      value: selectedItem,
      child: null
    }))
  })

  const selectedSlugs: Set<GROUPING_CRITERIA_SLUG> = useMemo(
    () =>
      items.reduce<Set<GROUPING_CRITERIA_SLUG>>((prev, item) => {
        if (item.value) {
          return prev.add(getGroupingCriterionSlug(item.value))
        }
        return prev
      }, new Set()),
    [items]
  )

  const isFirstRender = useIsFirstRender()
  useEffect(() => {
    if (!isFirstRender) {
      props.onUpdateSelectedItems(
        items.map((item) => item.value).filter((item) => !!item?.url)
      )
    }
  }, [items])

  const handleAddEmptyGroupingItem = () => {
    setItems((prev) =>
      prev.concat({
        id: incrementItemId(prev),
        value: null,
        child: null
      })
    )
  }

  const handleUpdateItem = (
    id: number,
    groupingCriterion: IViewGroupingItem | IGrouping
  ) => {
    if ('groupingCriterion' in groupingCriterion) {
      // Type narrowed to `IViewGrouping` => existing grouping updated
      setItems((prev) =>
        prev.map((item, index) => {
          if (item.id === id) {
            return {
              ...item,
              order: index,
              value: pickFromObj(
                groupingCriterion,
                'order',
                'url',
                'groupingCriterion',
                'nodeType',
                'displayName',
                'slug'
              )
            }
          }

          return item
        })
      )
    } else {
      // Type narrowed to `IGrouping` => new grouping added
      setItems((prev) =>
        prev.map((item, index) => {
          if (item.id === id) {
            return {
              ...item,
              order: index,
              value: {
                ...pickFromObj(
                  groupingCriterion,
                  'nodeType',
                  'displayName',
                  'slug'
                ),
                groupingCriterion: groupingCriterion.url,
                url: groupingCriterion.slug // PS ignores this, set for cache busting purposes
              } as IViewGroupingItem
            }
          }

          return item
        })
      )
    }
  }

  const handleRemoveItem = (removedGroupId: number) => {
    setItems((prev) => prev.filter((item) => item.id !== removedGroupId))
  }

  const handleReorder = (itemsReordered: IGroupingListItem[]) => {
    setItems(itemsReordered)
  }

  const groupings = items.map((item) => ({
    ...item,
    child: (
      <DraggableAutocomplete
        value={item.value}
        onChange={(value: IViewGroupingItem | IGrouping) => {
          handleUpdateItem(item.id, value)
        }}
        options={sortBy(
          (props.availableItems || []).filter(
            (availableItem) =>
              availableItem.slug === getGroupingCriterionSlug(item.value) ||
              !selectedSlugs.has(availableItem.slug as GROUPING_CRITERIA_SLUG)
          ),
          (grouping) => getGroupingCriterionLabel(grouping)
        )}
        getOptionLabel={getGroupingCriterionLabel}
        placeholder='Select a group...'
      />
    )
  }))

  return (
    <div>
      <DraggableList
        items={groupings}
        onReorder={handleReorder}
        onRemoveItem={handleRemoveItem}
        emptyText={
          <>
            No groups have been applied to this table.{' '}
            <ListEditorEmptyStateButton onClick={handleAddEmptyGroupingItem}>
              Add a group
            </ListEditorEmptyStateButton>{' '}
            to get started.
          </>
        }
      />
      <Button
        fullWidth
        outlined
        disabled={props.availableItems?.length <= items.length}
        onClick={handleAddEmptyGroupingItem}
      >
        {groupings.length === 0 ? 'Add A Group' : 'Add Another Group'}
      </Button>
    </div>
  )
}
