import React from 'react'

import {FieldArray, useField} from 'formik'
import {isNil} from 'lodash'

import AddIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/Close'

import {Formats, Types} from '@d1g1t/api/endpoints'
import {ComparisonValue} from '@d1g1t/typings/general'

import {Flex} from '@d1g1t/shared/components/flex'
import {KeyboardDatePickerField} from '@d1g1t/shared/components/form-field/keyboard-date-picker-field'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {ValueLabelSelectField} from '@d1g1t/shared/components/form-field/value-select-field'
import {
  DecimalNumberInput,
  IntegerInput,
  PercentageInput
} from '@d1g1t/shared/components/formatted-input'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {KeyboardDatePicker} from '@d1g1t/shared/components/mui/keyboard-date-picker'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'

export interface IFilterInputeProps {
  name: string
  type: Types
  format: Formats
  allowedValues: IValueLabelOption[]
}

export const FilterInput: React.FC<IFilterInputeProps> = (props) => {
  const [field, meta] = useField<ComparisonValue>(props.name)

  if (props.type === Types.isEmpty || props.type === Types.isNotEmpty) {
    return null
  }

  switch (props.format) {
    case Formats.string:
    case Formats.decimal:
    case Formats.percentage:
    case Formats.int: {
      const inputField = (
        index?: number,
        remove?: (index: number) => void,
        error?: string
      ) => {
        const indexExists = !isNil(index)

        const inputComponent = (() => {
          switch (props.format) {
            case Formats.decimal:
              return DecimalNumberInput
            case Formats.int:
              return IntegerInput
            case Formats.percentage:
              return PercentageInput
            default:
              return undefined
          }
        })()

        return (
          <Flex key={index} alignCenter>
            <OutlinedInputField
              name={`${props.name}${indexExists ? `[${index}]` : ''}`}
              error={error}
              outlinedInputProps={{
                spaceRight: true,
                inputComponent
              }}
            />
            {remove && (field.value as any[]).length && (
              <Tooltip title='Remove Option'>
                <IconButton errorTextMargin onClick={() => remove(index)}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
          </Flex>
        )
      }

      if (props.type === Types.between) {
        const [startNumberErrorText, endNumberErrorText] =
          meta.touched && meta.error ? meta.error.split('|') : ['', '']

        return (
          <Flex>
            {inputField(0, null, startNumberErrorText)}
            <Spacer vertical custom={6} />
            {inputField(1, null, endNumberErrorText)}
          </Flex>
        )
      }

      return [Types.oneOf, Types.in].includes(props.type) ? (
        <>
          <FieldArray name={props.name}>
            {(arrayHelpers) => (
              <>
                <Flex column>
                  {(field.value as any[]).map((_, index) =>
                    inputField(index, arrayHelpers.remove)
                  )}
                </Flex>
                <Tooltip title='Add Option'>
                  <IconButton
                    style={{marginTop: 2}}
                    onClick={() => arrayHelpers.push('')}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </FieldArray>
        </>
      ) : (
        inputField()
      )
    }
    case Formats.date:
      if (props.type === Types.dateBetween) {
        const [startDate, endDate] = field.value as string[]

        const handleChange = (index: number) => (value: string) => {
          field.onChange({
            target: {
              name: props.name,
              value: [
                (index === 0 ? value : startDate) || '',
                (index === 1 ? value : endDate) || ''
              ]
            }
          })
        }

        const [startDateErrorText, endDateErrorText] =
          meta.touched && meta.error ? meta.error.split('|') : ['', '']

        return (
          <Flex>
            <KeyboardDatePicker
              value={startDate}
              label='Start Date'
              onChange={handleChange(0)}
              helperText={startDateErrorText || ' '}
              error={!!startDateErrorText}
            />
            <Spacer vertical custom={6} />
            <KeyboardDatePicker
              value={endDate}
              label='End Date'
              onChange={handleChange(1)}
              helperText={endDateErrorText || ' '}
              error={!!endDateErrorText}
            />
          </Flex>
        )
      }

      return <KeyboardDatePickerField name={props.name} />
    case Formats.enum: {
      const multiple = [Types.oneOf, Types.in].includes(props.type)

      const multiSelectValue = (() => {
        if (!multiple) {
          return
        }

        const getKeyValuePair = (singleValue) =>
          props.allowedValues.find(
            (allowedValue) => String(allowedValue.value) === singleValue
          )

        const labelList = ((field.value as any[]) || []).map(
          (singleValue) => getKeyValuePair(singleValue)?.label
        )

        return labelList.join(', ')
      })()

      // Converts all allowedValues to strings if they are numbers
      // Needed to match currently selected value which is always a string.
      const allowedValues = (() => {
        if (typeof props.allowedValues[0].value === 'number') {
          return props.allowedValues.map((allowedValue) => ({
            ...allowedValue,
            value: String(allowedValue.value)
          }))
        }

        return props.allowedValues
      })()

      return (
        <ValueLabelSelectField
          name={props.name}
          valueLabelSelectProps={{
            options: allowedValues,
            placeholder: 'Select Options...',
            multiple,
            renderValue: multiple ? multiSelectValue : undefined
          }}
        />
      )
    }
    default:
      return null
  }
}
