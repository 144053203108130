import {IAccountData, IAccountsRebalancingResponse} from '@d1g1t/api/models'

import {
  getCurrentValue,
  getTargetValuesFromStandardResponse
} from '@d1g1t/lib/rebalance'
import {StandardResponse} from '@d1g1t/lib/standard-response'

import {ACTION, REBALANCE_ACCOUNT_ID_PREFIX} from './constants'

export const getTargetValuesForAccounts = (
  response: IAccountsRebalancingResponse,
  accountUrls: string[],
  action: ACTION = null
): IAccountData[] => {
  if (!response || action === ACTION.CLEAR) {
    return accountUrls.map((accountUrl) => ({account: accountUrl}))
  }

  if (response.accountTables.length !== accountUrls.length) {
    return []
  }

  const targetValues: IAccountData[] = []

  for (const [index, accountTable] of response.accountTables.entries()) {
    if (action === ACTION.PREVIEW && getCurrentValue(accountTable) === 0) {
      continue
    }

    const standardResponse = new StandardResponse(accountTable)

    targetValues.push({
      account: accountUrls[index],
      targetValues: getTargetValuesFromStandardResponse(standardResponse)
    })
  }

  return targetValues
}

/**
 * Verify accounts data is present and there is at least one target value
 */
export function previewRequestDataIsValid(accounts: IAccountData[]): boolean {
  if (!accounts) {
    return false
  }

  for (const account of accounts) {
    if (account.targetValues?.length) {
      return true
    }
  }

  return false
}

/**
 * Returns a prefixed ID given an account's firm provided key.
 * Used to ensure the StandardTable get an ID that's identical to the ID set in the Recoil atom.
 * @param firmProvidedKey - Rebalance page account's firmProvidedKey
 */
export const rebalanceAccountStandardTableId = (
  firmProvidedKey: string
): string => {
  return `${REBALANCE_ACCOUNT_ID_PREFIX}-${firmProvidedKey}`
}
