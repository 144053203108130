import {some} from 'lodash'

// NOTE(Alex.S) Placeholder for permissions management
import {IAccountsProfile, UI_PERMISSIONS} from '@d1g1t/api/models'
import {IUserMeResponse} from '@d1g1t/api/typings'

import {extractIdFromUrl} from '@d1g1t/lib/url'

export class Permissions {
  profile: IAccountsProfile

  user: IUserMeResponse

  constructor(profile: IAccountsProfile, user: IUserMeResponse) {
    this.profile = profile
    this.user = user
  }

  private hasProfile(): boolean {
    return !!this.profile
  }

  private hasUser(): boolean {
    return !!this.user
  }

  private hasPermissionCode(permissionCode: UI_PERMISSIONS): boolean {
    if (!permissionCode) {
      return false
    }

    return (
      this.hasUser() && some(this.user.uiPermissions, {code: permissionCode})
    )
  }

  /**
   * Checks if user is administrator
   */
  isAdministrator(): boolean {
    return this.hasProfile() && this.profile.isAdmin
  }

  /**
   * Grant access if user has the permission code
   *
   * @param permissionCode - UI Permission code to check
   *
   * @returns true if logged in user should be granted access
   */
  canAccess(permissionCode: UI_PERMISSIONS): boolean {
    return this.hasPermissionCode(permissionCode)
  }

  getId(): string {
    if (!this.hasProfile()) {
      return
    }

    return extractIdFromUrl(this.profile.url)
  }
}
