import React, {useEffect} from 'react'

import {useApi, useApiMutation} from 'fairlight'
import {FormikHelpers, useField} from 'formik'

import ConfirmOrderIcon from '@material-ui/icons/MoveToInbox'
import TradeIcon from '@material-ui/icons/SwapVerticalCircle'

import {
  AccountEndpoints,
  ITradingCheckRequestBody,
  ITradingCheckResponse,
  TradingEndpoints,
  TradingOrderEndpoints
} from '@d1g1t/api/endpoints'
import {
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  TRADEORDER_OPERATION,
  TRADEORDER_OPERATION_OPTIONS
} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'
import {getLabelWithValue} from '@d1g1t/lib/value-label'

import {confirm} from '@d1g1t/shared/components/confirmation'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H2} from '@d1g1t/shared/components/typography'
import {useSelectedEntities} from '@d1g1t/shared/containers/select-entities'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'

import {generateEquitiesEtfsTradeOrderPayload} from './equities-etfs/lib'
import {IEquitiesEtfsFormValues} from './equities-etfs/typings'
import {qtyChangeIsNumber, qtyChangeOperationSign} from './lib'
import {generateMutualFundTradeOrderPayload} from './mutual-funds/lib'
import {IMutualFundFormValues, QTY_TYPE} from './mutual-funds/typings'
import {TradeConfirmationContent} from './trade-confirmation-content'
import {ITradeOrderFormValues, TRADE_FORM_TYPE} from './typings'

import * as css from './style.scss'

export function useCreateTradeOrderMutation(params: {
  tradeType: TRADE_FORM_TYPE
  onSuccess?(): void
  onPreTradeValidationFailure(messages: string[]): void
}): [
  (
    values: ITradeOrderFormValues,
    formikBag: FormikHelpers<ITradeOrderFormValues>
  ) => Promise<void>
] {
  const api = useApi()
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()

  // Before opening trade confirmation modal, validate the trade
  const preTradeValidationFlow = async (
    tradeCheck: Omit<ITradingCheckRequestBody, 'quantityChange'> & {
      quantityChange: ITradeOrderFormValues['qty']
      operation: ITradeOrderFormValues['operation']
    }
  ): Promise<ITradingCheckResponse> => {
    if (!tradeCheck.account) {
      return {isValidForTrading: false, messages: []}
    }

    const quantityChange = qtyChangeIsNumber(tradeCheck.quantityChange)
      ? qtyChangeOperationSign(
          tradeCheck.quantityChange as number,
          tradeCheck.operation
        )
      : undefined

    try {
      const checkResponse = await api.request(
        TradingEndpoints.check({...tradeCheck, quantityChange})
      )

      params.onPreTradeValidationFailure(checkResponse.messages)

      return checkResponse
    } catch (error) {
      handleUnexpectedError(
        error,
        'An unexpected error occurred while verifying the trade.',
        true
      )
      return {isValidForTrading: false, messages: []}
    }
  }

  const [createTradeOrder] = useApiMutation({
    mutation:
      (
        values: ITradeOrderFormValues,
        formikBag: FormikHelpers<ITradeOrderFormValues>
      ) =>
      async (api) => {
        await api.request(
          TradingOrderEndpoints.create(
            params.tradeType === FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY
              ? generateEquitiesEtfsTradeOrderPayload(
                  values as IEquitiesEtfsFormValues
                )
              : generateMutualFundTradeOrderPayload(
                  values as IMutualFundFormValues
                )
          )
        )

        return formikBag
      },

    onSuccess: (formikBag: FormikHelpers<ITradeOrderFormValues>) => {
      showSnackbar({
        variant: 'success',
        message: 'Trade order has been submitted.'
      })

      if (params.onSuccess) {
        params.onSuccess()
      } else {
        formikBag.resetForm()
      }
    },

    onError: (error) => {
      handleUnexpectedError(
        error,
        'An unexpected error occurred while saving the trade.',
        true
      )
    }
  })

  const handleSubmit = async (
    values: ITradeOrderFormValues,
    formikBag: FormikHelpers<ITradeOrderFormValues>
  ) => {
    const validationResponse = await preTradeValidationFlow({
      account: values.accountUrl,
      instrument: values.instrumentUrl,
      quantityChange: values.qty,
      isDscFree:
        (values as IMutualFundFormValues).qtyType === QTY_TYPE.DSC_FREE,
      isSellAll: !!(values as IMutualFundFormValues).isSellAll,
      operation: (values as IMutualFundFormValues).operation
    })

    if (!validationResponse.isValidForTrading) {
      return
    }

    // Show the Order confirmation modal
    if (
      await confirm({
        title: (
          <>
            <TradeIcon style={{transform: 'rotate(90deg)'}} />
            <Spacer xxs vertical />
            <H2 light>Order Confirmation</H2>
          </>
        ),
        content: (
          <TradeConfirmationContent
            data={values}
            tradingValidationMessages={validationResponse.messages}
          />
        ),
        confirmLabel: (
          <>
            <ConfirmOrderIcon />
            <span>
              Confirm{' '}
              {getLabelWithValue(
                values.operation,
                TRADEORDER_OPERATION_OPTIONS
              )}{' '}
              Order
            </span>
          </>
        ),
        headerClass: (() => {
          switch (values.operation) {
            case TRADEORDER_OPERATION.BUY:
              return css.buyConfirmationHeader
            case TRADEORDER_OPERATION.SELL:
            case TRADEORDER_OPERATION.SELL_ALL:
              return css.sellConfirmationHeader
            case TRADEORDER_OPERATION.SWITCH:
            case TRADEORDER_OPERATION.SWITCH_ALL:
              return css.switchConfirmationHeader
            default:
              throw new Error('Unsupported operation type')
          }
        })()
      })
    ) {
      return createTradeOrder(values, formikBag)
    }
  }

  return [handleSubmit]
}

/**
 * sync account id with form state (required for submission)
 */
export function useSyncSelectEntitiesFormAccountUrl(
  accountUrlFieldName: string
): void {
  const api = useApi()
  const {selection} = useSelectedEntities()
  const accountId = selection.selected?.[0].accounts?.[0] || null

  const [{value: formAccountUrl}, , {setValue: setFormAccountUrl}] =
    useField<string>(accountUrlFieldName)

  useEffect(() => {
    if (
      (formAccountUrl ? extractIdFromUrl(formAccountUrl) : null) !==
      (accountId || null)
    ) {
      setFormAccountUrl(
        accountId
          ? api.buildUrl(AccountEndpoints.pathToResource(accountId))
          : null
      )
    }
  }, [formAccountUrl, accountId])
}
