import React from 'react'
import {AutoSizer} from 'react-virtualized'

import {isEmpty, isEqual} from 'lodash'

import {IChartTableItem} from '@d1g1t/api/models'

import {StandardResponse} from '@d1g1t/lib/standard-response'

import {Flex} from '@d1g1t/shared/components/flex'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Text} from '@d1g1t/shared/components/typography'

import {IChartProps, IStandardChartProps} from './typings'

interface IWithStandardChartState {
  drilldownIndex: number
  drilldownItems: IChartTableItem[]
  drilldownParentItems: IChartTableItem[][]
  drilldownPath: string[]
  drilldownNames: string[]
}

const getDrilldownNameAtIndex = (
  nextProps: IStandardChartProps,
  index: number
) => {
  if (isEmpty(nextProps.groupings)) {
    return nextProps.name
  }

  return `${nextProps.groupings[index].nodeType} ${nextProps.groupings[index].displayName}`
}

const getInitialState = (props: IStandardChartProps) => {
  return {
    drilldownIndex: 0,
    drilldownPath: [getDrilldownNameAtIndex(props, 0)],
    drilldownItems: props.chart.items,
    drilldownParentItems: [],
    drilldownNames: []
  }
}

export const withStandardChart = (
  BaseComponent: React.ComponentType<IChartProps>
) => {
  class WithStandardChart extends React.Component<
    IStandardChartProps,
    IWithStandardChartState
  > {
    state = getInitialState(this.props)

    componentDidUpdate(prevProps: IChartProps) {
      if (isEqual(prevProps.chart.items, this.props.chart.items)) {
        return null
      }

      this.setState(getInitialState(this.props))
    }

    getDrilldownTitleSegments = () => {
      const segments = []
      for (let i = 0; i < this.state.drilldownIndex; i++) {
        segments.push(
          [this.state.drilldownPath[i], this.state.drilldownNames[i]].join(':')
        )
      }

      return segments
    }

    getDrilldownTitle = () => {
      const segments = this.getDrilldownTitleSegments()

      return segments.join(' > ')
    }

    handleDrilldown = (item: IChartTableItem) => {
      const nextDrilldownIndex = this.state.drilldownIndex + 1

      this.setState({
        drilldownParentItems: [
          ...this.state.drilldownParentItems,
          this.state.drilldownItems
        ],
        drilldownIndex: nextDrilldownIndex,
        drilldownItems: item.items,
        drilldownPath: [
          ...this.state.drilldownPath,
          getDrilldownNameAtIndex(this.props, nextDrilldownIndex)
        ],
        drilldownNames: [...this.state.drilldownNames, item.data[0].value]
      })

      this.forceUpdate()
    }

    handleDrillup = () => {
      const items = this.state.drilldownParentItems.pop()
      this.state.drilldownNames.pop()
      this.state.drilldownPath.pop()

      this.setState({
        drilldownItems: items,
        drilldownIndex: this.state.drilldownIndex - 1
      })
    }

    isDrilledDown = () => this.state.drilldownIndex >= 1

    render() {
      const standardResponse = new StandardResponse({
        items: this.state.drilldownItems,
        categories: this.props.chart.categories
      })

      const series = standardResponse.asSeries(
        this.handleDrilldown
      ) as IChartProps['series']

      if (!this.isDrilledDown()) {
        return <BaseComponent series={series} {...this.props} />
      }

      return (
        <AutoSizer>
          {({height, width}) =>
            height > 0 && (
              <>
                <div style={{width}}>
                  <Flex justifySpaceBetween alignCenter>
                    <Text>{this.getDrilldownTitle()}</Text>
                    <Button onClick={this.handleDrillup}>Back</Button>
                  </Flex>
                </div>
                <div style={{width, height: height - 36}}>
                  <BaseComponent series={series} {...this.props} />
                </div>
              </>
            )
          }
        </AutoSizer>
      )
    }
  }

  return WithStandardChart
}
