import {isEqual} from 'lodash'

import {ALL_MODELS} from '@d1g1t/api/models'
import {IControl, ISelectedEntities} from '@d1g1t/typings/general'

import {ISearchResult} from '@d1g1t/shared/containers/search'

import {ALL_MODELS_TO_ISELECTEDENTITIES_KEY_MAP} from './constants'
import {ISelectEntitiesState} from './typings'

/**
 * Returns control object from select entities state
 * @param state - select entities state
 * @param entitySearchResults - entity search results from search API with selected entity IDs
 */
export function buildControlFromState(
  state: DeepPartial<ISelectEntitiesState>,
  entitySearchResults: ISearchResult[]
): IControl {
  const selectedEntities: ISelectedEntities = {}

  if (
    state.selected.length === 1 &&
    entitySearchResults.length === 1 &&
    entitySearchResults[0].modelName === ALL_MODELS.PORTFOLIO
  ) {
    // TODO: Should not have to do this; PS should accept a regular `selectedEntities` object for this case
    return {
      entityId: entitySearchResults[0].entityId
    }
  }

  for (const selection of state.selected) {
    if (state.focused && !isEqual(state.focused, selection)) {
      continue
    }

    const {entityId, accounts, positions} = selection
    const {modelName, printName, name} =
      entitySearchResults.find(
        (searchResult) => searchResult.entityId === entityId
      ) ?? {}

    if (!modelName) {
      continue
    }

    const key = ALL_MODELS_TO_ISELECTEDENTITIES_KEY_MAP[modelName]

    if (!accounts && !positions) {
      if (!selectedEntities[key]) {
        selectedEntities[key] = []
      }
      selectedEntities[key].push(entityId)
    }

    const accountsOrPositions = []
    if (accounts) {
      accountsOrPositions.push(...accounts)
    }
    if (positions) {
      accountsOrPositions.push(...positions)
    }

    if (accountsOrPositions.length > 0) {
      if (!selectedEntities.accountsOrPositions) {
        selectedEntities.accountsOrPositions = []
      }
      selectedEntities.accountsOrPositions.push(accountsOrPositions)

      if (!selectedEntities.accountDisplayNames) {
        selectedEntities.accountDisplayNames = []
      }
      selectedEntities.accountDisplayNames.push(printName ?? name)
    }
  }
  return {selectedEntities}
}
