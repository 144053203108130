import React, {useContext, useMemo} from 'react'

import {capitalize, groupBy, isEmpty, map} from 'lodash'

import {ConfigContext} from '@d1g1t/config/context'

import {ALL_MODELS} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {selectionForAccount} from '@d1g1t/lib/url'

import {Divider} from '@d1g1t/shared/components/mui/divider'
import {IPaperProps, Paper} from '@d1g1t/shared/components/mui/paper'
import {H3, P, Text} from '@d1g1t/shared/components/typography'

import {modelDisplayGroupOrderedBySearchScore} from '../helpers'
import {highlightMatch, SearchSuggestionItem} from '../search-suggestion-item'
import {ISearchResult} from '../typings'

import * as css from './style.scss'

export interface ISearchSuggestionsProps extends IPaperProps {
  /**
   * Currently "selected" item, used to make tab selections
   */
  selectedItem: ISearchResult
  /**
   * Current search text
   */
  text: string
  /**
   * Search results to display
   */
  searchResults: ISearchResult[]
  /**
   * true when search API is fetching
   */
  loading: boolean
  /**
   * When true, search results become links and do not emit `onResultSelect`
   */
  linkToResource?: boolean
  /**
   * When true, results will not be grouped by model
   */
  disableGrouping?: boolean
  /**
   * Show additional details next to search results currently includes:
   *   - City
   *   - AUM
   */
  showDetails: boolean
  /**
   * Shows Funds Available instead of AUM first. MUST BE used in conjuction w/ `showDetails`.
   */
  showDetailsFundsAvailable: boolean
  /**
   * Custom title on the top right of search suggestions
   */
  detailsTitle: string
  /**
   * Callback when item is selected
   */

  onItemClick?(searchResult: ISearchResult)
  /**
   * Show in search results, above content
   */
  header?: React.ReactNode
  /**
   * The header for grouped options, mapped with ALL_MODELS
   */
  groupedHeader?: {[key: string]: React.ReactNode}
  /**
   * Show in search results, below content
   */
  footer?: React.ReactNode
  /**
   * Search does not accept children
   */
  children?: never
  /**
   * Should account results be grouped by client? Defaults to true
   */
  groupAccountsByClient?: boolean
  /**
   * Extra filters for filtering the search result
   */
  extraFilters?: (props: {onChange: (filters) => void}) => React.ReactNode
  onChangeExtraFilters?: (filters: {
    tradingType: string[]
    tradingCurrency: string[]
    exchangeCodes: string[]
  }) => void
  /**
   * Overrides the options with a custom React component, mapped with ALL_MODELS
   */
  optionRenderOverride?: {
    [key: string]: (props: {
      option: ISearchResult
      text: string
    }) => React.ReactNode
  }
  /**
   * Position of the dropdown to the left or right from the search input
   */
  position?: 'left' | 'right'
}

function searchResultId(searchResult: ISearchResult): string {
  return searchResult.lookupId || searchResult.entityId
}

export const SearchSuggestions: React.FC<ISearchSuggestionsProps> = (props) => {
  const handleItemClick = (
    searchResult: ISearchResult,
    event: React.MouseEvent<any>
  ) => {
    event.stopPropagation()
    if (!props.linkToResource) {
      event.preventDefault()
    }

    if (typeof props.onItemClick === 'function') {
      props.onItemClick(searchResult)
    }
  }

  const {
    text,
    searchResults,
    loading,
    className,
    children,
    showDetails,
    showDetailsFundsAvailable,
    onItemClick,
    linkToResource,
    disableGrouping,
    header,
    footer,
    selectedItem,
    detailsTitle,
    groupAccountsByClient,
    extraFilters,
    position = 'right',
    ...paperProps
  } = props

  if (!searchResults) {
    return null
  }

  return (
    <Paper
      data-testid='container-search-suggestions'
      className={classNames(css.container, className, {
        [css.containerWithFilter]: !!extraFilters,
        [css.containerToLeft]: position === 'left'
      })}
      {...paperProps}
    >
      <div
        className={classNames(css.containerWrapper, {
          [css.left]: !!extraFilters
        })}
      >
        {searchResults.length === 0 && loading && <P indent>Searching...</P>}
        {searchResults.length === 0 && !loading && <P indent>No results</P>}
        <div className={css.optionsContainer}>
          {searchResults.length > 0 && props.header}
          {disableGrouping ? (
            searchResults.length > 0 && (
              <div className={css.searchSuggestionsContainer}>
                <div className={classNames(css.group, css.ungrouped)}>
                  {props.searchResults.map((item) => (
                    <SearchSuggestionItem
                      key={searchResultId(item)}
                      selected={
                        selectedItem &&
                        searchResultId(selectedItem) === searchResultId(item)
                      }
                      showDetails={props.showDetails}
                      showDetailsFundsAvailable={
                        props.showDetailsFundsAvailable
                      }
                      text={props.text}
                      searchResult={item}
                      to={null}
                      onItemClick={handleItemClick}
                      optionRenderOverride={
                        props.optionRenderOverride
                          ? props.optionRenderOverride[item.modelName]
                          : undefined
                      }
                    />
                  ))}
                </div>
              </div>
            )
          ) : (
            <GroupedSearchSuggestions
              {...props}
              onItemClick={handleItemClick}
            />
          )}
        </div>

        {props.footer}
      </div>
      {extraFilters && (
        <>
          <Divider orientation='vertical' flexItem />
          <div className={css.right}>
            {extraFilters({
              onChange: (filters) => {
                props.onChangeExtraFilters(filters)
              }
            })}
          </div>
        </>
      )}
    </Paper>
  )
}

const GroupedSearchSuggestions: React.FC<
  Omit<ISearchSuggestionsProps, 'onItemClick'> & {
    onItemClick: (
      searchResult: ISearchResult,
      event: React.MouseEvent<any>
    ) => any
  }
> = ({searchResults, ...props}) => {
  const groupedSearchResults = useMemo(() => {
    return groupBy(searchResults, 'modelName')
  }, [searchResults])

  return (
    <div className={css.searchSuggestionsContainer}>
      {modelDisplayGroupOrderedBySearchScore(groupedSearchResults).map(
        (modelName) => (
          <SearchSuggestionGroup
            key={modelName}
            modelName={modelName}
            selectedItem={props.selectedItem}
            groupAccountsByClient={props.groupAccountsByClient}
            searchResults={groupedSearchResults[modelName]}
            showDetails={props.showDetails}
            showDetailsFundsAvailable={props.showDetailsFundsAvailable}
            detailsTitle={props.detailsTitle}
            text={props.text}
            linkToResource={props.linkToResource}
            onItemClick={props.onItemClick}
            optionRenderOverride={
              props.optionRenderOverride
                ? props.optionRenderOverride[modelName]
                : undefined
            }
            groupedHeader={
              props.groupedHeader ? props.groupedHeader[modelName] : undefined
            }
          />
        )
      )}
    </div>
  )
}

interface ISearchSuggestionGroupProps {
  modelName: ALL_MODELS
  selectedItem: ISearchResult
  searchResults: ISearchResult[]
  showDetails: boolean
  showDetailsFundsAvailable: boolean
  groupAccountsByClient: boolean
  detailsTitle: string
  text: string
  linkToResource: boolean
  groupedHeader?: React.ReactNode
  onItemClick: (
    searchResult: ISearchResult,
    event: React.MouseEvent<any>
  ) => any
  /**
   * Overrides the options with a custom React component
   */
  optionRenderOverride?: (props: {
    option: ISearchResult
    text: string
  }) => React.ReactNode
}

const SearchSuggestionGroup: React.FC<ISearchSuggestionGroupProps> = (
  props
) => {
  const config = useContext(ConfigContext)

  if (isEmpty(props.searchResults)) {
    return null
  }

  if (props.modelName === ALL_MODELS.ACCOUNT && props.groupAccountsByClient) {
    return <AccountSearchSuggestionGroup {...props} />
  }

  const groupTitle = (() => {
    switch (props.modelName) {
      case ALL_MODELS.INDIVIDUAL:
        return 'Clients'
      case ALL_MODELS.ACCOUNTGROUP:
        return 'Account Groups'
      case ALL_MODELS.CLASSSERIES:
        return 'Funds'
      case ALL_MODELS.INVESTMENTMANDATE:
        return 'Investment Mandates'
      case ALL_MODELS.PERSON:
        return 'Contacts'
      case ALL_MODELS.TAG:
        return 'Tags'
      case ALL_MODELS.PERSONGROUP:
        return 'Contact Groups'
      default:
        return `${capitalize(props.modelName)}s`
    }
  })()

  const toLink = (item: ISearchResult) => {
    if (props.linkToResource && props.modelName === ALL_MODELS.ACCOUNT) {
      return config.modelPath(
        props.modelName,
        selectionForAccount(item.lookupId, item.client)
      )
    }

    if (props.linkToResource) {
      return config.modelPath(props.modelName, item.lookupId)
    }

    return null
  }

  return (
    <div className={css.group}>
      <H3 className={css.header}>{groupTitle}</H3>
      <div className={css.groupedHeader}>{props.groupedHeader}</div>
      {props.searchResults.map((item) => (
        <SearchSuggestionItem
          showDetails={props.showDetails}
          showDetailsFundsAvailable={props.showDetailsFundsAvailable}
          selected={
            props.selectedItem &&
            searchResultId(props.selectedItem) === searchResultId(item)
          }
          key={searchResultId(item)}
          text={props.text}
          searchResult={item}
          to={toLink(item)}
          onItemClick={props.onItemClick}
          optionRenderOverride={props.optionRenderOverride}
        />
      ))}
    </div>
  )
}

const AccountSearchSuggestionGroup: React.FC<ISearchSuggestionGroupProps> = (
  props
) => {
  const groupedItems = groupBy(props.searchResults, 'clientPrintName')

  return (
    <div className={css.accounts}>
      <H3 className={css.accountsHeader}>Accounts</H3>
      {props.showDetails && (
        <Text alignRight className={css.fundsAvailableHeader}>
          {props.detailsTitle || 'Cash Available For Trading'}
        </Text>
      )}
      <Divider className={css.divider} />
      {map(groupedItems, (accountGroup, itemKey) => (
        <div className={css.group} key={itemKey}>
          <H3 className={css.header}>{highlightMatch(itemKey, props.text)}</H3>
          {accountGroup.map((account) => (
            <SearchSuggestionItem
              account
              selected={
                props.selectedItem &&
                props.selectedItem.entityId === account.entityId
              }
              key={account.entityId}
              showDetails={props.showDetails}
              showDetailsFundsAvailable={props.showDetailsFundsAvailable}
              text={props.text}
              searchResult={account}
              onItemClick={props.onItemClick}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

SearchSuggestions.defaultProps = {
  groupAccountsByClient: true
}
