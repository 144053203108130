import React from 'react'

import {
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  TRADEORDER_DIVIDEND_TREATMENT_OPTIONS,
  TRADEORDER_EXPIRY_TYPE,
  TRADEORDER_EXPIRY_TYPE_OPTIONS,
  TRADEORDER_QTY_TYPE_OPTIONS,
  TRADEORDER_TYPE_OPTIONS
} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {StandardResponseItem} from '@d1g1t/lib/standard-response'
import {getLabelWithValue} from '@d1g1t/lib/value-label'

import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'
import {EditableCell} from '@d1g1t/shared/containers/standard-table/components/custom-types/editable-cell'
import {EditableEnum} from '@d1g1t/shared/containers/standard-table/components/custom-types/editable-enum'
import {TradeOrderQtyTypeValue} from '@d1g1t/shared/containers/standard-table/components/custom-types/enum-value'
import {DataCellValueFormatted} from '@d1g1t/shared/containers/standard-table/components/data-cell/data-cell-value'
import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'

import {CATEGORY_IDS} from '../../constants'

import * as dataCellCss from '@d1g1t/shared/containers/standard-table/components/data-cell/styles.scss'
import * as commonCss from '@d1g1t/shared/containers/standard-table/styles.scss'

const createEditableTradeOrderEnum = (
  options: IValueLabelOption[]
): React.FC<IDataCellProps> => {
  return (props) => {
    return <EditableEnum {...props} options={options} />
  }
}

export const EditableTradeOrderQtyType: React.FC<IDataCellProps> = (props) => {
  if (
    props.item.getClosestKey(CATEGORY_IDS.TRADING_TYPE) ===
    FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY
  ) {
    return <TradeOrderQtyTypeValue {...props} />
  }

  return <EditableEnum {...props} options={TRADEORDER_QTY_TYPE_OPTIONS} />
}

export const EditableTradeOrderType = createEditableTradeOrderEnum(
  TRADEORDER_TYPE_OPTIONS
)

export const EditableTradeOrderTradeDate = createEditableTradeOrderEnum(
  TRADEORDER_EXPIRY_TYPE_OPTIONS.filter((option) => {
    return [TRADEORDER_EXPIRY_TYPE.DAY, TRADEORDER_EXPIRY_TYPE.GTEM].includes(
      option.value
    )
  })
)

export const EditableTradeOrderDividendTreatment = createEditableTradeOrderEnum(
  TRADEORDER_DIVIDEND_TREATMENT_OPTIONS
)

export const EditableTradeOrderExpiryType = createEditableTradeOrderEnum(
  TRADEORDER_EXPIRY_TYPE_OPTIONS.filter(
    (option) => option.value !== TRADEORDER_EXPIRY_TYPE.GTD
  )
)

export const EditableMarketDropdown: React.FC<IDataCellProps> = (props) => {
  let instrumentItem: StandardResponseItem = props.item

  while (
    instrumentItem.itemParent &&
    !instrumentItem.getValue(CATEGORY_IDS.SYMBOL)
  ) {
    instrumentItem = instrumentItem.itemParent
  }

  const cellData = instrumentItem.getDatum(props.category.id)

  const options = cellData.options?.allowedValues.map((value) => {
    return {
      value: value.key,
      label: value.value
    }
  })

  const handleChange = (value) => {
    if (cellData.value === value) {
      return
    }

    props.onChange(
      props.item,
      props.category,
      cellData.options.allowedValues.find(
        (allowedValue) => allowedValue.key === value
      ).key,
      value
    )
  }

  const value = (() => {
    if (options) {
      return getLabelWithValue(cellData.key, options)
    }

    return cellData.value
  })()

  if (!options || instrumentItem.id !== props.item.id) {
    return <div className={commonCss.innerCell}>{value}</div>
  }

  return (
    <ValueLabelSelect
      size='compact'
      whiteBackground
      value={cellData.key}
      options={options}
      onChange={handleChange}
      data-testid='select-standard-table-inline-enum'
      data-category-id={props.category.id}
      data-item-id={props.item.id}
    />
  )
}

export const EditableTradeOrderQuantity: React.FC<IDataCellProps> = (props) => {
  if (
    props.item.getValue(CATEGORY_IDS.IS_SELL_ALL) &&
    (props.item.getValue(CATEGORY_IDS.IS_FUND) ||
      props.item.getKey(CATEGORY_IDS.TRADING_TYPE) ===
        FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND)
  ) {
    return (
      <div className={classNames(commonCss.innerCell, dataCellCss.number)}>
        ALL
      </div>
    )
  }

  if (!props.item.isLeafNode) {
    return (
      <DataCellValueFormatted
        getFormatter={props.getFormatter}
        value={props.item.getValue(props.category.id)}
        valueType={props.category.valueType}
        valueOptions={props.category.options}
      />
    )
  }

  return <EditableCell {...props} />
}
