import React, {useMemo, useRef} from 'react'

import produce from 'immer'

import {ALL_MODELS} from '@d1g1t/api/models'

import {calculatedWeightsValues} from '@d1g1t/lib/rebalance'
import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'

import {Card} from '@d1g1t/shared/components/card'
import {Flex, FlexChild} from '@d1g1t/shared/components/flex'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {ConcentrationChart} from '@d1g1t/shared/components/standard-chart/concentration'
import {DriftChart} from '@d1g1t/shared/components/standard-chart/drift'
import {StateBlock} from '@d1g1t/shared/components/state-block'
import {H3, H6, Text} from '@d1g1t/shared/components/typography'
import {Search} from '@d1g1t/shared/containers/search'
import {SearchWrapper} from '@d1g1t/shared/containers/search/search-wrapper'
import {ISearchResult} from '@d1g1t/shared/containers/search/typings'
import {SecuritySearchFilter} from '@d1g1t/shared/containers/security-search-filter'
import {StandardTable} from '@d1g1t/shared/containers/standard-table'
import {IStandardTableCategory} from '@d1g1t/shared/containers/standard-table/typings'
import {FormattedDate} from '@d1g1t/shared/wrappers/formatter'

import {ChartSideContainer} from '@d1g1t/advisor/components/chart-side-container'
import {CHART_OPTIONS} from '@d1g1t/advisor/containers/rebalance/constants'
import {SecuritySearchOption} from '@d1g1t/advisor/containers/security-search-option'
import {SecuritySearchOptionHeader} from '@d1g1t/advisor/containers/security-search-option/header'
import {RebalanceTotalCell} from '@d1g1t/advisor/containers/standard-table/components/custom-types/rebalance-total'

import {CATEGORY_IDS} from './constants'
import {IRebalanceAccountProps} from './typings'

import * as css from './style.scss'

const CATEGORIES_TOTAL_OVERRIDE = {
  [CATEGORY_IDS.TARGET_WEIGHT]: RebalanceTotalCell,
  [CATEGORY_IDS.WEIGHT_CHANGE]: RebalanceTotalCell
}

export const RebalanceAccount: React.FC<IRebalanceAccountProps> = (props) => {
  const changes = useRef<
    Dictionary<{
      item: StandardResponseItem
      category: IStandardTableCategory
      value: any
    }>
  >({})
  const updateTimer = useRef(null)

  const handleCellValueChange = (
    item: StandardResponseItem,
    category: IStandardTableCategory,
    value: any
  ) => {
    props.onCancelRebalanceRequest()
    if (updateTimer.current) {
      clearTimeout(updateTimer.current)
    }

    changes.current[item.id] = {
      item,
      category,
      value
    }

    updateTimer.current = setTimeout(() => {
      const listOfChanges = Object.values(changes.current).map((change) => ({
        value: change.value,
        category: change.category,
        standardItem: new StandardResponseItem(change.item),
        overviewTotalCurrentValue: props.overviewTotalCurrentValue
      }))

      const newResponse = produce(props.rebalanceResponse, (draft) => {
        draft.accountTables[props.index] = calculatedWeightsValues(
          new StandardResponse(props.accountTable),
          listOfChanges
        )
      })

      props.onUpdateData(newResponse)

      updateTimer.current = null
      changes.current = {}

      props.onFetchData(newResponse)
    }, 500)
  }

  const handleAddSecurity = (searchResult: ISearchResult) => {
    const newRebalanceTableResponse = produce(
      props.rebalanceResponse,
      (draft) => {
        draft.accountTables[props.index].items.push({
          id: searchResult.entityId,
          data: [
            {categoryId: CATEGORY_IDS.NAME, value: searchResult.name},
            {categoryId: CATEGORY_IDS.TARGET_VALUE, value: 0},
            {categoryId: CATEGORY_IDS.TARGET_WEIGHT, value: 0},
            {categoryId: CATEGORY_IDS.VALUE_CHANGE, value: 0},
            {categoryId: CATEGORY_IDS.WEIGHT_CHANGE, value: 0},
            {categoryId: CATEGORY_IDS.INSTRUMENT_URL, value: searchResult.url}
          ]
        })
      }
    )

    props.onUpdateData(newRebalanceTableResponse)
    props.onFetchData(newRebalanceTableResponse)
  }

  const selectChart = (selectedChart: CHART_OPTIONS) => () => {
    props.onSelectChart(selectedChart)
  }

  const renderChart = useMemo(() => {
    const selectedChart = props.referencePortfolio
      ? props.selectedChart
      : CHART_OPTIONS.TARGET_WEIGHTS

    if (selectedChart === CHART_OPTIONS.TARGET_WEIGHTS) {
      return (
        <ConcentrationChart
          data-concentration-chart
          chart={props.accountChart.concentrationTable({
            weightCategoryId: CATEGORY_IDS.TARGET_WEIGHT
          })}
          name='Concentration'
        />
      )
    }

    return (
      <DriftChart
        chart={props.accountChart.concentrationTable({
          weightCategoryId:
            selectedChart === CHART_OPTIONS.TARGET_DRIFT
              ? CATEGORY_IDS.TARGET_DRIFT
              : CATEGORY_IDS.CURRENT_DRIFT,
          filterZeroValues: false
        })}
        name='Drift'
      />
    )
  }, [props.referencePortfolio, props.selectedChart, props.accountChart])

  const table = useMemo((): StandardResponse => {
    if (!props.accountTable) {
      return null
    }

    let table = props.accountTable.updateCategoryOptions(
      [
        CATEGORY_IDS.CURRENT_WEIGHT,
        CATEGORY_IDS.TARGET_DRIFT,
        CATEGORY_IDS.CURRENT_DRIFT
      ],
      {
        decimals: 2
      }
    )

    if (!props.account.isTradable) {
      table = table.updateCategoryOptions(
        [
          CATEGORY_IDS.TARGET_WEIGHT,
          CATEGORY_IDS.TARGET_VALUE,
          CATEGORY_IDS.VALUE_CHANGE,
          CATEGORY_IDS.WEIGHT_CHANGE
        ],
        {editable: false}
      )
    }

    return table
  }, [props.accountTable, props.account.isTradable])

  return (
    <Card flexColumn>
      <Flex justifySpaceBetween>
        <Flex column>
          <Flex alignCenter>
            <H3 noMargin>
              {props.account.name
                ? `${props.account.name} (${props.account.firmProvidedKey})`
                : props.account.firmProvidedKey}
            </H3>
            {!props.account.isTradable && (
              <>
                <Spacer xxs vertical />
                <StateBlock text='NOT TRADABLE' />
              </>
            )}
          </Flex>
          <Text noMargin className={css.clientName}>
            {props.account.client.displayName}
          </Text>
          <H6 noMargin>
            Last Balanced:{' '}
            <FormattedDate value={props.account.lastRebalancingDate} />
          </H6>
        </Flex>
        <Flex>
          <SearchWrapper className={css.search}>
            <Search
              clearOnResultSelect
              disableGrouping
              isTradable
              placeholder='Type the name or ID of the security you want to add'
              searchBy={[ALL_MODELS.INSTRUMENT]}
              onResultSelect={handleAddSecurity}
              extraFilters={SecuritySearchFilter}
              position='left'
              header={<SecuritySearchOptionHeader />}
              optionRenderOverride={{
                [ALL_MODELS.INSTRUMENT]: SecuritySearchOption
              }}
            />
          </SearchWrapper>
        </Flex>
      </Flex>
      <Flex grow={1}>
        <FlexChild grow={1}>
          <StandardTable
            id={props.id}
            table={table}
            disableSorting
            defaultNameColumnWidth={250}
            defaultColumnWidth={100}
            minColumnWidth={80}
            categoriesTotalOverride={CATEGORIES_TOTAL_OVERRIDE}
            onCellValueChange={handleCellValueChange}
          />
        </FlexChild>
        {props.showGraphs && (
          <ChartSideContainer data-graph-container>
            {props.referencePortfolio && (
              <Flex justifyCenter>
                <Button
                  contained={
                    props.selectedChart === CHART_OPTIONS.TARGET_WEIGHTS
                  }
                  onClick={selectChart(CHART_OPTIONS.TARGET_WEIGHTS)}
                >
                  Post Weights
                </Button>
                <Button
                  contained={
                    props.selectedChart === CHART_OPTIONS.CURRENT_DRIFT
                  }
                  onClick={selectChart(CHART_OPTIONS.CURRENT_DRIFT)}
                >
                  Current Drift
                </Button>
                <Button
                  contained={props.selectedChart === CHART_OPTIONS.TARGET_DRIFT}
                  onClick={selectChart(CHART_OPTIONS.TARGET_DRIFT)}
                >
                  Post Drift
                </Button>
              </Flex>
            )}
            <FlexChild grow={1}>{renderChart}</FlexChild>
          </ChartSideContainer>
        )}
      </Flex>
    </Card>
  )
}
