import React, {useMemo, useState} from 'react'

import {useApiQuery} from 'fairlight'

import BackArrowIcon from '@material-ui/icons/ArrowBack'

import {RuleFilterEndpoints} from '@d1g1t/api/endpoints'
import {FILTERCRITERION_NODE_TYPE, IFilterRule} from '@d1g1t/api/models'

import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Modal} from '@d1g1t/shared/components/modal'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H3} from '@d1g1t/shared/components/typography'
import {
  ErrorBoundary,
  ModalContentsErrorFallback
} from '@d1g1t/shared/wrappers/error-boundary'

import {RuleFilterForm} from './rule-filter-form'
import {RuleFilterList} from './rule-filter-list'

export interface IAppliedRuleFilters {
  /**
   * The entity ID to which the filter(s) were applied.
   */
  entityId: string
  /**
   * Applied rule-based filter suffix
   */
  clientChipAppliedFilterSuffix?: string
  /**
   * Applied rule-based filter tooltip text
   */
  clientChipAppliedFilterTooltipText?: string
  /**
   * Set of filters applied to `entityId`
   */
  appliedFilters: IFilterRule[]
}

/**
 * Props for the Entity Filter modal in Manage Clients.
 */
interface IRuleFilterProps {
  onClose(): void
}

export const DEFAULT_FILTER_CRITERION_NODES: FILTERCRITERION_NODE_TYPE[] = [
  FILTERCRITERION_NODE_TYPE.HOUSEHOLD,
  FILTERCRITERION_NODE_TYPE.CLIENT,
  FILTERCRITERION_NODE_TYPE.ACCOUNT,
  FILTERCRITERION_NODE_TYPE.CUSTODIAN_ACCOUNT,
  FILTERCRITERION_NODE_TYPE.SLEEVE,
  FILTERCRITERION_NODE_TYPE.POSITION,
  FILTERCRITERION_NODE_TYPE.SECURITY,
  FILTERCRITERION_NODE_TYPE.PORTFOLIO
]

/**
 * Entity Filter modal component.
 */
export const RuleFilterModal: React.FC<IRuleFilterProps> = (props) => {
  const [create, setCreate] = useState(false)

  const [ruleFilters, ruleFiltersQueryActions] = useApiQuery(
    RuleFilterEndpoints.list(),
    {
      fetchPolicy: 'cache-and-fetch'
    }
  )

  const [selectedRuleFilterUrl, setSelectedRuleFilterUrl] =
    useState<string>(null)

  const selectedRuleFilter = useMemo(() => {
    if (selectedRuleFilterUrl && ruleFilters.data) {
      return ruleFilters.data.results.find(
        ({url}) => selectedRuleFilterUrl === url
      )
    }
  }, [selectedRuleFilterUrl, ruleFilters.data])

  const updateFilters = (newFilter: IFilterRule) => {
    if (create) {
      ruleFiltersQueryActions.setData({
        ...ruleFilters.data,
        results: [...ruleFilters.data.results, newFilter]
      })

      setSelectedRuleFilterUrl(newFilter.url)
      setCreate(false)

      return
    }

    ruleFiltersQueryActions.setData({
      ...ruleFilters.data,
      results: ruleFilters.data.results.map((filter) =>
        filter.url === selectedRuleFilterUrl ? newFilter : filter
      )
    })
  }

  const title = (() => {
    if (create || selectedRuleFilterUrl) {
      return (
        <>
          <Tooltip title='Back to rule filter list'>
            <Button
              primary
              contained
              small
              onClick={() => {
                if (create) {
                  setCreate(false)

                  return
                }

                setSelectedRuleFilterUrl(null)
              }}
            >
              <BackArrowIcon fontSize='small' />
              Rule Filters
            </Button>
          </Tooltip>
          <Spacer xs vertical />
          <H3>{`${create ? 'Create' : 'Edit'} Rule Filter`}</H3>
        </>
      )
    }

    return 'Manage Rule Filters'
  })()

  return (
    <Modal title={title} open fullscreen onClose={props.onClose}>
      <LoadingContainer loading={ruleFilters.loading}>
        <ErrorBoundary
          resetId='no-reset'
          fallback={<ModalContentsErrorFallback onClose={props.onClose} />}
        >
          {selectedRuleFilter || create ? (
            <RuleFilterForm
              create={create}
              ruleFilter={selectedRuleFilter}
              onSave={(filter) => {
                updateFilters(filter)
              }}
              onCancel={() => {
                if (create) {
                  setCreate(false)
                }

                setSelectedRuleFilterUrl(null)
              }}
            />
          ) : (
            <RuleFilterList
              ruleFilters={ruleFilters}
              onSelectRuleFilter={(filter) => {
                setCreate(false)
                setSelectedRuleFilterUrl(filter.url)
              }}
              onAddRuleFilter={() => setCreate(true)}
              onDeleteRuleFilter={(filter) => {
                ruleFiltersQueryActions.setData({
                  ...ruleFilters.data,
                  count: ruleFilters.data.count - 1,
                  results: ruleFilters.data.results.filter(
                    ({url}) => filter.url !== url
                  )
                })
              }}
            />
          )}
        </ErrorBoundary>
      </LoadingContainer>
    </Modal>
  )
}
