import React from 'react'

import {Paper} from '@material-ui/core'
import MuiAutocomplete, {AutocompleteProps} from '@material-ui/lab/Autocomplete'

import {useControlState} from '@d1g1t/shared/components/control-state'

import {Divider} from '../divider'

export interface IAutocompleteProps<
  T,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  value?: any // for some reason it's missing in AutocompleteProps
  /**
   * These options are rendered below the search options.
   * Typically used for a "Create..." actions that can be accessed
   * from the autocomplete dropdown.
   */
  staticMenuItems?: React.ReactNode
  /**
   * Minimum width of Autocomplete menu items
   */
  minMenuWidth?: number
}

export const Autocomplete = React.forwardRef(
  <T,>(
    props: IAutocompleteProps<T, boolean, boolean, boolean>,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    const controlState = useControlState()
    const {
      staticMenuItems,
      minMenuWidth,
      PaperComponent: CustomPaperComponent,
      ...muiProps
    } = props

    return (
      <MuiAutocomplete
        ref={ref}
        {...muiProps}
        PaperComponent={
          staticMenuItems
            ? ({children, ...other}) => (
                <Paper
                  {...other}
                  style={minMenuWidth ? {minWidth: minMenuWidth} : undefined}
                >
                  {children}
                  <Divider />
                  {staticMenuItems}
                </Paper>
              )
            : CustomPaperComponent
        }
        disabled={props.disabled || controlState.loading}
        data-testid='autocomplete-text-field'
      />
    )
  }
)
