import React, {memo, useMemo} from 'react'

import {useInternalTheme} from '@d1g1t/config/theme/internal-theme'

import {CHARTTABLEOPTIONS_STATUS} from '@d1g1t/api/models'

import {Flex} from '@d1g1t/shared/components/flex'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {Text} from '@d1g1t/shared/components/typography'

import {SmallCircleIcon, SmallFullIcon} from '../lib'

interface IStatusCellProps {
  status: CHARTTABLEOPTIONS_STATUS
  categoryId: string
  warnings: string[]
}

export const StatusCell: React.FC<IStatusCellProps> = memo(
  ({categoryId, status, warnings}) => {
    const {statusColourMap} = useInternalTheme()

    const color = statusColourMap[status]

    const icon = useMemo(() => {
      if (status !== CHARTTABLEOPTIONS_STATUS.NEUTRAL) {
        return <SmallFullIcon color={color} />
      }

      return <SmallCircleIcon />
    }, [color, status])

    // If warnings returns undefined or is an empty array then do not show the tooltip
    const showTooltip =
      warnings !== undefined && Array.isArray(warnings) && warnings.length > 0

    return (
      <Flex>
        <Spacer vertical xxs />
        {showTooltip ? (
          <Tooltip
            interactive
            placement='right'
            title={
              <>
                {warnings.map((warning, idx) => (
                  <Text key={idx}>{warning}</Text>
                ))}
              </>
            }
          >
            <div>{icon}</div>
          </Tooltip>
        ) : (
          icon
        )}
      </Flex>
    )
  }
)
