import React, {useEffect} from 'react'

import {useApi, useApiQuery} from 'fairlight'
import {useFormikContext} from 'formik'
import {isNumber} from 'lodash'

import {InstrumentEndpoints, PositionEndpoints} from '@d1g1t/api/endpoints'
import {
  ALL_MODELS,
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  TRADEORDER_COMMISSION_TYPE,
  TRADEORDER_DIVIDEND_TREATMENT_OPTIONS,
  TRADEORDER_OPERATION,
  TRADEORDER_PRO_STATUS_OPTIONS,
  TRADEORDER_QTY_TYPE,
  TRADEORDER_QTY_TYPE_OPTIONS
} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {FlexGridItem} from '@d1g1t/shared/components/flex'
import {CheckboxField} from '@d1g1t/shared/components/form-field/checkbox-field'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {SearchInputField} from '@d1g1t/shared/components/form-field/search-input-field'
import {ValueLabelSelectField} from '@d1g1t/shared/components/form-field/value-select-field'
import {
  CurrencyInput,
  SixDecimalInput
} from '@d1g1t/shared/components/formatted-input'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {ISearchResult} from '@d1g1t/shared/containers/search/typings'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'

import {IMutualFundsProps} from '../'
import {CommissionsSection} from '../../commissions-section'
import {TradeSelectField} from '../../trade-select-field'
import {
  IMutualFundFormValues,
  MUTUAL_FUND_FIELD_NAMES,
  QTY_TYPE
} from '../typings'

import * as css from '../../style.scss'

function fundInstrumentsFilter(searchResult: ISearchResult) {
  return (
    searchResult.tradingType === FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND
  )
}

interface IEditMutualFundsFormProps
  extends Pick<IMutualFundsProps, 'isTradable'> {
  editMode?: boolean
}

export const MutualFundsForm: React.FC<IEditMutualFundsFormProps> = (props) => {
  const api = useApi()
  const {firmConfiguration} = useFirmConfiguration()
  const {handleUnexpectedError} = useErrorHandler()
  const {showSnackbar} = useSnackbar()

  const {
    values: {
      instrumentUrl,
      fundCodeUrl,
      operation,
      accountUrl,
      isSellAll,
      fundCode,
      cusip,
      qtyType,
      qty
    },
    setFieldValue,
    isSubmitting
  } = useFormikContext<IMutualFundFormValues>()

  const [instrument] = useApiQuery(
    instrumentUrl &&
      InstrumentEndpoints.findById(extractIdFromUrl(instrumentUrl))
  )

  const [instrumentSwitchInto] = useApiQuery(
    fundCodeUrl && InstrumentEndpoints.findById(extractIdFromUrl(fundCodeUrl))
  )

  const isSwitch = [
    TRADEORDER_OPERATION.SWITCH,
    TRADEORDER_OPERATION.SWITCH_ALL
  ].includes(operation)

  const QTY_TYPE_OPTIONS = (() => {
    if (
      [TRADEORDER_OPERATION.SELL, TRADEORDER_OPERATION.SWITCH].includes(
        operation
      )
    ) {
      return [
        ...TRADEORDER_QTY_TYPE_OPTIONS,
        {value: QTY_TYPE.DSC_FREE, label: 'Deferred Sales Charge Free'}
      ]
    }
    return TRADEORDER_QTY_TYPE_OPTIONS
  })()

  /**
   * Auto populate fund code field after selecting a fund from
   * search results
   */
  useEffect(() => {
    if (!instrument.data) {
      return
    }

    /**
     * cusip is the fund code you switch out of in a switch trade
     * order operation
     */
    setFieldValue(
      isSwitch
        ? MUTUAL_FUND_FIELD_NAMES.cusip
        : MUTUAL_FUND_FIELD_NAMES.fundCode,
      instrument.data.fundCode
    )

    setFieldValue(MUTUAL_FUND_FIELD_NAMES.instrumentName, instrument.data?.name)
  }, [instrument.data])

  useEffect(() => {
    if (!instrumentSwitchInto.data) {
      return
    }

    if (!isSwitch) {
      return
    }

    setFieldValue(
      MUTUAL_FUND_FIELD_NAMES.fundCode,
      instrumentSwitchInto.data.fundCode
    )
  }, [instrumentSwitchInto.data])

  const checkIfFundIsHeldForSellOrSwitchAll = async () => {
    try {
      const response = await api.request(
        accountUrl &&
          PositionEndpoints.list({
            asOfDate: firmConfiguration.data?.latestDataAvailable,
            account: extractIdFromUrl(accountUrl),
            instrument: instrument.data.firmProvidedKey
          })
      )

      if (response.results.length === 0) {
        showSnackbar({
          variant: 'error',
          message: 'No units held for the current fund.'
        })
        return
      }
    } catch (error) {
      handleUnexpectedError(error, 'Cannot get amount of units held.')
    }
  }

  /**
   * When sell/switch all is checked then disable qty field and set
   * value to `ALL`. When sell/switch all is unchecked then re-enable
   * qty field and set value to null.
   */
  useEffect(() => {
    if (isSellAll && accountUrl && instrument.data?.firmProvidedKey) {
      checkIfFundIsHeldForSellOrSwitchAll()
    }
  }, [isSellAll, instrument.data?.firmProvidedKey])

  /**
   * Swap fund code field value with cusip when doing a switch trade order
   * and vice versa when doing other trade orders
   */
  useEffect(() => {
    if (isSwitch && fundCode) {
      setFieldValue(MUTUAL_FUND_FIELD_NAMES.cusip, fundCode)
      setFieldValue(MUTUAL_FUND_FIELD_NAMES.fundCode, '')
    }

    if (cusip && !isSwitch) {
      setFieldValue(MUTUAL_FUND_FIELD_NAMES.fundCode, cusip)
      setFieldValue(MUTUAL_FUND_FIELD_NAMES.cusip, '')
    }

    /**
     * Marks the operation as sell or switch all
     */
    setFieldValue(
      MUTUAL_FUND_FIELD_NAMES.isSellAll,
      [TRADEORDER_OPERATION.SELL_ALL, TRADEORDER_OPERATION.SWITCH_ALL].includes(
        operation
      )
    )

    const newQty = isNumber(qty) ? qty : 0
    setFieldValue(
      MUTUAL_FUND_FIELD_NAMES.qty,
      [TRADEORDER_OPERATION.SELL_ALL, TRADEORDER_OPERATION.SWITCH_ALL].includes(
        operation
      )
        ? 'ALL'
        : newQty
    )

    /**
     * Resets the quantity type for when DSC_FREE is selected and the user changes to
     * an operation that does not allow it
     */
    if (!QTY_TYPE_OPTIONS.find((option) => option.value === qtyType)) {
      setFieldValue(MUTUAL_FUND_FIELD_NAMES.qtyType, QTY_TYPE.UNITS)
    }
    /**
     * Sets dividendTreatment to null for sell and sell all operations
     * (dividendTreatment does not exist for these operations)
     */
    if (
      operation === TRADEORDER_OPERATION.SELL_ALL ||
      operation === TRADEORDER_OPERATION.SELL
    ) {
      setFieldValue(MUTUAL_FUND_FIELD_NAMES.dividendTreatment, null)
    }
  }, [operation])

  /**
   * Clear instrument data when the fund code field does not correspond
   * to the current instrument data
   */
  useEffect(() => {
    /** logic here is taking into account that cusip is field name for
     * (switch out of) fund code during switch trade order operation
     */
    if (
      instrument.data &&
      ((isSwitch && instrument.data.fundCode !== cusip) ||
        (!isSwitch && instrument.data.fundCode !== fundCode))
    ) {
      setFieldValue(MUTUAL_FUND_FIELD_NAMES.instrumentUrl, null)
    }
  }, [fundCode])

  const isDscFree = qtyType === QTY_TYPE.DSC_FREE
  useEffect(() => {
    const newQty = isNumber(qty) ? qty : 0
    setFieldValue(MUTUAL_FUND_FIELD_NAMES.qty, isDscFree ? 'DSC Free' : newQty)
  }, [isDscFree])

  /**
   * Sets the qty type to units whenever isSellAll is true
   */
  useEffect(() => {
    setFieldValue(
      MUTUAL_FUND_FIELD_NAMES.qtyType,
      isSellAll ? TRADEORDER_QTY_TYPE.UNITS : qtyType
    )
  }, [isSellAll])

  /**
   * Updates Commission and Expected Price information with instrument default values
   */
  useEffect(() => {
    setFieldValue(
      MUTUAL_FUND_FIELD_NAMES.commissionType,
      instrument.data?.defaultCommissionType ||
        TRADEORDER_COMMISSION_TYPE.AMOUNT
    )
    setFieldValue(
      MUTUAL_FUND_FIELD_NAMES.commission,
      instrument.data?.defaultCommission
    )

    if (qtyType === TRADEORDER_QTY_TYPE.UNITS) {
      setFieldValue(
        MUTUAL_FUND_FIELD_NAMES.expectedPrice,
        parseFloat(instrument.data?.marketPrice.toFixed(2))
      )
    }
    if (qtyType === TRADEORDER_QTY_TYPE.AMOUNT) {
      setFieldValue(MUTUAL_FUND_FIELD_NAMES.expectedPrice, undefined)
    }
  }, [instrument.data?.url, qtyType])

  /**
   * The validation rules for this field are dynamic and they change to require
   * a number when isSellAll or isDscFree change. The qty field takes one extra render
   * to change from a string to a number so we only change the input type after the
   * qty is reverted to 0
   */
  const qtyInputComponent =
    (qty !== null && typeof qty !== 'number') || isSellAll || isDscFree
      ? undefined
      : SixDecimalInput

  return (
    <ControlStateProvider
      loading={isSubmitting || !instrument.data || !accountUrl}
    >
      <FlexGridItem col='2/3' className={css.tradeSections}>
        <Grid container>
          <Grid item xs={2}>
            <ControlStateProvider
              loading={isSubmitting || !accountUrl || !props.isTradable}
            >
              <TradeSelectField
                name={MUTUAL_FUND_FIELD_NAMES.operation}
                showSwitch
                disabled={props.editMode}
              />
            </ControlStateProvider>
          </Grid>
          <Grid item xs={props.editMode ? 4 : 8}>
            <ControlStateProvider loading={isSubmitting || !accountUrl}>
              <SearchInputField
                label={isSwitch ? 'Switch out of Fund Code' : 'Fund Code'}
                name={MUTUAL_FUND_FIELD_NAMES.instrumentUrl}
                getOptionLabelOverride={(searchResult) =>
                  searchResult.displayText
                }
                searchProps={{
                  models: [ALL_MODELS.INSTRUMENT],
                  filterBy: fundInstrumentsFilter
                }}
                data-testid='search-fund'
                disabled={props.editMode}
              />
            </ControlStateProvider>
          </Grid>
          {props.editMode && (
            <Grid item xs={4}>
              <SearchInputField
                name={MUTUAL_FUND_FIELD_NAMES.accountUrl}
                label='Account'
                disabled
              />
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <OutlinedInputField
              disabled={(isSellAll || isDscFree) && !!accountUrl}
              name={MUTUAL_FUND_FIELD_NAMES.qty}
              label='Quantity'
              outlinedInputProps={{
                inputComponent: qtyInputComponent
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <ValueLabelSelectField
              disabled={isSellAll}
              name={MUTUAL_FUND_FIELD_NAMES.qtyType}
              label='Quantity Type'
              valueLabelSelectProps={{
                options: QTY_TYPE_OPTIONS
              }}
            />
          </Grid>
          <CommissionsSection
            commissionFormName={MUTUAL_FUND_FIELD_NAMES.commission}
            commissionTypeFormName={MUTUAL_FUND_FIELD_NAMES.commissionType}
          />
          {operation !== TRADEORDER_OPERATION.SELL_ALL &&
            operation !== TRADEORDER_OPERATION.SELL && (
              <Grid item xs={2}>
                <ValueLabelSelectField
                  name={MUTUAL_FUND_FIELD_NAMES.dividendTreatment}
                  label='Dividend Treatment'
                  valueLabelSelectProps={{
                    options: TRADEORDER_DIVIDEND_TREATMENT_OPTIONS
                  }}
                />
              </Grid>
            )}
          {qtyType === TRADEORDER_QTY_TYPE.UNITS && (
            <Grid item xs={2}>
              <OutlinedInputField
                name={MUTUAL_FUND_FIELD_NAMES.expectedPrice}
                label='Expected Price'
                outlinedInputProps={{
                  inputComponent: CurrencyInput
                }}
              />
            </Grid>
          )}
        </Grid>

        {isSwitch && (
          <Grid container>
            <Grid item xs={6}>
              <SearchInputField
                label='Switch into Fund Code'
                name={MUTUAL_FUND_FIELD_NAMES.fundCodeUrl}
                getOptionLabelOverride={(searchResult) =>
                  searchResult.displayText
                }
                searchProps={{
                  models: [ALL_MODELS.INSTRUMENT],
                  filterBy: fundInstrumentsFilter
                }}
                disabled={props.editMode}
              />
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={10}>
            <OutlinedInputField
              name={MUTUAL_FUND_FIELD_NAMES.notes}
              label='Notes'
              outlinedInputProps={{
                multiline: true,
                minRows: 1,
                maxRows: 4,
                placeholder: 'Write a note about this order...'
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <OutlinedInputField
              name={MUTUAL_FUND_FIELD_NAMES.instructions}
              label='Instructions'
              outlinedInputProps={{
                multiline: true,
                minRows: 1,
                maxRows: 4,
                placeholder: 'Write instructions about this order...'
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs='auto'>
            <CheckboxField
              name={MUTUAL_FUND_FIELD_NAMES.isSolicited}
              label='Solicited'
            />
          </Grid>
          <Grid item xs='auto'>
            <ValueLabelSelectField
              name={MUTUAL_FUND_FIELD_NAMES.proStatus}
              label='Pro Status'
              valueLabelSelectProps={{
                options: TRADEORDER_PRO_STATUS_OPTIONS
              }}
            />
          </Grid>
        </Grid>
      </FlexGridItem>
    </ControlStateProvider>
  )
}
