import React, {useEffect} from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import {P} from '@d1g1t/shared/components/typography'
import {getErrorDisplayMessage} from '@d1g1t/shared/wrappers/error-boundary'
import {reportError} from '@d1g1t/shared/wrappers/error-handler'

import * as css from './style.scss'

export interface IErrorDisplayProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  error: Error
}

export const ErrorDisplay: React.FC<IErrorDisplayProps> = ({
  error,
  className,
  children,
  ...props
}) => {
  useEffect(() => {
    reportError(error)
  }, [error])

  return (
    <P
      data-error-display
      className={classNames(css.error, className)}
      {...props}
    >
      {getErrorDisplayMessage(error)}
    </P>
  )
}
