import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import {EditableCell} from '@d1g1t/shared/containers/standard-table/components/custom-types/editable-cell'
import {DataCellValueFormatted} from '@d1g1t/shared/containers/standard-table/components/data-cell/data-cell-value'
import {isCurrentSingleCellEditActive} from '@d1g1t/shared/containers/standard-table/components/data-cell/lib'
import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'

import {displayDscFreeForItem, displaySellAllForItem} from '../../../lib'

import * as dataCellCss from '@d1g1t/shared/containers/standard-table/components/data-cell/styles.scss'
import * as commonCss from '@d1g1t/shared/containers/standard-table/styles.scss'

export const EditableTradeOrderQuantity: React.FC<IDataCellProps> = (props) => {
  const currentSingleCellEditActive = isCurrentSingleCellEditActive(
    {categoryId: props.category.id, itemId: props.item.id},
    props.activeEditingCellLocation
  )

  const isSellAll = displaySellAllForItem(props.item)
  const isDscFree = displayDscFreeForItem(props.item)

  if ((isSellAll || isDscFree) && !currentSingleCellEditActive) {
    return (
      <div className={classNames(commonCss.innerCell, dataCellCss.number)}>
        {isSellAll ? 'ALL' : 'DSC Free'}
      </div>
    )
  }

  if (currentSingleCellEditActive) {
    return <EditableCell {...props} />
  }

  return (
    <DataCellValueFormatted
      value={props.item.getValue(props.category.id)}
      valueType={props.category.valueType}
      getFormatter={props.getFormatter}
    />
  )
}
