import {useMemo} from 'react'

import {useApi, useApiQuery} from 'fairlight'
import produce from 'immer'
import {keyBy} from 'lodash'

import {IApiListResponse, ViewsEndpoints} from '@d1g1t/api/endpoints'
import {
  IInvestorPortalSection,
  INVESTORPORTALSECTION_NAME,
  IUserView
} from '@d1g1t/api/models'

import {formatFilterValue} from '@d1g1t/lib/filters'
import {useIsControlled} from '@d1g1t/lib/hooks/use-controlled-change-warning'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {useGlobalSettings} from '@d1g1t/shared/wrappers/global-settings'

import {IViewListItem} from './'
import {IDisplayOptions} from './components/display-options/typings'
import {convertViewToSelection, findSelectedView} from './helpers'
import {generateUniqueViewKey, getParams} from './lib'

type SelectedViewHookValue = [
  {
    /**
     * True if the view is loading.
     * Even when the loading is happening in the background to confirm a cached value
     */
    loading: boolean
    /**
     * The selected view of the component.
     */
    view: IUserView
    /**
     * The view options (metrics, groups, filters, and display options) of the selected view.
     */
    displayOptions: IDisplayOptions
    /**
     * A unique key for the selected view. Used with `useTableColumnFilterControl` and `useTableColumnFilterControlRecoil`
     * to reset filters if the selected view is changed/updated
     */
    viewKey: string
  },
  {
    /**
     * Saves the latest selected view for the component.
     */
    update(view: IUserView): void
  }
]

/**
 * Returns the last selected view of a component.
 * @param componentSaveKey - ID used by component as the save key in Global Settings.
 */
export function useSelectedView(
  componentSaveKey: string,
  opts: {
    /**
     * If true, will pull the view from global settings rather
     * than the views CRUD API, since this is a custom view.
     */
    saveToGlobalSettings?: boolean
    /**
     * If different instances of a component want to share the source for all views,
     * pass this param to determine `allViewsSource`
     * (e.g. `componentSaveKeys` = `'LOG-DETAILS-MONITOR-ACTIVITY'` and
     * `'LOG-DETAILS-ADMIN'` while `viewsListSaveKey` = `'LOG-DETAILS'`)
     */
    viewsListSaveKey?: string
    /**
     * Filter views by investor portal section
     */
    investorPortalSection?: INVESTORPORTALSECTION_NAME
  } = {}
): SelectedViewHookValue {
  const globalSettingsSaveKey = `${componentSaveKey}-selectedView`

  const isControlled = useIsControlled(opts.saveToGlobalSettings)
  const globalViews = useCustomViewsGlobalSettings(
    opts.viewsListSaveKey || componentSaveKey
  )[0]?.views
  const localViewsSource = useViewsListQuery(
    opts.viewsListSaveKey || componentSaveKey
  )[0]
  const localViews = localViewsSource?.data?.results

  const allViews: IUserView[] = isControlled ? globalViews : localViews

  const viewLoading = !!localViewsSource?.loading

  const [
    settings,
    {
      updateGlobalSettings: updateSavedSelectedView,
      loading: globalSettingsLoading
    }
  ] = useGlobalSettings<{selectedViewId: string}>(
    globalSettingsSaveKey,
    {selectedViewId: null},
    [allViews],
    (nextSettings) => getParams(nextSettings, allViews)
  )

  const selectedView = findSelectedView(
    settings?.selectedViewId,
    globalSettingsLoading,
    allViews,
    opts.investorPortalSection
  )

  const handleUpdate = (view: IUserView) => {
    updateSavedSelectedView(
      !view ? null : {selectedViewId: extractIdFromUrl(view.url)}
    )
  }

  const displayOptions = useMemo(() => {
    return convertViewToSelection(selectedView)
  }, [selectedView])

  const viewKey = useMemo(() => {
    if (!selectedView || !displayOptions) {
      return null
    }

    return generateUniqueViewKey(selectedView.url, displayOptions)
  }, [displayOptions, selectedView?.url])

  return [
    {
      view: selectedView,
      displayOptions,
      loading: viewLoading || globalSettingsLoading,
      viewKey
    },
    {
      update: handleUpdate
    }
  ]
}

type ViewsHookValue = [
  /**
   * All views related to the component.
   */
  Loadable<IApiListResponse<IUserView>>,
  {
    /**
     * Create a new view.
     */
    create(view: IUserView): Promise<IUserView>
    /**
     * Rename a view.
     */
    rename(viewId: string, newName: string): Promise<IUserView>
    /**
     * Removes a view from the component's view list.
     */
    remove(view: IUserView): Promise<void>
    /**
     * Edit view options (metrics, groups, filters, and display options) of a view.
     * Set the newly edited view as the selected view for the component.
     */
    update(view: IUserView, displayOptions: IDisplayOptions): Promise<IUserView>

    /**
     * Change the view order for a component.
     */
    updateOrder(views: IViewListItem[]): Promise<void>
  }
]

function useViewsListQuery(componentSaveKey: string) {
  return useApiQuery(ViewsEndpoints.list({table_key: componentSaveKey}), {
    fetchPolicy: 'cache-and-fetch'
  })
}

function useCustomViewsGlobalSettings(componentSaveKey: string) {
  const globalSettingsSaveKey = `${componentSaveKey}-views`

  return useGlobalSettings<{
    views: IUserView[]
  }>(globalSettingsSaveKey, {views: []})
}

export function useViews(
  componentSaveKey: string,
  opts: {
    /**
     * If true, will manage the views in global settings rather
     * than the views CRUD API, since this is a custom view.
     */
    saveToGlobalSettings?: boolean
  } = {}
): ViewsHookValue {
  return useIsControlled(opts.saveToGlobalSettings)
    ? useGlobalSettingsViews(componentSaveKey)
    : useApiViews(componentSaveKey)
}

/**
 * Stores & manages fully-custom views for metrics that aren't defined by the API.
 *
 * This currently uses global settings for persistence.
 */
function useGlobalSettingsViews(componentSaveKey: string): ViewsHookValue {
  const [settings, {updateGlobalSettingsAtKey, loading}] =
    useCustomViewsGlobalSettings(componentSaveKey)

  const views = settings?.views

  const updateViews = updateGlobalSettingsAtKey('views')

  const handleCreate = (view: IUserView) => {
    // save the view to list of component views
    const newView: IUserView = {
      url: `custom:${new Date().getTime()}`,
      tableKey: componentSaveKey,
      order: views.length + 1 ?? 0,
      displayData: {
        showCurrentDataOnly: true // true by default for new views unless overriden by `view`
      },
      ...view
    }

    updateViews(
      produce(views, (draft) => {
        draft.push(newView)
      })
    )

    return Promise.resolve(newView)
  }

  const handleRename = (viewId: string, newName: string) => {
    const renamedView: IUserView = {
      ...views.find(({url}) => extractIdFromUrl(url) === viewId),
      name: newName
    }

    updateViews(
      produce(views, (draft) => {
        for (let i = 0; i < draft.length; i++) {
          if (draft[i].url === renamedView.url) {
            draft[i] = renamedView
          }
        }
      })
    )

    return Promise.resolve(renamedView)
  }

  const handleRemove = (view: IUserView) => {
    updateViews(
      produce(views, (draft) => {
        for (let i = 0; i < draft.length; i++) {
          if (draft[i].url === view.url) {
            draft.splice(i, 1) // remove view
            return
          }
        }
      })
    )

    return Promise.resolve(null)
  }

  const handleUpdate = (view: IUserView, displayOptions: IDisplayOptions) => {
    const updatedView = generateUpdatedView(view, displayOptions)

    updateViews(
      produce(views, (draft) => {
        for (let i = 0; i < draft.length; i++) {
          if (draft[i].url === updatedView.url) {
            draft[i] = {
              ...draft[i],
              ...updatedView
            }
          }
        }
      })
    )

    return Promise.resolve(updatedView)
  }

  const handleUpdateOrder = (orderedViews: IViewListItem[]) => {
    const viewsByUrl = keyBy(views, 'url')

    updateViews(
      orderedViews.map((orderedView, index) => {
        return produce(viewsByUrl[orderedView.model.url], (draft) => {
          draft.order = ++index
          draft.tableKey = componentSaveKey
        })
      })
    )

    return Promise.resolve(null)
  }

  return [
    {
      data: views
        ? {
            results: views,
            count: views.length,
            next: null,
            previous: null
          }
        : null,
      loading,
      error: null
    },
    {
      create: handleCreate,
      rename: handleRename,
      remove: handleRemove,
      update: handleUpdate,
      updateOrder: handleUpdateOrder
    }
  ]
}

/**
 * All views created by advisor for a component.
 * @param componentSaveKey -ID used by component as the save key in Global Settings.
 */
function useApiViews(componentSaveKey: string): ViewsHookValue {
  const api = useApi()

  const [allViews, allViewsActions] = useViewsListQuery(componentSaveKey)

  const handleCreate = async (view: IUserView) => {
    // save the view to list of component views
    const newView = await api.request(
      ViewsEndpoints.create({
        tableKey: componentSaveKey,
        order: allViews.data?.count + 1 ?? 0,
        displayData: {
          showCurrentDataOnly: true // true by default for new views unless overriden by `view`
        },
        ...view
      })
    )

    allViewsActions.refetch()

    return newView
  }

  const handleRename = async (viewId: string, newName: string) => {
    const view = await api.request(
      ViewsEndpoints.partialUpdate(viewId, {
        name: newName
      })
    )

    allViewsActions.refetch()

    return view
  }

  const handleRemove = async (view: IUserView) => {
    await api.request(ViewsEndpoints.destroy(extractIdFromUrl(view.url)))

    allViewsActions.refetch()
  }

  const handleUpdate = async (
    view: IUserView,
    displayOptions: IDisplayOptions
  ) => {
    const updatedView = generateUpdatedView(view, displayOptions)

    const newView = await api.request(
      ViewsEndpoints.partialUpdate(extractIdFromUrl(view.url), updatedView)
    )

    allViewsActions.refetch()

    return newView
  }

  const handleUpdateOrder = async (views: IViewListItem[]) => {
    const promises = []

    for (const [index, view] of views.entries()) {
      if (view.model.isGlobal) {
        continue
      }
      promises.push(
        api.request(
          ViewsEndpoints.partialUpdate(extractIdFromUrl(view.model.url), {
            order: index,
            tableKey: componentSaveKey
          })
        )
      )
    }

    await Promise.all(promises)
    allViewsActions.refetch()
  }

  return [
    allViews,
    {
      create: handleCreate,
      rename: handleRename,
      remove: handleRemove,
      update: handleUpdate,
      updateOrder: handleUpdateOrder
    }
  ]
}

function generateUpdatedView(
  view: IUserView,
  displayOptions: IDisplayOptions
): IUserView {
  const investorPortalSections = (() => {
    const sections: IInvestorPortalSection[] = []

    for (const [section, value] of Object.entries(
      displayOptions.investorPortalSections
    )) {
      if (value) {
        sections.push({name: section as INVESTORPORTALSECTION_NAME})
      }
    }

    return sections
  })()

  return {
    groups: displayOptions.groups
      ? displayOptions.groups
          .filter((group) => !!group.groupingCriterion)
          .map((group, order) => {
            return {
              ...group,
              order,
              userView: extractIdFromUrl(view.url)
            }
          })
      : [],
    metrics: displayOptions.metrics
      ? displayOptions.metrics
          .filter((metric) => !!metric.metric)
          .map((metric, order) => {
            return {
              ...metric,
              order
            }
          })
      : [],
    filters: displayOptions.filter
      ? displayOptions.filter.items.map((filterItem) => {
          return {
            filterCriterion: filterItem.filterCriterion,
            value: formatFilterValue(filterItem.value),
            joinOperator: displayOptions.filter.joinOperator
          }
        })
      : [],
    displayData: produce(
      displayOptions.displayData || EMPTY_OBJECT,
      (draft: IDisplayOptions['displayData']) => {
        // Force boolean value of showCurrentDataOnly
        // Unsure why this is needed.
        draft.showCurrentDataOnly = !!draft.showCurrentDataOnly
      }
    ),
    url: view.url,
    name: view.name,
    isGlobal: displayOptions.isGlobal,
    isAvailableToExternalProfiles: displayOptions.isAvailableToExternalProfiles,
    investorPortalSections
  }
}
