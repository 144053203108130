import React, {useMemo, useState} from 'react'

import {format} from 'date-fns'
import {useApiQuery} from 'fairlight'
import {capitalize} from 'lodash'

import {grey} from '@material-ui/core/colors'
import {makeStyles} from '@material-ui/core/styles'

import {
  BASIC_COLOURS,
  useInternalTheme
} from '@d1g1t/config/theme/internal-theme'

import {UserAccountProfilesEndpoints} from '@d1g1t/api/endpoints'
import {CALCULATEDFEEDETAIL_ASSET_CALCULATION_METHOD_OPTIONS} from '@d1g1t/api/models'

import {ISO_DATE_FORMAT} from '@d1g1t/lib/constants'
import {parseDate} from '@d1g1t/lib/formatters/date-formatter'
import {extractIdFromUrl} from '@d1g1t/lib/url'
import {mapValueToLabel} from '@d1g1t/lib/value-label'

import {Flex} from '@d1g1t/shared/components/flex'
import {Divider} from '@d1g1t/shared/components/mui/divider'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {ListItem} from '@d1g1t/shared/components/mui/list-item'
import {Popover} from '@d1g1t/shared/components/mui/popover'
import {RouterLink} from '@d1g1t/shared/components/router-link'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {TextTag} from '@d1g1t/shared/components/text-tag'
import {H2, H4, P} from '@d1g1t/shared/components/typography'
import {useNumberFormatter} from '@d1g1t/shared/wrappers/formatter'

import {BillingLocations} from '@d1g1t/advisor/locations'

import {CALCULATED_FEE_STATUS} from '../../../constants'
import {ISummaryProps} from '../../../typings'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1),
    width: 340
  },
  billSpec: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    },
    margin: 0
  },

  dot: {
    marginLeft: '8px',
    marginRight: '8px'
  },
  noMargin: {
    margin: 0
  },
  grayBackground: {
    background: grey[100],
    borderRadius: theme.shape.borderRadius
  },
  routerLink: {
    color: theme.palette.primary.main
  }
}))
/**
 * Renders Summary of Single calculated fee
 */
export const Summary: React.FC<ISummaryProps> = (props) => {
  const classes = useStyles()
  const {uiColors} = useInternalTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const percentageFormatter = useNumberFormatter({
    style: 'percent',
    allowZero: true,
    decimalPlaces: 2
  })
  const currencyFormatter = useNumberFormatter({
    currencyDisplay: 'code',
    decimalPlaces: 2
  })

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const [userAccounts] = useApiQuery(UserAccountProfilesEndpoints.list(), {
    useErrorBoundary: true
  })
  const userAccountsOptions = useMemo(
    () =>
      userAccounts.data?.results.map((item) => {
        return {
          value: item.url,
          label: `${
            item.calculatedFullName || item.displayName || item.printName
          }`
        }
      }),
    [userAccounts.data?.results]
  )
  const generatedBy = userAccountsOptions?.find(
    (item) => item.value === props.feeData?.generatedBy
  )?.label

  const labelBackground = (() => {
    switch (props.feeData.status) {
      case CALCULATED_FEE_STATUS.APPROVED: {
        return uiColors.positiveGreen
      }
      case CALCULATED_FEE_STATUS.PENDING_APPROVAL: {
        return BASIC_COLOURS.YELLOW
      }
      case CALCULATED_FEE_STATUS.REJECTED: {
        return uiColors.negativeRed
      }
    }
  })()

  const billSpecName = props.feeData.calculationMeta?.billSpecMeta?.name || '-'

  // TODO:
  // Currenctly there is no way to know if user can open Bill Spec tab.
  // PS will need to add owner field to bill_spec_meta

  // const isOwner =
  // props.feeData?.calculationMeta?.billSpecMeta?.owner ===
  // userProfile.user?.profile

  const renderSpecName = () => {
    if (!props.feeData) {
      return null
    }
    return (
      <>
        <strong
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup='true'
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className={classes.billSpec}
        >
          <RouterLink
            className={classes.routerLink}
            to={BillingLocations.feeSpecSingle(
              extractIdFromUrl(props.feeData.originalBillSpec)
            )}
          >
            {billSpecName}
          </RouterLink>
        </strong>
        <Popover
          id='mouse-over-popover'
          className={classes.popover}
          classes={{
            paper: classes.paper
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <ListItem>
            <Grid container>
              <Grid xs={12}>
                <H4> {billSpecName}</H4>
              </Grid>
            </Grid>
          </ListItem>
          <Spacer xs />
          <ListItem>
            <Grid container justifyContent='space-between'>
              <P>Payment Frequency </P>
              <P>{props.feeData.paymentFrequency} </P>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem>
            <Grid container justifyContent='space-between'>
              <P>Payment Mode</P>
              <P>{capitalize(props.feeData.paymentMode)} </P>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem>
            <Grid container justifyContent='space-between'>
              <P>Asset Calculation Method</P>
              <P>
                {mapValueToLabel(
                  props.feeData.assetCalculationMethod,
                  CALCULATEDFEEDETAIL_ASSET_CALCULATION_METHOD_OPTIONS
                )}
              </P>
            </Grid>
          </ListItem>
          <Divider />
        </Popover>
      </>
    )
  }

  if (!props.feeData) {
    return null
  }

  return (
    <>
      <Flex row>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item>
            <H2>Summary</H2>
          </Grid>
          <Grid item>
            <TextTag
              title={props.feeData.status}
              style={{
                background: labelBackground
              }}
            />
          </Grid>
        </Grid>
      </Flex>
      <Spacer custom={8} />
      <Flex row>
        <Grid container direction='column'>
          <Grid item>
            <P className={classes.noMargin}>
              Billing Period:
              <strong>
                {' '}
                {props.feeData.billingStartDate} to{' '}
                {props.feeData.billingEndDate}
              </strong>
            </P>
          </Grid>
        </Grid>
      </Flex>
      <Spacer xs />
      <Flex row>
        <Grid
          container
          direction='row'
          xs={6}
          className={classes.grayBackground}
        >
          <Grid item xs={6}>
            <P className={classes.noMargin} fontSize={12}>
              Total Fee
            </P>
            <H2 className={classes.noMargin} semiBold>
              ${currencyFormatter.formatJSX(props.feeData.totalFee)}{' '}
              {extractIdFromUrl(props.feeData.currency)}
            </H2>
          </Grid>
          <Grid item xs={6}>
            <P className={classes.noMargin} fontSize={12}>
              Billable Assets
            </P>
            <H2 className={classes.noMargin} semiBold>
              ${currencyFormatter.formatJSX(props.feeData.totalAum)}{' '}
              {extractIdFromUrl(props.feeData.currency)}
            </H2>
          </Grid>
        </Grid>
      </Flex>
      <Spacer xs />
      <Flex row>
        <Grid container direction='row'>
          <Grid item xs={3}>
            <H4>Fee Information</H4>
            <P className={classes.noMargin}>
              Fee Charge:
              <strong>
                {' '}
                ${currencyFormatter.formatJSX(props.feeData.feeCharge)}{' '}
                {extractIdFromUrl(props.feeData.currency)}
              </strong>
            </P>
            <P className={classes.noMargin}>
              Effective Rate:
              <strong>
                {' '}
                {percentageFormatter.formatJSX(props.feeData.effectiveRate)}
              </strong>
            </P>
            <P className={classes.noMargin}>
              Tax:
              <strong>
                {' '}
                ${currencyFormatter.formatJSX(props.feeData.salesTax)}{' '}
                {extractIdFromUrl(props.feeData.currency)}
              </strong>
            </P>
            <P className={classes.noMargin}>
              Tax Rate:{' '}
              <strong>
                {percentageFormatter.formatJSX(props.feeData.taxRate)}
              </strong>
            </P>
          </Grid>
          <Grid item xs={3}>
            <H4>Configuration</H4>
            <P className={classes.noMargin}>
              Payment Frequency:
              <strong> {props.feeData.paymentFrequency}</strong>
            </P>
            <P className={classes.noMargin}>
              Payment Mode:
              <strong> {capitalize(props.feeData.paymentMode)}</strong>
            </P>
            <P className={classes.noMargin}>
              Bill Group:{' '}
              <strong>
                {props.feeData.originalBillGroupFirmProvidedKey || '-'}
              </strong>
            </P>
            <P className={classes.noMargin}>
              Bill Spec:{' '}
              <strong>{billSpecName ? renderSpecName() : '-'}</strong>
            </P>
            <P className={classes.noMargin}>
              Target Entity:{' '}
              <strong>{`${props.feeData.originalTargetName} (${props.feeData.originalTargetFirmProvidedKey})`}</strong>
            </P>
          </Grid>
        </Grid>
      </Flex>

      <Spacer md />
      <Flex row>
        <Grid container direction='row'>
          <Grid item xs={3}>
            <P className={classes.noMargin}>
              Batch ID:
              <strong> {props.feeData.batchId}</strong>
            </P>
            <P className={classes.noMargin}>
              Generated by:
              <strong>
                {' '}
                {generatedBy} on{' '}
                {format(parseDate(props.feeData.generatedOn), ISO_DATE_FORMAT)}
              </strong>
            </P>

            {props.feeData.statusUpdatedOn && (
              <P className={classes.noMargin}>
                {props.feeData.status} by:
                <strong>
                  {' '}
                  {props.feeData.statusUpdatedBy} on{' '}
                  {format(
                    parseDate(props.feeData.statusUpdatedOn),
                    ISO_DATE_FORMAT
                  )}
                </strong>
              </P>
            )}
          </Grid>
        </Grid>
      </Flex>
    </>
  )
}
