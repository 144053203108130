import React from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'

import MuiButton, {ButtonProps} from '@material-ui/core/Button'

import {classNames} from '@d1g1t/lib/class-names'
import {getDDButtonAction} from '@d1g1t/lib/data-dog-helpers'
import {getFirstTruthyObjectKey} from '@d1g1t/lib/first-truthy-key'
import {onlyTranslateStrings} from '@d1g1t/lib/only-translate-strings'

import {useControlState} from '@d1g1t/shared/components/control-state'

import * as css from './style.scss'

type IPickedMuiButtonProps = Omit<
  ButtonProps,
  'color' | 'size' | 'variant' | 'classes'
>

interface IButtonColorCustomProps {
  primary?: boolean
  secondary?: boolean
  inheritColor?: boolean
}

interface IButtonSizeCustomProps {
  small?: boolean
  medium?: boolean
  large?: boolean
}

interface IButtonVariantCustomProps {
  text?: boolean
  outlined?: boolean
  contained?: boolean
}

interface IButtonBackgroundCustomProps {
  whiteBackground?: boolean
  grayBackground?: boolean
}

// TODO: Need a `RequireOne` generic to allow only 1 from each custom props interface
export interface IButtonProps
  extends IPickedMuiButtonProps,
    IButtonColorCustomProps,
    IButtonSizeCustomProps,
    IButtonVariantCustomProps,
    IButtonBackgroundCustomProps {
  spaceLeft?: boolean
  spaceRight?: boolean
  noSVGSpacing?: boolean
  noMinWidth?: boolean
  errorTextMargin?: boolean
  noPadding?: boolean
}

/**
 * Returns the children of fragments if passed as node
 *
 * @param node - any react node passed as props
 */
export function getActualChildren(node: React.ReactNode): React.ReactNode {
  if (
    typeof node === 'object' &&
    node !== null &&
    'type' in node &&
    node.type === React.Fragment
  ) {
    return node.props.children
  }

  return node
}

const muiPropsFromCustomProps = (customProps: IButtonProps): ButtonProps => {
  const {
    small,
    medium,
    large,
    text,
    outlined,
    contained,
    primary,
    secondary,
    inheritColor,
    children,
    spaceLeft,
    spaceRight,
    noSVGSpacing,
    noMinWidth,
    noPadding,
    whiteBackground,
    grayBackground,
    disabled,
    errorTextMargin,
    disableRipple,
    ...other
  } = customProps

  const size = getFirstTruthyObjectKey({small, medium, large})
  const variant = getFirstTruthyObjectKey({
    text,
    outlined,
    contained
  })
  const color = getFirstTruthyObjectKey({
    primary,
    secondary,
    inherit: inheritColor
  })

  const classes = {
    root: classNames(css.root, css.button, {
      [css.spaceLeft]: spaceLeft,
      [css.spaceRight]: spaceRight,
      [css.noMinWidth]: noMinWidth,
      [css.errorTextMargin]: errorTextMargin,
      [css.noPadding]: noPadding,
      [css.whiteBackground]: whiteBackground,
      [css.grayBackground]: grayBackground
    }),
    label: classNames(css.label, css.icons, {
      [css.small]: small,
      [css.large]: large,
      [css.noSVGSpacing]: noSVGSpacing
    })
  }

  const controlState = useControlState()

  const wrappedChildren = React.Children.map(
    getActualChildren(children),
    (child) => {
      if (typeof child === 'string') {
        return <span>{child}</span>
      }

      return child
    }
  )

  return {
    size,
    variant,
    color,
    classes,
    children: wrappedChildren,
    disabled: disabled || controlState.loading,
    ...other
  }
}

export const Button: React.FC<IButtonProps> = React.forwardRef((props, ref) => {
  const {t} = useTranslation()
  const location = useLocation()

  const child = getActualChildren(props.children)
  const datadogAction = props['data-dd-action'] || getDDButtonAction(child)
  const datadogActionName = datadogAction
    ? `${location.pathname} - ${datadogAction}`
    : undefined

  return (
    <MuiButton
      ref={ref}
      {...muiPropsFromCustomProps(props)}
      data-dd-action={datadogActionName}
    >
      {onlyTranslateStrings(t, props.children)}
    </MuiButton>
  )
})

Button.displayName = 'Button(d1g1t)'
