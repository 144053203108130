import React from 'react'

import MuiListItem, {ListItemProps} from '@material-ui/core/ListItem'
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'

import {classNames} from '@d1g1t/lib/class-names'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      '&$primary&$selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,

        '&:hover': {
          backgroundColor: theme.palette.primary.main
        }
      }
    },
    selected: {},
    primary: {}
  })

interface IInternalListItemProps
  extends Omit<IListItemProps, 'classes'>,
    WithStyles<typeof styles> {
  forwardedRef?: ListItemProps['ref']
}

export interface IListItemProps extends ListItemProps {
  primary?: boolean
}

// Ignoring type error in `MuiMenuItem` due to bug in types
// https://github.com/mui-org/material-ui/issues/16245

const InternalListItem: React.FC<IInternalListItemProps> = ({
  classes,
  primary,
  forwardedRef,
  ...props
}) => {
  return (
    <MuiListItem
      // @ts-ignore
      ref={forwardedRef}
      classes={{
        root: classNames(classes.root, {
          [classes.primary]: primary
        }),
        selected: classes.selected
      }}
      {...props}
    />
  )
}

export const ListItemWithStyles = withStyles(styles, {withTheme: true})(
  InternalListItem
) as React.FC<IListItemProps & {forwardedRef?: ListItemProps['ref']}>

export const ListItem: React.FC<IListItemProps> = React.forwardRef(
  (props, ref) => {
    return <ListItemWithStyles {...props} forwardedRef={ref} />
  }
)

ListItem.displayName = 'ListItem(d1g1t)'
