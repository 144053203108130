import React, {useEffect, useMemo} from 'react'

import {useFormikContext} from 'formik'
import {get, isEmpty} from 'lodash'

import DeleteIcon from '@material-ui/icons/Delete'

import {IInstrument} from '@d1g1t/api/models'

import {StandardResponseItem} from '@d1g1t/lib/standard-response'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {Flex} from '@d1g1t/shared/components/flex'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Modal, ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {
  StandardTable,
  StandardTableAction
} from '@d1g1t/shared/containers/standard-table'
import {DropdownCell} from '@d1g1t/shared/containers/standard-table/components/custom-types/dropdown-cell'
import {
  ErrorBoundary,
  ModalContentsErrorFallback
} from '@d1g1t/shared/wrappers/error-boundary'

import {
  SECURITY_KEY_TO_METRIC_COLUMN_TITLE_MAP,
  SECURITY_KEYS
} from '../../constants'
import {IEditSecurityFormValues} from '../../typings'
import {useVectorEditorData} from './hook'

export interface IVectorizedPropertyEditModalProps {
  /**
   * The security being edited.
   */
  instrument: IInstrument
  /**
   * The vectorized property being edited.
   */
  vectorizedPropertyEditKey:
    | SECURITY_KEYS.ASSET_CLASS_VECTORIZED
    | SECURITY_KEYS.ASSET_CLASS_L2_VECTORIZED
    | SECURITY_KEYS.ASSET_CLASS_L3_VECTORIZED
    | SECURITY_KEYS.CURRENCY_VECTORIZED
    | SECURITY_KEYS.STRATEGY_VECTORIZED
    | SECURITY_KEYS.REGION_VECTORIZED
    | SECURITY_KEYS.INDUSTRY_VECTORIZED
    | SECURITY_KEYS.SECTOR_VECTORIZED

  onClose(): void
}

/**
 * Allows advisors to edit a vectorized property of a security.
 *
 * The following vectorized properties interfaces are supported by this modal:
 * AssetClasses, Sectors, Regions, Industries, Currencies, AssetClassL3s, AssetClassL2s, Strategies.
 */
export const VectorizedPropertyEditModal: React.FC<
  IVectorizedPropertyEditModalProps
> = (props) => {
  const formik = useFormikContext<IEditSecurityFormValues>()
  const [
    vectorChart,
    vectorListCount,
    {
      onCellValueChange,
      onRemoveVector,
      onAddVector,
      refetchVectorChart,
      setVectorChartData
    }
  ] = useVectorEditorData(props.instrument, props.vectorizedPropertyEditKey)

  /**
   * Since updating the data in the modal will update the VectorEditorDate cache and the form values even before the user
   * clicks on the save button
   * Those two initial values are necessary to ensure that when
   * closing the modal without saving the data will be reset to the latest locally saved changes
   */
  const initialValue = useMemo(
    () => formik.values[props.vectorizedPropertyEditKey],
    []
  )
  const initialChart = useMemo(() => vectorChart, [vectorChart.loading])

  useEffect(() => {
    if (!formik.touched[props.vectorizedPropertyEditKey]) {
      refetchVectorChart()
      formik.setFieldTouched(props.vectorizedPropertyEditKey, true)
    }
  }, [])

  const tableActions: StandardTableAction = (item: StandardResponseItem) => {
    return [
      {
        label: 'Delete exposure',
        icon: <DeleteIcon />,
        onClick: () => {
          onRemoveVector(item)
        }
      }
    ]
  }

  const handleClose = () => {
    formik.setFieldValue(props.vectorizedPropertyEditKey, initialValue)
    setVectorChartData(initialChart.data)
    props.onClose()
  }

  const handleSave = () => {
    formik.setFieldTouched(props.vectorizedPropertyEditKey, true)
    props.onClose()
  }
  if (!vectorChart.data) {
    return null
  }

  const hasError = !isEmpty(formik.errors[props.vectorizedPropertyEditKey])

  return (
    <Modal
      open
      title={`${
        SECURITY_KEY_TO_METRIC_COLUMN_TITLE_MAP[props.vectorizedPropertyEditKey]
      }: ${props.instrument.name}`}
      onClose={handleClose}
    >
      <ErrorBoundary
        resetId='no-reset'
        fallback={<ModalContentsErrorFallback onClose={props.onClose} />}
      >
        <LoadingContainer loading={vectorChart.loading}>
          <div style={{display: 'contents'}}>
            <ControlStateProvider loading={formik.isSubmitting}>
              <ModalContent>
                <StandardTable
                  table={vectorChart.data}
                  categoriesOverride={{
                    name: DropdownCell
                  }}
                  maxHeight={300}
                  actions={tableActions}
                  onCellValueChange={onCellValueChange}
                  getCellError={({item}) => {
                    // TODO: look into making error lookups more efficient
                    const index = vectorChart.data.items.findIndex(
                      (vectorItem) => vectorItem.id === item.id
                    )

                    return get(
                      formik.errors[props.vectorizedPropertyEditKey],
                      index
                    )
                  }}
                />
              </ModalContent>
              <ModalActions>
                <Flex justifySpaceBetween grow>
                  <Flex alignStart>
                    <Tooltip title='Add a new exposure row'>
                      <Button
                        primary
                        contained
                        onClick={onAddVector}
                        disabled={
                          vectorListCount + 1 ===
                          vectorChart.data.allItems().length
                        }
                        data-testid='button-add-exposure'
                      >
                        Add exposure
                      </Button>
                    </Tooltip>
                  </Flex>
                  <Flex alignEnd>
                    <Button
                      primary
                      contained
                      disabled={hasError}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Flex>
                </Flex>
              </ModalActions>
            </ControlStateProvider>
          </div>
        </LoadingContainer>
      </ErrorBoundary>
    </Modal>
  )
}
