import {ALL_MODELS} from '@d1g1t/api/models'

import {partialIsEqual} from '@d1g1t/lib/partial-is-equal'
import {StandardResponseItem} from '@d1g1t/lib/standard-response'

import {
  ICellLocation,
  IStandardTableCategory,
  StandardTableItem
} from '@d1g1t/shared/containers/standard-table/typings'

import {KNOWN_CATEGORY_IDS} from '../../constants'
import {cellStyleIsEqual} from '../../lib'
import {IDataCellProps} from './typings'

interface IStandardTableItemDetails {
  name: string
  entityId: string
  clientId: string
  model: ALL_MODELS
  parentEntityId: string
  parentModel: ALL_MODELS
}

export function itemDetails(
  item: StandardResponseItem
): IStandardTableItemDetails {
  return {
    name: item.getDatum(KNOWN_CATEGORY_IDS.NAME)?.value,
    entityId: item.getValue(KNOWN_CATEGORY_IDS.MODEL_ID),
    clientId: item.getValue(KNOWN_CATEGORY_IDS.CLIENT_ID),
    model: item.getValue(KNOWN_CATEGORY_IDS.MODEL_NAME),
    parentEntityId: item.getValue(KNOWN_CATEGORY_IDS.PARENT_MODEL_ID),
    parentModel: item.getValue(KNOWN_CATEGORY_IDS.PARENT_MODEL_NAME)
  }
}

export const isGrandParent = (item: StandardTableItem): boolean => {
  if (!item.items) {
    // no grandchildren
    return false
  }

  // at least one grandchild found
  return item.items.some((child) => !child.isLeafNode)
}

export const isExpandedColumn = (category: IStandardTableCategory): boolean => {
  return category && (category.expanded || category.level > 0)
}

export const isEqualDataCell = (
  props: IDataCellProps,
  nextProps: IDataCellProps
): boolean => {
  if (!cellStyleIsEqual(props.style, nextProps.style)) {
    return false
  }

  const rootProps: (keyof IDataCellProps)[] = [
    'columnIndex',
    'rowIndex',
    'component',
    'dragSource',
    'dropTarget',
    'hoverRow',
    'hoverColumn',
    'resizingColumn',
    'selectable',
    'rowStyleState',
    'tableIsFullyExpanded',
    'checked',
    'categories',
    'showIcon',
    'getCellValue',
    'actions',
    'activeEditingCellLocation'
  ]

  if (!partialIsEqual(props, nextProps, rootProps)) {
    return false
  }

  if (props.item && !nextProps.item) {
    return false
  }

  if (props.item) {
    const itemProps: (keyof IDataCellProps['item'])[] = [
      'id',
      'data',
      'expanded',
      'group',
      'level'
    ]

    if (!partialIsEqual(props.item, nextProps.item, itemProps)) {
      return false
    }
  }

  if (props.category) {
    const categoryProps: (keyof IDataCellProps['category'])[] = [
      'id',
      'expanded',
      'level'
    ]

    if (!partialIsEqual(props.category, nextProps.category, categoryProps)) {
      return false
    }
  }

  return true
}

export const isCurrentSingleCellEditActive = (
  currentCellLocation: ICellLocation,
  singleCellEditActiveLocation: ICellLocation
): boolean => {
  if (!singleCellEditActiveLocation) {
    return false
  }

  return (
    currentCellLocation.categoryId ===
      singleCellEditActiveLocation.categoryId &&
    currentCellLocation.itemId === singleCellEditActiveLocation.itemId
  )
}
