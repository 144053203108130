import {IEntityRelationsResponse, IInstrument} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ISelectEntitiesState} from '@d1g1t/shared/containers/select-entities'

interface IRestrictions {
  restricted: boolean
  firmWideRestricted: boolean
  selectedEntityRestricted: boolean
  selectedEntityHouseholdRestricted: boolean
}

/**
 * Returns an object containing trading restriction information.
 */
export function getKnownRestrictions(params: {
  selection: Partial<ISelectEntitiesState>
  instrument?: IInstrument
  entityRelationsResults?: IEntityRelationsResponse
}): IRestrictions {
  const knownRestrictions: IRestrictions = {
    /**
     * True iff at least 1 restriction exists.
     */
    restricted: false,
    firmWideRestricted: false,
    selectedEntityRestricted: false,
    selectedEntityHouseholdRestricted: false
  }

  if (!params.selection || !params.instrument?.tradingRestrictions) {
    return knownRestrictions
  }

  const currentSelectedEntityId =
    params.selection.selected && params.selection.selected[0].entityId
  const currentSelectedEntityHouseholdId =
    params.entityRelationsResults?.household?.entityId

  for (const restriction of params.instrument.tradingRestrictions) {
    if (restriction.entityType === 'firm') {
      knownRestrictions.restricted = true
      knownRestrictions.firmWideRestricted = true
    }

    const restrictionEntityId = extractIdFromUrl(restriction.entity)

    if (restrictionEntityId === currentSelectedEntityId) {
      knownRestrictions.restricted = true
      knownRestrictions.selectedEntityRestricted = true
    }

    if (restrictionEntityId === currentSelectedEntityHouseholdId) {
      knownRestrictions.restricted = true
      knownRestrictions.selectedEntityHouseholdRestricted = true
    }
  }

  return knownRestrictions
}

/**
 * Create a phrase to explain at which level(s) it's restricted
 */
export const restrictionsMessage = (restrictions: IRestrictions): string => {
  const levels = []
  if (restrictions.firmWideRestricted) {
    levels.push('firm')
  }
  if (restrictions.selectedEntityRestricted) {
    levels.push('client')
  }
  if (restrictions.selectedEntityHouseholdRestricted) {
    levels.push('household')
  }
  return `Trading is restricted on this instrument for this ${levels.join(
    ', '
  )}.`
}
