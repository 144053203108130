import React, {useContext, useEffect, useState} from 'react'

import {find} from 'lodash'

import BackArrowIcon from '@material-ui/icons/ArrowBack'
import EditIcon from '@material-ui/icons/Edit'
import SettingsIcon from '@material-ui/icons/Settings'

import {FIRMCONFIGURATION_LOOK_THROUGH_TYPE, IUserView} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {useToggleState} from '@d1g1t/lib/hooks'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {EditableModalTitle} from '@d1g1t/shared/components/editable-modal-title'
import {Modal, ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Button} from '@d1g1t/shared/components/mui/button'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H3} from '@d1g1t/shared/components/typography'
import {
  IValueLabelSelectOption,
  ValueLabelSelect
} from '@d1g1t/shared/components/value-label-select'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {CUSTOM_DATADOG_PROPERTIES} from '@d1g1t/shared/wrappers/datadog/constants'
import {DatadogCustomPropertyContext} from '@d1g1t/shared/wrappers/datadog/context'
import {
  ErrorBoundary,
  ModalContentsErrorFallback
} from '@d1g1t/shared/wrappers/error-boundary'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {usePermissions} from '@d1g1t/shared/wrappers/permissions'

import {AddView} from './components/add-view'
import {DisplayOptions} from './components/display-options'
import {
  IDisplayOptions,
  IInvestorPortalSections
} from './components/display-options/typings'
import {ViewIcons} from './components/view-icons'
import {ViewsList} from './components/views-list'
import {
  convertViewToSelection,
  mapPagePeriodMetricsToCustom,
  removeGroupingAndMetricUrls
} from './helpers'
import {useSelectedView, useViews} from './hooks'
import {IViewOptionsProps} from './typings'

import * as css from './styles.scss'

export * from './hooks'
export * from './typings'

/**
 * Shows a list of views available for a widget. And optionally
 * manages the Manage View modal too.
 */
export const ViewOptions: React.FC<IViewOptionsProps> = (props) => {
  const permissions = usePermissions()
  const datadogCustomProperty = useContext(DatadogCustomPropertyContext)
  const {firmConfiguration} = useFirmConfiguration()

  const [
    modalUncontrolledOpen,
    ,
    showModalUncontrolled,
    hideModalUncontrolled
  ] = useToggleState(false)
  const [addViewModalOpen, , showAddViewModal, hideAddViewModal] =
    useToggleState(false)

  const [addMetricModalOpen, , showAddMetricModal, hideAddMetricModal] =
    useToggleState(false)

  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()
  const [allViews, allViewsActions] = useViews(props.id, {
    saveToGlobalSettings: !!props.configuration.saveToGlobalSettings
  })

  const [{view: selectedView}, selectedViewActions] = useSelectedView(
    props.selectedViewSaveKey || props.id,
    {
      saveToGlobalSettings: !!props.configuration.saveToGlobalSettings,
      viewsListSaveKey: props.selectedViewSaveKey && props.id,
      investorPortalSection: props.investorPortalSection
    }
  )
  /**
   * The "opened view" is the view that is currently selected for
   * editing in the view modal.
   */
  const [openedViewId, setOpenedViewId] = useState<string>(null)

  const openedView = allViews.data?.results.find(
    (view) => extractIdFromUrl(view.url) === openedViewId
  )

  const [viewName, setViewName] = useState<string>(openedView?.name)

  // Set Datadog custom property for views selected
  useEffect(() => {
    if (selectedView) {
      datadogCustomProperty.addCustomProperty(CUSTOM_DATADOG_PROPERTIES.VIEWS, {
        ...datadogCustomProperty.context.views,
        [selectedView.tableKey]: extractIdFromUrl(selectedView.url)
      })
    }
    return () => {
      datadogCustomProperty.removeCustomProperty(
        CUSTOM_DATADOG_PROPERTIES.VIEWS
      )
    }
  }, [selectedView])

  useEffect(() => {
    setViewName(openedView?.name)
  }, [openedView?.name])

  const [saving, setSaving] = useState(false)

  const modalOpenIsControlled =
    typeof props.open === 'boolean' && typeof props.onClose === 'function'
  const modalOpen = (() => {
    if (modalOpenIsControlled) {
      return props.open
    }

    return modalUncontrolledOpen
  })()

  const hideModal = () => {
    if (!addViewModalOpen && !addMetricModalOpen) {
      if (modalOpenIsControlled) {
        props.onClose()
      } else {
        hideModalUncontrolled()
      }
    }
  }

  const handleSetDisplayOptions = async (
    displayOptions: IDisplayOptions,
    setSubbmiting: (isSubmitting: boolean) => void
  ) => {
    setSaving(true)
    try {
      const updatedDisplayOptions: IDisplayOptions = {
        ...displayOptions,
        metrics: mapPagePeriodMetricsToCustom(displayOptions.metrics),
        groups: displayOptions.groups.map((grouping) => {
          return grouping
        })
      }

      const renamedView =
        displayOptions.title === openedView.name
          ? null
          : await allViewsActions.rename(
              extractIdFromUrl(openedView?.url),
              displayOptions.title
            )
      if (renamedView) {
        selectedViewActions.update(renamedView)
      }

      const updatedView = await allViewsActions.update(
        renamedView || openedView,
        updatedDisplayOptions
      )

      selectedViewActions.update(updatedView)

      showSnackbar({
        variant: 'success',
        message: `View '${updatedView.name}' has been saved.`
      })

      if (modalOpenIsControlled) {
        props.onClose()
      } else {
        hideModalUncontrolled()
      }
    } catch (error) {
      handleUnexpectedError(
        error,
        'An unexpected error occurred while saving the view.'
      )
    } finally {
      setSaving(false)
      setSubbmiting(false)
    }
  }

  const handleDropdownViewSelect = (url: string, _, event) => {
    // If the user clicked on the edit icon button, don't select the view.
    if (event.nativeEvent.srcElement.classList.contains('MuiSvgIcon-root')) {
      return
    }

    if (url === '__manage') {
      setOpenedViewId(null)
      showModalUncontrolled()
    }

    selectedViewActions.update(find(allViews.data?.results, {url}))
  }

  const getTitle = () => {
    if (!openedView || props.single) {
      return <H3>Views</H3>
    }

    return (
      <>
        <Tooltip title='Back to views list'>
          <Button
            primary
            contained
            small
            onClick={() => {
              setOpenedViewId(null)
            }}
          >
            <BackArrowIcon fontSize='small' />
            Views
          </Button>
        </Tooltip>
        <Spacer xs vertical />
        <EditableModalTitle
          name={openedView?.name}
          onViewNameChange={(name) => {
            setViewName(name)
          }}
        />
      </>
    )
  }

  const renderAddViewModal = () => {
    if (!addViewModalOpen) {
      return null
    }
    return (
      <AddView
        onSave={async (name: string) => {
          const newView: IUserView = {name}
          if (props.configuration?.newViewDefaultDisplayData) {
            newView.displayData = props.configuration.newViewDefaultDisplayData
          }
          if (props.configuration?.newViewDefaultMetrics) {
            newView.metrics = props.configuration.newViewDefaultMetrics
          }
          if (props.configuration?.newViewDefaultGroups) {
            newView.groups = props.configuration.newViewDefaultGroups
          }

          setSaving(true)

          try {
            const view = await allViewsActions.create(newView)
            selectedViewActions.update(view)
            setOpenedViewId(extractIdFromUrl(view.url))
            showSnackbar({
              variant: 'success',
              message: `View '${view.name}' has been created.`
            })
            hideAddViewModal()
          } catch (error) {
            handleUnexpectedError(
              error,
              'An unexpected error occurred while creating the view.'
            )
          } finally {
            setSaving(false)
          }
        }}
        onClose={hideAddViewModal}
      />
    )
  }

  const localViews: IUserView[] = allViews.data?.results.filter(
    (view) => !view.isGlobal
  )
  const viewCountExceeded: boolean =
    props.maxViews && localViews?.length >= props.maxViews

  const renderViewList = () => {
    return (
      <>
        {renderAddViewModal()}
        <ModalContent>
          <ViewsList
            viewList={allViews.data?.results ?? undefined}
            viewCountExceeded={viewCountExceeded}
            onViewClick={setOpenedViewId}
            onReorder={async (views) => {
              try {
                await allViewsActions.updateOrder(views)
              } catch (error) {
                handleUnexpectedError(
                  error,
                  'An unexpected error occurred while removing the view.'
                )
              }
            }}
            createDuplicateView={async (
              view: IUserView
            ): Promise<IUserView> => {
              setSaving(true)
              try {
                const copy = await allViewsActions.create({
                  ...removeGroupingAndMetricUrls(view),
                  isGlobal: false,
                  isAvailableToExternalProfiles: false,
                  name: `${view.name} Copy`
                })
                showSnackbar({
                  variant: 'success',
                  message: 'View has been created.'
                })
                return copy
              } catch (error) {
                handleUnexpectedError(
                  error,
                  'An unexpected error occurred while creating a copy of a view.'
                )
              } finally {
                setSaving(false)
              }
            }}
            removeView={async (view: IUserView) => {
              setSaving(true)
              try {
                await allViewsActions.remove(view)

                if (selectedView.url === view.url) {
                  selectedViewActions.update(
                    allViews.data?.results?.find(
                      (aView) => aView.url !== view.url
                    ) ?? null
                  )
                }

                showSnackbar({
                  variant: 'success',
                  message: 'View has been deleted.'
                })
              } catch (error) {
                handleUnexpectedError(
                  error,
                  'An unexpected error occurred while removing the view.'
                )
              } finally {
                setSaving(false)
              }
            }}
          />
        </ModalContent>
        <ModalActions>
          <Tooltip title={viewCountExceeded ? 'View limit exceeded.' : ''}>
            <div>
              <Button
                primary
                contained
                onClick={showAddViewModal}
                data-testid='button_add_view'
                disabled={viewCountExceeded}
              >
                Add view
              </Button>
            </div>
          </Tooltip>
        </ModalActions>
      </>
    )
  }

  const renderModalContent = () => {
    if (!openedView) {
      return renderViewList()
    }

    const investorPortalSections = (() => {
      const sections: IInvestorPortalSections = {}
      if (!openedView.investorPortalSections) {
        return sections
      }

      for (const investorPortalSection of openedView.investorPortalSections) {
        sections[investorPortalSection.name] = true
      }

      return sections
    })()

    return (
      <DisplayOptions
        viewTitle={openedView.name}
        updatedViewTitleToBeSaved={viewName}
        key={openedView.url}
        title='Chart display options'
        displayOptions={convertViewToSelection(openedView)}
        onSetDisplayOptions={handleSetDisplayOptions}
        configuration={props.configuration}
        onCancel={hideModal}
        showAddMetricModal={showAddMetricModal}
        hideAddMetricModal={hideAddMetricModal}
        minimumRequiredGroups={props.configuration.groups?.minimumRequired}
        isGlobal={openedView.isGlobal}
        isAvailableToExternalProfiles={openedView.isAvailableToExternalProfiles}
        investorPortalSections={investorPortalSections}
      />
    )
  }

  const renderSelectViewDropdown = () => {
    const options: IValueLabelSelectOption[] = []
    const viewList = allViews.data?.results ?? []

    for (const view of viewList) {
      const disabledView =
        firmConfiguration.data?.lookThroughType ===
          FIRMCONFIGURATION_LOOK_THROUGH_TYPE.DISABLED &&
        view.displayData?.showLookThrough

      if (
        props.investorPortalSection &&
        !view.investorPortalSections.find(
          (investorPortalSection) =>
            investorPortalSection.name === props.investorPortalSection
        )
      ) {
        continue
      }

      options.push({
        value: view.url,
        label: (
          <span>
            {view.name}
            {!props.readOnly && <ViewIcons view={view} />}
          </span>
        ), // Don't show icons in investor app + read-only views of advisor app
        menuItemControls:
          !props.readOnly &&
          (permissions.isAdministrator() || !view.isGlobal) ? (
            <IconButton
              small
              onClick={() => {
                setOpenedViewId(extractIdFromUrl(view.url))
                showModalUncontrolled()
              }}
            >
              <EditIcon fontSize='small' />
            </IconButton>
          ) : undefined,
        disabled: disabledView
      })
    }

    if (!props.readOnly) {
      options.push({
        icon: <SettingsIcon />,
        value: '__manage',
        label: 'Manage Views'
      })
    }

    const classes = classNames({
      [css.headerStyle]: props.headerStyleSelect,
      [css.buttonStyleSelect]: props.buttonStyleSelect
    })

    return (
      <ValueLabelSelect
        data-testid='select-view-options'
        size='small'
        noBorder
        greyBackground={props.greyBackground}
        whiteBackground={props.whiteBackground}
        boldText={props.boldText}
        noLeftPadding={props.headerStyleSelect}
        largerFontSize={props.largerFontSize}
        className={props.className || classes}
        value={selectedView?.url}
        options={options}
        dividerIndexes={viewList.length ? [viewList.length] : undefined}
        onChange={handleDropdownViewSelect}
      />
    )
  }

  return (
    <>
      {!props.hideSelect && (
        <>
          {renderSelectViewDropdown()}
          <Spacer vertical xxs />
        </>
      )}
      <ControlStateProvider loading={saving}>
        <Modal
          wide={!props.configuration.filters}
          fullScreenWide={!!props.configuration.filters}
          title={getTitle()}
          open={modalOpen}
          onClose={hideModal}
        >
          <ErrorBoundary
            resetId={openedView?.url ?? 'no-reset'}
            fallback={<ModalContentsErrorFallback onClose={hideModal} />}
          >
            {renderModalContent()}
          </ErrorBoundary>
        </Modal>
      </ControlStateProvider>
    </>
  )
}
