import React from 'react'

import {Autocomplete} from '@d1g1t/shared/components/mui/autocomplete'
import {TextField} from '@d1g1t/shared/components/mui/text-field'

import * as css from './style.scss'

export interface IDraggableAutocompleteProps {
  options: any[]
  getOptionLabel(option: any): string
  value: any
  onChange(option: any): void
  placeholder?: string
}

export const DraggableAutocomplete: React.FC<IDraggableAutocompleteProps> =
  function DraggableAutocomplete(props) {
    return (
      <Autocomplete
        disableClearable
        value={props.value}
        onChange={(e, value) => {
          props.onChange(value)
        }}
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            size='small'
            placeholder={props.placeholder}
            fullWidth
            InputProps={{
              ...params.InputProps,
              classes: {underline: css.autocompleteInputUnderline}
            }}
          />
        )}
      />
    )
  }
