import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useApi} from 'fairlight'

import {UserAccountExternalProfileEndpoints} from '@d1g1t/api/endpoints'
import {
  EXTERNALPROFILE_DATE_FORMAT,
  EXTERNALPROFILE_PREFERRED_LANGUAGE
} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {getLocale} from '@d1g1t/shared/polyfill'
import {useExternalProfile} from '@d1g1t/shared/wrappers/user-external-profile/hook'

import {useUserProfile} from '../user-profile'
import {AVAILABLE_LANGUAGES, ILocalizationSettings} from './typings'

/**
 * Used to pull the current preferences from the API, and to update the preference in the backend.
 * For all other purposes, the locale should be obtained from `useTranslation` hook.
 */
export function useExternalProfileLocalizationPreferences(): [
  ILocalizationSettings,
  {
    updateLocalizationSettings(settings: Partial<ILocalizationSettings>): void
  }
] {
  const api = useApi()

  const [dateFormat, , refetchUserProfile] = useUserLocalizationPreferences()
  const [profile, , {refetch: refetchExternalProfile}] = useExternalProfile()

  const handleUpdatePreferredSetting = async (setting: {
    language?: EXTERNALPROFILE_PREFERRED_LANGUAGE
    dateFormat?: EXTERNALPROFILE_DATE_FORMAT
  }) => {
    // PATCH
    await api.request(
      UserAccountExternalProfileEndpoints.partialUpdate(
        extractIdFromUrl(profile.url),
        {
          ...(setting.language && {preferredLanguage: setting.language}),
          ...(setting.dateFormat && {dateFormat: setting.dateFormat})
        }
      )
    )

    // Force update the API caches for the ext-profile amd `/me` resp
    refetchExternalProfile()
    if (refetchUserProfile) {
      refetchUserProfile.refetchUserProfile()
    }
  }

  const language: EXTERNALPROFILE_PREFERRED_LANGUAGE =
    profile?.preferredLanguage || EXTERNALPROFILE_PREFERRED_LANGUAGE.ENGLISH

  return [
    {language, dateFormat},
    {updateLocalizationSettings: handleUpdatePreferredSetting}
  ]
}

/**
 * Used to prevent rendering until language prefences from
 * external-profile are correctly synced with i18n instance.
 */
export function useGlobalLocalizationSettingsSynchronization(): [boolean] {
  const {i18n} = useTranslation()
  const [settings] = useExternalProfileLocalizationPreferences()
  const [profile] = useExternalProfile()

  useEffect(() => {
    if (
      settings?.language &&
      !settings?.language.includes(i18n.language) &&
      i18n.changeLanguage
    ) {
      i18n.changeLanguage(
        settings?.language.slice(0, 2) ?? AVAILABLE_LANGUAGES.ENGLISH
      )
    }
  }, [settings])

  return [settings?.language.includes(i18n.language) && !!profile]
}

/**
 * Synchronizes the authentication and localization settings for user.
 *
 * This function allows us to detect user browser language preferences and
 * based on it set up application language.
 *
 * @param investorFrenchEnabled - A boolean value indicating whether French language
 *                                feature is enabled based on a firm configuration
 */
export function useGlobalAuthLocalizationSettingsSynchronization(
  investorFrenchEnabled?: boolean
) {
  const api = useApi()
  const {i18n} = useTranslation()

  useEffect(() => {
    if (i18n.changeLanguage && investorFrenchEnabled) {
      const locale = getLocale()
      i18n.changeLanguage(locale)
    } else {
      // If this company does not support the French language,
      // then we forcibly set the english language
      // to avoid language conflicts between UI and PS
      api.setDefaultHeader(
        'Language',
        EXTERNALPROFILE_PREFERRED_LANGUAGE.ENGLISH
      )
    }
  }, [i18n])

  const onLanguageChange = (locale: AVAILABLE_LANGUAGES) => {
    if (i18n.changeLanguage) {
      i18n.changeLanguage(locale)
    }
  }

  return [onLanguageChange]
}

/**
 * Used to pull the current date format from the API, and to refetch the preference from the backend.
 */
export function useUserLocalizationPreferences(): [
  EXTERNALPROFILE_DATE_FORMAT,
  EXTERNALPROFILE_PREFERRED_LANGUAGE,
  {refetchUserProfile(): void}
] {
  const [userProfile, refetchUserProfile] = useUserProfile()

  const dateFormat =
    (userProfile?.user?.dateFormat as EXTERNALPROFILE_DATE_FORMAT) ||
    EXTERNALPROFILE_DATE_FORMAT.YMD
  const preferredLanguage =
    (userProfile?.user
      ?.preferredLanguage as EXTERNALPROFILE_PREFERRED_LANGUAGE) ||
    EXTERNALPROFILE_PREFERRED_LANGUAGE.ENGLISH

  return [dateFormat, preferredLanguage, refetchUserProfile]
}
