import React, {useEffect, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {
  ApiError,
  apiRequestId,
  ApiRequestMethod,
  ApiRequestParams,
  useApi
} from 'fairlight'
import produce from 'immer'
import {isEqual} from 'lodash'

import {TradingOrderEndpoints} from '@d1g1t/api/endpoints'
import {IChartTable, IPortfolioSecurityTarget} from '@d1g1t/api/models'

import {useInputState, useToggleState} from '@d1g1t/lib/hooks'
import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'

import {ComboKeyListener} from '@d1g1t/shared/components/combo-key-listener'
import {Flex} from '@d1g1t/shared/components/flex'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Alert} from '@d1g1t/shared/components/mui/alert'
import {Button} from '@d1g1t/shared/components/mui/button'
import {OutlinedInput} from '@d1g1t/shared/components/mui/outlined-input'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {
  TableGrid,
  TableGridBody,
  TableGridHeader
} from '@d1g1t/shared/components/table-grid'
import {H3, Span, Text} from '@d1g1t/shared/components/typography'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {
  IStandardTableCategory,
  StandardTable
} from '@d1g1t/shared/containers/standard-table'
import {
  DefaultAlertErrorFallback,
  ErrorBoundary,
  HandledBoundaryError,
  IErrorFallbackProps
} from '@d1g1t/shared/wrappers/error-boundary'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useStandardResponseQuery} from '@d1g1t/shared/wrappers/standard-response-query'

import {OperationCell} from '@d1g1t/advisor/containers/standard-table/components/custom-types/operation'
import {TradeLocations} from '@d1g1t/advisor/locations'
import {IDirectiveInputsBySecurityEntityId} from '@d1g1t/advisor/pages/trade-directive/typings'
import {RebalanceStrategiesTradeOrderQuantity} from '@d1g1t/advisor/pages/trade/track-portfolios/rebalance/components/trade-order-qty-cell'

import {
  EditableMarketDropdown,
  EditableTradeOrderDividendTreatment,
  EditableTradeOrderExpiryType,
  EditableTradeOrderQtyType,
  EditableTradeOrderQuantity,
  EditableTradeOrderTradeDate,
  EditableTradeOrderType
} from './components/editable-enums'
import {LimitPriceCell} from './components/limit-price-cell'
import {
  CATEGORY_IDS,
  RELATED_COMPONENT,
  TRADE_PREVIEW_TABLE_ID
} from './constants'
import {mapPreviewTableToTradeOrders} from './lib'

export * from './lib'
export * from './constants'

export interface ITradePreviewProps {
  /**
   * When passed it will offer the refresh button that updates the `requestParamsState` state with the requestParams value
   * causing a page rerender which in turn updates `tradePreviewTable` via api call on click of the refresh button.
   */
  manualRefreshButton?: boolean
  /**
   * Optional prop to render specific custom cells for different
   * trade preview drawers according to the related parent component
   */
  relatedComponent?: RELATED_COMPONENT
  requestParams: ApiRequestParams<ApiRequestMethod, IChartTable>
  /**
   * JSX content to add at top of Recommended Trades. Will go directly to the left
   * of submit trades button.
   */
  topContent?: (data: StandardResponse) => JSX.Element
  /**
   * Update this symbol to trigger a refetch for trade preview table.
   * Useful when table should be updated yet request body has not changed.
   */
  refreshToken?: symbol
  loadingChartData?: boolean
  /**
   * Called when trades have been successfully submitted.
   *
   * If passed, will not redirect to Manage Orders.
   */
  onSuccess?(): void
  directiveInputsBySecurityEntityId?: IDirectiveInputsBySecurityEntityId
  /** If here are accounts that are sleeve enabled */
  isSleeve?: boolean
}

const TOTAL_ERRORS = [
  'Total Target Weight of Overview Rebalancing Table must be 100%.',
  'Total Target Weight of Account Rebalancing Table must be 100%.'
]

class TotalTargetWeightError extends HandledBoundaryError {}

/**
 * Recommended Trades drawer component.
 */
export const TradePreview: React.FC<ITradePreviewProps> = (props) => {
  return (
    <ErrorBoundary
      resetId={props.requestParams ? apiRequestId(props.requestParams) : null}
      fallback={<TradePreviewErrorFallback />}
      handler={(error) => {
        if (
          error instanceof ApiError &&
          error.status === 400 &&
          Array.isArray(error.responseBody)
        ) {
          if (
            error.responseBody.filter((error) => TOTAL_ERRORS.includes(error))
              .length
          ) {
            return new TotalTargetWeightError()
          }
        }
      }}
    >
      <TradePreviewContents {...props} />
    </ErrorBoundary>
  )
}

const TradePreviewContents: React.FC<ITradePreviewProps> = ({
  requestParams,
  manualRefreshButton,
  loadingChartData,
  onSuccess,
  topContent,
  refreshToken,
  relatedComponent,
  directiveInputsBySecurityEntityId,
  isSleeve
}) => {
  const api = useApi()
  const {showSnackbar} = useSnackbar()
  const history = useHistory()
  const {handleUnexpectedError} = useErrorHandler()
  const [submitting, toggleSubmitting] = useToggleState(false)
  const [blotterName, setBlotterName] = useInputState('')

  /**
   * When the `manualRefreshButton` is supplied we do not set the `requestParamsState` state to contain the `requestParams`
   * because we do not want to call the `rebalancing/bulk/trade-preview/` api in this particular case. In this case, we only
   * call it when clicking the Refresh button in the Recommended Trades Drawer to prevent auto api calls on cell updates when the
   * `manualRefreshButton` is supplied, otherwise we run the auto api calls when cell values change.
   * Trade Directive page needs this.
   */
  const [requestParamsState, setRequestParamsState] = useState(
    !manualRefreshButton && requestParams
  )
  useEffect(() => {
    if (!manualRefreshButton && requestParams !== requestParamsState) {
      setRequestParamsState(requestParams)
    }
  }, [requestParams])

  const [tradePreviewTable, tradePreviewTableActions] =
    useStandardResponseQuery(requestParamsState, {
      dontReinitialize: true
    })

  useEffect(() => {
    if (!refreshToken) {
      return
    }

    tradePreviewTableActions.refetch()
  }, [refreshToken])

  const [deselectedIds, setDeselectedIds] = useState(new Set<string>())

  const setSelectedIds = (_, nextSelection: string[]) => {
    const selected = new Set(nextSelection)
    const deselected = new Set<string>()

    for (const item of tradePreviewTable.data.leafItems()) {
      if (!selected.has(item.id)) {
        deselected.add(item.id)
      }
    }

    setDeselectedIds(deselected)
  }

  const selectedIds = (() => {
    if (!tradePreviewTable.data) {
      return []
    }

    const selected = []
    for (const item of tradePreviewTable.data.leafItems()) {
      if (!deselectedIds.has(item.id)) {
        selected.push(item.id)
      }
    }

    return selected
  })()

  const handleCellValueChange = (
    item: StandardResponseItem,
    category: IStandardTableCategory,
    value: any,
    key: any
  ) => {
    tradePreviewTableActions.setData((prev) => {
      return produce(prev, (draft) => {
        const draftItem = draft.findItemById(item.id)
        const draftData = draftItem.getDatum(category.id)

        if (key) {
          draftData.key = key
        }

        draftData.value = value
        // Update the children to cache bust memo for the child values
        // which actually pull from the parent
        for (const childItem of draftItem) {
          if (key) {
            childItem.getDatum(category.id).key = key
          }

          childItem.getDatum(category.id).value = value
        }
        draftData.value = value
        // Update the children to cache bust memo for the child values
        // which actually pull from the parent
        for (const childItem of draftItem) {
          childItem.getDatum(category.id).value = value
        }

        // Update parent quantity when changed
        if (category.id === CATEGORY_IDS.QUANTITY && draftItem.itemParent) {
          const draftParent = draftItem.itemParent
          let sum = 0
          for (const childItem of draftParent) {
            sum += childItem.getValue(category.id) || 0
          }
          draftParent.getDatum(category.id).value = sum
        }
      })
    })
  }

  const orders = useMemo(() => {
    if (!tradePreviewTable.data) {
      return []
    }

    return mapPreviewTableToTradeOrders(
      tradePreviewTable.data.filter((item) => selectedIds.includes(item.id)),
      relatedComponent,
      directiveInputsBySecurityEntityId
    )
  }, [tradePreviewTable.data, selectedIds])

  const submitTrades = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    toggleSubmitting(true)

    const requestOrders = blotterName
      ? produce(orders, (draft) => {
          for (const order of draft) {
            order.blotterName = blotterName
          }
        })
      : orders

    try {
      if (isSleeve) {
        await api.request(
          TradingOrderEndpoints.createSleeveOrders(
            requestOrders,
            // @ts-ignore - seems like there is typing error within fairlight
            requestParams?.body.sleevesData.map((data) => ({
              ...data,
              targetValues: data.targetValues.filter(
                (targetValue: IPortfolioSecurityTarget) => targetValue.currency
              )
            }))
          )
        )
      } else {
        await api.request(TradingOrderEndpoints.create(requestOrders))
      }

      showSnackbar({
        variant: 'success',
        message: 'Trades have been submitted'
      })

      if (!onSuccess) {
        history.push(TradeLocations.manageOrders())
      } else {
        toggleSubmitting(false)
        onSuccess()
        tradePreviewTableActions.refetch()
      }
    } catch (error) {
      if (
        error instanceof ApiError &&
        error.status === 400 &&
        error.responseBody?.market?.[0] ===
          'Market is required for equity orders'
      ) {
        showSnackbar({
          variant: 'error',
          message: "You must specify a 'Market' for each trade."
        })
      } else {
        handleUnexpectedError(error, 'Unexpected error while submitting trades')
      }

      toggleSubmitting(false)
    }
  }

  const disableRefresh =
    submitting || isEqual(requestParamsState || {}, requestParams || {})

  return (
    <LoadingContainer
      loading={tradePreviewTable.loading || loadingChartData}
      LoadingSpinnerProps={{size: 40}}
    >
      <TableGrid>
        <TableGridHeader>
          <Flex column>
            <Flex justifySpaceBetween>
              <H3 semiBold>
                Recommended Trades {orders.length ? `(${orders.length})` : ''}
              </H3>
              <Flex alignCenter>
                {topContent && topContent(tradePreviewTable.data)}
                {manualRefreshButton && (
                  <>
                    <Tooltip title='Please refresh to sync the recommended trades.'>
                      <span>
                        <Button
                          contained
                          primary
                          disabled={disableRefresh}
                          spaceLeft={!!topContent}
                          data-testid='button-refresh-trades'
                          onClick={() => {
                            setRequestParamsState(requestParams)
                          }}
                          aria-label='Refresh'
                        >
                          Refresh
                        </Button>
                        {!disableRefresh && (
                          <ComboKeyListener
                            combo
                            leaderKey='shift'
                            actionKey='t'
                            onAction={() => {
                              setRequestParamsState(requestParams)
                            }}
                          />
                        )}
                      </span>
                    </Tooltip>
                    <Spacer vertical xxs />
                  </>
                )}
                <Button
                  disabled={!orders.length || submitting}
                  contained
                  primary
                  spaceLeft={!!topContent}
                  data-testid='button-submit-trades'
                  onClick={submitTrades}
                  aria-label='Submit'
                >
                  {(() => {
                    if (submitting) {
                      return 'Submitting'
                    }

                    if (orders.length > 0) {
                      return `Submit ${orders.length} trade${
                        orders.length > 1 ? 's' : ''
                      }`
                    }

                    return 'Submit trades'
                  })()}
                </Button>
                <Span style={{margin: '0 5px'}}>to</Span>
                <Tooltip title='Set a blotter name for this set of trades'>
                  <OutlinedInput
                    style={{height: 36}}
                    placeholder='Blotter name'
                    value={blotterName}
                    onChange={setBlotterName}
                  />
                </Tooltip>
              </Flex>
            </Flex>
            <Spacer xs />
            <Text>
              The following trades would be required to complete the
              rebalancing, changing these fields will alter the weight and value
              amounts for the accounts.
            </Text>
          </Flex>
        </TableGridHeader>
        <TableGridBody>
          <div>
            {tradePreviewTable.data && (
              <StandardTable
                initializeItemsFullyExpanded
                id={TRADE_PREVIEW_TABLE_ID}
                debounceDelayTime={250}
                table={tradePreviewTable.data}
                categoriesOverride={{
                  [CATEGORY_IDS.OPERATION]: OperationCell,
                  [CATEGORY_IDS.QUANTITY_TYPE]:
                    relatedComponent !==
                      RELATED_COMPONENT.REBALANCE_STRATEGIES &&
                    EditableTradeOrderQtyType,
                  [CATEGORY_IDS.QUANTITY]:
                    relatedComponent === RELATED_COMPONENT.REBALANCE_STRATEGIES
                      ? RebalanceStrategiesTradeOrderQuantity
                      : EditableTradeOrderQuantity,
                  [CATEGORY_IDS.ORDER_TYPE]: EditableTradeOrderType,
                  [CATEGORY_IDS.GOOD_UNTIL]: EditableTradeOrderExpiryType,
                  [CATEGORY_IDS.DIVIDEND_TREATMENT]:
                    EditableTradeOrderDividendTreatment,
                  [CATEGORY_IDS.LIMIT_PRICE]: LimitPriceCell,
                  [CATEGORY_IDS.TRADE_DATE]: EditableTradeOrderTradeDate,
                  [CATEGORY_IDS.MARKET]: EditableMarketDropdown
                }}
                checkedRows={selectedIds}
                onCheckboxedRows={setSelectedIds}
                onCellValueChange={handleCellValueChange}
              />
            )}
          </div>
        </TableGridBody>
      </TableGrid>
    </LoadingContainer>
  )
}

export const TradePreviewErrorFallback: React.FC<IErrorFallbackProps> = (
  props
) => {
  if (props.error instanceof TotalTargetWeightError) {
    return (
      <Alert severity='warning'>Total target weight must add up to 100%.</Alert>
    )
  }

  if (
    props.error instanceof ApiError &&
    props.error.status === 400 &&
    props.error.responseBody &&
    Array.isArray(props.error.responseBody)
  ) {
    return (
      <Alert severity='warning'>
        {typeof props.error.responseBody[0] === 'string'
          ? props.error.responseBody[0]
          : Object.values(props.error.responseBody[0])[0]}
      </Alert>
    )
  }

  return <DefaultAlertErrorFallback {...props} />
}
