import React, {useState} from 'react'

import {useApiQuery} from 'fairlight'

import {PortfolioEndpoints} from '@d1g1t/api/endpoints'
import {ALL_MODELS} from '@d1g1t/api/models'

import {Flex} from '@d1g1t/shared/components/flex'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H5} from '@d1g1t/shared/components/typography'
import {ISearchResult, Search} from '@d1g1t/shared/containers/search'

interface ITargetPortoflioProps {
  referencePortfolio: string
  onReferencePortfolioChange: (payload: string) => void
  onClearReferencePortfolio: () => void
}

export const TargetPortfolio: React.FC<ITargetPortoflioProps> = (props) => {
  const [targetPortfolioName, settargetPortfolioName] = useState<string>(null)
  const [portfolios] = useApiQuery(PortfolioEndpoints.list(), {
    fetchPolicy: 'cache-and-fetch'
  })

  if (!portfolios.data) {
    return null
  }

  const handleResultSelect = (searchResult: ISearchResult) => {
    settargetPortfolioName(searchResult.displayText)
    props.onReferencePortfolioChange(searchResult.url)
  }
  const handleClear = () => {
    props.onClearReferencePortfolio()
    settargetPortfolioName(null)
  }

  return (
    <>
      <H5>Target Portfolio</H5>
      {targetPortfolioName ? (
        <>
          <H5>: </H5>
          <Spacer vertical xxs />
          <H5 bold> {targetPortfolioName}</H5>
        </>
      ) : (
        <Flex minWidth={460}>
          <Spacer vertical xxs />
          <Search
            placeholder='Search to add a target portfolio...'
            clearOnResultSelect
            disableGrouping
            searchCharacterMinimum={0}
            searchBy={[ALL_MODELS.PORTFOLIO]}
            onResultSelect={handleResultSelect}
          />
        </Flex>
      )}

      <Spacer xs vertical />
      {props.referencePortfolio && <Button onClick={handleClear}>Clear</Button>}
    </>
  )
}
