import React from 'react'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import {Flex} from '@d1g1t/shared/components/flex'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'

import * as css from './style.scss'

interface ILeftDrawerTabProps {
  hintText: string
  onClick(): void
}

export const LeftDrawerTab: React.FC<ILeftDrawerTabProps> = (props) => {
  return (
    <div className={css.toggleDrawerTabContainer}>
      <Tooltip
        title={props.hintText || 'Toggle Left Drawer'}
        placement='bottom'
      >
        <Flex
          alignCenter
          justifyFlexEnd
          className={css.toggleDrawerTab}
          onClick={props.onClick}
        >
          <ChevronRightIcon />
        </Flex>
      </Tooltip>
    </div>
  )
}
