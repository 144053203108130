import React, {useCallback, useMemo} from 'react'

import escapeStringRegexp from 'escape-string-regexp'

import {classNames} from '@d1g1t/lib/class-names'

import {
  ILinkProps,
  RouterWrappedLink
} from '@d1g1t/shared/components/router-link'
import {Text} from '@d1g1t/shared/components/typography'
import {useNumberFormatter} from '@d1g1t/shared/wrappers/formatter'

import {ISearchResult} from '../typings'

import * as searchSuggestionCss from '../search-suggestions/style.scss'
import * as css from './style.scss'

export interface ISearchSuggestionsItemProps {
  selected: boolean
  text: string
  searchResult: ISearchResult
  to?: ILinkProps['to']
  account?: boolean
  showDetails: boolean
  /**
   * Used w/ `showDetails` to show Funds Available instead of 'AUM when available'.
   */
  showDetailsFundsAvailable: boolean
  onItemClick?(searchResult?: ISearchResult, event?: React.MouseEvent<any>)
  optionRenderOverride?: (props: {
    option: ISearchResult
    text: string
  }) => React.ReactNode
}

export function highlightMatch(
  text: string,
  matchString: string
): React.ReactNode {
  if (!matchString || matchString.length < 2 || !text) {
    return text
  }

  const result = []
  const searchRegExp = new RegExp(escapeStringRegexp(matchString), 'i')

  let searchString = text
  let match = searchString.match(searchRegExp)
  let i = 0
  while (match) {
    if (match.index > 0) {
      result.push(<span key={i++}>{searchString.slice(0, match.index)}</span>)
    }
    searchString = searchString.slice(match.index)
    result.push(
      <strong key={i++}>{searchString.substr(0, matchString.length)}</strong>
    )

    searchString = searchString.slice(matchString.length)
    match = searchString.match(searchRegExp)
  }
  if (searchString.length > 0) {
    result.push(<span key={i}>{searchString}</span>)
  }

  return result
}

export const SearchSuggestionItem: React.FC<ISearchSuggestionsItemProps> = (
  props
) => {
  const handleItemClick = useCallback(
    (event: React.MouseEvent<any>) => {
      if (typeof props.onItemClick === 'function') {
        props.onItemClick(props.searchResult, event)
      }
    },
    [props.onItemClick, props.searchResult]
  )

  const currencyFormatter = useNumberFormatter({
    style: 'currency'
  })

  const fundsAvailable = (() => {
    if (!props.showDetails) {
      return null
    }

    const fundsAvailable = props.showDetailsFundsAvailable
      ? props.searchResult.fundsAvailable
      : props.searchResult.aum || props.searchResult.fundsAvailable

    if (!Number.isFinite(fundsAvailable)) {
      return null
    }

    return fundsAvailable
  })()

  const highlightedMatch = useMemo(() => {
    return highlightMatch(props.searchResult.displayText, props.text)
  }, [props.searchResult, props.text])

  const showCity = !!(props.showDetails && props.searchResult.city)

  const node = (
    <>
      <div
        className={css.highlightedMatch}
        data-searchtext={props.searchResult.displayText}
      >
        {props.optionRenderOverride ? (
          props.optionRenderOverride({
            option: props.searchResult,
            text: props.text
          })
        ) : (
          <Text>{highlightedMatch}</Text>
        )}
      </div>
      {!props.optionRenderOverride && (
        <>
          <div
            className={classNames(
              searchSuggestionCss.details,
              searchSuggestionCss.aumDetails
            )}
          >
            <Text>{currencyFormatter.formatJSX(fundsAvailable || '')}</Text>
          </div>
          <div
            className={classNames({[searchSuggestionCss.details]: showCity})}
          >
            <Text>
              {props.showDetails && props.searchResult.city
                ? props.searchResult.city
                : ''}
            </Text>
          </div>
        </>
      )}
    </>
  )

  const baseClassName = classNames(searchSuggestionCss.row, {
    [searchSuggestionCss.selected]: props.selected
  })

  if (props.to) {
    return (
      <RouterWrappedLink
        className={classNames(baseClassName, css.searchSuggestionLink)}
        to={props.to}
        onClick={handleItemClick}
      >
        {node}
      </RouterWrappedLink>
    )
  }

  return (
    <div className={baseClassName} onClick={handleItemClick}>
      {node}
    </div>
  )
}
