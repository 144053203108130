import React from 'react'
import {useTranslation} from 'react-i18next'

import produce from 'immer'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import {useDeepMemo, useStableCallback, useToggleState} from '@d1g1t/lib/hooks'

import {FormControl} from '@d1g1t/shared/components/mui/form-control'
import {FormHelperText} from '@d1g1t/shared/components/mui/form-helper-text'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {InputAdornment} from '@d1g1t/shared/components/mui/input-adornment'
import {InputLabel} from '@d1g1t/shared/components/mui/input-label'
import {
  IOutlinedInputProps,
  OutlinedInput
} from '@d1g1t/shared/components/mui/outlined-input'

import {MULTIPLE_VALUES} from '../constants'
import {useFormFieldControl, useMuiLabelWidth} from '../hook'
import {getFormFieldErrorState} from '../lib'
import {IFormFieldProps} from '../typings'

export interface IOutlinedInputFieldProps extends IFormFieldProps {
  outlinedInputProps?: Omit<IOutlinedInputProps, 'smallHeight'>
  disabled?: boolean
  hideLabel?: boolean
  /**
   * MUI helperText being passed down as a prop
   */
  helperText?: string
  /**
   * If you don't want 1Password to offer to save or fill on a field
   */
  ignore1Password?: boolean
  smallHeight?: boolean
  /**
   * Shows open-eye/closed-eye icons to show/hide the input value.
   *
   * NOTE: this is slightly different from using `outlinedInputProps.type = 'password'`
   * because this does not treat the input field as a password input, but still offers the
   * visibility controls, which ultimately ensures the browser does not offer password auto-fill
   * functionality.
   *
   * Use `outlinedInputProps.type = 'password'` for password inputs.
   * Use `allowVisibilityToggle = true` for input fields where input value should be shown/hidden.
   */
  allowVisibilityToggle?: boolean
  allowedCharacters?: RegExp
  /**
   * Override errors in meta object
   */
  error?: string
  /**
   * Allows you to override the display value. Useful when the value in the form is not readable,
   * and you want to produce a readable text from that. E.g. in `EditSecurities` component.
   * @param fieldValue - current field value pulled from the form
   * @returns display text string based on `fieldValue`
   */
  fieldValueDisplayText?(fieldValue: any): string
  /**
   * Fires on field click, also turns the field into a read-only field.
   * E.g. Admin \> Securities page's quick look drawer when editing a vectorized property.
   */
  onClick?(): void
  testId?: string
}

/**
 * `formik`-compatible `OutlinedInput` field
 */
export const OutlinedInputField: React.FC<IOutlinedInputFieldProps> = (
  props
) => {
  const {
    label,
    hideLabel: labelHidden,
    outlinedInputProps = {},
    disabled,
    helperText,
    testId
  } = props
  const [field, meta] = useFormFieldControl(props)
  const {hasError, errorText} = getFormFieldErrorState(meta)

  const [labelWidth, labelRef] = useMuiLabelWidth()
  const {t} = useTranslation()

  const [showFieldValue, toggleShowFieldValue] = useToggleState(false)
  const outlinedInputType = showFieldValue ? 'text' : 'password'

  const id = props.id || props.name
  const errorTextId = `${id}-error-text`

  const handleChange = useStableCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.allowedCharacters) {
        field.onChange(
          produce(event, (draft) => {
            draft.target.value = RegExp(props.allowedCharacters).test(
              event.target.value
            )
              ? event.target.value
              : field.value
          })
        )
      } else {
        field.onChange(event)
      }
    }
  )
  const handleBlur = useStableCallback((e: React.FocusEvent<any>) => {
    field.onBlur(e)

    if (props.onBlur) {
      props.onBlur(e)
    }
  })

  const isMultipleValues = field.value === MULTIPLE_VALUES
  return useDeepMemo(() => {
    const value = (() => {
      if (isMultipleValues) {
        return '{Multiple Values}'
      }

      if (props.fieldValueDisplayText) {
        return props.fieldValueDisplayText(field.value)
      }

      if (field.value || field.value === 0) {
        return field.value
      }

      return ''
    })()

    const isPasswordField = props.outlinedInputProps?.type === 'password'
    const showVisibilityToggleControls =
      isPasswordField || props.allowVisibilityToggle

    return (
      <FormControl
        variant='outlined'
        fullWidth
        error={hasError}
        disabled={disabled}
      >
        <InputLabel
          smallHeight={props.smallHeight}
          innerRef={labelRef}
          htmlFor={id}
          hidden={labelHidden}
        >
          {t(label)}
        </InputLabel>
        <OutlinedInput
          id={id}
          aria-describedby={errorTextId}
          labelWidth={labelHidden ? 0 : labelWidth}
          inputProps={{
            'data-1p-ignore': props.ignore1Password,
            'data-testid': testId
          }}
          {...produce(outlinedInputProps, (draft) => {
            draft.type = showVisibilityToggleControls
              ? outlinedInputType
              : undefined

            // eslint-disable-next-line no-nested-ternary
            draft.autoComplete = isPasswordField
              ? 'current-password'
              : showVisibilityToggleControls
              ? 'one-time-code' // hack to prevent autofill
              : undefined

            if (isMultipleValues) {
              delete draft.inputComponent

              draft.style = {
                fontWeight: 'bold',
                fontStyle: 'italic'
              }

              // select all (prevent modifying multiple values text)
              draft.onClick = (event) => {
                event.persist()
                setTimeout(() => {
                  ;(
                    event.target as HTMLInputElement | HTMLTextAreaElement
                  ).select()
                }, 0)
              }
            }
          })}
          name={field.name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          smallHeight={props.smallHeight}
          onClick={props.onClick}
          readOnly={!!props.onClick}
          endAdornment={
            showVisibilityToggleControls ? (
              <InputAdornment position='end'>
                <IconButton onClick={toggleShowFieldValue}>
                  {showFieldValue ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : undefined
          }
        />
        <FormHelperText id={errorTextId}>
          {hasError ? errorText : helperText ?? errorText}
        </FormHelperText>
      </FormControl>
    )
  }, [props, labelWidth, field.name, field.value, meta, showFieldValue])
}
