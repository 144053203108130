import React, {useMemo, useState} from 'react'

import {useApiQuery} from 'fairlight'

import BackArrowIcon from '@material-ui/icons/ArrowBack'

import {RebalancingRuleEndpoints} from '@d1g1t/api/endpoints'
import {IRebalancingRule} from '@d1g1t/api/models'

import {EditableModalTitle} from '@d1g1t/shared/components/editable-modal-title'
import {Modal} from '@d1g1t/shared/components/modal'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {
  ErrorBoundary,
  IModalContentsErrorFallbackProps,
  ModalContentsErrorFallback
} from '@d1g1t/shared/wrappers/error-boundary'

import {EditRule} from './edit-rule'
import {RuleList} from './rule-list'

interface IManageRebalanceRulesModalProps {
  initialSelectedRuleUrl?: string
  isTradeDirectivePage?: boolean
  onClose()
  onChangeSelectedRuleUrl(url: string)
}

export const ManageRebalanceRulesModal: React.FC<
  IManageRebalanceRulesModalProps
> = (props) => {
  return (
    <ErrorBoundary
      resetId='no-reset'
      fallback={<ManageRebalanceRulesErrorFallback onClose={props.onClose} />}
    >
      <ManageRebalanceRulesModalContents {...props} />
    </ErrorBoundary>
  )
}

/**
 * Define a separate modal for the error fallback since the
 * manage rebalance rules <Modal> title contains considerable
 * logic that may fail.
 */
const ManageRebalanceRulesErrorFallback: React.FC<
  IModalContentsErrorFallbackProps
> = (props) => (
  <Modal open title='Rebalancing Rules' onClose={props.onClose}>
    <ModalContentsErrorFallback {...props} />
  </Modal>
)

const ManageRebalanceRulesModalContents: React.FC<
  IManageRebalanceRulesModalProps
> = (props) => {
  const [selectedRuleUrl, setSelectedRuleUrl] = useState<string>(
    props.initialSelectedRuleUrl
  )
  const [rebalancingRules, rebalancingRulesQueryActions] = useApiQuery(
    RebalancingRuleEndpoints.list(),
    {
      fetchPolicy: 'cache-and-fetch',
      dontReinitialize: true
    }
  )
  const selectedRule = useMemo(() => {
    if (selectedRuleUrl && rebalancingRules.data) {
      return rebalancingRules.data.results.find(
        ({url}) => selectedRuleUrl === url
      )
    }

    return null
  }, [selectedRuleUrl, rebalancingRules.data])

  const [selectedRuleName, setSelectedRuleName] = useState<string>(
    selectedRule?.name
  )

  const updateRule = (updatedRule: IRebalancingRule) => {
    rebalancingRulesQueryActions.setData({
      ...rebalancingRules.data,
      results: rebalancingRules.data.results.map((rule) =>
        rule.url === selectedRuleUrl ? updatedRule : rule
      )
    })
  }

  return (
    <Modal
      open
      onClose={props.onClose}
      title={(() => {
        if (selectedRuleUrl) {
          return (
            <>
              <Tooltip title='Back to rules list'>
                <Button
                  primary
                  contained
                  small
                  onClick={() => setSelectedRuleUrl(null)}
                >
                  <BackArrowIcon fontSize='small' />
                  Rules
                </Button>
              </Tooltip>
              <Spacer xs vertical />
              <EditableModalTitle
                name={selectedRule.name}
                onViewNameChange={(name) => {
                  setSelectedRuleName(name)
                }}
              />
            </>
          )
        }

        return 'Manage Rules'
      })()}
    >
      <ErrorBoundary
        resetId={selectedRuleUrl}
        fallback={<ModalContentsErrorFallback onClose={props.onClose} />}
      >
        {rebalancingRules.data && (
          <>
            {selectedRule ? (
              <EditRule
                rule={selectedRule}
                isTradeDirectivePage={props.isTradeDirectivePage}
                selectedRuleName={selectedRuleName}
                onSave={(rule) => {
                  props.onChangeSelectedRuleUrl(rule.url)
                  props.onClose()
                  updateRule(rule)
                }}
              />
            ) : (
              <RuleList
                rules={rebalancingRules}
                onSelectRule={(rule) => setSelectedRuleUrl(rule.url)}
                onAddRule={(rule) => {
                  rebalancingRulesQueryActions.setData({
                    ...rebalancingRules.data,
                    count: rebalancingRules.data.count++,
                    results: rebalancingRules.data.results.concat(rule)
                  })
                }}
                onDeleteRule={(rule) => {
                  rebalancingRulesQueryActions.setData({
                    ...rebalancingRules.data,
                    count: rebalancingRules.data.count--,
                    results: rebalancingRules.data.results.filter(
                      ({url}) => rule.url !== url
                    )
                  })
                }}
              />
            )}
          </>
        )}
      </ErrorBoundary>
    </Modal>
  )
}
