import {ITradeOrder} from '@d1g1t/api/models'

export interface IBuySellEquitiesProps {
  /**
   * Initial instrument to trade with
   */
  initialInstrumentId?: string
  /**
   * Called after trades are successfully submitted
   */
  onSubmittedTrades?(): void
  preTradeValidationMessages: string[]
  /**
   * Returns `true` for the tradable account
   */
  isTradable?: boolean
}

export enum EQUITIES_ETFS_FIELD_NAMES {
  accountUrl = 'accountUrl', // must not change
  instrumentUrl = 'instrumentUrl', // must not change
  operation = 'operation', // must not change
  instrumentName = 'instrumentName',
  market = 'market',
  qty = 'qty',
  qtyType = 'qtyType',
  type = 'type',
  limitPrice = 'limitPrice',
  expiryType = 'expiryType',
  expiryDate = 'expiryDate',

  // additional fields
  isAllOrNone = 'isAllOrNone',
  isIceberg = 'isIceberg',
  isAnonymous = 'isAnonymous',
  isOddLot = 'isOddLot',
  isInsider = 'isInsider',
  proStatus = 'proStatus',
  isSellAll = 'isSellAll',
  broker = 'broker',
  commission = 'commission',
  commissionType = 'commissionType',
  notes = 'notes',
  instructions = 'instructions',
  isSolicited = 'isSolicited',
  expectedPrice = 'expectedPrice'
}

export interface IEquitiesEtfsFormValues {
  [EQUITIES_ETFS_FIELD_NAMES.operation]: ITradeOrder['operation']
  [EQUITIES_ETFS_FIELD_NAMES.instrumentUrl]: ITradeOrder['instrument']
  [EQUITIES_ETFS_FIELD_NAMES.instrumentName]: string
  [EQUITIES_ETFS_FIELD_NAMES.accountUrl]: string
  [EQUITIES_ETFS_FIELD_NAMES.market]: ITradeOrder['market']
  [EQUITIES_ETFS_FIELD_NAMES.qty]: ITradeOrder['qty']
  [EQUITIES_ETFS_FIELD_NAMES.qtyType]: ITradeOrder['qtyType']
  [EQUITIES_ETFS_FIELD_NAMES.type]: ITradeOrder['type']
  [EQUITIES_ETFS_FIELD_NAMES.limitPrice]: ITradeOrder['limitPrice']
  [EQUITIES_ETFS_FIELD_NAMES.expiryType]: ITradeOrder['expiryType']
  [EQUITIES_ETFS_FIELD_NAMES.expiryDate]: ITradeOrder['expiryDate']

  // additional fields
  [EQUITIES_ETFS_FIELD_NAMES.isAllOrNone]: ITradeOrder['isAllOrNone']
  [EQUITIES_ETFS_FIELD_NAMES.isIceberg]: ITradeOrder['isIceberg']
  [EQUITIES_ETFS_FIELD_NAMES.isAnonymous]: ITradeOrder['isAnonymous']
  [EQUITIES_ETFS_FIELD_NAMES.isOddLot]: ITradeOrder['isOddLot']
  [EQUITIES_ETFS_FIELD_NAMES.isInsider]: ITradeOrder['isInsider']
  [EQUITIES_ETFS_FIELD_NAMES.proStatus]: ITradeOrder['proStatus']
  [EQUITIES_ETFS_FIELD_NAMES.isSellAll]: ITradeOrder['isSellAll']
  [EQUITIES_ETFS_FIELD_NAMES.broker]: ITradeOrder['broker']
  [EQUITIES_ETFS_FIELD_NAMES.commission]: ITradeOrder['commission']
  [EQUITIES_ETFS_FIELD_NAMES.commissionType]: ITradeOrder['commissionType']
  [EQUITIES_ETFS_FIELD_NAMES.notes]: ITradeOrder['notes']
  [EQUITIES_ETFS_FIELD_NAMES.instructions]: ITradeOrder['instructions']
  [EQUITIES_ETFS_FIELD_NAMES.isSolicited]: ITradeOrder['isSolicited']
  [EQUITIES_ETFS_FIELD_NAMES.expectedPrice]: ITradeOrder['expectedPrice']
}
