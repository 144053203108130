import {
  all,
  call,
  delay,
  getContext,
  put,
  select,
  takeLatest
} from 'redux-saga/effects'

import {Api} from 'fairlight'
import produce from 'immer'
import {ActionType, getType} from 'typesafe-actions'

import {
  FirmConfigurationEndpoints,
  FXRateEndpoints,
  PortfolioRebalanceEndpoints
} from '@d1g1t/api/endpoints'
import {IChartTable, IFirmConfiguration, IFxRate} from '@d1g1t/api/models'
import {IApiListResponse} from '@d1g1t/api/typings'

import {reduxPut} from '@d1g1t/lib/sagas'
import {StandardResponse} from '@d1g1t/lib/standard-response'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {errorHandlerActions} from '@d1g1t/shared/wrappers/error-handler'

import {changeAllocationActions} from './actions'
import {
  calculateUpdateChartPayload,
  getEditedValueErrorsBySecurityAndAccount,
  someEditedAllocationValueErrors
} from './lib'
import {IAccountAllocationState} from './typings'

function* loadAccountAllocationChart(
  action: ActionType<typeof changeAllocationActions.loadAccountAllocationChart>
) {
  yield put(changeAllocationActions.loadAccountAllocationChartRequest())

  try {
    const [{response, fxRates}, baseCurrency]: [
      {response: StandardResponse; fxRates: IFxRate[]},
      string
    ] = yield all([
      call(loadAllocationChartAndFxRates, action),
      call(loadBaseCurrency)
    ])

    // load FX rates which we can use to calculate values

    yield put(
      changeAllocationActions.loadAccountAllocationChartSuccess({
        response,
        fxRates,
        baseCurrency
      })
    )
  } catch (error) {
    yield reduxPut(
      errorHandlerActions.handleError({
        error,
        snackbarMessage: 'An unexpected error occurred recommending trades.',
        unwrapErrorMessages: true
      })
    )

    yield put(changeAllocationActions.loadAccountAllocationChartFailure(error))
  }
}

function* loadAllocationChartAndFxRates(
  action: ActionType<typeof changeAllocationActions.loadAccountAllocationChart>
) {
  const api: Api = yield getContext('api')

  const chart: IChartTable = yield call(
    api.request,
    PortfolioRebalanceEndpoints.bulkChart(
      produce(action.payload, (draft) => {
        draft.instruments = draft.instruments.filter(
          ({allowTrading}) => allowTrading
        )
      })
    )
  )

  const response = new StandardResponse(chart)

  // need to load fx rates in order to calculate
  // currency conversions on the front-end when
  // calculating the chart payload
  const fxRatesResponse: IApiListResponse<IFxRate> = yield call(
    api.request,
    FXRateEndpoints.list({
      date__lte: action.payload.asOfDate,
      foreign: findAllCurrenciesInResponse(response)
    })
  )

  return {response, fxRates: fxRatesResponse.results}
}

function findAllCurrenciesInResponse(
  allocationChartResponse: StandardResponse
): string[] {
  const currencies = new Set<string>()

  // find currencies of each account via market value data
  for (const item of allocationChartResponse) {
    currencies.add(item.getDatum('market_value')?.options?.currency)
  }

  // find currencies of each security (category)
  for (const category of allocationChartResponse.dataCategories) {
    if (category.options?.currency) {
      currencies.add(category.options.currency)
    }
  }

  return Array.from(currencies)
}

/**
 * Loads the base currency name of the firm
 */
function* loadBaseCurrency() {
  const api: Api = yield getContext('api')
  const firmConfiguration: IFirmConfiguration = yield call(
    api.request,
    FirmConfigurationEndpoints.current(),
    {fetchPolicy: 'cache-first'}
  )
  return extractIdFromUrl(firmConfiguration.baseCurrency)
}

function* updateChart(
  action: ActionType<
    | typeof changeAllocationActions.loadAccountAllocationChartRequest
    | typeof changeAllocationActions.updateAccountAllocationChartValueRequest
  >
) {
  if (
    action.type ===
    getType(changeAllocationActions.loadAccountAllocationChartRequest)
  ) {
    // cancel
    return
  }

  yield delay(1000)

  const api: Api = yield getContext('api')
  const state: IAccountAllocationState = yield select()

  if (
    someEditedAllocationValueErrors(
      getEditedValueErrorsBySecurityAndAccount(state)
    )
  ) {
    // cancel due to cell-level errors
    return
  }

  try {
    const nextChart: IChartTable = yield call(
      api.request,
      PortfolioRebalanceEndpoints.bulkChartUpdate(
        calculateUpdateChartPayload(
          state,
          action.payload.includePending,
          action.payload.includeCashEquivalents,
          api,
          action.payload.checkedAccountsIds
        )
      )
    )
    yield put(
      changeAllocationActions.updateAccountAllocationChart(
        new StandardResponse(nextChart)
      )
    )
  } catch (error) {
    yield reduxPut(
      errorHandlerActions.handleError({
        error,
        snackbarMessage:
          'An unexpected error occurred while updating the account allocations.',
        unwrapErrorMessages: true
      })
    )
  }
}

export function* changeAllocationSaga() {
  yield takeLatest(
    getType(changeAllocationActions.loadAccountAllocationChart),
    loadAccountAllocationChart
  )

  yield takeLatest(
    [
      getType(changeAllocationActions.updateAccountAllocationChartValueRequest),
      getType(changeAllocationActions.loadAccountAllocationChartRequest)
    ],
    updateChart
  )
}
