import React from 'react'

import MuiCircularProgress, {
  CircularProgressProps
} from '@material-ui/core/CircularProgress'

export interface ICircularProgressProps extends CircularProgressProps {}

export const CircularProgress = React.forwardRef(
  (props: ICircularProgressProps, ref?: React.Ref<HTMLDivElement>) => {
    return <MuiCircularProgress ref={ref} {...props} />
  }
)
