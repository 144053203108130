import * as Yup from 'yup'

import {TRADEORDER_EXPIRY_TYPE, TRADEORDER_TYPE} from '@d1g1t/api/models'

import {EQUITIES_ETFS_FIELD_NAMES} from './typings'

export const EQUITIES_ETFS_VALIDATION_SCHEMA = Yup.object({
  [EQUITIES_ETFS_FIELD_NAMES.instrumentUrl]: Yup.string()
    .nullable()
    .required()
    .label('Ticker'),
  [EQUITIES_ETFS_FIELD_NAMES.market]: Yup.string().required().label('Market'),
  [EQUITIES_ETFS_FIELD_NAMES.qty]: Yup.number()
    .required()
    .label('Quantity')
    .nullable(),
  [EQUITIES_ETFS_FIELD_NAMES.type]: Yup.string().required().label('Order Type'),
  [EQUITIES_ETFS_FIELD_NAMES.limitPrice]: Yup.number()
    .label('Limit Price')
    .when(EQUITIES_ETFS_FIELD_NAMES.type, {
      is: TRADEORDER_TYPE.LIMIT,
      then: Yup.number().required().nullable(),
      otherwise: Yup.number().nullable()
    }),
  [EQUITIES_ETFS_FIELD_NAMES.operation]: Yup.string(),
  [EQUITIES_ETFS_FIELD_NAMES.qtyType]: Yup.string(),
  [EQUITIES_ETFS_FIELD_NAMES.expiryType]: Yup.string(),
  [EQUITIES_ETFS_FIELD_NAMES.expiryDate]: Yup.string()
    .label('Expiry Date')
    .nullable()
    .when(EQUITIES_ETFS_FIELD_NAMES.expiryType, {
      is: TRADEORDER_EXPIRY_TYPE.GTD,
      then: Yup.string().required(),
      otherwise: Yup.string()
    }),
  [EQUITIES_ETFS_FIELD_NAMES.isAllOrNone]: Yup.boolean(),
  [EQUITIES_ETFS_FIELD_NAMES.isIceberg]: Yup.boolean(),
  [EQUITIES_ETFS_FIELD_NAMES.isAnonymous]: Yup.boolean(),
  [EQUITIES_ETFS_FIELD_NAMES.isOddLot]: Yup.boolean(),
  [EQUITIES_ETFS_FIELD_NAMES.isInsider]: Yup.boolean(),
  [EQUITIES_ETFS_FIELD_NAMES.proStatus]: Yup.string(),
  [EQUITIES_ETFS_FIELD_NAMES.isSellAll]: Yup.boolean(),
  [EQUITIES_ETFS_FIELD_NAMES.broker]: Yup.string(),
  [EQUITIES_ETFS_FIELD_NAMES.commission]: Yup.number().nullable(),
  [EQUITIES_ETFS_FIELD_NAMES.commissionType]: Yup.string(),
  [EQUITIES_ETFS_FIELD_NAMES.notes]: Yup.string(),
  [EQUITIES_ETFS_FIELD_NAMES.instructions]: Yup.string(),
  [EQUITIES_ETFS_FIELD_NAMES.isSolicited]: Yup.boolean(),
  [EQUITIES_ETFS_FIELD_NAMES.expectedPrice]: Yup.number()
    .nullable()
    .label('Expected Price')
    .when(EQUITIES_ETFS_FIELD_NAMES.type, {
      is: TRADEORDER_TYPE.MARKET,
      then: Yup.number().required()
    })
})
