import React from 'react'

import SearchIcon from '@material-ui/icons/Search'

import {useToggleState} from '@d1g1t/lib/hooks'

import {TextInput} from '@d1g1t/shared/components/form/text-input'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'

export interface ITableSearchControlProps {
  /**
   * The value in the text box.
   */
  value: string
  /**
   * The placeholder text displayed when the text box is empty.
   */
  placeholder?: string
  /**
   * Callback trigger when the value in the text box changes.
   */
  onChange(event): void
}

/**
 * A collapsible search control.
 *
 * This is rendered as a button with the search icon in its "collapsed" state,
 * and a text box with search styling + a clear button in its "expanded" state.
 *
 * Created specifically to meet the design requirements of the <WidgetTitleBar>
 */
export const TableSearchControl: React.FC<ITableSearchControlProps> = (
  props
) => {
  const [searchBoxCollapsed, , closeSearch, openSearch] = useToggleState(true)

  const renderClosedSearch = () => {
    return (
      <IconButton
        onClick={openSearch}
        data-testid='table-search-control-expand-button'
      >
        <SearchIcon />
      </IconButton>
    )
  }
  const renderOpenSearch = () => {
    return (
      <TextInput
        autoFocus
        hasLeftSearchIcon
        rootProps={{
          style: {
            width: 400
          }
        }}
        onClear={() => {
          props.onChange('')
          closeSearch()
        }}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        data-testid='table-search-control-text-input'
      />
    )
  }

  return searchBoxCollapsed ? renderClosedSearch() : renderOpenSearch()
}
