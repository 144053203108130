import {UI_PERMISSIONS} from '@d1g1t/api/models'

export const TOUCH_SENSITIVITY = 30
export const TOUCH_START_X_DEFAULT_VALUE = -1

// Allows for keyboard navigation in conjunction with IMenuDefinition.nestedItems.keyboardNavLetter
export const LEADER_KEY = 'd'

/**
 * These slugs are exported for the primary side menu so that values only need changed in one location.
 */
export enum PRIMARY_SIDEMENU_SLUGS {
  MONITOR = 'monitor',
  MANAGE_CLIENTS = 'manage-clients',
  MANAGE_CLIENT_PORTFOLIOS = 'manage-clients-portfolios',
  MANAGE_CLIENT_HOLDINGS = 'manage-clients-holdings',
  MANAGE_CLIENT_HOUSEHOLDS = 'manage-clients-households',
  MANAGE_CLIENT_CLIENTS = 'manage-clients-clients',
  MANAGE_CLIENT_ACCOUNTS = 'manage-clients-accounts',
  BILLING = 'biling',
  BILLING_MANAGE_FEES = 'billing-manage-fees',
  BILLING_CONFIGURE_BILLING = 'billing-configure-billing',
  MANAGE_FUNDS = 'manage-funds',
  PORTFOLIO_PROGRAMS = 'portfolio-programs',
  PORTFOLIOS_PROGRAMS_MODEL_PORTFOLIOS = 'portfolios-programs-model-portfolios',
  PORTFOLIOS_PROGRAMS_INVESTMENT_PROGRAMS = 'portfolios-programs-investment-programs',
  SECURITIES = 'securities',
  EXPLORE = 'explore',
  EXPLORE_COMPARE_PORTFOLIOS = 'explore-compare-portfolios',
  TRADE = 'trade',
  TRADE_BUY_SELL = 'trade-buy-sell',
  TRADE_CHANGE_ALLOCATION = 'trade-change-allocation',
  TRADE_REBALANCE = 'trade-rebalance',
  TRADE_TRACK_STRATEGIES = 'trade-track-strategies',
  TRADE_TRADE_BLOTTER = 'trade-trade-blotter',
  TRACK_COMPLIANCE = 'track-compliance',
  TRACK_COMPLIANCE_TRACK_MANDATES = 'track-compliance-track-mandates',
  DOCUMENTS = 'documents',
  DOCUMENTS_REPORTS = 'documents-reports',
  DOCUMENTS_MANAGE_DOCUMENTS = 'documents-manage-documents',
  DMS = 'dms',
  CONTACTS = 'contacts',
  ADMINISTRATION = 'administration',
  ADMINISTRATION_USERS = 'administration-users',
  ADMINISTRATION_CLIENT_ASSIGNMENTS = 'administration-client-assignments',
  ADMINISTRATION_HOUSEHOLD_MANAGEMENT = 'administration-households-management',
  ADMINISTRATION_ACCOUNT_MANAGEMENT = 'administration-account-management',
  ADMINISTRATION_TRANSACTIONS = 'administration-transactions',
  ADMINISTRATION_MANAGE_SECURITIES = 'administration-manage-securities',
  ADMINISTRATION_TRADE_RESTRICTIONS = 'administration-trade-restrictions',
  ADMINISTRATION_BENCHMARKS = 'administration-benchmarks',
  ADMINISTRATION_TRADE_LOCATION_RULES = 'administration-trade-location-rules',
  ADMINISTRATION_PROCESSING = 'administration-processing',
  ADMINISTRATION_DATASETS = 'administration-datasets',
  ADMINISTRATION_COLLECTIONS = 'administration-collections'
}

export const PRIMARY_SIDEMENU_PERMISSIONS_MAP: Record<
  PRIMARY_SIDEMENU_SLUGS,
  UI_PERMISSIONS
> = {
  [PRIMARY_SIDEMENU_SLUGS.MONITOR]: UI_PERMISSIONS.MONITOR,
  [PRIMARY_SIDEMENU_SLUGS.MANAGE_CLIENTS]: UI_PERMISSIONS.MANAGE_CLIENTS,
  [PRIMARY_SIDEMENU_SLUGS.MANAGE_CLIENT_PORTFOLIOS]:
    UI_PERMISSIONS.MANAGE_CLIENTS_PORTFOLIOS,
  [PRIMARY_SIDEMENU_SLUGS.MANAGE_CLIENT_HOLDINGS]:
    UI_PERMISSIONS.MANAGE_CLIENTS_HOLDINGS,
  [PRIMARY_SIDEMENU_SLUGS.MANAGE_CLIENT_HOUSEHOLDS]:
    UI_PERMISSIONS.MANAGE_CLIENTS_HOUSEHOLDS,
  [PRIMARY_SIDEMENU_SLUGS.MANAGE_CLIENT_CLIENTS]:
    UI_PERMISSIONS.MANAGE_CLIENTS_CLIENTS,
  [PRIMARY_SIDEMENU_SLUGS.MANAGE_CLIENT_ACCOUNTS]:
    UI_PERMISSIONS.MANAGE_CLIENTS_ACCOUNTS,
  [PRIMARY_SIDEMENU_SLUGS.BILLING]: UI_PERMISSIONS.BILLING,
  [PRIMARY_SIDEMENU_SLUGS.BILLING_CONFIGURE_BILLING]:
    UI_PERMISSIONS.BILLING_MANAGE_BILLING,
  [PRIMARY_SIDEMENU_SLUGS.BILLING_MANAGE_FEES]:
    UI_PERMISSIONS.BILLING_CALCULATED_FEES,
  [PRIMARY_SIDEMENU_SLUGS.MANAGE_FUNDS]: UI_PERMISSIONS.MANAGE_FUNDS,
  [PRIMARY_SIDEMENU_SLUGS.PORTFOLIO_PROGRAMS]:
    UI_PERMISSIONS.PORTFOLIO_PROGRAMS,
  [PRIMARY_SIDEMENU_SLUGS.PORTFOLIOS_PROGRAMS_MODEL_PORTFOLIOS]:
    UI_PERMISSIONS.PORTFOLIOS_PROGRAMS_MODEL_PORTFOLIOS,
  [PRIMARY_SIDEMENU_SLUGS.PORTFOLIOS_PROGRAMS_INVESTMENT_PROGRAMS]:
    UI_PERMISSIONS.PORTFOLIOS_PROGRAMS_INVESTMENT_PROGRAMS,
  [PRIMARY_SIDEMENU_SLUGS.SECURITIES]: UI_PERMISSIONS.SECURITIES,
  [PRIMARY_SIDEMENU_SLUGS.EXPLORE]: UI_PERMISSIONS.EXPLORE,
  [PRIMARY_SIDEMENU_SLUGS.EXPLORE_COMPARE_PORTFOLIOS]:
    UI_PERMISSIONS.EXPLORE_COMPARE_PORTFOLIOS,
  [PRIMARY_SIDEMENU_SLUGS.TRADE]: UI_PERMISSIONS.TRADE,
  [PRIMARY_SIDEMENU_SLUGS.TRADE_BUY_SELL]: UI_PERMISSIONS.TRADE_BUY_SELL,
  [PRIMARY_SIDEMENU_SLUGS.TRADE_CHANGE_ALLOCATION]:
    UI_PERMISSIONS.TRADE_CHANGE_ALLOCATION,
  [PRIMARY_SIDEMENU_SLUGS.TRADE_REBALANCE]: UI_PERMISSIONS.TRADE_REBALANCE,
  [PRIMARY_SIDEMENU_SLUGS.TRADE_TRACK_STRATEGIES]:
    UI_PERMISSIONS.TRADE_TRACK_STRATEGIES,
  [PRIMARY_SIDEMENU_SLUGS.TRADE_TRADE_BLOTTER]:
    UI_PERMISSIONS.TRADE_TRADE_BLOTTER,
  [PRIMARY_SIDEMENU_SLUGS.TRACK_COMPLIANCE]: UI_PERMISSIONS.TRACK_COMPLIANCE,
  [PRIMARY_SIDEMENU_SLUGS.TRACK_COMPLIANCE_TRACK_MANDATES]:
    UI_PERMISSIONS.TRACK_COMPLIANCE_TRACK_MANDATES,
  [PRIMARY_SIDEMENU_SLUGS.DOCUMENTS]: UI_PERMISSIONS.DOCUMENTS,
  [PRIMARY_SIDEMENU_SLUGS.DOCUMENTS_REPORTS]: UI_PERMISSIONS.DOCUMENTS_REPORTS,
  [PRIMARY_SIDEMENU_SLUGS.DOCUMENTS_MANAGE_DOCUMENTS]:
    UI_PERMISSIONS.DOCUMENTS_MANAGE_DOCUMENTS,
  [PRIMARY_SIDEMENU_SLUGS.DMS]: UI_PERMISSIONS.DMS,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION]: UI_PERMISSIONS.ADMINISTRATION,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_USERS]:
    UI_PERMISSIONS.ADMINISTRATION_USERS,
  [PRIMARY_SIDEMENU_SLUGS.CONTACTS]: UI_PERMISSIONS.CONTACTS,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_CLIENT_ASSIGNMENTS]:
    UI_PERMISSIONS.ADMINISTRATION_CLIENT_ASSIGNMENTS,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_HOUSEHOLD_MANAGEMENT]:
    UI_PERMISSIONS.ADMINISTRATION_HOUSEHOLD_MANAGEMENT,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_ACCOUNT_MANAGEMENT]:
    UI_PERMISSIONS.ADMINISTRATION_ACCOUNT_MANAGEMENT,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_TRANSACTIONS]:
    UI_PERMISSIONS.ADMINISTRATION_TRANSACTIONS,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_MANAGE_SECURITIES]:
    UI_PERMISSIONS.ADMINISTRATION_MANAGE_SECURITIES,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_TRADE_RESTRICTIONS]:
    UI_PERMISSIONS.ADMINISTRATION_TRADE_RESTRICTIONS,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_TRADE_LOCATION_RULES]:
    UI_PERMISSIONS.ADMINISTRATION_TRADE_LOCATION_RULES,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_BENCHMARKS]:
    UI_PERMISSIONS.ADMINISTRATION_BENCHMARKS,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_PROCESSING]:
    UI_PERMISSIONS.ADMINISTRATION_PROCESSING,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_DATASETS]:
    UI_PERMISSIONS.ADMINISTRATION_DATASETS,
  [PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_COLLECTIONS]:
    UI_PERMISSIONS.ADMINISTRATION_COLLECTIONS
}
