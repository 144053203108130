import {round} from 'lodash'

import {
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  ITradeOrder,
  TRADEORDER_QTY_TYPE,
  TRADEORDER_TYPE
} from '@d1g1t/api/models'

import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {IDirectiveInputsBySecurityEntityId} from '@d1g1t/advisor/pages/trade-directive/typings'

import {CATEGORY_IDS, RELATED_COMPONENT} from './constants'

/**
 * Given a `StandardResponseItem`, will return a market price for that item
 *
 * @param directiveInputsBySecurityEntityId - object with directive inputs by security id
 * @param item - standard response item
 * @returns market price for each item
 */
export const getExpectedPrice = (
  directiveInputsBySecurityEntityId: IDirectiveInputsBySecurityEntityId,
  item: StandardResponseItem
): number => {
  for (const [id, data] of Object.entries(directiveInputsBySecurityEntityId)) {
    if (id === extractIdFromUrl(item.getValue('instrument_url'))) {
      return data.market_price
    }
  }
}

export const mapPreviewTableToTradeOrders = (
  response: StandardResponse,
  relatedComponent: RELATED_COMPONENT,
  directiveInputsBySecurityEntityId: IDirectiveInputsBySecurityEntityId
): ITradeOrder[] => {
  const orders: ITradeOrder[] = []

  for (const item of response.leafItems()) {
    const tradeOrder = {} as ITradeOrder

    const operation =
      item.getClosestKey(CATEGORY_IDS.OPERATION) ??
      item.getClosestValue(CATEGORY_IDS.OPERATION)

    const isSellAll = item.getClosestValue(CATEGORY_IDS.IS_SELL_ALL)
    const isDscFree = item.getClosestValue(CATEGORY_IDS.IS_DSC_FREE)

    const isFund =
      (item.getClosestKey(CATEGORY_IDS.TRADING_TYPE) ||
        item.getClosestValue(CATEGORY_IDS.TRADING_TYPE)) ===
      FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND

    const qtyType =
      item.getClosestKey(CATEGORY_IDS.QUANTITY_TYPE) ??
      item.getClosestValue(CATEGORY_IDS.QUANTITY_TYPE)

    const orderType = item.getClosestValue(CATEGORY_IDS.ORDER_TYPE)

    const sleeve = item.getDatum(CATEGORY_IDS.SLEEVE_URL)

    // for Limit type the expected price is null
    const expectedPrice =
      relatedComponent === RELATED_COMPONENT.TRADE_DIRECTIVE &&
      orderType === TRADEORDER_TYPE.MARKET
        ? getExpectedPrice(directiveInputsBySecurityEntityId, item)
        : null

    const qty = ((): number => {
      if ((isSellAll || isDscFree) && isFund) {
        return null
      }

      const qtyCategoryId = ((): CATEGORY_IDS => {
        if (relatedComponent === RELATED_COMPONENT.REBALANCE_STRATEGIES) {
          return qtyType === TRADEORDER_QTY_TYPE.UNITS
            ? CATEGORY_IDS.QUANTITY
            : CATEGORY_IDS.TRADE_VALUE
        }

        return CATEGORY_IDS.QUANTITY
      })()

      return round(
        Math.abs(
          item.data.find((dataItem) => dataItem.categoryId === qtyCategoryId)
            .value
        ),
        4
      )
    })()

    if (qty === 0) {
      // don't create a trade order
      continue
    }

    tradeOrder.operation = operation
    tradeOrder.qty = qty
    tradeOrder.isSellAll = isSellAll
    tradeOrder.qtyType = qtyType
    tradeOrder.expectedPrice = expectedPrice
    tradeOrder.sleeve = sleeve?.value || null

    tradeOrder.custodianAccount = item.getClosestValue(
      CATEGORY_IDS.CUSTODIAN_ACCOUNT_URL
    )

    tradeOrder.type =
      item.getClosestKey(CATEGORY_IDS.ORDER_TYPE) ??
      item.getClosestValue(CATEGORY_IDS.ORDER_TYPE)

    tradeOrder.limitPrice = item.getClosestValue(CATEGORY_IDS.LIMIT_PRICE)
    tradeOrder.account = item.getValue(CATEGORY_IDS.ACCOUNT_URL)
    tradeOrder.instrument = item.getClosestValue(CATEGORY_IDS.INSTRUMENT_URL)

    tradeOrder.expiryType =
      item.getClosestKey(CATEGORY_IDS.GOOD_UNTIL) ??
      item.getClosestValue(CATEGORY_IDS.GOOD_UNTIL)

    tradeOrder.instructions = item.getClosestValue(CATEGORY_IDS.INSTRUCTIONS)
    tradeOrder.notes = item.getClosestValue(CATEGORY_IDS.NOTES)

    if (isFund) {
      tradeOrder.isDscFree = isDscFree
      tradeOrder.dividendTreatment =
        item.getClosestKey(CATEGORY_IDS.DIVIDEND_TREATMENT) ??
        item.getClosestValue(CATEGORY_IDS.DIVIDEND_TREATMENT)
      tradeOrder.fundCode = item.getClosestValue(CATEGORY_IDS.FUND_CODE)
      tradeOrder.market = item.getClosestKey(CATEGORY_IDS.MARKET)
    } else {
      tradeOrder.market = item.getClosestKey(CATEGORY_IDS.MARKET)
    }

    for (const key of Object.keys(tradeOrder)) {
      if (tradeOrder[key] == null) {
        delete tradeOrder[key]
      }
    }

    tradeOrder.commission = item.getClosestValue(CATEGORY_IDS.COMMISSION)
    tradeOrder.commissionType =
      item.getClosestKey(CATEGORY_IDS.COMMISSION_TYPE) ?? undefined

    orders.push(tradeOrder)
  }

  return orders
}
