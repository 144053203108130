import React, {memo, useMemo} from 'react'
import {useDrop} from 'react-dnd'

import {isNil} from 'lodash'

import RightIcon from '@material-ui/icons/ChevronRight'
import CollapseAllIcon from '@material-ui/icons/UnfoldLess'
import ExpandAllIcon from '@material-ui/icons/UnfoldMore'

import {CHARTTABLEOPTIONS_STATUS} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {
  performanceMarkStart,
  performanceMeasureFromStartMark
} from '@d1g1t/lib/performance'

import {
  ActionDropdown,
  QuickActions
} from '@d1g1t/shared/components/action-dropdown'
import {Flex} from '@d1g1t/shared/components/flex'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {
  IStateBlockProps,
  StateBlock
} from '@d1g1t/shared/components/state-block'
import {StatusCell} from '@d1g1t/shared/containers/standard-table/components/custom-types/status-cell'
import {
  CHECK_ROW_TYPE,
  DRAG_ROW_TYPE,
  ROW_STYLE_STATE
} from '@d1g1t/shared/containers/standard-table/constants'

import {StandardTableItem} from '../../typings'
import {DataCellValue} from './data-cell-value'
import {isEqualDataCell, isGrandParent, itemDetails} from './lib'
import {NameCellIcon} from './name-cell-icon'
import {IDataCellProps} from './typings'

import * as commonCss from '@d1g1t/shared/containers/standard-table/styles.scss'
import * as css from './styles.scss'

const COLOUR_WIDTH = 5

/**
 * `DataCell` element is rendered for all non-header and drag cells. It is also called
 * when using custom renderers to set position and other events.
 *
 * General note about performance
 * ------------------------------
 *
 * Any changes in `DataCell` can easily cause performance issues in the table.
 * Each cell may be rendered ~200-300 times for larger tables (such as Manage Orders table).
 *
 * This of course means that just 1ms of work in this method, translates to 200ms+
 * of added render time (which makes the app feel super janky while scrolling).
 *
 * To make it easier to debug performance issues, it is generally preferable to
 * separate out react nodes into their own `FC` rather than rendering inline or
 * inside a `useMemo`. This will separate out the nodes in the performance tools
 * and allows us to optimize smaller pieces.
 */
export const DataCell: React.FC<IDataCellProps> = memo((props) => {
  performanceMarkStart('DataCell')

  const isCheckBoxColumn = props.category?.id === CHECK_ROW_TYPE
  const handleCellMouseEnter = () => {
    props.onMouseEnter(props.rowIndex, props.columnIndex)
  }

  const handleCellMouseLeave = () => {
    props.onMouseLeave(props.rowIndex, props.columnIndex)
  }

  const handleExpandClick = (event: React.MouseEvent) => {
    props.onExpandClick(props.item)

    event.stopPropagation()
  }

  const handleExpandAllClick = (event: React.MouseEvent) => {
    props.onExpandAllClick(props.item)

    event.stopPropagation()
  }

  const handleCollapseAllClick = (event: React.MouseEvent) => {
    props.onCollapseAllClick(props.item)

    event.stopPropagation()
  }

  const isDataCellEditable = props.item?.isEditable(props.category?.id)

  const handleCellClick = (event: React.SyntheticEvent) => {
    if (props.category && isCheckBoxColumn) {
      return
    }

    if (props.checkRowOnCellClick && !props.setActiveEditingCellLocation) {
      props.onCheck(props.item, props.rowIndex, event, props.checked)
    }

    if (
      props.setActiveEditingCellLocation &&
      !props.activeEditingCellLocation &&
      isDataCellEditable
    ) {
      props.setActiveEditingCellLocation({
        categoryId: props.category.id,
        itemId: props.item.id
      })
    }

    if (props.totalRow && props.onTotalRowClick) {
      return props.onTotalRowClick(props.item.id)
    }

    if (!props.onClick) {
      return
    }

    props.onClick(props.item.id)
  }

  const totalRowExpandControls = useMemo(() => {
    if (!props.expandableRows || props.expandAllDisabled) {
      return null
    }

    return (
      <>
        {props.onExpandAllRows && !props.tableIsFullyExpanded && (
          <IconButton
            small
            className={css.button}
            onClick={props.onExpandAllRows}
          >
            <ExpandAllIcon fontSize='small' />
          </IconButton>
        )}
        {props.onCollapseAllRows && props.tableIsFullyExpanded && (
          <IconButton
            small
            className={css.button}
            onClick={props.onCollapseAllRows}
          >
            <CollapseAllIcon fontSize='small' />
          </IconButton>
        )}
      </>
    )
  }, [
    props.expandableRows,
    props.onExpandAllRows,
    props.tableIsFullyExpanded,
    props.onCollapseAllRows
  ])

  // First column should be always treated as name column. As sometimes it can have different name
  // Also we bind actions only to the name columns
  const isNameCell = useMemo(() => {
    if (!props.categories) {
      return
    }

    const categories = props.categories.filter(
      (category) => !(category.options && category.options.hidden)
    )

    return (
      categories && props.category && categories[0].id === props.category.id
    )
  }, [props.categories, props.category && props.category.id])

  const nameAdornment = useMemo(() => {
    if (!(isNameCell && props.nameAdornment)) {
      return null
    }

    return (
      <Flex grow={1} justifyFlexEnd data-testid='table-row-nameAdornment'>
        {props.nameAdornment(props.item, props.categories)}
      </Flex>
    )
  }, [props.nameAdornment, isNameCell, props.item, props.categories])

  const expandIcon = (() => {
    if (!isNameCell) {
      return null
    }

    if (
      props.item &&
      !props.item.parent &&
      !props.item.items &&
      props.expandableRows
    ) {
      return <Spacer vertical custom={22} />
    }

    if (!props.item.group) {
      return null
    }

    return (
      <ExpandButton
        expanded={props.item.expanded}
        onClick={handleExpandClick}
      />
    )
  })()

  const expandCollapseAllIcon = (() => {
    if (
      props.expandAllDisabled ||
      !isNameCell ||
      props.totalRow ||
      !props.item.group ||
      !isGrandParent(props.item)
    ) {
      return null
    }

    if (props.item.fullyExpanded) {
      return (
        <IconButton small onClick={handleCollapseAllClick}>
          <CollapseAllIcon
            fontSize='small'
            className={classNames(css.icon, {
              [css.hide]: !props.hoverRow
            })}
          />
        </IconButton>
      )
    }

    return (
      <IconButton small onClick={handleExpandAllClick}>
        <ExpandAllIcon
          fontSize='small'
          className={classNames(css.icon, {
            [css.hide]: !props.hoverRow
          })}
        />
      </IconButton>
    )
  })()

  const modelIcon = useMemo(() => {
    if (!props.showIcon || !isNameCell) {
      return null
    }

    const {model} = itemDetails(props.item)

    return <NameCellIcon model={model} />
  }, [props.showIcon, isNameCell, props.item])

  const colours: string[] = (() => {
    if (!(isNameCell && props.colourCallout)) {
      return []
    }

    const oneOrManyColours = props.colourCallout(props.item)
    return Array.isArray(oneOrManyColours)
      ? oneOrManyColours
      : [oneOrManyColours]
  })()

  const colourCallout = colours.map((colour, idx) => (
    <div
      key={colour}
      style={{
        position: 'absolute',
        left: idx * COLOUR_WIDTH,
        top: 0,
        height: props.style.height,
        width: COLOUR_WIDTH,
        backgroundColor: colour
      }}
    />
  ))

  const leftPadding = (() => {
    if (isNameCell && !props.totalRow) {
      let leftPadding = 5

      leftPadding += props.item.level * 18

      if (!props.item.group && props.item.level > 0) {
        leftPadding += 17
      }

      if (!props.item.options?.pinned && !!props.item.options?.subtable) {
        leftPadding += 17
      }

      leftPadding += colours.length * COLOUR_WIDTH

      return `${leftPadding}px`
    }

    return null
  })()

  const valueNode = (() => {
    const mainValueNode = (
      <>
        <DataCellValue
          {...props}
          isCompactMode={props.isCompactMode}
          isFirstVisibleDataColumn={isNameCell}
        />
        <DataCellAdornment item={props.item} category={props.category} />
        {nameAdornment}
      </>
    )

    if (props.adornmentTooltip) {
      return (
        <props.adornmentTooltip item={props.item} category={props.category}>
          <Flex grow alignCenter>
            {mainValueNode}
          </Flex>
        </props.adornmentTooltip>
      )
    }

    return mainValueNode
  })()

  const benchmarkValueNodes = (() => {
    if (!props.item?.benchmarks) {
      return null
    }

    return (
      <Flex column grow>
        {props.item.benchmarks.map((benchmarkItem, i) => (
          <DataCellValue
            key={`benchmark-${i}`}
            benchmarkSubrow
            {...props}
            isCompactMode={props.isCompactMode}
            item={new StandardTableItem(benchmarkItem)}
          />
        ))}
      </Flex>
    )
  })()

  const [, dropRef] = useDrop({
    accept: DRAG_ROW_TYPE,
    hover() {
      props.onDragHover(props.rowIndex)
    },
    drop() {
      props.onDrop(props.rowIndex)
    }
  })

  const cellheight = props.isCompactMode ? 24 : 36

  performanceMeasureFromStartMark('DataCell')

  return (
    <div key={`${props.item?.id}_${props.rowIndex}_${props.columnIndex}`}>
      <div
        data-row={props.rowIndex}
        data-column={props.columnIndex}
        data-item-id={props.item?.id}
        data-category-id={props.category?.id}
        className={classNames(commonCss.cell, css.hoverIcons, {
          [commonCss.static]: props.renderStatic,
          [commonCss.hoverRow]: props.hoverRow,
          [commonCss.pinnedRow]: props.item?.options?.pinned,
          [commonCss.drageSourceRow]: props.dragSource,
          [commonCss.dropTargetRow]: props.dropTarget,
          [commonCss.resizingColumn]: props.resizingColumn,
          [commonCss.highlightedRow]:
            props.rowStyleState === ROW_STYLE_STATE.HIGHLIGHTED,
          [commonCss.totalRow]: props.totalRow || props.item?.options?.pinned,
          [css.selectableRow]:
            (props.selectable || props.checkRowOnCellClick) &&
            !props.setActiveEditingCellLocation,
          [css.singleCellEditor]:
            !props.activeEditingCellLocation &&
            !!props.setActiveEditingCellLocation &&
            !isCheckBoxColumn &&
            isDataCellEditable,
          [css.noBottomBorder]: !!benchmarkValueNodes,
          [css.checkbox]: isCheckBoxColumn
        })}
        style={{
          ...props.style,
          paddingLeft: leftPadding,
          height: !!benchmarkValueNodes ? cellheight : props.style.height // ignore computed height, and assume 36/24px height(depending on the mode)
        }}
        onMouseEnter={handleCellMouseEnter}
        onMouseLeave={props.onMouseLeave && handleCellMouseLeave}
        onClick={handleCellClick}
        ref={dropRef}
      >
        {colourCallout}
        {expandIcon}
        {modelIcon}
        {valueNode}
        <Flex
          grow={
            isNameCell &&
            !nameAdornment &&
            !props.totalRow &&
            !!(props.actions || expandCollapseAllIcon)
          }
          justifyFlexEnd
          alignCenter
        >
          {expandCollapseAllIcon}
          <DataCellActions
            isTotalRow={props.totalRow}
            isNameCell={isNameCell}
            totalRowExpandControls={totalRowExpandControls}
            actions={props.actions}
            item={props.item}
            category={props.category}
            categories={props.categories}
            rowIndex={props.rowIndex}
            columnIndex={props.columnIndex}
            hoverRow={props.hoverRow}
            rowStyleState={props.rowStyleState}
          />
        </Flex>
      </div>
      {benchmarkValueNodes && ( // benchmarks are rendered as sub rows, or sub cells
        <div
          className={classNames(commonCss.cell, {
            [commonCss.hoverRow]: props.hoverRow,
            [commonCss.resizingColumn]: props.resizingColumn
          })}
          style={{
            ...props.style,
            height: props.item.benchmarks.length * cellheight,
            top: Number(props.style.top?.toString()) + cellheight,
            paddingLeft: leftPadding
          }}
        >
          {benchmarkValueNodes}
        </div>
      )}
    </div>
  )
}, isEqualDataCell)

interface IExpandButtonProps {
  expanded: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const ExpandButton: React.FC<IExpandButtonProps> = memo((props) => {
  return (
    <IconButton
      data-testid='button-expand-row'
      small
      onClick={props.onClick}
      className={css.iconButton}
    >
      <RightIcon
        fontSize='small'
        className={classNames(css.icon, {
          [css.rotate90]: props.expanded
        })}
      />
    </IconButton>
  )
})

interface IDataCellActionsProps
  extends Pick<
    IDataCellProps,
    | 'actions'
    | 'item'
    | 'categories'
    | 'rowIndex'
    | 'columnIndex'
    | 'category'
    | 'rowStyleState'
    | 'hoverRow'
  > {
  isTotalRow: boolean
  isNameCell: boolean
  totalRowExpandControls: JSX.Element
}

const DataCellActions: React.FC<IDataCellActionsProps> = memo((props) => {
  if (props.isTotalRow && props.isNameCell) {
    return props.totalRowExpandControls
  }

  if (!(props.isNameCell && props.actions)) {
    return null
  }

  const actions = props.actions(props.item, props.categories)
  if (!actions) {
    return null
  }

  const allActionsPrimary = actions.every((action) => !!action.quickAccess)

  return (
    <>
      <QuickActions
        items={actions.filter((action) => action.quickAccess)}
        hoverRow={props.hoverRow}
        rowStyleState={props.rowStyleState}
        allActionsPrimary={allActionsPrimary}
      />
      {!allActionsPrimary && (
        <ActionDropdown
          rowIndex={props.rowIndex}
          columnIndex={props.columnIndex}
          itemId={props.item?.id}
          categoryId={props.category?.id}
          items={actions}
        />
      )}
    </>
  )
})

function getAdornmentColour(
  status: CHARTTABLEOPTIONS_STATUS
): Partial<IStateBlockProps> {
  switch (status) {
    case CHARTTABLEOPTIONS_STATUS.ERROR:
      return {
        red: true,
        lightFont: true
      }
    case CHARTTABLEOPTIONS_STATUS.WARNING:
      return {
        orange: true,
        lightFont: true
      }
    case CHARTTABLEOPTIONS_STATUS.NEUTRAL:
      return {
        white: true,
        outline: true
      }
    case CHARTTABLEOPTIONS_STATUS.SUCCESS:
      return {
        green: true,
        lightFont: true
      }
    default:
      return {}
  }
}

interface IDataCellAdornment
  extends Pick<IDataCellProps, 'item' | 'category'> {}

const DataCellAdornment: React.FC<IDataCellAdornment> = memo((props) => {
  if (!props.category) {
    return null
  }

  // const adornmentText = getItemAdornment(props.item, props.category.id)
  const adornmentText = props.item.getAdornment(props.category.id)
  const status = props.item.getStatus(props.category.id)
  const value = props.item.getValue(props.category.id)
  const warnings = props.item.getWarnings(props.category.id)

  if (!status) {
    return null
  }

  return adornmentText ? (
    <Flex justifyFlexStart>
      <StateBlock {...getAdornmentColour(status)} text={adornmentText} />
    </Flex>
  ) : (
    <Flex
      grow={isNil(value)}
      justifyFlexEnd={!isNil(value)}
      justifyCenter={isNil(value)}
    >
      <StatusCell
        status={status}
        categoryId={props.category.id}
        warnings={warnings}
      />
    </Flex>
  )
})
