import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import {Checkbox} from '@d1g1t/shared/components/mui/checkbox'
import {Radio} from '@d1g1t/shared/components/mui/radio'
import {CHECKED_STATE} from '@d1g1t/shared/containers/standard-table/constants'

import * as css from './styles.scss'

interface IStandardTableCheckboxProps {
  /**
   * Provides a Radio selection control.
   */
  singleSelect?: boolean
  onChange(event: React.ChangeEvent)
  checked: CHECKED_STATE
  disabled?: boolean
}

export const StandardTableCheckbox: React.FC<IStandardTableCheckboxProps> = ({
  onChange,
  checked,
  singleSelect,
  disabled
}) => {
  if (singleSelect) {
    return (
      <Radio
        classes={{
          root: classNames(css.root, css.iconButton)
        }}
        color='primary'
        onChange={onChange}
        checked={checked === CHECKED_STATE.CHECKED}
      />
    )
  }

  return (
    <Checkbox
      classes={{
        root: classNames(css.root, css.iconButton)
      }}
      color='primary'
      disabled={disabled}
      checked={checked === CHECKED_STATE.CHECKED}
      indeterminate={checked === CHECKED_STATE.PARTIAL}
      onChange={onChange}
    />
  )
}
