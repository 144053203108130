import produce from 'immer'

import {ITradeOrderPreviewRequestBody} from '@d1g1t/api/endpoints'
import {TRADEORDER_OPERATION, TRADEORDER_QTY_TYPE} from '@d1g1t/api/models'

import {qtyChangeIsNumber} from '@d1g1t/advisor/containers/trade-order-form/lib'
import {
  IMutualFundFormValues,
  QTY_TYPE
} from '@d1g1t/advisor/containers/trade-order-form/mutual-funds/typings'

export interface ITransformPayload
  extends Omit<ITradeOrderPreviewRequestBody, 'qtyType' | 'quantityChange'> {
  quantityChange:
    | ITradeOrderPreviewRequestBody['quantityChange']
    | IMutualFundFormValues['qty']
  qtyType: ITradeOrderPreviewRequestBody['qtyType'] | QTY_TYPE
}
export function transformPreviewRequestPayload(
  requestBody: ITransformPayload
): ITradeOrderPreviewRequestBody {
  const {operation, quantityChange, qtyType, isSellAll} = requestBody

  return produce(requestBody as ITradeOrderPreviewRequestBody, (draft) => {
    draft.quantityChange = ((): number => {
      if (
        !qtyChangeIsNumber(quantityChange) ||
        [
          TRADEORDER_OPERATION.SELL_ALL,
          TRADEORDER_OPERATION.SWITCH_ALL
        ].includes(operation) ||
        qtyType === QTY_TYPE.DSC_FREE
      ) {
        return undefined
      }

      return operation === TRADEORDER_OPERATION.SELL
        ? -Math.abs(quantityChange)
        : Math.abs(quantityChange)
    })()

    draft.qtyType =
      qtyType === QTY_TYPE.DSC_FREE ? TRADEORDER_QTY_TYPE.UNITS : qtyType

    draft.operation = ((): TRADEORDER_OPERATION => {
      if (operation === TRADEORDER_OPERATION.SELL_ALL) {
        return TRADEORDER_OPERATION.SELL
      }

      if (operation === TRADEORDER_OPERATION.SWITCH_ALL) {
        return TRADEORDER_OPERATION.SWITCH
      }
      return operation
    })()

    draft.isSellAll = [
      TRADEORDER_OPERATION.SELL,
      TRADEORDER_OPERATION.SELL_ALL
    ].includes(operation)
      ? isSellAll
      : undefined
  })
}
