import React, {forwardRef} from 'react'

import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import MuiIconButton, {IconButtonProps} from '@material-ui/core/IconButton'
import {makeStyles} from '@material-ui/core/styles'

import {classNames} from '@d1g1t/lib/class-names'
import {getDDButtonAction} from '@d1g1t/lib/data-dog-helpers'

import {useControlState} from '@d1g1t/shared/components/control-state'
import {Text} from '@d1g1t/shared/components/typography'

import * as css from './styles.scss'

const useStyles = makeStyles({
  rootWhite: {
    color: '#FFF'
  },
  small: {
    padding: 4
  },
  tiny: {
    padding: 2,
    marginBottom: -4
  },
  spaceRight: {
    marginRight: 6
  },
  spaceLeft: {
    marginLeft: 6
  },
  errorTextMargin: {
    marginBottom: 22
  }
})
export interface IIconButtonProps extends Omit<IconButtonProps, 'classes'> {
  white?: boolean
  /**
   * Shrink padding of button
   */
  small?: boolean
  tiny?: boolean
  spaceLeft?: boolean
  /**
   * Applies right margin of 6px
   */
  spaceRight?: boolean
  errorTextMargin?: boolean
  counter?: number
}
export const IconButton: React.FC<IIconButtonProps> = forwardRef(
  (
    {
      white,
      small,
      spaceRight,
      spaceLeft,
      tiny,
      errorTextMargin,
      title,
      ...props
    },
    ref
  ) => {
    const controlState = useControlState()
    const classes = useStyles(props)
    const {t} = useTranslation()
    const location = useLocation()

    const datadogAction =
      props['data-dd-action'] || getDDButtonAction({title, ...props})
    const datadogActionName = datadogAction
      ? `${location?.pathname} - ${datadogAction}`
      : undefined

    return (
      <>
        <MuiIconButton
          ref={ref}
          classes={{
            root: classNames({
              [classes.rootWhite]: white,
              [classes.small]: small,
              [classes.spaceRight]: spaceRight,
              [classes.spaceLeft]: spaceLeft,
              [classes.tiny]: tiny,
              [classes.errorTextMargin]: errorTextMargin
            })
          }}
          disabled={controlState.loading}
          title={title ? t(title) : ''}
          {...props}
          data-dd-action={datadogActionName}
        />
        {!!props.counter && (
          <Text fontSize={12} className={css.counterIcon}>
            {props.counter}
          </Text>
        )}
      </>
    )
  }
)
