import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import {Text} from '@d1g1t/shared/components/typography'

import {DATA_TO_SHOW} from '../constants'

import * as css from '../style.scss'

export const SecuritySearchOptionHeader: React.FC = () => {
  return (
    <div className={classNames(css.headerRow)}>
      {DATA_TO_SHOW.map((column) => (
        <div key={column.property} className={css[column.property] || ''}>
          <Text>{column.name}</Text>
        </div>
      ))}
    </div>
  )
}
