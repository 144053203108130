import React, {useMemo} from 'react'

import {ApiError, useApiMutation, useApiQuery} from 'fairlight'
import {FormikProvider, useFormik} from 'formik'
import invariant from 'invariant'
import * as Yup from 'yup'

import {
  ConstantsFilterCriterionEndpoints,
  CustodianAccountEndpoints,
  IBulkPartialTradeOrder,
  TradingOrderEndpoints
} from '@d1g1t/api/endpoints'
import {
  ITradeOrder,
  TRADEORDER_COMMISSION_TYPE,
  TRADEORDER_COMMISSION_TYPE_OPTIONS
} from '@d1g1t/api/models'
import {FILTER_CRITERIA_SLUG} from '@d1g1t/api/models/slugs'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {FormActions} from '@d1g1t/shared/components/form-actions'
import {MULTIPLE_VALUES} from '@d1g1t/shared/components/form-field/constants'
import {KeyboardDatePickerField} from '@d1g1t/shared/components/form-field/keyboard-date-picker-field'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {SearchInputField} from '@d1g1t/shared/components/form-field/search-input-field'
import {ValueLabelAutocompleteField} from '@d1g1t/shared/components/form-field/value-label-autocomplete-field'
import {ValueLabelSelectField} from '@d1g1t/shared/components/form-field/value-select-field'
import {FormUnsavedPrompt} from '@d1g1t/shared/components/form-unsaved-prompt'
import {
  CentsInput,
  CurrencyInput,
  DecimalNumberInput,
  PercentageInput,
  WholeNumberInput
} from '@d1g1t/shared/components/formatted-input'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'

import {TradeSelectField} from '@d1g1t/advisor/containers/trade-order-form/trade-select-field'

import {bulkEditValidateDate} from '../../../lib'
import {IEditTradeModalProps} from '../typings'
import {
  BULK_EDIT_ORDER_FIELD_NAMES,
  IBulkEditOrderFormValues,
  IS_INSIDER_DROPDOWN_OPTIONS
} from './typings'

interface IBulkEditTradeModalContents extends IEditTradeModalProps {
  // options for the Broker dropdown from '/trading/broker'
  brokersOptions: IValueLabelOption[]
  onSuccess(updatedOrders: Partial<ITradeOrder>[]): void
}

export const BulkEditTradeModalContents: React.FC<
  IBulkEditTradeModalContents
> = (props) => {
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()

  const [availableFilters] = useApiQuery(
    ConstantsFilterCriterionEndpoints.list(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  const tradeOrderEntityIdInFilter = useMemo(() => {
    if (!availableFilters.data) {
      return null
    }

    const filter = availableFilters.data.results.find(
      (filter) =>
        filter.slug ===
        FILTER_CRITERIA_SLUG.TRADE_ORDER_PROPERTY_ENTITYID_ONE_OF
    )

    invariant(
      filter,
      `Could not find filter ${FILTER_CRITERIA_SLUG.TRADE_ORDER_PROPERTY_ENTITYID_ONE_OF}`
    )

    return filter
  }, [availableFilters.data])

  const [orderSummary, orderSummaryActions] = useApiQuery(
    tradeOrderEntityIdInFilter &&
      TradingOrderEndpoints.summary({
        filter: {
          joinOperator: 'OR',
          items: [
            {
              filterCriterion: tradeOrderEntityIdInFilter.url,
              value: props.selectedItemIds.join(',')
            }
          ]
        }
      })
  )

  const [custodianAccount] = useApiQuery(
    typeof orderSummary.data?.custodianAccount === 'string' &&
      orderSummary.data.custodianAccount !== MULTIPLE_VALUES &&
      CustodianAccountEndpoints.findById(
        extractIdFromUrl(orderSummary.data.custodianAccount)
      )
  )
  const initialValues: IBulkEditOrderFormValues = useMemo(() => {
    if (!orderSummary.data) {
      return null
    }
    return {
      [BULK_EDIT_ORDER_FIELD_NAMES.instrumentUrl]: orderSummary.data.instrument,
      [BULK_EDIT_ORDER_FIELD_NAMES.accountUrl]: custodianAccount.data?.account,
      [BULK_EDIT_ORDER_FIELD_NAMES.operation]: orderSummary.data.operation,
      [BULK_EDIT_ORDER_FIELD_NAMES.broker]: orderSummary.data.broker,
      [BULK_EDIT_ORDER_FIELD_NAMES.commission]:
        orderSummary.data.commission ?? null,
      [BULK_EDIT_ORDER_FIELD_NAMES.commissionType]:
        orderSummary.data.commissionType ?? null,
      [BULK_EDIT_ORDER_FIELD_NAMES.notes]: orderSummary.data.notes || '',
      [BULK_EDIT_ORDER_FIELD_NAMES.qty]: orderSummary.data.qty || null,
      [BULK_EDIT_ORDER_FIELD_NAMES.isInsider]: orderSummary.data.isInsider,
      [BULK_EDIT_ORDER_FIELD_NAMES.traded]: orderSummary.data.traded,
      [BULK_EDIT_ORDER_FIELD_NAMES.settled]: orderSummary.data.settled
    }
  }, [orderSummary.data, custodianAccount.data])

  const [handleSubmit] = useApiMutation({
    mutation: (values: IBulkEditOrderFormValues) => async (api) => {
      const requestBody: Omit<IBulkPartialTradeOrder, 'entityId'> = {
        broker: values.broker,
        commission: values.commission,
        commissionType: values.commissionType,
        notes: values.notes,
        qty: values.qty,
        isInsider: values.isInsider,
        traded: values.traded || null,
        settled: values.settled || null
      }

      // delete any keys that have `__MULTIPLE__` as the their
      // value
      for (const [key, value] of Object.entries(requestBody)) {
        if (value === MULTIPLE_VALUES) {
          delete requestBody[key]
        }
      }

      const updatedOrders = await api.request(
        TradingOrderEndpoints.bulkUpdate(
          props.selectedItemIds.map((selectedItemId) => ({
            entityId: selectedItemId,
            ...requestBody
          }))
        )
      )

      return {requestBody, updatedOrders}
    },

    onSuccess: ({
      requestBody,
      updatedOrders
    }: {
      requestBody: Omit<IBulkPartialTradeOrder, 'entityId'>
      updatedOrders: ITradeOrder[]
    }) => {
      orderSummaryActions.setData({...orderSummary.data, ...requestBody})

      showSnackbar({
        variant: 'success',
        message: 'The trade has been updated.'
      })

      props.onSuccess(
        updatedOrders.map((order) => ({
          // the spread will eliminate the data from the order that are not important to update
          ...requestBody,
          entityId: order.entityId,
          modified: order.modified,
          totalCommission: order.totalCommission
        }))
      )

      props.onClose()
    },

    onError: (error) => {
      if (error instanceof ApiError && error.status === 400) {
        showSnackbar({
          variant: 'error',
          message: Object.values(error.responseBody)[0]
        })
      } else {
        handleUnexpectedError(
          error,
          'An unexpected error occurred while updating the trade.'
        )
      }
    }
  })

  const formik = useFormik<IBulkEditOrderFormValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      [BULK_EDIT_ORDER_FIELD_NAMES.traded]: Yup.string()
        .nullable()
        .test(
          'trade-date-format',
          'Invalid Date. Expected format YYYY-MM-DD',
          bulkEditValidateDate
        ),
      [BULK_EDIT_ORDER_FIELD_NAMES.settled]: Yup.string()
        .nullable()
        .test(
          'settlement-date-format',
          'Invalid Date. Expected format YYYY-MM-DD',
          bulkEditValidateDate
        )
    }),
    onSubmit: handleSubmit
  })

  const commissionFeeField = (() => {
    const inputComponent = (() => {
      switch (formik.values?.commissionType) {
        case TRADEORDER_COMMISSION_TYPE.CENTSPERSHARE:
          return CentsInput
        case TRADEORDER_COMMISSION_TYPE.AMOUNT:
          return CurrencyInput
        case TRADEORDER_COMMISSION_TYPE.OFORDER:
          return PercentageInput
        default:
          return null
      }
    })()

    if (!inputComponent) {
      return null
    }

    return (
      <OutlinedInputField
        name={BULK_EDIT_ORDER_FIELD_NAMES.commission}
        label='Commission'
        outlinedInputProps={{
          inputComponent
        }}
      />
    )
  })()

  const renderEditTradeModal = () => {
    return (
      <ModalContent>
        <Grid container>
          <Grid item xs={3}>
            <TradeSelectField
              name={BULK_EDIT_ORDER_FIELD_NAMES.operation}
              showSwitch
              disabled
            />
          </Grid>
        </Grid>
        <Grid container>
          {formik.values[BULK_EDIT_ORDER_FIELD_NAMES.accountUrl] && (
            <Grid item xs={4}>
              <SearchInputField
                name={BULK_EDIT_ORDER_FIELD_NAMES.accountUrl}
                label='Account'
                disabled
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <SearchInputField
              name={BULK_EDIT_ORDER_FIELD_NAMES.instrumentUrl}
              label='Security'
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <KeyboardDatePickerField
              name={BULK_EDIT_ORDER_FIELD_NAMES.traded}
              label='Trade Date'
              keyboardDatePickerProps={{allowFuture: true}}
            />
          </Grid>
          <Grid item xs={4}>
            <KeyboardDatePickerField
              name={BULK_EDIT_ORDER_FIELD_NAMES.settled}
              label='Settlement Date'
              keyboardDatePickerProps={{allowFuture: true}}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <ValueLabelAutocompleteField
              name={BULK_EDIT_ORDER_FIELD_NAMES.broker}
              label='Broker'
              ValueLabelAutocompleteProps={{
                options: props.brokersOptions
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <ValueLabelSelectField
              name={BULK_EDIT_ORDER_FIELD_NAMES.commissionType}
              label='Commission Type'
              valueLabelSelectProps={{
                options: TRADEORDER_COMMISSION_TYPE_OPTIONS
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {commissionFeeField}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <OutlinedInputField
              name={BULK_EDIT_ORDER_FIELD_NAMES.notes}
              label='Notes'
              outlinedInputProps={{
                multiline: true,
                minRows: 4,
                maxRows: 4,
                placeholder: 'Write a note about the order(s) ...'
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>
    )
  }

  const renderAggregatedEditTradeModal = () => {
    if (!props.editableCategories) {
      return null
    }
    return (
      <ModalContent>
        <Grid container>
          {props.editableCategories.includes(
            BULK_EDIT_ORDER_FIELD_NAMES.qty
          ) && (
            <Grid item xs={3}>
              <OutlinedInputField
                name={BULK_EDIT_ORDER_FIELD_NAMES.qty}
                label='Quantity'
                outlinedInputProps={{
                  inputComponent: DecimalNumberInput
                }}
              />
            </Grid>
          )}
          {props.editableCategories.includes('t-is-insider') && (
            <Grid item xs={3}>
              <ValueLabelSelectField
                name={BULK_EDIT_ORDER_FIELD_NAMES.isInsider}
                label='Insider'
                valueLabelSelectProps={{
                  options: IS_INSIDER_DROPDOWN_OPTIONS
                }}
              />
            </Grid>
          )}

          <Grid item xs={3}>
            <OutlinedInputField
              name={BULK_EDIT_ORDER_FIELD_NAMES.commission}
              label='Commission'
              outlinedInputProps={{
                inputComponent: WholeNumberInput
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <ValueLabelSelectField
              name={BULK_EDIT_ORDER_FIELD_NAMES.commissionType}
              label='Commission Type'
              valueLabelSelectProps={{
                options: TRADEORDER_COMMISSION_TYPE_OPTIONS
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <OutlinedInputField
              name={BULK_EDIT_ORDER_FIELD_NAMES.notes}
              label='Notes'
              outlinedInputProps={{
                multiline: true,
                minRows: 4,
                maxRows: 4,
                placeholder: 'Write a note about the order(s) ...'
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>
    )
  }

  return (
    <LoadingContainer
      loading={[availableFilters, orderSummary].some((query) => query.loading)}
    >
      {formik.values && (
        <FormikProvider value={formik}>
          <FormUnsavedPrompt />
          <ControlStateProvider loading={formik.isSubmitting}>
            <form onSubmit={formik.handleSubmit} style={{display: 'contents'}}>
              {props.isAggregated
                ? renderAggregatedEditTradeModal()
                : renderEditTradeModal()}
              <ModalActions>
                <FormActions hideDirtyText />
              </ModalActions>
            </form>
          </ControlStateProvider>
        </FormikProvider>
      )}
    </LoadingContainer>
  )
}
