import React from 'react'

import CheckCircle from '@material-ui/icons/CheckCircle'
import FilterListIcon from '@material-ui/icons/FilterList'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

import {IEntityRelationAccount} from '@d1g1t/api/models'

import {Flex} from '@d1g1t/shared/components/flex'
import {Divider} from '@d1g1t/shared/components/mui/divider'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {ListItemIcon} from '@d1g1t/shared/components/mui/list-item-icon'
import {MenuItem} from '@d1g1t/shared/components/mui/menu-item'
import {parseEntityName} from '@d1g1t/shared/containers/select-entities/components/entity-chip/lib'

import {IEntityChipProps, useEntityChipContext} from '../../../..'

interface IAccountFilterSubmenuProps extends IEntityChipProps {
  /**
   * Only accounts belonging to `ownerEntityId` will be
   * shown from `accountRelations`.
   */
  ownerEntityId: string
  /**
   * A list of accounts for the chip entity, these will be
   * automatically filtered by the `ownerEntityId`.
   */
  accountRelations: IEntityRelationAccount[]
  /**
   * Opens the FilterHoldings modal
   */
  onFilterHoldingsClick(): void
  /**
   * Closes the AccountFilterMenu dropdown.
   */
  onClose(): void
}

/**
 * Provides a list of accounts to filter.
 * On click, it will add/remove accounts to/from query params.
 */
export const AccountFiltersMenu: React.FC<IAccountFilterSubmenuProps> =
  React.forwardRef((props, ref) => {
    const {entity, actions} = useEntityChipContext()

    const selectedAccounts = entity?.accounts ?? []

    // Used to handle case where we DON't append to existing account filter(s)
    const handleAddAccountFilterClick = (accountEntityId: string) => {
      props.onClose()

      if (entity.entityId === props.ownerEntityId) {
        // Apply filters only, if same entity selected
        actions.applyEntityFilters(props.ownerEntityId, {
          accounts: [accountEntityId]
        })
      } else {
        actions.replaceEntity(entity.entityId, props.ownerEntityId, {
          accounts: [accountEntityId]
        })
      }
    }

    // Used to handle case where we DON't append to existing account filter(s)
    const handleAppendAccountFilterClick = (
      isApplied: boolean,
      accountEntityId: string
    ) => {
      if (props.singleAccount) {
        // We don't show the unchecked icon, so just do what we do on account name click
        handleAddAccountFilterClick(accountEntityId)
        return null
      }

      if (entity.entityId === props.ownerEntityId) {
        // Apply filters only, if same entity selected
        actions.applyEntityFilters(props.ownerEntityId, {
          accounts: isApplied
            ? selectedAccounts.filter(
                (selectedAccountEntityId) =>
                  selectedAccountEntityId !== accountEntityId
              )
            : selectedAccounts.concat(accountEntityId)
        })
      } else {
        actions.replaceEntity(entity.entityId, props.ownerEntityId, {
          accounts: [accountEntityId]
        })
      }
    }

    return (
      <>
        {props.accountRelations.map((account) => {
          const isApplied = selectedAccounts.includes(account.entityId)

          const accountName = parseEntityName(account)

          return (
            <MenuItem
              key={account.entityId}
              data-dd-action='Entity - Account filter menu chip'
            >
              <Flex
                grow
                alignCenter
                onClick={() => handleAddAccountFilterClick(account.entityId)}
              >
                <ListItemIcon>
                  <FilterListIcon fontSize='small' />
                </ListItemIcon>
                {accountName}
              </Flex>
              <Flex
                alignEnd
                style={{
                  paddingLeft: '10px',
                  width: 34,
                  height: 24
                }}
                onClick={() =>
                  handleAppendAccountFilterClick(isApplied, account.entityId)
                }
              >
                <IconButton size='small'>
                  {(() => {
                    if (isApplied) {
                      return <CheckCircle fontSize='small' />
                    }
                    if (!props.singleAccount) {
                      return <RadioButtonUncheckedIcon fontSize='small' />
                    }
                    return null
                  })()}
                </IconButton>
              </Flex>
            </MenuItem>
          )
        })}
        {props.accountRelations.length !== 0 && !props.singleAccount && (
          <Divider style={{marginTop: '1rem', marginBottom: '1rem'}} />
        )}
        {!props.singleAccount && (
          <MenuItem onClick={props.onFilterHoldingsClick}>
            Filter holdings
          </MenuItem>
        )}
      </>
    )
  })
