import React from 'react'
import {Helmet} from 'react-helmet-async'
import {Route, Switch} from 'react-router-dom'

import {
  AzureAdForm,
  LoginPage,
  LogoutPage
} from '@d1g1t/shared/containers/auth/login-page'
import {AuthenticationLocations} from '@d1g1t/shared/locations'
import {CreatePasswordPage} from '@d1g1t/shared/pages/create-password'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'

import {AuthenticatedRoutes} from './authenticated-routes'

import favicon from '@d1g1t/shared/assets/favicon32x32.png'

export const BaseRoutes: React.SFC = () => (
  <>
    <Helmet>
      <link rel='shortcut icon' type='image/png' href={favicon} />
    </Helmet>
    <Switch>
      <Route path={AuthenticationLocations.basePath}>
        <LoginPage />
      </Route>
      <Route path={AuthenticationLocations.logout()}>
        <LogoutPage />
      </Route>
      <Route path={AuthenticationLocations.signin()}>
        <AzureAdForm />
      </Route>
      <Route path='/activate/:contactId/:token'>
        <ErrorBoundary resetId='no-reset'>
          <CreatePasswordPage advisor />
        </ErrorBoundary>
      </Route>

      <Route path='*'>
        <AuthenticatedRoutes />
      </Route>
    </Switch>
  </>
)

BaseRoutes.displayName = 'BaseRoutes'
