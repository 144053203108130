import {atomFamily} from 'recoil'

import {StandardResponse} from '@d1g1t/lib/standard-response'

/**
 * All standard tables, can access each one by the StandardTable component's ID prop.
 */
export const standardTableAtomFamily = atomFamily<StandardResponse, string>({
  key: 'standardTables',
  default: null
})
