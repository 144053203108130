import React from 'react'
import {useParams} from 'react-router-dom'

import {useApiQuery} from 'fairlight'

import {CalculatedFeesEndpoints} from '@d1g1t/api/endpoints'
import {UI_PERMISSIONS} from '@d1g1t/api/models'

import {Card} from '@d1g1t/shared/components/card'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'
import {withPermissions} from '@d1g1t/shared/wrappers/permissions'

import {Page} from '@d1g1t/advisor/components/page'
import {PageContent} from '@d1g1t/advisor/components/page-content'
import {PageTitleBar} from '@d1g1t/advisor/containers/page-title-bar'
import {BillingLocations} from '@d1g1t/advisor/locations'

import {Summary} from './components/summary'
import {UnderlineFeeItems} from './components/underline-fee-items'

const CalculatedFeesSinglePageContent: React.FC = () => {
  const {selection: feeId} = useParams<{selection: string}>()

  const [feeData] = useApiQuery(
    feeId && CalculatedFeesEndpoints.findById(feeId)
  )

  if (!feeData.data) {
    return null
  }
  return (
    <Page>
      <PageTitleBar
        title={`Fee: ${feeData.data.id}`}
        backLink={BillingLocations.calculatedFees()}
        noBottomMargin
      />

      <PageContent saveBarBottomPadding>
        <LoadingContainer loading={feeData.loading}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card flexColumn noMinHeight noBottomMargin extraPadding>
                <ErrorBoundary resetId='no-reset'>
                  <Summary feeData={feeData.data} />
                </ErrorBoundary>
              </Card>
            </Grid>

            <Grid item xs={12} md={12}>
              <Card flexColumn noMinHeight noBottomMargin extraPadding>
                <ErrorBoundary resetId='no-reset'>
                  <UnderlineFeeItems
                    calculatedFeeItems={feeData.data?.calculatedFeeItems}
                    currency={feeData.data.currency}
                  />
                </ErrorBoundary>
              </Card>
            </Grid>
          </Grid>
        </LoadingContainer>
      </PageContent>
    </Page>
  )
}

export const CalculatedFeesSinglePage = withPermissions(
  UI_PERMISSIONS.SINGLE_CALCULATED_FEE
)(CalculatedFeesSinglePageContent)
