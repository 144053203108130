import React from 'react'

import {MaximizeIcon} from '@d1g1t/shared/components/icons/maximize'
import {MinimizeIcon} from '@d1g1t/shared/components/icons/minimize'
import {
  IconButton,
  IIconButtonProps
} from '@d1g1t/shared/components/mui/icon-button'

export interface IMaximizeIconButtonProps extends IIconButtonProps {
  maximized: boolean
}

export const MaximizeIconButton: React.FC<IMaximizeIconButtonProps> = ({
  maximized,
  ...props
}) => {
  return (
    <IconButton data-testid='maximize-button' {...props}>
      {maximized ? (
        <MinimizeIcon fontSize='small' />
      ) : (
        <MaximizeIcon fontSize='small' />
      )}
    </IconButton>
  )
}
