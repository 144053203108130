/* eslint react/no-find-dom-node: 0 */
import React from 'react'
import {
  DndComponentClass,
  DragSource,
  DragSourceSpec,
  DropTarget
} from 'react-dnd'
import {findDOMNode} from 'react-dom'

import {flow} from 'lodash'

import {OrderableListItem} from '@d1g1t/shared/components/orderable-list-item'
import {OrderableListItemText} from '@d1g1t/shared/components/orderable-list-item-text'

interface IDroppedItem {
  id: number
  index: number
}

export interface IDraggableItemProps {
  index: number
  id: string | number
  isSelected?: boolean
  onMove(dragIndex?: number, hoverIndex?: number)
  onRemove(id: string | number)
  onClick?(id: string | number)
  /**
   * Called when a draggable iteam has been dropped successfully onto a target
   * @param id - ID passed in from props
   * @param index - Drop index of the item: IE index = 2 means item has been moved to the third position
   */
  onDrop?(id: number, index: number, dropResult?: object)
}

export interface IDragDropProps {
  isDragging: boolean
  connectDragSource: any
  connectDropTarget: any
}

export interface IDraggableItem extends IDraggableItemProps, IDragDropProps {}

class DraggableItemComponent extends React.Component<IDraggableItem> {
  handleRemove = () => {
    this.props.onRemove(this.props.id)
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.id)
    }
  }

  render() {
    const {connectDragSource, connectDropTarget} = this.props
    const item = (
      <div>
        <OrderableListItem
          onClick={this.handleClick}
          onDelete={this.props.onRemove ? this.handleRemove : undefined}
          translucent={this.props.isDragging}
          isSelected={this.props.isSelected}
          draggable
        >
          <OrderableListItemText>{this.props.children}</OrderableListItemText>
        </OrderableListItem>
      </div>
    )

    return connectDragSource(connectDropTarget(item))
  }
}

const draggableDropdownSource: DragSourceSpec<IDraggableItemProps, any> = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index
    }
  },
  endDrag(props, monitor, component) {
    if (!component && !monitor.didDrop() && !props.onDrop) {
      return
    }

    const item = monitor.getItem() as IDroppedItem
    props.onDrop(item.id, item.index, monitor.getDropResult())
  }
}

const draggableDropdownTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = (
      findDOMNode(component) as HTMLElement
    ).getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    // Time to actually perform the action
    props.onMove(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  }
}

function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

function collectTarget(connect) {
  return {
    connectDropTarget: connect.dropTarget()
  }
}

export const DraggableItem = flow(
  DragSource('draggableDropdown', draggableDropdownSource, collectSource),
  DropTarget('draggableDropdown', draggableDropdownTarget, collectTarget)
)(DraggableItemComponent) as DndComponentClass<any, IDraggableItemProps>
