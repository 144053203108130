import React, {useState} from 'react'

import {useApi} from 'fairlight'

import {IApiListResponse, RebalancingRuleEndpoints} from '@d1g1t/api/endpoints'
import {IRebalancingRule} from '@d1g1t/api/models'

import {useToggleState} from '@d1g1t/lib/hooks'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Button} from '@d1g1t/shared/components/mui/button'
import {OrderableListItem} from '@d1g1t/shared/components/orderable-list-item'
import {OrderableListItemText} from '@d1g1t/shared/components/orderable-list-item-text'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'

import {AddRule} from '../add-rule-modal'

interface IRuleListProps {
  rules: Loadable<IApiListResponse<IRebalancingRule>>
  onSelectRule(rule: IRebalancingRule)
  onAddRule(rule: IRebalancingRule)
  onDeleteRule(rule: IRebalancingRule)
}

export const RuleList: React.FC<IRuleListProps> = function RuleList(props) {
  const [addRuleModalOpen, , showAddRuleModal, hideAddRuleModal] =
    useToggleState(false)
  const [deletingRule, setDeletingRule] = useState(false)

  const api = useApi()
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()

  return (
    <ControlStateProvider loading={deletingRule}>
      {addRuleModalOpen && (
        <AddRule
          onClose={hideAddRuleModal}
          onCreate={(rule) => {
            props.onAddRule(rule)
          }}
        />
      )}
      <ModalContent>
        {props.rules.data.results.map((rule) => (
          <OrderableListItem
            key={rule.id}
            onClick={() => props.onSelectRule(rule)}
            onDelete={async () => {
              try {
                setDeletingRule(true)
                await api.request(RebalancingRuleEndpoints.destroy(rule.id))

                showSnackbar({
                  variant: 'success',
                  message: `Rebalancing rule '${rule.name}' has been deleted.`
                })

                props.onDeleteRule(rule)
              } catch (error) {
                handleUnexpectedError(error)
              } finally {
                setDeletingRule(false)
              }
            }}
          >
            <OrderableListItemText>{rule.name}</OrderableListItemText>
          </OrderableListItem>
        ))}
      </ModalContent>
      <ModalActions>
        <Button
          primary
          contained
          onClick={showAddRuleModal}
          data-testid='button-add-rule'
        >
          {props.rules.data.results.length > 0
            ? 'Add another rule'
            : 'Add a rule'}
        </Button>
      </ModalActions>
    </ControlStateProvider>
  )
}
