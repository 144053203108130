import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import {Button} from '@d1g1t/shared/components/mui/button'
import {
  ButtonGroupDropdown,
  IButtonGroupSettings,
  IDropdownOption
} from '@d1g1t/shared/components/mui/button-group'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H3} from '@d1g1t/shared/components/typography'
import {useUserProfile} from '@d1g1t/shared/wrappers/user-profile'

import * as css from './style.scss'

export {useSelectedIds, useSelectedIdsRecoil} from './hook'

/**
 * If it's an array of arrays of actions, they will be
 * separated by a divider.
 */
export type ICheckedItemActions = ICheckedItemAction[] | ICheckedItemAction[][]

interface ICheckedItemBase {
  /**
   * Will always show button action if `count >= 0`
   */
  ignoreZeroCount?: boolean
  /**
   * Provides a more granular control to disable a particular button.
   */
  disabledButton?: boolean
}

interface ICheckedItemActionButton extends ICheckedItemBase {
  /**
   * Button label text.
   */
  label: string
  /**
   * Icon inside the button.
   */
  icon: React.ReactNode
  /**
   * Optional tooltip around the button.
   */
  tooltip?: string
  /**
   * Button component doesn't support ButtonGroup options
   */
  dropdownOptions?: never
  /**
   * Button component doesn't support ButtonGroup options
   */
  primaryAction?: never
  /**
   * Button component doesn't support ButtonGroup options
   */
  onButtonGroupActionClicked?: never
  /**
   * Button component doesn't support ButtonGroup options
   */
  onButtonGroupCancel?: never
  /**
   * On button click.
   */
  onClick(): void
}

interface ICheckedItemActionButtonGroup extends ICheckedItemBase {
  /**
   * If provided, uses `ButtonGroupDropdown` component to show a split button.
   */
  dropdownOptions: IDropdownOption[]
  /**
   * Id of action dropdown that should be shown to user.
   */
  primaryAction: IButtonGroupSettings
  /**
   * Saves the last selected button dropdown action in global settings.
   */
  onButtonGroupActionClicked(setting: IButtonGroupSettings): void
  /**
   * Required if dropdown options are provided. Will run on button group cancel click.
   */
  onButtonGroupCancel?(): void
  /**
   * ButtonGroup component doesn't support icons label
   */
  label?: never
  /**
   * ButtonGroup component doesn't support icons
   */
  icon?: never
  /**
   * ButtonGroup component doesn't support tooltips
   */
  tooltip?: never
  /**
   * ButtonGroup component doesn't need an onClick as there is an onClick
   * provided for each dropdown option
   */
  onClick?(): never
}

// Item can be button or button group
export type ICheckedItemAction =
  | ICheckedItemActionButton
  | ICheckedItemActionButtonGroup

interface ICheckedItemsActionsProps {
  /**
   * Disables all action buttons.
   * Should disable buttons while their requests are in progress.
   */
  disabled: boolean
  /**
   * Number of items affected by action group.
   */
  /**
   * Make selection context line render correctly if below a widget title
   * bar. (Updates `top` value in css)
   */
  belowWidgetTitleBar?: boolean
  count: number
  /**
   * Set of actions to take on the `count` number of selected items.
   */
  actions: ICheckedItemActions
  buttonTestIdPrefix?: string
  /**
   * Hides the count of items selected and any css associated with it.
   */
  hideSelectedRowsCount?: boolean
}

/**
 * Provides an action group of buttons and/or button-dropdown-groups.
 * Shows the number of selected items that will be affected by these actions to the left of buttons.
 *
 * Optionally renders provided children to group a search-box, for example, with the action group.
 * Optionally renders a line between buttons if provided actions are grouped in arrays.
 */
export const CheckedItemsActions: React.FC<ICheckedItemsActionsProps> = (
  props
) => {
  const [userProfile] = useUserProfile()

  const isCompactMode = userProfile.profile?.isCompactMode

  const actionGroups = (
    Array.isArray(props.actions[0]) ? props.actions : [props.actions]
  ) as ICheckedItemAction[][]
  const buttonDropdown = actionGroups[0].find(
    (action) => action.dropdownOptions
  )

  const atLeastOneActionIgnoringZeroCount = actionGroups.some((actionGroup) =>
    actionGroup.some((action) => action.ignoreZeroCount)
  )

  return (
    <div className={css.checkedItemsActions}>
      {!props.hideSelectedRowsCount && (
        <div
          className={classNames(css.selectionContextLine, {
            [css.active]: props.count > 0,
            [css.belowWidgetTitleBar]: props.belowWidgetTitleBar,
            [css.compactMode]: isCompactMode
          })}
        />
      )}
      {!props.hideSelectedRowsCount && <H3>{props.count} selected</H3>}
      {props.children}
      {/* Will always render the actions that have ignoreZeroCount property set to true */}
      {(atLeastOneActionIgnoringZeroCount || props.count > 0) && (
        <>
          <Spacer vertical xs />
          {actionGroups.map((actionGroup, actionGroupIndex) => (
            <React.Fragment key={actionGroupIndex}>
              {actionGroup.map((action, actionIndex) => {
                if (!action.ignoreZeroCount && props.count === 0) {
                  return
                }

                const buttonOrButtonGroup =
                  action.dropdownOptions && buttonDropdown.primaryAction ? (
                    <ButtonGroupDropdown
                      disabled={props.disabled || action.disabledButton}
                      loading={props.disabled || action.disabledButton}
                      mainOptionId={buttonDropdown.primaryAction.id}
                      dropdownOptions={action.dropdownOptions}
                      onClickCancel={action.onButtonGroupCancel}
                      onDropdownActionClicked={
                        buttonDropdown.onButtonGroupActionClicked
                      }
                      dataTestId={`${
                        props.buttonTestIdPrefix
                      }${action.label?.toLowerCase()}`}
                      data-dd-action={action.label}
                    />
                  ) : (
                    <Button
                      disabled={props.disabled || action.disabledButton}
                      outlined
                      onClick={action.onClick}
                      data-testid={`${
                        props.buttonTestIdPrefix
                      }${action.label?.toLowerCase()}`}
                      data-dd-action={action.label}
                    >
                      {action.icon}
                      {action.label}
                    </Button>
                  )

                return (
                  <React.Fragment key={actionIndex}>
                    {action.tooltip ? (
                      <Tooltip title={action.tooltip}>
                        <div>{buttonOrButtonGroup}</div>
                      </Tooltip>
                    ) : (
                      buttonOrButtonGroup
                    )}
                    <Spacer vertical xxs />
                  </React.Fragment>
                )
              })}
              {actionGroupIndex < actionGroups.length - 1 && (
                <>
                  <CheckedItemsDivider />
                  <Spacer vertical xxs />
                </>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  )
}

const CheckedItemsDivider = () => (
  <span className={css.checkedItemsDivider}>|</span>
)
