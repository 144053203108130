import React, {useEffect, useMemo} from 'react'

import {useApiQuery} from 'fairlight'

import {TradingEndpoints} from '@d1g1t/api/endpoints'

import {Flex} from '@d1g1t/shared/components/flex'
import {Button} from '@d1g1t/shared/components/mui/button'
import {H5, Label} from '@d1g1t/shared/components/typography'
import {
  IValueLabelSelectOption,
  ValueLabelSelect
} from '@d1g1t/shared/components/value-label-select'
import {useGlobalSettings} from '@d1g1t/shared/wrappers/global-settings'
import {useCurrencyList} from '@d1g1t/shared/wrappers/use-currency-list/use-currency-list'
import {useFirmSecurirtyTypes} from '@d1g1t/shared/wrappers/use-firm-security-types/use-firm-security-types'

import {
  DEFAULT_SECURITY_FILTER_VALUES,
  SECURITY_SEARCH_FILTER_KEYS
} from './constants'

import css from './styles.scss'

interface ISecuritySearchFilterProps {
  onChange: (val: {
    [SECURITY_SEARCH_FILTER_KEYS.SECURITY_TYPE]: string[]
    [SECURITY_SEARCH_FILTER_KEYS.TRADING_CURRENCY]: string[]
    [SECURITY_SEARCH_FILTER_KEYS.EXCHANGE_CODES]: string[]
  }) => void
}

export const SecuritySearchFilter: React.FC<ISecuritySearchFilterProps> = (
  props
) => {
  const {currencyOptionsName} = useCurrencyList()
  const {firmSecurityTypeOptionsSlugs} = useFirmSecurirtyTypes()

  const [exchanges] = useApiQuery(TradingEndpoints.exchanges())
  const [filters, {updateGlobalSettingsKeys, updateGlobalSettings}] =
    useGlobalSettings('securitySearchFilters', DEFAULT_SECURITY_FILTER_VALUES)

  // Need this useEffect as each specific search filter should have its own global settings
  // Having global settings in the search component will cause complication within the search
  // component as it will be handling multiple custom filters.
  // If we have the global settings in the component that uses the search, we will be defining
  // multiple instances of global settings with the same default values, and introduces props
  // callback hell.
  useEffect(() => {
    props.onChange(filters)
  }, [filters])

  const exchangeOptions: IValueLabelSelectOption[] = useMemo(
    () =>
      exchanges.data?.results.map((exchange) => ({
        label: exchange.name,
        value: exchange.code
      })) || [],
    [exchanges.data?.results]
  )

  if (!exchanges.data || !filters) {
    return null
  }

  return (
    <div className={css.container}>
      <Flex justifySpaceBetween>
        <H5 className={css.headerText}>Filters</H5>
        <Button
          small
          noPadding
          onClick={() => {
            updateGlobalSettings(DEFAULT_SECURITY_FILTER_VALUES)
          }}
        >
          Clear
        </Button>
      </Flex>
      <Label>Type</Label>
      <ValueLabelSelect
        id='type-select'
        label='Type'
        name='type'
        size='small'
        spaceRight
        multiple
        value={filters[SECURITY_SEARCH_FILTER_KEYS.SECURITY_TYPE]}
        options={firmSecurityTypeOptionsSlugs}
        renderValue={(val) =>
          val
            .map(
              (slug: string) =>
                firmSecurityTypeOptionsSlugs.find(
                  (option) => option.value === slug
                )?.label
            )
            .join(', ')
        }
        onChange={(val: string[]) => {
          updateGlobalSettingsKeys.securityType(val)
        }}
      />
      <Label>Currency</Label>
      <ValueLabelSelect
        id='currency-select'
        label='Currency'
        name='currency'
        size='small'
        spaceRight
        multiple
        value={filters[SECURITY_SEARCH_FILTER_KEYS.TRADING_CURRENCY]}
        options={currencyOptionsName}
        renderValue={(val) => val.join(', ')}
        onChange={(val: string[]) => {
          updateGlobalSettingsKeys.tradingCurrency(val)
        }}
      />
      <Label>Exchange</Label>
      <ValueLabelSelect
        id='exchange-select'
        label='Exchange'
        name='exchange'
        size='small'
        spaceRight
        multiple
        value={filters[SECURITY_SEARCH_FILTER_KEYS.EXCHANGE_CODES]}
        options={exchangeOptions}
        renderValue={(val) => val.join(', ')}
        onChange={(val: string[]) => {
          updateGlobalSettingsKeys.exchangeCodes(val)
        }}
      />
    </div>
  )
}
