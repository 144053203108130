import React, {useEffect, useRef} from 'react'

interface IProps {
  children: JSX.Element
  onClickOutside: (ev: MouseEvent) => void
}

/**
 * Wrapper component that detects clickoutside and fires function onClickOutside
 */
export function ClickOutside(props: IProps) {
  const wrapperRef = useRef(null)

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      props.onClickOutside(event)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [props, wrapperRef])

  return <div ref={wrapperRef}>{props.children}</div>
}
