import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'
import {getScrollbarWidth} from '@d1g1t/lib/dom'

import {
  FRAME_MARGIN_LEFT,
  FRAME_MARGIN_RIGHT
} from '@d1g1t/advisor/containers/frame'

import * as css from './style.scss'

const WIDTH =
  1368 - FRAME_MARGIN_RIGHT - FRAME_MARGIN_LEFT - getScrollbarWidth()

/**
 * When Page is not used, width is fluid, there are no restrictions, shrink and expand to any width.
 * By default Page has `width=expandable` (min-width = 1368px)
 */
export const Page: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const style = {
    minWidth: WIDTH
  }
  return (
    <div
      className={classNames(css.page, props.className)}
      {...props}
      style={{...style, ...props.style}}
    />
  )
}
