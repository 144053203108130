import React from 'react'

import {Flex} from '@d1g1t/shared/components/flex'
import {Text} from '@d1g1t/shared/components/typography'

export interface IDraggableTextProps
  extends React.HTMLAttributes<HTMLDivElement> {
  value: string
}

export const DraggableText: React.FC<IDraggableTextProps> = ({
  value,
  ...props
}) => {
  return (
    <Flex alignCenter grow {...props}>
      <Text indent>{value}</Text>
    </Flex>
  )
}
