const createMask = (...args: boolean[]): number => {
  let nMask = 0
  let nFlag = 0
  const nLen = args.length > 32 ? 32 : args.length

  for (nFlag; nFlag < nLen; nMask |= Number(args[nFlag]) << nFlag++) {}

  return nMask
}

export const MODIFIER_FLAGS = {
  shift: 1,
  ctrl: 2,
  alt: 4,
  meta: 8
}

/**
 * Creates a bitwise mask of keyboard modifiers, to be used with
 * MODIFIER_FLAGS
 */
export const createKeyboardModiferMask = (
  event: React.KeyboardEvent | KeyboardEvent
): number => {
  return createMask(event.shiftKey, event.ctrlKey, event.altKey, event.metaKey)
}

export function onlyMeta(event: KeyboardEvent | React.KeyboardEvent) {
  const mask = createKeyboardModiferMask(event)
  return mask & MODIFIER_FLAGS.meta
}

export enum Direction {
  up,
  down,
  left,
  right
}

const tabDirection = (mask: number): Nullable<Direction> => {
  if (mask === MODIFIER_FLAGS.shift) {
    return Direction.left
  }
  if (mask === 0) {
    return Direction.right
  }

  return null
}

const arrowUpDirection = (mask: number): Nullable<Direction> => {
  if (mask === 0 || mask & MODIFIER_FLAGS.alt) {
    return Direction.up
  }

  return null
}

const arrowDownDirection = (mask: number): Nullable<Direction> => {
  if (mask === 0 || mask & MODIFIER_FLAGS.alt) {
    return Direction.down
  }

  return null
}

const arrowLeftDirection = (mask: number): Nullable<Direction> => {
  if (mask === MODIFIER_FLAGS.alt) {
    return Direction.left
  }

  return null
}

const arrowRightDirection = (mask: number): Nullable<Direction> => {
  if (mask === MODIFIER_FLAGS.alt) {
    return Direction.right
  }

  return null
}

const enterDirection = (mask: number): Nullable<Direction> => {
  if (mask === MODIFIER_FLAGS.shift) {
    return Direction.up
  }
  if (mask === 0) {
    return Direction.down
  }

  return null
}

export const directionFromKeyboardEvent = (
  event: React.KeyboardEvent
): Nullable<Direction> => {
  const mask = createKeyboardModiferMask(event)
  switch (event.key) {
    case 'Tab':
      return tabDirection(mask)
    case 'ArrowUp':
      return arrowUpDirection(mask)
    case 'ArrowDown':
      return arrowDownDirection(mask)
    case 'ArrowLeft':
      return arrowLeftDirection(mask)
    case 'ArrowRight':
      return arrowRightDirection(mask)
    case 'Enter':
      return enterDirection(mask)
    default:
      return null
  }
}

/**
 * Returns `true` if any modifiers (alt, control, etc.) were applied
 * to a keyboard event
 */
export const isKeyDown = (
  event: Pick<KeyboardEvent, 'altKey' | 'ctrlKey' | 'shiftKey' | 'metaKey'>
) => event.altKey || event.ctrlKey || event.shiftKey || event.metaKey
