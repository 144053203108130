import React from 'react'
import {useTranslation} from 'react-i18next'

import {IOutlinedInputProps} from '@d1g1t/shared/components/mui/outlined-input'
import {ITextFieldProps} from '@d1g1t/shared/components/mui/text-field'
import {
  IValueLabelAutocompleteProps,
  ValueLabelAutocomplete
} from '@d1g1t/shared/components/value-label-autocomplete'

import {useFormFieldControl} from '../hook'
import {getFormFieldErrorState} from '../lib'
import {IFormFieldProps} from '../typings'

interface IValueLabelAutocompleteFieldProps extends IFormFieldProps {
  ValueLabelAutocompleteProps: Omit<
    IValueLabelAutocompleteProps,
    'renderInput' | 'value' | 'onChange' | 'ref'
  >
  TextFieldProps?: Omit<ITextFieldProps, 'label' | 'error' | 'helperText'>
  InputProps?: IOutlinedInputProps
  disabled?: boolean
}

/**
 * `formik`-compatible `ValueLabelAutocomplete` field
 */
export const ValueLabelAutocompleteField: React.FC<
  IValueLabelAutocompleteFieldProps
> = (props) => {
  const {
    name,
    label,
    ValueLabelAutocompleteProps,
    TextFieldProps = {},
    InputProps = {}
  } = props
  const [{onChange, ...field}, meta] = useFormFieldControl(props)
  const {hasError, errorText} = getFormFieldErrorState(meta)

  const id = props.id || props.name
  const {t} = useTranslation()

  return (
    <ValueLabelAutocomplete
      {...ValueLabelAutocompleteProps}
      id={id}
      {...field}
      onChange={(e, value) => onChange({target: {name, value}})}
      disabled={props.disabled}
      TextFieldProps={{
        label: t(label),
        error: hasError,
        helperText: errorText,
        ...TextFieldProps
      }}
      InputProps={InputProps}
    />
  )
}
