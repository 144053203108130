import {
  conditionResultsOptionModel,
  ConditionResultsValue
} from '@d1g1t/lib/metrics'

export const urlsFromConditionResultValue = (
  value: ConditionResultsValue[]
): string[] => {
  return value.map((option) => {
    const model = conditionResultsOptionModel(option)

    return model.url
  })
}
