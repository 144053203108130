import {IIconMenuMenuItemProps, IMenuItemActionOption} from './'

export interface IOptionsGrouped {
  [label: string]: IIconMenuMenuItemProps[]
}

export function isIMenuItemActionOption(
  options: IIconMenuMenuItemProps[]
): options is IMenuItemActionOption[] {
  if (!options) {
    return false
  }
  return (options[0] as IMenuItemActionOption)?.nestedMenuLabel !== undefined
}

/**
 * Converts array of options to object with `nestedMenuLabel`
 * as a key and array of all the options that has it as a value
 * @param options - array of action options
 */
export const formatDropdownOptions = (
  options: IIconMenuMenuItemProps[]
): IOptionsGrouped => {
  const result = {}
  if (isIMenuItemActionOption(options)) {
    for (const item of options) {
      const key = item.nestedMenuLabel
      result[key] = (result[key] || []).concat(item)
    }
  }
  return result
}

/**
 * Converts an array of options to 2 arrays(first one has only action options,
 * second one has options that need dropdown and will be rendered inside `NestedMenuItem`)
 *  based on `nestedMenuLabel`
 * @param options - array of action options
 */
export const createActionAndDropdownOptionsArrays = (
  options: IIconMenuMenuItemProps[]
): [IIconMenuMenuItemProps[], IIconMenuMenuItemProps[]] => {
  const actionOptions = []
  const dropdownOptions = []

  for (const item of options) {
    if ('nestedMenuLabel' in item) {
      if (item.nestedMenuLabel !== undefined) {
        dropdownOptions.push(item)
      } else {
        actionOptions.push(item)
      }
    } else {
      actionOptions.push(item)
    }
  }

  return [actionOptions, dropdownOptions]
}
