import {StandardResponseItem} from '@d1g1t/lib/standard-response'

import {CHECKED_STATE} from '@d1g1t/shared/containers/standard-table/constants'

const stateFromStateSet = (stateSet: Set<CHECKED_STATE>): CHECKED_STATE => {
  if (stateSet.has(CHECKED_STATE.PARTIAL)) {
    return CHECKED_STATE.PARTIAL
  }

  if (
    stateSet.has(CHECKED_STATE.EMPTY) &&
    stateSet.has(CHECKED_STATE.CHECKED)
  ) {
    return CHECKED_STATE.PARTIAL
  }

  if (stateSet.has(CHECKED_STATE.CHECKED)) {
    return CHECKED_STATE.CHECKED
  }

  return CHECKED_STATE.EMPTY
}

const updateCheckedStateMapForNode = (
  checkedIds: Set<string>,
  item: StandardResponseItem,
  checkedStateMap: Map<string, CHECKED_STATE>
): CHECKED_STATE => {
  if (!item.isLeafNode) {
    if (checkedIds.has(item.id)) {
      checkedStateMap.set(item.id, CHECKED_STATE.CHECKED)

      for (const id of item.descendentIds) {
        checkedStateMap.set(id, CHECKED_STATE.CHECKED)
      }

      return CHECKED_STATE.CHECKED
    }

    const childNodeStates: Set<CHECKED_STATE> = new Set()
    for (const childItem of item.children) {
      childNodeStates.add(
        updateCheckedStateMapForNode(checkedIds, childItem, checkedStateMap)
      )
    }

    const itemState = stateFromStateSet(childNodeStates)
    checkedStateMap.set(item.id, itemState)

    return itemState
  }

  if (checkedIds.has(item.id)) {
    checkedStateMap.set(item.id, CHECKED_STATE.CHECKED)

    return CHECKED_STATE.CHECKED
  }

  checkedStateMap.set(item.id, CHECKED_STATE.EMPTY)

  return CHECKED_STATE.EMPTY
}

/**
 * Takes a list of checked ids (leafs—intermediate nodes will be ignored)
 * Returns a map to represent the visual state of checkboxes
 */
export const checkedStateMapFromCheckedRowIds = (
  checkedIds: Set<string>,
  items: StandardResponseItem[]
): Map<string, CHECKED_STATE> => {
  const checkedStateMap: Map<string, CHECKED_STATE> = new Map()
  if (checkedIds.size === 0) {
    return checkedStateMap
  }

  for (const item of items) {
    updateCheckedStateMapForNode(checkedIds, item, checkedStateMap)
  }

  return checkedStateMap
}
