import React, {useMemo} from 'react'

import {FormikErrors, FormikTouched, useFormikContext} from 'formik'

import {Text} from '@d1g1t/shared/components/typography'

interface IFormErrorCountProps {
  /**
   * If true, ignores whether field is touched for displaying error count
   */
  ignoreTouched?: boolean
}

/**
 * Displays the number of errors at the bottom of large forms
 */
export const FormErrorCount: React.FC<IFormErrorCountProps> = (props) => {
  const {errors, touched} = useFormikContext()

  const errorCount = useMemo(
    () => getNumErrors(errors, touched, props.ignoreTouched),
    [errors, touched, props.ignoreTouched]
  )

  if (errorCount === 0) {
    return null
  }

  return (
    <Text bold negative>
      {errorCount} error{errorCount > 1 ? 's' : ''} in form
    </Text>
  )
}

/**
 * Recursively finds number of formik errors
 */
export function getNumErrors(
  errors: string | FormikErrors<any> | (string | FormikErrors<any>)[],
  touched: boolean | FormikTouched<any> | FormikTouched<any>[],
  ignoreTouched = false
): number {
  if (!errors || (!touched && !ignoreTouched)) {
    return 0
  }

  if (
    typeof errors === 'string' ||
    (Array.isArray(errors) &&
      errors.every((error) => typeof error === 'string'))
  ) {
    return touched || ignoreTouched ? 1 : 0
  }

  if (Array.isArray(errors) && (Array.isArray(touched) || ignoreTouched)) {
    return (errors as FormikErrors<any>[]).reduce(
      (prev, errorsAtIndex, idx) =>
        prev + getNumErrors(errorsAtIndex, touched?.[idx], ignoreTouched),
      0
    )
  }

  if (
    typeof errors === 'object' &&
    (typeof touched === 'object' || ignoreTouched)
  ) {
    return Object.entries(errors).reduce(
      (prev, [key, errorsForKey]) =>
        prev + getNumErrors(errorsForKey, touched?.[key], ignoreTouched),
      0
    )
  }

  return 0
}
