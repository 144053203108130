import React from 'react'

import {
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE_OPTIONS,
  TRADEORDER_ALLOCATION_METHOD_OPTIONS,
  TRADEORDER_COMMISSION_TYPE_OPTIONS,
  TRADEORDER_DIVIDEND_TREATMENT_OPTIONS,
  TRADEORDER_EXPIRY_TYPE_OPTIONS,
  TRADEORDER_OPERATION_OPTIONS,
  TRADEORDER_QTY_TYPE_OPTIONS,
  TRADEORDER_STATUS_OPTIONS,
  TRADEORDER_TYPE_OPTIONS
} from '@d1g1t/api/models'

import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'

export const createEnumValue = (
  options: IValueLabelOption[]
): React.ComponentClass<IDataCellProps> => {
  return class EnumValue extends React.Component<IDataCellProps> {
    getValue = () =>
      this.props.item.data.find(
        (dataItem) => dataItem.categoryId === this.props.category.id
      ).value

    render() {
      return (
        options.find((option) => this.getValue() === option.value)?.label ?? ''
      )
    }
  }
}

export const TradeOrderExpiryTypeValue = createEnumValue(
  TRADEORDER_EXPIRY_TYPE_OPTIONS
)
export const TradeOrderTypeValue = createEnumValue(TRADEORDER_TYPE_OPTIONS)
export const TradeOrderOperationValue = createEnumValue(
  TRADEORDER_OPERATION_OPTIONS
)
export const TradeOrderSecurityTypeValue = createEnumValue(
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE_OPTIONS
)
export const TradeOrderComissionTypeValue = createEnumValue(
  TRADEORDER_COMMISSION_TYPE_OPTIONS
)
export const TradeOrderQtyTypeValue = createEnumValue(
  TRADEORDER_QTY_TYPE_OPTIONS
)
export const TradeOrderDividendTreatmentValue = createEnumValue(
  TRADEORDER_DIVIDEND_TREATMENT_OPTIONS
)
export const TradeOrderStatusValue = createEnumValue(TRADEORDER_STATUS_OPTIONS)
export const TradeOrderAllocationMethodValue = createEnumValue(
  TRADEORDER_ALLOCATION_METHOD_OPTIONS
)
