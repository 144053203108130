import React, {forwardRef} from 'react'

import MuiToggleButtonGroup, {
  ToggleButtonGroupProps
} from '@material-ui/lab/ToggleButtonGroup'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './styles.scss'

export interface IToggleButtonGroupProps
  extends Omit<ToggleButtonGroupProps, 'classes'> {
  /**
   * Applies rounded border style, to create a "pill"-like element
   */
  rounded?: boolean
}

export const ToggleButtonGroup: React.FC<IToggleButtonGroupProps> = forwardRef(
  ({rounded, ...props}, ref) => {
    const classes = {
      root: classNames({
        [css.rounded]: rounded
      })
    }

    return <MuiToggleButtonGroup ref={ref} classes={classes} {...props} />
  }
)
