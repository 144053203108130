import {round} from 'lodash'

import {CurrencyEndpoints} from '@d1g1t/api/endpoints'
import {IChartTable, ISecurityTarget} from '@d1g1t/api/models'

import {
  itemWithUpdatedValue,
  responseWithUpdatedItem,
  responseWithUpdatedTotal,
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'

import {IStandardTableCategory} from '@d1g1t/shared/containers/standard-table/typings'

import {api} from '@d1g1t/advisor/api'
import {CATEGORY_IDS} from '@d1g1t/advisor/containers/rebalance/components/account/constants'
import {MODEL_PORTFOLIO_CATEGORY_IDS} from '@d1g1t/advisor/pages/security-model/holdings/constants'

export const calculateTargetValue = (params: {
  standardResponse: StandardResponse
  standardItem: StandardResponseItem
  category: IStandardTableCategory
  value: any
  overviewTotalCurrentValue?: number
}) => {
  const accountOrTotalCurrentValue = params.overviewTotalCurrentValue
    ? params.overviewTotalCurrentValue
    : params.standardResponse.totalItem.getValue(CATEGORY_IDS.CURRENT_VALUE)
  const securityCurrentValue = params.standardItem.getValue(
    CATEGORY_IDS.CURRENT_VALUE
  )
  const currentWeightValue = params.standardItem.getValue(
    CATEGORY_IDS.CURRENT_WEIGHT
  )

  if (params.category.id === CATEGORY_IDS.WEIGHT_CHANGE) {
    if (Number(params.value) === 0) {
      return round(securityCurrentValue, 2)
    }

    return round(
      accountOrTotalCurrentValue * (currentWeightValue + Number(params.value)),
      2
    )
  }

  if (params.category.id === CATEGORY_IDS.VALUE_CHANGE) {
    return round(securityCurrentValue + Number(params.value), 2)
  }

  if (params.category.id === CATEGORY_IDS.TARGET_WEIGHT) {
    if (Number(params.value) === currentWeightValue) {
      return round(securityCurrentValue, 2)
    }

    return round(accountOrTotalCurrentValue * Number(params.value), 2)
  }

  if (params.category.id === CATEGORY_IDS.TARGET_VALUE) {
    return round(Number(params.value), 2)
  }
}
/**
 * Returns new standard response with corresponding item data values updated.
 * For example if target value is updated then this method will calculate
 * the new target weight, change in weight and change in value for the same
 * item.
 * @param standardResponse - standard response to update
 * @param listOfUpdatedValues - list of items to be updated (could be multiple)
 */
export const calculatedWeightsValues = (
  standardResponse: StandardResponse,
  listOfUpdatedValues: {
    standardItem: StandardResponseItem
    category: IStandardTableCategory
    value: any
    overviewTotalCurrentValue?: number
  }[]
) => {
  let updatedResponse = {...standardResponse.toChartTable()}

  for (const updatedValue of listOfUpdatedValues) {
    const draftUpdatedResponse = new StandardResponse(updatedResponse)

    const accountOrTotalCurrentValue =
      updatedValue.overviewTotalCurrentValue ||
      draftUpdatedResponse.totalItem.getValue(CATEGORY_IDS.CURRENT_VALUE)

    const securityCurrentValue = updatedValue.standardItem.getValue(
      CATEGORY_IDS.CURRENT_VALUE
    )

    const currentWeightValue = updatedValue.standardItem.getValue(
      CATEGORY_IDS.CURRENT_WEIGHT
    )

    const updateValues = updateItemValues({
      value: updatedValue.value,
      accountOrTotalCurrentValue,
      securityCurrentValue,
      currentWeightValue,
      category: updatedValue.category
    })

    let item = {...updatedValue.standardItem.item}

    for (const key in updateValues) {
      item = itemWithUpdatedValue(item, key, updateValues[key])
    }

    updatedResponse = responseWithUpdatedTotal(
      responseWithUpdatedItem(
        draftUpdatedResponse.toChartTable(),
        updatedValue.standardItem.item.id,
        item
      ),
      [
        CATEGORY_IDS.TARGET_VALUE,
        CATEGORY_IDS.TARGET_WEIGHT,
        CATEGORY_IDS.WEIGHT_CHANGE,
        CATEGORY_IDS.VALUE_CHANGE,
        CATEGORY_IDS.CURRENT_VALUE,
        CATEGORY_IDS.CURRENT_WEIGHT,
        CATEGORY_IDS.TARGET_DRIFT,
        CATEGORY_IDS.CURRENT_DRIFT
      ]
    )
  }

  return updatedResponse
}

const updateItemValues = (params: {
  value: any
  category: IStandardTableCategory
  accountOrTotalCurrentValue: number
  securityCurrentValue: number
  currentWeightValue: number
}) => {
  if (params.category.id === CATEGORY_IDS.WEIGHT_CHANGE) {
    const weightChange = params.value
    const valueChange = params.accountOrTotalCurrentValue * weightChange

    return {
      [CATEGORY_IDS.WEIGHT_CHANGE]: weightChange,
      [CATEGORY_IDS.VALUE_CHANGE]: valueChange,
      [CATEGORY_IDS.TARGET_VALUE]:
        weightChange === 0
          ? params.securityCurrentValue
          : valueChange + params.securityCurrentValue,
      [CATEGORY_IDS.TARGET_WEIGHT]: params.currentWeightValue + weightChange
    }
  }

  if (params.category.id === CATEGORY_IDS.VALUE_CHANGE) {
    const valueChange = params.value
    const targetValue = params.securityCurrentValue + valueChange

    return {
      [CATEGORY_IDS.VALUE_CHANGE]: valueChange,
      [CATEGORY_IDS.TARGET_VALUE]: targetValue,
      [CATEGORY_IDS.WEIGHT_CHANGE]:
        valueChange / params.accountOrTotalCurrentValue,
      [CATEGORY_IDS.TARGET_WEIGHT]:
        targetValue / params.accountOrTotalCurrentValue
    }
  }

  if (params.category.id === CATEGORY_IDS.TARGET_WEIGHT) {
    const targetWeight = params.value
    const targetValue = params.accountOrTotalCurrentValue * targetWeight

    return {
      [CATEGORY_IDS.TARGET_WEIGHT]: targetWeight,
      [CATEGORY_IDS.TARGET_VALUE]:
        targetWeight === params.currentWeightValue
          ? params.securityCurrentValue
          : targetValue,
      [CATEGORY_IDS.VALUE_CHANGE]: targetValue - params.securityCurrentValue,
      [CATEGORY_IDS.WEIGHT_CHANGE]: targetWeight - params.currentWeightValue
    }
  }

  if (params.category.id === CATEGORY_IDS.TARGET_VALUE) {
    const targetValue = params.value
    const valueChange = targetValue - params.securityCurrentValue

    return {
      [CATEGORY_IDS.TARGET_VALUE]: targetValue,
      [CATEGORY_IDS.VALUE_CHANGE]: valueChange,
      [CATEGORY_IDS.TARGET_WEIGHT]:
        targetValue / params.accountOrTotalCurrentValue,
      [CATEGORY_IDS.WEIGHT_CHANGE]:
        valueChange / params.accountOrTotalCurrentValue
    }
  }

  // Special category for portfolio rebalancing
  if (params.category.id === MODEL_PORTFOLIO_CATEGORY_IDS.MIN_BOUND) {
    return {
      [MODEL_PORTFOLIO_CATEGORY_IDS.MIN_BOUND]: params.value
    }
  }

  // Special category for portfolio rebalancing
  if (params.category.id === MODEL_PORTFOLIO_CATEGORY_IDS.MAX_BOUND) {
    return {
      [MODEL_PORTFOLIO_CATEGORY_IDS.MAX_BOUND]: params.value
    }
  }

  // Special category for portfolio rebalancing
  if (params.category.id === MODEL_PORTFOLIO_CATEGORY_IDS.MIN_THRESHOLD) {
    return {
      [MODEL_PORTFOLIO_CATEGORY_IDS.MIN_THRESHOLD]: params.value
    }
  }

  // Special category for portfolio rebalancing
  if (params.category.id === MODEL_PORTFOLIO_CATEGORY_IDS.MAX_THRESHOLD) {
    return {
      [MODEL_PORTFOLIO_CATEGORY_IDS.MAX_THRESHOLD]: params.value
    }
  }

  return null
}

export const getTargetValuesFromStandardResponse = (
  standardResponse: StandardResponse
) => {
  const targetValues: ISecurityTarget[] = []
  for (const item of standardResponse) {
    /** value change !== 0
     *  OR
     *  current value does not exist (for new securities)
     *  OR
     *  has_draft is `true` therefore we should send this target value
     *  to make sure it is updated
     */
    if (
      !(
        round(item.getValue(CATEGORY_IDS.VALUE_CHANGE), 2) !== 0 ||
        !(typeof item.getValue(CATEGORY_IDS.CURRENT_VALUE) === 'number') ||
        item.getValue(CATEGORY_IDS.HAS_DRAFT)
      )
    ) {
      continue
    }

    const targetValue: ISecurityTarget = {
      value: round(item.getValue(CATEGORY_IDS.TARGET_VALUE), 2)
    }

    const instrumentUrl = item.getValue(CATEGORY_IDS.INSTRUMENT_URL)
    if (instrumentUrl) {
      targetValue.instrument = instrumentUrl
    } else {
      targetValue.currency = api.buildUrl(
        CurrencyEndpoints.pathToResource(item.id)
      )
    }

    targetValues.push(targetValue)
  }

  return targetValues
}

export const getCurrentValue = (table: IChartTable) => {
  const standardResponseItem = new StandardResponseItem(table.items[0])

  return standardResponseItem.getValue(CATEGORY_IDS.CURRENT_VALUE)
}
