import {find} from 'lodash'

import {StandardResponse} from '@d1g1t/lib/standard-response'

interface IUseStandardResponseUpdaterOptions {
  /**
   * List of ids of records to update in the standard response
   */
  itemIds: string[]

  /**
   * The standard response to update
   */
  standardResponse: StandardResponse

  /**
   * Function to update the standard response
   * @param standardResponse - standardResponse
   */
  updateStandardResponse(standardResponse: StandardResponse)

  /**
   * Hardcoded mapping of model keys to category ids
   */
  keyToCategoryIdMap: {[key: string]: string}
}

interface IUseStandardResponseUpdaterReturnType {
  /**
   * Update a standard response value
   *
   * @param itemId - when passed the update will be performed only to the item passed.
   * In case the `ItemId` is not defined the update will be done to all the items defined on the hook initialization
   */
  updateStandardResponseValue(
    value: any,
    key: string,
    cellKey?: any,
    itemId?: string
  ): void

  /**
   * Updates a standard response value using
   * the option's label matching the provided value
   *
   * @param itemId - when passed the update will be performed only to the item passed.
   * In case the `ItemId` is not defined the update will be done to all the items defined on the hook initialization
   */
  updateStandardResponseOption(
    value: string,
    key: string,
    options: Array<{value: string | number; label: string}>,
    itemId?: string
  ): void

  /**
   * Updates a standard response value using
   * the associated model's name matching the provided url
   *
   * @param itemId - when passed the update will be performed only to the item passed.
   * In case the `ItemId` is not defined the update will be done to all the items defined on the hook initialization
   */
  updateStandardResponseAssociatedUrl(
    url: string,
    key: string,
    models: Array<{name?: string; url?: string}>,
    itemId?: string
  ): void

  /**
   * Updates a standard response value using
   * the label in the provided boolean-to-label map
   *
   * @param itemId - when passed the update will be performed only to the item passed.
   * In case the `ItemId` is not defined the update will be done to all the items defined on the hook initialization
   */
  updateStandardResponseBoolean(
    value: boolean,
    key: string,
    booleanToLabelMap: {
      true: string
      false: string
    },
    itemId?: string
  ): void
}

export const DEFAULT_BOOLEAN_LABEL_MAP = {
  true: 'Yes',
  false: 'No'
}

/**
 * Helpers to simplify the process of updating the values in a
 * standard response due to quick look drawer field edits
 *
 * Note: this is a holdover from the quick edit fields. Once all of
 * the quick edit forms have been migrated to use Formik, we can
 * likely simplify some of this logic.
 */
export const useStandardResponseUpdater = ({
  itemIds,
  standardResponse,
  keyToCategoryIdMap,
  updateStandardResponse
}: IUseStandardResponseUpdaterOptions): IUseStandardResponseUpdaterReturnType => {
  const updateStandardResponseValue: IUseStandardResponseUpdaterReturnType['updateStandardResponseValue'] =
    (value, key, cellKey, itemId) => {
      const categoryId = keyToCategoryIdMap[key]

      if (!categoryId) {
        console.error(
          'Could not find mapped categoryId',
          keyToCategoryIdMap,
          key
        )
        return
      }

      const itemsToUpdate = itemId ? [itemId] : itemIds

      for (const _itemId of itemsToUpdate) {
        // update standard response in memory incase multiple
        // updates need to occur between render cycles
        standardResponse = standardResponse.updateValue(
          categoryId,
          _itemId,
          value,
          cellKey
        )
      }

      updateStandardResponse(standardResponse)
    }

  const updateStandardResponseOption: IUseStandardResponseUpdaterReturnType['updateStandardResponseOption'] =
    (value, key, options, itemId) => {
      const option = find(options, {value})
      updateStandardResponseValue(
        option?.label,
        key,
        option?.value ?? null,
        itemId
      )
    }

  const updateStandardResponseAssociatedUrl: IUseStandardResponseUpdaterReturnType['updateStandardResponseAssociatedUrl'] =
    (url, key, models, itemId) => {
      const associatedOption = find(models, {url})
      const label = associatedOption ? associatedOption.name : null
      updateStandardResponseValue(label, key, undefined, itemId)
    }

  const updateStandardResponseBoolean: IUseStandardResponseUpdaterReturnType['updateStandardResponseBoolean'] =
    (value, key, booleanToLabelMap, itemId) => {
      const label =
        booleanToLabelMap[value?.toString()] ?? DEFAULT_BOOLEAN_LABEL_MAP
      updateStandardResponseValue(label, key, undefined, itemId)
    }

  return {
    updateStandardResponseValue,
    updateStandardResponseOption,
    updateStandardResponseAssociatedUrl,
    updateStandardResponseBoolean
  }
}
