import React from 'react'

import {capitalize} from 'lodash'

import {Flex} from '@d1g1t/shared/components/flex'
import {Paper} from '@d1g1t/shared/components/mui/paper'
import {H3, H4} from '@d1g1t/shared/components/typography'

import * as css from './style.scss'

interface IKeyboardShortCurtsProps {
  /**
   * List of shortcuts with their name and their corresponding key combos
   */
  shortcuts: {name: string; leaderKey: string; actionKey: string}[]
}

const PlusSign: React.FC = () => (
  <H4 normalWeight className={css.plusSign}>
    +
  </H4>
)

/**
 * Component that renders list of shortcuts
 * (Section Name - Leader Key + SecondKey)
 */
export const KeyBoardShortCuts: React.FC<IKeyboardShortCurtsProps> = (
  props
) => {
  return (
    <Paper className={css.paper}>
      <H3 semiBold className={css.title}>
        Keyboard Shortcuts
      </H3>
      <H4>Module</H4>
      <H4>Keys</H4>
      {props.shortcuts.map((shortcut) => (
        <>
          <H4 normalWeight>{shortcut.name}</H4>
          <Flex alignCenter>
            <H4 normalWeight className={css.shortcutLetter}>
              {capitalize(shortcut.leaderKey)}
            </H4>
            <PlusSign />
            <H4 normalWeight className={css.shortcutLetter}>
              {capitalize(shortcut.actionKey)}
            </H4>
          </Flex>
        </>
      ))}
    </Paper>
  )
}
