import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {NavLink, useHistory} from 'react-router-dom'
import {Link as ScrollLink, scroller} from 'react-scroll'

import {isEmpty} from 'lodash'

import {makeStyles} from '@material-ui/core'

import {classNames} from '@d1g1t/lib/class-names'
import {scrollToTop} from '@d1g1t/lib/dom'
import {usePrevious} from '@d1g1t/lib/hooks'

import * as css from './style.scss'

export const SCROLL_ANIMATION_DURATION = 300

export interface IControlBoxNavGroup<S = any> {
  route?: string
  title: string
  section?: string
  sections?: IValueLabelOption<S>[]
}

interface IControlBoxNavProps {
  sectionOffset?: number
  groups: IControlBoxNavGroup[]
  isLoading?: boolean
}

export const ControlBoxNav: React.FC<IControlBoxNavProps> = ({
  sectionOffset = -170,
  groups,
  isLoading
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const [activeLink, setActiveLink] = useState('')
  const hasLoadingStarted = usePrevious(isLoading)
  const timers = useRef([])

  useEffect(() => {
    if (isLoading === undefined) {
      return
    }

    if (hasLoadingStarted && !isLoading) {
      // Cache active link as it could be changed dynamically during scrolling
      const finalActiveLink = activeLink

      // Launch scroll to active link with a short delay after loading is stopped
      timers.current[0] = setTimeout(() => {
        scroller.scrollTo(finalActiveLink, {
          smooth: true,
          isDynamic: true,
          offset: sectionOffset,
          duration: SCROLL_ANIMATION_DURATION
        })
      }, 100)

      // Make sure that specific link will be active at the end of scrolling as active state is changed during scrolling
      timers.current[1] = setTimeout(() => {
        setActiveLink(finalActiveLink)
      }, SCROLL_ANIMATION_DURATION + 150)
    }
  }, [isLoading, activeLink, setActiveLink])

  useEffect(() => {
    return () => {
      for (const timer of timers.current) {
        clearTimeout(timer)
      }
    }
  }, [])

  return (
    <div className={css.controlBoxNav}>
      {groups.map((group, index) => {
        const navLinkOrSectionComponent = (children: JSX.Element) => {
          if (group.section) {
            return (
              <div className={css.group}>
                <SectionLinkItem
                  key={group.section}
                  section={{value: group.section, label: group.title}}
                  sectionOffset={sectionOffset}
                  activeLink={activeLink}
                  setActiveLink={setActiveLink}
                  linkCssStyle={css.active}
                >
                  {children}
                </SectionLinkItem>
              </div>
            )
          }

          return (
            <NavLink
              exact
              to={group.route}
              className={classNames(css.group)}
              activeClassName={css.active}
              onClick={() => {
                const firstSection = group?.sections?.[0]?.value?.toString()
                setActiveLink(firstSection || '')
                scrollToTop()
              }}
            >
              {children}
            </NavLink>
          )
        }

        return (
          <div key={group.title}>
            {navLinkOrSectionComponent(
              <>
                <span className={css.number}>{index + 1}</span>
                <span className={css.text}>{t(group.title)}</span>
              </>
            )}
            {!isEmpty(group.sections) && (
              <ul className={css.sections}>
                {group.sections.map((section) => (
                  <li key={section.value}>
                    <SectionLinkItem
                      section={section}
                      route={group.route}
                      sectionOffset={sectionOffset}
                      activeLink={activeLink}
                      setActiveLink={setActiveLink}
                      linkCssStyle={classes.active}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    active: {
      color: theme.palette.primary.dark
    }
  }
})
interface ISectionLinkItemProps {
  sectionOffset: number
  route?: string
  section: IValueLabelOption
  activeLink: string
  setActiveLink: (link: string) => void
  linkCssStyle: string
}

const SectionLinkItem: React.FC<ISectionLinkItemProps> = ({
  sectionOffset,
  route,
  section,
  activeLink,
  setActiveLink,
  linkCssStyle,
  children
}) => {
  const history = useHistory()
  const {t} = useTranslation()
  const timer = useRef(null)

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const handleSectionLinkClick = (scrollId: string, route: string) => {
    if (route) {
      history.push(route)
    }

    // Set active link in controlled way to avoid an issue with inability to highlight links on the bottom correctly
    // like this - https://github.com/fisshy/react-scroll/issues/466
    timer.current = setTimeout(() => {
      setActiveLink(String(scrollId))
    }, SCROLL_ANIMATION_DURATION)
  }

  const isActive = activeLink === String(section.value)

  return (
    <ScrollLink
      spy
      smooth
      isDynamic
      offset={sectionOffset}
      duration={SCROLL_ANIMATION_DURATION}
      activeClass={classNames({
        [linkCssStyle]: isActive
      })}
      className={classNames({
        [linkCssStyle]: isActive
      })}
      to={String(section.value)}
      href={`#${section.value}`}
      onSetActive={(toLink) => {
        setActiveLink(toLink)
      }}
    >
      <span
        onClick={(e) => {
          e.preventDefault()
          handleSectionLinkClick(section.value, route)
        }}
      >
        {children || t(section.label)}
      </span>
    </ScrollLink>
  )
}
