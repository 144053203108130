import React from 'react'
import {useRouteMatch} from 'react-router-dom'

import {UI_PERMISSIONS} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {extractPath} from '@d1g1t/lib/url'

import {Flex} from '@d1g1t/shared/components/flex'
import {RouterLink} from '@d1g1t/shared/components/router-link'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {usePermissions} from '@d1g1t/shared/wrappers/permissions'

import * as css from './styles.scss'

interface INavigationTabItemOptions {
  label: string
  disabled?: boolean
  active?: boolean
  /**
   * Hides the link in the menu, but allows matching to show title.
   */
  testid?: string

  /**
   * The permission code that the user must have to access this item.
   */
  permissionCode: UI_PERMISSIONS
}

interface INavigationTabItemLink extends INavigationTabItemOptions {
  to: string

  /**
   * If passed, will use `match` instead of `to` to decide which title
   * to display.
   */
  match?: string | string[]
}

interface INavigationTabItemAction extends INavigationTabItemOptions {
  onClick(): void
}

export type INavigationTabItem =
  | INavigationTabItemLink
  | INavigationTabItemAction

function isLinkOption(
  option: INavigationTabItem
): option is INavigationTabItemLink {
  return 'to' in option
}

export interface INavigationTabsProps {
  tabs: INavigationTabItem[]
  /**
   * When true, moves the tabs up a little so they're not touching the content.
   * @example true in Manage Client page
   * @example false in Admin pages with tabs touching the content
   */
  detached?: boolean
  children?: never
}

export const NavigationTabs: React.FC<INavigationTabsProps> = ({
  tabs,
  detached
}) => {
  const permissions = usePermissions()
  return (
    <Flex>
      {tabs
        .filter((tab) => permissions.canAccess(tab.permissionCode))
        .map((item, index) => (
          <React.Fragment key={index}>
            <NavTab item={item} detached={detached} />
            {index !== tabs.length - 1 && <Spacer vertical custom={5} />}
          </React.Fragment>
        ))}
    </Flex>
  )
}

NavigationTabs.displayName = 'NavigationTabs'

interface INavTabProps {
  /**
   * When true, moves the tabs up a little so they're not touching the content.
   * @example true in Manage Client page
   * @example false in Admin pages with tabs touching the content
   */
  detached?: boolean
  item: INavigationTabItem
}

const NavTab: React.FC<INavTabProps> = ({detached, item}) => {
  const match = useRouteMatch(
    isLinkOption(item) && (item.match || (item.to && extractPath(item.to)))
  )

  const tab = (
    <div
      className={classNames(css.tab, {
        [css.active]:
          item.active ||
          (isLinkOption(item) && !item.disabled && match && match.isExact),
        [css.disabled]: item.disabled,
        [css.detached]: detached
      })}
    >
      {item.label}
    </div>
  )

  if (isLinkOption(item)) {
    const {permissionCode, ...linkItem} = item // removing permissionCode to avoid console warning on runtime
    return (
      <RouterLink
        data-testid={linkItem.testid}
        noUnderline
        {...linkItem}
        to={!linkItem.disabled && linkItem.to}
      >
        {tab}
      </RouterLink>
    )
  }

  return (
    <button className={css.button} onClick={item.onClick} type='button'>
      {tab}
    </button>
  )
}
