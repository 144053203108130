import {fork, setContext} from 'redux-saga/effects'

import {authSagas} from '@d1g1t/shared/wrappers/auth/sagas'
import {cphTableSagas} from '@d1g1t/shared/wrappers/calculations/sagas'
import {errorHandlerSagas} from '@d1g1t/shared/wrappers/error-handler/sagas'
import {globalSettingsSagas} from '@d1g1t/shared/wrappers/global-settings/sagas'

import {ISagaConext} from './typings'

export function* rootSaga(sagaContext: ISagaConext) {
  yield setContext(sagaContext)

  yield fork(authSagas)
  yield fork(cphTableSagas)
  yield fork(globalSettingsSagas)
  yield fork(errorHandlerSagas)
}
