import React, {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {ButtonProps} from '@material-ui/core/Button'

import {DATE_RANGES} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {DateFormatter} from '@d1g1t/lib/formatters/date-formatter'
import {useToggleState} from '@d1g1t/lib/hooks'

import {CalculationOverrideStat} from '@d1g1t/shared/components/calculation-override-stat'
import {Button} from '@d1g1t/shared/components/mui/button'
import {DatePicker} from '@d1g1t/shared/components/mui/date-picker'
import {Menu} from '@d1g1t/shared/components/mui/menu'
import {MenuItem} from '@d1g1t/shared/components/mui/menu-item'
import {useCalculationOptionsOverride} from '@d1g1t/shared/wrappers/calculation-options'
import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {useUserLocalizationPreferences} from '@d1g1t/shared/wrappers/localization-settings/hook'

import {
  DATE_OPTION_BEGINNING,
  PAGE_LEVEL_PERIOD_DATE_RANGE_OPTIONS_OVERRIDE
} from './constants'

import * as css from './style.scss'

interface IPageLevelPeriodSelectProps extends ButtonProps {
  /**
   * Boolean passed to prevent grayBackground prop and button class styling
   * needed for when page level period select is in the main monitor page.
   */
  override?: boolean
}

/**
 * Allows user to override the calculation option date-range at the page level.
 */
export const PageLevelPeriodSelect: React.FC<IPageLevelPeriodSelectProps> = ({
  override,
  ...rest
}) => {
  const periodSelectorRef = useRef<HTMLButtonElement>(null)

  const [calculationSettings] = useCalculationSettings()
  const [dateFormat] = useUserLocalizationPreferences()

  const {t} = useTranslation()

  const [calculationOptions, {updateCalculationPeriodOverride}] =
    useCalculationOptionsOverride()

  const [
    beginningDatePickerOpen,
    ,
    openBeginningDatePicker,
    closeBeginningDatePicker
  ] = useToggleState(false)

  const [dropdownOpen, , openDropdown, closeDropdown] = useToggleState(false)

  useEffect(() => {
    if (calculationOptions.pageLevelDateRange?.value === DATE_RANGES.CUSTOM) {
      updateCalculationPeriodOverride({
        pageLevelDateRange: {
          ...calculationOptions.pageLevelDateRange,
          endDate: calculationSettings.date.date
        }
      })
    }
  }, [calculationSettings.date.date])

  const dateRangeOptions =
    calculationOptions.dateRangeOptions ||
    PAGE_LEVEL_PERIOD_DATE_RANGE_OPTIONS_OVERRIDE

  const handleUpdateBeginningDate = (date: string) => {
    // Same as custom but end-date is automatically set as the As Of Date
    updateCalculationPeriodOverride({
      pageLevelDateRange: {
        label: `${t(DATE_OPTION_BEGINNING.label, {
          context: '_calculation_label'
        })} ${new DateFormatter({
          dateFormat
        }).format(date)}`,
        value: DATE_OPTION_BEGINNING.value,
        startDate: date,
        endDate: calculationSettings.date.date
      }
    })
  }

  return (
    <>
      <Button
        // https://stackoverflow.com/questions/57962146/button-components-inside-buttongroup
        // The way that ButtonGroup works is by cloning the child Button elements
        // and adding props to control the styling. When you introduce a custom
        // component in between, you need to pass through to the Button any props
        // not used by your custom component.
        {...rest}
        grayBackground={!override}
        className={`${rest.className} ${classNames({[css.button]: !override})}`}
        ref={periodSelectorRef}
        onClick={openDropdown}
      >
        <CalculationOverrideStat
          label='Period'
          data={calculationOptions?.pageLevelDateRange?.label}
        />
      </Button>
      <Menu
        open={dropdownOpen}
        onClose={closeDropdown}
        anchorEl={periodSelectorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        transformOrigin={{horizontal: 'left', vertical: 'top'}}
      >
        {dateRangeOptions.map((dateRange) => (
          <MenuItem
            key={dateRange.value}
            onClick={() => {
              closeDropdown()
              if (dateRange.value === DATE_RANGES.CUSTOM) {
                openBeginningDatePicker()
                return
              }
              updateCalculationPeriodOverride({
                pageLevelDateRange: dateRange
              })
            }}
          >
            {dateRange.label}
          </MenuItem>
        ))}
      </Menu>
      <DatePicker
        hideError
        showTodayButton
        open={beginningDatePickerOpen}
        value={
          calculationOptions.pageLevelDateRange?.startDate ||
          calculationSettings.date.date
        }
        maxDate={calculationSettings.date.date}
        inputProps={{
          'data-testid': 'page-period-beginning-date-select'
        }}
        onClose={closeBeginningDatePicker}
        onChange={handleUpdateBeginningDate}
        style={{display: 'none'}}
      />
    </>
  )
}
