import React from 'react'

import {StandardResponseItem} from '@d1g1t/lib/standard-response'

import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'
import {FormattedNumber} from '@d1g1t/shared/wrappers/formatter'

import * as css from './styles.scss'

interface IFillCellProps extends IDataCellProps {}

const getStyle = (value) => {
  if (value === 1) {
    return {
      backgroundColor: '%4bb6ac',
      color: '#f7f7f7'
    }
  }

  return {
    background: `linear-gradient(to left, #FFFFFF ${
      100 - value * 100
    }%, #4bb6ac ${100 - value * 100}%)`
  }
}

export const FillCell: React.SFC<IFillCellProps> = (props: IFillCellProps) => {
  const standardItem = new StandardResponseItem(props.item)

  return (
    <div
      className={css.cell}
      style={getStyle(standardItem.getKey(props.category.id))}
    >
      <FormattedNumber
        value={standardItem.getValue(props.category.id)}
        style='percent'
        decimalPlaces={1}
        allowZero
      />
    </div>
  )
}
