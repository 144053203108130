import React, {useEffect, useState} from 'react'

import {useApiQuery} from 'fairlight'
import {isEmpty} from 'lodash'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import FilterListIcon from '@material-ui/icons/FilterList'
import GroupIcon from '@material-ui/icons/Group'
import PublicIcon from '@material-ui/icons/Public'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

import {RuleFilterEndpoints} from '@d1g1t/api/endpoints'

import {useWillComponentUnmount} from '@d1g1t/lib/hooks/use-will-component-unmount'

import {Flex} from '@d1g1t/shared/components/flex'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Divider} from '@d1g1t/shared/components/mui/divider'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {ListItemIcon} from '@d1g1t/shared/components/mui/list-item-icon'
import {MenuItem} from '@d1g1t/shared/components/mui/menu-item'

import {useEntityChipContext} from '../../../..'
import {compareRuleFilters} from '../../lib'

import * as css from './style.scss'

interface IRulesFilteringSubmenuProps {
  onClose(): void
  onManageRuleFiltersClick(): void
}

/**
 * Rule-based filters menu. Must be used as a child of EntityChip component.
 *
 * Shows a list of available rule-filters. On click, it applies/removes the
 * rule filter id from the query params. Also controls the RuleFilter modal to
 * manage the rule filters (create, edit, remove).
 */
export const RuleFiltersMenu: React.FC<IRulesFilteringSubmenuProps> =
  React.memo((props, ref) => {
    const {entity, actions} = useEntityChipContext()
    const componentWillUnmount = useWillComponentUnmount()

    const [appliedRulesUrls, setAppliedRulesUrls] = useState(
      entity?.ruleFilters ?? []
    )

    useEffect(() => {
      return () => {
        if (
          componentWillUnmount.current &&
          !(isEmpty(appliedRulesUrls) && isEmpty(entity?.ruleFilters)) &&
          appliedRulesUrls !== entity?.ruleFilters
        ) {
          // If component will unmount, apply filters from current state
          actions.applyEntityFilters(entity.entityId, {
            ruleFilters: appliedRulesUrls
          })
        }
      }
    }, [appliedRulesUrls, componentWillUnmount.current])

    const [allRuleFilters] = useApiQuery(RuleFilterEndpoints.list(), {
      fetchPolicy: 'cache-and-fetch'
    })

    return (
      <LoadingContainer loading={allRuleFilters.loading}>
        {allRuleFilters.data.results
          .sort(compareRuleFilters)
          .map((ruleFilter) => {
            const isApplied = appliedRulesUrls.includes(ruleFilter.url)
            const isSharedToInvestors = ruleFilter.isAvailableToExternalProfiles
            const isSharedToAdvisors = ruleFilter.isShared
            return (
              <MenuItem
                key={ruleFilter.url}
                data-dd-action='Entity - Rule filter menu chip'
              >
                <Flex
                  grow
                  alignCenter
                  onClick={() => {
                    setAppliedRulesUrls([ruleFilter.url])

                    actions.applyEntityFilters(entity.entityId, {
                      ruleFilters: [ruleFilter.url]
                    })

                    props.onClose()
                  }}
                >
                  <ListItemIcon>
                    <FilterListIcon fontSize='small' />
                  </ListItemIcon>
                  {ruleFilter.name}
                  {isSharedToInvestors && (
                    <GroupIcon className={css.globalIcon} />
                  )}
                  {isSharedToAdvisors && (
                    <PublicIcon className={css.globalIcon} />
                  )}
                </Flex>
                <Flex
                  alignEnd
                  style={{paddingLeft: '10px'}}
                  onClick={() => {
                    setAppliedRulesUrls(
                      isApplied
                        ? appliedRulesUrls.filter((id) => id !== ruleFilter.url)
                        : appliedRulesUrls.concat(ruleFilter.url)
                    )
                  }}
                >
                  <IconButton size='small'>
                    {isApplied ? (
                      <CheckCircleIcon fontSize='small' />
                    ) : (
                      <RadioButtonUncheckedIcon fontSize='small' />
                    )}
                  </IconButton>
                </Flex>
              </MenuItem>
            )
          })}

        {allRuleFilters.data.results.length > 0 && (
          <Divider style={{marginTop: '1rem', marginBottom: '1rem'}} />
        )}

        <MenuItem onClick={props.onManageRuleFiltersClick}>
          Manage Rule Filters
        </MenuItem>
        {appliedRulesUrls.length > 0 && (
          <MenuItem
            onClick={() => {
              setAppliedRulesUrls([])

              actions.applyEntityFilters(entity.entityId, {
                ruleFilters: []
              })

              props.onClose()
            }}
          >
            Remove All Rule Filters
          </MenuItem>
        )}
      </LoadingContainer>
    )
  })
