import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'
import {getLabelWithValue} from '@d1g1t/lib/value-label'

import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'
import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'

import * as commonCss from '@d1g1t/shared/containers/standard-table/styles.scss'

/**
 * A dropdown component for adding/editing value. Provided as a prop to `StandardTable`.
 * If 'editbale' is set to `false` will just display the value,
 * if 'editbale' is set to `true` or not set - will display en editable dropdown with the selected option as a value.
 */
export const DropdownCell: React.FC<IDataCellProps> = (props) => {
  const cellData = props.item.getDatum(props.category.id)

  const options = cellData.options?.allowedValues.map((value) => {
    return {
      value: value.key,
      label: value.value
    }
  })

  const handleChange = (value) => {
    if (cellData.key === value) {
      return
    }

    props.onChange(props.item, props.category, value, value)
  }

  const value = (() => {
    if (options) {
      return getLabelWithValue(cellData.key, options)
    }

    return cellData.value
  })()

  if (!options || cellData.options?.editable === false) {
    return (
      <div
        className={classNames(commonCss.innerCell, {
          [commonCss.compactModeInnerCell]: props.isCompactMode
        })}
      >
        {value}
      </div>
    )
  }
  const error = Boolean(
    typeof props.getCellError === 'function' &&
      props.getCellError({
        item: props.item,
        category: props.category
      })
  )

  return (
    <ValueLabelSelect
      size='medium'
      error={error}
      whiteBackground
      value={cellData.key}
      options={options}
      onChange={handleChange}
      data-testid='select-standard-table-inline-enum'
      data-category-id={props.category.id}
      data-item-id={props.item.id}
      fullWidth
      overFlowHidden
      isCompactMode={props.isCompactMode}
    />
  )
}
