export enum CATEGORY_IDS {
  ACCOUNT_URL = 'account_url',
  CUSTODIAN_ACCOUNT_URL = 'custodian_account_url',
  DIVIDEND_TREATMENT = 'dividend_treatment',
  FUND_CODE = 'fund_code',
  GOOD_UNTIL = 'good_until',
  GOOD_UNTIL_DAY = 'good_until_day',
  INSTRUMENT_URL = 'instrument_url',
  IS_SELL_ALL = 'is_sell_all',
  IS_DSC_FREE = 'is_dsc_free',
  LIMIT_PRICE = 'limit_price',
  MARKET = 'market',
  OPERATION = 'operation',
  ORDER_TYPE = 'order_type',
  QUANTITY = 'quantity',
  QUANTITY_TYPE = 'quantity_type',
  INSTRUCTIONS = 'instructions',
  NOTES = 'notes',
  SYMBOL = 'symbol',
  TRADE_DATE = 'trade_date',
  TRADING_TYPE = 'trading_type',
  IS_FUND = 'is_fund',
  TRADE_VALUE = 'trade_value',
  TRIGGER_PRICE = 'trigger_price',
  COMMISSION = 'commission',
  COMMISSION_TYPE = 'commission_type',
  SLEEVE_URL = 'sleeve_url'
}

export const TRADE_PREVIEW_TABLE_ID = 'TRADE-PREVIEW'

export enum RELATED_COMPONENT {
  REBALANCE = 'rebalance',
  REBALANCE_STRATEGIES = 'rebalanceStrategies',
  TRADE_DIRECTIVE = 'tradeDirective'
}
