import React, {useMemo, useRef} from 'react'

import {isAfter, parseISO} from 'date-fns'
import {useApiQuery} from 'fairlight'

import {GlobalFilterEndpoints} from '@d1g1t/api/endpoints'

import {usePrimedDates, useToggleState} from '@d1g1t/lib/hooks'

import {CalculationOverrideStat} from '@d1g1t/shared/components/calculation-override-stat'
import {Button} from '@d1g1t/shared/components/mui/button'
import {ButtonGroup} from '@d1g1t/shared/components/mui/button-group'
import {DatePicker} from '@d1g1t/shared/components/mui/date-picker'
import {IconMenu, IIconMenuProps} from '@d1g1t/shared/components/mui/icon-menu'
import {ListItemText} from '@d1g1t/shared/components/mui/list-item-text'
import {Menu} from '@d1g1t/shared/components/mui/menu'
import {MenuItem, MenuItemLink} from '@d1g1t/shared/components/mui/menu-item'
import {PageLevelPeriodSelect} from '@d1g1t/shared/components/page-level-period-select'
import {useCalculationOptionsOverride} from '@d1g1t/shared/wrappers/calculation-options'
import {
  useCalculationSettings,
  useCalculationSettingsOverride
} from '@d1g1t/shared/wrappers/calculation-settings'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {useDateFormatter} from '@d1g1t/shared/wrappers/formatter'
import {
  DEFAULT_ALL_CLIENT_OPTION,
  useGlobalFilter,
  useGlobalFilterContext
} from '@d1g1t/shared/wrappers/global-filter'

import {GlobalFilterLocations} from '@d1g1t/advisor/locations'

import {IPageTitleBarProps} from '../..'

import * as css from './style.scss'

export interface ISettingsProps
  extends Pick<
    IPageTitleBarProps,
    'disableCurrency' | 'disableDate' | 'hideCurrency'
  > {
  /**
   * Hides the date on Settings display
   */
  hideDatePicker?: boolean
  /**
   * Hides the global filters on Settings display
   */
  hideGlobalFilters?: boolean
  /**
   * Menu options under 3 dots button in Calculation Settings
   */
  threeDotsMenuOptionOptions?: IIconMenuProps['options']
  /**
   * Allow all dates to be selected in as of date select
   */
  asOfUseAllDates?: boolean
}

/**
 * Provides page level currency, as-of-date, and global picker controls.
 */
export const PageCalculationSettings: React.FC<ISettingsProps> = (props) => {
  const {oldestPrimedDate, latestPrimedDate, primedDates} = usePrimedDates()
  const [, {updateGlobalFilter}] = useGlobalFilter()
  const [globalFilters] = useApiQuery(GlobalFilterEndpoints.list())
  const globalFilter = useGlobalFilterContext()
  const {firmConfiguration} = useFirmConfiguration()
  const [calculationSettings, {updateCalculationSettings}] =
    useCalculationSettings({
      ignoreFirmConfigLatestDate: !!props.asOfUseAllDates
    })
  const [, {updateCalculationSettingsOverride}] =
    useCalculationSettingsOverride()

  const [calculationOptions, {updateCalculationPeriodOverride}] =
    useCalculationOptionsOverride()

  const mergedThreeDotsMenuOptions = useMemo(() => {
    const options = []
    if (calculationOptions) {
      options.push({
        label: 'Set all components to match date range',
        onClick: () => {
          updateCalculationPeriodOverride({
            setAllDateRange: Symbol()
          })
        }
      })
    }
    if (props.threeDotsMenuOptionOptions) {
      return options.concat(props.threeDotsMenuOptionOptions)
    }
    return options
  }, [props.threeDotsMenuOptionOptions, calculationOptions])

  const [
    globalFiltersMenuOpen,
    ,
    openGlobalFiltersMenu,
    closeGlobalFiltersMenu
  ] = useToggleState(false)

  const [datePickerOpen, , openDatePicker, closeDatePicker] =
    useToggleState(false)

  const [currencyMenuOpen, , openCurrencyMenu, closeCurrencyMenu] =
    useToggleState(false)

  const dateFormatter = useDateFormatter()

  const handleUpdateDate = (date: string) => {
    if (
      !props.asOfUseAllDates &&
      isAfter(
        parseISO(date),
        parseISO(firmConfiguration.data?.latestDataAvailable)
      )
    ) {
      date = firmConfiguration.data?.latestDataAvailable
    }

    updateCalculationSettingsOverride({
      date: {
        date,
        value: 'specificDate'
      }
    })
  }

  const handleUpdateCurrency = (currency: string) => {
    const nextCurrency = currency.toUpperCase()
    updateCalculationSettings({
      currency: nextCurrency as any
    })
  }

  const handleGlobalFilterSelect = (url: string) => {
    const filter = globalFilters.data.results.find((item) => item.url === url)
    if (url) {
      // don't update global filter if we select 'Manage Global Filters'
      return updateGlobalFilter(filter)
    }
  }

  const sortedPrimedDatesObject = (() => {
    if (!primedDates) {
      return {}
    }

    const dates = {}

    for (const primedDate of primedDates) {
      dates[primedDate] = true
    }

    return dates
  })()

  const globalFiltersRef = useRef<HTMLButtonElement>(null)
  const currencyRef = useRef<HTMLButtonElement>(null)

  return (
    calculationSettings && (
      <>
        <ButtonGroup contained roundedCorners>
          {/* -------------------- GLOBAL FILTER BUTTON + GLOBAL FILTER MENU -------------------- */}
          {!props.hideGlobalFilters && globalFilter?.data && (
            <Button
              grayBackground
              className={css.button}
              ref={globalFiltersRef}
              onClick={openGlobalFiltersMenu}
            >
              <CalculationOverrideStat
                label='Global Filters'
                data={globalFilter.data.name || null}
              />
            </Button>
          )}

          {/* -------------------- Page Level Period Select -------------------- */}
          {calculationOptions && <PageLevelPeriodSelect />}
          {/* -------------------- As of Date -------------------- */}
          {!props.hideDatePicker && (
            <Button
              grayBackground
              className={css.button}
              onClick={openDatePicker}
              disabled={props.disableDate}
            >
              <CalculationOverrideStat
                label='As Of'
                data={calculationSettings.date.date}
              />
            </Button>
          )}

          {/* -------------------- CURRENCY BUTTON + CURRENCY MENU -------------------- */}
          {/* https://stackoverflow.com/questions/57962146/button-components-inside-buttongroup
              The way that ButtonGroup works is by cloning the child Button
              elements and adding props to control the styling.
          */}
          {!props.hideCurrency && (
            <Button
              grayBackground
              ref={currencyRef}
              className={css.button}
              disabled={props.disableCurrency}
              onClick={openCurrencyMenu}
            >
              <CalculationOverrideStat
                label='Currency'
                data={calculationSettings.currency}
              />
            </Button>
          )}

          {/* -------------------- BLUE THREE DOT MENU -------------------- */}
          {mergedThreeDotsMenuOptions.length && (
            <IconMenu
              threeDotMenuCalculation
              MenuProps={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                }
              }}
              IconButtonProps={{
                style: {
                  color: 'white'
                }
              }}
              options={mergedThreeDotsMenuOptions}
            />
          )}
        </ButtonGroup>
        <Menu
          keepMounted
          open={globalFiltersMenuOpen}
          onClose={closeGlobalFiltersMenu}
          anchorEl={globalFiltersRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
          transformOrigin={{horizontal: 'left', vertical: 'top'}}
        >
          {firmConfiguration.data?.showDefaultGlobalFilter && (
            <MenuItem
              onClick={() => {
                closeGlobalFiltersMenu()
                handleGlobalFilterSelect(DEFAULT_ALL_CLIENT_OPTION.url)
              }}
            >
              {DEFAULT_ALL_CLIENT_OPTION.name}
            </MenuItem>
          )}

          {globalFilters.data?.results.map((filter) => (
            <MenuItem
              key={filter.url}
              onClick={() => {
                closeGlobalFiltersMenu()
                handleGlobalFilterSelect(filter.url)
              }}
            >
              {filter.name}
            </MenuItem>
          ))}
          <MenuItemLink
            to={GlobalFilterLocations.path()}
            className={css.manageFilters}
          >
            <ListItemText>Manage Global Filters</ListItemText>
          </MenuItemLink>
        </Menu>
        <Menu
          open={currencyMenuOpen}
          onClose={closeCurrencyMenu}
          anchorEl={currencyRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
          transformOrigin={{horizontal: 'left', vertical: 'top'}}
        >
          {firmConfiguration.data?.firm?.reportingCurrencies.map(
            ({currency}) => (
              <MenuItem
                key={currency.url}
                onClick={() => {
                  closeCurrencyMenu()
                  handleUpdateCurrency(currency.name)
                }}
              >
                {currency.name}
              </MenuItem>
            )
          )}
        </Menu>

        {/* -------------------- AS OF DATE'S DATE PICKER -------------------- */}
        <div className={css.datePicker}>
          <DatePicker
            hideError
            showTodayButton={props.asOfUseAllDates}
            open={datePickerOpen}
            disabled={props.disableDate}
            value={calculationSettings.date.date}
            minDate={props.asOfUseAllDates ? undefined : oldestPrimedDate}
            maxDate={props.asOfUseAllDates ? undefined : latestPrimedDate}
            shouldDisableDate={(day: Date) =>
              !props.asOfUseAllDates &&
              !sortedPrimedDatesObject[dateFormatter.format(day)]
            }
            inputProps={{
              'data-testid': 'select-date'
            }}
            onClose={closeDatePicker}
            onChange={handleUpdateDate}
          />
        </div>
      </>
    )
  )
}
