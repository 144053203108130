export const WEIGHTS_SPECIFICATION_OPTIONS: IValueLabelOption<boolean>[] = [
  {
    value: true,
    label: '% of Total'
  },
  {
    value: false,
    label: '% of Account'
  }
]

export enum CHART_OPTIONS {
  TARGET_WEIGHTS,
  CURRENT_DRIFT,
  TARGET_DRIFT
}

export enum ACTION {
  CLEAR = 'CLEAR',
  PREVIEW = 'PREVIEW'
}

export const REBALANCE_VIEWS_SAVE_ID = 'REBALANCE'

export const REBALANCE_ACCOUNT_ID_PREFIX = 'rebalance-account'

export const REBALANCE_TABLE_ID = 'rebalance-overview'
