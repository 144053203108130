import produce from 'immer'

import {IMetricLeaf, IMetricOption} from '@d1g1t/api/endpoints'
import {METRICGROUP_RELATED_MODEL, PERIOD} from '@d1g1t/api/models'

import {
  DEFAULT_METRIC_TYPES,
  PAGE_PERIOD_METRIC_SUFFIX,
  PERIOD_SHORTFORMS
} from '../../../constants'
import {ICategory, IMetrics} from './typings'

interface IGetAvailableMetricsOptions {
  availableMetrics: IMetricOption[]
  includeRelatedModels?: METRICGROUP_RELATED_MODEL[]
  includeCategoryKeys?: string[]
  excludeCategoryKeys?: string[]
  useCustodianAccounts?: boolean
  addPagePeriod?: boolean
  customMetrics?: ICategory[]
  metricsFilter?(metric: IMetricLeaf): boolean
}

/**
 * Trim the metrics tree based on the supplied options.
 *
 * @param metricsTree - A list of IMetricOption (i.e. MetricGroup / branches in the metric tree) items.
 * @param relatedModels - A whitelist of METRICGROUP_RELATED_MODEL values
 * @param categoryKeys -  A whitelist of values that filters against IMetricOption.key values. This value is not passed down the callstack during recursion.
 * @param metricsFilter - A filter function applied to IMetricLeaf items.
 */
export const trimMetricsTree = (
  metricsTree: IMetricOption[],
  relatedModels?: METRICGROUP_RELATED_MODEL[],
  categoryKeys?: string[],
  metricsFilter?: IGetAvailableMetricsOptions['metricsFilter']
): IMetricOption[] => {
  return (
    metricsTree
      // Note: we apply this filter first instead of last so that we don't recurse
      // down branches that will ultimately be removed at the end.
      .filter((metricTreeBranch: IMetricOption) => {
        return (
          (!categoryKeys && !relatedModels) ||
          categoryKeys?.includes(metricTreeBranch.key) ||
          relatedModels?.includes(metricTreeBranch.relatedModel)
        )
      })
      // Apply the metricsFilter to metrics (leafs) and recurse to filter children (sub-branches)
      .map((metricTreeBranch: IMetricOption) => {
        const newBranch = {...metricTreeBranch}
        if (newBranch.metrics) {
          newBranch.metrics = metricsFilter
            ? metricTreeBranch.metrics?.filter(metricsFilter)
            : metricTreeBranch.metrics
        }

        if (newBranch.children) {
          newBranch.children = trimMetricsTree(
            metricTreeBranch.children,
            relatedModels,
            // NOTE: We don't pass the categoryKeys filtering when we
            //       recurse because it does exact string matching which is only
            //       meant to be applied to the direct children of the root
            //       element (of the metricsTree), so we only apply it in the
            //       first call.
            undefined,
            metricsFilter
          )
        }

        return newBranch
      })
      // Remove any branches that don't have children (branches) or metrics (leafs)
      .filter(
        (metricTreeBranch: IMetricOption) =>
          metricTreeBranch.children?.length > 0 ||
          metricTreeBranch.metrics?.length > 0
      )
  )
}

export function getAvailableMetricsCategory({
  includeRelatedModels,
  useCustodianAccounts,
  includeCategoryKeys,
  excludeCategoryKeys,
  availableMetrics,
  customMetrics,
  addPagePeriod,
  metricsFilter
}: IGetAvailableMetricsOptions): ICategory {
  let relatedModels =
    includeRelatedModels || (includeCategoryKeys ? null : DEFAULT_METRIC_TYPES)

  if (!useCustodianAccounts && relatedModels) {
    // hide all custodian account metrics if not visible
    relatedModels = relatedModels.filter(
      (metricType) => metricType !== METRICGROUP_RELATED_MODEL.CUSTODIAN_ACCOUNT
    )
  }

  // NOTE: There has to be a better way to deal with this than always manually
  //       indexing the first element. Unfortunately the root element can't be treated
  //       the same as the other elements. For example, the root element (that the api
  //       gives us) has `relatedModel: METRICGROUP_RELATED_MODEL.QLIB`. Applying any
  //       relatedModels filtering that didn't include that group would remove the
  //       root element from the tree, which is less than ideal.
  const trimmedTree = {
    ...availableMetrics[0],
    children: trimMetricsTree(
      availableMetrics[0].children,
      relatedModels,
      includeCategoryKeys,
      metricsFilter
    )
  }

  const category = convertMetricResponseToCategory(trimmedTree)

  if (customMetrics) {
    category.children.push(...customMetrics)
  }

  if (excludeCategoryKeys) {
    category.children = category.children.filter(
      (metric) => !excludeCategoryKeys.includes(metric.id)
    )
  }
  if (addPagePeriod) {
    return addPagePeriodAsChild(category)
  }
  return category
}

const convertMetric = (
  metric: ICategory,
  /** When converting last month metrics to page period should keep metric name
   * to prevent all options being named to page period (e.g. True, False, Annualized, Cumulative)
   */
  preventNameChange?: boolean
): ICategory => {
  return {
    id: metric.id.replace(PERIOD.LASTMONTH, PAGE_PERIOD_METRIC_SUFFIX),
    metric: metric.metric.replace(PERIOD.LASTMONTH, PAGE_PERIOD_METRIC_SUFFIX),
    columnTitle: metric.columnTitle.replace('1M', 'Page Period'),
    name: preventNameChange ? metric.name : 'Page Period'
  } as ICategory
}

const createPagePeriodBranch = (category: ICategory): ICategory => {
  return produce(category, (draft) => {
    if (draft.id) {
      draft.id = draft.id
        .replace(
          PERIOD_SHORTFORMS[PERIOD.LASTMONTH],
          PERIOD_SHORTFORMS[PAGE_PERIOD_METRIC_SUFFIX]
        )
        .replace(PERIOD.LASTMONTH, PAGE_PERIOD_METRIC_SUFFIX)
    }
    if (draft.metrics) {
      // Here is where you will convert multiple metric options names to
      // page period if you do not pass the second argument to prevent
      // `convertMetric` from changing all metric names to 'Page Period'
      // Metrics should not be converted when there are children.
      draft.metrics = draft.metrics.map((metric) =>
        convertMetric(metric, !!draft.metrics.length)
      )
    }
    if (draft.children) {
      draft.children = draft.children.map(createPagePeriodBranch)
    }
  })
}

/**
 * Traverse tree, add "Page Period" to date-range periods.
 */
const addPagePeriodAsChild = (category: ICategory): ICategory => {
  return produce(category, (draft) => {
    if (draft.metrics) {
      const lastMonthMetric = draft.metrics.find((metric) =>
        metric.id.includes(PERIOD.LASTMONTH)
      )
      if (lastMonthMetric) {
        draft.metrics = [...draft.metrics, convertMetric(lastMonthMetric)]
      }
    }

    if (draft.children) {
      if (
        ['Period', 'As Of'].includes(draft.name) &&
        draft.ignorePagePeriod !== true
      ) {
        const lastMonthChild = draft.children.find((child) =>
          child.id.includes(PERIOD_SHORTFORMS[PERIOD.LASTMONTH])
        )
        if (lastMonthChild) {
          const pagePeriodChild = {
            ...lastMonthChild,
            name: 'Page Period'
          }
          draft.children = [
            ...draft.children,
            createPagePeriodBranch(pagePeriodChild)
          ]
        }
      } else {
        draft.children = draft.children.map(addPagePeriodAsChild)
      }
    }
  })
}

export const isMetricsReady = (metrics: IMetrics): boolean =>
  !(metrics.selected === null || metrics.available === null)

export const convertMetricResponseToCategory = (
  responseMetric: IMetricOption
): ICategory => {
  const category: ICategory = {
    id: responseMetric.key,
    name: responseMetric.name,
    default: responseMetric.default,
    ignorePagePeriod: responseMetric.ignorePagePeriod
  }

  if (responseMetric.displayPortfolios) {
    category.displayPortfolios = true
  }

  if (responseMetric.displayTransactionAsOfDate) {
    category.displayTransactionAsOfDate = true
  }

  if (responseMetric.children) {
    category.children = responseMetric.children.map(
      convertMetricResponseToCategory
    )
  }

  if (responseMetric.metrics) {
    category.metrics = responseMetric.metrics.map(
      convertMetricLeafResponseToCategory
    )
    category.expandableColumn = !!responseMetric.expandableColumn
  }

  return category
}

export const convertMetricLeafResponseToCategory = (
  responseMetric: IMetricLeaf
): ICategory => {
  return {
    id: responseMetric.slug,
    name: responseMetric.name,
    default: responseMetric.default,
    metric: responseMetric.slug,
    columnTitle: responseMetric.columnTitle
  }
}
