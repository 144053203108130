import {atom} from 'recoil'

import {IChartTableCategory} from '@d1g1t/api/models'

/**
 * Holds the chart paginator response categories of different tables defined by unique id.
 */
export const chartCategoriesAtom = atom<{
  [id: string]: IChartTableCategory[]
}>({
  key: 'chartPaginatorCategoriesAtom',
  default: EMPTY_OBJECT
})
