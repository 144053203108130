import {
  ITradeOrder,
  TRADEORDER_COMMISSION_TYPE,
  TRADEORDER_DIVIDEND_TREATMENT,
  TRADEORDER_OPERATION,
  TRADEORDER_PRO_STATUS,
  TRADEORDER_QTY_TYPE,
  TRADEORDER_SECURITY_TYPE,
  TRADEORDER_TYPE
} from '@d1g1t/api/models'

import {qtyChangeIsNumber, qtyChangeOperationSign} from '../lib'
import {
  IMutualFundFormValues,
  MUTUAL_FUND_FIELD_NAMES,
  QTY_TYPE
} from './typings'

export function generateMutualFundTradeOrderPayload({
  instrumentUrl,
  accountUrl,
  ...values
}: IMutualFundFormValues): ITradeOrder {
  const isDscFree = values.qtyType
    ? values.qtyType === QTY_TYPE.DSC_FREE
    : undefined

  const qty = ((): number => {
    if (!qtyChangeIsNumber(values.qty) || values.isSellAll || isDscFree) {
      return undefined
    }

    return qtyChangeOperationSign(values.qty as number, values.operation)
  })()

  const quantityType = isDscFree
    ? TRADEORDER_QTY_TYPE.UNITS
    : (values.qtyType as TRADEORDER_QTY_TYPE)

  const payload: ITradeOrder = {
    ...values,
    instrument: instrumentUrl,
    securityType: TRADEORDER_SECURITY_TYPE.FUND,
    account: accountUrl,
    qty,
    qtyType: quantityType,
    isDscFree
  }

  payload.incomingInstrument = values.fundCodeUrl

  payload.operation = ((): TRADEORDER_OPERATION => {
    switch (values.operation) {
      case TRADEORDER_OPERATION.SELL_ALL:
        return TRADEORDER_OPERATION.SELL
      case TRADEORDER_OPERATION.SWITCH_ALL:
        return TRADEORDER_OPERATION.SWITCH
      default:
        return values.operation
    }
  })()

  if (values.operation) {
    payload.isSellAll = !!(
      accountUrl &&
      [TRADEORDER_OPERATION.SELL_ALL, TRADEORDER_OPERATION.SWITCH_ALL].includes(
        values.operation
      )
    )
  }

  return payload
}

export function getInitialCreateMutualFundFormInitialValues(
  initialInstrumentUrl: string
): IMutualFundFormValues {
  return {
    [MUTUAL_FUND_FIELD_NAMES.instrumentUrl]: initialInstrumentUrl || null,
    [MUTUAL_FUND_FIELD_NAMES.instrumentName]: null,
    [MUTUAL_FUND_FIELD_NAMES.accountUrl]: null,
    [MUTUAL_FUND_FIELD_NAMES.qty]: null,
    [MUTUAL_FUND_FIELD_NAMES.operation]: TRADEORDER_OPERATION.BUY,
    [MUTUAL_FUND_FIELD_NAMES.dividendTreatment]:
      TRADEORDER_DIVIDEND_TREATMENT.REINVEST,
    [MUTUAL_FUND_FIELD_NAMES.qtyType]: TRADEORDER_QTY_TYPE.AMOUNT,
    [MUTUAL_FUND_FIELD_NAMES.cusip]: '',
    [MUTUAL_FUND_FIELD_NAMES.notes]: '',
    [MUTUAL_FUND_FIELD_NAMES.instructions]: '',
    [MUTUAL_FUND_FIELD_NAMES.fundCodeUrl]: null,
    [MUTUAL_FUND_FIELD_NAMES.fundCode]: '',
    [MUTUAL_FUND_FIELD_NAMES.type]: TRADEORDER_TYPE.MARKET,
    [MUTUAL_FUND_FIELD_NAMES.isSellAll]: false,
    [MUTUAL_FUND_FIELD_NAMES.commissionType]: TRADEORDER_COMMISSION_TYPE.AMOUNT,
    [MUTUAL_FUND_FIELD_NAMES.commission]: null,
    [MUTUAL_FUND_FIELD_NAMES.expectedPrice]: null,
    [MUTUAL_FUND_FIELD_NAMES.isSolicited]: true,
    [MUTUAL_FUND_FIELD_NAMES.proStatus]: TRADEORDER_PRO_STATUS.NONPRO
  }
}

export function getInitialEditMutualFundFormInitialValues(
  tradeOrder: ITradeOrder,
  accountUrl: string
): IMutualFundFormValues {
  const operation = ((): TRADEORDER_OPERATION => {
    if (tradeOrder.isSellAll) {
      if (tradeOrder.operation === TRADEORDER_OPERATION.SELL) {
        return TRADEORDER_OPERATION.SELL_ALL
      }

      if (tradeOrder.operation === TRADEORDER_OPERATION.SWITCH) {
        return TRADEORDER_OPERATION.SWITCH_ALL
      }
    }

    return tradeOrder.operation
  })()
  return {
    [MUTUAL_FUND_FIELD_NAMES.instrumentUrl]: tradeOrder.instrument,
    [MUTUAL_FUND_FIELD_NAMES.instrumentName]: null,
    [MUTUAL_FUND_FIELD_NAMES.accountUrl]: accountUrl,
    [MUTUAL_FUND_FIELD_NAMES.qty]: tradeOrder.qty ?? null,
    [MUTUAL_FUND_FIELD_NAMES.operation]: operation,
    [MUTUAL_FUND_FIELD_NAMES.dividendTreatment]: tradeOrder.dividendTreatment,
    [MUTUAL_FUND_FIELD_NAMES.qtyType]: tradeOrder.qtyType,
    [MUTUAL_FUND_FIELD_NAMES.cusip]: tradeOrder.cusip || '',
    [MUTUAL_FUND_FIELD_NAMES.notes]: tradeOrder.notes || '',
    [MUTUAL_FUND_FIELD_NAMES.instructions]: tradeOrder.instructions || '',
    [MUTUAL_FUND_FIELD_NAMES.fundCodeUrl]:
      tradeOrder.incomingInstrument || null,
    [MUTUAL_FUND_FIELD_NAMES.fundCode]: tradeOrder.fundCode || '',
    [MUTUAL_FUND_FIELD_NAMES.type]: tradeOrder.type,
    [MUTUAL_FUND_FIELD_NAMES.isSellAll]: tradeOrder.isSellAll ?? false,
    [MUTUAL_FUND_FIELD_NAMES.commissionType]: tradeOrder.commissionType ?? null,
    [MUTUAL_FUND_FIELD_NAMES.commission]: tradeOrder.commission,
    [MUTUAL_FUND_FIELD_NAMES.expectedPrice]: tradeOrder.expectedPrice,
    [MUTUAL_FUND_FIELD_NAMES.proStatus]:
      tradeOrder.proStatus ?? TRADEORDER_PRO_STATUS.NONPRO,
    [MUTUAL_FUND_FIELD_NAMES.isSolicited]: tradeOrder.isSolicited ?? false
  }
}
