import React, {useRef} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'

import AppsIcon from '@material-ui/icons/Apps'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import ExposureIcon from '@material-ui/icons/Exposure'
import ListAltIcon from '@material-ui/icons/ListAlt'
import SocialPeopleIcon from '@material-ui/icons/People'
import TradeIcon from '@material-ui/icons/SwapVerticalCircle'
import RebalanceIcon from '@material-ui/icons/Tune'

import {UI_PERMISSIONS} from '@d1g1t/api/models'

import {useToggleState} from '@d1g1t/lib/hooks'

import {ComboKeyListener} from '@d1g1t/shared/components/combo-key-listener'
import {Flex} from '@d1g1t/shared/components/flex'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {ListItemIcon} from '@d1g1t/shared/components/mui/list-item-icon'
import {ListItemText} from '@d1g1t/shared/components/mui/list-item-text'
import {Menu} from '@d1g1t/shared/components/mui/menu'
import {MenuItem} from '@d1g1t/shared/components/mui/menu-item'
import {H1, Text} from '@d1g1t/shared/components/typography'
import {SELECT_ENTITIES_QUERY_PARAMS_VALIDATION_SCHEMA} from '@d1g1t/shared/containers/select-entities'
import {usePermissions} from '@d1g1t/shared/wrappers/permissions'
import {useUrlQueryParams} from '@d1g1t/shared/wrappers/url-query-params'

import {
  ExploreLocations,
  SingleClientLocations,
  TradeLocations
} from '@d1g1t/advisor/locations'
import {
  NavigationSection,
  NavigationSectionToLocationPathMap
} from '@d1g1t/advisor/wrappers/navigation-section/constants'

import {IPageTitleBarProps} from '../..'
import {SECTION_HEADINGS} from './constants'

import * as css from './style.scss'

export interface IMenuOptions {
  [sectionHeading: string]: {
    to: string
    selected: boolean
    icon: JSX.Element
    section: NavigationSection
    keyboardShortcutLetter: string
    permissionCode: UI_PERMISSIONS
  }[]
}

export type IPageNavigationProps = Pick<IPageTitleBarProps, 'title'>

const LEADER_KEY_PAGE_NAVIGATION = 'p'

export const PageNavigation: React.FC<IPageNavigationProps> = (props) => {
  const pageNavMenuRef = useRef(null)

  const history = useHistory()
  const location = useLocation()
  const permissions = usePermissions()

  const [menuOpen, , setMenuOpen, setMenuClosed] = useToggleState(false)

  const [selectEntitiesQueryParams] = useUrlQueryParams({
    schema: SELECT_ENTITIES_QUERY_PARAMS_VALIDATION_SCHEMA
  })

  const {entityId: firstEntityId, accounts: firstEntityAccounts} =
    (selectEntitiesQueryParams?.selected &&
      selectEntitiesQueryParams?.selected[0]) ??
    {}

  const menuOptions: IMenuOptions = {
    [SECTION_HEADINGS.REVIEW]: [
      {
        to: SingleClientLocations.default(selectEntitiesQueryParams),
        selected:
          location.pathname ===
          NavigationSectionToLocationPathMap[NavigationSection.CLIENT_MONITOR],
        icon: <SocialPeopleIcon key='CLIENT_MONITOR' />,
        section: NavigationSection.CLIENT_MONITOR,
        keyboardShortcutLetter: 'm',
        permissionCode: UI_PERMISSIONS.SINGLE_CLIENT_MONITOR
      },
      {
        to: ExploreLocations.compare(selectEntitiesQueryParams),
        selected:
          location.pathname ===
          NavigationSectionToLocationPathMap[
            NavigationSection.COMPARE_PORTFOLIOS
          ],
        icon: <BusinessCenterIcon key='COMPARE_PORTFOLIOS' />,
        section: NavigationSection.COMPARE_PORTFOLIOS,
        keyboardShortcutLetter: 'c',
        permissionCode: UI_PERMISSIONS.EXPLORE_COMPARE_PORTFOLIOS
      }
    ],
    [SECTION_HEADINGS.TRADE]: [
      {
        to: TradeLocations.bulkChangeAllocation(selectEntitiesQueryParams),
        selected:
          location.pathname ===
          NavigationSectionToLocationPathMap[NavigationSection.TRADE_DIRECTIVE],
        icon: <ExposureIcon key='TRADE_DIRECTIVE' />,
        section: NavigationSection.TRADE_DIRECTIVE,
        keyboardShortcutLetter: 't',
        permissionCode: UI_PERMISSIONS.TRADE_TRADE_DIRECTIVE
      },
      {
        to: TradeLocations.rebalance(selectEntitiesQueryParams),
        selected:
          location.pathname ===
          NavigationSectionToLocationPathMap[NavigationSection.REBALANCE],
        icon: <RebalanceIcon key='REBALANCE' />,
        section: NavigationSection.REBALANCE,
        keyboardShortcutLetter: 'r',
        permissionCode: UI_PERMISSIONS.TRADE_REBALANCE
      },
      {
        to: TradeLocations.rebalancePortfolios(selectEntitiesQueryParams),
        selected:
          location.pathname ===
          NavigationSectionToLocationPathMap[
            NavigationSection.REBALANCE_PORTFOLIOS
          ],
        icon: <ListAltIcon key='REBALANCE_STRATEGIES' />,
        section: NavigationSection.REBALANCE_PORTFOLIOS,
        keyboardShortcutLetter: 's',
        permissionCode: UI_PERMISSIONS.TRADE_TRACK_STRATEGIES
      },
      {
        // Only retain selection for this page if 1 entity is selected and 1 account is filtered
        to:
          selectEntitiesQueryParams?.selected?.length === 1 &&
          firstEntityAccounts?.length === 1
            ? TradeLocations.equitiesEtfs(firstEntityId, firstEntityAccounts[0])
            : TradeLocations.equitiesEtfs(),
        selected:
          location.pathname ===
          NavigationSectionToLocationPathMap[NavigationSection.BUY_SELL],
        icon: <TradeIcon style={{transform: 'rotate(90deg)'}} key='BUY_SELL' />,
        section: NavigationSection.BUY_SELL,
        keyboardShortcutLetter: 'b',
        permissionCode: UI_PERMISSIONS.TRADE_BUY_SELL
      }
    ]
  }

  const renderMenuItems = (() => {
    return Object.entries(menuOptions).map(
      ([sectionName, sectionMenuOptions], index) => {
        // Filter down to the list of items that the user is allowed to access
        const filteredMenuOptions = sectionMenuOptions.filter(
          (menuItem) =>
            !menuItem.permissionCode ||
            permissions.canAccess(menuItem.permissionCode)
        )
        if (!filteredMenuOptions.length) {
          return undefined
        }
        return (
          <div key={index}>
            <Text bold className={css.sectionHeading}>
              {sectionName}
            </Text>
            {sectionMenuOptions.map((option) => (
              <Link
                key={option.keyboardShortcutLetter}
                to={option.to}
                title={`${LEADER_KEY_PAGE_NAVIGATION} ${option.keyboardShortcutLetter}`}
              >
                <MenuItem selected={option.selected}>
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <ListItemText>{option.section}</ListItemText>
                </MenuItem>
              </Link>
            ))}
          </div>
        )
      }
    )
  })()

  return (
    <>
      <div
        onClick={setMenuOpen}
        ref={pageNavMenuRef}
        data-testid='select-title-menu'
      >
        <Flex alignCenter row pointer className={css.alignLeft}>
          <IconButton>
            <AppsIcon style={{fontSize: 32}} />
          </IconButton>
          <H1>{props.title}</H1>
        </Flex>
      </div>
      <Menu
        open={menuOpen}
        onClose={setMenuClosed}
        anchorEl={pageNavMenuRef.current}
        transformOrigin={{
          horizontal: 'left',
          vertical: -90
        }}
      >
        {renderMenuItems}
      </Menu>

      {Object.values(menuOptions)
        .flat()
        .filter(
          (menuItem) =>
            !menuItem.permissionCode ||
            permissions.canAccess(menuItem.permissionCode)
        )
        .map((option, index) => (
          <ComboKeyListener
            key={index}
            leaderKey={LEADER_KEY_PAGE_NAVIGATION}
            actionKey={option.keyboardShortcutLetter}
            onAction={() => {
              history.push(option.to)
            }}
          />
        ))}
    </>
  )
}
