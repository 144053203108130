import {datadogRum} from '@datadog/browser-rum'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'

import {config} from '@d1g1t/config/config'

import {COLLAPSE_URL, collapseUrl} from '@d1g1t/lib/data-dog-helpers'
import {history} from '@d1g1t/lib/history'

const EMPTY_OBJECT = {}
if (__DEVELOPMENT__) {
  // Shows up as 'EMPTY_OBJECT' instead of {} in the console
  Object.defineProperty(EMPTY_OBJECT, Symbol.toStringTag, {
    value: 'EMPTY_OBJECT'
  })
}
Object.freeze(EMPTY_OBJECT)

const SortOrder = {
  ASC: 'ASC',
  DESC: 'DESC'
}
Object.freeze(SortOrder)

// Define properties so that they cannot be modified by any scripts
// Note that changes to global properties should also be reflected in the test
// environment (setup-jest-env.ts)
Object.defineProperties(window, {
  NOOP: {
    value: () => {},
    writable: false,
    configurable: false
  },
  EMPTY_OBJECT: {
    value: EMPTY_OBJECT,
    writable: false,
    configurable: false
  },
  SORT_ORDER: {
    value: SortOrder,
    writable: false,
    configurable: false
  }
})

// Strip trailing slash from URL on App load
if (history.location.pathname[history.location.pathname.length - 1] === '/') {
  history.replace(
    history.location.pathname.slice(0, history.location.pathname.length - 1)
  )
}

function updateDataDogScreenDimensions() {
  datadogRum.addRumGlobalContext('screenWidth', window.screen.width)
  datadogRum.addRumGlobalContext('screenHeight', window.screen.height)
  datadogRum.addRumGlobalContext('windowWidth', window.outerWidth)
  datadogRum.addRumGlobalContext('windowHeight', window.outerHeight)
}

if (__PRODUCTION__) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    release: process.env.SENTRY_RELEASE_NAME,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],
    tracesSampleRate: 0.2
  })

  Sentry.configureScope((scope) => {
    scope.setTag('revision', config.revision)
    scope.setTag('api', config.api)
    scope.setTag('subdomain', window.location.host.split('.')[0])
  })

  datadogRum.init({
    applicationId: process.env.DATADOG_APPLICATION_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: process.env.DATADOG_SITE,
    service: process.env.DATADOG_SERVICE,
    env: process.env.DATADOG_ENV,
    version: process.env.DATADOG_VERSION ?? config.revision,
    proxyUrl: process.env.DATADOG_PROXY,
    sampleRate: process.env.DATADOG_SAMPLE_RATE
      ? parseInt(process.env.DATADOG_SAMPLE_RATE, 10)
      : 100,
    sessionReplaySampleRate: 0,
    trackInteractions: true,
    actionNameAttribute: 'data-dd-action',
    beforeSend: (event) => {
      // Skip root route event
      if (event.view.url === '/#/') {
        return false
      }
      // Removes hash from urls. E.g. `/#/documents/manage-documents` -> `/documents/manage-documents`
      event.view.url = event.view.url.replace('/#', '')

      // Collapse url that matches any path in COLLAPSE_URL
      event.view.url = COLLAPSE_URL.reduce(
        (prev, current) => collapseUrl(current, prev),
        event.view.url
      )
    }
  })

  updateDataDogScreenDimensions()

  window.addEventListener('resize', updateDataDogScreenDimensions)
}
