import React, {memo} from 'react'

import CollapseIcon from '@material-ui/icons/IndeterminateCheckBox'
import EyeIcon from '@material-ui/icons/RemoveRedEye'

import {Flex} from '@d1g1t/shared/components/flex'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {Text} from '@d1g1t/shared/components/typography'

import {IExpandedControlCellProps} from './typings'

import * as css from './styles.scss'

export const ExpandedControlCell: React.FC<IExpandedControlCellProps> = memo(
  (props) => {
    const handleExpandClick = () => {
      props.onExpandClick(props.category)
    }
    const showLookThrough = props.category.options?.isLookThroughEnabled
    const calculatedWidth = ((props?.style?.width as number) ?? 0) - 24
    return (
      <Flex
        justifySpaceBetween
        className={css.expandedControlCell}
        style={props.style}
      >
        <div style={{width: calculatedWidth}}>
          <Tooltip
            title={
              props.category.name &&
              props.category.name.split(':')[props.category.level]
            }
          >
            <Flex fullWidth className={css.alignEnd}>
              {showLookThrough && (
                <Tooltip title='Look-through enabled'>
                  <EyeIcon fontSize='small' style={{marginTop: '2px'}} />
                </Tooltip>
              )}

              {props.startAdornment && (
                <>
                  {props.startAdornment}
                  <Spacer custom={4} vertical />
                </>
              )}
              <Text className={css.name}>
                {props.category.name &&
                  props.category.name.split(':')[props.category.level]}
              </Text>
            </Flex>
          </Tooltip>
          {props.category.subName && (
            <Flex fullWidth className={css.alignEnd}>
              {props.startAdornment && (
                <>
                  <div className={css.hiddenSpacing} />
                  <Spacer custom={4} vertical />
                </>
              )}

              <Tooltip title={props.category.description}>
                <Text normalWeight className={css.name}>
                  {props.category.subName}
                </Text>
              </Tooltip>
            </Flex>
          )}
        </div>
        {!props.category.options?.alwaysExpanded && (
          <Tooltip title='Collapse'>
            <IconButton tiny onClick={handleExpandClick}>
              <CollapseIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        )}
      </Flex>
    )
  }
)
