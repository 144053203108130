import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import {Flex} from '@d1g1t/shared/components/flex'
import {H6} from '@d1g1t/shared/components/typography'

import * as css from './style.scss'

export interface IStateBlockProps {
  text: string
  orange?: boolean
  red?: boolean
  outline?: boolean
  white?: boolean
  green?: boolean
  lightFont?: boolean
}

export const StateBlock: React.FC<IStateBlockProps> = (props) => {
  return (
    <Flex
      inlineFlex
      className={classNames(css.stateBlock, {
        [css.orange]: props.orange,
        [css.red]: props.red,
        [css.outline]: props.outline,
        [css.white]: props.white,
        [css.green]: props.green
      })}
      justifyCenter
    >
      <H6 noMargin semiBold light={props.lightFont}>
        {props.text}
      </H6>
    </Flex>
  )
}
