import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './style.scss'

export const SearchWrapper: React.SFC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={classNames(css.wrapper, className)} {...props} />
