import React from 'react'

import MuiPopper, {PopperProps} from '@material-ui/core/Popper'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './styles.scss'

export interface IPopperProps extends PopperProps {
  ref?: React.Ref<HTMLDivElement>
  insideModal?: boolean
}

export const Popper: React.FC<IPopperProps> = React.forwardRef(
  ({className, insideModal, ...props}, ref) => {
    return (
      <MuiPopper
        ref={ref}
        className={classNames(css.root, {[css.modalPopper]: insideModal})}
        {...props}
      />
    )
  }
)
