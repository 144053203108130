import React, {createContext, useContext, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {
  LocationPathToNavigationSectionMap,
  NavigationSection
} from './constants'

interface INavigationSectionContextValue {
  section?: NavigationSection
}

const NavigationSectionContext = createContext<INavigationSectionContextValue>({
  section: null
})

export const NavigationSectionProvider: React.FC =
  function NavigationSectionProvider(props) {
    const [section, setSection] = useState<NavigationSection>(null)

    const location = useLocation()
    useEffect(() => {
      // Track specific page visits
      if (
        Object.keys(LocationPathToNavigationSectionMap).includes(
          location.pathname
        ) &&
        location.search === ''
      ) {
        setSection(
          LocationPathToNavigationSectionMap[
            location.pathname
          ] as NavigationSection
        )
      }
    }, [location])

    return (
      <NavigationSectionContext.Provider value={{section}}>
        {props.children}
      </NavigationSectionContext.Provider>
    )
  }

/**
 * Keeps track of some of the sections user visited using the left primary menu.
 *
 * Assumption: assume that when the left primary menu is used query params
 * are empty. Not a 100% true assumption, but will work for now.
 */
export function useNavigationSectionContext() {
  return useContext(NavigationSectionContext)
}
