import React, {useMemo, useState} from 'react'

import {useApi, useApiQuery} from 'fairlight'
import {FormikProvider, useFormik} from 'formik'

import {AccountEndpoints, InstrumentEndpoints} from '@d1g1t/api/endpoints'
import {
  ALL_MODELS,
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  METRIC_Q_SPOT_HOLDING_VALUES_CASH_AMOUNT,
  METRIC_Q_SPOT_HOLDING_VALUES_VALUE,
  UI_PERMISSIONS
} from '@d1g1t/api/models'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {FormActions} from '@d1g1t/shared/components/form-actions'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Modal, ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Alert} from '@d1g1t/shared/components/mui/alert'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {useSelectEntitiesStateControl} from '@d1g1t/shared/containers/select-entities'
import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {
  ErrorBoundary,
  ModalContentsErrorFallback
} from '@d1g1t/shared/wrappers/error-boundary'
import {useFirmSecurirtyTypes} from '@d1g1t/shared/wrappers/use-firm-security-types/use-firm-security-types'

import {
  INavigationTabItem,
  NavigationTabs
} from '@d1g1t/advisor/components/navigation-tabs'
import {SelectEntities} from '@d1g1t/advisor/containers/select-entities'
import {
  BuySellEquities,
  getInitialTradeOrderFormValues,
  getTradeOrderValidationSchema,
  MutualFunds,
  TRADE_FORM_TYPE,
  useCreateTradeOrderMutation
} from '@d1g1t/advisor/containers/trade-order-form'

export interface ITradeModalProps {
  onClose(): void
  onSuccess?(): void
  clientId?: string
  accountId?: string
  instrumentId?: string
}

const TRADE_MODAL_METRICS = [
  METRIC_Q_SPOT_HOLDING_VALUES_VALUE,
  METRIC_Q_SPOT_HOLDING_VALUES_CASH_AMOUNT
]

export const TradeModal: React.FC<ITradeModalProps> = (props) => {
  return (
    <Modal open onClose={props.onClose} title='Trade' fullscreen>
      <ErrorBoundary
        resetId='no-reset'
        fallback={<ModalContentsErrorFallback onClose={props.onClose} />}
      >
        <TradeModalContents {...props} />
      </ErrorBoundary>
    </Modal>
  )
}

const TradeModalContents: React.FC<ITradeModalProps> = (props) => {
  const [calculationSettings] = useCalculationSettings()

  const {firmSecurityTypes} = useFirmSecurirtyTypes()

  const [initialInstrument] = useApiQuery(
    props.instrumentId && InstrumentEndpoints.findById(props.instrumentId)
  )

  const initialTradeFormType =
    initialInstrument.data &&
    (firmSecurityTypes.data?.results.find(
      ({url}) => url === initialInstrument.data.firmSecurityType
    )?.instrumentTradingType as TRADE_FORM_TYPE)

  return (
    <LoadingContainer
      loading={[initialInstrument, firmSecurityTypes].some(
        (query) => query.loading
      )}
    >
      {calculationSettings?.date &&
        firmSecurityTypes.data &&
        !initialInstrument.loading && (
          <TradeModalForm
            {...props}
            initialTradeFormType={initialTradeFormType}
          />
        )}
    </LoadingContainer>
  )
}

interface ITradeModalFormProps extends ITradeModalProps {
  initialTradeFormType?: TRADE_FORM_TYPE
}

const TradeModalForm: React.FC<ITradeModalFormProps> = (props) => {
  const api = useApi()
  const [selectEntitiesState, selectEntitiesActions] =
    useSelectEntitiesStateControl({
      selected:
        props.clientId && props.accountId
          ? [{entityId: props.clientId, accounts: [props.accountId]}]
          : []
    })

  const selectedAccountId = selectEntitiesState.selected[0]?.accounts?.[0]

  const [account] = useApiQuery(
    selectedAccountId && AccountEndpoints.findById(selectedAccountId),
    {
      fetchPolicy: 'cache-and-fetch'
    }
  )

  const [tradeFormType, setTradeFormType] = useState<TRADE_FORM_TYPE>(
    props.initialTradeFormType ||
      FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY
  )

  const tabs: INavigationTabItem[] = [
    {
      onClick: () =>
        setTradeFormType(FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY),
      label: 'Equities and ETFs',
      active: tradeFormType === FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY,
      permissionCode: UI_PERMISSIONS.TRADE_BUY_SELL
    },
    {
      onClick: () =>
        setTradeFormType(FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND),
      label: 'Mutual Funds',
      active: tradeFormType === FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND,
      permissionCode: UI_PERMISSIONS.TRADE_BUY_SELL
    }
  ]

  const [preTradeValidationMessages, setPreTradeValidationMessages] = useState<
    string[]
  >([])

  const [handleSubmit] = useCreateTradeOrderMutation({
    tradeType: tradeFormType,
    onPreTradeValidationFailure: setPreTradeValidationMessages,
    onSuccess: () => {
      props.onClose()
      if (props.onSuccess) {
        props.onSuccess()
      }
    }
  })

  /**
   * Reinitialize values when the selected trade type changes
   */
  const initialValues = useMemo(
    () =>
      getInitialTradeOrderFormValues(
        tradeFormType,
        props.instrumentId &&
          api.buildUrl(InstrumentEndpoints.pathToResource(props.instrumentId))
      ),
    [tradeFormType]
  )

  const validationSchema = useMemo(
    () => getTradeOrderValidationSchema(tradeFormType),
    [tradeFormType]
  )

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit
  })

  return (
    <FormikProvider value={formik}>
      <form
        autoComplete='off'
        onSubmit={formik.handleSubmit}
        style={{display: 'contents'}}
      >
        <ControlStateProvider loading={formik.isSubmitting}>
          <ModalContent darkerBackground>
            <SelectEntities
              selectEntitiesState={selectEntitiesState}
              selectEntitiesActions={selectEntitiesActions}
              limit={1}
              disableCalculationSettingsOverride
              SearchProps={{
                searchBy: [ALL_MODELS.ACCOUNT],
                showDetails: true,
                showDetailsFundsAvailable: true,
                placeholder: 'Search for an account',
                isTradable: true
              }}
              BasicInfoProps={{
                defaultMetrics: TRADE_MODAL_METRICS
              }}
              EntityChipProps={{
                disableRuleBasedFiltering: true,
                hideInvestmentMandatesSubmenu: true
              }}
            >
              <Spacer xs />
              <NavigationTabs tabs={tabs} />
              <Spacer md />
              {account.data && !account.data.isTradable && (
                <Alert severity='error'>
                  Account '{account.data?.name}' is not tradeable.
                </Alert>
              )}

              {tradeFormType ===
                FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY && (
                <BuySellEquities
                  preTradeValidationMessages={preTradeValidationMessages}
                  isTradable={account.data?.isTradable}
                />
              )}
              {tradeFormType ===
                FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND && (
                <MutualFunds isTradable={account.data?.isTradable} />
              )}
            </SelectEntities>
          </ModalContent>
          {account.data?.isTradable && (
            <ModalActions>
              <FormActions
                formType='create'
                showFormErrorCount
                onCancel={props.onClose}
                submitButtonText={{
                  default: 'Preview'
                }}
              />
            </ModalActions>
          )}
        </ControlStateProvider>
      </form>
    </FormikProvider>
  )
}
