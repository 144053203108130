import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'

import {datadogRum} from '@datadog/browser-rum'
import {groupBy} from 'lodash'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {grey} from '@material-ui/core/colors'
import SearchIcon from '@material-ui/icons/Search'

import {ConfigContext} from '@d1g1t/config/context'

import {ALL_MODELS} from '@d1g1t/api/models'

import {history} from '@d1g1t/lib/history'
import {useInputState, useToggleState} from '@d1g1t/lib/hooks'
import {createKeyboardModiferMask, MODIFIER_FLAGS} from '@d1g1t/lib/keyboard'
import {selectionForAccount} from '@d1g1t/lib/url'

import {Flex} from '@d1g1t/shared/components/flex'
import {TextInput} from '@d1g1t/shared/components/form/text-input'
import {Popper} from '@d1g1t/shared/components/mui/popper'
import {useGenerateUrlWithCurrentPath} from '@d1g1t/shared/containers/select-entities'

import {rankedSearchResults} from './helpers'
import {useSearchData} from './hook'
import {SearchSuggestions} from './search-suggestions'
import {ISearchProps, ISearchResult} from './typings'

import * as css from './style.scss'

export * from './search-wrapper'
export * from './search-child'
export * from './typings'
export * from './hook'
export * from './constants'

const computeStyle = (data) => {
  data.styles = {
    width: Math.max(data.offsets.reference.width, 265),
    left: data.offsets.reference.left
  }

  // Defines the bounds of the popper area Currently set to the width of search bar
  // and a max height of the area between the bottom of search bar and the bounds of
  // window, with a min height of 220px
  const boundingRect = data.instance.reference.getBoundingClientRect()
  const bottomHeight = Math.max(
    window.innerHeight - boundingRect.y - boundingRect.height - 10,
    220
  )

  data.styles.height = bottomHeight
  data.styles.top = data.offsets.reference.bottom

  return data
}

const popperModifiers = {
  preventOverflow: {
    boundariesElement: 'viewport'
  },
  arrow: {
    enabled: false
  },
  computeStyle: {
    fn: computeStyle,
    x: 'bottom',
    y: 'left'
  }
}

export const Search: React.FC<ISearchProps> = (props) => {
  const config = useContext(ConfigContext)
  const containerRef = useRef<HTMLDivElement>(null)
  const [focused, toggleFocused] = useToggleState(!!props.focusOnMount)
  const [searchText, setSearchText] = useInputState('')
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const generateUrlWithCurrentPath = useGenerateUrlWithCurrentPath()

  const [filters, setFilters] = useState({})

  const [searchData] = useSearchData(searchText, {
    searchCharacterMinimum: props.searchCharacterMinimum,
    models: props.searchBy,
    offset: props.offset,
    limit: props.limit,
    preloadedData: props.preloadedData,
    status: props.accountStatusIn,
    isTradable: props.isTradable,
    isAccountSpecificNot: props.isAccountSpecificNot,
    filters,
    isPortfolioInstrument: props.isPortfolioInstrument
  })

  const filteredSearchResults: ISearchResult[] = useMemo(() => {
    if (!searchData.data) {
      return null
    }

    let searchResults = searchData.data

    if (props.filterBy) {
      searchResults = searchResults.filter(props.filterBy)
    }

    return searchResults
  }, [searchData.data, props.filterBy])

  const groupedSearchResults = useMemo(() => {
    return groupBy(filteredSearchResults, 'modelName')
  }, [filteredSearchResults])

  const sortedSearchResults = useMemo(() => {
    return rankedSearchResults(groupedSearchResults)
  }, [groupedSearchResults])

  const selectedItem: ISearchResult = useMemo(() => {
    if (selectedIndex === -1) {
      return null
    }
    return sortedSearchResults[selectedIndex]
  }, [selectedIndex, sortedSearchResults])

  useEffect(() => {
    if (
      selectedIndex > -1 &&
      filteredSearchResults &&
      selectedIndex >= filteredSearchResults.length
    ) {
      setSelectedIndex(filteredSearchResults.length - 1)
    }
  }, [selectedIndex, filteredSearchResults, setSelectedIndex])

  const handleClickAway = (event: React.MouseEvent<Document>) => {
    if (
      typeof props.onClickAway === 'function' &&
      typeof event.persist === 'function'
    ) {
      event.persist()
    }

    // Needs to move this event to the next tick, otherwise it will unmount the child components
    // before they calls their DOM events (mostly for click)
    setTimeout(() => {
      // Fixes issue where clicking on filter dropdown triggering clickaway
      const tagName = (event.target as HTMLElement).tagName
      const isNestedMuiPopover = tagName === 'BODY'
      if (isNestedMuiPopover) {
        return
      }
      toggleFocused(false)

      if (typeof props.onClickAway === 'function') {
        props.onClickAway(event)
      }
    })
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.stopPropagation()
    if (typeof props.onFocus === 'function') {
      props.onFocus(event)
    }

    toggleFocused(true)
  }

  const handleResultSelect = (
    searchResult: ISearchResult,
    {replaceSelection = false} = {}
  ) => {
    if (typeof props.onResultSelect === 'function') {
      props.onResultSelect(searchResult)
    }

    if (clearOnResultSelect) {
      setSearchText('')
    }

    if (props.linkToResource) {
      const path = (() => {
        const selection =
          searchResult.modelName === ALL_MODELS.ACCOUNT
            ? selectionForAccount(searchResult.lookupId, searchResult.client)
            : searchResult.lookupId

        if (replaceSelection) {
          return generateUrlWithCurrentPath(selection)
        }

        return config.modelPath(searchResult.modelName, selection)
      })()

      history.push(path)
    }

    setSelectedIndex(-1)
    toggleFocused(false)

    const activeEl = document.activeElement as HTMLInputElement
    if (activeEl && activeEl.blur) {
      activeEl.blur()
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const mask = createKeyboardModiferMask(event)

    if (
      (event.key === 'Tab' || event.key === 'ArrowDown') &&
      mask === 0 &&
      filteredSearchResults.length > selectedIndex - 2
    ) {
      event.preventDefault()
      setSelectedIndex(selectedIndex + 1)
    }

    if (
      ((event.key === 'Tab' && mask === MODIFIER_FLAGS.shift) ||
        (event.key === 'ArrowUp' && mask === 0)) &&
      filteredSearchResults.length > 0 &&
      selectedIndex > -1
    ) {
      event.preventDefault()
      setSelectedIndex(selectedIndex - 1)
    }

    if (event.key === 'Enter') {
      if (typeof props.onEnterKeyDown === 'function') {
        props.onEnterKeyDown(searchText, event)
      }

      if (mask === 0 || mask === MODIFIER_FLAGS.shift) {
        const item = selectedItem || sortedSearchResults[0]
        handleResultSelect(item, {
          replaceSelection: mask === MODIFIER_FLAGS.shift
        })
      }
    }

    if (event.key === 'Escape' && mask === 0) {
      toggleFocused(false)
      const activeEl = document.activeElement as HTMLInputElement
      if (activeEl && activeEl.blur) {
        activeEl.blur()
      }

      if (typeof props.onClickAway === 'function') {
        props.onClickAway(event as unknown as React.MouseEvent<Document>)
      }
    }
  }

  const {
    hideSearchIcon,
    searchCharacterMinimum,
    inputRef,
    onClickAway,
    onFocus,
    onResultSelect,
    onEnterKeyDown,
    clearOnResultSelect,
    linkToResource,
    limit,
    offset,
    disableGrouping,
    showSearchIcon,
    searchBy,
    showDetails,
    filterBy,
    inline,
    rootProps,
    children,
    preloadedData,
    header,
    groupedHeader,
    footer,
    groupAccountsByClient,
    showDetailsFundsAvailable,
    accountStatusIn,
    isTradable,
    connectedPrefixIcon,
    globalSearchBarStyle,
    noMinWidth,
    ...other
  } = props

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Flex
        alignCenter
        grow
        ref={containerRef}
        data-testid='container-search'
        {...rootProps}
      >
        {connectedPrefixIcon && (
          <div className={css.connectedIcon}>{connectedPrefixIcon}</div>
        )}
        {showSearchIcon && !hideSearchIcon && (
          <SearchIcon htmlColor={grey[400]} />
        )}
        <TextInput
          onClick={(event) => {
            event.stopPropagation()
            datadogRum.addAction('searchBox', {
              trigger: 'click'
            })
          }}
          onChange={setSearchText}
          inputRef={inputRef}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          value={searchText}
          search={!inline && !connectedPrefixIcon}
          mergedLeftIconStyle={!!connectedPrefixIcon}
          hideBorder={inline}
          autoComplete='off'
          data-searchby={searchBy?.join(',')}
          {...other}
          hasLeftSearchIcon={globalSearchBarStyle}
          style={
            globalSearchBarStyle
              ? {
                  height: 48
                }
              : undefined
          }
        />
        {containerRef.current && filteredSearchResults !== null && (
          <Popper
            insideModal
            anchorEl={containerRef.current}
            open={focused}
            modifiers={popperModifiers}
          >
            <SearchSuggestions
              selectedItem={selectedItem}
              showDetails={props.showDetails}
              showDetailsFundsAvailable={showDetailsFundsAvailable}
              detailsTitle={props.detailsTitle}
              loading={searchData.loading}
              searchResults={
                !filteredSearchResults && searchData.loading
                  ? []
                  : filteredSearchResults
              }
              text={searchText}
              linkToResource={linkToResource}
              disableGrouping={disableGrouping}
              onItemClick={handleResultSelect}
              groupAccountsByClient={groupAccountsByClient}
              className={noMinWidth ? css.noMinWidth : null}
              header={header}
              groupedHeader={groupedHeader}
              footer={footer}
              extraFilters={props.extraFilters}
              onChangeExtraFilters={setFilters}
              optionRenderOverride={props.optionRenderOverride}
              position={props.position}
            />
          </Popper>
        )}
      </Flex>
    </ClickAwayListener>
  )
}

Search.defaultProps = {
  groupAccountsByClient: true
}
