import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {isNil} from 'lodash'

import {Tooltip} from '@material-ui/core'

import {classNames} from '@d1g1t/lib/class-names'
import {onlyTranslateStrings} from '@d1g1t/lib/only-translate-strings'

import {useControlState} from '@d1g1t/shared/components/control-state'
import {Flex} from '@d1g1t/shared/components/flex'
import {Checkbox} from '@d1g1t/shared/components/mui/checkbox'
import {Divider} from '@d1g1t/shared/components/mui/divider'
import {ListItemIcon} from '@d1g1t/shared/components/mui/list-item-icon'
import {ListItemText} from '@d1g1t/shared/components/mui/list-item-text'
import {
  IMenuItemProps,
  MenuItem,
  MenuItemLink
} from '@d1g1t/shared/components/mui/menu-item'
import {
  ISelectProps,
  Select,
  SelectMultiValue
} from '@d1g1t/shared/components/mui/select'

import {Spacer} from '../spacer'

import * as css from './style.scss'

// eslint-disable-next-line
type ValueLabelSelectOption = IValueLabelOption<any, React.ReactNode>

interface IValueLabelSelectOptionsCommon {
  testid?: string
  icon?: React.ReactElement
  /**
   * Disables the menu option.
   * E.g. some views are disabled when view look-through is enabled, but firm look-through is disabled.
   */
  disabled?: boolean

  /**
   * Controls to show to the right of the item in the <select> menu list.
   */
  menuItemControls?: React.ReactNode
}

interface IValueLabelSelectOptionsValue
  extends IValueLabelSelectOptionsCommon,
    ValueLabelSelectOption {
  link?: never
  onClick?: never
}

interface IValueLabelSelectOptionsLink
  extends IValueLabelSelectOptionsCommon,
    Omit<ValueLabelSelectOption, 'value'> {
  link: string
  value?: never
  onClick?: never
}
interface IValueLabelSelectOptionsAction
  extends IValueLabelSelectOptionsCommon,
    Omit<ValueLabelSelectOption, 'value'> {
  link?: never
  value?: never
  onClick(): void
}

export type IValueLabelSelectOption =
  | IValueLabelSelectOptionsValue
  | IValueLabelSelectOptionsLink
  | IValueLabelSelectOptionsAction

function isValueOption(
  option: IValueLabelSelectOption
): option is IValueLabelSelectOptionsValue {
  return 'value' in option
}

function isLinkOption(
  option: IValueLabelSelectOption
): option is IValueLabelSelectOptionsLink {
  return 'link' in option
}

function isActionOption(
  option: IValueLabelSelectOption
): option is IValueLabelSelectOptionsAction {
  return 'onClick' in option
}

interface IValueLabelSelectDivider {
  index: number
  label: React.ReactNode
}

export interface IValueLabelSelectProps
  extends Omit<ISelectProps, 'renderValue'> {
  options: IValueLabelSelectOption[]
  /**
   * Display a `<Divider>` above the option at provided indexes
   */
  dividerIndexes?: number[]
  /**
   * Display a `<Divider>` with label at the position provided
   */
  dividers?: IValueLabelSelectDivider[]
  /**
   * Method or string of text to render inside input field
   */
  renderValue?: string | ((value, label) => React.ReactNode)
  children?: never
  errorTextMargin?: boolean
  /**
   *  Set overflow to hidden (usefull for standard table to show an  action button)
   */
  overFlowHidden?: boolean
  /**
   * Transforms the ValueLabelSelect modal origin to be on the left of the element
   */
  originRight?: boolean
}

interface IDisabledTooltipWrapper {}
const DisabledTooltipWrapper: React.FunctionComponent<
  IDisabledTooltipWrapper
> = ({children}) => {
  return (
    <Tooltip
      title='System is not configured to show look-through views'
      PopperProps={{
        style: {pointerEvents: 'none', marginTop: '-50px'}
      }}
    >
      {/* Without this div, we loose the tooltip on disabled MenuItems. */}
      {/* Also added custom style on Tooltip above following the issue below */}
      {/* https://github.com/mui/material-ui/issues/10502 */}
      <div>{children}</div>
    </Tooltip>
  )
}

export const ValueLabelSelect: React.FC<IValueLabelSelectProps> =
  React.forwardRef(
    (
      {
        options,
        dividerIndexes,
        dividers,
        renderValue,
        displayEmpty,
        value,
        disabled,
        errorTextMargin,
        overFlowHidden,
        ...props
      },
      ref
    ) => {
      const {t} = useTranslation()

      const renderSelectValue = useCallback(
        (value: ISelectProps['value']) => {
          if (typeof renderValue === 'function') {
            const {label} =
              options.find((option: IValueLabelOption) => {
                return option.value === value
              }) ??
              (options && options[0])
            return onlyTranslateStrings(t, renderValue(value, label))
          }

          if (typeof renderValue === 'string') {
            return t(renderValue)
          }

          const option = options.find((option) => {
            if (isValueOption(option)) {
              return option.value === value
            }

            return false
          })

          if (option) {
            return onlyTranslateStrings(t, option.label)
          }

          if (props.placeholder) {
            return t(props.placeholder)
          }
        },
        [options, renderValue]
      )

      const handleChange = useCallback(
        (value, menuItem: React.ReactElement<IMenuItemProps>, ...args) => {
          if (menuItem.props.onClick) {
            ;(menuItem.props.onClick as Function)()
          }

          if (props.onChange) {
            props.onChange(value, menuItem, ...args)
          }
        },
        [props.onChange]
      )

      const dividersPositionIndexes = useMemo(() => {
        if (!dividers) {
          return null
        }

        return dividers.map(({index}) => index)
      }, [dividers])

      const renderOptions = useMemo(() => {
        const menuItems = []

        if (props.placeholder) {
          menuItems.push(
            <MenuItem
              key='placeholder'
              value=''
              disabled
              button
              title={props.placeholder}
            >
              <ListItemText>{t(props.placeholder)}</ListItemText>
            </MenuItem>
          )
        }

        for (let i = 0; i < options.length; i++) {
          const option = options[i]
          if (dividerIndexes && dividerIndexes.includes(i)) {
            menuItems.push(<Divider key={`${i}-divider`} />)
          }

          if (dividersPositionIndexes && dividersPositionIndexes.includes(i)) {
            menuItems.push(
              <div className={css.seperator} key={`${i}-seperator`}>
                {onlyTranslateStrings(
                  t,
                  dividers.find(({index}) => index === i).label
                )}
              </div>
            )
            menuItems.push(<Divider key={`${i}-divider`} />)
          }

          const dataTestId = (() => {
            if (option.testid) {
              return option.testid
            }

            if (typeof option.label === 'object') {
              for (const child of (
                option.label as React.ReactNode as React.ReactChild as React.ReactElement
              ).props?.children) {
                if (typeof child === 'string') {
                  return child
                }
              }
            }

            return option.label
          })()

          if (isValueOption(option)) {
            const key =
              typeof option.value === 'object'
                ? JSON.stringify(option.value)
                : option.value

            const menuItemEl = (
              <MenuItem
                data-testid={dataTestId}
                button
                key={key}
                value={option.value}
                selectedVariant={props.multiple ? 'none' : 'default'}
                disabled={option.disabled}
                title={
                  typeof option.label === 'string' ? option.label : undefined
                }
              >
                {props.multiple && (
                  <Checkbox
                    checked={(value as SelectMultiValue).includes(option.value)}
                  />
                )}
                {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                <ListItemText>
                  <Flex justifySpaceBetween shrink alignCenter>
                    <Flex>{onlyTranslateStrings(t, option.label)}</Flex>
                    {option.menuItemControls && (
                      <>
                        <Spacer xs vertical />
                        <Flex className={css.menuItemControls}>
                          {option.menuItemControls}
                        </Flex>
                      </>
                    )}
                  </Flex>
                </ListItemText>
              </MenuItem>
            )
            menuItems.push(
              option.disabled ? (
                <DisabledTooltipWrapper>{menuItemEl}</DisabledTooltipWrapper>
              ) : (
                menuItemEl
              )
            )
          }

          if (isLinkOption(option)) {
            menuItems.push(
              <MenuItemLink
                data-testid={dataTestId}
                key={option.link}
                to={option.link}
              >
                {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                <ListItemText>
                  {onlyTranslateStrings(t, option.label)}
                </ListItemText>
              </MenuItemLink>
            )
          }

          if (isActionOption(option)) {
            menuItems.push(
              <MenuItem
                data-testid={option.testid}
                button
                key={i}
                onClick={option.onClick}
                title={
                  typeof option.label === 'string' ? option.label : undefined
                }
              >
                {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                <ListItemText>
                  {onlyTranslateStrings(t, option.label)}
                </ListItemText>
              </MenuItem>
            )
          }
        }

        return menuItems
      }, [options, dividerIndexes, value])

      const modifiedDisplayEmpty = useMemo(() => {
        if (typeof displayEmpty === 'boolean') {
          return displayEmpty
        }

        if (typeof renderValue === 'string') {
          return true
        }
      }, [displayEmpty, renderValue])

      const controlState = useControlState()

      const selectValue = (() => {
        if (props.multiple && isNil(value)) {
          return []
        }

        if (isNil(value)) {
          return ''
        }

        return value
      })()

      return (
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: props.originRight ? 'right' : 'left'
            },
            getContentAnchorEl: null,
            style: {zIndex: 9999}
          }}
          className={classNames({
            [css.errorTextMargin]: errorTextMargin,
            [css.overFlowHidden]: overFlowHidden
          })}
          ref={ref}
          noLeftPadding={props.noLeftPadding}
          noPadding={props.noPadding}
          displayEmpty={modifiedDisplayEmpty}
          disabled={controlState.loading || disabled}
          value={selectValue}
          renderValue={renderSelectValue}
          {...props}
          onChange={handleChange}
        >
          {renderOptions}
        </Select>
      )
    }
  )
