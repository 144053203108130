import * as Yup from 'yup'

import {FIXED_INCOME_FIELD_NAMES} from './typings'

export const FIXED_INCOME_VALIDATION_SCHEMA = Yup.object({
  [FIXED_INCOME_FIELD_NAMES.instrumentUrl]: Yup.string()
    .nullable()
    .required()
    .label('Ticker'),
  [FIXED_INCOME_FIELD_NAMES.qty]: Yup.number()
    .required()
    .label('Quantity')
    .nullable(),
  [FIXED_INCOME_FIELD_NAMES.operation]: Yup.string().required(),
  [FIXED_INCOME_FIELD_NAMES.qtyType]: Yup.string(),
  [FIXED_INCOME_FIELD_NAMES.notes]: Yup.string()
})
