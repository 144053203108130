import {useMemo} from 'react'

import {useApiQuery} from 'fairlight'

import {ConstantsFilterCriterionEndpoints} from '@d1g1t/api/endpoints'
import {FILTERCRITERION_NODE_TYPE} from '@d1g1t/api/models'

import {preComputedPaths} from '@d1g1t/shared/containers/filter-editor/lib'

import {useFirmConfiguration} from '../firm-configuration'
import {HIDE_OPTIONS, IFilterCriteria} from './typings'

export function useFilterCriteria(
  types?: FILTERCRITERION_NODE_TYPE[],
  hideOptions?: HIDE_OPTIONS[],
  isGlobal?: boolean
): [Loadable<IFilterCriteria>] {
  const {firmConfiguration} = useFirmConfiguration()
  const [criteria] = useApiQuery(
    ConstantsFilterCriterionEndpoints.tree({
      isGlobal: !!isGlobal
    }),
    {
      fetchPolicy: 'cache-first'
    }
  )

  const filterCriteria = useMemo(() => {
    if (!firmConfiguration.data || !criteria.data) {
      return null
    }

    return preComputedPaths(
      criteria.data.items,
      firmConfiguration.data.useCustodianAccounts,
      types,
      hideOptions
    )
  }, [criteria.data, firmConfiguration.data, types, hideOptions])

  return [
    {
      data: filterCriteria,
      loading: firmConfiguration.loading || criteria.loading,
      error: firmConfiguration.error || criteria.error
    }
  ]
}
