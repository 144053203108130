import React from 'react'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import {Flex} from '@d1g1t/shared/components/flex'
import {Text} from '@d1g1t/shared/components/typography'

import css from './style.scss'

interface ICalculationOverrideStatProps {
  label: string
  data: number | string
}

export const CalculationOverrideStat: React.SFC<
  ICalculationOverrideStatProps
> = (props) => {
  return (
    <Flex column>
      <Flex>
        <Text className={css.label}>{props.label}</Text>
      </Flex>
      <Flex justifyFlexStart alignStart>
        <Text
          semiBold
          className={css.placeholder}
          style={{textTransform: 'none'}}
        >
          {props.data}
        </Text>
        <ArrowDropDownIcon className={css.arrowDown} />
      </Flex>
    </Flex>
  )
}
