import produce from 'immer'
import {isEqual, isPlainObject, isObject} from 'lodash'

/**
 * Returns only the diff between two objects - i.e. edited values and newly added values.
 * If 'editedObject' appears to have a non-object value, it is treated as a diff from the previous state.
 * Removed fields will be set as undefined on the result.
 * Only plain objects will be deeply compared ( @see _.isPlainObject)
 *
 * @param initialObject - Starting object
 * @param editedObject - Object with edits
 * @returns A new object with the edited and newly added values to `initialObject`
 */
export function deepDiffObject<T extends object>(
  initialObject: T,
  editedObject: T
): T {
  if (!isObject(editedObject)) {
    return editedObject
  }

  return produce(editedObject, (draft) => {
    for (const [key, value] of Object.entries(editedObject)) {
      // If property in `base` is `undefined than move
      // to next property in iteration
      if (initialObject[key] === undefined) {
        continue
      }

      // If property is equal in both objects then remove
      // from result diff object.
      if (isEqual(value, initialObject[key])) {
        delete draft[key]
        continue
      }

      // If property is an object then recursively call
      // `deepDiffObject` of that property
      if (isPlainObject(initialObject[key])) {
        draft[key] = deepDiffObject(initialObject[key], value)
      }
    }

    // Map removed fields to undefined
    for (const key of Object.keys(initialObject)) {
      if (editedObject[key] === undefined) {
        draft[key] = undefined
      }
    }
  })
}
