export enum CATEGORY_IDS {
  NAME = 'name',
  WEIGHT_CHANGE = 'weight_change',
  VALUE_CHANGE = 'value_change',
  TARGET_WEIGHT = 'target_weight',
  TARGET_VALUE = 'target_value',
  INSTRUMENT_URL = 'instrument_url',
  CURRENT_VALUE = 'current_value',
  CURRENT_WEIGHT = 'current_weight',
  TARGET_DRIFT = 'target_drift',
  CURRENT_DRIFT = 'current_drift',
  HAS_DRAFT = 'has_draft'
}
