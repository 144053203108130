import React from 'react'

import {deepOrange, green} from '@material-ui/core/colors'

import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'

import * as css from './styles.scss'

interface ICreateBooleanCellOptions {
  trueTextColor?: string
  trueBackgroundColor?: string
  trueText?: string

  falseTextColor?: string
  falseBackgroundColor?: string
  falseText?: string

  borderRadius?: number | string
}
/**
 * Used to format boolean cells
 *
 * @param options - options that helps to format cell
 * @param categoryKey - key to use to getDatum from item, if not provided will use 'key'
 */
export const createBooleanCell = (
  options: ICreateBooleanCellOptions,
  categoryKey = 'key'
): React.SFC<IDataCellProps> => {
  const trueTextColor = options.trueTextColor || '#FFF'
  const trueBackgroundColor = options.trueBackgroundColor || '#616161'
  const trueText = options.trueText || 'Yes'

  const falseTextColor = options.falseTextColor || '#616161'
  const falseBackgroundColor = options.falseBackgroundColor || 'transparent'
  const falseText = options.falseText || 'No'

  const borderRadius =
    options.borderRadius === null || options.borderRadius === undefined
      ? 4
      : options.borderRadius

  return (props) => {
    const key = props.item.getDatum(props.category.id)[categoryKey]
    return (
      <div
        className={css.booleanCell}
        style={{
          borderRadius,
          color: key ? trueTextColor : falseTextColor,
          backgroundColor: key ? trueBackgroundColor : falseBackgroundColor
        }}
      >
        {key ? trueText : falseText}
      </div>
    )
  }
}

const RED = deepOrange.A700
const GREEN = green[800]

export const IsPublicCell = createBooleanCell({
  trueText: 'Public',
  falseText: 'Private'
})
IsPublicCell.displayName = 'IsPublicCell'

/**
 * Returns green "active" for true and red "Inactive" for false
 *
 * @param categoryKey - key to use to getDatum from item, if not provided will use 'key'
 */
export const IsActiveCell = (categoryKey?: string) => {
  return createBooleanCell(
    {
      trueText: 'Active',
      trueBackgroundColor: 'transparent',
      trueTextColor: GREEN,
      falseText: 'Inactive',
      falseTextColor: RED
    },
    categoryKey
  )
}
IsActiveCell.displayName = 'IsActiveCell'
