import React, {useCallback, useEffect, useMemo} from 'react'

import {formatISO, isAfter, parseISO} from 'date-fns'
import {useApiQuery} from 'fairlight'

import {PortfolioEndpoints} from '@d1g1t/api/endpoints'

import {DateFormatter} from '@d1g1t/lib/formatters/date-formatter'
import {useToggleState} from '@d1g1t/lib/hooks'

import {CalculationOverrideStat} from '@d1g1t/shared/components/calculation-override-stat'
import {CurrencySelect} from '@d1g1t/shared/components/currency-select'
import {Flex} from '@d1g1t/shared/components/flex'
import {Button} from '@d1g1t/shared/components/mui/button'
import {DatePicker} from '@d1g1t/shared/components/mui/date-picker'
import {PageLevelPeriodSelect} from '@d1g1t/shared/components/page-level-period-select'
import {useSelectedEntities} from '@d1g1t/shared/containers/select-entities'
import {useCalculationOptionsOverride} from '@d1g1t/shared/wrappers/calculation-options'
import {
  ISettings,
  useCalculationSettings,
  useCalculationSettingsOverride
} from '@d1g1t/shared/wrappers/calculation-settings'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {useUserLocalizationPreferences} from '@d1g1t/shared/wrappers/localization-settings/hook'

import css from './style.scss'

export interface ICalculationOverrideProps {
  loadingDefaultCurrency?: boolean
  /**
   * Passes `disabled` to both select menus
   */
  disabled?: boolean
  /**
   * When passed, date and currency will be separated with a border. Passed from `Bar` component in `SelectEntities`.
   */
  separateWithBorder?: boolean
  /**
   * Indicates that `SelectEntities` is opened from Model Portfolios page.
   */
  isModelPortfoliosPage?: boolean
  /**
   * Default currency, this component checks when this values changes and
   * updated the calculation settings override accordingly
   */
  defaultCurrency: string
  /**
   * Passing this value will replace the value displayed in the menus and
   * will prevent editing values if a key is present
   */
  staticValue?: Partial<ISettings>
  /**
   * When this is true, use allow selection of today's date, ignoring firm configuration latestDataAvailable
   */
  ignoreFirmConfigLatestDate?: boolean
}

export const CalculationOverride: React.FC<ICalculationOverrideProps> = (
  props
) => {
  const {firmConfiguration} = useFirmConfiguration()

  const [calculationOptions] = useCalculationOptionsOverride()

  const [calculationSettings] = useCalculationSettings({
    ignoreFirmConfigLatestDate: props.ignoreFirmConfigLatestDate
  })
  const [, {updateCalculationSettingsOverride}] =
    useCalculationSettingsOverride()

  /**
   * TODO: remove/refactor this
   * This is an external-profile specific hook that is running in advisor app too.
   * It "works" because it has defaults, but it should be designed s.t. it doesn't
   * run at all in advisor app. OR, add the same options for advisors and refactor the
   * hook to pull either from investor, or from advisor options to get the date and lang
   * preferences.
   */
  const [dateFormat] = useUserLocalizationPreferences()

  useEffect(() => {
    if (!props.defaultCurrency) {
      return
    }

    updateCalculationSettingsOverride({currency: props.defaultCurrency})
  }, [props.defaultCurrency])

  const [datePickerOpen, , openDatePicker, closeDatePicker] =
    useToggleState(false)

  const handleSetDate = (date: string) => {
    if (
      !props.ignoreFirmConfigLatestDate &&
      isAfter(
        parseISO(date),
        parseISO(firmConfiguration.data?.latestDataAvailable)
      )
    ) {
      date = firmConfiguration.data?.latestDataAvailable
    }

    updateCalculationSettingsOverride({
      date: {
        date,
        value: 'specificDate'
      }
    })
  }

  const handleCurrencyChange = useCallback(
    (currency: string) => {
      updateCalculationSettingsOverride({
        currency
      })
    },
    [updateCalculationSettingsOverride]
  )

  const dateValue = useMemo(() => {
    if (props.staticValue && props.staticValue.date) {
      return props.staticValue.date
    }

    return calculationSettings?.date
  }, [props.staticValue?.date, calculationSettings?.date])

  const dateLabel = useMemo(() => {
    return new DateFormatter({
      dateFormat
    }).format(dateValue.date)
  }, [dateFormat, dateValue])

  const currencyValue = useMemo(() => {
    if (props.staticValue && props.staticValue.currency) {
      return props.staticValue.currency
    }

    return calculationSettings?.currency
  }, [
    props.staticValue && props.staticValue.currency,
    calculationSettings?.currency
  ])

  const selectedEntities = useSelectedEntities()

  const [nodes] = useApiQuery(
    selectedEntities?.control?.entityId &&
      // only Portfolios have node dates
      props.isModelPortfoliosPage &&
      datePickerOpen &&
      PortfolioEndpoints.nodes(selectedEntities.control.entityId)
  )

  if (!calculationSettings) {
    return null
  }

  return (
    <Flex
      stretch
      data-testid='container-calculation-override'
      className={css.containerCalculationOverride}
    >
      {/* -------------------- Page Level Date Period -------------------- */}
      {calculationOptions && (
        <PageLevelPeriodSelect
          data-testid='calculation-override-period'
          override
        />
      )}
      {/* -------------------- As of Date -------------------- */}
      <Button
        data-testid='calculation-override-date-picker'
        onClick={openDatePicker}
        disabled={
          props.disabled || !!(props.staticValue && props.staticValue.date)
        }
      >
        <CalculationOverrideStat label='As Of' data={dateLabel} />
      </Button>
      <div className={css.datePicker}>
        <DatePicker
          showTodayButton
          invalidDateMessage
          smallHeight
          mutedBorder
          pointerCursor
          fullWidth={false}
          open={datePickerOpen}
          value={dateValue.date}
          disabled={
            props.disabled || !!(props.staticValue && props.staticValue.date)
          }
          maxDate={
            props.ignoreFirmConfigLatestDate
              ? undefined
              : firmConfiguration.data?.latestDataAvailable
          }
          onChange={handleSetDate}
          onClose={closeDatePicker}
          inputProps={{
            'data-testid': 'select-date'
          }}
          renderDay={(
            date: Date,
            selectedDate: Date,
            dayInCurrentMonth: boolean,
            dayComponent: JSX.Element
          ): JSX.Element => {
            if (!nodes.data) {
              return dayComponent
            }
            // if day is a "node" date - apply higlighted style
            if (
              nodes.data.nodes.includes(
                formatISO(date, {representation: 'date'})
              )
            ) {
              return React.cloneElement(dayComponent, {
                className: css.highlightedDay
              })
            }
            return dayComponent
          }}
        />
      </div>
      {/* -------------------- Currency Selector -------------------- */}
      <CurrencySelect
        data-testid='calculation-override-currency-select'
        disabled={
          props.disabled || !!(props.staticValue && props.staticValue.currency)
        }
        onChange={handleCurrencyChange}
        currency={props.loadingDefaultCurrency ? '' : currencyValue}
      />
    </Flex>
  )
}
