import React from 'react'

import {useCalculationSettings} from './hook'
import {ICalculationSettingsProps} from './typings'

export * from './lib'
export * from './constants'
export * from './typings'
export * from './hook'
export * from './context'

export const CalculationSettingsData: React.FC<ICalculationSettingsProps> = ({
  children,
  renderWithoutData
}) => {
  const [calculationSettings, {updateCalculationSettings}] =
    useCalculationSettings()

  if (calculationSettings) {
    return children(calculationSettings, updateCalculationSettings)
  }

  if (!renderWithoutData) {
    return null
  }

  return children(null, updateCalculationSettings)
}
