import React, {Suspense} from 'react'

import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'
import {PermissionErrorBoundary} from '@d1g1t/shared/wrappers/permissions'

import {FrameErrorFallback} from './components/error-fallback'
import {PrimarySideMenu} from './components/primary-side-menu'
import {SecondaryMenu} from './components/secondary-menu'

import * as css from './style.scss'

export const FRAME_MARGIN_TOP = 70 // accounts for top toolbar
export const FRAME_MARGIN_RIGHT = 15 // bit of padding
export const FRAME_MARGIN_BOTTOM = 0
export const FRAME_MARGIN_LEFT = 90 // accounts for primary menu

const frameContentStyle = {
  paddingTop: FRAME_MARGIN_TOP,
  paddingRight: FRAME_MARGIN_RIGHT,
  paddingBottom: FRAME_MARGIN_BOTTOM,
  paddingLeft: FRAME_MARGIN_LEFT
}

export const Frame: React.FC = (props) => {
  return (
    <div className={css.mainWrapper}>
      <ErrorBoundary resetId='no-reset'>
        <PrimarySideMenu />
      </ErrorBoundary>
      <ErrorBoundary resetId='no-reset'>
        <SecondaryMenu />
      </ErrorBoundary>
      <div className={css.frame}>
        <div style={frameContentStyle}>
          <PermissionErrorBoundary>
            <ErrorBoundary fallback={<FrameErrorFallback />} resetId='no-reset'>
              <Suspense fallback={null}>{props.children}</Suspense>
            </ErrorBoundary>
          </PermissionErrorBoundary>
        </div>
      </div>
    </div>
  )
}
