import React, {createContext, useContext} from 'react'

import {IEntityChipContextValues, IEntityChipProviderProps} from './typings'

export const EntityChipContext = createContext<IEntityChipContextValues>(null)

export const EntityChipProvider: React.FC<IEntityChipProviderProps> = (
  props
) => {
  return (
    <EntityChipContext.Provider
      value={{
        ...props.value
      }}
    >
      {props.children}
    </EntityChipContext.Provider>
  )
}

export function useEntityChipContext() {
  return useContext(EntityChipContext)
}
