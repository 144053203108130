import {format} from 'date-fns'

import {
  COMMISSION_TYPE,
  ITradeOrder,
  TRADEORDER_EXPIRY_TYPE,
  TRADEORDER_OPERATION,
  TRADEORDER_PRO_STATUS,
  TRADEORDER_QTY_TYPE,
  TRADEORDER_SECURITY_TYPE,
  TRADEORDER_TYPE
} from '@d1g1t/api/models'

import {ISO_DATE_FORMAT} from '@d1g1t/lib/constants'

import {qtyChangeOperationSign} from '../lib'
import {EQUITIES_ETFS_FIELD_NAMES, IEquitiesEtfsFormValues} from './typings'

export function getInitialCreateEquitiesEtfsFormInitialValues(
  initialInstrumentUrl?: string
): IEquitiesEtfsFormValues {
  return {
    [EQUITIES_ETFS_FIELD_NAMES.instrumentUrl]: initialInstrumentUrl || null,
    [EQUITIES_ETFS_FIELD_NAMES.instrumentName]: null,
    [EQUITIES_ETFS_FIELD_NAMES.accountUrl]: initialInstrumentUrl || null,
    [EQUITIES_ETFS_FIELD_NAMES.market]: '',
    [EQUITIES_ETFS_FIELD_NAMES.qty]: null,
    [EQUITIES_ETFS_FIELD_NAMES.limitPrice]: null,
    [EQUITIES_ETFS_FIELD_NAMES.operation]: TRADEORDER_OPERATION.BUY,
    [EQUITIES_ETFS_FIELD_NAMES.qtyType]: TRADEORDER_QTY_TYPE.UNITS,
    [EQUITIES_ETFS_FIELD_NAMES.commissionType]: COMMISSION_TYPE.AMOUNT,
    [EQUITIES_ETFS_FIELD_NAMES.type]: TRADEORDER_TYPE.MARKET,
    [EQUITIES_ETFS_FIELD_NAMES.expiryType]: TRADEORDER_EXPIRY_TYPE.DAY,
    [EQUITIES_ETFS_FIELD_NAMES.expiryDate]: format(new Date(), ISO_DATE_FORMAT),
    [EQUITIES_ETFS_FIELD_NAMES.isAllOrNone]: false,
    [EQUITIES_ETFS_FIELD_NAMES.isIceberg]: false,
    [EQUITIES_ETFS_FIELD_NAMES.isAnonymous]: false,
    [EQUITIES_ETFS_FIELD_NAMES.isOddLot]: false,
    [EQUITIES_ETFS_FIELD_NAMES.isInsider]: false,
    [EQUITIES_ETFS_FIELD_NAMES.proStatus]: TRADEORDER_PRO_STATUS.NONPRO,
    [EQUITIES_ETFS_FIELD_NAMES.isSellAll]: false,
    [EQUITIES_ETFS_FIELD_NAMES.broker]: '',
    [EQUITIES_ETFS_FIELD_NAMES.commission]: null,
    [EQUITIES_ETFS_FIELD_NAMES.notes]: '',
    [EQUITIES_ETFS_FIELD_NAMES.instructions]: '',
    [EQUITIES_ETFS_FIELD_NAMES.isSolicited]: true,
    [EQUITIES_ETFS_FIELD_NAMES.expectedPrice]: null
  }
}

export function getInitialEditEquitiesEtfsFormInitialValues(
  tradeOrder: ITradeOrder,
  accountUrl: string
): IEquitiesEtfsFormValues {
  return {
    [EQUITIES_ETFS_FIELD_NAMES.instrumentUrl]: tradeOrder.instrument,
    [EQUITIES_ETFS_FIELD_NAMES.instrumentName]: null,
    [EQUITIES_ETFS_FIELD_NAMES.accountUrl]: accountUrl,
    [EQUITIES_ETFS_FIELD_NAMES.market]: tradeOrder.market ?? null,
    [EQUITIES_ETFS_FIELD_NAMES.qty]: tradeOrder.qty ?? null,
    [EQUITIES_ETFS_FIELD_NAMES.limitPrice]: tradeOrder.limitPrice ?? null,
    [EQUITIES_ETFS_FIELD_NAMES.operation]: tradeOrder.operation,
    [EQUITIES_ETFS_FIELD_NAMES.qtyType]: tradeOrder.qtyType,
    [EQUITIES_ETFS_FIELD_NAMES.commissionType]:
      tradeOrder.commissionType ?? null,
    [EQUITIES_ETFS_FIELD_NAMES.type]: tradeOrder.type ?? null,
    [EQUITIES_ETFS_FIELD_NAMES.expiryType]: tradeOrder.expiryType || null,
    [EQUITIES_ETFS_FIELD_NAMES.expiryDate]: tradeOrder.expiryDate || null,
    [EQUITIES_ETFS_FIELD_NAMES.isAllOrNone]: tradeOrder.isAllOrNone ?? false,
    [EQUITIES_ETFS_FIELD_NAMES.isIceberg]: tradeOrder.isIceberg ?? false,
    [EQUITIES_ETFS_FIELD_NAMES.isAnonymous]: tradeOrder.isAnonymous ?? false,
    [EQUITIES_ETFS_FIELD_NAMES.isOddLot]: tradeOrder.isOddLot ?? false,
    [EQUITIES_ETFS_FIELD_NAMES.isInsider]: tradeOrder.isInsider ?? false,
    [EQUITIES_ETFS_FIELD_NAMES.proStatus]:
      tradeOrder.proStatus || TRADEORDER_PRO_STATUS.NONPRO,
    [EQUITIES_ETFS_FIELD_NAMES.isSellAll]: tradeOrder.isSellAll ?? false,
    [EQUITIES_ETFS_FIELD_NAMES.broker]: tradeOrder.broker || '',
    [EQUITIES_ETFS_FIELD_NAMES.commission]: tradeOrder.commission,
    [EQUITIES_ETFS_FIELD_NAMES.notes]: tradeOrder.notes || '',
    [EQUITIES_ETFS_FIELD_NAMES.instructions]: tradeOrder.instructions || '',
    [EQUITIES_ETFS_FIELD_NAMES.isSolicited]: tradeOrder.isSolicited || false,
    [EQUITIES_ETFS_FIELD_NAMES.expectedPrice]: tradeOrder.expectedPrice ?? null
  }
}

export function generateEquitiesEtfsTradeOrderPayload({
  instrumentUrl,
  accountUrl,
  instrumentName,
  ...values
}: IEquitiesEtfsFormValues): ITradeOrder {
  const payload: ITradeOrder = {
    ...values,
    securityType: TRADEORDER_SECURITY_TYPE.EQUITY,
    instrument: instrumentUrl,
    account: accountUrl
  }

  payload.limitPrice =
    values.type === TRADEORDER_TYPE.MARKET ? null : values.limitPrice

  if (values.qty) {
    payload.qty = qtyChangeOperationSign(values.qty, values.operation)
  }

  if (!!values.expiryType && values.expiryType !== TRADEORDER_EXPIRY_TYPE.GTD) {
    delete payload.expiryDate
  }

  return payload
}
