import React, {forwardRef} from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './style.scss'

interface ITableGridProps extends React.HTMLAttributes<HTMLDivElement> {
  ref?: React.Ref<HTMLDivElement>
  /**
   * Removes row gap between table header and body
   */
  noRowGap?: boolean
}

export const TableGrid: React.FC<ITableGridProps> = forwardRef(
  ({className, noRowGap, ...props}, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={classNames(css.tableGrid, className, {
          [css.noRowGap]: noRowGap
        })}
      />
    )
  }
)

export const TableGridHeader: React.FC<ITableGridProps> = forwardRef(
  ({className, ...props}, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={classNames(css.tableGridHeader, className)}
      />
    )
  }
)

export const TableGridBody: React.FC<ITableGridProps> = forwardRef(
  ({className, ...props}, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={classNames(className, css.tableGridBody)}
      />
    )
  }
)

export const TableGridFooter: React.FC<ITableGridProps> = forwardRef(
  ({className, ...props}, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={classNames(css.tableGridFooter, className)}
      />
    )
  }
)
