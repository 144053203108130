import produce from 'immer'
import {map} from 'lodash'

import {
  INVESTORPORTALSECTION_NAME,
  IUserView,
  IViewFilterItem,
  IViewMetricItem,
  PERIOD
} from '@d1g1t/api/models'
import {IFilterValue} from '@d1g1t/typings/general'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {IDisplayOptions} from './components/display-options/typings'
import {
  CUSTOM_METRIC_PREFIX,
  PAGE_PERIOD_METRIC_SUFFIX,
  PERIOD_SHORTFORMS
} from './constants'

/**
 * Complementary to `mapPagePeriodMetricsToCustom()`
 *
 * For Page Level Period Selection feature, we map the "page-period" (PP) metric to
 * "custom-period" (CP) to save it as a valid metric value, and set the flag to `true`.
 *
 * When retrieving saved views, we also undo the "page-period" to "custom-period"
 * mapping if the flag is `true`.
 *
 * @param metricItems - saved metrics from the API
 * @returns metrics where CP is replaced with PP when flag is `true`
 */
const mapCustomMetricToPagePeriod = (
  viewMetrics: IViewMetricItem[]
): IViewMetricItem[] => {
  if (!viewMetrics) {
    return []
  }
  return viewMetrics.map((viewMetric) => {
    if (viewMetric.usePagePeriod) {
      return {
        ...viewMetric,
        metric: viewMetric.metric.replace(
          PERIOD.CUSTOM,
          PAGE_PERIOD_METRIC_SUFFIX
        ),
        path: viewMetric.path.map((p: string) =>
          p
            .replace(
              PERIOD_SHORTFORMS[PERIOD.CUSTOM],
              PERIOD_SHORTFORMS[PAGE_PERIOD_METRIC_SUFFIX]
            )
            .replace(PERIOD.CUSTOM, PAGE_PERIOD_METRIC_SUFFIX)
        ),
        usePagePeriod: true
      }
    }
    return viewMetric
  })
}

const mapMetricsToState = (metrics: IViewMetricItem[]): IViewMetricItem[] => {
  if (!metrics) {
    return []
  }

  return metrics.map((metric, order) => {
    let path = metric.path.slice()

    if (!metric.metric.startsWith(CUSTOM_METRIC_PREFIX)) {
      path = path.filter((_, index) => index % 2 !== 0)
    }

    return {
      ...metric,
      path,
      order
    }
  })
}

const mapFiltersToState = (filters: IViewFilterItem[]): IFilterValue => {
  return {
    joinOperator:
      filters?.length > 0 && filters[0].joinOperator === 'AND' ? 'AND' : 'OR',
    items: map(filters, (filter) => {
      return {
        value: filter.value,
        filterCriterion: filter.filterCriterion
      }
    })
  }
}

/**
 * Maps IUserView to IDisplayOptions.
 */
export const convertViewToSelection = (view: IUserView): IDisplayOptions => {
  if (!view) {
    return
  }

  return {
    tableKey: view.tableKey,
    groups: view.groups || [],
    metrics: mapMetricsToState(mapCustomMetricToPagePeriod(view.metrics)),
    filter: mapFiltersToState(view.filters),
    displayData: view.displayData
  }
}

/**
 * Complementary to `mapCustomMetricToPagePeriod()`
 *
 * For Page Level Period Selection feature, we map the "page-period" (PP) metric to
 * "custom-period" (CP) to save it as a valid metric value, and set the flag to `true`.
 *
 * When retrieving saved views, we also undo the "page-period" to "custom-period"
 * mapping if the flag is `true`.
 *
 * @param metricItems - metrics to save to API
 * @returns valid metrics, by replacing PP with CP and setting flag to `true`
 */
export const mapPagePeriodMetricsToCustom = (
  metricItems: IViewMetricItem[]
): IViewMetricItem[] => {
  if (!metricItems) {
    return []
  }
  return metricItems.map((viewMetric) => {
    if (viewMetric.metric.includes(PAGE_PERIOD_METRIC_SUFFIX)) {
      return {
        ...viewMetric,
        metric: viewMetric.metric.replace(
          PAGE_PERIOD_METRIC_SUFFIX,
          PERIOD.CUSTOM
        ),
        usePagePeriod: true
      }
    }
    return {...viewMetric, usePagePeriod: false}
  })
}

/**
 * Removes URLs from view.groupings and view.metrics
 * so it can be safely duplicated without mutating the original view in the process.
 * The urls are unique representing the grouping row and metric column in the database,
 * so they should not be duplicated or reused for another view.
 */
export const removeGroupingAndMetricUrls = (view: IUserView): IUserView => {
  return produce(view, (draftModel) => {
    for (const [index] of view.groups.entries()) {
      delete draftModel.groups[index].url
    }

    for (const [index] of view.metrics.entries()) {
      delete draftModel.metrics[index].url
    }
  })
}

/**
 * Given the saved selected view and all views, will return
 * the view matching the url of the saved selected view.
 *
 * Defaults to the first view if there is no selected view or the
 * selected view no longer exists.
 *
 * Returns `null` if loading or there are no views.
 *
 * Will filter views by investor portal sections if available to external profiles
 */
export function findSelectedView(
  selectedViewId: string,
  loadingSavedSelectedView: boolean,
  allViews: IUserView[],
  investorPortalSection?: INVESTORPORTALSECTION_NAME
) {
  if ((!selectedViewId && loadingSavedSelectedView) || !allViews) {
    // still loading views & selected view
    return null
  }

  const filteredViews = (() => {
    if (!investorPortalSection) {
      return allViews
    }

    // If investor portal section passed then filter all views by
    // corresponding investor portal section
    return allViews.filter((view) =>
      view.investorPortalSections.find(
        (section) => section.name === investorPortalSection
      )
    )
  })()

  if (filteredViews.length === 0) {
    // no views exist, even if the user saved an old one
    return null
  }

  if (!selectedViewId) {
    // user hasn't selected a view yet; just use first view
    return filteredViews[0]
  }

  // find the view corresponding to the selected view
  return (
    filteredViews.find(
      (view) => extractIdFromUrl(view.url) === selectedViewId
    ) || filteredViews[0]
  )
}

export function generateCustomMetricUrl(key: string) {
  return `${CUSTOM_METRIC_PREFIX}:${key}`
}
