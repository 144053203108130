import {isNil} from 'lodash'

import {ENTITYRELATIONCLIENT_STATUS} from '@d1g1t/api/models'

import {BADGE_STATUS} from '../mui/badge'

export const mapClientStatusToBadgeStatus = (
  clientStatus: ENTITYRELATIONCLIENT_STATUS
): BADGE_STATUS | null => {
  switch (clientStatus) {
    case ENTITYRELATIONCLIENT_STATUS.ACTIVE:
      return BADGE_STATUS.ACTIVE
    case ENTITYRELATIONCLIENT_STATUS.PROSPECT:
      return BADGE_STATUS.INACTIVE
    case ENTITYRELATIONCLIENT_STATUS.CLOSING:
      return BADGE_STATUS.PENDING
    case ENTITYRELATIONCLIENT_STATUS.CLOSED:
      return BADGE_STATUS.CLOSED
    default:
      return null
  }
}

export const mapMandateIsActiveStrategyToBadgeStatus = (
  mandateIsActiveStrategy: boolean
): BADGE_STATUS.ACTIVE | BADGE_STATUS.PENDING | null => {
  if (isNil(mandateIsActiveStrategy)) {
    return null
  }

  return mandateIsActiveStrategy ? BADGE_STATUS.ACTIVE : BADGE_STATUS.PENDING
}
