import React from 'react'
import {useLocation, useParams} from 'react-router-dom'

import {useApi, useApiQuery} from 'fairlight'

import {UserAccountProfilesEndpoints} from '@d1g1t/api/endpoints'

import {history} from '@d1g1t/lib/history'
import {ISelectionParams} from '@d1g1t/lib/url'

import {Card} from '@d1g1t/shared/components/card'
import {confirm} from '@d1g1t/shared/components/confirmation'
import {LoaderDisplay} from '@d1g1t/shared/components/loader-display'
import {TwoColumnPageGrid} from '@d1g1t/shared/components/two-column-page-grid'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {
  IWithPermissionsProps,
  withPermissions
} from '@d1g1t/shared/wrappers/permissions'
import {useUserProfile} from '@d1g1t/shared/wrappers/user-profile'

import {Page} from '@d1g1t/advisor/components/page'
import {PageContent} from '@d1g1t/advisor/components/page-content'
import {PageTitleBar} from '@d1g1t/advisor/containers/page-title-bar'
import {AdministrationLocations} from '@d1g1t/advisor/locations'
import {GrayBox} from '@d1g1t/advisor/pages/administration/users/user/single/components/gray-box'
import {UserProfileForm} from '@d1g1t/advisor/pages/administration/users/user/single/components/user-profile-form'
import {UserStatusForm} from '@d1g1t/advisor/pages/administration/users/user/single/components/user-status-form'

interface IUserProfileContainerProps extends IWithPermissionsProps {}

export const UserProfileComponent: React.FC<IUserProfileContainerProps> = (
  props
) => {
  const {selection: userId} = useParams<{selection: string}>()
  const location = useLocation<ISelectionParams>()
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()
  const api = useApi()

  const [userProfileData, userProfileActions] = useApiQuery(
    UserAccountProfilesEndpoints.findById(userId),
    {
      useErrorBoundary: false
    }
  )
  const [, {refetchUserProfile}] = useUserProfile()

  const handleUserDelete = async () => {
    const name = `${userProfileData.data.user.firstName} ${userProfileData.data.user.lastName}`
    const confirmation = confirm({
      title: `Delete User Account: ${name}`,
      content: 'Do you want to delete this user account?',
      confirmLabel: 'Delete'
    })

    if (!(await confirmation)) {
      return
    }

    try {
      await api.request(UserAccountProfilesEndpoints.destroy(userId))
      showSnackbar({
        variant: 'success',
        message: 'User has been deleted'
      })

      history.push(AdministrationLocations.userList())
    } catch (error) {
      handleUnexpectedError(error, 'Error deleting users')
    }
  }

  const handleUserEnable = async () => {
    const confirmation = confirm({
      title: 'Enable user',
      content: 'Do you want to enable user?',
      confirmLabel: 'Enable'
    })

    if (!(await confirmation)) {
      return
    }
    try {
      await api.request(
        UserAccountProfilesEndpoints.partialUpdate(userId, {
          user: {
            url: userProfileData.data.user.url,
            email: userProfileData.data.user.email,
            isActive: true
          }
        })
      )

      showSnackbar({
        variant: 'success',
        message: 'User has been enabled'
      })
      userProfileActions.refetch()
    } catch (error) {
      handleUnexpectedError(error, 'Error enabling user')
    }
  }

  const handleUserDisable = async () => {
    const confirmation = confirm({
      title: 'Disable user',
      content: 'Do you want to disable user?',
      confirmLabel: 'Disable'
    })

    if (!(await confirmation)) {
      return
    }

    try {
      await api.request(
        UserAccountProfilesEndpoints.partialUpdate(userId, {
          user: {
            url: userProfileData.data.user.url,
            email: userProfileData.data.user.email,
            isActive: false
          }
        })
      )

      showSnackbar({
        variant: 'success',
        message: 'User has been disabled'
      })
      userProfileActions.refetch()
    } catch (error) {
      handleUnexpectedError(error, 'Error disabling user')
    }
  }

  const isUserSettings = () => {
    if (!location) {
      return false
    }
    return AdministrationLocations.userSettings(userId) === location.pathname
  }

  const handleUserProfileCacheClear = () => {
    // resets the value of user profile for the places that rely on the useApiQuery hook
    userProfileActions.refetch()
    // resets the value of user for the places that rely on the useUserProfile hook
    refetchUserProfile()
  }

  return (
    <Page>
      {userProfileData.data && (
        <PageTitleBar
          title={`User: ${
            userProfileData.data.user
              ? [
                  userProfileData.data.user.firstName,
                  userProfileData.data.user.lastName
                ].join(' ')
              : '-'
          }`}
          backLink={
            props.permissions.isAdministrator()
              ? AdministrationLocations.userList()
              : null
          }
          noBottomMargin
        />
      )}
      <PageContent saveBarBottomPadding>
        <LoaderDisplay autoHeight {...userProfileData} />
        <TwoColumnPageGrid>
          <GrayBox
            profile={userProfileData.data}
            onEnable={handleUserEnable}
            onDisable={handleUserDisable}
            onDelete={handleUserDelete}
            isUserSettings={isUserSettings()}
          />
          <div>
            {userProfileData.data && (
              <UserStatusForm status={userProfileData.data.user.isActive} />
            )}
            <Card extraPadding minHeight={120}>
              {userProfileData.data && (
                <UserProfileForm
                  showRepCodes={props.permissions.getId() !== userId}
                  initialValues={userProfileData.data}
                  onUpdateSuccess={handleUserProfileCacheClear}
                  userId={userId}
                />
              )}
            </Card>
          </div>
        </TwoColumnPageGrid>
      </PageContent>
    </Page>
  )
}

export const UserProfilePage = withPermissions()(UserProfileComponent)

UserProfilePage.displayName = 'UserProfilePage'

export default UserProfilePage
