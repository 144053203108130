import React, {useMemo} from 'react'

import {Options} from 'highcharts'
import {merge} from 'lodash'

import {BaseChart} from '@d1g1t/shared/components/charts/base-chart'

interface IDonutChartProps {
  config?: Options
}

const DEFAULT_CONFIG: Options = {
  chart: {
    type: 'pie'
  },
  title: {
    align: 'center',
    verticalAlign: 'middle',
    floating: true,
    y: 0
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        color: '#616161',
        formatter() {
          if (this.y > 0) {
            return `${parseFloat(this.percentage as unknown as string).toFixed(
              2
            )} %`
          }
        }
      }
    },
    pie: {
      innerSize: '50%',
      showInLegend: true
    }
  },
  tooltip: {
    pointFormat: '{point.percentage:.1f} %'
  },
  // Disabling legend reduces render time by ~60%
  // they aren't very useful as soon as there are more then 2 items in any case
  // could potentially be re-enabed if we upgrade highcharts, they have made
  // a long list of performance improvements, not sure if this is part of it
  legend: {
    enabled: false
  }
}

export const DonutChart: React.FC<IDonutChartProps> = (
  props: IDonutChartProps
) => {
  const config = useMemo(
    () => merge({}, DEFAULT_CONFIG, props.config),
    [props.config]
  )

  return <BaseChart config={config} />
}
