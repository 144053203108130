import React, {useContext} from 'react'

import {KeyboardDatePicker} from '@d1g1t/shared/components/mui/keyboard-date-picker'
import {Text} from '@d1g1t/shared/components/typography'

import {MetricsFormContext} from '../../context'

import css from './style.scss'

export const MetricsCustomDateRange: React.FC = () => {
  const {metricItem, latestAvailableData, onStartDateChange, onEndDateChange} =
    useContext(MetricsFormContext)

  return (
    <div className={css.dateRangeContainer}>
      <Text>From</Text>
      <KeyboardDatePicker
        margin='dense'
        value={metricItem.dateRange.startDate}
        onChange={onStartDateChange}
        maxDate={latestAvailableData}
      />
      <Text>To</Text>
      <KeyboardDatePicker
        margin='dense'
        value={metricItem.dateRange.endDate}
        onChange={onEndDateChange}
        maxDate={latestAvailableData}
      />
    </div>
  )
}
