import {useCallback} from 'react'

import {Api, useApi} from 'fairlight'
import {flatten} from 'lodash'
import useAsyncCall from 'use-async-call'

import {AccountEndpoints, EntityEndpoints} from '@d1g1t/api/endpoints'
import {ALL_MODELS} from '@d1g1t/api/models'
import {ISelectedEntities} from '@d1g1t/typings/general'

/**
 * Returns a list of URLs of accounts of the entities in selectedEntities.
 *
 * Does not consider account filters. Tested with the following models:
 *
 * ALL_MODELS.HOUSEHOLD, ALL_MODELS.CLIENT, ALL_MODELS.INVESTMENTMANDATE, ALL_MODELS.CORPORATION
 */
export function useAccountUrls(selectedEntities: ISelectedEntities) {
  const api = useApi()

  const fetchEntityRelations = useCallback(
    () => fetchAccountUrls(selectedEntities, api),
    [selectedEntities]
  )

  return useAsyncCall(fetchEntityRelations)
}

/**
 * Returns a list of URLs of accounts of the entities in selectedEntities.
 *
 * Does not consider account filters. Tested with the following models:
 *
 * ALL_MODELS.HOUSEHOLD, ALL_MODELS.CLIENT, ALL_MODELS.INVESTMENTMANDATE, ALL_MODELS.CORPORATION
 */
export async function fetchAccountUrls(
  selectedEntities: ISelectedEntities,
  api: Api
): Promise<string[]> {
  const entityIdsRequiringRelations: string[] = []
    .concat(selectedEntities?.clients)
    .concat(selectedEntities?.households)
    .concat(selectedEntities?.investmentMandates)
    .filter((id) => id)

  const entitiesRelations = await (() => {
    if (!entityIdsRequiringRelations.length) {
      return null
    }

    return api.request(
      EntityEndpoints.entitiesRelationships({
        entities: entityIdsRequiringRelations
      })
    )
  })()

  // Assumes `selectedEntities.accountsOrPositions` has no position entity IDs
  const individualAccounts =
    flatten(selectedEntities?.accountsOrPositions).map((accountEntityId) => {
      return api.buildUrl(AccountEndpoints.pathToResource(accountEntityId))
    }) || []

  return Object.values(entitiesRelations || {})
    .flatMap((relation) => {
      if (ALL_MODELS.HOUSEHOLD === relation.selected.model) {
        return relation.accounts.map((account) =>
          api.buildUrl(AccountEndpoints.pathToResource(account.entityId))
        )
      }

      return relation.accounts
        .filter(
          (account) =>
            account.ownerEntityId === relation.selected.entityId ||
            account.mandateEntityId === relation.selected.entityId
        )
        .map((account) =>
          api.buildUrl(AccountEndpoints.pathToResource(account.entityId))
        )
    })
    .concat(individualAccounts)
}
