import {IBulkRebalanceChartParams} from '@d1g1t/api/endpoints'
import {COMMISSION_TYPE, IFxRate} from '@d1g1t/api/models'

import {StandardResponse} from '@d1g1t/lib/standard-response'

export enum BulkChartEditableFieldName {
  quantityChange = 'quantity_change',
  valueChange = 'value_change',
  weightChange = 'weight_change',
  targetQuantity = 'target_quantity',
  targetValue = 'target_value',
  targetWeight = 'target_weight'
}

export interface IEditedValuesBySecurityAndAccount {
  [accountId: string]: IEditedValuesBySecurity
}

export interface IEditedValuesBySecurity {
  [securityId: string]: {
    editableFieldName: BulkChartEditableFieldName
    value: number | null
  }
}

export type IBulkRebalanceParams = Omit<IBulkRebalanceChartParams, 'accounts'>

export interface IDirectiveInputs {
  lower_bound: number
  target_weight: number
  upper_bound: number
  market_price?: number
  commission: number
  commission_type: COMMISSION_TYPE
}

export interface IInitialMarketPrices {
  [securityEntityId: string]: number
}

export interface ILoadedSecurity {
  url: string
  label: string
  /**
   * Tracks which securities require recommendation
   * We should only target values for those which the user has explicitly asked
   * for by clicking "Recommend Trades" button.
   */
  shouldRecommend: boolean
  /**
   * Kept separately because market price shouldn't be sent to back-end unless it is overridden.
   */
  initialMarketPrice: number
}

export interface IDirectiveInputsBySecurityEntityId {
  [securityEntityId: string]: IDirectiveInputs
}

export interface IAccountAllocationState {
  securities: ILoadedSecurity[]
  checkedSecurityUrls: string[]
  loadingSecuritiesForModelPortfolio: boolean
  directiveInputsBySecurityEntityId: IDirectiveInputsBySecurityEntityId
  selectedModelPortfolio: boolean
  bulkRebalanceParams: IBulkRebalanceParams
  accountAllocationChart: Loadable<StandardResponse>
  /**
   * FX rates of all currencies in the accountAllocationChart, which
   * are used to convert currencies.
   */
  fxRates: IFxRate[]
  /**
   * Base currency of the firm, which is used to convert currencies.
   */
  baseCurrency: string
  /**
   * The original recommended chart from the APi
   * This is used to compare against the edited version
   */
  recommendedAccountAllocationChart: StandardResponse
  editedValuesBySecurityAndAccount: IEditedValuesBySecurityAndAccount
}

export interface ITradeDirectivePageSelectedClientsProps {
  recommendTradesClicked: 'clicked' | 'loaded'
  modelPortfolioSelected: string
  setRecommendTradesClicked: React.Dispatch<
    React.SetStateAction<'clicked' | 'loaded'>
  >
  setModelPortfolioSelected: React.Dispatch<React.SetStateAction<string>>
}
