import React, {useState} from 'react'

import {OutlinedInput} from '@d1g1t/shared/components/mui/outlined-input'

interface IEditableModalTitleProps {
  name: string
  /**
   * Updates the name state on view name change
   */
  onViewNameChange: (name: string) => void
}

/**
 * Editable field which can be placed in a `Modal`'s `title`
 */
export const EditableModalTitle: React.FC<IEditableModalTitleProps> =
  function EditableModalTitle(props) {
    const [name, setName] = useState(() => props.name)

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value)
      props.onViewNameChange(event.target.value)
    }

    return (
      <>
        <OutlinedInput
          value={name}
          onChange={onNameChange}
          fullWidth
          smallHeight
        />
      </>
    )
  }
