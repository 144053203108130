import {atom} from 'recoil'

import {PROCESS_STATUS} from '@d1g1t/api/models'

/**
 * Using recoil state to keep status and if user initiated refresh between
 * different pages/entity changes
 */
export const entityRefreshAtom = atom<{
  [householdId: string]: {
    userInitiatedRefresh: boolean
    status: PROCESS_STATUS
    statusDetails?: string // Extra details about process status. Exposed to UI
  }
}>({key: 'entityRefreshAtom', default: EMPTY_OBJECT})
