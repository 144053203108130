export const assertNever = (
  /**
   * When the `switch` statement exhaustive with an `enum`,
   * `never` will be the inferred type in the `default` clause
   */
  x: never,
  errorMessage = `Unexpected object: ${x}`
) => {
  if (x) {
    throw new Error(errorMessage)
  }
}
