import React, {useEffect, useMemo, useRef, useState} from 'react'
import {INumberFormatProps} from 'react-number-format'

import {debounce, isNil, round} from 'lodash'

import {
  CHART_VALUE_TYPE,
  IChartTableCategory,
  IChartTableItem
} from '@d1g1t/api/models'

import {BooleanFormatter} from '@d1g1t/lib/formatters/boolean-formatter'
import {Direction, directionFromKeyboardEvent} from '@d1g1t/lib/keyboard'

import {ClickOutside} from '@d1g1t/shared/components/click-outside'
import {Flex} from '@d1g1t/shared/components/flex'
import {
  createNumberFormatInput,
  formatInputPercentage,
  parseInputPercentage
} from '@d1g1t/shared/components/formatted-input'
import {InputAdornment} from '@d1g1t/shared/components/mui/input-adornment'
import {KeyboardDatePicker} from '@d1g1t/shared/components/mui/keyboard-date-picker'
import {OutlinedInput} from '@d1g1t/shared/components/mui/outlined-input'

import {isCurrentSingleCellEditActive} from '../../data-cell/lib'
import {EditableEnum} from '../editable-enum'
import {IEditableCellComponentProps} from './typings'

import css from './style.scss'

export const EditableCell: React.FC<IEditableCellComponentProps> = ({
  textAlignRight = true,
  ...props
}) => {
  const inputEl = useRef(null)

  const cellDataValue = props.value ?? props.item.getValue(props.category.id)
  const allowedValues = props.item.getAllowedValues(props.category.id)

  const isPercentageInput =
    props.category.valueType === CHART_VALUE_TYPE.PERCENTAGE

  const decimalScale = (() => {
    if (typeof props.category.options?.decimals === 'number') {
      return props.category.options.decimals
    }

    switch (props.category.valueType) {
      case CHART_VALUE_TYPE.MONEY_ABBREVIATED:
      case CHART_VALUE_TYPE.DECIMAL:
      case CHART_VALUE_TYPE.PERCENTAGE:
        return 2
      case CHART_VALUE_TYPE.DECIMAL_LONG:
        return 4
      case CHART_VALUE_TYPE.DECIMAL_LONG_LONG:
        return 6
      default:
        return 0
    }
  })()

  const formattedValue = (() => {
    if (
      allowedValues ||
      (props.category.valueType === CHART_VALUE_TYPE.STRING && !props.component)
    ) {
      return cellDataValue
    }

    return round(cellDataValue, decimalScale + (isPercentageInput ? 2 : 0))
  })()

  const [value, setValue] = useState(cellDataValue)
  const [openedCalendar, setOpenedCalender] = useState(false)

  useEffect(() => {
    setValue(cellDataValue)
  }, [cellDataValue])

  const changeCallback = React.useCallback(
    (value: any, key: any, cellDataValue: any, formattedValue: any) => {
      if (
        value === cellDataValue ||
        [value, cellDataValue].every(isNil) ||
        (cellDataValue && value === formattedValue)
      ) {
        return
      }

      props.onChange(props.item, props.category, value, key)
    },
    [props.onChange, props.item, props.category]
  )

  const change = (value: any, key?: any) => {
    changeCallback(value, key, cellDataValue, formattedValue)
  }

  const handleClickAway = () => {
    if (
      !props.setActiveEditingCellLocation ||
      !isCurrentSingleCellEditActive(
        {categoryId: props.category.id, itemId: props.item.id},
        props.activeEditingCellLocation
      )
    ) {
      return
    }

    change(value)

    props.setActiveEditingCellLocation(null)
  }

  const handleDebounceChange = useMemo(
    () => debounce(changeCallback, props.debounceDelayTime),
    [props.debounceDelayTime, changeCallback]
  )

  const handleChange = (value: any, key?: any) => {
    setValue(value)

    if (props.activeEditingCellLocation) {
      return
    }

    if (Number.isFinite(props.debounceDelayTime)) {
      handleDebounceChange(value, key, cellDataValue, formattedValue)
      return
    }

    change(value, key)
  }

  const handleChangeDateInput = (value: string) => {
    handleChange(value)
  }

  const handleChangeOutlinedInput = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    handleChange(event.target.value)
  }

  const handleChangeEnumInput = (
    item: IChartTableItem,
    category: IChartTableCategory,
    value: any,
    key?: any
  ) => {
    change(value, key)

    if (props.setActiveEditingCellLocation) {
      props.setActiveEditingCellLocation(null)
    }
  }

  const findNextEditableIndexes = (
    direction: Direction,
    wrap: boolean
  ): {rowIndex: number; columnIndex: number} => {
    if (direction === Direction.up || direction === Direction.down) {
      return {
        columnIndex: props.columnIndex,
        rowIndex:
          direction === Direction.up ? props.rowIndex - 1 : props.rowIndex + 1
      }
    }

    const indexes = props.findEditableColumnIndexes(props.columnIndex)

    if (direction === Direction.right) {
      if (indexes.next !== null) {
        return {
          columnIndex: indexes.next,
          rowIndex: props.rowIndex
        }
      }
      if (wrap) {
        return {
          columnIndex: indexes.first,
          rowIndex: props.rowIndex + 1
        }
      }

      return null
    }
    if (indexes.prev !== null) {
      return {
        columnIndex: indexes.prev,
        rowIndex: props.rowIndex
      }
    }
    if (wrap) {
      return {
        columnIndex: indexes.last,
        rowIndex: props.rowIndex - 1
      }
    }

    return null
  }

  const findNextEditableInput = (
    containerNode: HTMLElement,
    {columnIndex, rowIndex}: {columnIndex: number; rowIndex: number}
  ): HTMLInputElement => {
    if (!containerNode) {
      return null
    }

    const querySelector = `input[data-row-index="${rowIndex}"][data-column-index="${columnIndex}"]`

    return containerNode.querySelector(querySelector)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.setActiveEditingCellLocation) {
      if (event.key === 'Escape') {
        props.setActiveEditingCellLocation(null)
      }

      if (event.key === 'Enter') {
        change(value)

        props.setActiveEditingCellLocation(null)
      }

      return
    }

    const direction = directionFromKeyboardEvent(event)
    if (direction === null) {
      return null
    }

    event.preventDefault()

    const wrap = event.key === 'Tab'
    const nextEditableIndexes = findNextEditableIndexes(direction, wrap)

    if (!nextEditableIndexes) {
      return
    }

    props.scrollTo(nextEditableIndexes)
    const domNode = inputEl.current as Element

    let containerElement = domNode.parentElement

    while (!containerElement.dataset.containerGrid) {
      containerElement = containerElement.parentElement
    }

    setTimeout(() => {
      const nextInputNode = findNextEditableInput(
        containerElement,
        nextEditableIndexes
      )

      if (nextInputNode) {
        nextInputNode.focus()
        nextInputNode.select()
      }
    })
  }

  const inputComponent = (() => {
    const InputComponent = useMemo(() => {
      if (
        allowedValues ||
        (props.category.valueType === CHART_VALUE_TYPE.STRING &&
          !props.component)
      ) {
        return
      }

      const max = props.category.options?.max
      const min = props.category.options?.min

      // Apply limit if max and min are defined
      const isAllowed: INumberFormatProps['isAllowed'] =
        max || min
          ? (values) => {
              if (typeof values.floatValue !== 'number') {
                return true
              }

              if (typeof min !== 'number') {
                return values.floatValue <= max
              }

              if (typeof max !== 'number') {
                return values.floatValue >= min
              }

              return values.floatValue >= min && values.floatValue <= max
            }
          : undefined

      const commonNumberFormatOpts = {
        decimalScale,
        allowNegative: !props.category.options?.absolute,
        thousandSeparator: true,
        isAllowed
      }

      if (isPercentageInput) {
        return createNumberFormatInput({
          ...commonNumberFormatOpts,
          suffix: ' %',
          parseValue: parseInputPercentage,
          formatValue: formatInputPercentage
        })
      }

      return createNumberFormatInput(commonNumberFormatOpts)
    }, [
      decimalScale,
      props.category.options?.absolute,
      props.category.options?.min,
      props.category.options?.max,
      isPercentageInput,
      allowedValues
    ])

    const errorMessage =
      typeof props.getCellError === 'function' &&
      props.getCellError({
        item: props.item,
        category: props.category
      })

    if (props.category.valueType === CHART_VALUE_TYPE.DATE) {
      return (
        <KeyboardDatePicker
          inputRef={inputEl}
          smallHeight
          isCompactMode={props.isCompactMode}
          hideError
          error={Boolean(errorMessage)}
          inputProps={{
            'data-row-index': props.rowIndex,
            'data-column-index': props.columnIndex,
            'data-category-id': props.category.id,
            'data-item-id': props.item.id,
            title: typeof errorMessage === 'string' ? errorMessage : undefined,
            style: {
              paddingTop: 0,
              paddingBottom: 0
            },
            placeholder: 'YYYY-MM-DD'
          }}
          data-row-index={props.rowIndex}
          data-column-index={props.columnIndex}
          value={value || ''}
          onChange={(value) => {
            handleChangeDateInput(value || null)
          }}
          onKeyDown={handleKeyDown}
          allowFuture
          open={openedCalendar}
          onOpen={() => setOpenedCalender(true)}
          onClose={() => setOpenedCalender(false)}
        />
      )
    }

    if (props.category.valueType === CHART_VALUE_TYPE.BOOLEAN) {
      const booleanFormatter = new BooleanFormatter()
      return (
        <EditableEnum
          {...props}
          onChange={handleChangeEnumInput}
          options={[true, false].map((value) => ({
            value,
            label: booleanFormatter.format(value)
          }))}
          isCompactMode={props.isCompactMode}
        />
      )
    }

    if (allowedValues) {
      const options = allowedValues.map((allowedValue) => ({
        value: allowedValue.key,
        label: allowedValue.value
      }))

      return (
        <EditableEnum
          {...props}
          isCompactMode={props.isCompactMode}
          onChange={handleChangeEnumInput}
          options={options}
        />
      )
    }

    return (
      <Flex justifyFlexEnd fullWidth>
        <OutlinedInput
          inputRef={inputEl}
          smallHeight
          isCompactMode={props.isCompactMode}
          autoFocus={!!props.setActiveEditingCellLocation}
          onFocus={
            // select all when focused if single cell editing
            props.setActiveEditingCellLocation
              ? (event) => event.target.select()
              : undefined
          }
          disabled={props.disabled}
          inputComponent={InputComponent}
          error={Boolean(errorMessage)}
          startAdornment={
            props.category.options?.currency ? (
              <InputAdornment
                className={css.currencyAdornment}
                position='start'
              >
                {props.category.options.currency}
              </InputAdornment>
            ) : undefined
          }
          value={value}
          inputProps={{
            'data-row-index': props.rowIndex,
            'data-column-index': props.columnIndex,
            'data-category-id': props.category.id,
            'data-item-id': props.item.id,
            title: typeof errorMessage === 'string' ? errorMessage : undefined,
            style: {
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
          deltaColours={props.category.options?.delta}
          textAlignRight={textAlignRight}
          onChange={handleChangeOutlinedInput}
          onKeyDown={handleKeyDown}
        />
      </Flex>
    )
  })()

  if (
    !openedCalendar &&
    props.activeEditingCellLocation &&
    !props.category.options?.allowedValues &&
    (props.category.valueType !== CHART_VALUE_TYPE.BOOLEAN || allowedValues)
  ) {
    return (
      <ClickOutside onClickOutside={handleClickAway}>
        {inputComponent}
      </ClickOutside>
    )
  }

  return inputComponent
}
