import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './styles.scss'

export interface IChartSideContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const ChartSideContainer: React.SFC<IChartSideContainerProps> = ({
  className,
  ...props
}) => <div className={classNames(css.sideContainer, className)} {...props} />
