import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

import {ALL_MODELS} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'

import {ClientIcon} from '@d1g1t/shared/components/client-icon'
import {Flex} from '@d1g1t/shared/components/flex'

import * as css from './style.scss'

export interface IChipProps {
  text: string
  tooltip?: string // if there is no tooltip provided, the text prop will be desplayied as a tooltip on hover
  focused?: boolean
  model?: ALL_MODELS
  ref?: React.Ref<HTMLDivElement>
}

/**
 * A chip with left icon based on the `model` prop, and text.
 */
export const Chip: React.FC<IChipProps> = React.forwardRef((props, ref) => (
  <Flex
    alignCenter
    justifyCenter
    title={props.tooltip ? props.tooltip : props.text}
    className={classNames(css.chip, {[css.unFocus]: !props.focused})}
    ref={ref}
  >
    {props.model && (
      <ClientIcon className={css.shrinkSvgIcon} model={props.model} />
    )}
    <div className={css.text}>{props.text}</div>
    {props.children}
  </Flex>
))

export interface ILoadingChipProps
  extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * A chip with loading spinner as the left icon.
 */
export const LoadingChip: React.FC<ILoadingChipProps> = ({
  className,
  children,
  ...props
}) => (
  <Flex
    alignCenter
    justifySpaceBetween
    className={classNames(css.chip, css.loading, className)}
    {...props}
  >
    <CircularProgress size={18} thickness={2} />
    {children}
  </Flex>
)
