import React from 'react'

import {FieldArray, FieldArrayRenderProps, useField} from 'formik'

import {IRepCode} from '@d1g1t/api/models'

import {FlexGridItem, FlexGridRow} from '@d1g1t/shared/components/flex'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {FieldLabel} from '@d1g1t/shared/components/form/field-label'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Spacer} from '@d1g1t/shared/components/spacer'

const FORM_NAMES: PartialKeysOf<IRepCode> = {
  name: 'name'
}

interface IRepCodeFieldsProps {
  name: string
}

export const RepCodeFields: React.FC<IRepCodeFieldsProps> = (props) => {
  return (
    <>
      <FieldLabel label='REP Codes' />
      <FieldArray name={props.name} component={RenderRepCodeFields} />
    </>
  )
}

interface IRenderRepCodeFieldsProps extends FieldArrayRenderProps {}

const RenderRepCodeFields: React.FC<IRenderRepCodeFieldsProps> = (props) => {
  const [field] = useField(props.name)

  const handleAddRepCode = () => {
    props.push({name: ''})
  }

  return (
    <>
      {(field.value as unknown as any[]).map((_, index) => (
        <React.Fragment key={index}>
          <FlexGridRow>
            <FlexGridItem col='1/2'>
              <OutlinedInputField
                name={`${props.name}[${index}].${FORM_NAMES.name}`}
                label=''
              />
            </FlexGridItem>
            {index > 0 && (
              <div>
                <Button onClick={() => props.remove(index)}>Remove</Button>
              </div>
            )}
          </FlexGridRow>
          <Spacer xxs />
        </React.Fragment>
      ))}
      <Button primary outlined onClick={handleAddRepCode}>
        Add another REP code
      </Button>
      <Spacer xs />
    </>
  )
}
