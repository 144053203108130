import {ALL_MODELS} from '@d1g1t/api/models'

export enum CALCULATED_FEES_CATEGORY_IDS {
  APPROVAL_URL = 'approval_url',
  REJECT_URL = 'reject_url'
}
export const APPROVAL_INDEX = 6

export enum CALCULATED_FEE_STATUS {
  APPROVED = 'Approved',
  PENDING_APPROVAL = 'Pending Approval',
  REJECTED = 'Rejected'
}

export const TARGET_ENTITY_MODELS_OPTIONS = [
  ALL_MODELS.ACCOUNT,
  ALL_MODELS.FOUNDATION,
  ALL_MODELS.CORPORATION,
  ALL_MODELS.INDIVIDUAL,
  ALL_MODELS.TRUST,
  ALL_MODELS.HOUSEHOLD,
  ALL_MODELS.DATASET
]
