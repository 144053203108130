import {call, getContext, put, takeLatest} from 'redux-saga/effects'

import {Api} from 'fairlight'
import {getType} from 'typesafe-actions'

import {PortfolioRebalanceEndpoints} from '@d1g1t/api/endpoints'
import {IAccountsRebalancingResponse} from '@d1g1t/api/models'

import {reduxPut} from '@d1g1t/lib/sagas'

import {snackbarActions} from '@d1g1t/shared/containers/snackbar'
import {errorHandlerActions} from '@d1g1t/shared/wrappers/error-handler'

import {actions} from './actions'

function* rebalanceDataFetcher(
  action:
    | ReturnType<typeof actions.rebalanceAccountsRequest>
    | ReturnType<typeof actions.rebalanceAccountsCancel>
) {
  const api: Api = yield getContext('api')

  if (action.type === getType(actions.rebalanceAccountsCancel)) {
    return
  }

  try {
    const response: IAccountsRebalancingResponse = yield call(
      api.request,
      PortfolioRebalanceEndpoints.charts(action.payload)
    )

    yield put(actions.rebalanceAccountsSuccess(response))
  } catch (error) {
    yield reduxPut(
      errorHandlerActions.handleError({
        error,
        snackbarMessage:
          'An unexpected error occurred while trying to rebalance the account.'
      })
    )

    yield put(actions.rebalanceAccountsFailure(error))
  }
}

function* clearRebalanceDraft({
  payload
}: ReturnType<typeof actions.clearDraftRequest>) {
  const api: Api = yield getContext('api')

  try {
    const response: IAccountsRebalancingResponse = yield call(
      api.request,
      PortfolioRebalanceEndpoints.clearDraft(payload)
    )

    yield put(actions.clearDraftSuccess(response))
    yield reduxPut(
      snackbarActions.show({
        variant: 'success',
        message: 'Draft has been cleared.'
      })
    )
  } catch (error) {
    yield reduxPut(
      errorHandlerActions.handleError({
        error,
        snackbarMessage:
          'An unexpected error occurred while trying to clear the draft.'
      })
    )

    yield put(actions.clearDraftFailure(error))
  }
}

export function* rebalanceDataFetchingSagas() {
  yield takeLatest(getType(actions.clearDraftRequest), clearRebalanceDraft)
  yield takeLatest(
    [
      getType(actions.rebalanceAccountsRequest),
      getType(actions.rebalanceAccountsCancel)
    ],
    rebalanceDataFetcher
  )
}
