import React, {useMemo} from 'react'

import {useApi} from 'fairlight'
import {Formik} from 'formik'
import * as Yup from 'yup'

import {
  Formats,
  RoleEndpoints,
  RuleFilterEndpoints,
  TeamEndpoints,
  Types
} from '@d1g1t/api/endpoints'
import {IFilterRule} from '@d1g1t/api/models'
import {IFilterValueItem} from '@d1g1t/typings/general'

import {formatFilterValue, parseFilterValue} from '@d1g1t/lib/filters'
import {filterItemSchema} from '@d1g1t/lib/rule-filter-validation'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {Flex} from '@d1g1t/shared/components/flex'
import {FormActions} from '@d1g1t/shared/components/form-actions'
import {CheckboxField} from '@d1g1t/shared/components/form-field/checkbox-field'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {FilterEditorContainer} from '@d1g1t/shared/containers/filter-editor'
import {nodeIdFromFilterItem} from '@d1g1t/shared/containers/filter-editor/lib'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useFilterCriteria} from '@d1g1t/shared/wrappers/filter-criteria'
import {usePermissions} from '@d1g1t/shared/wrappers/permissions'

import {DEFAULT_FILTER_CRITERION_NODES} from '../'

enum RULE_FILTER_FIELD_NAMES {
  filter = 'filter',
  requiredInvestorAppFilter = 'requiredInvestorAppFilter',
  hiddenInvestorAppFilter = 'hiddenInvestorAppFilter',
  applyToInvestorHome = 'applyToInvestorHome',
  applyToInvestorInvestments = 'applyToInvestorInvestments',
  applyToInvestorActivity = 'applyToInvestorActivity',
  isAvailableToExternalProfiles = 'isAvailableToExternalProfiles'
}

export enum FILTER_FIELD_NAMES {
  name = 'name',
  joinOperator = 'joinOperator',
  items = 'items'
}

interface IRuleFilterFormValues {
  filter: IFilterValue
  requiredInvestorAppFilter: boolean
  hiddenInvestorAppFilter: boolean
  applyToInvestorHome: boolean
  applyToInvestorInvestments: boolean
  applyToInvestorActivity: boolean
  isAvailableToExternalProfiles: boolean
}

interface IFilterValue extends Omit<IFilterRule, 'ruleFilterItems'> {
  items: IFilterValueItem[]
}

interface IRuleFilterFormProps {
  /**
   * Pass true to render create form with corresponding initial values.
   * Will call create api instead if true as well.
   */
  create: boolean
  ruleFilter: IFilterRule
  onSave: (filter: IFilterRule) => void
  onCancel: () => void
}

export const RuleFilterForm: React.FC<IRuleFilterFormProps> = (props) => {
  const api = useApi()
  const permissions = usePermissions()
  const [filterCriteria] = useFilterCriteria(DEFAULT_FILTER_CRITERION_NODES)
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()
  const validationSchema = useMemo(
    () =>
      Yup.object({
        [RULE_FILTER_FIELD_NAMES.filter]: Yup.object({
          [FILTER_FIELD_NAMES.name]: Yup.string().required().label('Name'),
          [FILTER_FIELD_NAMES.items]: Yup.array()
            .of(Yup.lazy(filterItemSchema(filterCriteria.data)))
            .required()
            .min(1)
        }),
        [RULE_FILTER_FIELD_NAMES.isAvailableToExternalProfiles]:
          Yup.boolean().required(),
        [RULE_FILTER_FIELD_NAMES.requiredInvestorAppFilter]:
          Yup.boolean().required(),
        [RULE_FILTER_FIELD_NAMES.hiddenInvestorAppFilter]:
          Yup.boolean().required(),
        [RULE_FILTER_FIELD_NAMES.applyToInvestorActivity]:
          Yup.boolean().required(),
        [RULE_FILTER_FIELD_NAMES.applyToInvestorHome]: Yup.boolean().required(),
        [RULE_FILTER_FIELD_NAMES.applyToInvestorInvestments]:
          Yup.boolean().required()
      }),
    [filterCriteria.data]
  )
  const initialValues: IRuleFilterFormValues = useMemo(() => {
    if (!filterCriteria.data) {
      return null
    }

    if (props.create) {
      return {
        [RULE_FILTER_FIELD_NAMES.filter]: {
          [FILTER_FIELD_NAMES.name]: '',
          [FILTER_FIELD_NAMES.items]: [
            {
              id: filterCriteria.data?.defaultOperator.id,
              roleId: filterCriteria.data?.defaultOperator.roleId,
              teamId: filterCriteria.data?.defaultOperator.teamId,
              value: '',
              filterCriterion: filterCriteria.data?.defaultOperator.url
            }
          ]
        },
        [RULE_FILTER_FIELD_NAMES.isAvailableToExternalProfiles]: false,
        [RULE_FILTER_FIELD_NAMES.requiredInvestorAppFilter]: false,
        [RULE_FILTER_FIELD_NAMES.hiddenInvestorAppFilter]: false,
        [RULE_FILTER_FIELD_NAMES.applyToInvestorActivity]: false,
        [RULE_FILTER_FIELD_NAMES.applyToInvestorHome]: false,
        [RULE_FILTER_FIELD_NAMES.applyToInvestorInvestments]: false
      }
    }

    return {
      filter: {
        name: props.ruleFilter.name,
        joinOperator: props.ruleFilter.joinOperator,
        items: props.ruleFilter.ruleFilterItems.map((item) => {
          const {paths} = filterCriteria.data
          const {type, format} = paths[nodeIdFromFilterItem(item)]

          const isNumber = [
            Formats.int,
            Formats.decimal,
            Formats.percentage
          ].includes(format)

          const isMultiple = [
            Types.oneOf,
            Types.dateBetween,
            Types.in,
            Types.between
          ].includes(type)

          return {
            ...item,
            value: parseFilterValue(item.value as string, {
              isNumber,
              isMultiple
            })
          }
        })
      },
      [RULE_FILTER_FIELD_NAMES.isAvailableToExternalProfiles]:
        props.ruleFilter[RULE_FILTER_FIELD_NAMES.isAvailableToExternalProfiles],
      [RULE_FILTER_FIELD_NAMES.requiredInvestorAppFilter]:
        props.ruleFilter[RULE_FILTER_FIELD_NAMES.requiredInvestorAppFilter],
      [RULE_FILTER_FIELD_NAMES.hiddenInvestorAppFilter]:
        props.ruleFilter[RULE_FILTER_FIELD_NAMES.hiddenInvestorAppFilter],
      [RULE_FILTER_FIELD_NAMES.applyToInvestorActivity]:
        props.ruleFilter[RULE_FILTER_FIELD_NAMES.applyToInvestorActivity],
      [RULE_FILTER_FIELD_NAMES.applyToInvestorHome]:
        props.ruleFilter[RULE_FILTER_FIELD_NAMES.applyToInvestorHome],
      [RULE_FILTER_FIELD_NAMES.applyToInvestorInvestments]:
        props.ruleFilter[RULE_FILTER_FIELD_NAMES.applyToInvestorInvestments]
    }
  }, [filterCriteria.data, props.ruleFilter])

  const handleSubmit = async (values: IRuleFilterFormValues) => {
    const ruleFilterRequestBody = {
      ...values.filter,
      ruleFilterItems: values.filter.items.map((item) => {
        return {
          ...item,
          filterCriterion: item.filterCriterion,
          role: item.roleId
            ? api.buildUrl(RoleEndpoints.pathToResource(item.roleId))
            : null,
          team: item.teamId
            ? api.buildUrl(TeamEndpoints.pathToResource(item.teamId))
            : null,
          value: formatFilterValue(item.value)
        }
      }),
      [RULE_FILTER_FIELD_NAMES.isAvailableToExternalProfiles]:
        values[RULE_FILTER_FIELD_NAMES.isAvailableToExternalProfiles],
      [RULE_FILTER_FIELD_NAMES.requiredInvestorAppFilter]:
        values[RULE_FILTER_FIELD_NAMES.requiredInvestorAppFilter],
      [RULE_FILTER_FIELD_NAMES.hiddenInvestorAppFilter]:
        values[RULE_FILTER_FIELD_NAMES.hiddenInvestorAppFilter],
      [RULE_FILTER_FIELD_NAMES.applyToInvestorActivity]:
        values[RULE_FILTER_FIELD_NAMES.applyToInvestorActivity],
      [RULE_FILTER_FIELD_NAMES.applyToInvestorHome]:
        values[RULE_FILTER_FIELD_NAMES.applyToInvestorHome],
      [RULE_FILTER_FIELD_NAMES.applyToInvestorInvestments]:
        values[RULE_FILTER_FIELD_NAMES.applyToInvestorInvestments]
    }

    try {
      const data = props.create
        ? await api.request(RuleFilterEndpoints.create(ruleFilterRequestBody))
        : await api.request(
            RuleFilterEndpoints.partialUpdate(
              extractIdFromUrl(props.ruleFilter.url),
              ruleFilterRequestBody
            )
          )

      showSnackbar({
        variant: 'success',
        message: `Rule filter has been successfully ${
          props.create ? 'created' : 'updated'
        }.`
      })

      props.onSave(data)
    } catch (error) {
      handleUnexpectedError(error, 'An unexpected error occured.')
    }
  }

  return (
    <LoadingContainer loading={filterCriteria.loading}>
      {filterCriteria.data && (
        <Formik<IRuleFilterFormValues>
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({handleSubmit, isSubmitting, values}) => (
            <form onSubmit={handleSubmit} style={{display: 'contents'}}>
              <ControlStateProvider loading={isSubmitting}>
                <ModalContent>
                  <OutlinedInputField
                    label='Rule Filter Name'
                    name={`${RULE_FILTER_FIELD_NAMES.filter}.${FILTER_FIELD_NAMES.name}`}
                  />
                  <FilterEditorContainer
                    name={RULE_FILTER_FIELD_NAMES.filter}
                    types={DEFAULT_FILTER_CRITERION_NODES}
                  />
                  {permissions.isAdministrator() && (
                    <Flex column>
                      <CheckboxField
                        name={
                          RULE_FILTER_FIELD_NAMES.isAvailableToExternalProfiles
                        }
                        label='Share with investors in the investor portal'
                      />
                      {values.isAvailableToExternalProfiles && (
                        <>
                          <CheckboxField
                            name={
                              RULE_FILTER_FIELD_NAMES.requiredInvestorAppFilter
                            }
                            label='Required investor portal filter'
                          />
                          <CheckboxField
                            name={
                              RULE_FILTER_FIELD_NAMES.hiddenInvestorAppFilter
                            }
                            label='Hidden investor portal filter'
                          />
                          <CheckboxField
                            name={RULE_FILTER_FIELD_NAMES.applyToInvestorHome}
                            label='Apply to home page'
                          />
                          <CheckboxField
                            name={
                              RULE_FILTER_FIELD_NAMES.applyToInvestorInvestments
                            }
                            label='Apply to investments page'
                          />
                          <CheckboxField
                            name={
                              RULE_FILTER_FIELD_NAMES.applyToInvestorActivity
                            }
                            label='Apply to activity page'
                          />
                        </>
                      )}
                    </Flex>
                  )}
                </ModalContent>
                <ModalActions>
                  <FormActions
                    formType={props.create ? 'create' : 'edit'}
                    showFormErrorCount
                    onCancel={props.onCancel}
                  />
                </ModalActions>
              </ControlStateProvider>
            </form>
          )}
        </Formik>
      )}
    </LoadingContainer>
  )
}
