import React, {useEffect, useMemo, useRef, useState} from 'react'

import {isNil} from 'lodash'
import pluralize from 'pluralize'

import CircularProgress from '@material-ui/core/CircularProgress'

import {ALL_MODELS_OPTIONS} from '@d1g1t/api/models'

import {useIsFirstRender, usePrevious} from '@d1g1t/lib/hooks'

import {Autocomplete} from '@d1g1t/shared/components/mui/autocomplete'
import {TextField} from '@d1g1t/shared/components/mui/text-field'
import {H4} from '@d1g1t/shared/components/typography'
import {ISearchResult} from '@d1g1t/shared/containers/search'

import {MULTIPLE_VALUES} from '../constants'
import {useFormFieldControl} from '../hook'
import {getFormFieldErrorState} from '../lib'
import {IFormFieldProps} from '../typings'
import {useSearch} from './hooks'
import {ISearchInputFieldProps} from './typings'

import * as css from './styles.scss'

/**
 * `formik`-compatible `SearchInput` autocomplete field
 */
export const SearchInputField: React.FC<ISearchInputFieldProps> = React.memo(
  (props) => {
    const {name, label} = props
    const [{onChange, value, ...field}, meta] = useFormFieldControl(
      props as IFormFieldProps
    )
    const {hasError, errorText} = getFormFieldErrorState(meta)
    const helperText = hasError ? errorText : props.helperText || ' '

    const id = props.id || props.name

    const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

    const [filters, setFilters] = useState({})

    useEffect(() => {
      // if user clicks on end adornmen icon that opens modal
      // we have to specifically close the autocomplete dropdown
      // as it does not treat it as click away
      if (props.endAdornmentModalIsOpen) {
        setDropdownIsOpen(false)
      }
    }, [props.endAdornmentModalIsOpen])

    const valueIsOptionLabel = props.valueType === 'optionLabel'

    const valueIsEntityId = props.valueType === 'entityId'

    const search = useSearch({
      initialValue: valueIsOptionLabel ? value : undefined,
      valueUrl: valueIsOptionLabel ? undefined : value,
      models: props.searchProps?.models,
      entityIds: props.searchProps?.entityIds || undefined,
      filterBy: props.searchProps?.filterBy ?? undefined,
      isTradable: props.searchProps?.isTradable ?? undefined,
      groupByModelName: props.searchProps?.groupByModelName,
      filters
    })

    useEffect(() => {
      if (props.refreshToken) {
        search.refetch()
      }
    }, [props.refreshToken])

    const CustomPaperComponent = useMemo(
      () =>
        props.paperComponent
          ? (paperProps) =>
              props.paperComponent({
                ...paperProps,
                onChange: setFilters
              })
          : undefined,
      []
    )

    const isMultipleValues = value === MULTIPLE_VALUES

    const getOptionLabel = (searchResult: ISearchResult) => {
      if (isMultipleValues) {
        return MULTIPLE_VALUES
      }

      if (searchResult) {
        let label =
          searchResult.printName ||
          searchResult.displayText ||
          searchResult.name

        if (props.getOptionLabelOverride) {
          label = props.getOptionLabelOverride(searchResult)
        }

        if (isNil(label)) {
          throw new Error("Search result api can't provide label data")
        }

        return label
      }

      return ''
    }

    const selectedOption = useMemo(() => {
      if (value) {
        return (
          search.options?.find((option) => {
            if (valueIsOptionLabel) {
              return [
                option.printName,
                option.displayText,
                option.name,
                getOptionLabel(option)
              ].includes(value)
            }

            if (valueIsEntityId) {
              return option.entityId === value
            }

            return option.url === value
          }) || null
        )
      }

      return null
    }, [value, search.options])

    // Added for when the search input field initially loads or when its's value change it will populate the input field.
    // On initial load onInputChange is fired with reason === 'reset'
    // We needed to skip this and manually reset via the renderInput > InputProps onBlur.
    // The reason to skip the reset is due to after every keystroke onInputChange will be
    // fired again with reason === 'reset' and back to initial value.
    const inputWasInitialized = useRef(false)
    const previousValue = usePrevious(value)
    const isFirstRender = useIsFirstRender()
    useEffect(() => {
      if (value !== previousValue) {
        inputWasInitialized.current = false

        /**
         * Triggers a new list search.
         * This is necessary for when a new item is added to the list
         * ie: when a custom model portfolio is created using the static menu item
         */
        if (!isFirstRender) {
          search.refetch()
        }
      }

      if (inputWasInitialized.current) {
        return
      }

      if (!value) {
        search.onInputValueChange('')
        inputWasInitialized.current = true
        return
      }

      if (selectedOption) {
        const valueLabel = getOptionLabel(selectedOption)
        if (valueLabel !== search.inputValue) {
          search.onInputValueChange(valueLabel)
          inputWasInitialized.current = true
        }
      }
    }, [value, selectedOption])

    return (
      <Autocomplete
        open={dropdownIsOpen}
        openOnFocus
        id={id}
        size={props.size}
        style={props.style}
        loading={search.loading || props.loading}
        disabled={props.disabled}
        options={search.options}
        staticMenuItems={props.staticMenuItems}
        value={selectedOption}
        renderOption={props.renderOption}
        getOptionLabel={getOptionLabel}
        onOpen={() => setDropdownIsOpen(true)}
        onClose={(event, reason) => {
          const tagName = (event.target as HTMLElement).tagName
          const isNestedMuiPopover = tagName === 'INPUT'
          // Fixes issue where auto complete closing when clicking on the select dropdown
          // But does not collapse on clickaway
          if (isNestedMuiPopover && props.paperComponent) {
            return
          }
          if (
            (event as unknown as React.KeyboardEvent).key !== 'Escape' &&
            reason !== 'select-option' &&
            !search.inputValue &&
            value !== search.inputValue &&
            value &&
            dropdownIsOpen
          ) {
            ;(onChange as ISearchInputFieldProps['onChange'])?.(
              {
                ...event,
                target: {
                  name,
                  value: null
                }
              },
              null
            )
          }
          search.onInputValueChange(getOptionLabel(selectedOption))
          setDropdownIsOpen(false)
        }}
        onInputChange={(e, value, reason) => {
          // Autocomplete input value gets reset when the dropdown appears
          // https://github.com/mui-org/material-ui/issues/20939
          if (reason === 'reset') {
            return
          }
          search.onInputValueChange(value)
        }}
        inputValue={
          isMultipleValues ? '{Multiple Values}' : search.inputValue || ''
        }
        groupBy={
          props.searchProps?.groupByModelName
            ? (option: ISearchResult) => option.modelName
            : undefined
        }
        PaperComponent={CustomPaperComponent}
        renderGroup={
          props.searchProps?.groupByModelName
            ? (params) => (
                <div key={`${props.name || props.id}-group-key-${params.key}`}>
                  <H4 noMargin className={css.groupLabel}>
                    {pluralize(
                      ALL_MODELS_OPTIONS.find(
                        (model) => model.value === params.group
                      ).label
                    ).toUpperCase()}
                  </H4>
                  {params.children}
                </div>
              )
            : undefined
        }
        {...field}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            search.onInputValueChange(getOptionLabel(selectedOption))
          }
        }}
        onChange={(event, option: ISearchResult, reason: string, details) => {
          // remove value on clear
          if (reason === 'clear') {
            search.onInputValueChange('')
            // when not in focus, it is safe to save the clear action
            if (!dropdownIsOpen) {
              search.onInputValueChange(getOptionLabel(selectedOption))
              ;(onChange as ISearchInputFieldProps['onChange'])?.(
                {
                  ...event,
                  target: {
                    name,
                    value: null
                  }
                },
                null
              )
            }
          }

          if (option) {
            /**
             * We reset the input value to the current selected value so the change
             * for the new value is handled by the update cycle, be it the formik update process
             * or an external save and load process.
             * This covers the case when the update process fail and the new value selection
             * cant be confirmed. In this case the field will display the last successfully confirmed value
             */
            const selectedOptionLabel = getOptionLabel(selectedOption)

            search.onInputValueChange(selectedOptionLabel)

            const value = (() => {
              if (valueIsOptionLabel) {
                getOptionLabel(option)
              }

              if (valueIsEntityId) {
                return option.entityId
              }

              return option.url
            })()

            ;(onChange as ISearchInputFieldProps['onChange'])?.(
              {
                ...event,
                target: {
                  name,
                  value
                }
              },
              option
            )
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant='outlined'
            size={props.size}
            hiddenLabel={!label}
            label={label || undefined}
            error={hasError}
            helperText={
              !props.name || props.disableHelpText ? undefined : helperText
            }
            className={props.className}
            InputProps={{
              ...params.InputProps,
              placeholder:
                props.searchProps?.placeholder || 'Search options...',
              startAdornment: props.startAdornment,
              endAdornment: (
                <>
                  {props.endAdornment}
                  {search.loading ||
                    (props.loading && (
                      <CircularProgress
                        color='inherit'
                        size={18}
                        className={css.loader}
                      />
                    ))}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            /* eslint-disable react/jsx-no-duplicate-props */
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
              style: isMultipleValues
                ? {
                    fontWeight: 'bold',
                    fontStyle: 'italic'
                  }
                : undefined
            }}
          />
        )}
      />
    )
  }
)
