import React from 'react'

import {ILinkProps, RouterLink} from '@d1g1t/shared/components/router-link'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H3, P, Text} from '@d1g1t/shared/components/typography'

import {
  ControlBoxNav,
  IControlBoxNavGroup,
  SCROLL_ANIMATION_DURATION
} from './control-box-nav'

import * as css from './styles.scss'

export interface IControlBoxMenuItem {
  value: React.ReactNode
  icon?: React.ReactNode
  link?: ILinkProps['to']
  replace?: ILinkProps['replace']
  onClick?(): void
}

interface IControlBoxProps {
  title: React.ReactNode
  navGroups?: IControlBoxNavGroup[]
  description?: React.ReactNode
  menu?: IControlBoxMenuItem[]
  saveBar?: boolean
  selectEntities?: boolean
  isLoading?: boolean
}

const ControlBox: React.FC<IControlBoxProps> = (props) => {
  /**
   * Calculate top and height relative to the height of other elements on the page
   * (nav bar, save bar, etc.)
   */
  const maxHeight: string = (() => {
    let height = 70 /* top bar */ + 70 /* top + bottom padding */

    if (props.saveBar) {
      height += 70
    }

    if (props.selectEntities) {
      height += 50
    }

    return `calc(100vh - ${height}px)`
  })()

  const top: string = (() => {
    let top = 70 /* top bar */ + 19 /* padding */

    if (props.selectEntities) {
      top += 83
    }

    return `${top}px`
  })()

  return (
    <div className={css.controlBox} style={{maxHeight, top}}>
      <H3>{props.title}</H3>
      {props.description && <P>{props.description}</P>}
      {props.navGroups && (
        <ControlBoxNav groups={props.navGroups} isLoading={props.isLoading} />
      )}

      {props.menu?.length > 0 && (
        <>
          <Spacer xxs />
          <hr className={css.divider} />
          <Spacer xxs />

          {props.menu.map((item, index) => {
            const menuItemContent = (
              <Text
                action
                className={css.menuItem}
                onClick={item.onClick}
                data-testid={
                  typeof item.value === 'string'
                    ? item.value.toLowerCase().replace(/ /g, '-')
                    : `control-box-item-${index}`
                }
              >
                {item.icon} {item.value}
              </Text>
            )

            return (
              <div key={`ControlBoxMenu${index}`}>
                {item.link ? (
                  <RouterLink to={item.link} replace={item.replace}>
                    {menuItemContent}
                  </RouterLink>
                ) : (
                  menuItemContent
                )}
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

export {ControlBox, SCROLL_ANIMATION_DURATION}
