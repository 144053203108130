import React from 'react'
import * as ReactDOM from 'react-dom'

let measureNode: HTMLElement

const createOrGetMeasureNode = () => {
  if (measureNode) {
    return measureNode
  }

  measureNode = document.createElement('div')

  measureNode.style.position = 'absolute'
  measureNode.style.visibility = 'hidden'
  measureNode.style.left = `${document.documentElement.clientWidth}px`

  document.body.appendChild(measureNode)

  return measureNode
}

const getRenderContainerNode = () => {
  const renderNode = document.createElement('div')
  renderNode.style.width = `${document.documentElement.clientWidth}px`

  return renderNode
}

export const getRenderedWidth = (
  node: React.ReactElement<any>
): Promise<number> => {
  return new Promise((resolve) => {
    const renderContainer = getRenderContainerNode()
    createOrGetMeasureNode().appendChild(renderContainer)

    ReactDOM.render(node, renderContainer, () => {
      const renderedElement = renderContainer.lastChild as HTMLElement

      resolve(renderedElement.clientWidth)

      measureNode.removeChild(renderContainer)
      ReactDOM.unmountComponentAtNode(renderContainer)
    })
  })
}
