import React, {useContext} from 'react'

import {ICategory} from '../../../typings'
import {MetricsLevel} from '../../metrics-level-select'
import {MetricsFormContext} from '../context'
import {getChildren, getSubCategory} from '../lib'
import {ExpandableMetricColumns} from './expandable-metric-columns'
import {findCategory} from './lib'
import {MetricPortfolioSearch} from './metric-portfolio-search'
import {MetricTransactionAsOfDateSelect} from './metric-transaction-as-of-date-select'
import {MetricsCustomDate} from './metrics-custom-date'
import {MetricsCustomDateRange} from './metrics-custom-date-range'
import {MetricsProperties} from './metrics-properties'

interface IMetricSelectionProps {
  category: ICategory
  level: number
}

export const MetricSelection: React.FC<IMetricSelectionProps> = (props) => {
  const {category, level} = props
  const {metricItem} = useContext(MetricsFormContext)

  let hasExpandableColumn = false

  const levelId = metricItem.path[level]
  const children = getChildren(category)
  const childCategory = findCategory(children, levelId)

  const subCategory = getSubCategory(childCategory)

  if (subCategory && subCategory.expandableColumn) {
    hasExpandableColumn = true
  }

  const isCustomPeriodProperty =
    category.name === 'Period' && metricItem.metric.includes('custom-period')
  const isCustomAsOfProperty =
    category.name === 'As Of' && metricItem.metric.includes('custom-period')

  const categoryComponent = (() => {
    if (childCategory?.displayPortfolios) {
      return <MetricPortfolioSearch />
    }

    if (childCategory?.displayTransactionAsOfDate) {
      return (
        <MetricTransactionAsOfDateSelect
          label={category ? category.name : null}
        />
      )
    }

    return (
      <MetricsLevel
        label={category ? category.name : null}
        value={levelId}
        list={children}
        level={level}
      />
    )
  })()

  return (
    <>
      {categoryComponent}
      {level === 1 && <MetricsProperties />}
      {isCustomPeriodProperty && <MetricsCustomDateRange />}
      {isCustomAsOfProperty && <MetricsCustomDate />}
      {subCategory && (
        <MetricSelection category={subCategory} level={level + 1} />
      )}
      {hasExpandableColumn && <ExpandableMetricColumns />}
    </>
  )
}
