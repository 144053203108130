import {invert} from 'lodash'
import * as Yup from 'yup'

import {
  COUNTRIES_OPTIONS,
  INSTRUMENT_CAPITAL_APPRECIATION_LEVEL_OPTIONS,
  INSTRUMENT_DEFAULT_COMMISSION_TYPE_OPTIONS,
  INSTRUMENT_DIVIDEND_LEVEL_OPTIONS,
  INSTRUMENT_GEOGRAPHY_OPTIONS,
  INSTRUMENT_INTEREST_INCOME_LEVEL_OPTIONS,
  INSTRUMENT_ISSUER_CREDIT_RATING_OPTIONS,
  INSTRUMENT_LEGAL_STRUCTURE_OPTIONS,
  INSTRUMENT_STATUS_OPTIONS,
  METRIC_S_ACTIVE,
  METRIC_S_APPLY_BUY_TAX,
  METRIC_S_APPLY_SELL_TAX,
  METRIC_S_ASSET_CLASS_EXPOSURE_VECTORS,
  METRIC_S_ASSET_CLASS_L2_EXPOSURE_VECTORS,
  METRIC_S_ASSET_CLASS_L3_EXPOSURE_VECTORS,
  METRIC_S_CALCULATED_SYMBOL,
  METRIC_S_CAPITAL_APPRECIATION_LEVEL,
  METRIC_S_COUNTRY,
  METRIC_S_COUPON_RATE,
  METRIC_S_CURRENCY_EXPOSURE_VECTORS,
  METRIC_S_DEFAULT_COMMISSION,
  METRIC_S_DEFAULT_COMMISSION_TYPE,
  METRIC_S_DISPLAY_QUANTITY,
  METRIC_S_DIVIDEND_LEVEL,
  METRIC_S_DIVIDEND_YIELD,
  METRIC_S_FIRM_PROVIDED_KEY,
  METRIC_S_FUND_CODE,
  METRIC_S_FUND_NAME,
  METRIC_S_INDUSTRY_EXPOSURE_VECTORS,
  METRIC_S_INSTRUMENT_ASSET_CATEGORY,
  METRIC_S_INSTRUMENT_ASSET_CLASS_L2_NAME,
  METRIC_S_INSTRUMENT_ASSET_CLASS_NAME,
  METRIC_S_INSTRUMENT_ASSET_SUBCATEGORY,
  METRIC_S_INSTRUMENT_CURRENCY_NAME,
  METRIC_S_INSTRUMENT_CUSIP,
  METRIC_S_INSTRUMENT_DESCRIPTION,
  METRIC_S_INSTRUMENT_FUND_MANAGER,
  METRIC_S_INSTRUMENT_FUND_MANDATE,
  METRIC_S_INSTRUMENT_FUND_STRUCTURE,
  METRIC_S_INSTRUMENT_FUND_TYPE,
  METRIC_S_INSTRUMENT_FUND_VEHICLE,
  METRIC_S_INSTRUMENT_GEOGRAPHY,
  METRIC_S_INSTRUMENT_INDUSTRY_NAME,
  METRIC_S_INSTRUMENT_LEGAL_STRUCTURE,
  METRIC_S_INSTRUMENT_NAME,
  METRIC_S_INSTRUMENT_REPORTING_NAME,
  METRIC_S_INSTRUMENT_RISK_RATING,
  METRIC_S_INSTRUMENT_RISK_RETURN,
  METRIC_S_INSTRUMENT_SECTOR,
  METRIC_S_INSTRUMENT_SEDOL,
  METRIC_S_INSTRUMENT_SUB_STRATEGY,
  METRIC_S_INSTRUMENT_TICKER_SHORT,
  METRIC_S_INSTRUMENT_TYPE,
  METRIC_S_INSTRUMENT_USER_DEFINED_1,
  METRIC_S_INSTRUMENT_USER_DEFINED_2,
  METRIC_S_INSTRUMENT_USER_DEFINED_3,
  METRIC_S_INSTRUMENT_USER_DEFINED_4,
  METRIC_S_INSTRUMENT_USER_DEFINED_5,
  METRIC_S_INTEREST_INCOME_LEVEL,
  METRIC_S_INVESTMENT_CODE,
  METRIC_S_IS_CASH_EQUIVALENT,
  METRIC_S_IS_DEFERRED_SALES_CHARGE,
  METRIC_S_IS_MARGIN,
  METRIC_S_IS_TRADABLE,
  METRIC_S_ISIN,
  METRIC_S_ISSUER,
  METRIC_S_ISSUER_CREDIT_RATING,
  METRIC_S_LIQUIDITY_SCORE,
  METRIC_S_LIQUIDITY_SCORE_NAME,
  METRIC_S_MATURITY_DATE,
  METRIC_S_MULT_DIV_INDICATOR,
  METRIC_S_NOTES,
  METRIC_S_PRIMARY_MARKET,
  METRIC_S_PRODUCT_TYPE,
  METRIC_S_PUBLIC,
  METRIC_S_REGION_EXPOSURE_VECTORS,
  METRIC_S_REGION_NAME,
  METRIC_S_RISK_SCORE,
  METRIC_S_SECONDARY_MARKET,
  METRIC_S_SECTOR_EXPOSURE_VECTORS,
  METRIC_S_STRATEGY,
  METRIC_S_STRATEGY_EXPOSURE_VECTORS,
  METRIC_S_TICKER_EXCHANGE,
  METRIC_S_TRADING_CURRENCY,
  METRIC_S_TRADING_TYPE,
  METRIC_S_UNIT_OF_MEASURE,
  METRIC_S_VALUATION_PER_POSITION
} from '@d1g1t/api/models'

import {validateSumsToHundred} from './lib'

// keys from GET /instruments/{id} response
export enum SECURITY_KEYS {
  APPLY_BUY_TAX = 'applyBuyTax',
  APPLY_SELL_TAX = 'applySellTax',
  IS_CASH_EQUIVALENT = 'isCashEquivalent',
  REPORTING_NAME = 'reportingName',
  INVESMENT_CODE = 'investmentCode',
  GEOGRAPHY = 'geography',
  INDUSTRY = 'industry',
  LEGAL_STRUCTURE = 'legalStructure',
  SECURITY_RISK_RATING = 'securityRiskRating',
  SUB_STRATEGY = 'subStrategy',
  ASSET_CLASS = 'assetClass',
  ASSET_CATEGORY = 'assetCategory',
  ASSET_SUB_CATEGORY = 'assetSubcategory',
  ASSET_CLASS_L2 = 'assetClassL2',
  CALCULATED_SYMBOL = 'calculatedSymbol',
  COUNTRY = 'country',
  COUPON_RATE = 'couponRate',
  CREDIT_RATING = 'creditRating',
  CURRENCY = 'currency',
  CUSIP = 'cusip',
  DESCRIPTION = 'description',
  DIVIDEND_YIELD = 'dividendYield',
  FIRM_PROVIDED_KEY = 'firmProvidedKey',
  FUND_CODE = 'fundCode',
  FUND_CODE_OVERRIDE = 'fundCodeOverride',
  FUND_NAME = 'fundName',
  IS_DEFERRED_SALES_CHARGE = 'isDeferredSalesCharge',
  ISIN = 'isin',
  ISSUER = 'issuer',
  ISSUER_CREDIT_RATING = 'issuerCreditRating',
  LIQUIDITY = 'liquidity',
  LIQUIDITY_SCORE = 'liquidityScore',
  MATURITY_DATE = 'maturityDate',
  NAME = 'name',
  NOTES = 'notes',
  FIRM_SECURITY_TYPE = 'firmSecurityType',
  TRADING_TYPE = 'tradingType',
  INSTRUMENT_MODEL_TYPE = 'instrumentModelType',
  PUBLIC = 'public',
  REGION = 'region',
  RISK_SCORE = 'riskScore',
  SECTOR = 'sector',
  STATUS = 'status',
  STRATEGY = 'strategy',
  TICKER = 'ticker',
  TICKER_SHORT = 'tickerShort',
  FUND_MANAGER = 'fundManager',
  FUND_MANDATE = 'fundMandate',
  FUND_STRUCTURE = 'fundStructure',
  FUND_TYPE = 'fundType',
  FUND_VEHICLE = 'fundVehicle',
  RISK_RETURN = 'riskReturn',
  SEDOL = 'sedol',
  USER_DEFINED_1 = 'userDefined1',
  USER_DEFINED_2 = 'userDefined2',
  USER_DEFINED_3 = 'userDefined3',
  USER_DEFINED_4 = 'userDefined4',
  USER_DEFINED_5 = 'userDefined5',
  IS_MARGIN = 'isMargin',
  IS_TRADABLE = 'isTradable',
  PRIMARY_MARKET = 'primaryMarket',
  SECONDARY_MARKET = 'secondaryMarket',
  TRADING_CURRENCY = 'tradingCurrency',
  EXCHANGE = 'exchange',
  COMMISSION = 'defaultCommission',
  COMMISSION_TYPE = 'defaultCommissionType',
  ASSET_CLASS_VECTORIZED = 'assetClasses',
  ASSET_CLASS_L2_VECTORIZED = 'assetClassesL2',
  ASSET_CLASS_L3_VECTORIZED = 'assetClassesL3',
  CURRENCY_VECTORIZED = 'currencies',
  STRATEGY_VECTORIZED = 'strategies',
  REGION_VECTORIZED = 'regions',
  INDUSTRY_VECTORIZED = 'industries',
  SECTOR_VECTORIZED = 'sectors',
  INTEREST_INCOME_LEVEL = 'interestIncomeLevel',
  CAPITAL_APPRECIATION_LEVEL = 'capitalAppreciationLevel',
  DIVIDEND_LEVEL = 'dividendLevel',
  VALIDATION_PER_POSITION = 'valuationPerPosition',
  UNIT_OF_MEASURE = 'unitOfMeasure',
  METRIC_S_MULT_DIV_INDICATOR = 'multDivIndicator',
  METRIC_S_DISPLAY_QUANTITY = 'displayQuantity'
}

export const EDIT_SECURITY_VALIDATION_SCHEMA = Yup.object({
  [SECURITY_KEYS.NAME]: Yup.string()
    .required()
    .label(METRIC_S_INSTRUMENT_NAME.columnTitle),

  [SECURITY_KEYS.DIVIDEND_YIELD]: Yup.number()
    .nullable()
    .label(METRIC_S_DIVIDEND_YIELD.columnTitle),

  [SECURITY_KEYS.RISK_SCORE]: Yup.number()
    .nullable()
    .label(METRIC_S_RISK_SCORE.columnTitle),

  [SECURITY_KEYS.COUPON_RATE]: Yup.number()
    .nullable()
    .label(METRIC_S_COUPON_RATE.columnTitle),

  [SECURITY_KEYS.ASSET_CLASS_VECTORIZED]: Yup.array().of(
    Yup.object({
      url: Yup.string().required().label('URL'),
      assetClass: Yup.string().required().label('Asset Class'),
      weight: Yup.number().min(0).max(1).required().label('Weight')
    }).test('sums-to-100', 'Total must add up to 100%', validateSumsToHundred)
  ),

  [SECURITY_KEYS.ASSET_CLASS_L2_VECTORIZED]: Yup.array().of(
    Yup.object({
      url: Yup.string().required().label('URL'),
      assetClassL2: Yup.string().required().label('Asset Class L2'),
      weight: Yup.number().min(0).max(1).required().label('Weight')
    }).test('sums-to-100', 'Total must add up to 100%', validateSumsToHundred)
  ),

  [SECURITY_KEYS.ASSET_CLASS_L3_VECTORIZED]: Yup.array().of(
    Yup.object({
      url: Yup.string().required().label('URL'),
      assetClassL3: Yup.string().required().label('Asset Class L3'),
      weight: Yup.number().min(0).max(1).required().label('Weight')
    }).test('sums-to-100', 'Total must add up to 100%', validateSumsToHundred)
  ),

  [SECURITY_KEYS.CURRENCY_VECTORIZED]: Yup.array().of(
    Yup.object({
      url: Yup.string().required().label('URL'),
      currency: Yup.string().required().label('Currency'),
      weight: Yup.number().min(0).max(1).required().label('Weight')
    }).test('sums-to-100', 'Total must add up to 100%', validateSumsToHundred)
  ),

  [SECURITY_KEYS.REGION_VECTORIZED]: Yup.array().of(
    Yup.object({
      url: Yup.string().required().label('URL'),
      region: Yup.string().required().label('Region'),
      weight: Yup.number().min(0).max(1).required().label('Weight')
    }).test('sums-to-100', 'Total must add up to 100%', validateSumsToHundred)
  ),

  [SECURITY_KEYS.SECTOR_VECTORIZED]: Yup.array().of(
    Yup.object({
      url: Yup.string().required().label('URL'),
      sector: Yup.string().required().label('Sector'),
      weight: Yup.number().min(0).max(1).required().label('Weight')
    }).test('sums-to-100', 'Total must add up to 100%', validateSumsToHundred)
  ),

  [SECURITY_KEYS.INDUSTRY_VECTORIZED]: Yup.array().of(
    Yup.object({
      url: Yup.string().required().label('URL'),
      industry: Yup.string().required().label('Industry'),
      weight: Yup.number().min(0).max(1).required().label('Weight')
    }).test('sums-to-100', 'Total must add up to 100%', validateSumsToHundred)
  ),

  [SECURITY_KEYS.STRATEGY_VECTORIZED]: Yup.array().of(
    Yup.object({
      url: Yup.string().required().label('URL'),
      strategy: Yup.string().required().label('Strategy'),
      weight: Yup.number().min(0).max(1).required().label('Weight')
    }).test('sums-to-100', 'Total must add up to 100%', validateSumsToHundred)
  )
})

export const CATEGORY_INSTRUMENT_KEY_MAP = {
  [SECURITY_KEYS.TICKER]: 'tickerShort',
  [SECURITY_KEYS.CREDIT_RATING]: 'issuerCreditRating'
}

export const SECURITY_KEY_TO_METRIC_SLUG_MAP: Record<SECURITY_KEYS, string> = {
  [SECURITY_KEYS.APPLY_BUY_TAX]: METRIC_S_APPLY_BUY_TAX.slug,
  [SECURITY_KEYS.APPLY_SELL_TAX]: METRIC_S_APPLY_SELL_TAX.slug,
  [SECURITY_KEYS.IS_CASH_EQUIVALENT]: METRIC_S_IS_CASH_EQUIVALENT.slug,
  [SECURITY_KEYS.REPORTING_NAME]: METRIC_S_INSTRUMENT_REPORTING_NAME.slug,
  [SECURITY_KEYS.INVESMENT_CODE]: METRIC_S_INVESTMENT_CODE.slug,
  [SECURITY_KEYS.GEOGRAPHY]: METRIC_S_INSTRUMENT_GEOGRAPHY.slug,
  [SECURITY_KEYS.INDUSTRY]: METRIC_S_INSTRUMENT_INDUSTRY_NAME.slug,
  [SECURITY_KEYS.SUB_STRATEGY]: METRIC_S_INSTRUMENT_SUB_STRATEGY.slug,
  [SECURITY_KEYS.LEGAL_STRUCTURE]: METRIC_S_INSTRUMENT_LEGAL_STRUCTURE.slug,
  [SECURITY_KEYS.SECURITY_RISK_RATING]: METRIC_S_INSTRUMENT_RISK_RATING.slug,

  [SECURITY_KEYS.TRADING_TYPE]: METRIC_S_TRADING_TYPE.slug,
  [SECURITY_KEYS.FIRM_SECURITY_TYPE]: METRIC_S_PRODUCT_TYPE.slug,
  [SECURITY_KEYS.FUND_CODE_OVERRIDE]: METRIC_S_FUND_CODE.slug,

  [SECURITY_KEYS.TICKER]: METRIC_S_INSTRUMENT_TICKER_SHORT.slug,
  [SECURITY_KEYS.CREDIT_RATING]: METRIC_S_ISSUER_CREDIT_RATING.slug,

  [SECURITY_KEYS.ASSET_CLASS]: METRIC_S_INSTRUMENT_ASSET_CLASS_NAME.slug,
  [SECURITY_KEYS.ASSET_CATEGORY]: METRIC_S_INSTRUMENT_ASSET_CATEGORY.slug,
  [SECURITY_KEYS.ASSET_SUB_CATEGORY]:
    METRIC_S_INSTRUMENT_ASSET_SUBCATEGORY.slug,
  [SECURITY_KEYS.ASSET_CLASS_VECTORIZED]:
    METRIC_S_ASSET_CLASS_EXPOSURE_VECTORS.slug,
  [SECURITY_KEYS.ASSET_CLASS_L2]: METRIC_S_INSTRUMENT_ASSET_CLASS_L2_NAME.slug,
  [SECURITY_KEYS.ASSET_CLASS_L2_VECTORIZED]:
    METRIC_S_ASSET_CLASS_L2_EXPOSURE_VECTORS.slug,
  [SECURITY_KEYS.ASSET_CLASS_L3_VECTORIZED]:
    METRIC_S_ASSET_CLASS_L3_EXPOSURE_VECTORS.slug,
  [SECURITY_KEYS.COUNTRY]: METRIC_S_COUNTRY.slug,
  [SECURITY_KEYS.COUPON_RATE]: METRIC_S_COUPON_RATE.slug,
  [SECURITY_KEYS.CURRENCY]: METRIC_S_INSTRUMENT_CURRENCY_NAME.slug,
  [SECURITY_KEYS.CURRENCY_VECTORIZED]: METRIC_S_CURRENCY_EXPOSURE_VECTORS.slug,
  [SECURITY_KEYS.CUSIP]: METRIC_S_INSTRUMENT_CUSIP.slug,
  [SECURITY_KEYS.DESCRIPTION]: METRIC_S_INSTRUMENT_DESCRIPTION.slug,
  [SECURITY_KEYS.DIVIDEND_YIELD]: METRIC_S_DIVIDEND_YIELD.slug,
  [SECURITY_KEYS.FIRM_PROVIDED_KEY]: METRIC_S_FIRM_PROVIDED_KEY.slug,
  [SECURITY_KEYS.FUND_CODE]: METRIC_S_FUND_CODE.slug,
  [SECURITY_KEYS.FUND_NAME]: METRIC_S_FUND_NAME.slug,
  [SECURITY_KEYS.INDUSTRY_VECTORIZED]: METRIC_S_INDUSTRY_EXPOSURE_VECTORS.slug,
  [SECURITY_KEYS.INSTRUMENT_MODEL_TYPE]: METRIC_S_INSTRUMENT_TYPE.slug,
  [SECURITY_KEYS.IS_DEFERRED_SALES_CHARGE]:
    METRIC_S_IS_DEFERRED_SALES_CHARGE.slug,
  [SECURITY_KEYS.ISIN]: METRIC_S_ISIN.slug,
  [SECURITY_KEYS.ISSUER]: METRIC_S_ISSUER.slug,
  [SECURITY_KEYS.ISSUER_CREDIT_RATING]: METRIC_S_ISSUER_CREDIT_RATING.slug,
  [SECURITY_KEYS.LIQUIDITY]: METRIC_S_LIQUIDITY_SCORE_NAME.slug,
  [SECURITY_KEYS.LIQUIDITY_SCORE]: METRIC_S_LIQUIDITY_SCORE.slug,
  [SECURITY_KEYS.MATURITY_DATE]: METRIC_S_MATURITY_DATE.slug,
  [SECURITY_KEYS.NAME]: METRIC_S_INSTRUMENT_NAME.slug,
  [SECURITY_KEYS.NOTES]: METRIC_S_NOTES.slug,
  [SECURITY_KEYS.PUBLIC]: METRIC_S_PUBLIC.slug,
  [SECURITY_KEYS.REGION]: METRIC_S_REGION_NAME.slug,
  [SECURITY_KEYS.REGION_VECTORIZED]: METRIC_S_REGION_EXPOSURE_VECTORS.slug,
  [SECURITY_KEYS.RISK_SCORE]: METRIC_S_RISK_SCORE.slug,
  [SECURITY_KEYS.SECTOR]: METRIC_S_INSTRUMENT_SECTOR.slug,
  [SECURITY_KEYS.SECTOR_VECTORIZED]: METRIC_S_SECTOR_EXPOSURE_VECTORS.slug,
  [SECURITY_KEYS.STATUS]: METRIC_S_ACTIVE.slug,
  [SECURITY_KEYS.STRATEGY]: METRIC_S_STRATEGY.slug,
  [SECURITY_KEYS.STRATEGY_VECTORIZED]: METRIC_S_STRATEGY_EXPOSURE_VECTORS.slug,
  [SECURITY_KEYS.TICKER_SHORT]: METRIC_S_INSTRUMENT_TICKER_SHORT.slug,
  [SECURITY_KEYS.CALCULATED_SYMBOL]: METRIC_S_CALCULATED_SYMBOL.slug,
  [SECURITY_KEYS.FUND_MANAGER]: METRIC_S_INSTRUMENT_FUND_MANAGER.slug,
  [SECURITY_KEYS.FUND_MANDATE]: METRIC_S_INSTRUMENT_FUND_MANDATE.slug,
  [SECURITY_KEYS.FUND_STRUCTURE]: METRIC_S_INSTRUMENT_FUND_STRUCTURE.slug,
  [SECURITY_KEYS.FUND_TYPE]: METRIC_S_INSTRUMENT_FUND_TYPE.slug,
  [SECURITY_KEYS.FUND_VEHICLE]: METRIC_S_INSTRUMENT_FUND_VEHICLE.slug,
  [SECURITY_KEYS.RISK_RETURN]: METRIC_S_INSTRUMENT_RISK_RETURN.slug,
  [SECURITY_KEYS.SEDOL]: METRIC_S_INSTRUMENT_SEDOL.slug,
  [SECURITY_KEYS.USER_DEFINED_1]: METRIC_S_INSTRUMENT_USER_DEFINED_1.slug,
  [SECURITY_KEYS.USER_DEFINED_2]: METRIC_S_INSTRUMENT_USER_DEFINED_2.slug,
  [SECURITY_KEYS.USER_DEFINED_3]: METRIC_S_INSTRUMENT_USER_DEFINED_3.slug,
  [SECURITY_KEYS.USER_DEFINED_4]: METRIC_S_INSTRUMENT_USER_DEFINED_4.slug,
  [SECURITY_KEYS.USER_DEFINED_5]: METRIC_S_INSTRUMENT_USER_DEFINED_5.slug,
  [SECURITY_KEYS.IS_MARGIN]: METRIC_S_IS_MARGIN.slug,
  [SECURITY_KEYS.IS_TRADABLE]: METRIC_S_IS_TRADABLE.slug,
  [SECURITY_KEYS.PRIMARY_MARKET]: METRIC_S_PRIMARY_MARKET.slug,
  [SECURITY_KEYS.SECONDARY_MARKET]: METRIC_S_SECONDARY_MARKET.slug,
  [SECURITY_KEYS.TRADING_CURRENCY]: METRIC_S_TRADING_CURRENCY.slug,
  [SECURITY_KEYS.EXCHANGE]: METRIC_S_TICKER_EXCHANGE.slug,
  [SECURITY_KEYS.COMMISSION]: METRIC_S_DEFAULT_COMMISSION.slug,
  [SECURITY_KEYS.COMMISSION_TYPE]: METRIC_S_DEFAULT_COMMISSION_TYPE.slug,
  [SECURITY_KEYS.INTEREST_INCOME_LEVEL]: METRIC_S_INTEREST_INCOME_LEVEL.slug,
  [SECURITY_KEYS.DIVIDEND_LEVEL]: METRIC_S_DIVIDEND_LEVEL.slug,
  [SECURITY_KEYS.VALIDATION_PER_POSITION]: METRIC_S_VALUATION_PER_POSITION.slug,
  [SECURITY_KEYS.CAPITAL_APPRECIATION_LEVEL]:
    METRIC_S_CAPITAL_APPRECIATION_LEVEL.slug,
  [SECURITY_KEYS.UNIT_OF_MEASURE]: METRIC_S_UNIT_OF_MEASURE.slug,
  [SECURITY_KEYS.METRIC_S_DISPLAY_QUANTITY]: METRIC_S_DISPLAY_QUANTITY.slug,
  [SECURITY_KEYS.METRIC_S_MULT_DIV_INDICATOR]: METRIC_S_MULT_DIV_INDICATOR.slug
}

export const METRIC_SLUG_TO_SECURITY_KEY_MAP = invert(
  SECURITY_KEY_TO_METRIC_SLUG_MAP
) as Record<string, SECURITY_KEYS>

export const SECURITY_KEY_PUBLIC_BOOLEAN_MAP = {
  true: 'Public',
  false: 'Private'
}

export const METRIC_INSTRUMENT_OPTIONS_MAP = {
  [SECURITY_KEYS.COUNTRY]: COUNTRIES_OPTIONS,
  [SECURITY_KEYS.STATUS]: INSTRUMENT_STATUS_OPTIONS,
  [SECURITY_KEYS.INTEREST_INCOME_LEVEL]:
    INSTRUMENT_INTEREST_INCOME_LEVEL_OPTIONS,
  [SECURITY_KEYS.DIVIDEND_LEVEL]: INSTRUMENT_DIVIDEND_LEVEL_OPTIONS,
  [SECURITY_KEYS.CAPITAL_APPRECIATION_LEVEL]:
    INSTRUMENT_CAPITAL_APPRECIATION_LEVEL_OPTIONS,
  [SECURITY_KEYS.ISSUER_CREDIT_RATING]: INSTRUMENT_ISSUER_CREDIT_RATING_OPTIONS,
  [SECURITY_KEYS.GEOGRAPHY]: INSTRUMENT_GEOGRAPHY_OPTIONS,
  [SECURITY_KEYS.LEGAL_STRUCTURE]: INSTRUMENT_LEGAL_STRUCTURE_OPTIONS,
  [SECURITY_KEYS.COMMISSION_TYPE]: INSTRUMENT_DEFAULT_COMMISSION_TYPE_OPTIONS
}

export const SECURITY_KEY_TO_METRIC_COLUMN_TITLE_MAP: Record<
  SECURITY_KEYS,
  string
> = {
  [SECURITY_KEYS.APPLY_BUY_TAX]: METRIC_S_APPLY_BUY_TAX.columnTitle,
  [SECURITY_KEYS.APPLY_SELL_TAX]: METRIC_S_APPLY_SELL_TAX.columnTitle,
  [SECURITY_KEYS.IS_CASH_EQUIVALENT]: METRIC_S_IS_CASH_EQUIVALENT.columnTitle,
  [SECURITY_KEYS.REPORTING_NAME]:
    METRIC_S_INSTRUMENT_REPORTING_NAME.columnTitle,
  [SECURITY_KEYS.INVESMENT_CODE]: METRIC_S_INVESTMENT_CODE.columnTitle,
  [SECURITY_KEYS.GEOGRAPHY]: METRIC_S_INSTRUMENT_GEOGRAPHY.columnTitle,
  [SECURITY_KEYS.INDUSTRY]: METRIC_S_INSTRUMENT_INDUSTRY_NAME.columnTitle,
  [SECURITY_KEYS.SUB_STRATEGY]: METRIC_S_INSTRUMENT_SUB_STRATEGY.columnTitle,
  [SECURITY_KEYS.LEGAL_STRUCTURE]:
    METRIC_S_INSTRUMENT_LEGAL_STRUCTURE.columnTitle,
  [SECURITY_KEYS.SECURITY_RISK_RATING]:
    METRIC_S_INSTRUMENT_RISK_RATING.columnTitle,

  [SECURITY_KEYS.TRADING_TYPE]: METRIC_S_TRADING_TYPE.columnTitle,
  [SECURITY_KEYS.FIRM_SECURITY_TYPE]: METRIC_S_PRODUCT_TYPE.columnTitle,
  [SECURITY_KEYS.FUND_CODE_OVERRIDE]: METRIC_S_FUND_CODE.columnTitle,

  [SECURITY_KEYS.TICKER]: METRIC_S_INSTRUMENT_TICKER_SHORT.columnTitle,
  [SECURITY_KEYS.CREDIT_RATING]: METRIC_S_ISSUER_CREDIT_RATING.columnTitle,

  [SECURITY_KEYS.ASSET_CLASS]: METRIC_S_INSTRUMENT_ASSET_CLASS_NAME.columnTitle,
  [SECURITY_KEYS.ASSET_CATEGORY]:
    METRIC_S_INSTRUMENT_ASSET_CATEGORY.columnTitle,
  [SECURITY_KEYS.ASSET_SUB_CATEGORY]:
    METRIC_S_INSTRUMENT_ASSET_SUBCATEGORY.columnTitle,

  [SECURITY_KEYS.ASSET_CLASS_VECTORIZED]:
    METRIC_S_ASSET_CLASS_EXPOSURE_VECTORS.columnTitle,
  [SECURITY_KEYS.ASSET_CLASS_L2]:
    METRIC_S_INSTRUMENT_ASSET_CLASS_L2_NAME.columnTitle,
  [SECURITY_KEYS.ASSET_CLASS_L2_VECTORIZED]:
    METRIC_S_ASSET_CLASS_L2_EXPOSURE_VECTORS.columnTitle,
  [SECURITY_KEYS.ASSET_CLASS_L3_VECTORIZED]:
    METRIC_S_ASSET_CLASS_L3_EXPOSURE_VECTORS.columnTitle,
  [SECURITY_KEYS.COUNTRY]: METRIC_S_COUNTRY.columnTitle,
  [SECURITY_KEYS.COUPON_RATE]: METRIC_S_COUPON_RATE.columnTitle,
  [SECURITY_KEYS.CURRENCY]: METRIC_S_INSTRUMENT_CURRENCY_NAME.columnTitle,
  [SECURITY_KEYS.CURRENCY_VECTORIZED]:
    METRIC_S_CURRENCY_EXPOSURE_VECTORS.columnTitle,
  [SECURITY_KEYS.CUSIP]: METRIC_S_INSTRUMENT_CUSIP.columnTitle,
  [SECURITY_KEYS.DESCRIPTION]: METRIC_S_INSTRUMENT_DESCRIPTION.columnTitle,
  [SECURITY_KEYS.DIVIDEND_YIELD]: METRIC_S_DIVIDEND_YIELD.columnTitle,
  [SECURITY_KEYS.FIRM_PROVIDED_KEY]: METRIC_S_FIRM_PROVIDED_KEY.columnTitle,
  [SECURITY_KEYS.FUND_CODE]: METRIC_S_FUND_CODE.columnTitle,
  [SECURITY_KEYS.FUND_NAME]: METRIC_S_FUND_NAME.columnTitle,
  [SECURITY_KEYS.INDUSTRY_VECTORIZED]:
    METRIC_S_INDUSTRY_EXPOSURE_VECTORS.columnTitle,
  [SECURITY_KEYS.INSTRUMENT_MODEL_TYPE]: METRIC_S_INSTRUMENT_TYPE.columnTitle,
  [SECURITY_KEYS.IS_DEFERRED_SALES_CHARGE]:
    METRIC_S_IS_DEFERRED_SALES_CHARGE.columnTitle,
  [SECURITY_KEYS.ISIN]: METRIC_S_ISIN.columnTitle,
  [SECURITY_KEYS.ISSUER]: METRIC_S_ISSUER.columnTitle,
  [SECURITY_KEYS.ISSUER_CREDIT_RATING]:
    METRIC_S_ISSUER_CREDIT_RATING.columnTitle,
  [SECURITY_KEYS.LIQUIDITY]: METRIC_S_LIQUIDITY_SCORE_NAME.columnTitle,
  [SECURITY_KEYS.LIQUIDITY_SCORE]: METRIC_S_LIQUIDITY_SCORE.columnTitle,
  [SECURITY_KEYS.MATURITY_DATE]: METRIC_S_MATURITY_DATE.columnTitle,
  [SECURITY_KEYS.NAME]: METRIC_S_INSTRUMENT_NAME.columnTitle,
  [SECURITY_KEYS.NOTES]: METRIC_S_NOTES.columnTitle,
  [SECURITY_KEYS.PUBLIC]: METRIC_S_PUBLIC.columnTitle,
  [SECURITY_KEYS.REGION]: METRIC_S_REGION_NAME.columnTitle,
  [SECURITY_KEYS.REGION_VECTORIZED]:
    METRIC_S_REGION_EXPOSURE_VECTORS.columnTitle,
  [SECURITY_KEYS.RISK_SCORE]: METRIC_S_RISK_SCORE.columnTitle,
  [SECURITY_KEYS.SECTOR]: METRIC_S_INSTRUMENT_SECTOR.columnTitle,
  [SECURITY_KEYS.SECTOR_VECTORIZED]:
    METRIC_S_SECTOR_EXPOSURE_VECTORS.columnTitle,
  [SECURITY_KEYS.STATUS]: METRIC_S_ACTIVE.columnTitle,
  [SECURITY_KEYS.STRATEGY]: METRIC_S_STRATEGY.columnTitle,
  [SECURITY_KEYS.STRATEGY_VECTORIZED]:
    METRIC_S_STRATEGY_EXPOSURE_VECTORS.columnTitle,
  [SECURITY_KEYS.TICKER_SHORT]: METRIC_S_INSTRUMENT_TICKER_SHORT.columnTitle,
  [SECURITY_KEYS.CALCULATED_SYMBOL]: METRIC_S_CALCULATED_SYMBOL.columnTitle,
  [SECURITY_KEYS.FUND_MANAGER]: METRIC_S_INSTRUMENT_FUND_MANAGER.columnTitle,
  [SECURITY_KEYS.FUND_MANDATE]: METRIC_S_INSTRUMENT_FUND_MANDATE.columnTitle,
  [SECURITY_KEYS.FUND_STRUCTURE]:
    METRIC_S_INSTRUMENT_FUND_STRUCTURE.columnTitle,
  [SECURITY_KEYS.FUND_TYPE]: METRIC_S_INSTRUMENT_FUND_TYPE.columnTitle,
  [SECURITY_KEYS.FUND_VEHICLE]: METRIC_S_INSTRUMENT_FUND_VEHICLE.columnTitle,
  [SECURITY_KEYS.RISK_RETURN]: METRIC_S_INSTRUMENT_RISK_RETURN.columnTitle,
  [SECURITY_KEYS.SEDOL]: METRIC_S_INSTRUMENT_SEDOL.columnTitle,
  [SECURITY_KEYS.USER_DEFINED_1]:
    METRIC_S_INSTRUMENT_USER_DEFINED_1.columnTitle,
  [SECURITY_KEYS.USER_DEFINED_2]:
    METRIC_S_INSTRUMENT_USER_DEFINED_2.columnTitle,
  [SECURITY_KEYS.USER_DEFINED_3]:
    METRIC_S_INSTRUMENT_USER_DEFINED_3.columnTitle,
  [SECURITY_KEYS.USER_DEFINED_4]:
    METRIC_S_INSTRUMENT_USER_DEFINED_4.columnTitle,
  [SECURITY_KEYS.USER_DEFINED_5]:
    METRIC_S_INSTRUMENT_USER_DEFINED_5.columnTitle,
  [SECURITY_KEYS.IS_MARGIN]: METRIC_S_IS_MARGIN.columnTitle,
  [SECURITY_KEYS.IS_TRADABLE]: METRIC_S_IS_TRADABLE.columnTitle,
  [SECURITY_KEYS.PRIMARY_MARKET]: METRIC_S_PRIMARY_MARKET.columnTitle,
  [SECURITY_KEYS.SECONDARY_MARKET]: METRIC_S_SECONDARY_MARKET.columnTitle,
  [SECURITY_KEYS.TRADING_CURRENCY]: METRIC_S_TRADING_CURRENCY.columnTitle,
  [SECURITY_KEYS.EXCHANGE]: METRIC_S_TICKER_EXCHANGE.columnTitle,
  [SECURITY_KEYS.COMMISSION]: METRIC_S_DEFAULT_COMMISSION.columnTitle,
  [SECURITY_KEYS.COMMISSION_TYPE]: METRIC_S_DEFAULT_COMMISSION_TYPE.columnTitle,
  [SECURITY_KEYS.INTEREST_INCOME_LEVEL]:
    METRIC_S_INTEREST_INCOME_LEVEL.columnTitle,
  [SECURITY_KEYS.DIVIDEND_LEVEL]: METRIC_S_DIVIDEND_LEVEL.columnTitle,
  [SECURITY_KEYS.VALIDATION_PER_POSITION]:
    METRIC_S_VALUATION_PER_POSITION.columnTitle,
  [SECURITY_KEYS.CAPITAL_APPRECIATION_LEVEL]:
    METRIC_S_CAPITAL_APPRECIATION_LEVEL.columnTitle,
  [SECURITY_KEYS.UNIT_OF_MEASURE]: METRIC_S_UNIT_OF_MEASURE.columnTitle,
  [SECURITY_KEYS.METRIC_S_DISPLAY_QUANTITY]:
    METRIC_S_DISPLAY_QUANTITY.columnTitle,
  [SECURITY_KEYS.METRIC_S_MULT_DIV_INDICATOR]:
    METRIC_S_MULT_DIV_INDICATOR.columnTitle
}

export enum SCALING_FACTORS {
  MULTIPLY = 'Multiply',
  DIVIDE = 'Divide'
}
export const SCALING_FACTOR_BOOLEAN_MAP = {
  true: SCALING_FACTORS.DIVIDE,
  false: SCALING_FACTORS.MULTIPLY
}
export const SCALING_FACTOR_VALUES_MAP = {
  [SCALING_FACTORS.DIVIDE]: true,
  [SCALING_FACTORS.MULTIPLY]: false
}
export const SCALING_FACTOR_OPERATIONS: IValueLabelOption[] = [
  {
    value: SCALING_FACTORS.MULTIPLY,
    label: SCALING_FACTORS.MULTIPLY
  },
  {
    value: SCALING_FACTORS.DIVIDE,
    label: SCALING_FACTORS.DIVIDE
  }
]
