import React from 'react'

import {Flex} from '@d1g1t/shared/components/flex'
import {Text} from '@d1g1t/shared/components/typography'

export const OrderableListItemText = function OrderableListItemText(props) {
  return (
    <Flex alignCenter grow fullHeight>
      <Text indent style={{width: '100%'}}>
        {props.children}
      </Text>
    </Flex>
  )
}
