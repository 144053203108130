import React, {useContext} from 'react'

import {isEmpty} from 'lodash'

import {Label} from '@d1g1t/shared/components/typography'
import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'

import {ICategory} from '../../typings'
import {MetricsFormContext} from '../metrics-form/context'

export interface IMetricsFormProps {
  label: string
  value: string
  level: number
  list: ICategory[]
  disabled?: boolean
}

export const MetricsLevel: React.FC<IMetricsFormProps> = (props) => {
  const {onLevelChange} = useContext(MetricsFormContext)

  const handleChange = (categoryId: string) => {
    onLevelChange(
      props.level,
      props.list.find(({id}) => id === categoryId)
    )
  }

  if (isEmpty(props.list)) {
    return null
  }

  return (
    <>
      <Label>{props.label}</Label>
      <ValueLabelSelect
        size='small'
        fullWidth
        mutedBorder
        disabled={props.disabled}
        value={props.value}
        options={props.list.map((category) => ({
          value: category.id,
          label: category.name
        }))}
        onChange={handleChange}
      />
    </>
  )
}
