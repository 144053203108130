import React, {useCallback, useMemo, useState} from 'react'

import {format, subMonths} from 'date-fns'
import {useApi} from 'fairlight'
import produce from 'immer'

import {IMetricResponse, MetricEndpoints} from '@d1g1t/api/endpoints'
import {
  IViewMetricItem,
  VIEWMETRICITEM_TRANSACTION_AS_OF_DATE
} from '@d1g1t/api/models'

import {ISO_DATE_FORMAT} from '@d1g1t/lib/constants'
import {useDomEventCallback} from '@d1g1t/lib/hooks'
import {createKeyboardModiferMask, MODIFIER_FLAGS} from '@d1g1t/lib/keyboard'
import {
  CONDITION_RESULTS_KEY,
  CONTRIBUTION_DIMENSION_FIELD_NAME
} from '@d1g1t/lib/metrics'

import {ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {ISearchResult, Search} from '@d1g1t/shared/containers/search'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'

import {ICategory} from '../../typings'
import {MetricsFormContextProvider} from './context'
import {
  categoryAtPath,
  cleanNoneValuesForCondtionResults,
  conditionResultValueFromUrls,
  defaultMetricItem,
  findConditionResultsKey,
  getRelationalAndTransactionAsOfDateMetricSlugs,
  isConditionResultsEnabled,
  searchableMetrics,
  updatedPathWithDefaults
} from './lib'
import {MetricSelection} from './metric-selection'
import {ContributionDimensionKey} from './typings'

export interface IMetricsFormProps extends Omit<IMetricResponse, 'available'> {
  id: number
  available: ICategory
  value: IViewMetricItem
  onCancel(): void
  onApply(value: IViewMetricItem, indexToUpdate: number): void
}

export const MetricsForm: React.FC<IMetricsFormProps> = (props) => {
  const api = useApi()
  const {firmConfiguration} = useFirmConfiguration()

  const contributionDimensionOptions = {
    assetClassOptions: props.assetClassOptions,
    currencyOptions: props.currencyOptions,
    strategyOptions: props.strategyOptions
  }

  const contributionDimensions = useMemo(() => {
    const supportedValues = Object.values(CONTRIBUTION_DIMENSION_FIELD_NAME)
    return props.contributionDimensions.filter((contributionDimension) => {
      return supportedValues.includes(
        contributionDimension.fieldName as CONTRIBUTION_DIMENSION_FIELD_NAME
      )
    })
  }, [props.contributionDimensions])

  // TODO: refactor the following pieces of state to `useReducer` with actions
  const [conditionResults1, setConditionResults1] = useState(() =>
    isConditionResultsEnabled(
      props.value,
      contributionDimensions,
      'contributionDimension',
      contributionDimensionOptions
    )
  )
  const [conditionResults2, setConditionResults2] = useState(() =>
    isConditionResultsEnabled(
      props.value,
      contributionDimensions,
      'contributionDimension2',
      contributionDimensionOptions
    )
  )
  const [expandableColumn, setExpandableColumn] = useState(
    () => !!props.value.contributionDimension
  )
  const [metricItem, setMetricItem] = useState(() =>
    defaultMetricItem(props.value, props.available)
  )
  const [relatedEntity, setRelatedEntity] = useState<ISearchResult>()

  const metricSearchResults = useMemo(() => {
    return searchableMetrics(props.available)
  }, [props.available])

  const [relationalMeticSlugs, transactionAsOfDateSlugs] = useMemo(() => {
    return getRelationalAndTransactionAsOfDateMetricSlugs(props.available)
  }, [props.available])

  const setMetricItemProperties = (properties: Partial<IViewMetricItem>) => {
    setMetricItem((prev) => ({
      ...prev,
      ...properties
    }))
  }

  // Callbacks

  const handleExpandableColumnCheck = (isInputChecked: boolean) => {
    if (!isInputChecked) {
      setMetricItemProperties({
        contributionDimension: null,
        contributionDimension2: null,
        assetClasses: [],
        currencies: [],
        strategies: []
      })
      setExpandableColumn(false)

      return
    }

    setExpandableColumn(true)
  }

  const handleConditionResultsCheck =
    (
      contributionDimensionKey: ContributionDimensionKey,
      setConditionResults: (value: React.SetStateAction<boolean>) => void
    ) =>
    (value: boolean) => {
      if (!value) {
        const conditionResultsKey = findConditionResultsKey(
          contributionDimensions,
          metricItem[contributionDimensionKey]
        )

        setMetricItemProperties({[conditionResultsKey]: []})
      }

      setConditionResults(value)
    }

  const handleConditionResultsOptionCheck =
    (url: string, selectedUrls: string[], key: CONDITION_RESULTS_KEY) => () => {
      const selectedUrlsSet = new Set(selectedUrls)
      if (selectedUrlsSet.has(url)) {
        selectedUrlsSet.delete(url)
      } else {
        selectedUrlsSet.add(url)
      }

      const conditionResultsValue = conditionResultValueFromUrls(
        Array.from(selectedUrlsSet),
        key
      )

      setMetricItemProperties({
        [key]: conditionResultsValue
      })
    }

  const setNextMetricItem = (path: string[], value: ICategory) => {
    const metric: ICategory = value.metric
      ? value
      : categoryAtPath(props.available, path)

    setMetricItem(
      produce(metricItem, (draft) => {
        draft.path = path
        draft.displayName = metric.columnTitle || ''
        draft.metric = metric.metric

        if (metric.metric.includes('custom-period')) {
          // Default date range is 1-month from latest available date
          draft.dateRange = {
            startDate: format(
              subMonths(
                new Date(firmConfiguration.data.latestDataAvailable),
                1
              ),
              ISO_DATE_FORMAT
            ),
            endDate: firmConfiguration.data.latestDataAvailable
          }
        } else {
          draft.dateRange = {startDate: null, endDate: null}
        }
      })
    )
  }

  const handleDisplayTotalChange =
    (key: 'displayTotal1' | 'displayTotal2') => (value: boolean) =>
      setMetricItemProperties({[key]: value})

  const handleSearchResultSelect = ({
    data: {metric, category}
  }: ISearchResult<{metric: ICategory; category: ICategory}>) => {
    if (metricItem.path[1] === metric.id) {
      return
    }

    const nextPath = updatedPathWithDefaults(1, metric, metricItem.path)
    nextPath[0] = category.id

    setNextMetricItem(nextPath, metric)
  }

  const handleLevelChange = (level: number, value: ICategory) => {
    const path = updatedPathWithDefaults(level, value, metricItem.path)

    setNextMetricItem(path, value)
  }

  const handlecontributionDimensionChange = (value) => {
    setMetricItemProperties({
      contributionDimension: value,
      contributionDimension2: null,
      assetClasses: [],
      currencies: [],
      strategies: []
    })
  }

  const handleContributionDimension2Change = (value) => {
    const nextContributionResultsKey = findConditionResultsKey(
      contributionDimensions,
      value
    )

    const changes = {
      contributionDimension2: value,
      [nextContributionResultsKey]: []
    }

    if (metricItem.contributionDimension2) {
      const prevContributionResultsKey = findConditionResultsKey(
        contributionDimensions,
        metricItem.contributionDimension2
      )
      changes[prevContributionResultsKey] = []
    }

    setMetricItemProperties(changes)
  }

  const handleUpdateDisplayName = (value: string) => {
    setMetricItemProperties({
      displayName: value
    })
  }

  const handleUpdateStartDate = (date: string) => {
    setMetricItemProperties({
      dateRange: {
        startDate: date,
        endDate: metricItem.dateRange.endDate
      }
    })
  }

  const handleUpdateEndDate = (date: string) => {
    setMetricItemProperties({
      dateRange: {
        endDate: date,
        startDate: metricItem.dateRange.startDate
      }
    })
  }

  const handleUpdateDateRange = (startDate: string, endDate: string) => {
    setMetricItemProperties({
      dateRange: {
        startDate,
        endDate
      }
    })
  }

  const handleRelatedEntityChange = (searchResult: ISearchResult) => {
    setRelatedEntity(searchResult)
    setMetricItemProperties({
      entityId: searchResult && searchResult.entityId
    })
  }

  const handleTransactionAsOfDateChange = (
    transactionAsOfDate: VIEWMETRICITEM_TRANSACTION_AS_OF_DATE
  ) => {
    setMetricItemProperties({
      transactionAsOfDate
    })
  }

  const isValid = (() => {
    if (relationalMeticSlugs.has(metricItem.metric)) {
      return !!metricItem.entityId
    }

    return true
  })()

  const handleApply = useCallback(() => {
    // Prevent saving when form is invalid
    if (!isValid) {
      return
    }

    props.onApply(
      produce(metricItem, (draft) => {
        draft.assetClasses =
          cleanNoneValuesForCondtionResults<CONDITION_RESULTS_KEY.ASSET_CLASSES>(
            metricItem.assetClasses
          )
        draft.currencies =
          cleanNoneValuesForCondtionResults<CONDITION_RESULTS_KEY.CURRENCIES>(
            metricItem.currencies
          )
        draft.strategies =
          cleanNoneValuesForCondtionResults<CONDITION_RESULTS_KEY.STRATEGIES>(
            metricItem.strategies
          )
        draft.metric = api.buildUrl(
          MetricEndpoints.pathToResource(metricItem.metric)
        )

        // Remove entityId when it isn't required
        if (!relationalMeticSlugs.has(metricItem.metric)) {
          draft.entityId = null
        }

        // Remove transactionAsOfDate when it isn't required
        if (!transactionAsOfDateSlugs.has(metricItem.metric)) {
          draft.transactionAsOfDate = null
        }
      }),
      props.id
    )
  }, [metricItem, props.id, props.onApply])

  useDomEventCallback(window, 'keydown', (event) => {
    const mask = createKeyboardModiferMask(event)

    if (event.code === 'Enter' && mask === MODIFIER_FLAGS.meta) {
      handleApply()
    }
  })

  if (!firmConfiguration.data) {
    return null
  }

  return (
    <MetricsFormContextProvider
      value={{
        metricItem,
        displayTotalWhenExpanded1: metricItem.displayTotal1,
        displayTotalWhenExpanded2: metricItem.displayTotal2,
        onDisplayTotalWhenExpanded1Change:
          handleDisplayTotalChange('displayTotal1'),
        onDisplayTotalWhenExpanded2Change:
          handleDisplayTotalChange('displayTotal2'),
        conditionResults1,
        conditionResults2,
        expandableColumn,
        relatedEntity,
        setRelatedEntity,
        contributionDimensionOptions,
        contributionDimensions,
        latestAvailableData:
          firmConfiguration.data && firmConfiguration.data.latestDataAvailable,
        onLevelChange: handleLevelChange,
        onDisplayNameChange: handleUpdateDisplayName,
        oncontributionDimensionChange: handlecontributionDimensionChange,
        onContributionDimension2Change: handleContributionDimension2Change,
        onShowExpandableColumnChange: handleExpandableColumnCheck,
        onShowConditionResults1Change: handleConditionResultsCheck(
          'contributionDimension',
          setConditionResults1
        ),
        onShowConditionResults2Change: handleConditionResultsCheck(
          'contributionDimension2',
          setConditionResults2
        ),
        onStartDateChange: handleUpdateStartDate,
        onEndDateChange: handleUpdateEndDate,
        onDateRangeChange: handleUpdateDateRange,
        onRelatedEntityChange: handleRelatedEntityChange,
        onTransactionAsOfDateChange: handleTransactionAsOfDateChange,
        createOnConditionOptionCheck: handleConditionResultsOptionCheck
      }}
    >
      <ModalContent>
        <Search
          disableGrouping
          clearOnResultSelect
          focusOnMount
          preloadedData={metricSearchResults}
          placeholder='Search for Metrics'
          onResultSelect={handleSearchResultSelect}
          data-testid='search-metrics'
        />
        <MetricSelection category={props.available} level={0} />
      </ModalContent>
      <ModalActions>
        <Button onClick={props.onCancel} data-testid='button-cancel'>
          Cancel
        </Button>
        <Spacer vertical xxs />
        <Button
          primary
          contained
          disabled={!isValid}
          onClick={handleApply}
          data-testid='button-apply'
        >
          Apply
        </Button>
      </ModalActions>
    </MetricsFormContextProvider>
  )
}
