import React from 'react'

import {useField} from 'formik'

/**
 * `TRADEORDER_COMMISSION_TYPE_OPTIONS` has an extra option that is called `Multiple Values`
 * so we use `TRADINGAPPROVALSINSTRUMENTINPUT_COMMISSION_TYPE_OPTIONS` that has the same set of
 * options excluding the `Multiple Values` one
 */
import {COMMISSION_TYPE, COMMISSION_TYPE_OPTIONS} from '@d1g1t/api/models'

import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {ValueLabelSelectField} from '@d1g1t/shared/components/form-field/value-select-field'
import {
  CentsInput,
  CurrencyInput,
  PercentageInput
} from '@d1g1t/shared/components/formatted-input'
import {Grid} from '@d1g1t/shared/components/mui/grid'

interface ICommissionsSectionProps {
  commissionTypeFormName: string
  commissionFormName: string
}

export const CommissionsSection: React.FC<ICommissionsSectionProps> = (
  props
) => {
  const [{value: commissionType}] = useField<COMMISSION_TYPE>(
    props.commissionTypeFormName
  )

  const inputComponent = (() => {
    switch (commissionType) {
      case COMMISSION_TYPE.CENTSPERSHARE:
        return CentsInput
      case COMMISSION_TYPE.AMOUNT:
        return CurrencyInput
      case COMMISSION_TYPE.OFORDER:
        return PercentageInput
      default:
        return null
    }
  })()

  return (
    <>
      <Grid item xs={2}>
        <ValueLabelSelectField
          name={props.commissionTypeFormName}
          label='Commission Type'
          valueLabelSelectProps={{
            options: COMMISSION_TYPE_OPTIONS
          }}
        />
      </Grid>
      <Grid item xs={2}>
        {!!inputComponent && (
          <OutlinedInputField
            name={props.commissionFormName}
            label='Commission'
            outlinedInputProps={{
              inputComponent
            }}
          />
        )}
      </Grid>
    </>
  )
}
