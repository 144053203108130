import React, {useMemo} from 'react'

import {useApiQuery} from 'fairlight'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import HelpIcon from '@material-ui/icons/Help'
import SettingsIcon from '@material-ui/icons/Settings'

import {SystemStatusEndpoints} from '@d1g1t/api/endpoints'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {Flex} from '@d1g1t/shared/components/flex'
import {D1G1TLogo} from '@d1g1t/shared/components/logo'
import {Alert} from '@d1g1t/shared/components/mui/alert'
import {Badge} from '@d1g1t/shared/components/mui/badge'
import {
  IconMenu,
  IIconMenuMenuItemProps
} from '@d1g1t/shared/components/mui/icon-menu'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {P, Text} from '@d1g1t/shared/components/typography'
import {UserAvatar} from '@d1g1t/shared/components/user-avatar'
import {AuthenticationLocations} from '@d1g1t/shared/locations'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {useUserProfile} from '@d1g1t/shared/wrappers/user-profile'

import {SecondaryMenuSearchBar} from '@d1g1t/advisor/containers/secondary-menu-search-bar'
import {AdministrationLocations} from '@d1g1t/advisor/locations'

import * as css from './style.scss'

export const SecondaryMenu: React.FC = () => {
  const {firmConfiguration, firmUiConfig} = useFirmConfiguration()
  const [userProfile] = useUserProfile()

  const [version] = useApiQuery(SystemStatusEndpoints.systemStatus(), {
    fetchPolicy: 'cache-first'
  })

  const menuOptions: IIconMenuMenuItemProps[] = useMemo(() => {
    const options: IIconMenuMenuItemProps[] = []

    if (userProfile?.user) {
      options.push({
        label: 'User Settings',
        icon: <SettingsIcon />,
        link: AdministrationLocations.userSettings(
          extractIdFromUrl(userProfile.user.profile)
        ),
        testid: 'menu-item-user-menu-settings'
      })
    }
    options.push({
      label: 'Support',
      icon: <HelpIcon />,
      onClick: () => window.open('https://support.d1g1t.com/', '_blank'),
      testid: 'menu-item-support-link'
    })
    options.push({
      label: 'Log Out',
      icon: <LogoutIcon />,
      link: AuthenticationLocations.logout(),
      testid: 'menu-item-user-menu-logout'
    })

    return options
  }, [userProfile])

  const initialsIconMenu = (() => {
    const iconMenu = (
      <IconMenu
        options={menuOptions}
        icon={<UserAvatar noRightMargin />}
        firstOptionNode={<UserAvatar menuItem />}
        MenuProps={{
          MenuListProps: {
            style: {
              width: '270px',
              minWidth: '270px'
            }
          }
        }}
      />
    )
    if (firmUiConfig.data?.advisor.showApiTagVersion && version.data) {
      return (
        <Badge
          badgeContent={
            <P light noMargin noTopMargin noWrap normalWeight fontSize={12}>
              v{version.data.tag}
            </P>
          }
          overlap='circular'
          color='secondary'
          style={{
            transition: 'width 5s'
          }}
        >
          {iconMenu}
        </Badge>
      )
    }
    return iconMenu
  })()

  const tooltip = (
    <>
      <Flex row>
        <ErrorOutlineIcon className={css.errorIcon} />
        <div>
          <Text bold> {firmConfiguration.data?.bannerSummary}</Text>
          {firmConfiguration.data?.bannerMessage}
        </div>
      </Flex>
    </>
  )

  return (
    <div className={css.secondaryMenu}>
      <Flex toolbar className={css.topBar}>
        <Flex grow stretch>
          <Flex className={css.logo}>
            <D1G1TLogo />
          </Flex>
          <SecondaryMenuSearchBar />
        </Flex>
        {firmConfiguration.data?.bannerMessageEnabled &&
          firmConfiguration.data.bannerSummary && (
            <Tooltip title={tooltip}>
              <Alert severity='error' style={{background: '#f7f7f7'}}>
                {firmConfiguration.data.bannerSummary}
              </Alert>
            </Tooltip>
          )}
        <Spacer vertical xxs />
        <Flex className={css.topBarRight} stretch alignCenter justifyFlexEnd>
          {initialsIconMenu}
          {firmConfiguration.data && (
            <img
              className={css.firmLogo}
              src={
                firmConfiguration.data.subfirm?.logo ||
                firmConfiguration.data.logo
              }
            />
          )}
        </Flex>
      </Flex>
    </div>
  )
}
