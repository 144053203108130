import React from 'react'

import DeleteIcon from '@material-ui/icons/Delete'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled'

import {IAccountsProfile} from '@d1g1t/api/models'

import {ControlBox} from '@d1g1t/shared/components/control-box'
import {
  IWithPermissionsProps,
  withPermissions
} from '@d1g1t/shared/wrappers/permissions'

interface IGrayBoxComponentProps extends IGrayBoxProps, IWithPermissionsProps {}

interface IGrayBoxProps {
  profile: IAccountsProfile
  isUserSettings: boolean
  onEnable()
  onDisable()
  onDelete()
}

const GrayBoxComponent: React.FC<IGrayBoxComponentProps> = (props) => {
  const deleteUser = () => {
    props.onDelete()
  }

  const enableUser = () => {
    props.onEnable()
  }

  const disableUser = () => {
    props.onDisable()
  }

  const getMenu = () => {
    if (props.isUserSettings) {
      return []
    }

    if (!props.permissions.isAdministrator()) {
      return []
    }

    return [
      {
        value: 'Delete user',
        onClick: deleteUser,
        icon: <DeleteIcon />
      },
      props.profile.user.isActive
        ? {
            value: 'Disable user',
            onClick: disableUser,
            icon: <PersonAddDisabledIcon />
          }
        : {
            value: 'Enable user',
            onClick: enableUser,
            icon: <PersonAddIcon />
          }
    ]
  }

  if (!props.profile || !props.profile.user) {
    return null
  }

  return (
    <ControlBox
      title='What are Internal Users?'
      description='Internal users refer to the members of your company that have their own login access to the d1g1t platform.'
      menu={getMenu()}
      saveBar
    />
  )
}

export const GrayBox = withPermissions()(GrayBoxComponent)
