import {
  CHART_VALUE_TYPE,
  IChartTable,
  IChartTableOptions
} from '@d1g1t/api/models'

import {ISortColumnOptions} from '@d1g1t/shared/containers/standard-table'

/**
 * Method to convert list of objects into IChartTable format. Will only map keys that are
 * passed in via `propertiesToInclude` argument.
 *
 * @param list - List of objects to be mapped
 *
 * @param propertiesToInclude - List of properties to use that are keys of the object in the list
 * (will be mapped to `categoryId`), the corresponding category name and optional format function.
 *
 * @param itemIdPropertyOrGenerator - Name of property key in object to use to map to `item.id`,
 * OR a function that generates unique ID for each row. Useful when duplicate entries are allowed (used in Admin-Datasets).
 */
export function mapObjectListToChartTable<
  T extends Record<
    string,
    string | number | boolean | string[] | number[] | any[] | any
  >
>(
  list: T[],
  propertiesToInclude: {
    /**
     * Property key from object
     */
    propertyKey: keyof T
    /**
     * Override the property key being used as category id to some custom string
     */
    categoryId?: string
    /**
     * Title of the category/column
     */
    name: string
    /**
     * Set category options
     */
    options?: IChartTableOptions
    /**
     * Category Value Type
     */
    valueType?: CHART_VALUE_TYPE
    /**
     * Format function applied before value is mapped
     */
    format?: (value: any, parentValue: T) => string
  }[],
  itemIdPropertyOrGenerator?: keyof T | ((data: T, index: number) => string)
): IChartTable {
  if (!list) {
    return null
  }

  return {
    categories: propertiesToInclude.map(
      ({propertyKey, name, valueType, options, categoryId}) => {
        return {
          id: categoryId || (propertyKey as string),
          name,
          valueType: valueType || CHART_VALUE_TYPE.STRING,
          options
        }
      }
    ),
    items: list.map((data, index) => {
      return {
        ...(itemIdPropertyOrGenerator && {
          id:
            typeof itemIdPropertyOrGenerator === 'function'
              ? itemIdPropertyOrGenerator(data, index)
              : String(data[itemIdPropertyOrGenerator])
        }),
        data: propertiesToInclude.map(
          ({propertyKey, categoryId, format, options}) => {
            const value = data[propertyKey]
            const items = {
              categoryId: categoryId || (propertyKey as string),
              value: format ? format(value, data) : value
            }
            if (options?.allowedValues) {
              // if item has allowed values also include options for mapping dropdown values correctly
              // and  the key  for filtering in standard table
              Object.assign(items, {
                options,
                key: format ? format(value, data) : value
              })
            }
            return items
          }
        )
      }
    })
  }
}

/**
 * Determine if single row update is possible
 * @param categoryId - id of category being updated
 * @param sortedCategoryId - current sort state of standard table
 * @param dependentCategoryIdsList - list of dependent category id lists to help
 * determine if a full table refresh is required (e.g. Expected Trade
 * Value and Quantity where Quantity might be sorted column but Expected
 * Trade Value was updated)
 */
export function canUpdateSingleRow(
  categoryId: string,
  sortedCategoryId: ISortColumnOptions['categoryId'],
  dependentCategoryIdsList?: string[][]
): boolean {
  // No sorting active then can perform a single row update
  if (!sortedCategoryId) {
    return true
  }

  // If sorting active and nothing is passed for `dependentCategoryIds`
  // then we are assuming each column is dependent on each other and
  // cannot update single row
  if (!dependentCategoryIdsList) {
    return false
  }

  // Updated column is not currently sorted
  // AND
  // None of the lists of dependant categories contain both the
  // currently updating category AND sorted category
  // then can also perform a single row update
  if (
    sortedCategoryId !== categoryId &&
    !dependentCategoryIdsList.find(
      (dependentCategoryIds) =>
        dependentCategoryIds.includes(categoryId) &&
        dependentCategoryIds.includes(sortedCategoryId)
    )
  ) {
    return true
  }

  return false
}
