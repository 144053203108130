import React from 'react'

import {useApiQuery} from 'fairlight'

import {config} from '@d1g1t/config/config'

import {SystemStatusEndpoints} from '@d1g1t/api/endpoints'

export const Version: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
  props
) => {
  const [version] = useApiQuery(SystemStatusEndpoints.systemStatus(), {
    fetchPolicy: 'cache-first'
  })

  return (
    <div {...props}>
      {version.data && (
        <>
          UI Version: {config.tag} ({config.revision})<br />
          API Version: {version.data.version}
        </>
      )}
    </div>
  )
}
