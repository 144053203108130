import React, {useMemo} from 'react'

import {ApiError, useApiMutation, useApiQuery} from 'fairlight'
import {FormikProvider, useFormik} from 'formik'

import {
  CustodianAccountEndpoints,
  InstrumentEndpoints,
  TradingOrderEndpoints
} from '@d1g1t/api/endpoints'
import {
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  ITradeOrder
} from '@d1g1t/api/models'

import {deepDiffObject} from '@d1g1t/lib/deep-diff-object'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {FormActions} from '@d1g1t/shared/components/form-actions'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useFirmSecurirtyTypes} from '@d1g1t/shared/wrappers/use-firm-security-types/use-firm-security-types'

import {
  EquitiesEtfsForm,
  getTradeOrderValidationSchema,
  ITradeOrderFormValues,
  MutualFundsForm,
  TRADE_FORM_TYPE
} from '@d1g1t/advisor/containers/trade-order-form'
import {generateEquitiesEtfsTradeOrderPayload} from '@d1g1t/advisor/containers/trade-order-form/equities-etfs/lib'
import {IEquitiesEtfsFormValues} from '@d1g1t/advisor/containers/trade-order-form/equities-etfs/typings'
import {FixedIncomeForm} from '@d1g1t/advisor/containers/trade-order-form/fixed-income/form'
import {
  getInitialEditTradeOrderFormValues,
  UnsupportedTradeTypeError
} from '@d1g1t/advisor/containers/trade-order-form/lib'
import {generateMutualFundTradeOrderPayload} from '@d1g1t/advisor/containers/trade-order-form/mutual-funds/lib'
import {IMutualFundFormValues} from '@d1g1t/advisor/containers/trade-order-form/mutual-funds/typings'

import {IEditTradeModalProps} from '../typings'

interface ISingleEditOpenTradeModalContentsProps extends IEditTradeModalProps {
  tradeOrder: ITradeOrder
  onSuccess(updatedOrders: Partial<ITradeOrder>[]): void
}

export const SingleEditOpenTradeModalContents: React.FC<
  ISingleEditOpenTradeModalContentsProps
> = (props) => {
  const {firmSecurityTypes} = useFirmSecurirtyTypes()

  const [instrument] = useApiQuery(
    InstrumentEndpoints.findById(extractIdFromUrl(props.tradeOrder.instrument))
  )

  const instrumentTradingType =
    instrument.data &&
    firmSecurityTypes.data?.results.find(
      ({url}) => url === instrument.data.firmSecurityType
    )?.instrumentTradingType

  if ([firmSecurityTypes, instrument].some((query) => !query.data)) {
    return <LoadingContainer loading />
  }

  const tradeFormType = ((): TRADE_FORM_TYPE => {
    switch (instrumentTradingType) {
      case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY:
      case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND:
      case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FIXED_INCOME:
        return instrumentTradingType
      default:
        throw new UnsupportedTradeTypeError()
    }
  })()

  return <EditOpenTradeModalForm {...props} tradeFormType={tradeFormType} />
}

interface IEditOpenTradeModalFormProps
  extends ISingleEditOpenTradeModalContentsProps {
  tradeFormType: TRADE_FORM_TYPE
}

const EditOpenTradeModalForm: React.FC<IEditOpenTradeModalFormProps> = (
  props
) => {
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()

  /**
   * Load the custodian account to get the readonly account URL
   * to display in the form.
   */
  const [custodianAccount] = useApiQuery(
    CustodianAccountEndpoints.findById(
      extractIdFromUrl(props.tradeOrder.custodianAccount)
    )
  )

  /**
   * Reinitialize values when the selected trade type changes
   */
  const initialValues = useMemo(() => {
    if (!custodianAccount.data) {
      return null
    }
    return getInitialEditTradeOrderFormValues(
      props.tradeOrder,
      custodianAccount.data.account
    )
  }, [props.tradeOrder, custodianAccount.data])

  const validationSchema = useMemo(
    () => getTradeOrderValidationSchema(props.tradeFormType),
    [props.tradeFormType]
  )

  const [handleSubmit] = useApiMutation({
    mutation: (values: ITradeOrderFormValues) => (api) => {
      const updatedData = deepDiffObject(initialValues, values)

      return api.request(
        TradingOrderEndpoints.partialUpdate(
          extractIdFromUrl(props.tradeOrder.url),
          props.tradeFormType ===
            FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY
            ? generateEquitiesEtfsTradeOrderPayload({
                ...updatedData,
                type: (values as IEquitiesEtfsFormValues).type
              } as IEquitiesEtfsFormValues)
            : generateMutualFundTradeOrderPayload(
                updatedData as IMutualFundFormValues
              )
        )
      )
    },

    onSuccess: (tradeOrder: ITradeOrder) => {
      showSnackbar({
        variant: 'success',
        message: 'Trade order has been saved.'
      })

      props.onSuccess([tradeOrder])

      props.onClose()
    },

    onError: (error) => {
      if (error instanceof ApiError && error.status === 400) {
        showSnackbar({
          variant: 'error',
          message: Object.values(error.responseBody)[0]
        })
      } else {
        handleUnexpectedError(
          error,
          'An unexpected error occurred while saving the trade.'
        )
      }
    }
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit
  })

  if (!formik.values) {
    return <LoadingContainer loading />
  }

  return (
    <FormikProvider value={formik}>
      <form
        autoComplete='off'
        onSubmit={formik.handleSubmit}
        style={{display: 'contents'}}
      >
        <ControlStateProvider loading={formik.isSubmitting}>
          <ModalContent>
            {(() => {
              switch (props.tradeFormType) {
                case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY:
                  return <EquitiesEtfsForm editMode />
                case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND:
                  return <MutualFundsForm editMode />
                case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FIXED_INCOME:
                  return <FixedIncomeForm editMode />
                default:
                  throw new UnsupportedTradeTypeError()
              }
            })()}
          </ModalContent>
          <ModalActions>
            <FormActions
              showFormErrorCount
              // hide dirty text because the trade order form
              // sets a lot of values on mount, causing it to
              // always show dirty text...
              hideDirtyText
            />
          </ModalActions>
        </ControlStateProvider>
      </form>
    </FormikProvider>
  )
}
