import {useCallback} from 'react'

import {Api, useApi} from 'fairlight'
import useAsyncCall from 'use-async-call'

import {
  AccountEndpoints,
  EntityEndpoints,
  IAccountsOrPositionsResult
} from '@d1g1t/api/endpoints'
import {IAccount} from '@d1g1t/api/models'
import {IApiListResponse} from '@d1g1t/api/typings'
import {ISelectedEntities} from '@d1g1t/typings/general'

import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'

export function useAccountsData(
  /**
   * Should maintain referential equality
   */
  selectedEntities: ISelectedEntities
) {
  const api = useApi()
  const [calculationSettings] = useCalculationSettings()

  const fetchAccounts = useCallback(async (): Promise<IAccount[]> => {
    if (!selectedEntities) {
      return []
    }

    const entities = selectedEntities.clients || selectedEntities.households
    const accountsPromises: Array<Promise<IAccount[]>> = []

    if (entities) {
      accountsPromises.push(
        ...entities.map(async (entity) => {
          const {results: accounts} = await api.request(
            AccountEndpoints.list({
              ownerEntityId: entity,
              asOfDate: calculationSettings.date.date,
              currency: calculationSettings.currency
            })
          )

          return accounts
        })
      )
    }

    if (selectedEntities.accounts) {
      const accountPromises: Array<Promise<IAccount>> = []

      for (const accountIds of selectedEntities.accounts) {
        for (const accountId of accountIds) {
          accountPromises.push(
            api.request(AccountEndpoints.findById(accountId))
          )
        }
      }

      accountsPromises.push(Promise.all(accountPromises))
    }

    if (selectedEntities.accountsOrPositions) {
      const accountsOrPositionsInfo = await getOwnerEntityIds(
        api,
        selectedEntities.accountsOrPositions.flat()
      )
      const uniqueAccountIds = [
        ...new Set(
          accountsOrPositionsInfo.map((result) => result.accountEntityId)
        )
      ]

      accountsPromises.push(
        Promise.all(
          uniqueAccountIds.map((accountId) =>
            api.request(AccountEndpoints.findById(accountId))
          )
        )
      )
    }

    return (await Promise.all(accountsPromises)).flat()
  }, [selectedEntities])

  return useAsyncCall(fetchAccounts, {
    waitFor: [calculationSettings]
  })
}

const accountsOrPositionsCache = new Map<string, IAccountsOrPositionsResult>()

/**
 * Returns a list, in the same order as entites passed, of information containing
 * owner account and content type for a mixed list of accounts or positions
 */
export async function getOwnerEntityIds(
  api: Api,
  accountsOrPositionIds: string[]
) {
  const accountOrPositionRequestIdsToRequest = []
  for (const entityId of accountsOrPositionIds) {
    if (!accountsOrPositionsCache.has(entityId)) {
      accountOrPositionRequestIdsToRequest.push(entityId)
    }
  }

  if (accountOrPositionRequestIdsToRequest.length === 0) {
    return accountsOrPositionIds.map((entityId) =>
      accountsOrPositionsCache.get(entityId)
    )
  }

  const response: IApiListResponse<IAccountsOrPositionsResult> =
    await api.request(
      EntityEndpoints.accountsOrPositions({
        accountsOrPositions: accountOrPositionRequestIdsToRequest
      })
    )

  const returnValue: IAccountsOrPositionsResult[] = []
  for (const entityId of accountsOrPositionIds) {
    if (accountsOrPositionsCache.has(entityId)) {
      returnValue.push(accountsOrPositionsCache.get(entityId))
    } else {
      const data = response.results.find(
        (result) => result.entityId === entityId
      )
      returnValue.push(data)
      accountsOrPositionsCache.set(entityId, data)
    }
  }

  return returnValue
}
