import {useMemo} from 'react'

import {useApiQuery} from 'fairlight'

import {
  AssetCategoryEndpoints,
  AssetClassEndpoints,
  AssetClassL2Endpoints,
  AssetClassL3Endpoints,
  AssetSubCategoryEndpoints,
  CurrencyEndpoints,
  FirmSecurityTypeEndpoints,
  InstrumentIndustryEndpoints,
  InstrumentRegionEndpoints,
  InstrumentSectorEndpoints,
  InstrumentStrategyEndpoints,
  InstrumentSubStrategyEndpoints,
  LiquidityScoresEndpoints,
  RiskReturnsEndpoints,
  SecurityRiskRatingEndpoints,
  TradingEndpoints
} from '@d1g1t/api/endpoints'

/**
 * Makes a bunch of API calls to get the options available in various dropdowns.
 */
export function useEditSecuritiesDropdownOptions() {
  const [markets] = useApiQuery(TradingEndpoints.markets(), {
    fetchPolicy: 'cache-first',
    useErrorBoundary: true
  })
  const marketOptions = useMemo(
    () =>
      (markets.data?.results || []).map((market) => ({
        value: market.url,
        label: market.country
      })),
    [markets.data]
  )

  const [exchanges] = useApiQuery(TradingEndpoints.exchanges(), {
    fetchPolicy: 'cache-first',
    useErrorBoundary: true
  })

  const [instrumentStrategies] = useApiQuery(
    InstrumentStrategyEndpoints.list(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  const [instrumentSubStrategies] = useApiQuery(
    InstrumentSubStrategyEndpoints.list(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  const [assetClasses] = useApiQuery(AssetClassEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const [assetClassesL2] = useApiQuery(AssetClassL2Endpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const [assetCategories] = useApiQuery(AssetCategoryEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const [assetSubCategories] = useApiQuery(AssetSubCategoryEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const [assetClassesL3] = useApiQuery(AssetClassL3Endpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const [sectors] = useApiQuery(InstrumentSectorEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const [regions] = useApiQuery(InstrumentRegionEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const [liquidityScores] = useApiQuery(LiquidityScoresEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const [instrumentRegions] = useApiQuery(InstrumentRegionEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const [securityRiskRatings] = useApiQuery(
    SecurityRiskRatingEndpoints.list(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  const [instrumentIndustries] = useApiQuery(
    InstrumentIndustryEndpoints.list(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  const [currencies] = useApiQuery(CurrencyEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const [firmSecurityTypes] = useApiQuery(FirmSecurityTypeEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const [industries] = useApiQuery(InstrumentIndustryEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const [riskReturns] = useApiQuery(RiskReturnsEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  return {
    markets,
    marketOptions,
    exchanges,
    instrumentStrategies,
    instrumentSubStrategies,
    assetClasses,
    assetClassesL2,
    assetClassesL3,
    assetCategories,
    assetSubCategories,
    sectors,
    regions,
    liquidityScores,
    instrumentRegions,
    instrumentIndustries,
    currencies,
    firmSecurityTypes,
    industries,
    riskReturns,
    securityRiskRatings
  }
}
