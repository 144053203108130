export enum COLLAPSABLE_URLS {
  ACTIVATE = '/activate',
  DOCUMENTS_REPORTS = '/documents/reports'
}
export const COLLAPSE_URL = [
  COLLAPSABLE_URLS.ACTIVATE,
  COLLAPSABLE_URLS.DOCUMENTS_REPORTS
]

/**
 * Collapse urlToRCollapse on the first "/" when it matches url
 * @param url - the url to match
 * @param urlToCollapse - the url to collapse
 * @returns Url string that is collapsed
 */
export const collapseUrl = (url: string, urlToCollapse: string) =>
  urlToCollapse.replace(new RegExp(`${url}/.*`), `${url}`)

/**
 * Gets string representation from a component's children to use as
 * custom datadog action
 * @param children - the component's children
 * @returns action name for datadog
 */
export const getDDButtonAction = (children) => {
  if (Array.isArray(children)) {
    return getDDButtonAction(children.find((child) => getDDButtonAction(child)))
  }

  if (!children) {
    return
  }

  if (typeof children === 'string') {
    return children
  }

  if (typeof children.props?.children === 'string') {
    return children.props?.children
  }

  if (children.props?.label) {
    return children?.props?.label
  }

  if (children.title) {
    return children.title
  }

  if (children['data-testid']) {
    return children['data-testid']
  }
}
