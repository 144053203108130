import {invert} from 'lodash'

import {
  AdministrationLocations,
  ClientsLocations,
  ContactsLocations,
  DocumentsLocations,
  ExploreLocations,
  InvestmentProgramLocations,
  ManageFundLocations,
  overviewPath,
  securitiesListPath,
  SecurityModelLocations,
  SingleClientLocations,
  TrackComplianceLocations,
  TradeLocations
} from '@d1g1t/advisor/locations'

/**
 * Sections tracked and provided with `useNavigationSectionContext()`.
 */
export enum NavigationSection {
  MONITOR_BUSINESS = 'Monitor Business',
  CLIENT_PORTFOLIOS = 'Client Portfolios',
  HOLDINGS = 'Holdings',
  HOUSEHOLDS = 'Households',
  CLIENTS = 'Clients',
  ACCOUNTS = 'Accounts',
  CLIENT_MANDATES = 'Mandates',
  CLIENT_MONITOR = 'Manage Clients',
  FUNDS_AND_SMAS = 'Funds And Smas',
  INVESTMENT_PROGRAMS = 'Investment Programs',
  SECURITY_MODELS = 'Security Models',
  SECURITIES = 'Securities',
  COMPARE_PORTFOLIOS = 'Compare Portfolios',
  BUY_SELL = 'Buy & Sell',
  TRADE_DIRECTIVE = 'Trade Directive',
  REBALANCE = 'Rebalance',
  REBALANCE_PORTFOLIOS = 'Rebalance Portfolios',
  MANAGE_PORTFOLIOS = 'Manage Portfolios',
  MANAGE_ORDERS = 'Manage Orders',
  MANAGE_REPORTS = 'Manage Reports',
  MANAGE_DOCUMENTS = 'Manage Documents',
  CONTACTS = 'Contacts',
  ADMINISTRATION_USERS = 'Administration Users',
  ADMINISTRATION_CLIENTS = 'Administration Clients',
  ADMINISTRATION_HOUSEHOLDS = 'Administration Households',
  ADMINISTRATION_ACCOUNTS = 'Administration Accounts',
  ADMINISTRATION_TRANSACTIONS = 'Administration Transactions',
  ADMINISTRATION_SECURITIES = 'Administration Securities',
  ADMINISTRATION_TRADING_RESTRICTIONS = 'Administration Trading Restrictions',
  ADMINISTRATION_TRADE_LOCATION_RULES = 'Administration Trade Location Rules'
}

export const LocationPathToNavigationSectionMap = {
  [overviewPath()]: NavigationSection.MONITOR_BUSINESS,
  [ClientsLocations.portfolios()]: NavigationSection.CLIENT_PORTFOLIOS,
  [ClientsLocations.holdings()]: NavigationSection.HOLDINGS,
  [ClientsLocations.households()]: NavigationSection.HOUSEHOLDS,
  [ClientsLocations.clients()]: NavigationSection.CLIENTS,
  [ClientsLocations.accounts()]: NavigationSection.ACCOUNTS,
  [TrackComplianceLocations.trackMandates()]: NavigationSection.CLIENT_MANDATES,
  [SingleClientLocations.monitorOverview()]: NavigationSection.CLIENT_MONITOR,
  [ManageFundLocations.path()]: NavigationSection.FUNDS_AND_SMAS,
  [InvestmentProgramLocations.path()]: NavigationSection.INVESTMENT_PROGRAMS,
  [SecurityModelLocations.list()]: NavigationSection.SECURITY_MODELS,
  [securitiesListPath()]: NavigationSection.SECURITIES,
  [ExploreLocations.compare()]: NavigationSection.COMPARE_PORTFOLIOS,
  [TradeLocations.equitiesEtfs()]: NavigationSection.BUY_SELL,
  [TradeLocations.bulkChangeAllocation()]: NavigationSection.TRADE_DIRECTIVE,
  [TradeLocations.rebalance()]: NavigationSection.REBALANCE,
  [TradeLocations.rebalancePortfolios()]:
    NavigationSection.REBALANCE_PORTFOLIOS,
  [TradeLocations.managePortfolios()]: NavigationSection.MANAGE_PORTFOLIOS,
  [TradeLocations.manageOrders()]: NavigationSection.MANAGE_ORDERS,
  [DocumentsLocations.reportTemplates()]: NavigationSection.MANAGE_REPORTS,
  [DocumentsLocations.documents()]: NavigationSection.MANAGE_DOCUMENTS,
  [ContactsLocations.basePath]: NavigationSection.CONTACTS,
  [AdministrationLocations.userList()]: NavigationSection.ADMINISTRATION_USERS,
  [AdministrationLocations.clientAssignments()]:
    NavigationSection.ADMINISTRATION_CLIENTS,
  [AdministrationLocations.accountManagement()]:
    NavigationSection.ADMINISTRATION_HOUSEHOLDS,
  [AdministrationLocations.householdsList()]:
    NavigationSection.ADMINISTRATION_ACCOUNTS,
  [AdministrationLocations.transactionsList()]:
    NavigationSection.ADMINISTRATION_TRANSACTIONS,
  [AdministrationLocations.manageSecurities()]:
    NavigationSection.ADMINISTRATION_SECURITIES,
  [AdministrationLocations.tradeRestrictions()]:
    NavigationSection.ADMINISTRATION_TRADING_RESTRICTIONS,
  [AdministrationLocations.tradeLocationRules()]:
    NavigationSection.ADMINISTRATION_TRADE_LOCATION_RULES
}

export const NavigationSectionToLocationPathMap = invert(
  LocationPathToNavigationSectionMap
)
