import React from 'react'
import {useTranslation} from 'react-i18next'

import DateFnsUtils from '@date-io/date-fns'
import {format, parse} from 'date-fns'
import {enCA, frCA} from 'date-fns/locale'
import {upperFirst} from 'lodash'

import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

import {classNames} from '@d1g1t/lib/class-names'
import {ISO_DATE_FORMAT} from '@d1g1t/lib/constants'

import {useControlState} from '@d1g1t/shared/components/control-state'
import {AVAILABLE_LANGUAGES} from '@d1g1t/shared/wrappers/localization-settings/typings'

import * as keyboardDatePickerCss from '@d1g1t/shared/components/mui/keyboard-date-picker/styles.scss'
import * as css from './styles.scss'

export interface IDatePickerProps
  extends Omit<
    DatePickerProps,
    'value' | 'onChange' | 'format' | 'maxDate' | 'minDate' | 'views'
  > {
  /**
   * A date string in ISO date format
   */
  value: string
  /**
   * @param value -  A date string in ISO date format yyyy-MM-dd
   */
  onChange(value: string): void
  /**
   * A date string in ISO date format yyyy-MM-dd
   */
  maxDate?: string
  /**
   * A date string in ISO date format yyyy-MM-dd
   */
  minDate?: string
  /**
   * Enable future date selection
   */
  allowFuture?: boolean
  /**
   * Enum to select a specific view
   */
  dateType?: 'date' | 'year'
  /**
   * Smaller height for input field to fit in tables
   */
  smallHeight?: boolean
  /**
   * Adds additional border-radius to give select a "pill" shape
   */
  rounded?: boolean
  /**
   * Updates the border to a muted colour
   */
  mutedBorder?: boolean
  /**
   * If true, hides the error message
   */
  hideError?: boolean
  /**
   * If true, the input shows a pointer cursor on hover
   */
  pointerCursor?: boolean
}

const LOCALES = {
  [AVAILABLE_LANGUAGES.ENGLISH]: enCA,
  [AVAILABLE_LANGUAGES.FRENCH]: {
    ...frCA,
    options: {
      weekStartsOn: 0,
      firstWeekContainsDate: 1
    }
  }
}

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date) {
    return upperFirst(format(date, 'eee d MMMM', {locale: this.locale}))
  }
}

export const DatePicker: React.FC<IDatePickerProps> = ({
  maxDate,
  minDate,
  onChange,
  dateType = 'date',
  value,
  allowFuture,
  smallHeight,
  rounded,
  hideError,
  mutedBorder,
  pointerCursor,
  InputProps = {},
  ...props
}) => {
  const controlState = useControlState()
  const {i18n, t} = useTranslation()

  return (
    <MuiPickersUtilsProvider
      utils={LocalizedUtils}
      locale={LOCALES[i18n.language]}
    >
      <MuiDatePicker
        disableFuture={!allowFuture}
        disabled={controlState.loading || props.disabled}
        allowKeyboardControl
        todayLabel={t('Today')}
        cancelLabel={t('Cancel')}
        inputVariant='outlined'
        format={dateType === 'year' ? 'yyyy' : ISO_DATE_FORMAT}
        value={parseDateString(value)}
        views={dateType === 'year' ? ['year'] : undefined}
        onChange={(value: Date) => {
          onChange(value ? format(value, ISO_DATE_FORMAT) : '')
        }}
        InputProps={{
          ...InputProps,
          classes: {
            root: classNames(keyboardDatePickerCss.root, {
              [keyboardDatePickerCss.smallHeight]: smallHeight,
              [keyboardDatePickerCss.rounded]: rounded,
              [keyboardDatePickerCss.mutedBorder]: mutedBorder,
              [css.pointerCursor]: pointerCursor
            })
          }
        }}
        invalidLabel={hideError && ''}
        {...props}
        maxDate={maxDate ? parseDateString(maxDate) : undefined}
        minDate={minDate ? parseDateString(minDate) : undefined}
      />
    </MuiPickersUtilsProvider>
  )
}

function parseDateString(value: string): Date {
  return value ? parse(value, ISO_DATE_FORMAT, new Date()) : null
}
