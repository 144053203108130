import {useState} from 'react'

import {useGlobalSettings} from '@d1g1t/shared/wrappers/global-settings'

import {ISortColumnOptions} from '../..'

const DEFAULT_STATE: ISortColumnOptions = {
  categoryId: null,
  sortOrder: null
}

/**
 * Controls sorting.
 *
 * If a table `id` is passed, it manages sort properties in global settings.
 * If no table `id` is passed, it manages sort properties in react state.
 */

/**
 * Controls sorting.
 * @param id - If a table `id` is passed, it manages sort properties
 * in global settings.
 * If no table `id` is passed, it manages sort properties in react state.
 * @param defaultState - Override default state of no sort.
 */
export function useTableSortControl(
  id?: string,
  defaultState?: ISortColumnOptions
): [ISortColumnOptions, (sorting: ISortColumnOptions) => void] {
  const [sorting, setSorting] = useState<ISortColumnOptions>(
    defaultState ?? DEFAULT_STATE
  )

  const [savedSorting, {updateGlobalSettings}] = useGlobalSettings(
    `TABLE-SORTING-${id}`,
    defaultState ?? DEFAULT_STATE,
    [id]
  )

  if (!id) {
    return [sorting, setSorting]
  }

  return [savedSorting, updateGlobalSettings]
}
