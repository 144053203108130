import {isNil} from 'lodash'

import {IContributionDimention} from '@d1g1t/api/endpoints'

import {CONTRIBUTION_DIMENSION_FIELD_NAME} from '@d1g1t/lib/metrics'

const SECONDARY_DIMENSIONS_BY_PRIMARY_DIMENSIONS: {
  [fieldName: string]: Set<string>
} = {
  [CONTRIBUTION_DIMENSION_FIELD_NAME.ASSET_CLASSES]: new Set([
    CONTRIBUTION_DIMENSION_FIELD_NAME.STRATEGIES
  ]),
  [CONTRIBUTION_DIMENSION_FIELD_NAME.CURRENCIES]: new Set([
    CONTRIBUTION_DIMENSION_FIELD_NAME.ASSET_CLASSES,
    CONTRIBUTION_DIMENSION_FIELD_NAME.STRATEGIES
  ])
}

export const supportedSecondaryDimensionsForPrimaryDimension = (
  contributionDimensions: IContributionDimention[],
  primaryDimensionId: number
): IContributionDimention[] => {
  if (isNil(primaryDimensionId)) {
    return []
  }

  const primaryDimension = contributionDimensions.find(
    ({id}) => id === primaryDimensionId
  )
  const supportedSecondaryDimensionFieldNames =
    SECONDARY_DIMENSIONS_BY_PRIMARY_DIMENSIONS[primaryDimension.fieldName]

  if (!supportedSecondaryDimensionFieldNames) {
    return []
  }

  return contributionDimensions.filter(({fieldName}) => {
    return supportedSecondaryDimensionFieldNames.has(fieldName)
  })
}
