import FileSaver from 'file-saver'

import {IDownload} from '@d1g1t/api/models'

import {MIME_TYPE} from '@d1g1t/lib/constants'

/**
 * Downloads base64 encoded files
 * @param base64files - array of base64 encoded files to download
 */
export const downloadBase64 = async (
  base64files: IDownload,
  type = MIME_TYPE.excel
) => {
  const promise = await fetch(`data:${type};base64,${base64files.data}`)
  const blob = await promise.blob()
  FileSaver.saveAs(blob, base64files.filename)
}
