import {UrlSelection} from '@d1g1t/lib/url'

import {ClientsLocations} from './clients-locations'

export class SingleClientLocations extends ClientsLocations {
  static new() {
    return super.path('new')
  }

  static default(selection?: UrlSelection) {
    return super.withSelection('default', selection)
  }

  static monitor() {
    return super.path('monitor')
  }

  // Monitor
  static monitorOverview(selection?: UrlSelection) {
    return super.withSelection('monitor/overview', selection)
  }

  static monitorPast(selection?: UrlSelection) {
    return super.withSelection('monitor/past', selection)
  }

  static monitorPresent(selection?: UrlSelection) {
    return super.withSelection('monitor/present', selection)
  }

  static monitorFuture(selection?: UrlSelection) {
    return super.withSelection('monitor/future', selection)
  }

  static monitorActivity(selection?: UrlSelection) {
    return super.withSelection('monitor/activity', selection)
  }

  // Allocation
  static allocation(selection?: UrlSelection) {
    return super.withSelection('allocation', selection)
  }

  static allocationAccounts(selection?: UrlSelection) {
    return super.withSelection('allocation/accounts', selection)
  }

  static allocationMandates(selection?: UrlSelection) {
    return super.withSelection('allocation/mandates', selection)
  }

  // Reconciliation
  static reconciliation(selection?: UrlSelection) {
    return super.withSelection('reconciliation', selection)
  }

  // Accounts
  static accountsList(selection?: UrlSelection) {
    return super.withSelection('accounts', selection)
  }

  static account(accountId: string, selection?: UrlSelection) {
    return super.withSelection(`accounts/${accountId}`, selection)
  }

  static accountTransactionCreate(accountId: string, selection?: UrlSelection) {
    return super.withSelection(
      `accounts/${accountId}/create-transaction`,
      selection
    )
  }

  static accountTransactionEdit(
    accountId: string,
    transactionId: string,
    selection?: UrlSelection
  ) {
    return super.withSelection(
      `accounts/${accountId}/edit-transaction/${transactionId}`,
      selection
    )
  }

  static accountEdit(accountId: string, selection?: UrlSelection) {
    return super.withSelection(`accounts/${accountId}/edit`, selection)
  }

  // Custodian Accounts
  static custodianAccount(accountId: string, selection?: UrlSelection) {
    return super.withSelection(`custodian-accounts/${accountId}`, selection)
  }

  static custodianAccountTransactionEdit(
    accountId: string,
    transactionId: string,
    selection?: UrlSelection
  ) {
    return super.withSelection(
      `custodian-accounts/${accountId}/edit-transaction/${transactionId}`,
      selection
    )
  }

  static custodianAccountEdit(accountId: string, selection?: UrlSelection) {
    return super.withSelection(
      `custodian-accounts/${accountId}/edit`,
      selection
    )
  }

  static custodianAccountTransactionCreate(
    accountId: string,
    selection?: UrlSelection
  ) {
    return super.withSelection(
      `custodian-accounts/${accountId}/create-transaction`,
      selection
    )
  }

  // Mandates
  static mandatesList(selection?: UrlSelection) {
    return super.withSelection('mandates', selection)
  }

  static mandatesNew(selection?: UrlSelection) {
    return super.withSelection('mandates/new', selection)
  }

  static mandate(mandateId: string, selection?: UrlSelection) {
    return super.withSelection(`mandates/${mandateId}`, selection)
  }

  static mandateEdit(mandateId: string, selection?: UrlSelection) {
    return super.withSelection(`mandates/${mandateId}/edit`, selection)
  }

  static mandateDetailNew(mandateId: string, selection?: UrlSelection) {
    return super.withSelection(`mandates/${mandateId}/new`, selection)
  }

  // Goals
  static goalsList(selection?: UrlSelection) {
    return super.withSelection('goals', selection)
  }

  static goalsNew(selection?: UrlSelection) {
    return super.withSelection('goals/new', selection)
  }

  static goal(goalId: string, selection?: UrlSelection) {
    return super.withSelection(`goals/${goalId}`, selection)
  }

  static goalEdit(goalId: string, selection?: UrlSelection) {
    return super.withSelection(`goals/${goalId}/edit`, selection)
  }

  // Profile
  static profile(selection?: UrlSelection) {
    return super.withSelection('profile', selection)
  }

  static relationshipManagement(selection?: UrlSelection) {
    return super.withSelection('profile/relationship-management', selection)
  }

  static relationshipManagementEditRoles(selection?: UrlSelection) {
    return super.withSelection(
      'profile/relationship-management/edit-roles/',
      selection
    )
  }

  static investorProfile(selection?: UrlSelection) {
    return super.withSelection('profile/investor-profile', selection)
  }

  static investorProfileCreateRestriction(selection?: UrlSelection) {
    return super.withSelection(
      'profile/investor-profile/create-restriction',
      selection
    )
  }
}
