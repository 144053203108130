import React, {useEffect, useMemo} from 'react'

import {format} from 'date-fns'
import {useApi, useApiQuery} from 'fairlight'
import {useFormikContext} from 'formik'

import {
  InstrumentEndpoints,
  PositionEndpoints,
  TradingEndpoints
} from '@d1g1t/api/endpoints'
import {
  TRADEORDER_COMMISSION_TYPE,
  TRADEORDER_EXPIRY_TYPE,
  TRADEORDER_EXPIRY_TYPE_OPTIONS,
  TRADEORDER_OPERATION,
  TRADEORDER_PRO_STATUS_OPTIONS,
  TRADEORDER_QTY_TYPE,
  TRADEORDER_TYPE,
  TRADEORDER_TYPE_OPTIONS
} from '@d1g1t/api/models'

import {ISO_DATE_FORMAT} from '@d1g1t/lib/constants'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {FlexGridItem} from '@d1g1t/shared/components/flex'
import {CheckboxField} from '@d1g1t/shared/components/form-field/checkbox-field'
import {KeyboardDatePickerField} from '@d1g1t/shared/components/form-field/keyboard-date-picker-field'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {SearchInputField} from '@d1g1t/shared/components/form-field/search-input-field'
import {ValueLabelSelectField} from '@d1g1t/shared/components/form-field/value-select-field'
import {
  CurrencyInput,
  FourDecimalInput,
  SixDecimalInput
} from '@d1g1t/shared/components/formatted-input'
import {Alert} from '@d1g1t/shared/components/mui/alert'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {P} from '@d1g1t/shared/components/typography'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'

import {CommissionsSection} from '../../commissions-section'
import {TradeSelectField} from '../../trade-select-field'
import {
  EQUITIES_ETFS_FIELD_NAMES,
  IBuySellEquitiesProps,
  IEquitiesEtfsFormValues
} from '../typings'
import {EquitiesEtfsInstrumentField} from './instrument-field'

import commonCss from '../../style.scss'

interface IEquitiesEtfsFormProps
  extends Pick<IBuySellEquitiesProps, 'isTradable'> {
  editMode?: boolean
}

export const EquitiesEtfsForm: React.FC<IEquitiesEtfsFormProps> = (props) => {
  const api = useApi()
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()
  const {isSubmitting} = useFormikContext()

  const {
    values: {
      instrumentUrl,
      accountUrl,
      isSellAll,
      operation,
      expiryType,
      type,
      expectedPrice
    },
    setFieldValue
  } = useFormikContext<IEquitiesEtfsFormValues>()

  const [markets] = useApiQuery(TradingEndpoints.markets(), {
    fetchPolicy: 'cache-first'
  })

  const [instrument] = useApiQuery(
    instrumentUrl &&
      InstrumentEndpoints.findById(extractIdFromUrl(instrumentUrl))
  )

  const [brokers] = useApiQuery(TradingEndpoints.brokers(), {
    fetchPolicy: 'cache-first'
  })

  const brokerOptions: IValueLabelOption[] = useMemo(() => {
    return (
      brokers?.data?.results?.map((item) => ({
        value: item.url,
        label: `${item.name}${item.cuid ? ` (${item.cuid})` : ''}`
      })) ?? []
    )
  }, [brokers])

  /**
   * Auto select market name when a new security is selected.
   */
  useEffect(() => {
    if (!markets.data || !instrument.data) {
      return
    }

    const market = markets.data.results.find(
      (market) =>
        market.url ===
        (instrument.data.primaryMarket?.url ??
          instrument.data.secondaryMarket?.url)
    )

    if (!market) {
      return
    }

    setFieldValue(EQUITIES_ETFS_FIELD_NAMES.market, market.url)
  }, [markets.data, instrument.data])

  /**
   * Updates Commission and Expected Trade Price information with instrument default values
   */
  useEffect(() => {
    setFieldValue(
      EQUITIES_ETFS_FIELD_NAMES.commissionType,
      instrument.data?.defaultCommissionType ||
        TRADEORDER_COMMISSION_TYPE.AMOUNT
    )
    setFieldValue(
      EQUITIES_ETFS_FIELD_NAMES.commission,
      instrument.data?.defaultCommission
    )
    if (type === TRADEORDER_TYPE.MARKET && instrument.data?.marketPrice) {
      setFieldValue(
        EQUITIES_ETFS_FIELD_NAMES.expectedPrice,
        parseFloat(instrument.data.marketPrice.toFixed(2))
      )
    }
    if (type === TRADEORDER_TYPE.LIMIT) {
      setFieldValue(EQUITIES_ETFS_FIELD_NAMES.expectedPrice, undefined)
    }
  }, [instrument.data?.url, type])

  const populateQuantityWithUnitsHeld = async () => {
    setFieldValue(EQUITIES_ETFS_FIELD_NAMES.qtyType, TRADEORDER_QTY_TYPE.UNITS)

    setFieldValue(EQUITIES_ETFS_FIELD_NAMES.qty, 0)

    try {
      const response = await api.request(
        PositionEndpoints.list({
          asOfDate: format(new Date(), ISO_DATE_FORMAT),
          account: extractIdFromUrl(accountUrl),
          instrument: instrument.data.firmProvidedKey
        })
      )

      if (response.results.length === 0) {
        showSnackbar({
          variant: 'error',
          message: 'No units held for the current security.'
        })
        return
      }

      setFieldValue(
        EQUITIES_ETFS_FIELD_NAMES.qty,
        Math.floor(response.results[0].unitsHeld)
      )
    } catch (error) {
      handleUnexpectedError(error, 'Cannot get amount of units held.')
    }
  }

  /**
   * When `Sell All` is checked then populate the quantity field
   * with the total amount of units held in that account for the
   * current instrument
   */
  const instrumentFirmKey = instrument.data?.firmProvidedKey
  useEffect(() => {
    if (isSellAll && accountUrl && instrumentFirmKey) {
      populateQuantityWithUnitsHeld()
    }
  }, [isSellAll, instrumentFirmKey])

  /**
   * Resets the quantity when instrument is unselected
   */
  useEffect(() => {
    if (isSellAll && !instrumentUrl) {
      setFieldValue(EQUITIES_ETFS_FIELD_NAMES.qty, 0)
    }
  }, [instrumentUrl])

  /**
   * Removes isSellAll when the operation is not SELL
   */
  useEffect(() => {
    if (operation !== TRADEORDER_OPERATION.SELL) {
      setFieldValue(EQUITIES_ETFS_FIELD_NAMES.isSellAll, false)
    }
  }, [operation])

  /**
   * List of markets comes from the security. Options are restricted to primary
   * and secondary markets of the security.
   */
  const marketOptions = useMemo(() => {
    if (!instrument.data || !markets.data) {
      return []
    }

    const primaryMarket = instrument.data.primaryMarket
    const secondaryMarket = instrument.data.secondaryMarket

    if (!primaryMarket && !secondaryMarket) {
      // If market values aren't populated, show the available markets
      return markets.data.results.map((item) => ({
        value: item.url,
        label: item.country
      }))
    }

    const instrumentMarkets = []
    if (primaryMarket) {
      instrumentMarkets.push({
        value: primaryMarket.url,
        label: primaryMarket.country
      })
    }

    if (secondaryMarket) {
      instrumentMarkets.push({
        value: secondaryMarket.url,
        label: secondaryMarket.country
      })
    }

    return instrumentMarkets
  }, [instrument.data])

  return (
    <ControlStateProvider
      loading={isSubmitting || !accountUrl || !instrument.data}
    >
      <FlexGridItem col='2/3' className={commonCss.tradeSections}>
        {instrument.data &&
          !instrument.data.marketPrice &&
          !!(!expectedPrice && type === TRADEORDER_TYPE.MARKET) && (
            <Alert severity='warning'>
              <P>
                The security doesn't have a market price. Please enter a price
                if you want to use this security for trading.
              </P>
            </Alert>
          )}
        <Grid container>
          <ControlStateProvider
            loading={
              isSubmitting || !accountUrl || props.editMode || !props.isTradable
            }
          >
            <Grid item xs={2}>
              <TradeSelectField
                name={EQUITIES_ETFS_FIELD_NAMES.operation}
                buySellOnly
              />
            </Grid>
            <Grid item xs={props.editMode ? 3 : 6}>
              <EquitiesEtfsInstrumentField />
            </Grid>
            {props.editMode && (
              <Grid item xs={3}>
                <SearchInputField
                  name={EQUITIES_ETFS_FIELD_NAMES.accountUrl}
                  label='Account'
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={2}>
              <ValueLabelSelectField
                name={EQUITIES_ETFS_FIELD_NAMES.market}
                label='Market'
                disabled={marketOptions.length === 0}
                valueLabelSelectProps={{
                  options: marketOptions
                }}
              />
            </Grid>
          </ControlStateProvider>
          <Grid item xs={2}>
            {operation === TRADEORDER_OPERATION.SELL && (
              <CheckboxField
                name={EQUITIES_ETFS_FIELD_NAMES.isSellAll}
                label='Sell Current Holding'
              />
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <OutlinedInputField
              disabled={isSellAll}
              name={EQUITIES_ETFS_FIELD_NAMES.qty}
              label='Quantity'
              outlinedInputProps={{
                inputComponent: SixDecimalInput
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <ValueLabelSelectField
              name={EQUITIES_ETFS_FIELD_NAMES.type}
              label='Order Type'
              valueLabelSelectProps={{
                options: TRADEORDER_TYPE_OPTIONS
              }}
            />
          </Grid>
          {type === TRADEORDER_TYPE.LIMIT && (
            <Grid item xs={2}>
              <OutlinedInputField
                name={EQUITIES_ETFS_FIELD_NAMES.limitPrice}
                label='Limit Price'
                outlinedInputProps={{
                  inputComponent: FourDecimalInput
                }}
              />
            </Grid>
          )}
          {type === TRADEORDER_TYPE.MARKET && (
            <Grid item xs={2}>
              <OutlinedInputField
                name={EQUITIES_ETFS_FIELD_NAMES.expectedPrice}
                label='Expected Price'
                outlinedInputProps={{
                  inputComponent: CurrencyInput
                }}
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <ValueLabelSelectField
              name={EQUITIES_ETFS_FIELD_NAMES.expiryType}
              label='Duration'
              valueLabelSelectProps={{
                options: TRADEORDER_EXPIRY_TYPE_OPTIONS
              }}
            />
          </Grid>
          {expiryType === TRADEORDER_EXPIRY_TYPE.GTD && (
            <Grid item xs={2}>
              <KeyboardDatePickerField
                name={EQUITIES_ETFS_FIELD_NAMES.expiryDate}
                keyboardDatePickerProps={{
                  allowFuture: true,
                  disablePast: true
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <ValueLabelSelectField
              name={EQUITIES_ETFS_FIELD_NAMES.broker}
              label='Broker'
              valueLabelSelectProps={{
                options: brokerOptions
              }}
            />
          </Grid>
          <CommissionsSection
            commissionFormName={EQUITIES_ETFS_FIELD_NAMES.commission}
            commissionTypeFormName={EQUITIES_ETFS_FIELD_NAMES.commissionType}
          />
        </Grid>
        <Grid container>
          <Grid item xs={10}>
            <OutlinedInputField
              name={EQUITIES_ETFS_FIELD_NAMES.notes}
              label='Notes'
              outlinedInputProps={{
                multiline: true,
                minRows: 1,
                maxRows: 4,
                placeholder: 'Write a note about this order...'
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <OutlinedInputField
              name={EQUITIES_ETFS_FIELD_NAMES.instructions}
              label='Instructions'
              outlinedInputProps={{
                multiline: true,
                minRows: 1,
                maxRows: 4,
                placeholder: 'Write instructions about this order...'
              }}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs='auto'>
            <CheckboxField
              name={EQUITIES_ETFS_FIELD_NAMES.isAllOrNone}
              label='All or Nothing'
            />
          </Grid>
          <Grid item xs='auto'>
            <CheckboxField
              name={EQUITIES_ETFS_FIELD_NAMES.isIceberg}
              label='Iceberg'
            />
          </Grid>
          <Grid item xs='auto'>
            <CheckboxField
              name={EQUITIES_ETFS_FIELD_NAMES.isAnonymous}
              label='Anonymous'
            />
          </Grid>

          <Grid item xs='auto'>
            <CheckboxField
              name={EQUITIES_ETFS_FIELD_NAMES.isOddLot}
              label='Allow Odd Lots'
            />
          </Grid>
          <Grid item xs='auto'>
            <CheckboxField
              name={EQUITIES_ETFS_FIELD_NAMES.isInsider}
              label='Insider'
            />
          </Grid>
          <Grid item xs='auto'>
            <CheckboxField
              name={EQUITIES_ETFS_FIELD_NAMES.isSolicited}
              label='Solicited'
            />
          </Grid>
          <Grid item xs='auto'>
            <ValueLabelSelectField
              name={EQUITIES_ETFS_FIELD_NAMES.proStatus}
              label='Pro Status'
              valueLabelSelectProps={{
                options: TRADEORDER_PRO_STATUS_OPTIONS
              }}
            />
          </Grid>
        </Grid>
      </FlexGridItem>
    </ControlStateProvider>
  )
}
