import {Api} from 'fairlight'
import produce from 'immer'
import invariant from 'invariant'
import {keyBy, mapValues, round} from 'lodash'
import * as Yup from 'yup'

import {
  AccountEndpoints,
  InstrumentEndpoints,
  IRebalancingDraftResponse
} from '@d1g1t/api/endpoints'
import {
  CHARTTABLEOPTIONS_STATUS,
  IAccountData,
  IBulkUpdateRequest,
  IChartTableOptions,
  IFxRate,
  IInstrument,
  ISecurityTarget
} from '@d1g1t/api/models'

import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {
  BulkChartEditableFieldName,
  IAccountAllocationState,
  IEditedValuesBySecurityAndAccount
} from './typings'

/**
 * Helpers for accessing various values relative to a given
 * account (a single row in the account allocation table)
 */
class AccountData {
  private item: StandardResponseItem

  constructor(item: StandardResponseItem) {
    this.item = item
  }

  getMarketValue(): number {
    return this.item.getValue('market_value')
  }

  getCurrency(): IChartTableOptions['currency'] {
    return this.item.getDatum('market_value').options.currency
  }

  getCurrentWeightOfSecurity(securityId: string): number {
    return this.item.getValue(`${securityId}:current_weight`)
  }

  getCurrentQuantityOfSecurity(securityId: string): number {
    return this.item.getValue(`${securityId}:current_quantity`)
  }

  getCurrentValueOfSecurity(securityId: string): number {
    return this.item.getValue(`${securityId}:current_value`)
  }

  getTargetValueOfSecurity(securityId: string): number {
    return this.item.getValue(
      `${securityId}:${BulkChartEditableFieldName.targetValue}`
    )
  }

  getPriceOfSecurity(securityId: string): number {
    return this.item.getValue(`${securityId}:price`)
  }

  getSecurityUnitOfMeasure(securityId: string): number {
    return this.item.getValue(`${securityId}:unit_of_measure`) ?? 1
  }

  getSecurityMultDivIndicator(securityId: string): boolean {
    return this.item.getValue(`${securityId}:mult_div_indicator`) ?? false
  }

  getValueChange(securityId: string): number {
    return this.item.getValue(
      `${securityId}:${BulkChartEditableFieldName.valueChange}`
    )
  }
}

function getSecurityCurrency(
  accountAllocationChart: StandardResponse,
  securityId: string
): string {
  return accountAllocationChart.findCategoryById(securityId).options.currency
}

/**
 * Generate the payload required by trade preview from the current state
 */
export function calculateUpdateChartPayload(
  state: Pick<
    IAccountAllocationState,
    | 'accountAllocationChart'
    | 'bulkRebalanceParams'
    | 'editedValuesBySecurityAndAccount'
    | 'baseCurrency'
    | 'fxRates'
  >,
  includePending: boolean,
  includeCashEquivalents: boolean,
  api: Api,
  checkedAccountsIds: string[]
): IBulkUpdateRequest {
  const accountsData: IAccountData[] = []

  for (const item of state.accountAllocationChart.data) {
    const {id: accountId} = item

    if (!checkedAccountsIds.includes(accountId)) {
      // will not include the unchecked accounts in the trades suggestions calculations
      continue
    }

    const accountData = new AccountData(item)

    const targetValues: ISecurityTarget[] = []
    for (const instrument of state.bulkRebalanceParams.instruments) {
      if (!instrument?.allowTrading) {
        continue
      }

      const securityId = extractIdFromUrl(instrument.instrument)

      const fxRateCalculator = new FxRateCalculator(
        state.fxRates,
        state.baseCurrency
      )

      const calculateTargetValueFromTargetQuantity = (
        quantity: number
      ): number => {
        const unitOfMeasure = accountData.getSecurityUnitOfMeasure(securityId)

        const effectiveQuantity = accountData.getSecurityMultDivIndicator(
          securityId
        )
          ? quantity / unitOfMeasure
          : quantity * unitOfMeasure

        return effectiveQuantity * accountData.getPriceOfSecurity(securityId)
      }

      const calculateTargetValueFromTargetWeight = (weight: number): number => {
        const fxRate = fxRateCalculator.calculate(
          accountData.getCurrency(),
          getSecurityCurrency(state.accountAllocationChart.data, securityId)
        )

        return accountData.getMarketValue() * weight * fxRate
      }

      const editedValue =
        state.editedValuesBySecurityAndAccount[item.id]?.[securityId]

      const value = ((): number => {
        if (editedValue && editedValue !== null) {
          if (
            editedValue.value === 0 &&
            [
              BulkChartEditableFieldName.quantityChange,
              BulkChartEditableFieldName.weightChange,
              BulkChartEditableFieldName.valueChange
            ].includes(editedValue.editableFieldName)
          ) {
            // due to potential rounding issues, if user explicitly enters
            // 0 change, just return the current value
            return accountData.getCurrentValueOfSecurity(securityId)
          }

          switch (editedValue.editableFieldName) {
            case BulkChartEditableFieldName.quantityChange:
              return calculateTargetValueFromTargetQuantity(
                accountData.getCurrentQuantityOfSecurity(securityId) +
                  editedValue.value
              )
            case BulkChartEditableFieldName.weightChange:
              return calculateTargetValueFromTargetWeight(
                accountData.getCurrentWeightOfSecurity(securityId) +
                  editedValue.value
              )
            case BulkChartEditableFieldName.valueChange:
              return (
                accountData.getCurrentValueOfSecurity(securityId) +
                editedValue.value
              )
            case BulkChartEditableFieldName.targetQuantity:
              return calculateTargetValueFromTargetQuantity(editedValue.value)
            case BulkChartEditableFieldName.targetValue:
              return editedValue.value
            case BulkChartEditableFieldName.targetWeight:
              return calculateTargetValueFromTargetWeight(editedValue.value)
          }
        }

        return accountData.getTargetValueOfSecurity(securityId)
      })()

      targetValues.push({
        instrument: api.buildUrl(
          InstrumentEndpoints.pathToResource(securityId)
        ),
        value: round(value, 6)
      })
    }

    if (targetValues.length === 0) {
      continue
    }

    accountsData.push({
      targetValues,
      account: api.buildUrl(AccountEndpoints.pathToResource(accountId))
    })
  }
  return {
    accountsData,
    marketPriceOverrides: state.bulkRebalanceParams.instruments
      .filter(
        (instrument) =>
          typeof instrument.marketPriceOverride === 'number' &&
          instrument.allowTrading
      )
      .map((instrument) => ({
        instrument: instrument.instrument,
        marketPrice: instrument.marketPriceOverride
      })),
    includePending,
    includeCashEquivalents,
    instrumentCommissions: state.bulkRebalanceParams.instruments.map(
      (instrument) => {
        return {
          instrument: instrument.instrument,
          commission: instrument.commission,
          commissionType: instrument.commissionType
        }
      }
    ),
    desiredAction: state.bulkRebalanceParams.desiredAction,
    rebalanceRule: state.bulkRebalanceParams.rebalanceRule,
    asOfDate: state.bulkRebalanceParams.asOfDate
  }
}

/**
 * Calculates the FX rate to convert between currencies.
 * For trading between foreign currencies, converts from
 * and to the base currency.
 *
 * Note - consider moving this to `lib/currency` if we ever
 * need to use it outside of the change allocation page.
 */
export class FxRateCalculator {
  private fxRates: IFxRate[]

  private firmBaseCurrency: string

  constructor(fxRates: IFxRate[], firmBaseCurrency: string) {
    this.fxRates = fxRates
    this.firmBaseCurrency = firmBaseCurrency
  }

  calculate(fromCurrency: string, toCurrency: string): number {
    const fromToBase = 1 / this.findFxRate(fromCurrency)
    const baseToTarget = this.findFxRate(toCurrency)
    return fromToBase * baseToTarget
  }

  /**
   * Finds and returns the FX rate from the base to the specified currency
   */
  private findFxRate(currency: string): number {
    if (currency === this.firmBaseCurrency) {
      return 1
    }

    const fxRate = this.fxRates.find(
      (fxRate) => fxRate.foreignName === currency
    )

    invariant(
      fxRate,
      `Could not find matching fx rate for currency ${currency}`
    )

    return fxRate.close
  }
}

/**
 * Updates chart with all of the edited values to ensure
 * that it stays up-to-date with what the user entered manually.
 */
export function applyEditedValues(
  chart: StandardResponse,
  editedValuesBySecurityAndAccount: IEditedValuesBySecurityAndAccount
): StandardResponse {
  for (const [accountId, editedValuesBySecurityId] of Object.entries(
    editedValuesBySecurityAndAccount
  )) {
    for (const [securityId, {value, editableFieldName}] of Object.entries(
      editedValuesBySecurityId
    )) {
      chart = chart.updateValue(
        `${securityId}:${editableFieldName}`,
        accountId,
        value
      )
    }
  }

  return chart
}

export interface IEditedValueErrorsBySecurityAndAccount {
  [accountId: string]: {
    [securityId: string]: {
      editableFieldName: BulkChartEditableFieldName
      errorMessage?: string
    }
  }
}

const MAX_TARGET_VALUE = 999999999.999999 // max accepted by PS
const MIN_TARGET_VALUE = MAX_TARGET_VALUE * -1 // min accepted by PS
function generateMinMaxSchemaUsingFormula(
  formula: (targetValue: number) => number
): Yup.Schema<number> {
  return Yup.number()
    .min(round(formula(MIN_TARGET_VALUE), 6))
    .max(round(formula(MAX_TARGET_VALUE), 6))
}

/**
 * For each field, there are lower and higher limits that are accepted
 * relative to other values for the instrument / security (ex. market price).
 *
 * This maps each editable field name type to a function which returns
 * a validation schema of that field's value relative to the account/security's
 * existing values.
 */
const GET_SCHEMA_BY_FIELD_TYPE: Record<
  BulkChartEditableFieldName,
  (
    accountData: AccountData,
    securityId: string,
    accountAllocationChart: StandardResponse,
    fxRateCalculator: FxRateCalculator
  ) => Yup.Schema<number>
> = {
  [BulkChartEditableFieldName.quantityChange]: (accountData, securityId) =>
    generateMinMaxSchemaUsingFormula(
      (targetValue) =>
        targetValue / accountData.getPriceOfSecurity(securityId) -
        accountData.getCurrentQuantityOfSecurity(securityId)
    ),
  [BulkChartEditableFieldName.weightChange]: (
    accountData,
    securityId,
    accountAllocationChart,
    fxRateCalculator
  ) => {
    const securityCurrency = getSecurityCurrency(
      accountAllocationChart,
      securityId
    )
    const fxRate = fxRateCalculator.calculate(
      accountData.getCurrency(),
      securityCurrency
    )

    return generateMinMaxSchemaUsingFormula(
      (targetValue) =>
        targetValue / (accountData.getMarketValue() * fxRate) -
        accountData.getCurrentWeightOfSecurity(securityId)
    )
  },
  [BulkChartEditableFieldName.valueChange]: (accountData, securityId) =>
    generateMinMaxSchemaUsingFormula(
      (targetValue) =>
        targetValue - accountData.getCurrentValueOfSecurity(securityId)
    ),
  [BulkChartEditableFieldName.targetQuantity]: (accountData, securityId) =>
    generateMinMaxSchemaUsingFormula(
      (targetValue) => targetValue / accountData.getPriceOfSecurity(securityId)
    ),
  [BulkChartEditableFieldName.targetValue]: (accountData, securityId) =>
    generateMinMaxSchemaUsingFormula((targetValue) => targetValue),
  [BulkChartEditableFieldName.targetWeight]: (
    accountData,
    securityId,
    accountAllocationChart,
    fxRateCalculator
  ) => {
    const securityCurrency = getSecurityCurrency(
      accountAllocationChart,
      securityId
    )
    const fxRate = fxRateCalculator.calculate(
      accountData.getCurrency(),
      securityCurrency
    )

    return generateMinMaxSchemaUsingFormula(
      (targetValue) => targetValue / (accountData.getMarketValue() * fxRate)
    )
  }
}

/**
 * Returns of a mapping of account/security ids to either an error message
 * if the value is invalid, or `null` if not.
 */
export function getEditedValueErrorsBySecurityAndAccount(
  state: Pick<
    IAccountAllocationState,
    | 'accountAllocationChart'
    | 'bulkRebalanceParams'
    | 'editedValuesBySecurityAndAccount'
    | 'baseCurrency'
    | 'fxRates'
  >
): IEditedValueErrorsBySecurityAndAccount {
  const itemsById = keyBy(state.accountAllocationChart.data.items, 'id')

  const fxRateCalculator = new FxRateCalculator(
    state.fxRates,
    state.baseCurrency
  )

  return mapValues(
    state.editedValuesBySecurityAndAccount,
    (valuesBySecurity, accountId) => {
      const accountData = new AccountData(itemsById[accountId])

      return mapValues(
        valuesBySecurity,
        ({value, editableFieldName}, securityId) => {
          return {
            editableFieldName,
            errorMessage: ((): string | null => {
              const schema = GET_SCHEMA_BY_FIELD_TYPE[editableFieldName](
                accountData,
                securityId,
                state.accountAllocationChart.data,
                fxRateCalculator
              )

              try {
                schema.validateSync(value)
                return null
              } catch (error) {
                if (!(error instanceof Yup.ValidationError)) {
                  throw error
                }

                return error.message
              }
            })()
          }
        }
      )
    }
  )
}

/**
 * Returns true if there are any edited allocation value errors
 */
export function someEditedAllocationValueErrors(
  editedValueErrorsByAccountAndSecurityId: IEditedValueErrorsBySecurityAndAccount
): boolean {
  return Object.values(editedValueErrorsByAccountAndSecurityId || {}).some(
    (errorsBySecurityId) =>
      Object.values(errorsBySecurityId).some((value) => !!value.errorMessage)
  )
}

export function getWarningsByAccountId(
  chart: StandardResponse
): Dictionary<string[]> {
  const warningsByAccountId: Dictionary<string[]> = {}

  for (const item of chart.items) {
    warningsByAccountId[item.id] = []
    if (
      item.options?.status === CHARTTABLEOPTIONS_STATUS.WARNING &&
      Array.isArray(item.options?.context?.warnings)
    ) {
      warningsByAccountId[item.id].push(...item.options?.context.warnings)
    }
  }

  return warningsByAccountId
}

/**
 *  Creates a Dictionary of changed IDs
 * @param recommendedChart - StandardResponse
 */
export function getIsChangedByAccountId(
  recommendedChart: StandardResponse
): Dictionary<boolean> {
  const result = {}
  for (const item of recommendedChart.items) {
    result[item.id] = accountIsEdited(item)
  }
  return result
}
/**
 * Returns true if account was changed by checking if 'value_change' of account is not 0
 * @param item - StandardResponse
 */
function accountIsEdited(item: StandardResponseItem): boolean {
  return item.data.some((datum) => {
    const [, editableFieldName] = datum.categoryId.split(':')
    if (editableFieldName === 'value_change') {
      return datum.value !== 0
    }
    return false
  })
}

/**
 * Finds initial target weight for a model portfolio security's based
 * on the model portfolio's target weight
 */
export function getInitialTargetWeightForModelPortfolioSecurity(
  instrument: IInstrument,
  draft: IRebalancingDraftResponse
): number {
  const modelValue = draft.find(
    (modelDraft) => modelDraft.instrument === instrument.url
  )?.targetWeight
  return modelValue == null ? null : round(modelValue, 4) // round values to 4 to get 2 decimal precision with percentage
}

/**
 * Filters the Account Allocation Table based on the accounts checked in the table
 * @param allocationTable - StandardResponse
 * @param checkedAccountsIds - string[]
 */
export function filterAllocationTableByCheckedAccounts(
  allocationTable: StandardResponse,
  checkedAccountsIds: string[]
): StandardResponse {
  return produce(allocationTable, (draftChart: StandardResponse) => {
    draftChart.applyItemFilter((item) => checkedAccountsIds.includes(item.id))
    return draftChart
  })
}
