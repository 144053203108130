import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'
import {getLabelWithValue} from '@d1g1t/lib/value-label'

import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'
import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'

import * as commonCss from '@d1g1t/shared/containers/standard-table/styles.scss'

export interface IEditableEnumProps extends IDataCellProps {
  options: IValueLabelOption<string | boolean, string>[]
}

export const EditableEnum: React.FC<IEditableEnumProps> = React.memo(
  (props) => {
    const cellData = props.item.getDatum(props.category.id)
    const cellValue = props.item.getClosestValue(props.category.id)
    const cellKey = props.item.getClosestKey(props.category.id)
    const keyOrValue = cellKey ?? cellValue

    const handleChange = (changeValue: string | boolean) => {
      if (cellKey === changeValue || cellValue === changeValue) {
        return
      }

      const option = props.options.find(
        (option) => option.value === changeValue
      )

      // TODO: Does not make sense to send back option.label as a value, need to look into this
      props.onChange(props.item, props.category, option.label, changeValue)
    }

    // Checks whether the cell, item row or category has
    // editable set to true
    if (
      !(
        cellData.options?.editable ||
        props.category.options?.editable ||
        props.item.options?.editable
      )
    ) {
      return (
        <div
          className={classNames(commonCss.innerCell, {
            [commonCss.compactModeInnerCell]: props.isCompactMode
          })}
        >
          {getLabelWithValue(keyOrValue, props.options)}
        </div>
      )
    }

    return (
      <ValueLabelSelect
        // always open if in single cell editor mode
        open={!!props.setActiveEditingCellLocation || undefined}
        onClose={
          props.setActiveEditingCellLocation
            ? () => props.setActiveEditingCellLocation(null)
            : undefined
        }
        size={props.isCompactMode ? 'compact' : 'medium'}
        whiteBackground
        value={keyOrValue}
        options={props.options}
        onChange={handleChange}
      />
    )
  }
)

export const createEditableEnum = (
  options: IValueLabelOption[]
): React.FC<IDataCellProps> => {
  return (props) => {
    return <EditableEnum {...props} options={options} />
  }
}
