import {invert} from 'lodash'

import {
  METRIC_T_ACCRUED_INTEREST,
  METRIC_T_BROKER,
  METRIC_T_COMMISSION,
  METRIC_T_COMMISSION_TYPE,
  METRIC_T_DIVIDEND_TREATMENT,
  METRIC_T_EXPECTED_SETTLED,
  METRIC_T_EXPECTED_TRADED,
  METRIC_T_EXPIRY_DATE,
  METRIC_T_EXPIRY_TYPE,
  METRIC_T_FX_RATE,
  METRIC_T_INSTRUCTIONS,
  METRIC_T_IS_ALL_OR_NONE,
  METRIC_T_IS_ANONYMOUS,
  METRIC_T_IS_ICEBERG,
  METRIC_T_IS_INSIDER,
  METRIC_T_IS_ODD_LOT,
  METRIC_T_IS_PRO,
  METRIC_T_LIMIT_PRICE,
  METRIC_T_MARKET,
  METRIC_T_MODIFIED,
  METRIC_T_NEGO_ID,
  METRIC_T_NOTES,
  METRIC_T_OPERATION,
  METRIC_T_QTY,
  METRIC_T_QTY_TYPE,
  METRIC_T_SETTLED,
  METRIC_T_TAX_WITHHELD,
  METRIC_T_TOTAL_COMMISSION,
  METRIC_T_TRADE_VALUE_GROSS,
  METRIC_T_TRADE_VALUE_NET,
  METRIC_T_TRADED,
  METRIC_T_TYPE
} from '@d1g1t/api/models'

import {IFilterGenerator} from '@d1g1t/shared/wrappers/chart-paginator/typings'

import {manageOrdersQuantityFieldFilterGenerator} from './lib'

export const PREFIX = 'BLOTTER-LIST'

export enum TRADE_ORDER_KEYS {
  ACCRUED_INTEREST = 'accruedInterest',
  BROKER = 'broker',
  COMMISSION = 'commission',
  COMMISSION_TYPE = 'commissionType',
  DIVIDEND_TREATMENT = 'dividendTreatment',
  EXPIRY_DATE = 'expiryDate',
  EXPIRY_TYPE = 'expiryType',
  ICEBERG = 'isIceberg',
  INSIDER = 'isInsider',
  INSTRUCTIONS = 'instructions',
  LIMIT = 'limitPrice',
  MARKET = 'market',
  NOTES = 'notes',
  ODD_LOT = 'isOddLot',
  OPERATION = 'operation',
  ORDER_TYPE = 'type',
  PRO_STATUS = 'proStatus',
  QUANTITY = 'qty',
  QUANTITY_TYPE = 'qtyType',
  IS_ALL_OR_NONE = 'isAllOrNone',
  IS_ANONYMOUS = 'isAnonymous',
  IS_SELL_ALL = 'isSellAll',
  STRATEGY = 'strategy',
  ROUTE_ID = 'routeId',
  TRADED = 'traded',
  SETTLED = 'settled',
  MODIFIED = 'modified',
  PROPOSED_ALLOCATION = 'proposedAllocation',
  TRADE_VALUE = 'tradeValueGross',
  TRADE_VALUE_NET = 'tradeValueNet',
  TAX_WITHHELD = 'taxWithheld',
  TOTAL_COMMISSION = 'totalCommission',
  TRADE_DATE = 'traded',
  SECURITY_TO_SETTLEMENT_FX_RATE = 'securityToSettlementFxRate',
  NEGO_ID = 'negoId',
  EXPECTED_TRADED = 'expectedTraded',
  EXPECTED_SETTLED = 'expectedSettled'
}

export const TRADE_ORDER_CATEGORY_ID_TO_KEY_MAP = {
  [METRIC_T_ACCRUED_INTEREST.slug]: TRADE_ORDER_KEYS.ACCRUED_INTEREST,
  [METRIC_T_BROKER.slug]: TRADE_ORDER_KEYS.BROKER,
  [METRIC_T_COMMISSION.slug]: TRADE_ORDER_KEYS.COMMISSION,
  [METRIC_T_COMMISSION_TYPE.slug]: TRADE_ORDER_KEYS.COMMISSION_TYPE,
  [METRIC_T_DIVIDEND_TREATMENT.slug]: TRADE_ORDER_KEYS.DIVIDEND_TREATMENT,
  [METRIC_T_EXPIRY_DATE.slug]: TRADE_ORDER_KEYS.EXPIRY_DATE,
  [METRIC_T_EXPIRY_TYPE.slug]: TRADE_ORDER_KEYS.EXPIRY_TYPE,
  [METRIC_T_IS_ICEBERG.slug]: TRADE_ORDER_KEYS.ICEBERG,
  [METRIC_T_IS_INSIDER.slug]: TRADE_ORDER_KEYS.INSIDER,
  [METRIC_T_INSTRUCTIONS.slug]: TRADE_ORDER_KEYS.INSTRUCTIONS,
  [METRIC_T_LIMIT_PRICE.slug]: TRADE_ORDER_KEYS.LIMIT,
  [METRIC_T_MARKET.slug]: TRADE_ORDER_KEYS.MARKET,
  [METRIC_T_NOTES.slug]: TRADE_ORDER_KEYS.NOTES,
  [METRIC_T_IS_ODD_LOT.slug]: TRADE_ORDER_KEYS.ODD_LOT,
  [METRIC_T_OPERATION.slug]: TRADE_ORDER_KEYS.OPERATION,
  [METRIC_T_TYPE.slug]: TRADE_ORDER_KEYS.ORDER_TYPE,
  [METRIC_T_QTY.slug]: TRADE_ORDER_KEYS.QUANTITY,
  [METRIC_T_QTY_TYPE.slug]: TRADE_ORDER_KEYS.QUANTITY_TYPE,
  [METRIC_T_IS_ALL_OR_NONE.slug]: TRADE_ORDER_KEYS.IS_ALL_OR_NONE,
  [METRIC_T_IS_ANONYMOUS.slug]: TRADE_ORDER_KEYS.IS_ANONYMOUS,
  [METRIC_T_IS_PRO.slug]: TRADE_ORDER_KEYS.PRO_STATUS,
  [METRIC_T_TRADED.slug]: TRADE_ORDER_KEYS.TRADED,
  [METRIC_T_SETTLED.slug]: TRADE_ORDER_KEYS.SETTLED,
  [METRIC_T_MODIFIED.slug]: TRADE_ORDER_KEYS.MODIFIED,
  [METRIC_T_TRADE_VALUE_GROSS.slug]: TRADE_ORDER_KEYS.TRADE_VALUE,
  [METRIC_T_TRADE_VALUE_NET.slug]: TRADE_ORDER_KEYS.TRADE_VALUE_NET,
  [METRIC_T_TAX_WITHHELD.slug]: TRADE_ORDER_KEYS.TAX_WITHHELD,
  [METRIC_T_TOTAL_COMMISSION.slug]: TRADE_ORDER_KEYS.TOTAL_COMMISSION,
  [METRIC_T_TRADED.slug]: TRADE_ORDER_KEYS.TRADE_DATE,
  [METRIC_T_FX_RATE.slug]: TRADE_ORDER_KEYS.SECURITY_TO_SETTLEMENT_FX_RATE,
  [METRIC_T_NEGO_ID.slug]: TRADE_ORDER_KEYS.NEGO_ID,
  [METRIC_T_EXPECTED_TRADED.slug]: TRADE_ORDER_KEYS.EXPECTED_TRADED,
  [METRIC_T_EXPECTED_SETTLED.slug]: TRADE_ORDER_KEYS.EXPECTED_SETTLED
}

export const TRADE_ORDER_KEY_TO_CATEGORY_ID_MAP = invert(
  TRADE_ORDER_CATEGORY_ID_TO_KEY_MAP
)

export enum BLOTTER_CATEGORY_IDS {
  IS_AGGREGATED_PARENT = 'is_aggregated_parent',
  IS_SELL_ALL = 'is_sell_all',
  IS_DSC_FREE = 'is_dsc_free',
  INSTRUMENT_TRADING_TYPE = 'trading_type',
  IS_FUND = 'is_fund'
}

/**
 * Additional filters to be applied for the `METRIC_T_QTY` column in the `manage-orders` page
 */
export const MANAGE_ORDERS_ADDITIONAL_FITLERS: Dictionary<IFilterGenerator> = {
  [METRIC_T_QTY.slug]: manageOrdersQuantityFieldFilterGenerator
}

export const proAndNonProErrorMsg =
  'This action will result in a bundling of Pro and non-Pro trade orders.'
