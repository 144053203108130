import {ApiRequestParams, useApi} from 'fairlight'
import FileSaver from 'file-saver'

import {MIME_TYPE} from '@d1g1t/lib/constants'

import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'

/**
 *
 * @param request - Api Request params. Must resolve as a `blob` with correct Content-Type
 * @param filename - File name without extension (_____.xlsx)
 */
export function useExportExcel(
  request: ApiRequestParams<'POST', Blob>,
  filename: string
): () => void {
  const api = useApi()
  const {handleUnexpectedError} = useErrorHandler()

  return async function exportExcel() {
    try {
      // TS 3.8 bug, remove after upgrading to TS > 3.9.6
      // @ts-ignore
      const fileContent = await api.request(request)
      if (fileContent.type !== MIME_TYPE.excel) {
        throw new Error('Received file is not an excel file')
      }

      FileSaver.saveAs(
        new Blob([fileContent], {
          type: MIME_TYPE.excel
        }),
        `${filename}.xlsx`
      )
    } catch (error) {
      handleUnexpectedError(error, 'Could not create excel export')
    }
  }
}
