import {useMemo} from 'react'

import {
  ApiRequestMethod,
  ApiRequestParams,
  useApiQuery,
  UseApiQueryActions,
  UseApiQueryOptions
} from 'fairlight'

import {IChartTable} from '@d1g1t/api/models'

import {StandardResponse} from '@d1g1t/lib/standard-response'

/**
 * Wraps `useApiQuery` with a standard response
 *
 * Additionally, it sets `deduplicate` to `true` by default.
 */
export function useStandardResponseQuery(
  params: ApiRequestParams<ApiRequestMethod, IChartTable>,
  opts?: UseApiQueryOptions<StandardResponse>
): [Loadable<StandardResponse>, UseApiQueryActions<StandardResponse>] {
  const [queryData, queryActions] = useApiQuery<IChartTable>(params, opts)

  const queryDataWithStandardResponse = useMemo(
    () => ({
      ...queryData,
      data: queryData.data
        ? new StandardResponse(queryData.data)
        : (queryData.data as null)
    }),
    [queryData]
  )

  return [
    queryDataWithStandardResponse,
    {
      ...queryActions,
      setData: (data) => {
        if (typeof data === 'function') {
          queryActions.setData((prev) =>
            data(new StandardResponse(prev)).toChartTable()
          )
        } else {
          queryActions.setData(data.toChartTable())
        }
      }
    }
  ]
}
