import {isEqual, pick} from 'lodash'

export const partialIsEqual = <T extends {}>(
  props: T,
  nextProps: T,
  compareKeys: (keyof T)[],
  deep = false
): boolean => {
  if (deep) {
    return isEqual(pick(props, compareKeys), pick(nextProps, compareKeys))
  }

  /**
   * Using for-index-access loop over for-of because it is at least 50% faster
   * in today's (2018) JS engines -- probably due to limitations in the optimizing compiler
   * See https://jsperf.com/js-for-of-vs-for-index-access
   */
  for (let i = 0; i < compareKeys.length; i++) {
    if (props[compareKeys[i]] !== nextProps[compareKeys[i]]) {
      return false
    }
  }

  return true
}
