import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './style.scss'

interface IPageContentComponentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Adds 70dp bottom padding.
   */
  saveBarBottomPadding?: boolean
  /**
   * Adds 85dp bottom padding.
   */
  recommendedTradesBarBottomPadding?: boolean
}

export type IPageContentProps = RequireOnlyOne<
  IPageContentComponentProps,
  'saveBarBottomPadding' | 'recommendedTradesBarBottomPadding'
>

/**
 * Page content is used to restrict the width of the page
 * from `fluid` to `fixed` or `expandable`.
 *
 * Used to add consistent bottom padding for a "save bar" or
 * for a 'recommended trades bar'.
 */
export const PageContent: React.FC<IPageContentProps> = ({
  saveBarBottomPadding,
  recommendedTradesBarBottomPadding,
  ...props
}) => {
  return (
    <div
      className={classNames(css.page, props.className, {
        [css.saveBarBottomPadding]: saveBarBottomPadding,
        [css.recommendedTradesBarBottomPadding]:
          recommendedTradesBarBottomPadding
      })}
      {...props}
    />
  )
}
