import {useMemo} from 'react'

export function usePrefixedId(prefix: string, id?: string): string {
  return useMemo(() => {
    if (!id) {
      return prefix
    }

    return `${prefix}-${id}`
  }, [prefix, id])
}
