import React, {useEffect} from 'react'
import {Prompt} from 'react-router-dom'

import {useFormikContext} from 'formik'

const DEFAULT_MESSAGE = 'Do you want to leave?'

interface IFormUnsavedPromptProps {
  message?: string
}

/**
 * When the parent Formik form is dirty, it will prompt the user
 * to confirm that they want to navigate away. This works via
 * history changes or window close/refresh.
 */
export const FormUnsavedPrompt: React.FC<IFormUnsavedPromptProps> = ({
  message = DEFAULT_MESSAGE
}) => {
  const {dirty, isSubmitting} = useFormikContext()

  /**
   * Handle window close/refresh.
   *
   * TODO - remove this once we upgrade to `react-router@v6`, since it should
   * already be implemented in `history@v5`
   *
   * https://github.com/ReactTraining/history/blob/083400cbde5da4d4de7286adf953e462ec819879/docs/blocking-transitions.md#caveats
   */
  useEffect(() => {
    if (!dirty) {
      return
    }

    const onBeforeUnload = (event) => {
      event.returnValue = message
    }

    window.addEventListener('beforeunload', onBeforeUnload)
    return () => window.removeEventListener('beforeunload', onBeforeUnload)
  }, [dirty])

  /**
   * Handle history changes.
   */
  return <Prompt when={dirty && !isSubmitting} message={message} />
}
