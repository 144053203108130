import {IFilterRule} from '@d1g1t/api/models'

import {ISearchResult} from '@d1g1t/shared/containers/search'
import {ISelectedEntity} from '@d1g1t/shared/containers/select-entities'

import {IEntityChipProps} from './components/entity-chip'

/**
 * `SelectEntities` waits to render children if this returns `true`.
 * `EntityChip` adds the filter if this returns `true`.
 *
 * @returns `true` if default rule filters (if any) should be applied.
 */
export function shouldApplyDefaultRuleFilters(
  entityChipProps: IEntityChipProps,
  defaultRuleFilters: IFilterRule[],
  entitySelection: ISelectedEntity,
  entitySearchResult: ISearchResult
): boolean {
  return (
    !entityChipProps?.disableRuleBasedFiltering &&
    defaultRuleFilters?.length > 0 &&
    !entitySelection.touchedRuleFilters &&
    (!entitySelection.ruleFilters || entitySelection.ruleFilters.length <= 0) &&
    !entityChipProps?.modelsWithRuleBasedFilteringDisabled?.includes(
      entitySearchResult.modelName
    )
  )
}
