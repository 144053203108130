import React, {forwardRef} from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'

import {classNames} from '@d1g1t/lib/class-names'
import {useToggleState} from '@d1g1t/lib/hooks'

import {ClientIcon} from '@d1g1t/shared/components/client-icon'
import {Flex, FlexChild} from '@d1g1t/shared/components/flex'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Text} from '@d1g1t/shared/components/typography'

import {IChipGreyBitProps, IChipGreyProps, IChipPopoutProps} from './typings'

import * as css from './style.scss'

export * from './typings'

export const ChipGrey: React.FC<IChipGreyProps> = forwardRef((props, ref) => {
  const [
    ruleFilterPopoutOpen,
    ,
    openRuleFilterPopoutOpen,
    closeRuleFilterPopoutOpen
  ] = useToggleState(false)

  if (props.loading) {
    return <LoadingChipGrey />
  }

  return (
    <Flex
      alignCenter
      onMouseEnter={openRuleFilterPopoutOpen}
      onMouseLeave={closeRuleFilterPopoutOpen}
    >
      <Flex
        ref={ref}
        alignCenter
        justifySpaceBetween
        className={classNames(css.chip, {
          [css.unFocus]: !props.focused,
          [css.clickable]: !!props.onClick
        })}
      >
        <Flex alignCenter onClick={props.onClick}>
          {props.model ? (
            <ClientIcon
              fontSize='small'
              model={props.model}
              badgeStatus={props.status}
            />
          ) : (
            props.leftIcon
          )}
          <FlexChild grow style={{marginLeft: 10}}>
            <Tooltip title={props.tooltipText} placement='bottom'>
              <Text className={css.text}>{props.text}</Text>
            </Tooltip>
          </FlexChild>
          {props.rightIcon}
        </Flex>
        <Flex alignEnd>
          {props.greyBits?.map((bit, index) => (
            <ChipGreyBit
              {...bit}
              endBit={index + 1 === props.greyBits.length}
              key={bit.text}
              focused={props.focused}
            />
          ))}
        </Flex>
        {props.children}
      </Flex>
      {props.popout?.enablePopout && ruleFilterPopoutOpen && (
        <ChipPopout {...props.popout} />
      )}
    </Flex>
  )
})

/**
 * A chip with loading spinner as the left icon.
 */
const LoadingChipGrey: React.FC = ({children, ...props}) => (
  <Flex
    alignCenter
    justifySpaceBetween
    className={classNames(css.chip, css.loading)}
    {...props}
  >
    <CircularProgress size={18} thickness={2} />
    {children}
  </Flex>
)

/**
 * The grey bits that get added to indicate the ChipGrey entity is
 * further filtered by holdings or rules.
 */
const ChipGreyBit: React.FC<IChipGreyBitProps> = forwardRef((props, ref) => {
  const [hovered, setHovered] = useToggleState(false)

  return (
    <Flex
      ref={ref}
      alignCenter
      justifySpaceBetween
      className={classNames(
        css.greyBit,
        {[css.greyBitEnd]: props.endBit},
        {[css.unFocus]: !props.focused},
        {[css.clickable]: !!props.onTextClick}
      )}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {props.onDismiss ? (
        <IconButton small onClick={props.onDismiss}>
          {hovered ? <CloseIcon fontSize='small' /> : props.leftIcon}
        </IconButton>
      ) : (
        props.leftIcon
      )}
      <Tooltip
        key={props.text}
        title={props.tooltipText}
        placement='bottom'
        onClick={props.onTextClick}
      >
        <div
          className={classNames(css.text, {
            [css.strikethrough]: props.strikethrough
          })}
        >
          {props.text}
        </div>
      </Tooltip>
    </Flex>
  )
})
/**
 * Pops out from the right side when hovering over the chip, or the grey bit(s).
 */
const ChipPopout: React.FC<IChipPopoutProps> = React.forwardRef(
  (props, ref) => {
    return (
      <Tooltip title={props.popoutTooltipText} placement='bottom' ref={ref}>
        <Flex
          justifyFlexEnd
          onClick={props.onPopoutClick}
          className={classNames(css.chipPopout)}
        >
          {props.popoutIcon}
        </Flex>
      </Tooltip>
    )
  }
)
