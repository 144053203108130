import {TRADEORDER_OPERATION, TRADEORDER_QTY_TYPE} from '@d1g1t/api/models'

export enum FIXED_INCOME_FIELD_NAMES {
  accountUrl = 'accountUrl', // must not change
  instrumentUrl = 'instrumentUrl', // must not change
  operation = 'operation', // must not change
  qty = 'qty',
  qtyType = 'qtyType',
  cusip = 'cusip',
  notes = 'notes'
}

export interface IFixedIncomeFormValues {
  [FIXED_INCOME_FIELD_NAMES.instrumentUrl]: string
  [FIXED_INCOME_FIELD_NAMES.accountUrl]: string
  [FIXED_INCOME_FIELD_NAMES.qty]: number | 'ALL'
  [FIXED_INCOME_FIELD_NAMES.qtyType]: TRADEORDER_QTY_TYPE
  [FIXED_INCOME_FIELD_NAMES.notes]: string
  [FIXED_INCOME_FIELD_NAMES.operation]: TRADEORDER_OPERATION
}
