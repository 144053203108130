import React, {useEffect} from 'react'

import {useApi} from 'fairlight'
import {isEmpty} from 'lodash'

import AddIcon from '@material-ui/icons/AddCircleOutline'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import UploadIcon from '@material-ui/icons/CloudUpload'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import HistoryIcon from '@material-ui/icons/History'

import {ImportersEndpoints, InstrumentEndpoints} from '@d1g1t/api/endpoints'
import {
  CALCULATION_CODES,
  FILTERCRITERION_NODE_TYPE,
  METRIC_S_FIRM_PROVIDED_KEY,
  METRIC_S_INSTRUMENT_CURRENCY_NAME,
  METRIC_S_INSTRUMENT_NAME,
  METRIC_T_INSTRUMENT_PUBLIC,
  METRICGROUP_RELATED_MODEL
} from '@d1g1t/api/models'

import {MIME_TYPE} from '@d1g1t/lib/constants'
import {useInputState} from '@d1g1t/lib/hooks'
import {StandardResponseItem} from '@d1g1t/lib/standard-response'

import {Card} from '@d1g1t/shared/components/card'
import {confirm} from '@d1g1t/shared/components/confirmation'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {IIconMenuMenuItemProps} from '@d1g1t/shared/components/mui/icon-menu'
import {QuickLookDrawer} from '@d1g1t/shared/components/quick-look-drawer'
import {ButtonLink} from '@d1g1t/shared/components/router-link'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {
  TableGrid,
  TableGridBody,
  TableGridHeader
} from '@d1g1t/shared/components/table-grid'
import {WidgetTitleBar} from '@d1g1t/shared/containers/calculation-widgets/widget-title-bar'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {
  ROW_STYLE_STATE,
  StandardTable,
  useTableColumnFilterControl,
  useTableSortControl
} from '@d1g1t/shared/containers/standard-table'
import {IsPublicCell} from '@d1g1t/shared/containers/standard-table/components/custom-types/boolean-cell'
import {CurrencyCell} from '@d1g1t/shared/containers/standard-table/components/custom-types/currency'
import {
  IStandardTableAction,
  StandardTableAction
} from '@d1g1t/shared/containers/standard-table/typings'
import {useSelectedView} from '@d1g1t/shared/containers/view-options'
import {useCalculationData} from '@d1g1t/shared/wrappers/calculations'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useFileUploader} from '@d1g1t/shared/wrappers/file-uploader'
import {useMarketPriceHistoryModalControl} from '@d1g1t/shared/wrappers/market-price-history'
import {usePathToggle} from '@d1g1t/shared/wrappers/path-toggle'
import {useSelectionQuery} from '@d1g1t/shared/wrappers/selection-query'

import {MarketPriceHistoryChart} from '@d1g1t/advisor/containers/market-price-history-modal'
import {PageTitleBar} from '@d1g1t/advisor/containers/page-title-bar'
import {AdministrationLocations} from '@d1g1t/advisor/locations'

import {AddSecurityModal} from './components/add-security-modal'
import {EditSecurities} from './components/edit-securities'

export const SECURITY_LIST_SAVE_ID = 'MANAGE-SECURITY-LIST'

export interface ISecuritiesListProps {
  editable?: boolean
}

const SecuritiesList: React.FC<ISecuritiesListProps> = (props) => {
  const api = useApi()
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()

  const [{displayOptions, viewKey}] = useSelectedView(SECURITY_LIST_SAVE_ID)
  const [sortOptions, setSortOptions] = useTableSortControl(
    SECURITY_LIST_SAVE_ID
  )

  const [search, setSearch] = useInputState('')
  const [selectedItemId, setSelectedItemId] = useSelectionQuery()

  const [marketPriceState, marketPriceActions] =
    useMarketPriceHistoryModalControl()
  const [addSecurityOpen, {openLink, onClose}] = usePathToggle({
    closedPath: AdministrationLocations.manageSecurities(),
    openPath: AdministrationLocations.manageSecuritiesCreate()
  })

  // On View Change, reset search query.
  useEffect(() => {
    setSearch('')
  }, [viewKey])

  const {
    columnFilterState,
    setColumnFilterState,
    showColumnFilter,
    toggleShowColumnFilter
  } = useTableColumnFilterControl(viewKey)

  const [
    securities,
    {
      fetchRanges,
      refresh: refreshSecurities,
      updateData: setSecurities,
      exportExcel
    }
  ] = useCalculationData({
    calculationCode: CALCULATION_CODES.ADMIN_SECURITIES,
    loadDataIncrementally: true,
    waitFor: [displayOptions],
    filter: displayOptions?.filter,
    metricViews: displayOptions?.metrics,
    transform: {
      fullTextFilter: search,
      columnSort: sortOptions,
      showCurrentDataOnly: displayOptions?.displayData?.showCurrentDataOnly,
      columnFilter:
        showColumnFilter || isEmpty(columnFilterState)
          ? columnFilterState
          : EMPTY_OBJECT
    }
  })

  const [uploadingSecurities, openSecuritiesFileUploader] = useFileUploader({
    mimeTypes: [MIME_TYPE.csv, MIME_TYPE.excel],
    upload: (selectedFiles) => {
      const data = new FormData()
      data.append('file', selectedFiles[0])
      return api.request(ImportersEndpoints.instrumentsUpload(data))
    },
    onSuccess: refreshSecurities,
    successWithLink: true
  })

  const [uploadingMarketPrices, openMarketPricesFileUploader] = useFileUploader(
    {
      mimeTypes: [MIME_TYPE.csv, MIME_TYPE.excel],
      upload: (selectedFiles) => {
        const data = new FormData()
        data.append('file', selectedFiles[0])
        return api.request(ImportersEndpoints.marketPricesUpload(data))
      },
      onSuccess: refreshSecurities,
      successWithLink: true
    }
  )

  const handleDeleteSecurity = async (security: StandardResponseItem) => {
    const name = security.getValue(METRIC_S_INSTRUMENT_NAME.slug)

    const securityId = security.getValue(METRIC_S_FIRM_PROVIDED_KEY.slug)

    const confirmation = confirm({
      title: 'Delete Security',
      content: `Do you want to delete ${name}, ${securityId}?`,
      confirmLabel: 'Delete'
    })
    if (!(await confirmation)) {
      return
    }

    try {
      await api.request(InstrumentEndpoints.destroy(security.id))

      showSnackbar({
        variant: 'success',
        message: 'Security deleted'
      })

      refreshSecurities()

      // Close the edit modal
      setSelectedItemId(null)
    } catch (error) {
      handleUnexpectedError(error, 'Could not delete security')
    }
  }

  const tableActions: StandardTableAction = (item: StandardResponseItem) => {
    const actions: IStandardTableAction[] = [
      {
        label: 'View Market Price History',
        icon: <HistoryIcon />,
        onClick: () => {
          marketPriceActions.showMarketPriceHistory(
            item.id,
            item.getValue(METRIC_S_INSTRUMENT_NAME.slug)
          )
        }
      },
      {
        label: 'Edit Market Prices',
        icon: <EditIcon />,
        onClick: () => {
          marketPriceActions.showMarketPriceHistory(
            item.id,
            item.getValue(METRIC_S_INSTRUMENT_NAME.slug),
            {editMode: true}
          )
        }
      }
    ]

    if (props.editable) {
      actions.push({
        label: 'Delete Security',
        icon: <DeleteIcon />,
        onClick: () => {
          handleDeleteSecurity(item)
        }
      })
    }

    return actions
  }

  const iconMenuOptions: IIconMenuMenuItemProps[] = (() => {
    const options: IIconMenuMenuItemProps[] = [
      {
        label: 'Import Market Price',
        icon: <UploadIcon />,
        onClick: openMarketPricesFileUploader
      },
      {
        label: 'Export Data to Excel',
        onClick: exportExcel,
        icon: <DownloadIcon />
      }
    ]

    if (props.editable) {
      options.unshift({
        label: 'Import Securities',
        onClick: openSecuritiesFileUploader,
        icon: <UploadIcon />
      })
    }

    return options
  })()

  const isLoading =
    uploadingSecurities || uploadingMarketPrices || securities.loading

  return (
    <>
      <PageTitleBar noBottomMargin title='Securities' />
      <Card fullPageHeight>
        <TableGrid>
          <TableGridHeader>
            <WidgetTitleBar
              heading={{
                ViewOptionsProps: {
                  id: SECURITY_LIST_SAVE_ID,
                  configuration: {
                    metrics: {
                      includeRelatedModels: [
                        METRICGROUP_RELATED_MODEL.SECURITY
                      ],
                      singleLevel: true
                    },
                    filters: {
                      types: [FILTERCRITERION_NODE_TYPE.SECURITY]
                    },
                    showDisplayData: true,
                    newViewDefaultDisplayData: {
                      showCurrentDataOnly: false
                    }
                  }
                }
              }}
              SearchProps={{
                value: search,
                placeholder: 'Search securities containing...',
                onChange: setSearch
              }}
              secondaryControls={
                props.editable && (
                  <>
                    <Spacer vertical xxs />
                    <ButtonLink
                      outlined
                      to={openLink}
                      data-testid='button_add_securities'
                    >
                      <AddIcon />
                      Create Security
                    </ButtonLink>
                    {addSecurityOpen && (
                      <AddSecurityModal
                        onCancel={onClose}
                        onCreate={() => {
                          refreshSecurities()
                          onClose()
                        }}
                      />
                    )}
                  </>
                )
              }
              overflowMenuOptions={iconMenuOptions}
            />
          </TableGridHeader>
          <TableGridBody>
            <LoadingContainer loading={isLoading} />
            {securities.data && (
              <>
                <div>
                  <StandardTable
                    id={SECURITY_LIST_SAVE_ID}
                    table={securities.data}
                    sortedColumn={sortOptions}
                    loading={securities.loading}
                    rowsInStyleState={{
                      [ROW_STYLE_STATE.HIGHLIGHTED]: [selectedItemId]
                    }}
                    defaultNameColumnWidth={220}
                    actions={tableActions}
                    onSortClick={setSortOptions}
                    onItemsRendered={fetchRanges}
                    externalToggleColumnFilterActive={toggleShowColumnFilter}
                    externalSetColumnFilterInputStateAtKey={
                      setColumnFilterState
                    }
                    onItemSelect={
                      props.editable ? setSelectedItemId : undefined
                    }
                    categoriesOverride={{
                      [METRIC_S_INSTRUMENT_CURRENCY_NAME.slug]: CurrencyCell,
                      [METRIC_T_INSTRUMENT_PUBLIC.slug]: IsPublicCell
                    }}
                    externalColumnFilterInputState={
                      showColumnFilter && columnFilterState
                    }
                  />
                </div>
                {marketPriceState.marketPriceHistoryOpen && (
                  <MarketPriceHistoryChart
                    editMode={marketPriceState.openInEditMode}
                    securityId={marketPriceState.securityId}
                    securityName={marketPriceState.securityName}
                    onClose={marketPriceActions.hideMarketPriceHistory}
                  />
                )}
                {props.editable && !!selectedItemId && (
                  <QuickLookDrawer
                    title='Edit Security'
                    onClose={() => setSelectedItemId(null)}
                  >
                    <ErrorBoundary resetId={selectedItemId}>
                      <EditSecurities
                        data={securities.data}
                        selectedItemId={selectedItemId}
                        categories={securities.data.dataCategories}
                        onUpdate={setSecurities}
                      />
                    </ErrorBoundary>
                  </QuickLookDrawer>
                )}
              </>
            )}
          </TableGridBody>
        </TableGrid>
      </Card>
    </>
  )
}

export default SecuritiesList
