import React, {useEffect, useMemo, useRef, useState} from 'react'

import {useApi, useApiQuery} from 'fairlight'
import produce from 'immer'
import {isEqual, overEvery} from 'lodash'

import AddIcon from '@material-ui/icons/AddCircleOutline'
import RefreshIcon from '@material-ui/icons/Refresh'
import CustomizeDisplayIcon from '@material-ui/icons/Settings'
import WarningIcon from '@material-ui/icons/Warning'

import {RuleFilterEndpoints, SearchEndpoints} from '@d1g1t/api/endpoints'
import {
  ALL_MODELS,
  FIRMCONFIGURATION_WIDGETS_CONFIG_KEYS,
  UI_PERMISSIONS
} from '@d1g1t/api/models'
import {IControl} from '@d1g1t/typings/general'

import {classNames} from '@d1g1t/lib/class-names'
import {getControlId} from '@d1g1t/lib/control'
import {useToggleState} from '@d1g1t/lib/hooks'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {Bar} from '@d1g1t/shared/components/bar'
import {Card} from '@d1g1t/shared/components/card'
import {ComboKeyListener} from '@d1g1t/shared/components/combo-key-listener'
import {Flex, FlexChild} from '@d1g1t/shared/components/flex'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {
  IconMenu,
  IIconMenuMenuItemProps
} from '@d1g1t/shared/components/mui/icon-menu'
import {OverlapBar} from '@d1g1t/shared/components/overlap-bar'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H4} from '@d1g1t/shared/components/typography'
import {CalculationOverride} from '@d1g1t/shared/containers/calculation-override'
import {BasicInfo} from '@d1g1t/shared/containers/calculation-widgets/basic-info'
import {ISearchResult, Search} from '@d1g1t/shared/containers/search'
import {SearchWrapper} from '@d1g1t/shared/containers/search/search-wrapper'
import {ISelectedEntity} from '@d1g1t/shared/containers/select-entities'
import {DEFAULT_BASIC_INFO_METRICS} from '@d1g1t/shared/containers/select-entities/constants'
import {
  ISelectEntitiesContext,
  SelectEntitiesContext
} from '@d1g1t/shared/containers/select-entities/context'
import {
  useDefaultReportingCurrency,
  useSelectEntitiesStateFromProps
} from '@d1g1t/shared/containers/select-entities/hooks'
import {buildControlFromState} from '@d1g1t/shared/containers/select-entities/lib'
import {
  useSelectedView,
  ViewOptions
} from '@d1g1t/shared/containers/view-options'
import {useCalculationOptionsOverride} from '@d1g1t/shared/wrappers/calculation-options'
import {CalculationSettingsOverrideProvider} from '@d1g1t/shared/wrappers/calculation-settings'
import {
  EntityNotFound,
  ErrorBoundary
} from '@d1g1t/shared/wrappers/error-boundary'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {
  generateMetricsConfig,
  generateWidgetConfigurationKey,
  getDefaultMetrics
} from '@d1g1t/shared/wrappers/firm-configuration/lib'
import {usePermissions} from '@d1g1t/shared/wrappers/permissions'

import {EmptyStateCard} from '@d1g1t/advisor/components/empty-state-card'
import {PageTitleBar} from '@d1g1t/advisor/containers/page-title-bar'

import {EntityChip, EntityChipProvider} from './components/entity-chip'
import {EntityRefresh} from './components/entity-refresh'
import {IRefreshEntityRef} from './components/entity-refresh/typings'
import {SelectEntitiesLeftDrawer} from './components/left-drawer'
import {LeftDrawerTab} from './components/left-drawer-tab'
import {shouldApplyDefaultRuleFilters} from './lib'
import {IAdvisorSelectedEntitiesProps} from './typings'

import * as css from '@d1g1t/shared/containers/select-entities/style.scss'

/**
 * @see  /pages/manage-client - Manage Client
 * @see  /pages/compare-strategies - Compare Portfolios
 * @see  /pages/rebalance - Rebalance
 * @see  /pages/rebalance-portfolios - Rebalance Portfolios
 * @see  /pages/bulk-change-allocation - Trade Directive
 * @see  /pages/trade/buy-sell - Buy & Sell
 * @see  /pages/security-model - Model Portfolios
 * @see  /pages/manage-funds - Manage Funds
 * @see  /containers/trade-modal - Trade Modal
 */
export const SelectEntities: React.FC<IAdvisorSelectedEntitiesProps> = ({
  renderedChipsLimit = 5,
  ...props
}) => {
  const api = useApi()
  const {firmConfiguration} = useFirmConfiguration()
  const permissions = usePermissions()

  const refreshEntityRef = useRef<IRefreshEntityRef>()

  const [allRuleFilters] = useApiQuery(RuleFilterEndpoints.list(), {
    fetchPolicy: 'cache-and-fetch'
  })

  const [calculationOptions, {updateCalculationPeriodOverride}] =
    useCalculationOptionsOverride()

  // Get params state/actions from props or if not passed, use query params
  const [selectEntitiesState, selectEntitiesActions] =
    useSelectEntitiesStateFromProps(
      props.selectEntitiesState,
      props.selectEntitiesActions
    )

  const [searchBoxOpen, setSearchBoxOpen] = useState(false)
  const [leftDrawerVisibility, toggleLeftDrawerVisibility] = useToggleState(
    selectEntitiesState.selected?.length > 1
  )
  const [customizeDisplayOpen, , openCustomizeDisplay, closeCustomizeDisplay] =
    useToggleState(false)

  useEffect(() => {
    if (!selectEntitiesState.selected) {
      setSearchBoxOpen(true)
    }
  }, [selectEntitiesState.selected])

  const selectedEntityIds: string[] = useMemo(() => {
    return (
      selectEntitiesState.selected?.map(
        (selectedEntity) => selectedEntity.entityId
      ) ?? []
    )
  }, [selectEntitiesState.selected])

  const [entitySearchResults, {refetch: refetchSearch}] = useApiQuery(
    selectedEntityIds.length > 0 &&
      SearchEndpoints.search({
        q: '',
        entityIds: selectedEntityIds
      })
  )

  if (
    entitySearchResults.data &&
    entitySearchResults.data.results.length !==
      selectedEntityIds.filter(
        (entityId, index) => selectedEntityIds.indexOf(entityId) === index
      ).length
  ) {
    console.error(
      'Some selected entity IDs not found in search response:',
      selectedEntityIds
    )
    // bubbles up to frame error boundary
    throw new EntityNotFound()
  }

  // The `leftSelectionAndFocusMode` uses `focused` so it is required there.
  const focusedRequired: boolean =
    props.enableFocusControl || !!props.leftSelectionAndFocusMode

  const calculationNotSupportedFallbackEnabled =
    !firmConfiguration.data.fundsInClientPagesEnabled

  const expandedSelectEntitiesState = useMemo(() => {
    if (!entitySearchResults.data?.results || !selectEntitiesState.selected) {
      return {}
    }

    // Special case for the Buy & Sell page
    // ASSUMPTION: only Buy-Sell page passes 'searchBy: [accounts]'
    if (isEqual(props.SearchProps?.searchBy, [ALL_MODELS.ACCOUNT])) {
      return produce(selectEntitiesState, (draft) => {
        // Remove focused when it is not explicitly enabled.
        if (!focusedRequired) {
          delete draft.focused
        }
      })
    }

    return produce(selectEntitiesState, (draft) => {
      // Remove focused when it is not explicitly enabled.
      if (!focusedRequired) {
        delete draft.focused
      }

      /**
       * Filter out any entities that are not supported by this page.
       *
       * Support for a entity's model is implied by the `SearchProps.searchBy` array.
       * Also remove any entity that has `isCalcNotSupported = true` if `removeCalculationNotSupportedEntities` prop is passed.
       */
      draft.selected = draft.selected.filter((selection) => {
        const searchResult = entitySearchResults.data.results.find(
          (result) => result.entityId === selection.entityId
        )

        if (
          props.removeCalculationNotSupportedEntities &&
          calculationNotSupportedFallbackEnabled &&
          searchResult.isCalcNotSupported === true
        ) {
          return false
        }

        return props.SearchProps?.searchBy?.includes(searchResult.modelName)
      })

      if (draft.selected.length === 0) {
        delete draft.selected
        return draft
      }

      // Apply entity count limit from props.
      if (props.limit) {
        // Try to pick the focused (if compatible) when `limit` is 1
        if (props.limit === 1 && selectEntitiesState.focused) {
          const focusedIndex = draft.selected.findIndex((s) =>
            isEqual(s, selectEntitiesState.focused)
          )
          draft.selected =
            focusedIndex > -1
              ? [selectEntitiesState.focused]
              : draft.selected.slice(0, props.limit)
        } else {
          draft.selected = draft.selected.slice(0, props.limit)
        }
      }

      /**
       * Take the rule filter IDs from URL and expand them to URLs to Rule Filter resource.
       */
      for (let i = 0; i < draft.selected.length; i++) {
        if (draft.selected[i].ruleFilters) {
          draft.selected[i].ruleFilters = draft.selected[i].ruleFilters.map(
            (ruleFilter) =>
              api.buildUrl(
                RuleFilterEndpoints.pathToResource(extractIdFromUrl(ruleFilter))
              )
          )
        }
      }
      if (focusedRequired && draft.focused) {
        if (draft.focused.ruleFilters) {
          draft.focused.ruleFilters = draft.focused.ruleFilters.map(
            (ruleFilter) =>
              api.buildUrl(
                RuleFilterEndpoints.pathToResource(extractIdFromUrl(ruleFilter))
              )
          )
        }
      }

      /**
       * Filter out any rule-based, position-based, and account-based filters if the page doesn't support it.
       *
       * Support for a filter type is indicated by `EntityChipProps.disableRuleBasedFiltering`,
       * `EntityChipProps.disableHoldingsFiltering`, `EntityChipProps.filterByAccountsOnly`.
       */
      if (props.EntityChipProps) {
        // Delete from the `selected` array
        for (let i = 0; i < draft.selected.length; i++) {
          if (props.EntityChipProps.disableRuleBasedFiltering) {
            delete draft.selected[i].ruleFilters
          }

          if (props.EntityChipProps.disableHoldingsFiltering) {
            if (!props.EntityChipProps.filterByAccountsOnly) {
              delete draft.selected[i].accounts
            }

            delete draft.selected[i].positions
          }
        }

        // Delete from the `focused` object
        if (focusedRequired && draft.focused) {
          if (props.EntityChipProps.disableRuleBasedFiltering) {
            delete draft.focused.ruleFilters
          }

          if (props.EntityChipProps.disableHoldingsFiltering) {
            if (!props.EntityChipProps.filterByAccountsOnly) {
              delete draft.focused.accounts
            }

            delete draft.focused.positions
          }
        }
      }
    })
  }, [selectEntitiesState, entitySearchResults])

  useEffect(() => {
    // `focused` should be present, and valid (i.e. present in `selected`).
    if (expandedSelectEntitiesState.selected?.length > 0 && focusedRequired) {
      const focusedSelectionFromExpandedState =
        expandedSelectEntitiesState.selected.find((entity) =>
          isEqual(entity, expandedSelectEntitiesState.focused)
        )

      if (
        !expandedSelectEntitiesState.focused ||
        !focusedSelectionFromExpandedState
      ) {
        selectEntitiesActions.focusEntity(
          expandedSelectEntitiesState.selected[0], // Focus on first compatible entity
          {
            replace: true
          }
        )
      }
    }
  }, [
    focusedRequired,
    expandedSelectEntitiesState.focused,
    expandedSelectEntitiesState.selected
  ])

  const showSearchBox =
    searchBoxOpen ||
    !expandedSelectEntitiesState.selected ||
    expandedSelectEntitiesState.selected.length === 0

  const showAddIcon =
    !showSearchBox &&
    !props.readOnly &&
    (props.limit ? selectedEntityIds.length < props.limit : true)

  /**
   * Should show a special fallback for when there are no entities shown on a page,
   * and there is at least 1 entity that has `isCalcNotSupported = true`.
   */
  const calculationNotSupportedEntities: ISearchResult[] =
    props.removeCalculationNotSupportedEntities &&
    !expandedSelectEntitiesState.selected &&
    entitySearchResults.data?.results.filter(
      (result) => result.isCalcNotSupported
    )

  /**
   * Note: there is a distinction between when to use
   * `expandedSelectEntitiesState` and `selectEntitiesState`.
   *
   * `selectEntitiesState` should be used when the user's actual selection
   * is necessary. E.g. to filter out the user's selection.
   *
   * `expandedSelectEntitiesState` should be used to show user selection
   * after the filter is applied, to build control, and to make requests.
   */

  const defaultCurrency = useDefaultReportingCurrency(
    expandedSelectEntitiesState
  )

  const filterCurrentSelectionFromSearchResults = (
    searchResult: ISearchResult
  ) => {
    return !selectedEntityIds.includes(searchResult.entityId)
  }

  // Create combined filter method
  const searchFilter = overEvery([
    filterCurrentSelectionFromSearchResults,
    props.SearchProps?.filterBy
  ])

  // Build a control object based on expanded state
  const control: IControl = useMemo(() => {
    if (
      !entitySearchResults.data?.results ||
      !expandedSelectEntitiesState.selected
    ) {
      return null
    }

    return buildControlFromState(
      expandedSelectEntitiesState,
      entitySearchResults.data?.results
    )
  }, [entitySearchResults.data?.results, expandedSelectEntitiesState])

  const handleSearchResultSelect = (searchResult: ISearchResult) => {
    setSearchBoxOpen(false)
    selectEntitiesActions.addEntityFromSearchResult(searchResult)
  }

  const isEntityFocused = (entity: ISelectedEntity) => {
    // If this page does not have focus control enables, all entities
    // are always "focused"
    if (!focusedRequired) {
      return true
    }

    return isEqual(entity, expandedSelectEntitiesState?.focused)
  }

  // Get the either the first selection, or the focused selection
  const focusedSearchResult = (() => {
    const {focused} = expandedSelectEntitiesState

    if (!entitySearchResults.data) {
      return null
    }

    if (focused) {
      return entitySearchResults.data.results.find(
        (item) => item.entityId === focused.entityId
      )
    }

    return entitySearchResults.data.results[0]
  })()

  /**
   * Show a provided fallback page when nothing is selected
   */
  const showFallback =
    !expandedSelectEntitiesState.selected && props.emptyFallback !== undefined

  const contextValue: ISelectEntitiesContext = {
    selection: expandedSelectEntitiesState,
    focusedSearchResult,
    control,
    noData: control === null && !entitySearchResults.loading,
    searchResults: entitySearchResults.data?.results,
    refresh: () => refetchSearch({deduplicate: false})
  }

  /**
   * Autogenerate an id for <ViewOptions> based on the sectionName.
   */
  const basicInfoConfigViewOptionsId = `${props.BasicInfoProps?.sectionName?.toUpperCase()}-BASIC-INFO-METRICS`
  const [{displayOptions}] = useSelectedView(basicInfoConfigViewOptionsId)

  /**
   * Build the key for FirmConfiguration.widgetsConfig
   */
  const widgetsConfigKey: FIRMCONFIGURATION_WIDGETS_CONFIG_KEYS = props
    .BasicInfoProps?.sectionName
    ? generateWidgetConfigurationKey(
        props.BasicInfoProps?.sectionName,
        'basic-info'
      )
    : undefined

  /**
   * Pull the BasicInfo widget's config from the FirmConfiguration.
   */
  const basicInfoWidgetConfig = widgetsConfigKey
    ? firmConfiguration.data?.widgetConfigurations?.[widgetsConfigKey]
    : undefined

  const defaultBasicInfoMetrics = basicInfoWidgetConfig
    ? getDefaultMetrics(basicInfoWidgetConfig)
    : DEFAULT_BASIC_INFO_METRICS

  const permissionToRefreshHousehold = permissions.canAccess(
    UI_PERMISSIONS.REFRESH_HOUSEHOLD
  )

  const isRefreshableEntity =
    permissionToRefreshHousehold &&
    props.refreshEntityModels?.includes(focusedSearchResult?.modelName)

  const overflowMenuOptions = (() => {
    const menuOptions: IIconMenuMenuItemProps[] = []

    if (typeof props.overflowMenuOptions === 'function') {
      menuOptions.push(...props.overflowMenuOptions(contextValue))
    } else {
      menuOptions.push(...(props.overflowMenuOptions || []))
    }

    if (isRefreshableEntity) {
      menuOptions.push({
        icon: <RefreshIcon />,
        label: 'Refresh Household Data',
        onClick: () => refreshEntityRef.current.refreshEntity()
      })
    }

    if (basicInfoWidgetConfig) {
      menuOptions.push({
        icon: <CustomizeDisplayIcon />,
        label: 'Customize Display',
        onClick: openCustomizeDisplay
      })
    }

    if (calculationOptions) {
      menuOptions.push({
        label: 'Set all components to match date range',
        onClick: () => {
          updateCalculationPeriodOverride({
            setAllDateRange: Symbol()
          })
        }
      })
    }

    return menuOptions
  })()

  const defaultRuleFilters =
    allRuleFilters.data?.results?.filter((rule) => rule.isDefault) ?? []

  let shouldWaitForDefaultRuleFilters = !props.hideSelectionBar

  // Remove all unfocused entities when in left-drawer or focus mode
  const filteredSelectedEntities =
    allRuleFilters.data &&
    entitySearchResults.data &&
    expandedSelectEntitiesState.selected?.filter((entity) => {
      if (props.leftSelectionAndFocusMode) {
        return isEqual(entity, expandedSelectEntitiesState?.focused)
      }
      return true
    })
  // Projected chips that will be rendered
  const chipsLength = filteredSelectedEntities?.length

  const selectEntitiesTopBarElements = (() => {
    return (
      <Bar {...props.BarProps} data-testid='container-select-entities'>
        {/* Entity Refresh */}
        {isRefreshableEntity && (
          <EntityRefresh refreshEntityRef={refreshEntityRef} />
        )}

        <OverlapBar />
        <Card noMinHeight noBottomMargin shadow extraRoundedCorners noPadding>
          <Flex>
            <Flex justifySpaceBetween grow gridMargin>
              <Flex alignCenter wrap grow flexBasis={440}>
                {/* Chips */}
                {filteredSelectedEntities &&
                  filteredSelectedEntities
                    .slice(0, renderedChipsLimit)
                    .map((entity, index) => {
                      const entitySearchResult =
                        entitySearchResults.data.results.find(
                          (result) => result.entityId === entity.entityId
                        )

                      shouldWaitForDefaultRuleFilters =
                        shouldApplyDefaultRuleFilters(
                          props.EntityChipProps,
                          defaultRuleFilters,
                          entity,
                          entitySearchResult
                        ) &&
                        (expandedSelectEntitiesState.selected[0].ruleFilters
                          ?.length || 0) < 1 &&
                        !props.hideSelectionBar

                      return (
                        <EntityChipProvider
                          key={index}
                          value={{
                            entity,
                            entitySearchResult,
                            defaultRuleFilters,
                            selected: expandedSelectEntitiesState.selected,
                            focused: isEntityFocused(entity),
                            actions: selectEntitiesActions,
                            refreshToken: props.clientChipRefreshToken,
                            readOnly: props.readOnly
                          }}
                        >
                          <EntityChip {...props.EntityChipProps} />
                        </EntityChipProvider>
                      )
                    })}
                {chipsLength > renderedChipsLimit && (
                  <Flex alignCenter>
                    <H4>... and {chipsLength - renderedChipsLimit} more.</H4>
                  </Flex>
                )}
                {/* Search */}
                {!props.readOnly &&
                  (searchBoxOpen ||
                    !expandedSelectEntitiesState.selected ||
                    expandedSelectEntitiesState.selected.length === 0) && (
                    <SearchWrapper className={css.searchFocused}>
                      <Search
                        clearOnResultSelect
                        focusOnMount
                        {...props.SearchProps}
                        filterBy={searchFilter}
                        onClickAway={() => setSearchBoxOpen(false)}
                        onResultSelect={handleSearchResultSelect}
                        data-testid='search-select-entities'
                      />
                    </SearchWrapper>
                  )}
                {showAddIcon && (
                  <Flex
                    alignCenter
                    grow
                    className={css.searchContainer}
                    onClick={() => setSearchBoxOpen(true)}
                    data-testid='button-select-entities-add-entity'
                  >
                    <IconButton>
                      <AddIcon />
                    </IconButton>
                  </Flex>
                )}
              </Flex>

              <Flex alignCenter>
                {/* Basic Info */}
                {!props.disableBasicInfo && control && allRuleFilters.data && (
                  <BasicInfo
                    singleResult
                    control={control}
                    metrics={displayOptions?.metrics}
                    defaultMetrics={defaultBasicInfoMetrics}
                    {...props.BasicInfoProps}
                  />
                )}
              </Flex>
            </Flex>

            {/* As-of-date and Currency, Period Selector, 3 dot menu */}
            <CalculationOverride
              {...props.CalculationOverrideProps}
              separateWithBorder
              defaultCurrency={defaultCurrency.data?.currency}
              loadingDefaultCurrency={defaultCurrency.loading}
              disabled={props.disableCalculationSettingsOverride}
              staticValue={props.staticCalculationSettings}
            />

            {/* Overflow menu */}
            {overflowMenuOptions?.length > 0 && (
              <Flex alignCenter className={css.rightActionMenu}>
                <IconMenu
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right'
                    }
                  }}
                  IconButtonProps={{
                    style: {
                      color: 'white'
                    }
                  }}
                  options={overflowMenuOptions}
                />
              </Flex>
            )}

            {/* Control basicInfo Metrics When It's Enabled via FirmConfiguration */}
            {basicInfoWidgetConfig && (
              <ViewOptions
                id={basicInfoConfigViewOptionsId}
                hideSelect
                open={customizeDisplayOpen}
                onClose={closeCustomizeDisplay}
                maxViews={1}
                configuration={{
                  maxMetrics: 5,
                  metrics: generateMetricsConfig(basicInfoWidgetConfig)
                }}
                {...props.BasicInfoViewOptionsProps}
              />
            )}
          </Flex>
        </Card>
      </Bar>
    )
  })()

  const shouldRenderLeftDrawer =
    props.leftSelectionAndFocusMode &&
    entitySearchResults.data &&
    expandedSelectEntitiesState.selected

  return (
    <CalculationSettingsOverrideProvider
      staticValue={props.staticCalculationSettings}
    >
      <SelectEntitiesContext.Provider value={contextValue}>
        <Flex>
          {leftDrawerVisibility && shouldRenderLeftDrawer && (
            <Flex shrink className={css.leftDrawerScroll}>
              <SelectEntitiesLeftDrawer
                {...props.leftSelectionAndFocusMode
                  .SelectEntitiesLeftDrawerProps}
                onClose={toggleLeftDrawerVisibility}
                rows={expandedSelectEntitiesState.selected.map((selected) => {
                  return {
                    selected,
                    entitySearchResult: entitySearchResults.data.results.find(
                      (result) => result.entityId === selected.entityId
                    ),
                    focused: isEqual(
                      selected,
                      expandedSelectEntitiesState?.focused
                    ),
                    onFocusEntity: () =>
                      selectEntitiesActions.focusEntity(selected),
                    onClick:
                      props.leftSelectionAndFocusMode
                        .SelectEntitiesLeftDrawerProps.onClick
                  }
                })}
              />
            </Flex>
          )}
          {shouldRenderLeftDrawer && (
            <ComboKeyListener
              combo
              leaderKey='shift'
              actionKey='o'
              onAction={toggleLeftDrawerVisibility}
            />
          )}
          {!leftDrawerVisibility && shouldRenderLeftDrawer && (
            <LeftDrawerTab
              onClick={toggleLeftDrawerVisibility}
              hintText={
                props.leftSelectionAndFocusMode.PageTitleBarProps
                  .toggleButtonHintText
              }
            />
          )}
          <FlexChild
            grow
            className={classNames({
              [css.leftDrawerMargin]: !!(
                leftDrawerVisibility && shouldRenderLeftDrawer
              )
            })}
          >
            {props.leftSelectionAndFocusMode && (
              <PageTitleBar
                {...props.leftSelectionAndFocusMode.PageTitleBarProps}
                FocusNavigationControlBoxProps={{
                  selectEntitiesState: expandedSelectEntitiesState,
                  selectEntitiesActions
                }}
                leftDrawerToggle={toggleLeftDrawerVisibility}
              />
            )}
            {!props.hideSelectionBar && selectEntitiesTopBarElements}
            <ErrorBoundary resetId={getControlId(control)}>
              {(() => {
                if (
                  calculationNotSupportedEntities &&
                  calculationNotSupportedFallbackEnabled
                ) {
                  return (
                    <CalculationNotSupportedFallback
                      unsupportedEntities={calculationNotSupportedEntities}
                    />
                  )
                }

                if (showFallback) {
                  return props.emptyFallback
                }

                if (
                  !allRuleFilters.data ||
                  !entitySearchResults.data ||
                  shouldWaitForDefaultRuleFilters
                ) {
                  return null
                }

                return props.children
              })()}
            </ErrorBoundary>
          </FlexChild>
        </Flex>
      </SelectEntitiesContext.Provider>
    </CalculationSettingsOverrideProvider>
  )
}
const CalculationNotSupportedFallback: React.FC<{
  unsupportedEntities: ISearchResult[]
}> = (props) => (
  <>
    <Spacer xs />
    <EmptyStateCard
      icon={WarningIcon}
      text={`Calculations not supported for the following entities: ${props.unsupportedEntities.map(
        (entity) => entity.printName
      )}`}
    />
  </>
)
