import React from 'react'

import {round} from 'lodash'

import {deepOrange, green} from '@material-ui/core/colors'

import {CHART_VALUE_TYPE} from '@d1g1t/api/models'

import {StandardResponseItem} from '@d1g1t/lib/standard-response'

import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'
import {useNumberFormatter} from '@d1g1t/shared/wrappers/formatter'

import {CATEGORY_IDS} from '@d1g1t/advisor/containers/rebalance/components/account/constants'

const colorCategory = (props: IDataCellProps) => {
  return [
    CATEGORY_IDS.TARGET_VALUE,
    CATEGORY_IDS.TARGET_WEIGHT,
    CATEGORY_IDS.WEIGHT_CHANGE,
    CATEGORY_IDS.VALUE_CHANGE
  ].includes(props.category.id as CATEGORY_IDS)
}

export const RebalanceTotalCell: React.SFC<IDataCellProps> = (
  props: IDataCellProps
) => {
  const standardItem = new StandardResponseItem(props.item)
  const currentValue = standardItem.getValue(CATEGORY_IDS.CURRENT_VALUE)
  const targetValue = standardItem.getValue(CATEGORY_IDS.TARGET_VALUE)
  const data = standardItem.getValue(props.category.id)
  const color =
    round(currentValue, 0) === round(targetValue, 0)
      ? green[800]
      : deepOrange.A700

  const formatterOptions = ((): Parameters<typeof useNumberFormatter>[0] => {
    switch (props.category.valueType) {
      case CHART_VALUE_TYPE.PERCENTAGE:
        return {style: 'percent'}
      case CHART_VALUE_TYPE.MONEY_ABBREVIATED:
        return {style: 'currency', abbreviate: true}
      case CHART_VALUE_TYPE.INTEGER:
      case CHART_VALUE_TYPE.DECIMAL:
      case CHART_VALUE_TYPE.DECIMAL_LONG:
      case CHART_VALUE_TYPE.DECIMAL_LONG_LONG:
      default:
        return {style: 'decimal'}
    }
  })()

  const formatter = useNumberFormatter({
    ...formatterOptions,
    allowZero: true,
    decimalPlaces: 2
  })

  return (
    <div
      style={{
        color: colorCategory(props) ? color : null,
        textAlign: 'right',
        flexGrow: 1
      }}
    >
      {formatter.format(Math.abs(data) < 0.00001 ? 0 : data)}
    </div>
  )
}
