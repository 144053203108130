export const BOOLEAN_ALLOWED_VALUES = [
  {
    key: true,
    value: 'Yes'
  },
  {
    key: false,
    value: 'No'
  }
]
