import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'

import {FEATURE_FLAGS} from '@d1g1t/config/feature-flags'

import {classNames} from '@d1g1t/lib/class-names'
import {getElementDimensions} from '@d1g1t/lib/dom'

import {Flex} from '@d1g1t/shared/components/flex'

import {HeartBeatProgress} from '../loading-container'

import * as css from './style.scss'

export interface IBlockLoaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  linear?: boolean
  overlay?: boolean
  size?: number
  thickness?: number
  autoHeight?: boolean
  defaultAutoHeight?: number
  absolutePosition?: boolean
}

export interface IBlockLoaderState {
  height?: number
}

export class BlockLoader extends React.Component<
  IBlockLoaderProps,
  IBlockLoaderState
> {
  static defaultProps: Partial<IBlockLoaderProps> = {
    defaultAutoHeight: 150
  }

  state = {
    height: null
  }

  rootEl: HTMLDivElement

  componentDidMount() {
    if (!this.props.autoHeight) {
      return
    }

    const {height} = getElementDimensions(this.rootEl.parentElement)
    this.setState({
      height: height || this.props.defaultAutoHeight
    })
  }

  ref = (node) => (this.rootEl = node)

  render() {
    const {
      linear,
      className,
      overlay,
      size,
      thickness,
      children,
      autoHeight,
      defaultAutoHeight,
      absolutePosition,
      ...props
    } = this.props

    return (
      <div
        data-block-loader
        className={classNames({
          [css.overlayContainer]: overlay,
          [css.absolutePosition]: absolutePosition
        })}
        ref={this.ref}
        {...props}
      >
        <div
          className={classNames({
            [css.overlay]: overlay
          })}
          {...(Number.isFinite(this.state.height)
            ? {style: {height: `${this.state.height}px`}}
            : {})}
        >
          <Flex
            fullHeight
            {...(linear ? {linear} : {justifyCenter: true, alignCenter: true})}
          >
            {
              // eslint-disable-next-line no-nested-ternary
              linear ? (
                <LinearProgress />
              ) : FEATURE_FLAGS.VALENTINES_DAY_HEART_PROGRESS ? (
                <HeartBeatProgress />
              ) : (
                <CircularProgress size={size || 100} thickness={thickness} />
              )
            }
            {children}
          </Flex>
        </div>
      </div>
    )
  }
}
