import React from 'react'

import {CommonProps} from '@material-ui/core/OverridableComponent'
import {SvgIconTypeMap} from '@material-ui/core/SvgIcon'
import DomainIcon from '@material-ui/icons/Domain'
import HouseIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'

import {ALL_MODELS} from '@d1g1t/api/models'

import {FoundationIcon} from '@d1g1t/shared/components/icons/foundation'
import {TrustIcon} from '@d1g1t/shared/components/icons/trust'
import {Badge, BADGE_STATUS} from '@d1g1t/shared/components/mui/badge'

import accountIcon from './account-icon.svg'
import guidePostIcon from './guidepost-icon.svg'

type SvgIconProps = SvgIconTypeMap['props']
export interface IClientIconProps
  extends CommonProps<SvgIconTypeMap<{}, 'svg'>>,
    SvgIconProps {
  model: ALL_MODELS | string
  badgeStatus?: BADGE_STATUS
}

export const ClientIcon: React.FC<IClientIconProps> = ({
  model,
  badgeStatus,
  ...props
}) => {
  const renderIcon = () => {
    switch (model) {
      case ALL_MODELS.HOUSEHOLD:
        return <HouseIcon {...props} />
      case ALL_MODELS.CORPORATION:
        return <DomainIcon {...props} />
      case ALL_MODELS.INDIVIDUAL:
        return <PersonIcon {...props} />
      case ALL_MODELS.ACCOUNTGROUP:
        return <PeopleIcon {...props} />
      case ALL_MODELS.TRUST:
        return <TrustIcon {...props} />
      case ALL_MODELS.FOUNDATION:
        return <FoundationIcon {...props} />
      case ALL_MODELS.INVESTMENTMANDATE:
      case ALL_MODELS.ACCOUNT: {
        const iconMap = {
          [ALL_MODELS.INVESTMENTMANDATE]: guidePostIcon,
          [ALL_MODELS.ACCOUNT]: accountIcon
        }

        return (
          <div
            style={{
              width: '18px',
              height: '18px',
              backgroundImage: `url(${iconMap[model]})`,
              backgroundSize: '100% auto',
              backgroundPosition: 'bottom',
              backgroundRepeat: 'no-repeat'
            }}
          />
        )
      }
      default:
        return null
    }
  }

  if (badgeStatus) {
    return (
      <Badge
        variant='dot'
        status={badgeStatus}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        overlap='rectangular'
      >
        {renderIcon()}
      </Badge>
    )
  }
  return renderIcon()
}
