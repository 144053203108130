import {useMemo, useState} from 'react'

import produce from 'immer'
import {atom, useRecoilState} from 'recoil'

import {StandardResponse} from '@d1g1t/lib/standard-response'

/**
 * Given a `StandardResponse`, will return a `useState` tuple
 * which filters the selected ids to _only_ those which appear in the
 * standard response.
 */
export function useSelectedIds(
  response: StandardResponse
): [string[], (selectedIds: string[]) => void] {
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const selectedIdsInTable = useMemo(() => {
    if (!response) {
      return []
    }

    const selectedIdsSet = new Set(selectedIds)
    const selectedIdsInTable: string[] = []

    for (const item of response.leafItems()) {
      if (selectedIdsSet.has(item.id)) {
        selectedIdsInTable.push(item.id)
      }
    }

    return selectedIdsInTable
  }, [response, selectedIds])

  return [selectedIdsInTable, setSelectedIds]
}

const selectedIdsAtom = atom<{
  [id: string]: {
    selectedIds: string[]
  }
}>({
  key: 'selectedIdsAtom',
  default: EMPTY_OBJECT
})

/**
 * Given a `StandardResponse`, will return a `useRecoilState` tuple
 * which filters the selected ids to _only_ those which appear in the
 * standard response.
 *
 * @param response - the table state
 * @param id - the recoil state's fieldname for the selected checkboxes
 */
export function useSelectedIdsRecoil(
  response: StandardResponse,
  id: string
): [string[], (selectedIds: string[]) => void] {
  const [selectedIdsRecoil, setSelectedIdsRecoil] =
    useRecoilState(selectedIdsAtom)

  const handleSetSelectedIdsRecoil = (selectedIds: string[]) => {
    setSelectedIdsRecoil(
      produce(selectedIdsRecoil, (draft) => {
        if (!selectedIdsRecoil[id]) {
          draft[id] = {selectedIds: []}
        }
        draft[id].selectedIds = selectedIds
      })
    )
  }

  const selectedIdsInTable = useMemo(() => {
    if (!response) {
      return []
    }

    const selectedIdsInTable: string[] = []

    if (selectedIdsRecoil[id]) {
      for (const item of response.leafItems()) {
        if (selectedIdsRecoil[id].selectedIds.includes(item.id)) {
          selectedIdsInTable.push(item.id)
        }
      }
    }

    return selectedIdsInTable
  }, [response, selectedIdsRecoil])

  return [selectedIdsInTable, handleSetSelectedIdsRecoil]
}
