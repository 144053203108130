import {useCallback} from 'react'

import {isEmpty} from 'lodash'

import {IGlobalFilter} from '@d1g1t/typings/general'

import {useGlobalSettings} from '@d1g1t/shared/wrappers/global-settings'

import {DEFAULT_ALL_CLIENT_OPTION, GLOBAL_SETTINGS_SAVE_KEY} from './constants'

/**
 * Get the value and update method for global filter
 *
 * For consuming this value in calculations/other requests, use
 * `useGlobalFilterContext` which returns `null` when provider is not present
 *
 * This method will always return the current value of global filter
 */
export function useGlobalFilter(): [
  IGlobalFilter,
  {
    updateGlobalFilter(filter: IGlobalFilter): void
    isDefault: boolean
  }
] {
  const [filter, {updateGlobalSettings}] = useGlobalSettings(
    GLOBAL_SETTINGS_SAVE_KEY,
    DEFAULT_ALL_CLIENT_OPTION
  )

  const updateGlobalFilter = useCallback(
    (filter: IGlobalFilter) => {
      const nextFilter = isEmpty(filter) ? DEFAULT_ALL_CLIENT_OPTION : filter

      updateGlobalSettings(nextFilter)
    },
    [updateGlobalSettings]
  )

  const isDefault = filter && filter.url === DEFAULT_ALL_CLIENT_OPTION.url

  return [filter, {updateGlobalFilter, isDefault}]
}
