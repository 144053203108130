import {useCallback, useEffect, useState} from 'react'

import {useApi} from 'fairlight'
import produce from 'immer'
import {fromPairs, toPairs} from 'lodash'

import {GlobalSettingsEndpoints} from '@d1g1t/api/endpoints'
import {PERIOD} from '@d1g1t/api/models'

/**
 * Used internally by `<StandardTable>` to provide state for column width properties
 * @param id - id
 * @param pagePeriodMetrics - list of metrics/category ids that use the page period
 */
export function useInternalTableColumnWidthControl(
  id: string,
  pagePeriodMetrics: string[]
): {
  columnWidths: Dictionary<number>
  updateColumnWidths(columnWidth: Dictionary<number>): void
  /**
   * Save current values to global settings
   */
  commitColumnWidths(): void
} {
  const api = useApi()
  const [columnWidths, setColumnWidths] = useState<Dictionary<number>>(
    id ? null : {}
  )

  useEffect(() => {
    if (!id) {
      return
    }

    let didCancel = false

    const fetchSettings = async () => {
      const response: any = await api.request(
        GlobalSettingsEndpoints.findById(`TABLE-WIDTHS-${id}`)
      )

      if (didCancel) {
        return
      }

      if (response) {
        // using fromPairs to fix camelizer mangling keys
        setColumnWidths(fromPairs(response.columnWidths))
      } else {
        setColumnWidths({})
      }
    }

    fetchSettings()

    return () => {
      didCancel = true
    }
  }, [id])

  const commitColumnWidths = useCallback(() => {
    if (!id) {
      return
    }

    api.request(
      GlobalSettingsEndpoints.update(`TABLE-WIDTHS-${id}`, {
        // using toParis to fix camelizer mangling keys
        columnWidths: toPairs(columnWidths)
      })
    )
  }, [id, columnWidths])

  const handleSetColumnWidths = (columnWidths: Dictionary<number>) => {
    if (!pagePeriodMetrics) {
      setColumnWidths(columnWidths)

      return
    }

    // Replaces category ids that use the page period with 'page-period' suffix instead of date range.
    // This is done by iterating through the list of categories that use the page period
    // and replaces their suffix with 'page-period'.
    // e.g. q-aggregation-volatility-monthly-last-3-years to q-aggregation-volatility-monthly-page-period
    const formattedColumnWidths = produce(columnWidths, (draft) => {
      for (const pagePeriodMetricId of pagePeriodMetrics) {
        const columnWidth = draft[pagePeriodMetricId]

        delete draft[pagePeriodMetricId]

        const suffixToReplace = Object.values(PERIOD).find((periodSuffix) =>
          pagePeriodMetricId.includes(periodSuffix)
        )

        draft[pagePeriodMetricId.replace(suffixToReplace, 'page-period')] =
          columnWidth
      }
    })

    setColumnWidths(formattedColumnWidths)
  }

  // Replaces page period category ids with their actual category ids
  // This is done by iterating through all the columns widths in the state that contain the 'page-period' suffix
  // and replacing them with their original suffix.
  // e.g. q-aggregation-volatility-monthly-page-period to q-aggregation-volatility-monthly-last-3-years
  const parsedColumnWidths = (() => {
    if (!columnWidths) {
      return null
    }

    if (!pagePeriodMetrics) {
      return columnWidths
    }

    return produce(columnWidths, (draft) => {
      for (const categoryId of Object.keys(columnWidths).filter((categoryId) =>
        categoryId.includes('page-period')
      )) {
        const columnWidth = draft[categoryId]

        delete draft[categoryId]

        const actualCategoryId = pagePeriodMetrics.find((pagePeriodMetricId) =>
          pagePeriodMetricId.includes(categoryId.replace('page-period', ''))
        )

        draft[actualCategoryId] = columnWidth
      }
    })
  })()

  return {
    columnWidths: parsedColumnWidths,
    commitColumnWidths,
    updateColumnWidths: handleSetColumnWidths
  }
}
