import React from 'react'

import {useApiQuery} from 'fairlight'
import {sortBy} from 'lodash'

import {RuleFilterEndpoints} from '@d1g1t/api/endpoints'
import {
  ACCOUNTSPROFILE_DISPLAY_CLIENT_OBJECTS,
  ALL_MODELS
} from '@d1g1t/api/models'

import {ClientIcon} from '@d1g1t/shared/components/client-icon'
import {
  mapClientStatusToBadgeStatus,
  mapMandateIsActiveStrategyToBadgeStatus
} from '@d1g1t/shared/components/client-icon/lib'
import {Divider} from '@d1g1t/shared/components/mui/divider'
import {ListItemIcon} from '@d1g1t/shared/components/mui/list-item-icon'
import {Menu} from '@d1g1t/shared/components/mui/menu'
import {MenuItem} from '@d1g1t/shared/components/mui/menu-item'
import {NestedMenuItem} from '@d1g1t/shared/components/nested-menu-item'
import {useUserProfile} from '@d1g1t/shared/wrappers/user-profile'

import {useEntityChipContext} from '../..'
import {DropdownFilterItem, DropdownItem} from '../dropdown-items'
import {RuleFiltersMenu} from './components/rules-filter-submenu'
import {ENTITYRELATIONCLIENT_STATUS_ORDER, ITEM_GROUP_LIMIT} from './constants'
import {compareRuleFilters} from './lib'
import {IDropdownMenuProps} from './typings'

export const DropdownMenu: React.FC<IDropdownMenuProps> = function DropdownMenu(
  props
) {
  const [allRuleFilters] = useApiQuery(RuleFilterEndpoints.list(), {
    fetchPolicy: 'cache-and-fetch'
  })
  const [{profile: userProfile}] = useUserProfile() // userProfile.displayClientObjects decides investor-centric or mandate-centric layout of the chip dropdown.

  const {
    entity: entitySelection,
    entitySearchResult,
    focused,
    actions
  } = useEntityChipContext()

  const preferredView: ACCOUNTSPROFILE_DISPLAY_CLIENT_OBJECTS =
    userProfile.displayClientObjects ||
    ACCOUNTSPROFILE_DISPLAY_CLIENT_OBJECTS.INVESTOR_ENTITIES_FIRST

  const invManadateCount = props.entityRelations.mandates.length
  const entitiesCount =
    props.entityRelations.clients.length +
    (props.entityRelations.household ? 1 : 0)

  const getSortedClientsList = () => {
    return props.entityRelations.clients.sort((a, b) => {
      // Sort by status first, then by alphabetical order second
      return (
        ENTITYRELATIONCLIENT_STATUS_ORDER[a.status] -
          ENTITYRELATIONCLIENT_STATUS_ORDER[b.status] ||
        a.name.localeCompare(b.name)
      )
    })
  }

  // Returns an array of related legal entities or a submenu for related legal entities
  const entitiesElements = (() => {
    const legalEntities = (() => {
      return getSortedClientsList().map((client) => {
        return (
          <DropdownItem
            key={client.entityId}
            entityRelation={client}
            singleAccount={props.singleAccount}
            allAccountRelations={props.entityRelations.accounts}
            selected={entitySearchResult.entityId === client.entityId}
            disableHoldingsFiltering={props.disableHoldingsFiltering}
            holdingsCountSuffix={props.holdingsCountSuffix}
            icon={
              <ClientIcon
                model={client.model}
                fontSize='small'
                badgeStatus={mapClientStatusToBadgeStatus(client.status)}
              />
            }
            onFilterModalOpen={(entityId, entityModel, entityName) => {
              props.onFilterModalOpen(entityId, entityModel, entityName)
            }}
            onClose={props.onClose}
          />
        )
      })
    })()

    if (
      preferredView ===
        ACCOUNTSPROFILE_DISPLAY_CLIENT_OBJECTS.INVESTOR_ENTITIES_FIRST ||
      entitiesCount <= ITEM_GROUP_LIMIT
    ) {
      // show all entities
      return legalEntities
    }

    // group entities into a submenu
    return (
      <NestedMenuItem
        key='legalEntitiesKey'
        label={
          <>
            <ListItemIcon>
              <ClientIcon model={ALL_MODELS.INDIVIDUAL} fontSize='small' />
            </ListItemIcon>
            Related Legal Entities
          </>
        }
      >
        {legalEntities}
      </NestedMenuItem>
    )
  })()

  // Returns an array of investment mandates, or a submenu for investment mandates
  const mandatesElements = (() => {
    const investmentMandates = (() => {
      return sortBy(props.entityRelations.mandates, 'name').map((mandate) => (
        <DropdownItem
          key={mandate.entityId}
          entityRelation={{...mandate, model: ALL_MODELS.INVESTMENTMANDATE}}
          singleAccount={props.singleAccount}
          allAccountRelations={props.entityRelations.accounts}
          selected={entitySearchResult.entityId === mandate.entityId}
          disableHoldingsFiltering={props.disableHoldingsFiltering}
          holdingsCountSuffix={props.holdingsCountSuffix}
          icon={
            <ClientIcon
              model={ALL_MODELS.INVESTMENTMANDATE}
              fontSize='small'
              badgeStatus={mapMandateIsActiveStrategyToBadgeStatus(
                mandate.isActiveStrategy
              )}
            />
          }
          onFilterModalOpen={props.onFilterModalOpen}
          onClose={props.onClose}
        />
      ))
    })()

    if (props.hideInvestmentMandatesSubmenu) {
      return undefined
    }

    if (
      preferredView === ACCOUNTSPROFILE_DISPLAY_CLIENT_OBJECTS.MANDATES_FIRST ||
      invManadateCount <= ITEM_GROUP_LIMIT
    ) {
      return investmentMandates
    }

    return (
      props.entityRelations.mandates.length > 0 && (
        <NestedMenuItem
          key='invMandSubMenuKey'
          label={
            <>
              <ListItemIcon>
                <ClientIcon
                  model={ALL_MODELS.INVESTMENTMANDATE}
                  fontSize='small'
                />
              </ListItemIcon>
              Investment Mandates
            </>
          }
        >
          {investmentMandates}
        </NestedMenuItem>
      )
    )
  })()

  return (
    <Menu
      open={props.open}
      anchorEl={props.anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
      transformOrigin={{horizontal: 'left', vertical: 'top'}}
      onClose={props.onClose}
    >
      {/* Household Item */}
      {props.entityRelations.household && (
        <DropdownItem
          entityRelation={props.entityRelations.household}
          allAccountRelations={props.entityRelations.accounts}
          singleAccount={props.singleAccount}
          selected={
            entitySearchResult.entityId ===
            props.entityRelations.household?.entityId
          }
          holdingsCountSuffix={props.holdingsCountSuffix}
          disableHoldingsFiltering={props.disableHoldingsFiltering}
          icon={<ClientIcon model={ALL_MODELS.HOUSEHOLD} fontSize='small' />}
          onFilterModalOpen={props.onFilterModalOpen}
          onClose={props.onClose}
        />
      )}

      {
        /**
         * Investor-centric vs. Mandate-centric switch.
         *
         * If the advisor has not set a preference, assume IC.
         *
         * Render all clients/mandates if its the preferred view, OR if 3 or less of them exist.
         *
         * For example, if the advisor prefers investment mandates, then ALL IM are rendered first.
         * Then, if there are fewer than 3 clients, they are simply rendered.
         * Otherwise, the clients are grouped in a submenu.
         */
        // eslint-disable-next-line no-nested-ternary
        props.disableHoldingsFiltering
          ? entitiesElements
          : preferredView ===
            ACCOUNTSPROFILE_DISPLAY_CLIENT_OBJECTS.INVESTOR_ENTITIES_FIRST
          ? [entitiesElements, mandatesElements]
          : [mandatesElements, entitiesElements]
      }

      {/* Divider + the options below the divider such as Filter holdings, Filter rules, Clear selection, etc. */}
      <Divider style={{marginTop: '1rem', marginBottom: '1rem'}} />
      {/* Accounts and positions based filters */}
      {!props.disableHoldingsFiltering && !props.singleAccount && (
        <MenuItem
          onClick={() => {
            props.onFilterModalOpen(
              entitySearchResult.entityId,
              entitySearchResult.modelName,
              entitySearchResult.printName || entitySearchResult.name
            )
          }}
        >
          Filter holdings
        </MenuItem>
      )}
      {/* Rule based filters */}
      {!props.disableRuleBasedFiltering && (
        <NestedMenuItem label='Filter rule'>
          <RuleFiltersMenu
            onClose={props.onClose}
            onManageRuleFiltersClick={() => {
              props.onClose()
              props.onRuleFilterModalOpen()
            }}
          />
        </NestedMenuItem>
      )}
      {/* A list of active rule filters */}
      {entitySelection?.ruleFilters &&
        allRuleFilters.data?.results
          .sort(compareRuleFilters)
          .filter((rule) => entitySelection.ruleFilters.includes(rule.url))
          .map((activeRuleDetails) => {
            return (
              <DropdownFilterItem
                key={activeRuleDetails.url}
                filterText={activeRuleDetails.name}
                sharedFilterItem={activeRuleDetails.isShared}
                onRemoveFilter={() => {
                  actions.applyEntityFilters(entitySearchResult.entityId, {
                    ruleFilters: entitySelection.ruleFilters.filter(
                      (rule) => rule !== activeRuleDetails.url
                    )
                  })
                }}
              />
            )
          })}
      {/* Focus on client */}
      {!focused && (
        <MenuItem onClick={() => actions.focusEntity(entitySelection)}>
          Focus on this client
        </MenuItem>
      )}
      {/* Remove all applied filters */}
      {entitySelection &&
        !props.singleAccount &&
        (entitySelection.accounts ||
          entitySelection.positions ||
          entitySelection.ruleFilters) && (
          <MenuItem
            onClick={() => {
              actions.applyEntityFilters(entitySearchResult.entityId, {
                accounts: [],
                positions: [],
                ruleFilters: []
              })
            }}
          >
            Remove all filters
          </MenuItem>
        )}
      {/* Remove chip */}
      <MenuItem
        onClick={() => {
          actions.removeEntity(entitySearchResult.entityId)
        }}
      >
        Clear selection
      </MenuItem>
    </Menu>
  )
}
