import React from 'react'

import {TRADEORDER_DISPLAY_STATUS} from '@d1g1t/api/models'

import {assertNever} from '@d1g1t/lib/assert'
import {classNames} from '@d1g1t/lib/class-names'
import {StandardResponseItem} from '@d1g1t/lib/standard-response'

import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'

import {SmallEmptyIcon, SmallFullIcon} from '../lib'

import * as css from './styles.scss'

const getIcon = (status: TRADEORDER_DISPLAY_STATUS) => {
  if (!status) {
    return null
  }

  switch (status) {
    case TRADEORDER_DISPLAY_STATUS.OPEN:
      return <SmallEmptyIcon color='#2e7d32' />
    case TRADEORDER_DISPLAY_STATUS.CANCELLED:
      return <SmallFullIcon color='#dd2c00' />
    case TRADEORDER_DISPLAY_STATUS.PENDING_APPROVAL:
      return <SmallFullIcon color='#ffb97a' />
    case TRADEORDER_DISPLAY_STATUS.REJECTED:
    case TRADEORDER_DISPLAY_STATUS.DELETED:
    case TRADEORDER_DISPLAY_STATUS.FAILED:
      return <SmallEmptyIcon color='#242424' />
    case TRADEORDER_DISPLAY_STATUS.WORKING:
    case TRADEORDER_DISPLAY_STATUS.WORKING_CONFIRMED:
    case TRADEORDER_DISPLAY_STATUS.CLOSED:
      return null
    default:
      assertNever(status, `Invalid status: ${status}`)
  }
}

export const AllocationStatusCell: React.FC<IDataCellProps> = (props) => {
  const standardItem = new StandardResponseItem(props.item)
  const status = standardItem.getKey(props.category.id)
  const value = standardItem.getValue(props.category.id)

  return (
    <div className={classNames(css.cell)}>
      {getIcon(status)} {value}
    </div>
  )
}
