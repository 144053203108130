import * as Yup from 'yup'

import {useUrlQueryParams} from '@d1g1t/shared/wrappers/url-query-params'

const SELECTED_ITEM_QUERY_SCHEMA = Yup.object({
  selectedItem: Yup.string()
})

type IUseSelectionQueryReturnValue = [
  /**
   * Currently selected item id.
   */
  string,
  /**
   * Sets the selected item id.
   */
  (id: string) => void
]

/**
 * Similar to `useState` but for a `selected` query parameter.
 */
export function useSelectionQuery(): IUseSelectionQueryReturnValue {
  const [queryParams, {replaceQueryParams}] = useUrlQueryParams({
    schema: SELECTED_ITEM_QUERY_SCHEMA
  })
  const setSelectedItemId = (id: string) =>
    replaceQueryParams({selectedItem: id}, {merge: true})
  return [queryParams.selectedItem, setSelectedItemId]
}
