import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import RefreshIcon from '@material-ui/icons/Refresh'

import {useIsFirstRender} from '@d1g1t/lib/hooks'

import {Button} from '@d1g1t/shared/components/mui/button'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H2} from '@d1g1t/shared/components/typography'
import {
  getErrorDisplayMessage,
  IErrorFallbackProps
} from '@d1g1t/shared/wrappers/error-boundary'

export const FrameErrorFallback: React.SFC<IErrorFallbackProps> = ({
  error,
  onReset
}) => {
  const location = useLocation()
  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (!isFirstRender) {
      // reset error boundary on route change
      onReset()
    }
  }, [location.pathname])

  return (
    <div data-error>
      <Spacer xs />
      <H2>{getErrorDisplayMessage(error)}</H2>
      <Spacer xxs />
      <Button outlined small onClick={onReset}>
        <RefreshIcon /> Reload Page
      </Button>
    </div>
  )
}
