import {IInstrument, TRADEORDER_QTY_TYPE} from '@d1g1t/api/models'

import {ITradeOrderFormValues} from '../'
import {
  IMutualFundFormValues,
  QTY_TYPE,
  QTY_TYPE_DSC_FREE
} from '../mutual-funds/typings'

export const maxSize = 52
export const midSize = 28
export const minSize = 14

export const getCircleFontSize = (
  qty: number,
  qtyType: QTY_TYPE_DSC_FREE | TRADEORDER_QTY_TYPE
): number => {
  if (qtyType === QTY_TYPE_DSC_FREE.DSC_FREE) {
    return midSize
  }
  if (!qty) {
    return maxSize
  }

  const stringVersionOfQty = qty.toString()
  const numberOfDigits = stringVersionOfQty.length

  let fontSize = maxSize - (numberOfDigits - 3) * 6

  if (numberOfDigits > 8 && numberOfDigits < 12) {
    fontSize = 20 - (numberOfDigits - 7)
  }
  // ^ added to reduce font size by less when the number is 9 to 11 digits long

  if (fontSize < minSize) {
    return minSize
  }

  if (qty <= 999) {
    return maxSize
  }

  return fontSize
}

export const unitsOrAmount = (
  data: {
    qtyType: ITradeOrderFormValues['qtyType']
    qty: ITradeOrderFormValues['qty']
    fundCode?: IMutualFundFormValues['fundCode']
  },
  instrument: IInstrument
): string => {
  if (data.qtyType === TRADEORDER_QTY_TYPE.UNITS) {
    return data.qty === 1 ? 'UNIT' : 'UNITS'
  }

  if (data.qtyType === QTY_TYPE.DSC_FREE) {
    return 'UNITS'
  }

  if (data.qtyType === TRADEORDER_QTY_TYPE.AMOUNT) {
    if (instrument) {
      return instrument.currency.name
    }
  }

  return ' '
}
