import React, {useState} from 'react'

import {FieldLabel} from '@d1g1t/shared/components/form/field-label'
import {TextInput} from '@d1g1t/shared/components/form/text-input'
import {Modal, ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Button} from '@d1g1t/shared/components/mui/button'

export interface IAddViewProps {
  onSave(name: string)
  onClose()
}

export const AddView: React.FC<IAddViewProps> = function AddView(props) {
  const [name, setName] = useState('')

  const handleTextFieldChange = (event) => {
    setName(event.target.value)
  }

  const handleSave = () => {
    props.onSave(name)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSave()
    }
  }

  return (
    <Modal open narrow noMinHeight title='Add View' onClose={props.onClose}>
      <ModalContent>
        <FieldLabel label='View Name' />
        <TextInput
          focusOnMount
          standard
          value={name}
          onChange={handleTextFieldChange}
          onKeyDown={handleKeyDown}
          data-testid='text-field-view-name'
        />
      </ModalContent>
      <ModalActions>
        <Button
          primary
          contained
          onClick={handleSave}
          data-testid='button-create-view'
        >
          Create
        </Button>
      </ModalActions>
    </Modal>
  )
}
