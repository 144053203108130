import {ENTITYRELATIONCLIENT_STATUS} from '@d1g1t/api/models'

/**
 * When there are investment mandates or related legal
 * entities greater than `ITEM_GROUP_LIMIT`, a submenu is
 * created for them if the advisor has set them as "lower priority".
 */

export const ITEM_GROUP_LIMIT = 3

export const ENTITYRELATIONCLIENT_STATUS_ORDER = {
  [ENTITYRELATIONCLIENT_STATUS.ACTIVE]: 1,
  [ENTITYRELATIONCLIENT_STATUS.PROSPECT]: 2,
  [ENTITYRELATIONCLIENT_STATUS.CLOSING]: 3,
  [ENTITYRELATIONCLIENT_STATUS.CLOSED]: 4
}
