import React, {useMemo} from 'react'

import {UI_PERMISSIONS} from '@d1g1t/api/models'

import {Permissions} from '@d1g1t/lib/permissions'

import {H1, H3} from '@d1g1t/shared/components/typography'

import {useUserProfile} from '../user-profile'

export interface IWithPermissionsProps {
  permissions: Permissions
  noAccess: React.ReactNode
}

export const ADMIN_ONLY = true
export const ANY_USER = false

export const NoAccessView: React.FC = () => (
  <div>
    <H1>Access Denied</H1>
    <H3>
      You don't have permissions to view this page. Please contact your
      administrator if you need to access this page.
    </H3>
  </div>
)

export interface IWithPermissionParams {
  /**
   * When passed, allows access to the page when advisor user has admin priviledge.
   */
  adminOnly?: boolean
}

export const withPermissions = (
  permissionCode: UI_PERMISSIONS = null,
  options: IWithPermissionParams = {
    adminOnly: false
  }
) => {
  const {adminOnly} = options

  return function WithPermissions<TProps extends IWithPermissionsProps>(
    Component: React.ComponentType<TProps>
  ): React.ComponentType<Omit<TProps, keyof IWithPermissionsProps>> {
    return (props: TProps) => {
      const permissions = usePermissions()

      if (!permissions.getId()) {
        return null
      }

      if (permissionCode && !permissions.canAccess(permissionCode)) {
        return <NoAccessView />
      }

      if (adminOnly === ADMIN_ONLY && !permissions.isAdministrator()) {
        return <NoAccessView />
      }

      return (
        <Component
          {...props}
          permissions={permissions}
          noAccess={<NoAccessView />}
        />
      )
    }
  }
}

export class PermissionError extends Error {
  waiting: boolean

  constructor(message?: string, {waiting = false} = {}) {
    super(message)

    this.waiting = !!waiting
    this.name = 'PermissionError'
  }
}

export function isPermissionError(
  error: PermissionError | unknown
): error is PermissionError {
  return error instanceof PermissionError
}

interface IPermissionErrorBoundaryProps {
  fallbackComponent?: React.ComponentType<{error: PermissionError}>
}

export class PermissionErrorBoundary extends React.Component<IPermissionErrorBoundaryProps> {
  static defaultProps = {
    fallbackComponent: NoAccessView
  }

  static getDerivedStateFromError(error: PermissionError | unknown) {
    if (isPermissionError(error)) {
      return {error}
    }
  }

  state: {error?: PermissionError} = {}

  render() {
    if (!this.state.error) {
      return this.props.children
    }

    return <this.props.fallbackComponent error={this.state.error} />
  }
}

export function usePermissions() {
  const [{user, profile}] = useUserProfile()

  const permissions = useMemo(
    () => new Permissions(profile, user),
    [user, profile]
  )

  return permissions
}
