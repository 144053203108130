import React, {forwardRef} from 'react'

import MuiTextField, {TextFieldProps} from '@material-ui/core/TextField'

export type ITextFieldProps = TextFieldProps & {}

// @ts-ignore
export const TextField: React.FC<ITextFieldProps> = forwardRef((props, ref) => (
  <MuiTextField
    ref={ref}
    {...props}
    InputLabelProps={{
      shrink: true
    }}
  />
))
