import React, {memo} from 'react'

import {StandardTableCheckbox} from '@d1g1t/shared/containers/standard-table/components/checkbox'
import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'

interface ICheckboxCellProps extends IDataCellProps {}

function isCheckboxCellEqual(
  props: ICheckboxCellProps,
  nextProps: ICheckboxCellProps
) {
  return (
    props.checked === nextProps.checked &&
    props.item.level === nextProps.item.level &&
    props.item.id === nextProps.item.id &&
    props.item.group === nextProps.item.group &&
    props.externalColumnFilterInputState ===
      nextProps.externalColumnFilterInputState
  )
}

export const CheckboxCell: React.FC<IDataCellProps> = memo((props) => {
  const handleOnCheck = (event: React.ChangeEvent) => {
    props.onCheck(props.item, props.rowIndex, event, props.checked)
  }

  const isDisabled = (() => {
    if (!props.disableCheckedRows) {
      return false
    }

    if (typeof props.disableCheckedRows === 'function') {
      return props.disableCheckedRows(props.item)
    }

    return props.disableCheckedRows.includes(props.item.id)
  })()

  return (
    <StandardTableCheckbox
      disabled={isDisabled}
      onChange={handleOnCheck}
      checked={props.checked}
      singleSelect={props.singleSelect}
    />
  )
}, isCheckboxCellEqual)
