import React from 'react'

import MuiListItemIcon, {
  ListItemIconProps
} from '@material-ui/core/ListItemIcon'

export interface IListItemIconProps extends ListItemIconProps {}

export const ListItemIcon: React.FC<IListItemIconProps> = (props) => (
  <MuiListItemIcon {...props} />
)
