import {IFilterRule} from '@d1g1t/api/models'

/**
 * A comparison function for sort() that compares two RuleFilters together.
 * Global filters are ranked higher than other filters, otherwise they are
 * sorted by name.
 *
 * @param lhs - Left-hand side of the comparison
 * @param rhs - Right-hand side of the comparison
 */
export const compareRuleFilters = (
  lhs: IFilterRule,
  rhs: IFilterRule
): number => {
  const lhsIsGlobal = !!lhs.isShared
  const rhsIsGlobal = !!rhs.isShared

  if (lhsIsGlobal && !rhsIsGlobal) {
    return -1
  }
  if (!lhsIsGlobal && rhsIsGlobal) {
    return 1
  }

  if (lhs.name < rhs.name) {
    return -1
  }

  if (lhs.name > rhs.name) {
    return 1
  }

  return 0
}
