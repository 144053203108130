import React, {useRef} from 'react'

import {useToggleState} from '@d1g1t/lib/hooks'

import {Flex} from '@d1g1t/shared/components/flex'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Menu} from '@d1g1t/shared/components/mui/menu'
import {MenuItem} from '@d1g1t/shared/components/mui/menu-item'
import {IValueLabelSelectProps} from '@d1g1t/shared/components/value-label-select'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'

import {CalculationOverrideStat} from '../calculation-override-stat'

import css from './style.scss'

interface ICurrencySelectProps
  extends Omit<IValueLabelSelectProps, 'value' | 'options'> {
  currency: string
}

export const CurrencySelect: React.FC<ICurrencySelectProps> = ({
  currency,
  ...props
}) => {
  const {firmConfiguration} = useFirmConfiguration()

  const currencyRef = useRef<HTMLButtonElement>(null)
  const [currencyMenuOpen, , openCurrencyMenu, closeCurrencyMenu] =
    useToggleState(false)

  return (
    <Flex stretch>
      <Button
        ref={currencyRef}
        className={css.button}
        disabled={props.disabled}
        onClick={openCurrencyMenu}
      >
        <CalculationOverrideStat label='Currency' data={currency} />
      </Button>
      <Menu
        keepMounted
        open={currencyMenuOpen}
        onClose={closeCurrencyMenu}
        anchorEl={currencyRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        transformOrigin={{horizontal: 'left', vertical: 'top'}}
      >
        {firmConfiguration.data?.firm?.reportingCurrencies.map(({currency}) => (
          <MenuItem
            key={currency.url}
            onClick={() => {
              closeCurrencyMenu()
              props.onChange(currency.name)
            }}
          >
            {currency.name}
          </MenuItem>
        ))}
      </Menu>
    </Flex>
  )
}
