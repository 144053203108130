import React, {useCallback, useImperativeHandle, useMemo} from 'react'

import {useApi, useApiQuery} from 'fairlight'
import produce from 'immer'
import {useRecoilState} from 'recoil'

import CloseIcon from '@material-ui/icons/Clear'

import {
  EntityEndpoints,
  PeopleClientsEndpoints,
  ProcessingEndpoints
} from '@d1g1t/api/endpoints'
import {ALL_MODELS, PROCESS_STATUS} from '@d1g1t/api/models'

import {useInterval} from '@d1g1t/lib/hooks'

import {Alert, Color} from '@d1g1t/shared/components/mui/alert'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {useSelectedEntities} from '@d1g1t/shared/containers/select-entities'

import {entityRefreshAtom} from './atoms'
import {IEntityRefreshProps} from './typings'

import * as css from './styles.scss'

export const EntityRefresh: React.FC<IEntityRefreshProps> = (props) => {
  const api = useApi()
  const {focusedSearchResult} = useSelectedEntities()
  const [entityRefresh, setEntityRefresh] = useRecoilState(entityRefreshAtom)

  const isHouseholdEntity =
    focusedSearchResult.modelName === ALL_MODELS.HOUSEHOLD

  const [entityRelationsResults] = useApiQuery(
    focusedSearchResult.modelName !== ALL_MODELS.HOUSEHOLD &&
      EntityEndpoints.entityRelationships(focusedSearchResult.entityId)
  )

  /**
   * Entity ID of the associated household, can be a client ID as some
   * clients do not have an associated household.
   */
  const householdId = (() => {
    if (isHouseholdEntity) {
      return focusedSearchResult.entityId
    }

    if (!entityRelationsResults.data) {
      return
    }

    if (entityRelationsResults.data.household) {
      return entityRelationsResults.data.household.entityId
    }

    return focusedSearchResult.entityId
  })()

  const statusAndRefreshRequestPayload = useMemo(
    () =>
      isHouseholdEntity
        ? {
            household: focusedSearchResult.url
          }
        : {
            client: api.buildUrl(
              PeopleClientsEndpoints.pathToResource(
                focusedSearchResult.entityId
              )
            )
          },
    [focusedSearchResult.url, focusedSearchResult.entityId, isHouseholdEntity]
  )

  const handleRefreshEntity = async () => {
    const response = await api.request(
      ProcessingEndpoints.householdRefresh(statusAndRefreshRequestPayload)
    )

    setEntityRefresh({
      ...entityRefresh,
      [householdId]: {
        userInitiatedRefresh: true,
        status: response.status
      }
    })
  }

  const entityRefreshStatusRequest = useCallback(async () => {
    const response = await api.request(
      ProcessingEndpoints.householdRefreshStatus(statusAndRefreshRequestPayload)
    )

    setEntityRefresh((currentEntityRefresh) =>
      produce(currentEntityRefresh, (draft) => {
        draft[householdId] = {
          status: response.status,
          statusDetails: response.statusDetails,
          userInitiatedRefresh:
            [
              PROCESS_STATUS.NEW,
              PROCESS_STATUS.SUBMITTED,
              PROCESS_STATUS.RUNNING
            ].includes(response.status) ||
            currentEntityRefresh[householdId]?.userInitiatedRefresh
        }
      })
    )
  }, [householdId, isHouseholdEntity, statusAndRefreshRequestPayload])

  useInterval(householdId && entityRefreshStatusRequest, 30000)

  useImperativeHandle(
    props.refreshEntityRef,
    () => ({
      refreshEntity: handleRefreshEntity
    }),
    [handleRefreshEntity]
  )

  const userInitiatedRefreshOnRelatedEntity =
    householdId && entityRefresh[householdId]?.userInitiatedRefresh

  const status = householdId && entityRefresh[householdId]?.status
  const statusDetails = householdId && entityRefresh[householdId]?.statusDetails
  const alert: {text: string; severity: Color; action?: React.ReactNode} =
    (() => {
      if (!status) {
        return
      }

      if (
        status === PROCESS_STATUS.FINISHED &&
        userInitiatedRefreshOnRelatedEntity
      ) {
        return {
          text: 'Refresh finished, please reload the page.',
          severity: 'warning' as Color
        }
      }

      if (
        [
          PROCESS_STATUS.NEW,
          PROCESS_STATUS.SUBMITTED,
          PROCESS_STATUS.RUNNING
        ].includes(status)
      ) {
        return {text: 'Refresh in progress', severity: 'info' as Color}
      }

      if (
        status === PROCESS_STATUS.FAILED &&
        userInitiatedRefreshOnRelatedEntity
      ) {
        return {
          text: `Refresh failed${!!statusDetails ? `: ${statusDetails}` : '.'}`,
          severity: 'error' as Color,
          action: (
            <IconButton
              small
              onClick={() =>
                setEntityRefresh(
                  produce(entityRefresh, (draft) => {
                    draft[householdId].userInitiatedRefresh = false
                    draft[householdId].statusDetails = undefined
                  })
                )
              }
            >
              <CloseIcon />
            </IconButton>
          )
        }
      }
    })()

  if (!alert) {
    return null
  }

  return (
    <Alert
      className={css.alertPosition}
      action={alert.action}
      severity={alert.severity}
    >
      {alert.text}
    </Alert>
  )
}
