import React from 'react'

export interface IFlagIconProps {
  /**
   * Currency code of flag to display.
   */
  currency: string
}

/**
 * Displays a flag icon matching the given currency code, or nothing
 * if the flag could not be found.
 */
export const FlagIcon: React.FC<IFlagIconProps> = (props) => {
  return (
    <img
      src={`https://flagcdn.com/16x12/${props.currency
        .slice(0, -1)
        .toLowerCase()}.png`}
      width='16'
      height='12'
      alt={props.currency}
    />
  )
}
