import React from 'react'

import {TRADEORDER_OPERATION} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'

import {Card} from '@d1g1t/shared/components/card'
import {Flex, FlexGridRow} from '@d1g1t/shared/components/flex'

import * as css from './style.scss'

interface ITradeOperationPanelProps {
  operation: TRADEORDER_OPERATION
}

export const TradeOperationPanel: React.FC<ITradeOperationPanelProps> =
  function TradeOperationPanel({operation, children}) {
    return (
      <Flex justifyCenter>
        <Card
          noMinHeight
          className={classNames(css.panel, {
            [css.buyFrame]: operation === TRADEORDER_OPERATION.BUY,
            [css.sellFrame]: [
              TRADEORDER_OPERATION.SELL,
              TRADEORDER_OPERATION.SELL_ALL
            ].includes(operation),
            [css.switchFrame]: [
              TRADEORDER_OPERATION.SWITCH,
              TRADEORDER_OPERATION.SWITCH_ALL
            ].includes(operation)
          })}
        >
          <FlexGridRow className={css.fullHeight}>{children}</FlexGridRow>
        </Card>
      </Flex>
    )
  }
