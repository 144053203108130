import React from 'react'

export function useCheckRows(
  defaultValue: string[]
): [string[], (selectedIds: string[]) => any] {
  const [state, updateState] = React.useState(defaultValue)

  const onCheckboxedRow = React.useCallback((selectedIds: string[]) => {
    updateState(selectedIds)
  }, [])

  return [state, onCheckboxedRow]
}
