import {IGlobalFilter} from '@d1g1t/typings/general'

export const DEFAULT_ALL_CLIENT_OPTION: IGlobalFilter = {
  url: '__all_clients__',
  name: 'All clients',
  rank: null,
  joinOperator: null,
  filterItems: null
}

export const GLOBAL_SETTINGS_SAVE_KEY = 'global-selected-filter'
