import {useEffect, useRef} from 'react'

/**
 *  Custom hook that returns a boolean indicating
 * if the component is about to unmount.
 */
export function useWillComponentUnmount() {
  const componentUnmounting = useRef(false)

  useEffect(() => {
    return () => {
      componentUnmounting.current = true
    }
  }, [])

  return componentUnmounting
}
