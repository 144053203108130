import {ISearchQuery} from '@d1g1t/api/endpoints'
import {ALL_MODELS} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'

/**
 * Returns the search query params for a SearchInputField's
 * form value.
 */
export function getSearchInputValueQuery(
  url: string,
  models: ALL_MODELS[],
  initialValue: string
): ISearchQuery {
  return {
    lookupId: url ? extractIdFromUrl(url) : undefined,
    q: url ? undefined : initialValue,
    models
  }
}
