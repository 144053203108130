import {IAccountsUser, IOTPJWT} from '@d1g1t/api/models'

import {BaseEndpoints} from './base'

export interface ILoginRequest {
  username: string
  password: string
}

// Input from user
export interface IMfaDestinationRequestInput
  extends Required<Pick<IOTPJWT, 'deliveryDestination'>> {}

// Request interface must add MFA token from Redux
export interface IMfaDestinationRequest extends IMfaDestinationRequestInput {
  otpToken: string
}

// Input from user
export interface IMfaLoginRequestInput {
  otp: number
}
// Request interface must add MFA token from Redux
export interface IMfaLoginRequest extends IMfaLoginRequestInput {
  otpToken: string
}

export interface ILoginAzureAdRequest {
  code: string
  session_state: string
}

export interface IRefreshTokenRequest {
  token: string
}

export interface ILoginResponse {
  token: string
}

export interface IPasswordResetCheckResponse {
  isOtpMobilePhoneRequired: boolean
}

export interface IUIPermissionCode {
  code: string
  name: string
}

export interface IEmulator
  extends Pick<IAccountsUser, 'firstName' | 'lastName' | 'username'> {}

export interface IUserMeResponse {
  url: string
  /**
   * User can log in into investor app as a his contact/investor. We call it emulator.
   */
  emulator?: IEmulator
  firstName: string
  lastName: string
  /**
   * Can be an empty string.
   */
  email: string
  /**
   * Email used for login (there are rare cases where this value is not an email)
   */
  username: string
  /**
   * Link to /user-accounts/profiles/:profileId
   */
  profile: string
  /**
   * Indicates active status of user, users can be de-activated by admins.
   * In theory this should always be true, inactive users cannot login.
   */
  isActive: boolean
  /**
   * User has `superuser` flag. Only d1g1t employees with admin access will
   * have this property. Generally used to display validation controls.
   */
  isSuperuser: boolean
  /**
   * User's last login time.
   *
   * Datetime string in ISO 8601 format
   * Eg: 2019-11-27T19:39:40+00:00
   */
  lastLogin: string
  /**
   * User's date joined time.
   *
   * Datetime string in ISO 8601 format
   * Eg: 2019-11-27T19:39:40+00:00
   */
  dateJoined: string
  /**
   * User's UI Permissions Set
   */
  uiPermissions: IUIPermissionCode[]
  /**
   * Link to attached contact: `/user-account/people/{contact-entity-id}`
   */
  person: string
  /**
   * User's preferred language. This is set as a default header for all requests as soon as it is received.
   */
  preferredLanguage: string
  /**
   * User's preferred date format
   */
  dateFormat: string
}

export interface IUserPasswordCreateResetRequest {
  uid: string
  token: string
  newPassword: string
  reNewPassword: string
  mobilePhoneNumber: string
}

export interface IUserPasswordResetRequest {
  email: string
}
export interface IEmulatorLoginRequest {
  username: string
}
export interface IEmulatorLoginResponse {
  token: {token: string}
}

export class AuthEndpoints extends BaseEndpoints {
  static basePath = '/auth'

  static login(body: ILoginRequest) {
    return super._post<ILoginResponse>('/login', {body})
  }

  static emulatorLogin(body: IEmulatorLoginRequest) {
    return super._post<IEmulatorLoginResponse>('/login-as', {body})
  }

  static mfaDestination(body: IMfaDestinationRequest) {
    return super._post<ILoginResponse>('/login', {body})
  }

  static mfaLogin(body: IMfaLoginRequest) {
    return super._post<ILoginResponse>('/login', {body})
  }

  static refresh(body: IRefreshTokenRequest) {
    return super._post<ILoginResponse>('/login/refresh', {body})
  }

  static loginAzureAd(body: ILoginAzureAdRequest) {
    return super._post<ILoginResponse>('/azuread/login', {body})
  }

  static resetPassword(body: IUserPasswordResetRequest) {
    return super._post('/password/reset', {body})
  }

  static resetPasswordConfirm(body: IUserPasswordCreateResetRequest) {
    return super._post('/password/reset/confirm', {body})
  }

  static resetPasswordCheck(
    body: Pick<IUserPasswordCreateResetRequest, 'uid' | 'token'>
  ) {
    return super._post<IPasswordResetCheckResponse>('/password/reset/check', {
      body
    })
  }

  static me() {
    return super._get<IUserMeResponse>('/me')
  }
}
