import React from 'react'

import {ISO_DATE_FORMAT} from '@d1g1t/lib/constants'

import {
  IKeyboardDatePickerProps,
  KeyboardDatePicker
} from '@d1g1t/shared/components/mui/keyboard-date-picker'

import {MULTIPLE_VALUES} from '../constants'
import {useFormFieldControl} from '../hook'
import {getFormFieldErrorState} from '../lib'
import {IFormFieldProps} from '../typings'

interface IKeyboardDatePickerFieldProps extends IFormFieldProps {
  keyboardDatePickerProps?: Partial<Omit<IKeyboardDatePickerProps, 'onChange'>>
  /**
   * Passing `dateType={'year'}` will turn the date picker into a year picker to choose only the year
   */
  dateType?: 'date' | 'year'
  disabled?: boolean
  /**
   * Setting this to true will use `null` instead of `<empty string>` for empty values
   */
  useNullEmptyValues?: boolean
}

/**
 * `formik`-compatible `Select` field
 */
export const KeyboardDatePickerField: React.FC<
  IKeyboardDatePickerFieldProps
> = (props) => {
  const {name, label, keyboardDatePickerProps = {}, dateType = 'date'} = props
  const [{onChange, ...field}, meta] = useFormFieldControl(props)
  const {hasError, errorText} = getFormFieldErrorState(meta)

  const id = props.id || props.name
  const errorTextId = `${id}-error-text`

  const value = props.useNullEmptyValues ? field.value ?? '' : field.value
  const isMultipleValues = value === MULTIPLE_VALUES

  return (
    <KeyboardDatePicker
      id={id}
      aria-describedby={errorTextId}
      label={label}
      multipleValues={isMultipleValues}
      fullWidth
      disabled={props.disabled}
      error={hasError}
      helperText={errorText}
      {...field}
      value={value}
      inputValue={isMultipleValues && '{Multiple Values}'}
      onChange={(value) => {
        if (value === '' && props.useNullEmptyValues) {
          onChange({
            target: {
              name,
              value: null
            }
          })
        } else {
          onChange({
            target: {
              name,
              value
            }
          })
        }
      }}
      format={dateType === 'year' ? 'yyyy' : ISO_DATE_FORMAT}
      views={dateType === 'year' ? ['year'] : undefined}
      {...keyboardDatePickerProps}
    />
  )
}
