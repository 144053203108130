import React from 'react'

import {useApi} from 'fairlight'
import {FormikProvider, useFormik} from 'formik'
import * as Yup from 'yup'

import {UserAccountProfilesEndpoints} from '@d1g1t/api/endpoints'
import {
  ACCOUNTSPROFILE_DISPLAY_CLIENT_OBJECTS_OPTIONS,
  ACCOUNTSPROFILE_MANAGE_CLIENT_LANDING_SUBPAGE_OPTIONS,
  ACCOUNTSPROFILE_PAGE_LEVEL_DATE_PERIOD_OPTIONS,
  ACCOUNTSPROFILE_SALUTATION_OPTIONS,
  ACCOUNTSPROFILE_SECURITY_MODELS_LANDING_SUBPAGE_OPTIONS,
  IAccountsProfile
} from '@d1g1t/api/models'

import {deepDiffObject} from '@d1g1t/lib/deep-diff-object'
import {useToggleState} from '@d1g1t/lib/hooks'
import {testPhoneNumber} from '@d1g1t/lib/yup-validators'

import {Bar} from '@d1g1t/shared/components/bar'
import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {Flex} from '@d1g1t/shared/components/flex'
import {FormActions} from '@d1g1t/shared/components/form-actions'
import {CheckboxField} from '@d1g1t/shared/components/form-field/checkbox-field'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {ValueLabelSelectField} from '@d1g1t/shared/components/form-field/value-select-field'
import {PhoneNumberInput} from '@d1g1t/shared/components/formatted-input'
import {LabeledCheckbox} from '@d1g1t/shared/components/mui/checkbox'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {H4, Text} from '@d1g1t/shared/components/typography'
import {UserAvatar} from '@d1g1t/shared/components/user-avatar'
import {AddressFields} from '@d1g1t/shared/containers/form-definitions/address'
import {
  ADDRESS_INITIAL_VALUES,
  getAddressValidationSchema
} from '@d1g1t/shared/containers/form-definitions/address/constants'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {
  IWithPermissionsProps,
  withPermissions
} from '@d1g1t/shared/wrappers/permissions'
import {useCurrencyList} from '@d1g1t/shared/wrappers/use-currency-list/use-currency-list'

import {RepCodeFields} from '@d1g1t/advisor/pages/administration/users/user/single/components/rep-codes-field'

enum USER_PROFILE_FIELD_NAMES {
  user = 'user',
  title = 'title',
  salutation = 'salutation',
  phoneNumberCell = 'phoneNumberCell',
  phoneNumberDaytime = 'phoneNumberDaytime',
  repCodes = 'repCodes',
  isAdmin = 'isAdmin',
  joinedFirmIn = 'joinedFirmIn',
  joinedIndustryIn = 'joinedIndustryIn',
  workAddress = 'workAddress',
  defaultCurrency = 'defaultCurrency',
  displayClientObjects = 'displayClientObjects',
  mfaRequired = 'mfaRequired',
  manageClientLandingSubpage = 'manageClientLandingSubpage',
  pageLevelDatePeriod = 'pageLevelDatePeriod',
  securityModelsLandingSubpage = 'securityModelsLandingSubpage',
  isCompactMode = 'isCompactMode'
}

enum FIELD_NAMES_USER {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email'
}

interface IAccountsProfileFormValues extends IAccountsProfile {}

const VALIDATION_SCHEMA = Yup.object({
  [USER_PROFILE_FIELD_NAMES.user]: Yup.object({
    firstName: Yup.string().required('First name is a required field'),
    lastName: Yup.string().required('Last name is a required field'),
    email: Yup.string().required('Email is a required field')
  }),
  [USER_PROFILE_FIELD_NAMES.title]: Yup.string(),
  [USER_PROFILE_FIELD_NAMES.salutation]: Yup.string(),
  [USER_PROFILE_FIELD_NAMES.phoneNumberCell]: Yup.string()
    .test(...testPhoneNumber())
    .nullable()
    .label('Phone number')
    .when(USER_PROFILE_FIELD_NAMES.mfaRequired, {
      is: true,
      then: Yup.string().required(
        'A valid cell phone number is required for 2-factor authentication'
      )
    }),

  [USER_PROFILE_FIELD_NAMES.phoneNumberDaytime]: Yup.string()
    .test(...testPhoneNumber())
    .nullable()
    .label('Phone number'),
  [USER_PROFILE_FIELD_NAMES.isAdmin]: Yup.boolean().required('Required field'),
  [USER_PROFILE_FIELD_NAMES.isCompactMode]: Yup.boolean(),
  [USER_PROFILE_FIELD_NAMES.joinedFirmIn]: Yup.string().nullable(),
  [USER_PROFILE_FIELD_NAMES.joinedIndustryIn]: Yup.string().nullable(),
  [USER_PROFILE_FIELD_NAMES.defaultCurrency]: Yup.string().nullable(),
  [USER_PROFILE_FIELD_NAMES.displayClientObjects]: Yup.string()
    .nullable()
    .required('Required field'),
  [USER_PROFILE_FIELD_NAMES.manageClientLandingSubpage]: Yup.string()
    .nullable()
    .required('Required field'),
  [USER_PROFILE_FIELD_NAMES.securityModelsLandingSubpage]: Yup.string()
    .nullable()
    .required('Required field'),
  [USER_PROFILE_FIELD_NAMES.pageLevelDatePeriod]:
    Yup.string().required('Required field'),
  [USER_PROFILE_FIELD_NAMES.workAddress]: Yup.object(
    getAddressValidationSchema()
  ).nullable(),
  [USER_PROFILE_FIELD_NAMES.repCodes]: Yup.array()
    .of(
      Yup.object({
        name: Yup.string().required('Rep code can not be empty')
      }).required()
    )
    .nullable()
})

export interface IUserProfileFormProps {
  showRepCodes?: boolean
  initialValues: IAccountsProfile
  userId: string
  onUpdateSuccess: () => void
}

export interface IFormComponentProps
  extends IUserProfileFormProps,
    IWithPermissionsProps {}

export const FormComponent: React.FC<IFormComponentProps> = (props) => {
  const api = useApi()
  const {showSnackbar} = useSnackbar()
  const {handleFormError} = useErrorHandler()

  const {firmConfiguration} = useFirmConfiguration()
  const [isLicensedPortfolioManager, toggleIsLicensedPortfolioManager] =
    useToggleState(
      () =>
        props.initialValues.repCodes && props.initialValues.repCodes.length > 0
    )

  const mfaRequired =
    props.initialValues.mfaRequired !== null
      ? props.initialValues.mfaRequired
      : firmConfiguration.data?.mfaRequiredInternal

  const initialValues: IAccountsProfileFormValues = {
    ...props.initialValues,
    ...(!props.initialValues.workAddress && {
      workAddress: {...ADDRESS_INITIAL_VALUES}
    }),
    mfaRequired
  }

  const formik = useFormik<IAccountsProfileFormValues>({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (data, formikBag) => {
      const updatedValues = deepDiffObject(initialValues, data)
      /// url is required to update the user
      const updatedUserReqBody = updatedValues.user
        ? {
            ...updatedValues,
            user: {
              ...updatedValues.user,
              url: props.initialValues.user.url
            }
          }
        : updatedValues

      try {
        await api.request(
          UserAccountProfilesEndpoints.partialUpdate(
            props.userId,
            updatedUserReqBody
          )
        )

        showSnackbar({
          variant: 'success',
          message: 'User profile updated'
        })

        props.onUpdateSuccess()
      } catch (error) {
        handleFormError(
          error,
          formikBag,
          props.initialValues,
          'An unexpected error occured while updating the User'
        )
      }
    },
    validationSchema: VALIDATION_SCHEMA
  })

  const {handleSubmit, setFieldValue, setFieldTouched, isSubmitting} = formik

  const {currencyOptions} = useCurrencyList()

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <ControlStateProvider loading={isSubmitting}>
          <Grid container>
            <Grid item xs={8}>
              <ValueLabelSelectField
                name={USER_PROFILE_FIELD_NAMES.salutation}
                label='Salutation'
                valueLabelSelectProps={{
                  options: ACCOUNTSPROFILE_SALUTATION_OPTIONS
                }}
              />
              <OutlinedInputField
                name={`${USER_PROFILE_FIELD_NAMES.user}.${FIELD_NAMES_USER.firstName}`}
                label='First name'
              />
              <OutlinedInputField
                name={`${USER_PROFILE_FIELD_NAMES.user}.${FIELD_NAMES_USER.lastName}`}
                label='Last name'
              />
              <OutlinedInputField
                name={`${USER_PROFILE_FIELD_NAMES.user}.${FIELD_NAMES_USER.email}`}
                label='Email'
              />
              <OutlinedInputField
                name={USER_PROFILE_FIELD_NAMES.title}
                label='Title'
              />
            </Grid>
            <Grid item xs={4}>
              <Flex column alignCenter>
                <UserAvatar
                  userIdOverride={props.userId} // shows upload + remove buttons
                  sizeOverride={200}
                  fontSizeOverride={100}
                />
              </Flex>
            </Grid>
            <Grid item xs={12}>
              <H4>Work Address</H4>
            </Grid>
            <Grid item xs={12}>
              <AddressFields name={USER_PROFILE_FIELD_NAMES.workAddress} />
            </Grid>
            <Grid item xs={6}>
              <OutlinedInputField
                name={USER_PROFILE_FIELD_NAMES.phoneNumberCell}
                label='Phone Number (Cell)'
                outlinedInputProps={{
                  inputComponent: PhoneNumberInput
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <OutlinedInputField
                name={USER_PROFILE_FIELD_NAMES.phoneNumberDaytime}
                label='Phone Number (Daytime)'
                helperText='This information is shared with investors'
                outlinedInputProps={{
                  inputComponent: PhoneNumberInput
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Text italics>
                Note: Please include country code: +1 for US/Canada, +44 for UK,
                etc.
              </Text>
            </Grid>

            <Grid item xs={12}>
              <H4>Professional Details</H4>
            </Grid>

            <Grid item xs={6}>
              <OutlinedInputField
                name={USER_PROFILE_FIELD_NAMES.joinedFirmIn}
                label='Joined Firm in (Year)'
              />
            </Grid>
            <Grid item xs={6}>
              <OutlinedInputField
                name={USER_PROFILE_FIELD_NAMES.joinedIndustryIn}
                label='Joined Industry in (Year)'
              />
            </Grid>

            {props.showRepCodes && (
              <Grid item xs={6}>
                <LabeledCheckbox
                  stopPropagation
                  label='Is user a Licensed Portfolio Manager?'
                  checked={isLicensedPortfolioManager}
                  onChangeValue={(value) => {
                    toggleIsLicensedPortfolioManager()
                    if (value) {
                      setFieldValue(USER_PROFILE_FIELD_NAMES.repCodes, [
                        {name: ''}
                      ])
                      setFieldTouched(
                        `${USER_PROFILE_FIELD_NAMES.repCodes}[0].name`,
                        false
                      )
                    } else {
                      setFieldValue(USER_PROFILE_FIELD_NAMES.repCodes, [])
                    }
                  }}
                />
                {isLicensedPortfolioManager && (
                  <RepCodeFields name={USER_PROFILE_FIELD_NAMES.repCodes} />
                )}
              </Grid>
            )}

            {props.permissions.isAdministrator() && (
              <Grid item xs={6}>
                <CheckboxField
                  name={USER_PROFILE_FIELD_NAMES.isAdmin}
                  label='Is user an Admin?'
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <H4>Preferences</H4>
            </Grid>
            <Grid item xs={6}>
              <ValueLabelSelectField
                name={USER_PROFILE_FIELD_NAMES.defaultCurrency}
                label='Reporting Currency'
                valueLabelSelectProps={{
                  options: currencyOptions
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ValueLabelSelectField
                id='display-client-objects'
                name={USER_PROFILE_FIELD_NAMES.displayClientObjects}
                label='Display Client Objects'
                valueLabelSelectProps={{
                  options: ACCOUNTSPROFILE_DISPLAY_CLIENT_OBJECTS_OPTIONS
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ValueLabelSelectField
                id='manage-client-landing-subpage'
                name={USER_PROFILE_FIELD_NAMES.manageClientLandingSubpage}
                label='Manage Client Landing Subpage'
                valueLabelSelectProps={{
                  options: ACCOUNTSPROFILE_MANAGE_CLIENT_LANDING_SUBPAGE_OPTIONS
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ValueLabelSelectField
                id='security-models-landing-subpage'
                name={USER_PROFILE_FIELD_NAMES.securityModelsLandingSubpage}
                label='Security Models Landing Subpage'
                valueLabelSelectProps={{
                  options:
                    ACCOUNTSPROFILE_SECURITY_MODELS_LANDING_SUBPAGE_OPTIONS
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ValueLabelSelectField
                id='page-level-date-period'
                name={USER_PROFILE_FIELD_NAMES.pageLevelDatePeriod}
                label='Page Level Date Period'
                valueLabelSelectProps={{
                  options: ACCOUNTSPROFILE_PAGE_LEVEL_DATE_PERIOD_OPTIONS
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CheckboxField
                name={USER_PROFILE_FIELD_NAMES.isCompactMode}
                label='Compact Mode Display'
              />
            </Grid>
          </Grid>
          <Bar fixedBottomBar>
            <FormActions />
          </Bar>
        </ControlStateProvider>
      </form>
    </FormikProvider>
  )
}

export const UserProfileForm = withPermissions()(FormComponent)
