import React, {useContext, useEffect} from 'react'

import {useApi} from 'fairlight'

import {SearchEndpoints} from '@d1g1t/api/endpoints'
import {ALL_MODELS} from '@d1g1t/api/models'

import {Label, Text} from '@d1g1t/shared/components/typography'
import {Search} from '@d1g1t/shared/containers/search'

import {MetricsFormContext} from '../../context'

export const MetricPortfolioSearch: React.FC = () => {
  const api = useApi()

  const {metricItem, relatedEntity, setRelatedEntity, onRelatedEntityChange} =
    useContext(MetricsFormContext)

  useEffect(() => {
    if (!metricItem.entityId) {
      return
    }

    if (!relatedEntity) {
      ;(async () => {
        try {
          const response = await api.request(
            SearchEndpoints.search({
              entityId: metricItem.entityId
            })
          )
          if (response.results && response.results.length) {
            setRelatedEntity(response.results[0])
          }
        } catch {}
      })()
    }
  }, [metricItem.entityId])

  return (
    <>
      <Label>Related Portfolio</Label>
      <Search
        clearOnResultSelect
        placeholder='Search Portfolios'
        onResultSelect={onRelatedEntityChange}
        searchBy={[
          ALL_MODELS.PERSON,
          ALL_MODELS.PORTFOLIO,
          ALL_MODELS.HOUSEHOLD,
          ALL_MODELS.INSTRUMENT,
          ALL_MODELS.ACCOUNT
        ]}
      />
      {relatedEntity && (
        <>
          <Text mediumLight indent>
            Selected:
          </Text>
          <Text semiBold indent>
            {relatedEntity.displayText}
          </Text>
        </>
      )}
    </>
  )
}
