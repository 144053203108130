import React, {useState} from 'react'

import {isEmpty} from 'lodash'

import {ALL_MODELS, CALCULATION_CODES} from '@d1g1t/api/models'

import {StandardResponseItem} from '@d1g1t/lib/standard-response'

import {LoaderDisplay} from '@d1g1t/shared/components/loader-display'
import {Modal, ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {P} from '@d1g1t/shared/components/typography'
import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'
import {ALL_MODELS_TO_ISELECTEDENTITIES_KEY_MAP} from '@d1g1t/shared/containers/select-entities'
import {StandardTable} from '@d1g1t/shared/containers/standard-table'
import {useCalculationData} from '@d1g1t/shared/wrappers/calculations'
import {
  ErrorBoundary,
  ModalContentsErrorFallback
} from '@d1g1t/shared/wrappers/error-boundary'
import {useGlobalSettings} from '@d1g1t/shared/wrappers/global-settings'

import {useEntityChipContext} from '../entity-chip'
import {
  ACCOUNT_GROUPING_OPTION,
  DEFAULT_HOLDINGS_FILTER_SETTNIGS,
  GROUPBY_ACCOUNT_MANDATE,
  GROUPBY_ACCOUNT_NAME,
  GROUPBY_ACCOUNT_TYPE,
  METRICS,
  POSITION_NAME
} from './constants'
import {findItemIdsFromModelIds, separateAccountsAndPositions} from './lib'
import {IHoldingsFilterProps} from './typings'

export * from './constants'

export * from './typings'
export * from './lib'

export const HoldingsFilter: React.FC<IHoldingsFilterProps> = (props) => {
  return (
    <Modal
      open
      fullscreen
      title={`Holdings Filter ${
        props.entityPrintName && ` - ${props.entityPrintName}`
      }`}
      onClose={props.onClose}
    >
      <ErrorBoundary
        resetId='no-reset'
        fallback={<ModalContentsErrorFallback onClose={props.onClose} />}
      >
        <HoldingsFilterModalContents {...props} />
      </ErrorBoundary>
    </Modal>
  )
}

const HoldingsFilterModalContents: React.FC<IHoldingsFilterProps> = (props) => {
  const saveId = `SELECT-CLIENTS-FILTER-GROUPING-${props.entityId}`
  const [settings, {updateGlobalSettingsKeys}] = useGlobalSettings(
    saveId,
    DEFAULT_HOLDINGS_FILTER_SETTNIGS,
    [props.entityId]
  )

  const {entity: entitySelection, actions} = useEntityChipContext()

  const [selectedHoldings, setSelectedHoldings] = useState(() => {
    if (!entitySelection) {
      return
    }

    const urlSelectedHoldings = []
      .concat(entitySelection.accounts ?? [])
      .concat(entitySelection.positions ?? [])

    if (!isEmpty(urlSelectedHoldings)) {
      return urlSelectedHoldings
    }

    return props.accountsEntityIds
  })

  const singleEntityControl = (() => {
    return {
      selectedEntities: {
        [ALL_MODELS_TO_ISELECTEDENTITIES_KEY_MAP[props.entityModel]]: [
          props.entityId
        ]
      }
    }
  })()

  const calculationGroupings = () => {
    switch (settings?.accountGrouping) {
      case ACCOUNT_GROUPING_OPTION.ACCOUNT:
        return GROUPBY_ACCOUNT_TYPE
      case ACCOUNT_GROUPING_OPTION.ACCOUNT_NAME:
        return GROUPBY_ACCOUNT_NAME
      case ACCOUNT_GROUPING_OPTION.INVESTMENT_MANDATE:
        return GROUPBY_ACCOUNT_MANDATE
      default:
        return GROUPBY_ACCOUNT_MANDATE
    }
  }

  const [calculationData] = useCalculationData({
    calculationCode: CALCULATION_CODES.CPH_TABLE,
    useCachedData: false,
    excludeRuleBasedFilters: true,
    control: singleEntityControl,
    singleResult: true,
    requiredMetrics: METRICS,
    groups: props.filterByAccountsOnly
      ? calculationGroupings().filter((grouping) => grouping !== POSITION_NAME)
      : calculationGroupings(),
    useErrorBoundary: true
  })

  const handleConfirm = () => {
    const separatedAccountsAndPositions = separateAccountsAndPositions(
      calculationData.data,
      selectedHoldings
    )

    // Apply filters only, if same entity selected
    if (entitySelection.entityId === props.entityId) {
      actions.applyEntityFilters(props.entityId, {
        accounts: separatedAccountsAndPositions.accounts,
        positions: separatedAccountsAndPositions.positions
      })
    } else {
      actions.replaceEntity(entitySelection.entityId, props.entityId, {
        accounts: separatedAccountsAndPositions.accounts,
        positions: separatedAccountsAndPositions.positions
      })
    }

    props.onClose()
  }

  // Given a list of selected accounts/positions model IDs, find item IDs
  const getCheckedRowsItemIds = () => {
    return findItemIdsFromModelIds(calculationData.data, selectedHoldings)
  }

  return (
    <>
      <ModalContent fullHeight>
        <P>
          Please select the accounts/holdings you wish to include in the
          results.
        </P>
        Group by{'  '}
        <ValueLabelSelect
          size='small'
          greyBackground
          noBorder
          value={settings?.accountGrouping}
          onChange={updateGlobalSettingsKeys.accountGrouping}
          options={[
            {
              value: ACCOUNT_GROUPING_OPTION.ACCOUNT,
              label: 'Account Type'
            },
            {
              value: ACCOUNT_GROUPING_OPTION.ACCOUNT_NAME,
              label: 'Account Name'
            },
            {
              value: ACCOUNT_GROUPING_OPTION.INVESTMENT_MANDATE,
              label: 'Investment Mandate'
            }
          ]}
        />
        <LoaderDisplay {...calculationData} />
        {calculationData.data && (
          <div>
            <StandardTable
              id='account-select-client-account-holdings'
              table={calculationData.data}
              checkedColumnWidth={60}
              checkedRows={getCheckedRowsItemIds()}
              onCheckboxedRows={(ids) => {
                const modelIds = calculationData.data.modelIdsByItemIds(ids)
                setSelectedHoldings(modelIds)
              }}
              checkedRowsPredicate={(item: StandardResponseItem): boolean => {
                return (
                  item.modelName === ALL_MODELS.ACCOUNT ||
                  item.modelName === ALL_MODELS.CASHPOSITION ||
                  item.modelName === ALL_MODELS.POSITIONHOLDING
                )
              }}
            />
          </div>
        )}
      </ModalContent>
      <ModalActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Spacer xs vertical />
        <Button
          primary
          contained
          onClick={handleConfirm}
          disabled={selectedHoldings.length === 0}
        >
          Apply Filter
        </Button>
      </ModalActions>
    </>
  )
}
