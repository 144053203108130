import produce from 'immer'
import {ActionType, getType} from 'typesafe-actions'

import {loaderInit} from '@d1g1t/lib/reducers'

import {actions} from './actions'
import {IDomain} from './typings'

const initialState: IDomain = {
  rebalance: loaderInit(null)
}

export function reducer(
  state: IDomain = initialState,
  action: ActionType<typeof actions>
): IDomain {
  switch (action.type) {
    case getType(actions.rebalanceAccountsRequest):
    case getType(actions.clearDraftRequest):
      return produce(state, (draft) => {
        draft.rebalance.loading = true
      })
    case getType(actions.rebalanceAccountsSuccess):
    case getType(actions.updateData):
    case getType(actions.clearDraftSuccess):
      return produce(state, (draft) => {
        draft.rebalance = {
          loading: false,
          data: action.payload,
          error: null
        }
      })
    case getType(actions.rebalanceAccountsFailure):
      return produce(state, (draft) => {
        draft.rebalance.loading = false
        draft.rebalance.error = action.payload
      })
    case getType(actions.clearDraftFailure):
      return produce(state, (draft) => {
        draft.rebalance = {
          loading: false,
          data: null,
          error: action.payload
        }
      })
    default:
      return state
  }
}
