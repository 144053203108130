import {useHistory, useLocation, useRouteMatch} from 'react-router-dom'

interface IUsePathToggleParams {
  closedPath: string
  openPath: string
}

type IUsePathToggleReturnValue = [
  /**
   * `true` if in the `open` path
   */
  boolean,
  {
    /**
     * Link to go to the `openPath`
     */
    openLink: string
    /**
     * Navigates to the `closedPath`
     */
    onClose(): void
  }
]

/**
 * Hook for simplifying the process of opening and closing something (ie. a modal)
 * based on the location path.
 * **
 * ****It will automatically keep the search string appended when navigating back and forth.****
 * **
 */
export function usePathToggle(
  params: IUsePathToggleParams
): IUsePathToggleReturnValue {
  const {search} = useLocation()
  const history = useHistory()
  const routeMatch = useRouteMatch(params.openPath)

  const open = params.openPath && !!routeMatch

  const appendSearch = (path: string) => (search ? `${path}${search}` : path)

  const openLink = appendSearch(params.openPath)

  const onClose = () => {
    history.replace(appendSearch(params.closedPath))
  }

  return [open, {openLink, onClose}]
}
