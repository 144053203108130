import React from 'react'
import {
  DragSource,
  DragSourceCollector,
  DragSourceSpec,
  DropTarget
} from 'react-dnd'
import {compose} from 'redux'

import {shouldUpdate} from '@shakacode/recompose'

import DragHandleIcon from '@material-ui/icons/DragHandle'

import {classNames} from '@d1g1t/lib/class-names'
import {partialIsEqual} from '@d1g1t/lib/partial-is-equal'

import {Text} from '@d1g1t/shared/components/typography'
import {
  DRAG_ROW_TYPE,
  ROW_STYLE_STATE
} from '@d1g1t/shared/containers/standard-table/constants'
import {
  cellStyleIsEqual,
  createDropSpec_DEPRECATED,
  dropTargetCollector_DEPRECATED
} from '@d1g1t/shared/containers/standard-table/lib'
import {IDropTargetCollect} from '@d1g1t/shared/containers/standard-table/typings'

import {IDragCellProps, IDragSourceCollect} from './typings'

import * as commonCss from '@d1g1t/shared/containers/standard-table/styles.scss'
import * as css from './styles.scss'

export class DragCellComponent extends React.PureComponent<
  IDragCellProps & IDragSourceCollect & IDropTargetCollect
> {
  handleCellMouseEnter = () => {
    this.props.onMouseEnter(this.props.rowIndex, this.props.columnIndex)
  }

  render() {
    return this.props.connectDropTarget(
      this.props.connectDragSource(
        <div
          data-row={this.props.rowIndex}
          data-column={this.props.columnIndex}
          className={classNames(commonCss.cell, css.dragCell, {
            [commonCss.hoverRow]: this.props.hoverRow,
            [commonCss.drageSourceRow]: this.props.isDragging,
            [commonCss.dropTargetRow]: this.props.dropTarget,
            [commonCss.highlightedRow]:
              this.props.rowStyleState === ROW_STYLE_STATE.HIGHLIGHTED
          })}
          style={this.props.style}
          onMouseEnter={this.handleCellMouseEnter}
        >
          {this.props.dragActive ? (
            <Text className={css.rowNumber} alignRight>
              {this.props.rowNumber}
            </Text>
          ) : (
            <DragHandleIcon />
          )}
        </div>
      )
    )
  }
}

const dragSpec: DragSourceSpec<IDragCellProps, any> = {
  beginDrag(props, monitor, component) {
    props.onBeginDrag(props.rowIndex, props.item)

    return props
  },
  endDrag(props, monitor, component) {
    props.onEndDrag(props.rowIndex)
  }
}

const dragSourceCollector: DragSourceCollector<any, any> = (
  connect,
  monitor
): IDragSourceCollect => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
})

export const shouldDragCellUpdate = (
  props: IDragCellProps,
  nextProps: IDragCellProps
): boolean => {
  if (!cellStyleIsEqual(props.style, nextProps.style)) {
    return true
  }

  const rootProps: (keyof IDragCellProps)[] = [
    'rowIndex',
    'rowNumber',
    'hoverRow',
    'rowStyleState',
    'dragActive'
  ]

  if (!partialIsEqual(props, nextProps, rootProps)) {
    return true
  }

  return false
}

export const DragCell = compose<React.ComponentClass<IDragCellProps>>(
  shouldUpdate(shouldDragCellUpdate),
  DragSource(DRAG_ROW_TYPE, dragSpec, dragSourceCollector),
  DropTarget(
    DRAG_ROW_TYPE,
    createDropSpec_DEPRECATED<IDragCellProps>(),
    dropTargetCollector_DEPRECATED
  )
)(DragCellComponent)
