import React from 'react'

import CloseIcon from '@material-ui/icons/Clear'
import LaunchIcon from '@material-ui/icons/Launch'

import {classNames} from '@d1g1t/lib/class-names'

import {Flex} from '@d1g1t/shared/components/flex'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {IconButtonLink} from '@d1g1t/shared/components/router-link'
import {H2} from '@d1g1t/shared/components/typography'

import * as css from './style.scss'

interface IQuickLookDrawerProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  /**
   * Title will appear in toolbar in H2 element
   */
  title: React.ReactNode
  /**
   * Path to linked resource, will create a button in the top-bar
   */
  link?: string
  /**
   * Display a tooltip on the link icon
   */
  linkTooltip?: React.ReactNode
  /**
   * Called when close button clicked, sends null for selected values
   */
  onClose(): void
  /**
   * custom icon with action that will appear inside the quick look drawer
   */
  additionalQuickLookActionIcon?: JSX.Element
}

/**
 * Use this component to create a quick look drawer next to `<StandardTable>`
 * of a fixed width
 */
export const QuickLookDrawer: React.FC<IQuickLookDrawerProps> = ({
  onClose,
  children,
  title,
  link,
  linkTooltip,
  additionalQuickLookActionIcon,
  className
}) => {
  return (
    <aside className={classNames(css.quickLookDrawer, className)}>
      <header>
        <Flex toolbar>
          <H2>{title}</H2>
          <Flex alignCenter>
            {additionalQuickLookActionIcon || null}
            {link && (
              <Tooltip title={linkTooltip}>
                <IconButtonLink to={link}>
                  <LaunchIcon />
                </IconButtonLink>
              </Tooltip>
            )}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Flex>
        </Flex>
      </header>
      {children}
    </aside>
  )
}

interface IQuickLookDrawerContentProps {
  /**
   * Remove top padding
   */
  noTopPadding?: boolean
}

export const QuickLookDrawerContent: React.FC<IQuickLookDrawerContentProps> = (
  props
) => {
  return (
    <div
      className={classNames(css.quickLookDrawerContent, {
        [css.noTopPadding]: props.noTopPadding
      })}
    >
      {props.children}
    </div>
  )
}

export const QuickLookDrawerActions: React.FC = (props) => {
  return (
    <footer className={css.quickLookDrawerActions}>{props.children}</footer>
  )
}
