import React, {useMemo} from 'react'

import {useApiQuery} from 'fairlight'
import {startCase} from 'lodash'

import {FeeSchedulesEndpoints} from '@d1g1t/api/endpoints'
import {CHART_VALUE_TYPE} from '@d1g1t/api/models'

import {mapObjectListToChartTable} from '@d1g1t/lib/chart-table/lib'
import {StandardResponse} from '@d1g1t/lib/standard-response'
import {extractIdFromUrl} from '@d1g1t/lib/url'
import {mapModelToValueLabel} from '@d1g1t/lib/value-label'

import {Grid} from '@d1g1t/shared/components/mui/grid'
import {H2} from '@d1g1t/shared/components/typography'
import {StandardTable} from '@d1g1t/shared/containers/standard-table'
import {DropdownCell} from '@d1g1t/shared/containers/standard-table/components/custom-types/dropdown-cell'

import {
  ICalculatedFeeItemNestedModified,
  IUnderlineFeeItemsProps
} from '../../../typings'

export const UnderlineFeeItems: React.FC<IUnderlineFeeItemsProps> = (props) => {
  const [feeSchedules] = useApiQuery(FeeSchedulesEndpoints.list(), {
    useErrorBoundary: true
  })
  const feeSchedulesOptions = useMemo(
    () => mapModelToValueLabel(feeSchedules.data?.results),
    [feeSchedules.data?.results]
  )

  const modifiedData: ICalculatedFeeItemNestedModified[] =
    props.calculatedFeeItems?.map((item) => {
      return {
        ...item,
        paymentAccountName: `${item.configurationMeta.paymentAccountName}(${item.configurationMeta.paymentAccountFirmProvidedKey})`,
        invoiceEntityFirmProvidedKey:
          item.configurationMeta.invoiceEntityFirmProvidedKey
      }
    })

  const table = useMemo(
    () =>
      new StandardResponse(
        mapObjectListToChartTable(
          modifiedData as Pick<
            ICalculatedFeeItemNestedModified,
            | 'id'
            | 'feeCategory'
            | 'feeSchedule'
            | 'normalizedAum'
            | 'feeCharge'
            | 'discountRate'
            | 'totalFee'
            | 'tax1'
            | 'taxCode1'
            | 'tax2'
            | 'taxCode2'
            | 'payee'
            | 'configurationMeta'
            | 'paymentAccountName'
            | 'invoiceEntityFirmProvidedKey'
          >[],
          [
            {
              propertyKey: 'id',
              name: 'Fee Item'
            },
            {
              propertyKey: 'feeCategory',
              name: 'Fee Category',
              format: (value) => startCase(value)
            },
            {
              propertyKey: 'feeSchedule',
              name: 'Fee Schedule',
              options: {
                editable: false,
                allowedValues: feeSchedulesOptions.map((item) => {
                  return {key: item.value, value: item.label}
                })
              }
            },
            {
              propertyKey: 'payee',
              name: 'Payee',
              format: (value) => value.payee
            },
            {
              propertyKey: 'invoiceEntityFirmProvidedKey',
              name: 'Invoice Entity'
            },
            {
              propertyKey: 'configurationMeta',
              name: 'Account',
              format: (value) =>
                `${value.accountName} (${value.accountFirmProvidedKey})`
            },
            {
              propertyKey: 'paymentAccountName',
              name: 'Payment Account'
            },
            {
              propertyKey: 'normalizedAum',
              name: `Billable Assets (${extractIdFromUrl(props.currency)})`,
              valueType: CHART_VALUE_TYPE.DECIMAL
            },
            {
              propertyKey: 'feeCharge',
              name: `Charges (${extractIdFromUrl(props.currency)})`,
              valueType: CHART_VALUE_TYPE.DECIMAL
            },

            {
              propertyKey: 'discountRate',
              name: 'Discount Rate',
              valueType: CHART_VALUE_TYPE.PERCENTAGE,
              options: {decimals: 2}
            },
            {
              propertyKey: 'tax1',
              name: `Tax 1 (${extractIdFromUrl(props.currency)})`,
              valueType: CHART_VALUE_TYPE.DECIMAL,
              options: {decimals: 2}
            },
            {
              propertyKey: 'taxCode1',
              name: 'Tax Code 1'
            },
            {
              propertyKey: 'tax2',
              name: `Tax 2 (${extractIdFromUrl(props.currency)})`,
              valueType: CHART_VALUE_TYPE.DECIMAL,
              options: {decimals: 2}
            },
            {
              propertyKey: 'taxCode2',
              name: 'Tax Code 2'
            },

            {
              propertyKey: 'totalFee',
              name: `Total Fee (${extractIdFromUrl(props.currency)})`,
              valueType: CHART_VALUE_TYPE.DECIMAL
            }
          ],
          'id'
        )
      ),
    [modifiedData, feeSchedulesOptions]
  )

  return (
    <Grid container>
      <Grid item>
        <H2>Underlying Fee Items ({modifiedData.length})</H2>
      </Grid>
      <Grid container direction='column'>
        <StandardTable
          table={table}
          maxHeight={400}
          id='underlying-fee-items'
          defaultColumnWidth={210}
          defaultColumnWidthForIds={{
            paymentAccountName: 320,
            configurationMeta: 320
          }}
          categoriesOverride={{
            paymentAccount: DropdownCell,
            feeSchedule: DropdownCell
          }}
        />
      </Grid>
    </Grid>
  )
}
