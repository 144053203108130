import React from 'react'

import {useField} from 'formik'

import {COUNTRIES_OPTIONS} from '@d1g1t/api/models'

import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {ProvinceSelectField} from '@d1g1t/shared/components/form-field/province-select-field'
import {ValueLabelAutocompleteField} from '@d1g1t/shared/components/form-field/value-label-autocomplete-field'
import {Grid} from '@d1g1t/shared/components/mui/grid'

import {ADDRESS_FIELD_NAMES} from './constants'

export interface IAddressFieldsProps {
  /**
   * Formik form key for these address fields to be nested under.
   */
  name: string
  showNameInput?: boolean
}

/**
 * Fields for a single address
 */
export const AddressFields: React.FC<IAddressFieldsProps> = ({
  name,
  showNameInput
}) => {
  const [{value: country}] = useField<string>(
    `${name}.${ADDRESS_FIELD_NAMES.country}`
  )

  return (
    <div data-testid='container-address'>
      {showNameInput && (
        <Grid container>
          <Grid item xs={12}>
            <OutlinedInputField
              name={`${name}.${ADDRESS_FIELD_NAMES.name}`}
              label='Full Name'
            />
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <OutlinedInputField
            name={`${name}.${ADDRESS_FIELD_NAMES.address1}`}
            label='Street Name and Number'
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <OutlinedInputField
            name={`${name}.${ADDRESS_FIELD_NAMES.address2}`}
            label='Address Line 2'
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <ValueLabelAutocompleteField
            name={`${name}.${ADDRESS_FIELD_NAMES.country}`}
            label='Country'
            ValueLabelAutocompleteProps={{
              options: COUNTRIES_OPTIONS
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <OutlinedInputField
            name={`${name}.${ADDRESS_FIELD_NAMES.city}`}
            label='City'
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <ProvinceSelectField
            name={`${name}.${ADDRESS_FIELD_NAMES.stateOrProvince}`}
            label='Province / State / Territory'
            country={country}
          />
        </Grid>
        <Grid item xs={6}>
          <OutlinedInputField
            name={`${name}.${ADDRESS_FIELD_NAMES.zipOrPostalCode}`}
            label={country === 'US' ? 'ZIP Code' : 'Postal Code'}
          />
        </Grid>
      </Grid>
    </div>
  )
}
