import {atom, RecoilValueReadOnly, selector} from 'recoil'

import {standardTableAtomFamily} from '@d1g1t/shared/containers/standard-table/atoms'
import {IGenerateExcelSheetParams} from '@d1g1t/shared/wrappers/generate-excel'

import {TRADE_PREVIEW_TABLE_ID} from '@d1g1t/advisor/containers/trade-preview'

import {REBALANCE_TABLE_ID} from './constants'

/**
 * Holds the list of IDs of RebalanceAccount StandardTables, and account names to use as tab titles.
 */
export const accountsDetailsAtom = atom<{[accountEntityId: string]: string}>({
  key: 'accountDetailsAtom',
  default: EMPTY_OBJECT
})

/**
 * A selector to get the tables necessary to generate
 * the Rebalance Approval Report as an Excel file.
 */
export const rebalanceApprovalReportSelector = selector({
  key: 'rebalanceApprovalReport',
  get: ({get}) => {
    const sheetParams: IGenerateExcelSheetParams[] = [
      {
        title: 'Overview',
        data: get(standardTableAtomFamily(REBALANCE_TABLE_ID))
      },
      {
        title: 'Recommended Trades',
        data: get(standardTableAtomFamily(TRADE_PREVIEW_TABLE_ID))
      }
    ]

    // Get the rebalance Accounts table(s)
    const accountDetails = get(accountsDetailsAtom)
    for (const rebalanceAccountStandardTableKey of Object.keys(
      accountDetails
    )) {
      const accountTable = get(
        standardTableAtomFamily(rebalanceAccountStandardTableKey)
      )

      sheetParams.push({
        title: accountDetails[rebalanceAccountStandardTableKey],
        data: accountTable
      })
    }

    return sheetParams
  }
}) as RecoilValueReadOnly<IGenerateExcelSheetParams[]>
