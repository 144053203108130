import React from 'react'

import produce from 'immer'

import {Flex} from '@d1g1t/shared/components/flex'
import {Checkbox} from '@d1g1t/shared/components/mui/checkbox'
import {FormControlLabel} from '@d1g1t/shared/components/mui/form-control-label'
import {IDisplayDataProps} from '@d1g1t/shared/containers/view-options/components/display-options/typings'

export const DisplayData: React.FC<IDisplayDataProps> = ({
  displayData,
  onChange,
  configuration,
  firmlookThroughDisabled
}) => {
  const createHandleToggle =
    (key: keyof IDisplayDataProps['displayData']) => () => {
      // On 'Show current data only' toggle, toggle the 'Hide empty nodes' checkbox
      onChange(
        produce(displayData, (draft) => {
          if (key === 'controlFilter') {
            return
          }

          draft[key] = !displayData[key]

          if (key === 'showCurrentDataOnly') {
            draft.hideEmptyNodes = !displayData[key]
          }
        })
      )
    }

  return (
    <Flex column wrap>
      {configuration.showHideEmptyZeroValueRowsControl && (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={!!displayData.hideEmptyRows}
              onChange={createHandleToggle('hideEmptyRows')}
              data-testid='checkbox-hide-empty-rows'
            />
          }
          label='Hide empty and zero-value rows'
        />
      )}
      {configuration.showInternalTransactionsControl && (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={!!displayData.showInternalTransactions}
              onChange={createHandleToggle('showInternalTransactions')}
              data-testid='checkbox-show-internal-transations'
            />
          }
          label='Show d1g1t internal transactions'
        />
      )}
      {configuration.showDisplayData && (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={!!displayData.showCurrentDataOnly}
              onChange={createHandleToggle('showCurrentDataOnly')}
              data-testid='checkbox-show-current-holdings'
            />
          }
          label='Show current holdings only'
        />
      )}
      {configuration.showDisplayData && displayData.showCurrentDataOnly && (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={!!displayData.hideEmptyNodes}
              onChange={createHandleToggle('hideEmptyNodes')}
              data-testid='checkbox-hide-empty-nodes'
            />
          }
          label='Hide empty nodes'
        />
      )}
      {configuration.showMultiplePositionsControl && (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={!!displayData.showMultiplePositions}
              onChange={createHandleToggle('showMultiplePositions')}
              data-testid='checkbox-show-multiple-positions'
            />
          }
          label='Separate positions with same Security Name'
        />
      )}
      {configuration.showBenchmarksControl && (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={!!displayData.showBenchmarks}
              onChange={createHandleToggle('showBenchmarks')}
              data-testid='checkbox-show-benchmarks'
            />
          }
          label='Show benchmarks'
        />
      )}
      {configuration.showCancelledTransactionsControl && (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={!!displayData.showCancelledTransactions}
              onChange={createHandleToggle('showCancelledTransactions')}
              data-testid='checkbox-show-cancelled-transactions'
            />
          }
          label='Show Cancelled Transactions'
        />
      )}
      {configuration.showPendingTransactionsControl && (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={!!displayData.showPendingTransactions}
              onChange={createHandleToggle('showPendingTransactions')}
              data-testid='checkbox-show-pending-transactions'
            />
          }
          label='Show Pending Transactions'
        />
      )}
      {configuration.showLookThrough && (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={!!displayData.showLookThrough}
              disabled={firmlookThroughDisabled}
              onChange={createHandleToggle('showLookThrough')}
              data-testid='checkbox-look-through-fund-and-holdings'
            />
          }
          title={
            firmlookThroughDisabled
              ? 'System is not configured to show look-through views.'
              : ''
          }
          label='Enable Look-through & SMA Funds'
        />
      )}
      {configuration.showTradesAsUngroupedItems && (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={!!displayData.showTradesAsUngroupedItems}
              onChange={createHandleToggle('showTradesAsUngroupedItems')}
              data-testid='checkbox-view-trades-ungrouped'
            />
          }
          label='View all trades as un-grouped items'
        />
      )}
    </Flex>
  )
}
