import React from 'react'

export const DescendingIcon = (props: {className?: string}) => (
  <svg
    width='12'
    height='15'
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={props.className}
  >
    <path
      d='M4.14634 4.36364V3.39394H8.04878V4.36364H4.14634ZM4.14634 7.27273V6.30303H6.09756V7.27273H4.14634ZM4.14634 1.45455V0.484848H10V1.45455H4.14634ZM2.19512 6.30303H3.41463L1.70732 8L0 6.30303H1.21951V0H2.19512V6.30303Z'
      fill='black'
    />
  </svg>
)
