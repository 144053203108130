import React from 'react'

import {IViewMetricItem} from '@d1g1t/api/models'

import {DraggableList} from '@d1g1t/shared/components/draggable-list'
import {DraggableText} from '@d1g1t/shared/components/draggable-text'
import {ListEditorEmptyStateButton} from '@d1g1t/shared/components/list-editor-empty-state'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'

interface IDraggableItem {
  id: number
  child: React.ReactElement<any>
}

interface IMetricsListProps {
  selectedItems: IViewMetricItem[]
  editIndex: number
  /**
   * The maximum number of metrics the user can define for this view.
   */
  maxMetrics?: number
  onMetricClick(id)
  onReorder(orderedSelectedItems: IViewMetricItem[])
  removeColumn(id)
  addColumn(column: IViewMetricItem)
}

export const MetricsList: React.FC<IMetricsListProps> = function MetricsList(
  props
) {
  const addItem = () => {
    props.addColumn({
      path: [],
      displayName: '',
      metric: '',
      contributionDimension: null,
      url: ''
    })
  }

  const removeItem = (itemIndex: number) => {
    props.removeColumn(itemIndex)
  }

  const handleReorder = (itemsReordered: IDraggableItem[]) => {
    if (!props.onReorder) {
      return
    }

    const orderedSelectedItems = itemsReordered.map(
      (item) => props.selectedItems[item.id]
    )
    props.onReorder(orderedSelectedItems)
  }

  const metrics = ((): IDraggableItem[] => {
    return props.selectedItems?.map((item, i) => ({
      id: i,
      child: (
        <DraggableText
          key={i}
          value={item.displayName}
          data-testid='metric-item-dragable'
          data-testtext={item.displayName}
        />
      )
    }))
  })()

  const metricsLimitExceeded: boolean =
    props.maxMetrics && metrics.length >= props.maxMetrics

  return (
    <div>
      <DraggableList
        items={metrics}
        itemSelected={props.editIndex}
        onReorderDrop={handleReorder}
        onRemoveItem={removeItem}
        onItemClick={props.onMetricClick}
        emptyText={
          <>
            No metrics have been applied to this table.{' '}
            <ListEditorEmptyStateButton onClick={addItem}>
              Add a metric
            </ListEditorEmptyStateButton>{' '}
            to get started.
          </>
        }
      />
      <Tooltip title={metricsLimitExceeded ? 'Metrics limit exceeded.' : ''}>
        <div>
          <Button
            fullWidth
            outlined
            onClick={addItem}
            data-testid='button-add-metric'
            disabled={metricsLimitExceeded}
          >
            {metrics.length === 0 ? 'Add a metric' : 'Add another metric'}
          </Button>
        </div>
      </Tooltip>
    </div>
  )
}
