import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {isValid, parseISO} from 'date-fns'

import LinkIcon from '@material-ui/icons/Link'

import {DATE_RANGES, DATE_RANGES_OPTIONS} from '@d1g1t/api/models'

import {
  DATE_OPTION_CUSTOM,
  DEFAULT_DATE_OPTIONS,
  IDateRange,
  IDateRangeValueType
} from '@d1g1t/lib/date-range'
import {useIsFirstRender, useToggleState} from '@d1g1t/lib/hooks'
import {pickFromObj} from '@d1g1t/lib/pick-from-obj'

import {Flex, FlexGridItem, FlexGridRow} from '@d1g1t/shared/components/flex'
import {Modal, ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Alert} from '@d1g1t/shared/components/mui/alert'
import {Button} from '@d1g1t/shared/components/mui/button'
import {KeyboardDatePicker} from '@d1g1t/shared/components/mui/keyboard-date-picker'
import {
  DATE_OPTION_PAGE_PERIOD,
  UI_DATE_RANGES
} from '@d1g1t/shared/components/range-selector/typings'
import {P} from '@d1g1t/shared/components/typography'
import {
  IValueLabelSelectOption,
  IValueLabelSelectProps,
  ValueLabelSelect
} from '@d1g1t/shared/components/value-label-select'
import {useCalculationOptionsOverride} from '@d1g1t/shared/wrappers/calculation-options'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {useDateFormatter} from '@d1g1t/shared/wrappers/formatter'

export interface IRangeSelectorProps
  extends Pick<IValueLabelSelectProps, 'spaceLeft' | 'spaceRight'> {
  /**
   * The selected date-range option.
   */
  dateRange: IDateRange
  filter?: IFilterDateOptions
  /**
   * Do not use firm config latest data available for default start and end dates
   */
  useTodayForDateBoundary?: boolean
  onDateRangeChange(dateRange: IDateRange): void
  allowFuture?: boolean
  fullWidth?: boolean
}

interface IFilterDateOptions {
  /**
   * Excludes these date-range options in the dropdown.
   */
  exclude?: IDateRange[]
  /**
   * Includes these date-range options in the dropdown specifically.
   *
   * Note: If `exclude` is provided, it will be applied on these to filter out some of these options.
   */
  include?: IDateRange[]
}

/**
 * Provides date range selector dropdown component.
 * i.e. the "1 Year", "3 Year", "5 Year", "Custom", "Since inception", etc. dropdown.
 */
export const DateRangeSelector: React.FC<IRangeSelectorProps> = (props) => {
  const isFirstRender = useIsFirstRender()
  const {t} = useTranslation()
  const {firmConfiguration} = useFirmConfiguration()
  const dateFormatter = useDateFormatter()

  const [calculationOptions] = useCalculationOptionsOverride()
  useEffect(() => {
    if (calculationOptions?.setAllDateRange && !isFirstRender) {
      // Change to "selected period" on `setAllDateRange` update
      props.onDateRangeChange(DATE_OPTION_PAGE_PERIOD)
    }
  }, [calculationOptions?.setAllDateRange])

  const [
    customDateRangeModalOpen,
    ,
    openCustomDateRangeModal,
    closeCustomDateRangeModal
  ] = useToggleState(false)

  // Today's date in 'YYYY-MM-DD' format
  const today = dateFormatter.format(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
  )

  const [dateRange, setDateRange] = useState({
    value: null,
    label: null,
    startDate:
      (props.dateRange && props.dateRange.startDate) ||
      (!props.useTodayForDateBoundary &&
        firmConfiguration.data?.latestDataAvailable) ||
      today,
    endDate:
      (props.dateRange && props.dateRange.endDate) ||
      (!props.useTodayForDateBoundary &&
        firmConfiguration.data?.latestDataAvailable) ||
      today
  })

  const handleDropdownChange = (value: IDateRangeValueType) => {
    if (value === DATE_RANGES.CUSTOM) {
      openCustomDateRangeModal()
      return
    }

    if (value === UI_DATE_RANGES.PAGE_PERIOD) {
      props.onDateRangeChange({
        value: DATE_OPTION_PAGE_PERIOD.value,
        label: DATE_OPTION_PAGE_PERIOD.label
      })
      return
    }

    const option = filteredDateRangeOptions().find(
      (dateOption) => dateOption.value === value
    )
    props.onDateRangeChange(option)
  }

  const handleCustomDatesConfirm = () => {
    closeCustomDateRangeModal()
    props.onDateRangeChange({
      ...dateRange,
      label: DATE_OPTION_CUSTOM.label,
      value: DATE_OPTION_CUSTOM.value
    })
  }

  const handleMinCustomDatePickerChange = (date: string) =>
    setDateRange({
      ...dateRange,
      startDate: date
    })

  const handleMaxCustomDatePickerChange = (date: string) =>
    setDateRange({
      ...dateRange,
      endDate: date
    })

  const filteredDateRangeOptions = () => {
    let dateOptions = DEFAULT_DATE_OPTIONS

    if (props.filter && props.filter.include) {
      dateOptions = props.filter.include
    }

    if (props.filter && props.filter.exclude) {
      return dateOptions.filter(
        (option) =>
          !props.filter.exclude.find(
            (excludedDateOption) => excludedDateOption.value === option.value
          )
      )
    }

    return dateOptions
  }

  if (!props.dateRange) {
    return null
  }

  const getDateRangeOptions = () => {
    const options: IValueLabelSelectOption[] = filteredDateRangeOptions().map(
      (dropdownOption) => pickFromObj(dropdownOption, 'value', 'label')
    )

    if (
      calculationOptions &&
      !props.filter?.exclude?.find(
        (excludedDateOption) =>
          excludedDateOption.value === UI_DATE_RANGES.PAGE_PERIOD
      )
    ) {
      // Appends `Page Period` option if CalcalationOptionsOverride context is detected
      const option = pickFromObj(
        DATE_OPTION_PAGE_PERIOD,
        'value',
        'label'
      ) as IValueLabelSelectOption

      option.icon = <LinkIcon />
      options.push(option)
    }

    return options
  }

  const startDate = parseISO(dateRange.startDate)
  const endDate = parseISO(dateRange.endDate)

  const validDateInputs =
    isValid(startDate) &&
    isValid(endDate) &&
    startDate <= new Date() &&
    endDate <= new Date()

  const startDateBeforeOrEqualToEndDate = startDate <= endDate

  return (
    <Flex>
      <ValueLabelSelect
        greyBackground
        noBorder
        size='small'
        fullWidth={props.fullWidth}
        value={props.dateRange.value}
        onChange={handleDropdownChange}
        options={getDateRangeOptions()}
        spaceLeft={props.spaceLeft}
        spaceRight={props.spaceRight}
        onClick={(event) => {
          const input = event.target as HTMLElement
          if (
            input.innerText ===
            DATE_RANGES_OPTIONS.find(
              (item) => item.value === DATE_RANGES.CUSTOM
            ).label
          ) {
            openCustomDateRangeModal()
          }
        }}
        renderValue={(value, label) => {
          if (value === DATE_OPTION_PAGE_PERIOD.value) {
            const label = (() => {
              switch (calculationOptions.pageLevelDateRange.value) {
                case DATE_RANGES.SINCE_INCEPTION:
                  return 'ItD'
                case DATE_RANGES.YTD:
                  return 'YtD'
                case DATE_RANGES.QTD:
                  return 'QtD'
                case DATE_RANGES.CUSTOM:
                  return 'Custom'
                default:
                  return calculationOptions.pageLevelDateRange.label
              }
            })()

            return (
              <Flex alignCenter>
                <LinkIcon />
                &nbsp;{t(label)}
              </Flex>
            )
          }
          return label
        }}
        data-testid='select-date-range'
      />
      <Modal
        title='Select a custom date range'
        open={customDateRangeModalOpen}
        onClose={closeCustomDateRangeModal}
      >
        <ModalContent>
          <FlexGridRow>
            <FlexGridItem col='1/2'>
              <P>From Date</P>
              <KeyboardDatePicker
                margin='dense'
                value={dateRange.startDate}
                onChange={handleMinCustomDatePickerChange}
                data-testid='from-date-field'
              />
            </FlexGridItem>
            <FlexGridItem col='1/2'>
              <P>To Date</P>
              <KeyboardDatePicker
                margin='dense'
                value={dateRange.endDate}
                allowFuture={props?.allowFuture ?? false}
                onChange={handleMaxCustomDatePickerChange}
                data-testid='to-date-field'
              />
            </FlexGridItem>
          </FlexGridRow>
          {validDateInputs && !startDateBeforeOrEqualToEndDate && (
            <Alert severity='error'>
              'To Date' cannot be before 'From Date'.
            </Alert>
          )}
        </ModalContent>
        <ModalActions>
          <Button
            primary
            contained
            disabled={!validDateInputs || !startDateBeforeOrEqualToEndDate}
            onClick={handleCustomDatesConfirm}
            data-testid='confirm-button'
          >
            Confirm
          </Button>
        </ModalActions>
      </Modal>
    </Flex>
  )
}
