import React, {useRef, useState} from 'react'
import {Link, Route, useHistory} from 'react-router-dom'

import {datadogRum} from '@datadog/browser-rum'

import Collapse from '@material-ui/core/Collapse'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AssessmentIcon from '@material-ui/icons/Assessment'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DeviceHub from '@material-ui/icons/DeviceHub'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined'
import FolderIcon from '@material-ui/icons/Folder'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import LayersIcon from '@material-ui/icons/Layers'
import MonetizationOnRounded from '@material-ui/icons/MonetizationOnRounded'
import SocialPeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'

import {classNames} from '@d1g1t/lib/class-names'
import {useToggleState} from '@d1g1t/lib/hooks'

import {ComboKeyListener} from '@d1g1t/shared/components/combo-key-listener'
import {Flex} from '@d1g1t/shared/components/flex'
import {ExploreIcon} from '@d1g1t/shared/components/icons/explore'
import {Popper} from '@d1g1t/shared/components/mui/popper'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'
import {usePermissions} from '@d1g1t/shared/wrappers/permissions'

import {Version} from '@d1g1t/advisor/containers/version'
import * as locations from '@d1g1t/advisor/locations'

import {KeyBoardShortCuts} from './components/keyboard-shortcuts'
import {
  LEADER_KEY,
  PRIMARY_SIDEMENU_PERMISSIONS_MAP,
  PRIMARY_SIDEMENU_SLUGS
} from './constants'
import {usePrimaryMenuVisibility} from './hooks'
import {IMenuDefinition} from './typings'

import * as css from './style.scss'

/**
 * Process Docs: https://www.notion.so/d1g1t/Primary-Menu-ab6480bf8d364af0a4f484714b426c49
 */
export const PrimarySideMenu: React.FC = () => {
  const history = useHistory()

  const permissions = usePermissions()

  // To expand and collapse primary menu on hover/swipe
  const {
    openPrimaryMenu,
    setOpenPrimaryMenu,
    onTouchStart,
    onTouchEnd,
    onTouchMove
  } = usePrimaryMenuVisibility()

  /**
   * Keeps track of whether each nested menu is open.
   * Keys are the nested menu item slugs.
   */
  const [openNestedMenu, setOpenNestedMenu] = useState({})

  /**
   * Test if the current user's profile allows them to see the sidemenu option.
   *
   */
  const userAllowedToSeeSection = (slug: PRIMARY_SIDEMENU_SLUGS) =>
    permissions.canAccess(PRIMARY_SIDEMENU_PERMISSIONS_MAP[slug])

  /**
   * NOTE: If these slugs change, or you edit the slug in Admin Panel \> Section,
   * then be sure to let PS know so they can create a migration for it.
   */
  const menuItems: IMenuDefinition[] = [
    {
      label: 'Monitor Business',
      topLevelRoutePath: ['monitor'],
      href: locations.overviewPath(),
      keyboardNavLetter: 'h',
      icon: <DashboardIcon />,
      slug: PRIMARY_SIDEMENU_SLUGS.MONITOR
    },
    {
      label: 'Manage Clients',
      topLevelRoutePath: [locations.ClientsLocations.basePath],
      icon: <SocialPeopleIcon />,
      slug: PRIMARY_SIDEMENU_SLUGS.MANAGE_CLIENTS,
      nestedItems: [
        {
          label: 'Client Portfolios',
          href: locations.ClientsLocations.portfolios(),
          keyboardNavLetter: 'c',
          slug: PRIMARY_SIDEMENU_SLUGS.MANAGE_CLIENT_PORTFOLIOS
        },
        {
          label: 'Households',
          href: locations.ClientsLocations.households(),
          slug: PRIMARY_SIDEMENU_SLUGS.MANAGE_CLIENT_HOUSEHOLDS
        },
        {
          label: 'Clients',
          href: locations.ClientsLocations.clients(),
          slug: PRIMARY_SIDEMENU_SLUGS.MANAGE_CLIENT_CLIENTS
        },
        {
          label: 'Accounts',
          href: locations.ClientsLocations.accounts(),
          slug: PRIMARY_SIDEMENU_SLUGS.MANAGE_CLIENT_ACCOUNTS
        },
        {
          label: 'Holdings',
          href: locations.ClientsLocations.holdings(),
          slug: PRIMARY_SIDEMENU_SLUGS.MANAGE_CLIENT_HOLDINGS
        }
      ]
    },
    {
      label: 'Manage Funds',
      topLevelRoutePath: [locations.ManageFundLocations.basePath],
      href: locations.ManageFundLocations.path(),
      keyboardNavLetter: 'f',
      icon: <DeviceHub />,
      slug: PRIMARY_SIDEMENU_SLUGS.MANAGE_FUNDS
    },
    {
      label: 'Models',
      topLevelRoutePath: [
        locations.SecurityModelLocations.basePath,
        locations.InvestmentProgramLocations.basePath
      ],
      icon: <TrackChangesIcon />,
      slug: PRIMARY_SIDEMENU_SLUGS.PORTFOLIO_PROGRAMS,
      nestedItems: [
        {
          label: 'Security Models',
          href: locations.SecurityModelLocations.list(),
          keyboardNavLetter: 'p',
          slug: PRIMARY_SIDEMENU_SLUGS.PORTFOLIOS_PROGRAMS_MODEL_PORTFOLIOS
        },
        {
          label: 'Investment Programs',
          href: locations.InvestmentProgramLocations.path(),
          keyboardNavLetter: 'i',
          slug: PRIMARY_SIDEMENU_SLUGS.PORTFOLIOS_PROGRAMS_INVESTMENT_PROGRAMS
        }
      ]
    },
    {
      label: 'Securities',
      topLevelRoutePath: [locations.securitiesListPath()],
      href: locations.securitiesListPath(),
      keyboardNavLetter: 's',
      icon: <LayersIcon className={css.slightlyBiggerMenuIcon} />,
      slug: PRIMARY_SIDEMENU_SLUGS.SECURITIES
    },
    {
      label: 'Explore',
      topLevelRoutePath: [locations.ExploreLocations.basePath],
      icon: <ExploreIcon />,
      slug: PRIMARY_SIDEMENU_SLUGS.EXPLORE,
      nestedItems: [
        {
          label: 'Compare Portfolios',
          href: locations.ExploreLocations.compare(),
          keyboardNavLetter: 'x',
          slug: PRIMARY_SIDEMENU_SLUGS.EXPLORE_COMPARE_PORTFOLIOS
        }
      ]
    },
    {
      label: 'Trade',
      topLevelRoutePath: [locations.TradeLocations.basePath],
      icon: (
        <SwapVerticalCircleIcon
          style={{transform: 'rotate(90deg) translateX(-50%)'}}
        />
      ),
      slug: PRIMARY_SIDEMENU_SLUGS.TRADE,
      nestedItems: [
        {
          label: 'Buy & Sell',
          href: locations.TradeLocations.equitiesEtfs(),
          keyboardNavLetter: 'b',
          slug: PRIMARY_SIDEMENU_SLUGS.TRADE_BUY_SELL
        },
        {
          label: 'Trade Directive',
          href: locations.TradeLocations.bulkChangeAllocation(),
          keyboardNavLetter: 't',
          slug: PRIMARY_SIDEMENU_SLUGS.TRADE_CHANGE_ALLOCATION
        },
        {
          label: 'Rebalance',
          href: locations.TradeLocations.rebalance(),
          keyboardNavLetter: 'r',
          slug: PRIMARY_SIDEMENU_SLUGS.TRADE_REBALANCE
        },
        {
          label: 'Manage Portfolios',
          href: locations.TradeLocations.managePortfolios(),
          keyboardNavLetter: 'a',
          slug: PRIMARY_SIDEMENU_SLUGS.TRADE_TRACK_STRATEGIES
        },
        {
          label: 'Manage Orders',
          href: locations.TradeLocations.manageOrders(),
          keyboardNavLetter: 'o',
          slug: PRIMARY_SIDEMENU_SLUGS.TRADE_TRADE_BLOTTER
        }
      ]
    },
    {
      label: 'Billing',
      topLevelRoutePath: [locations.BillingLocations.basePath],
      icon: <MonetizationOnRounded />,
      slug: PRIMARY_SIDEMENU_SLUGS.BILLING,
      nestedItems: [
        {
          label: 'Configure Billing',
          href: locations.BillingLocations.billingGroups(),
          keyboardNavLetter: 'l',
          slug: PRIMARY_SIDEMENU_SLUGS.BILLING_CONFIGURE_BILLING
        },
        {
          label: 'Manage Billing',
          href: locations.BillingLocations.calculatedFees(),
          keyboardNavLetter: 'e',
          slug: PRIMARY_SIDEMENU_SLUGS.BILLING_MANAGE_FEES
        }
      ]
    },
    {
      label: 'Compliance',
      topLevelRoutePath: [locations.TrackComplianceLocations.basePath],
      icon: <FlagOutlinedIcon />,
      slug: PRIMARY_SIDEMENU_SLUGS.TRACK_COMPLIANCE,
      nestedItems: [
        {
          label: 'Mandates',
          href: locations.TrackComplianceLocations.trackMandates(),
          slug: PRIMARY_SIDEMENU_SLUGS.TRACK_COMPLIANCE_TRACK_MANDATES
        }
      ]
    },
    {
      label: 'Document Vault',
      topLevelRoutePath: [locations.DMSDocumentsLocations.basePath],
      href: locations.DMSDocumentsLocations.documents(),
      icon: <FolderIcon />,
      keyboardNavLetter: 'v',
      slug: PRIMARY_SIDEMENU_SLUGS.DMS
    },
    {
      label: 'Reports',
      topLevelRoutePath: [locations.DocumentsLocations.basePath],
      icon: <AssessmentIcon />,
      slug: PRIMARY_SIDEMENU_SLUGS.DOCUMENTS,
      nestedItems: [
        {
          label: 'Report Templates',
          href: locations.DocumentsLocations.reportTemplates(),
          slug: PRIMARY_SIDEMENU_SLUGS.DOCUMENTS_REPORTS
        },
        {
          label: 'Documents',
          href: locations.DocumentsLocations.documents(),
          keyboardNavLetter: 'd',
          slug: PRIMARY_SIDEMENU_SLUGS.DOCUMENTS_MANAGE_DOCUMENTS
        }
      ]
    },
    {
      label: 'Contacts',
      topLevelRoutePath: [locations.ContactsLocations.basePath],
      href: locations.ContactsLocations.basePath,
      keyboardNavLetter: 'n',
      icon: <ContactMailIcon />,
      slug: PRIMARY_SIDEMENU_SLUGS.CONTACTS
    },
    {
      label: 'Administration',
      topLevelRoutePath: [locations.AdministrationLocations.basePath],
      icon: <SettingsIcon />,
      slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION,
      nestedItems: [
        {
          label: 'Users',
          href: locations.AdministrationLocations.userList(),
          slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_USERS
        },
        {
          label: 'Clients',
          href: locations.AdministrationLocations.clientAssignments(),
          slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_CLIENT_ASSIGNMENTS
        },
        {
          label: 'Households',
          href: locations.AdministrationLocations.householdsList(),
          slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_HOUSEHOLD_MANAGEMENT
        },
        {
          label: 'Accounts',
          href: locations.AdministrationLocations.accountManagement(),
          slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_ACCOUNT_MANAGEMENT
        },
        {
          label: 'Datasets',
          href: locations.AdministrationLocations.datasetsList(),
          slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_DATASETS
        },
        {
          label: 'Collections',
          href: locations.AdministrationLocations.collectionsList(),
          slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_COLLECTIONS
        },
        {
          label: 'Transactions',
          href: locations.AdministrationLocations.transactionsList(),
          slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_TRANSACTIONS
        },
        {
          label: 'Securities',
          href: locations.AdministrationLocations.manageSecurities(),
          slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_MANAGE_SECURITIES
        },
        {
          label: 'Restrictions & Exclusions',
          href: locations.AdministrationLocations.tradeRestrictions(),
          slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_TRADE_RESTRICTIONS
        },
        {
          label: 'Benchmarks',
          href: locations.AdministrationLocations.benchmarks(),
          slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_BENCHMARKS
        },
        {
          label: 'Trade Location Rules',
          href: locations.AdministrationLocations.tradeLocationRules(),
          slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_TRADE_LOCATION_RULES
        },
        {
          label: 'Processes',
          href: locations.AdministrationLocations.fileProcessing(),
          slug: PRIMARY_SIDEMENU_SLUGS.ADMINISTRATION_PROCESSING
        }
      ]
    }
  ]

  // Nested item(s)
  const renderNestedItems = (item: IMenuDefinition) => {
    if (!item.nestedItems) {
      return []
    }

    return (
      <Collapse in={openPrimaryMenu && openNestedMenu[item.slug]}>
        <List disablePadding component='div'>
          {item.nestedItems
            .filter((nestedItem) => userAllowedToSeeSection(nestedItem.slug))
            .map((subItem) => (
              <Route key={subItem.slug} path={subItem.href}>
                {({match}) => (
                  <Link to={subItem.href}>
                    <ListItem
                      button
                      className={classNames(css.listItem, css.nested, {
                        [css.selectedNested]: !!match
                      })}
                      onClick={
                        subItem.href && (() => setOpenPrimaryMenu(false))
                      }
                      data-testid={`navigation-side-menu-item-${subItem.slug}`}
                    >
                      <ListItemText disableTypography primary={subItem.label} />
                    </ListItem>
                    {subItem.keyboardNavLetter && (
                      <ComboKeyListener
                        key={subItem.slug + subItem.keyboardNavLetter}
                        leaderKey={LEADER_KEY}
                        actionKey={subItem.keyboardNavLetter}
                        onAction={() => {
                          history.push(subItem.href)
                          datadogRum.addAction('keyboardShortcut', {
                            leaderKey: LEADER_KEY,
                            actionKey: subItem.keyboardNavLetter,
                            href: subItem.href,
                            label: subItem.label
                          })
                        }}
                      />
                    )}
                  </Link>
                )}
              </Route>
            ))}
        </List>
      </Collapse>
    )
  }

  // Top level items
  const renderItem = (item: IMenuDefinition) => {
    const paths = item.topLevelRoutePath.map((path) => {
      if (path.charAt(0) === '/') {
        return path
      }

      return `/${path}`
    })

    return (
      <Route key={item.slug} path={paths}>
        {({match}) => {
          const topLevelNode = (
            <ListItem
              button
              className={classNames(css.listItem, css.topLevel, {
                [css.selected]: !!match
              })}
              onClick={() => {
                if (item.href) {
                  setOpenPrimaryMenu(false)
                } else {
                  setOpenNestedMenu({
                    ...openNestedMenu,
                    [item.slug]: openNestedMenu[item.slug]
                      ? !openNestedMenu[item.slug]
                      : true
                  })
                }
              }}
              data-testid={`navigation-side-menu-item-${item.slug}`}
            >
              <ListItemIcon>
                {React.cloneElement(item.icon, {
                  className: classNames(css.menuIcon, item.icon.props.className)
                })}
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={openPrimaryMenu ? item.label : ''}
              />
              {item.nestedItems &&
                openPrimaryMenu &&
                (openNestedMenu[item.slug] ? <ExpandLess /> : <ExpandMore />)}
              {item.keyboardNavLetter && (
                <ComboKeyListener
                  leaderKey={LEADER_KEY}
                  actionKey={item.keyboardNavLetter}
                  onAction={() => {
                    history.push(item.href)
                    datadogRum.addAction('keyboardShortcut', {
                      leaderKey: LEADER_KEY,
                      actionKey: item.keyboardNavLetter,
                      href: item.href,
                      label: item.label
                    })
                  }}
                />
              )}
            </ListItem>
          )

          return (
            <>
              {item.href ? (
                <Link to={item.href}>{topLevelNode}</Link>
              ) : (
                topLevelNode
              )}
              {item.nestedItems && renderNestedItems(item)}
            </>
          )
        }}
      </Route>
    )
  }

  const sideMenuRef = useRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const [keyboardShortcutsOpen, toggleKeyboardShortcutsOpen] =
    useToggleState(false)

  const handleClick = () => {
    setAnchorEl(sideMenuRef.current)
    toggleKeyboardShortcutsOpen()
  }

  const shortcuts = (() => {
    const shortcutList = []

    for (const menuItem of menuItems) {
      if (
        menuItem.keyboardNavLetter &&
        userAllowedToSeeSection(menuItem.slug)
      ) {
        shortcutList.push({
          name: menuItem.label,
          leaderKey: LEADER_KEY,
          actionKey: menuItem.keyboardNavLetter
        })
      }

      if (menuItem.nestedItems) {
        for (const nestedItem of menuItem.nestedItems) {
          if (
            nestedItem.keyboardNavLetter &&
            userAllowedToSeeSection(nestedItem.slug)
          ) {
            shortcutList.push({
              name: nestedItem.label,
              leaderKey: LEADER_KEY,
              actionKey: nestedItem.keyboardNavLetter
            })
          }
        }
      }
    }

    return shortcutList
  })()

  return (
    <div
      data-testid='container-side-menu'
      onMouseEnter={() => setOpenPrimaryMenu(true)}
      onMouseLeave={() => {
        setOpenPrimaryMenu(false)
        toggleKeyboardShortcutsOpen(false)
      }}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <Drawer
        open
        variant='permanent'
        anchor='left'
        classes={{
          paper: classNames(css.drawer, {
            [css.shrunk]: !openPrimaryMenu
          })
        }}
      >
        <List component='div'>
          {menuItems
            .filter((menuItem) => userAllowedToSeeSection(menuItem.slug))
            .map((item) => renderItem(item))}
        </List>
        <div
          ref={sideMenuRef}
          className={classNames(css.versionContainer, {
            [css.open]: openPrimaryMenu
          })}
        >
          <ErrorBoundary resetId='no-reset' fallback={null}>
            <Flex justifySpaceBetween alignCenter>
              <div className={css.keyboardIcon} onClick={handleClick}>
                <KeyboardIcon
                  className={classNames({
                    [css.keyboardShortcutsOpen]: keyboardShortcutsOpen
                  })}
                />
              </div>
              <Version
                className={classNames(css.version, {
                  [css.open]: openPrimaryMenu
                })}
              />
            </Flex>
          </ErrorBoundary>
        </div>
        <Popper
          open={keyboardShortcutsOpen}
          style={{zIndex: 1001, marginLeft: '1px'}}
          anchorEl={anchorEl}
          placement='right-end'
        >
          <KeyBoardShortCuts shortcuts={shortcuts} />
        </Popper>
      </Drawer>
    </div>
  )
}
