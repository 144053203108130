import React from 'react'

import CloseIcon from '@material-ui/icons/Close'

import {Formats, IFilterCriteriaOperator, Types} from '@d1g1t/api/endpoints'
import {IFilterValueItem, JoinOperator} from '@d1g1t/typings/general'

import {Flex} from '@d1g1t/shared/components/flex'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {Text} from '@d1g1t/shared/components/typography'
import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'
import {
  IComputedPath,
  IFilterOptions,
  IFilterOptionsValues,
  IFragmentValue
} from '@d1g1t/shared/wrappers/filter-criteria/typings'

import {Condition} from '../condition'
import {FilterInput} from '../input'

enum COMMON_ITEMS_FIELD_NAMES {
  value = 'value'
}

interface IFilterItemProps extends IFilterValueItem, IComputedPath {
  options: IFilterOptions
  values: IFilterOptionsValues
  joinOperator: JoinOperator
  index: number
  name: string
  computeNewOperator(fragment: StrNum[], index: number): IFilterCriteriaOperator
  onOperatorChange(
    id: IFilterCriteriaOperator,
    indexOfItem: number,
    previous: {type: Types; format: Formats}
  )
  onJoinOperatorChange(value: JoinOperator)
  onRemove(index: number)
}

export const FilterItem: React.FC<IFilterItemProps> = (props) => {
  const handleOperatorChange = (value: IFragmentValue) => {
    const operator = props.computeNewOperator(value.fragment, value.index)

    props.onOperatorChange(operator, props.index, {
      type: props.type,
      format: props.format
    })
  }

  const handleRemove = () => {
    props.onRemove(props.index)
  }

  if (!props.options) {
    return null
  }

  return (
    <div>
      {props.index > 0 && (
        <Condition
          joinOperator={props.joinOperator}
          onChange={props.index === 1 ? props.onJoinOperatorChange : null}
        />
      )}
      <div>
        <Flex>
          <Spacer vertical xxs />
          <Text>Rule {props.index + 1}</Text>
        </Flex>
        <Spacer xxs />
        <Flex justifySpaceBetween alignCenter>
          <Flex alignStart>
            <ValueLabelSelect
              spaceRight
              errorTextMargin
              tabIndex={1}
              value={props.values.subjects}
              options={props.options.subjects}
              onChange={handleOperatorChange}
            />
            <ValueLabelSelect
              spaceRight
              errorTextMargin
              tabIndex={1}
              value={props.values.propertiesOrMetrics}
              options={[...props.options.properties, ...props.options.metrics]}
              dividers={
                props.options.metrics.length
                  ? [
                      {
                        index: 0,
                        label: 'Properties'
                      },
                      {
                        index: props.options.properties.length,
                        label: 'Metrics'
                      }
                    ]
                  : null
              }
              onChange={handleOperatorChange}
            />
            {props.options.submetrics.map((submetric, i) => (
              <ValueLabelSelect
                key={i}
                spaceRight
                errorTextMargin
                tabIndex={3}
                value={props.values.submetrics[i]}
                options={submetric}
                onChange={handleOperatorChange}
              />
            ))}
            <ValueLabelSelect
              spaceRight
              errorTextMargin
              tabIndex={1}
              value={props.values.operators}
              options={props.options.operators}
              onChange={handleOperatorChange}
            />
            <FilterInput
              name={`${props.name}.${COMMON_ITEMS_FIELD_NAMES.value}`}
              type={props.type}
              format={props.format}
              allowedValues={props.allowedValues}
            />
          </Flex>
          <Flex>
            <Tooltip title='Remove Condition'>
              <IconButton errorTextMargin onClick={handleRemove}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Flex>
        </Flex>
      </div>
    </div>
  )
}
