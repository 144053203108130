import React, {useMemo, useState} from 'react'

import {useApiQuery} from 'fairlight'
import {isEmpty} from 'lodash'

import {MetricEndpoints} from '@d1g1t/api/endpoints'
import {IViewMetricItem} from '@d1g1t/api/models'

import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Modal} from '@d1g1t/shared/components/modal'
import {useCalculationOptionsOverride} from '@d1g1t/shared/wrappers/calculation-options'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'

import {MetricsForm} from './components/metrics-form'
import {MetricsList} from './components/metrics-list'
import {MetricsSingleLevelForm} from './components/metrics-single-level-form'
import {getAvailableMetricsCategory} from './lib'
import {IMetricsTabProps} from './typings'

export const MetricsTab: React.FC<IMetricsTabProps> = (props) => {
  const [metricsTree] = useApiQuery(MetricEndpoints.tree(), {
    fetchPolicy: 'cache-first'
  })
  const [calculationOptions] = useCalculationOptionsOverride()

  const availableMetricCategories = useMemo(() => {
    if (!metricsTree.data) {
      return null
    }
    return getAvailableMetricsCategory({
      availableMetrics: metricsTree.data.available,
      includeRelatedModels: props.includeRelatedModels,
      includeCategoryKeys: props.includeCategoryKeys,
      excludeCategoryKeys: props.excludeCategoryKeys,
      useCustodianAccounts: props.useCustodianAccounts,
      customMetrics: props.customMetrics,
      metricsFilter: props.metricsFilter,
      addPagePeriod: !!calculationOptions
    })
  }, [
    metricsTree.data?.available,
    props.includeRelatedModels,
    props.excludeCategoryKeys,
    props.includeCategoryKeys,
    props.useCustodianAccounts,
    calculationOptions
  ])
  const [editIndex, setEditIndex] = useState(-1)

  const removeMetricItem = (index: number) => {
    const selected = [...props.selected]
    selected.splice(index, 1)

    props.onChange(selected)
  }

  const addMetricItem = (metricItem: IViewMetricItem) => {
    const selected = [...props.selected, metricItem]
    props.onChange(selected)
    setEditIndex(selected.length - 1)
  }

  const handleReorder = (orderedSelectedItems: IViewMetricItem[]) => {
    props.onChange(orderedSelectedItems)
  }

  const handleApply = (columnToUpdate: IViewMetricItem, index: number) => {
    const selected = [...props.selected]
    selected[index] = {
      ...selected[index],
      ...columnToUpdate,
      contributionDimension: columnToUpdate.contributionDimension || null,
      contributionDimension2: columnToUpdate.contributionDimension2 || null
    }

    props.onChange(selected)
    handleClose()
  }

  const handleClose = () => {
    props.hideAddMetricModal()
    setEditIndex(-1)
  }

  if (!metricsTree.data) {
    return <LoadingContainer loading />
  }

  if (!!props.singleLevel) {
    return (
      <MetricsSingleLevelForm
        available={availableMetricCategories?.children[0]}
        maxMetrics={props.maxMetrics}
        selectedItems={props.selected}
        removeColumn={removeMetricItem}
        addColumn={addMetricItem}
        onChange={handleApply}
        onReorder={handleReorder}
      />
    )
  }

  const metricsForm = (() => {
    if (
      isEmpty(props.selected) ||
      !availableMetricCategories ||
      editIndex === -1
    ) {
      return null
    }

    const editSelection: IViewMetricItem = props.selected[editIndex]

    return (
      <MetricsForm
        key={editSelection.url || editIndex}
        id={editIndex}
        available={availableMetricCategories}
        value={editSelection}
        contributionDimensions={metricsTree.data.contributionDimensions}
        assetClassOptions={metricsTree.data.assetClassOptions}
        currencyOptions={metricsTree.data.currencyOptions}
        strategyOptions={metricsTree.data.strategyOptions}
        onCancel={handleClose}
        onApply={handleApply}
      />
    )
  })()

  return (
    <>
      <MetricsList
        editIndex={editIndex}
        selectedItems={props.selected}
        removeColumn={removeMetricItem}
        addColumn={addMetricItem}
        onMetricClick={setEditIndex}
        onReorder={handleReorder}
        maxMetrics={props.maxMetrics}
      />
      <Modal
        narrow
        title='Column Properties'
        open={editIndex !== -1}
        onClose={handleClose}
      >
        <ErrorBoundary resetId='no-reset'>{metricsForm}</ErrorBoundary>
      </Modal>
    </>
  )
}
