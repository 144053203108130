import React, {useEffect} from 'react'

import mousetrap from 'mousetrap'

export interface IComboKeyListenerProps {
  /**
   * Key to be pressed first in sequence followed by action key triggering `onAction`
   */
  leaderKey: KeyboardEvent['key']
  /**
   * Key to be pressed second in sequence after leader key triggering `onAction`
   */
  actionKey: KeyboardEvent['key']
  /**
   * If true will add `+` between characters to make it a combo
   * where keys have to be pressed together.
   * Otherwise it will be a sequence and separated by a space. (e.g. Gmail)
   */
  combo?: boolean
  /**
   * Callback function triggered when key sequence/combo is pressed
   *
   */
  onAction(): void
}

/**
 * Component binding keyboard shortcuts using `mousetrap`.
 * Will trigger `onAction` when key sequence/combo is pressed.
 */
export const ComboKeyListener: React.FC<IComboKeyListenerProps> = (props) => {
  const keys = `${props.leaderKey}${props.combo ? '+' : ' '}${props.actionKey}`

  useEffect(() => {
    mousetrap.bind(keys, props.onAction)

    return () => {
      mousetrap.unbind(keys)
    }
  }, [keys, props.onAction])

  return null
}
