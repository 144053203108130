/* eslint react/no-direct-mutation-state: 0 */
import React, {useCallback, useEffect, useMemo} from 'react'
import {DraggableData} from 'react-draggable'
import {Translation} from 'react-i18next'
import {
  AutoSizer,
  GridCellProps,
  GridCellRangeProps,
  GridCellRangeRenderer,
  MultiGrid
} from 'react-virtualized'

import produce from 'immer'
import {isEmpty, isEqual, range, some, throttle} from 'lodash'
import printHtmlElement from 'print-html-element'
import {useSetRecoilState} from 'recoil'

import {CHART_VALUE_TYPE, IChartTableCategory} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {getScrollbarWidth} from '@d1g1t/lib/dom'
import {useDebouncedValue} from '@d1g1t/lib/hooks'
import {
  performanceMarkStart,
  performanceMeasureFromStartMark
} from '@d1g1t/lib/performance'
import {
  itemLeafIds,
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'

import {Flex} from '@d1g1t/shared/components/flex'
import {LinearProgress} from '@d1g1t/shared/components/mui/linear-progress'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {P, Text} from '@d1g1t/shared/components/typography'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'
import {useChartValueCachedFormatter} from '@d1g1t/shared/wrappers/formatter'
import {useUserProfile} from '@d1g1t/shared/wrappers/user-profile'

import {standardTableAtomFamily} from './atoms'
import {ColumnFilterCell} from './components/column-filter-cell'
import {CheckboxCell} from './components/custom-types/checkbox-cell'
import {DataCell} from './components/data-cell'
import {DragCell} from './components/drag-cell'
import {ExpandedControlCell} from './components/expanded-control-cell'
import {ExpandedHeaderCell} from './components/expanded-header-cell'
import {HeaderCell, HeaderCellFiller} from './components/header-cell'
import {PopoutColumnFilterToggle} from './components/popout-column-filter-toggle'
import {PrintableTable} from './components/printable'
import {
  APPLY_COLUMN_FILTER_BUTTON_COLUMN,
  CHECK_ROW_TYPE,
  CHECKED_STATE,
  CLASS_NAME_TOP_RIGHT_GRID,
  DEFAULT_COLUMN_WIDTH_TYPES,
  DEFAULT_EXPANDED_HEADER_HEIGHT,
  KNOWN_CATEGORY_IDS,
  ROW_STYLE_STATE
} from './constants'
import {
  createStandardTableCategories,
  findVisibleColumnExpansions,
  generateTableItems,
  getCheckedIds,
  getCheckedIdsWithHierarchy,
  getFlattenedCategoriesToRender,
  getTableRowCount,
  isFirstExpandedCategory
} from './lib'
import {getRenderedWidth} from './render-element'
import {
  ICellLocation,
  IItemPageId,
  IndexBox,
  IStandardTableCategory,
  IStandardTableProps,
  IStandardTablePublicProps,
  IStandardTableState,
  ITableRowStyleState,
  StandardTableItem
} from './typings'
import {TableCheckedControl} from './wrappers/table-checked-control'
import {useColumnExpansionControl} from './wrappers/table-column-expansion-control'
import {useInternalTableColumnWidthControl} from './wrappers/table-column-width-control'
import {
  IColumnFilterState,
  useIntermediateColumnFilterControl,
  useTableColumnFilterControl,
  useTableColumnFilterControlRecoil
} from './wrappers/table-filters-control'
import {useTableSortControl} from './wrappers/table-sort-control'

import * as css from './styles.scss'

export * from './typings'
export * from './wrappers/table-filters-control'
export {useTableSortControl} from './wrappers/table-sort-control'
export * from './constants'

export const isReorderable = (
  props: Pick<IStandardTableProps, 'onReorder'>
): boolean => {
  return typeof props.onReorder === 'function'
}

export const isSortable = (
  props: Pick<
    IStandardTableProps,
    'disableSorting' | 'onCellValueChange' | 'onReorder'
  >
): boolean => {
  return !props.disableSorting && !isReorderable(props)
}

export const isSelectable = (props: IStandardTableProps): boolean => {
  return typeof props.onItemSelect === 'function'
}

export const isExpandable = (props: IStandardTableProps): boolean => {
  return props.table.items.some((item) => item && !item.isLeafNode)
}

export const getCategoryWidth = (
  nextProps: IStandardTableProps,
  category: IStandardTableCategory
): number => {
  if (nextProps.columnWidths[category.id]) {
    return nextProps.columnWidths[category.id]
  }

  if (
    (category.id === CHECK_ROW_TYPE ||
      category.id === APPLY_COLUMN_FILTER_BUTTON_COLUMN) &&
    nextProps.externalColumnFilterInputState &&
    (nextProps.applyColumnFilterButton ||
      nextProps.applyButtonBasedColumnFilter)
  ) {
    return nextProps.applyColumnFilterColumnWidth
  }

  if (category.id === CHECK_ROW_TYPE) {
    return nextProps.checkedColumnWidth
  }

  if (category.id === KNOWN_CATEGORY_IDS.NAME) {
    return nextProps.defaultNameColumnWidth
  }

  if (
    nextProps.defaultColumnWidthForIds &&
    nextProps.defaultColumnWidthForIds[category.id]
  ) {
    return nextProps.defaultColumnWidthForIds[category.id]
  }

  const columnTypeWidth = nextProps.defaultColumnWidthTypes[category.valueType]
  if (columnTypeWidth) {
    return columnTypeWidth
  }

  return nextProps.defaultColumnWidth
}

export const getDerivedRenderCategoriesAndTableWidth = (
  nextProps: IStandardTableProps,
  deferUpdate = false
) => {
  const categoriesWithCheckedRow = produce(
    nextProps.table.categories,
    (draft) => {
      if (nextProps.onCheckboxedRows) {
        draft.unshift({
          id: CHECK_ROW_TYPE,
          name: null,
          valueType: CHECK_ROW_TYPE
        })

        return
      }

      if (
        (nextProps.applyColumnFilterButton ||
          nextProps.applyButtonBasedColumnFilter) &&
        nextProps.externalColumnFilterInputState
      ) {
        draft.unshift({
          id: APPLY_COLUMN_FILTER_BUTTON_COLUMN
        })
      }
    }
  )

  const renderCategories = getFlattenedCategoriesToRender(
    createStandardTableCategories(
      categoriesWithCheckedRow,
      nextProps.expandedCategories || new Set(),
      nextProps.categoryIsHiddenWhenExpanded
    )
  )

  // apply category widths
  for (const category of renderCategories) {
    category.width = getCategoryWidth(nextProps, category)
  }

  let totalWidth = renderCategories.reduce(
    (prev, category) => prev + category.width,
    0
  )

  if (isReorderable(nextProps)) {
    totalWidth += nextProps.reorderableColumnWidth
  }

  const expandedCategoriesDepth = renderCategories.reduce(
    (prev, category) => Math.max(prev, category.level),
    0
  )

  let rowOffset = expandedCategoriesDepth + 1
  if (nextProps.externalColumnFilterInputState) {
    rowOffset += 1
  }

  const rowsIndexesInStyleState = getRowsIndexesAndStyleState(
    nextProps,
    rowOffset
  )

  return {
    deferUpdate,
    totalWidth,
    expandedCategoriesDepth,
    expandedCategoriesPropReference: nextProps.expandedCategories,
    renderCategories,
    rowsIndexesInStyleState
  } as IStandardTableState
}

/**
 * Checks if the current props provide a cateogryId for sorting and is sortable
 */
export const isValidSorting = (
  nextProps: IStandardTableProps,
  prevState: IStandardTableState,
  flattenedCategories: IStandardTableCategory[]
): boolean => {
  if (!isSortable(nextProps)) {
    return false
  }

  let sortCategoryId: string = null
  if (nextProps.sortedColumn) {
    sortCategoryId = nextProps.sortedColumn.categoryId
  }

  if (!sortCategoryId) {
    return false
  }

  return !!flattenedCategories.find((category) => {
    return category.id === sortCategoryId
  })
}

/**
 * Normalize current sorting state based on props
 */
export const getSortingProperties = (
  nextProps: IStandardTableProps,
  prevState: IStandardTableState,
  flattenedCategories: IStandardTableCategory[]
): {
  sortCategoryId: string
  sortOrder: SORT_ORDER
} => {
  if (!isValidSorting(nextProps, prevState, flattenedCategories)) {
    return {
      sortCategoryId: null,
      sortOrder: null
    }
  }

  return {
    sortCategoryId: nextProps.sortedColumn.categoryId,
    sortOrder: nextProps.sortedColumn.sortOrder
  }
}

export const getRowsIndexesAndStyleState = (
  nextProps: IStandardTableProps,
  rowOffset: number
) => {
  const rowsInState: ITableRowStyleState = new Map()

  const rowsInStyleState = Object.entries(
    nextProps.rowsInStyleState || {}
  ) as Array<[ROW_STYLE_STATE, string[]]>

  if (rowsInStyleState.length) {
    const tableItemsIndexById: Record<string, number> = nextProps.table
      .allItems()
      .reduce((prev, item, index) => ({...prev, [item.id]: index}), {})

    for (const [state, rowsIds] of rowsInStyleState) {
      for (const id of rowsIds || []) {
        if (!id) {
          continue
        }

        const index = tableItemsIndexById[id]

        if (index !== undefined) {
          rowsInState.set(index + rowOffset, state)
        }
      }
    }
  }
  return rowsInState
}

export const derivedStateUpdateRequired = (
  nextProps: IStandardTableProps,
  prevState: IStandardTableState
): boolean => {
  if (!nextProps.table) {
    return false
  }

  if (
    !isEqual(
      nextProps.rowsInStyleState,
      prevState.rowsInStyleStatePropReference
    ) ||
    nextProps.table.toChartTable().items !== prevState.itemsPropReference ||
    nextProps.table.categories !== prevState.categoriesPropReference ||
    nextProps.expandedCategories !==
      prevState.expandedCategoriesPropReference ||
    nextProps.categoryIsHiddenWhenExpanded !==
      prevState.categoryIsHiddenWhenExpandedPropReference ||
    !isEqual(
      getDerivedRenderCategoriesAndTableWidth(nextProps).renderCategories.map(
        (category) => category.width
      ),
      prevState.renderCategories.map((category) => category.width)
    )
  ) {
    return true
  }

  return false
}

export class StandardTableComponent extends React.Component<
  IStandardTableProps,
  IStandardTableState
> {
  multiGridRef: MultiGrid

  containerRef: HTMLDivElement

  state: IStandardTableState = {
    total: null,
    items: [],
    itemPageIds: [],
    expandedItems: new Set<string>(),
    expandedCategoriesDepth: 0,
    allTableRowsCount: 0,
    renderCategories: [],
    totalWidth: 0,
    hoverRow: null,
    hoverColumn: null,
    dragSourceRow: null,
    dragSourceItem: null,
    dropTargetRow: null,
    horizontalScrollbarSize: 0,
    verticalScrollbarSize: 0,
    resizingColumn: null,
    expandable: false,
    deferUpdate: false,
    tableIsFullyExpanded: false,
    print: false,
    columnFilterFocusedId: null,
    toggleFilterVisible: false,
    activeEditingCellLocation: null,

    itemsPropReference: null,
    categoriesPropReference: null,
    rowsInStyleStatePropReference: this.props.rowsInStyleState,
    rowsIndexesInStyleState: null,
    expandedCategoriesPropReference: this.props.expandedCategories,
    categoryIsHiddenWhenExpandedPropReference:
      this.props.categoryIsHiddenWhenExpanded,
    lastRenderedSection: null
  }

  static getDerivedStateFromProps(
    nextProps: IStandardTableProps,
    prevState: IStandardTableState
  ): Nullable<Partial<IStandardTableState>> {
    if (!derivedStateUpdateRequired(nextProps, prevState)) {
      return null
    }

    const categoryProperties =
      getDerivedRenderCategoriesAndTableWidth(nextProps)

    const total =
      nextProps.table.hasTotalItem && !nextProps.hideTotalRow
        ? new StandardTableItem(nextProps.table.totalItem)
        : undefined

    return {
      total,

      itemsPropReference: nextProps.table.toChartTable().items,
      categoriesPropReference: nextProps.table.categories,
      rowsInStyleStatePropReference: nextProps.rowsInStyleState,
      categoryIsHiddenWhenExpandedPropReference:
        nextProps.categoryIsHiddenWhenExpanded,

      expandedItems: prevState.expandedItems,
      allTableRowsCount: getTableRowCount(nextProps.table.items),
      expandable: isExpandable(nextProps),
      ...generateTableItems(nextProps.table.items, prevState.expandedItems),
      ...categoryProperties
    }
  }

  shouldComponentUpdate(
    nextProps: IStandardTableProps,
    nextState: IStandardTableState
  ) {
    if (nextState.deferUpdate) {
      nextState.deferUpdate = false

      return false
    }

    return true
  }

  /**
   * Called before each component update.
   *
   * When this method returns `true`, the grid will be recomputed after the update.
   */
  getSnapshotBeforeUpdate(
    prevProps: IStandardTableProps,
    prevState: IStandardTableState
  ) {
    if (!this.multiGridRef) {
      return null
    }

    if (
      some([
        prevProps.table !== this.props.table,
        prevProps.containerWidth !== this.props.containerWidth,
        prevProps.columnWidths !== this.props.columnWidths,
        prevProps.id !== this.props.id,
        prevProps.headerHeight !== this.props.headerHeight
      ])
    ) {
      return true
    }

    return null
  }

  setMultiGridRef = (node) => (this.multiGridRef = node)

  setContainerRef = (node) => (this.containerRef = node)

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)

    if (this.props.initializeItemsFullyExpanded && this.props.table) {
      this.state.expandedItems = new Set(this.props.table.allItemIds())
      this.updateExpandedRows()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  componentDidUpdate(
    prevProps: IStandardTableProps,
    prevState: IStandardTableState,
    snapshot: boolean
  ) {
    const conditions = [
      snapshot,
      prevProps.expandedCategories !== this.props.expandedCategories,
      prevState.renderCategories !== this.state.renderCategories
    ]

    if (conditions.some(Boolean)) {
      if (__DEVELOPMENT__) {
        console.info('StandardTable forceUpdate')
      }
      this.multiGridRef?.recomputeGridSize()
      this.forceUpdate()
    }
  }

  reorderable = (): boolean => isReorderable(this.props)

  sortable = (): boolean => isSortable(this.props)

  selectable = (): boolean => isSelectable(this.props)

  scrollTo = (options: {columnIndex: number; rowIndex: number}) => {
    const {fixedRowCount, fixedColumnCount, rowCount} = this.multiGridRef.props

    const columnIndex = options.columnIndex - fixedColumnCount
    const rowIndex = Math.min(
      Math.max(options.rowIndex - fixedRowCount, 0),
      rowCount - fixedRowCount - 1
    )

    // @ts-ignore - Need use of private property to avoid recalculating scroll positions
    this.multiGridRef._bottomRightGrid.scrollToCell({columnIndex, rowIndex})
  }

  /**
   * Finds adjacent editable column indexes for a given column indexes. This
   * is used to determine which column to go to next.
   */
  findEditableColumnIndexes = (fromColumnIndex: number) => {
    let i = 0
    if (this.reorderable()) {
      i++
    }

    let prev: number = null
    let next: number = null
    let first: number = null
    let last: number = null

    for (const category of this.state.renderCategories) {
      if (!category.options) {
        i++
        continue
      }
      if (category.options.editable) {
        if (i < fromColumnIndex) {
          prev = i
        }

        if (next === null && i > fromColumnIndex) {
          next = i
        }

        if (first === null) {
          first = i
        }
        last = i
      }
      i++
    }

    return {
      first,
      last,
      prev,
      next
    }
  }

  updateColumnWidth = (category: IStandardTableCategory, width: number) => {
    const newWidth = Math.min(
      Math.max(width, this.props.minColumnWidth),
      this.props.maxColumnWidth
    )

    if (newWidth === this.props.columnWidths[category.id]) {
      return
    }

    if (
      category.id !== CHECK_ROW_TYPE &&
      category.id !== APPLY_COLUMN_FILTER_BUTTON_COLUMN
    ) {
      this.props.onColumnWidthsChange({
        ...this.props.columnWidths,
        [category.id]: newWidth
      })
    }

    this.setState(
      getDerivedRenderCategoriesAndTableWidth(this.props, true),
      () => {
        this.multiGridRef?.recomputeGridSize()
        this.forceUpdate()
      }
    )
  }

  updateExpandedRows = () => {
    const tableItems = generateTableItems(
      this.props.table.items,
      this.state.expandedItems
    )
    this.setState(tableItems)

    if (__DEVELOPMENT__) {
      console.info('StandardTable grid size recomputed')
    }
    this.multiGridRef?.recomputeGridSize()

    return tableItems
  }

  handleResizeStart = (columnIndex: number) => {
    this.setState({
      resizingColumn: columnIndex
    })
  }

  handleResizeEnd = () => {
    this.setState({
      resizingColumn: null
    })
    this.props.commitColumnWidthChanges()
  }

  handleColumnResize = (
    category: IStandardTableCategory,
    data: DraggableData
  ) => {
    if (data.deltaX === 0) {
      return
    }

    this.updateColumnWidth(category, category.width + data.deltaX)
  }

  handleRequestAutoResizeColumn = async (
    columnIndex: number,
    category: IStandardTableCategory
  ) => {
    this.updateColumnWidth(category, await this.measureColumnWidth(columnIndex))
  }

  handleSortClick = (category: IChartTableCategory) => {
    const columnSortOrder = (() => {
      if (!this.props.sortedColumn) {
        return SORT_ORDER.DESC
      }

      if (category.id !== this.props.sortedColumn.categoryId) {
        if (
          [CHART_VALUE_TYPE.DATE, CHART_VALUE_TYPE.DATETIME].includes(
            category.valueType as CHART_VALUE_TYPE
          )
        ) {
          return SORT_ORDER.DESC
        }

        return SORT_ORDER.ASC
      }

      return this.props.sortedColumn.sortOrder === SORT_ORDER.ASC
        ? SORT_ORDER.DESC
        : SORT_ORDER.ASC
    })()

    this.props.onSortClick({
      categoryId: category.id,
      sortOrder: columnSortOrder
    })
  }

  /**
   * Handle expansion of a single item.
   *
   * State mutator
   */
  handleRowExpandClick = (item: StandardTableItem) => {
    if (this.state.expandedItems.has(item.id)) {
      this.state.expandedItems.delete(item.id)
    } else {
      this.state.expandedItems.add(item.id)
    }

    const {itemPageIds} = this.updateExpandedRows()
    /**
     * Update items rendered, since `MultiGrid#onSectionRendered`
     * isn't necessarily called when a row is expanded
     */
    this.updateItemsRendered(this.state.lastRenderedSection, itemPageIds)
  }

  addItemFamilyToExpandedItems = (item: StandardResponseItem) => {
    if (!this.state.expandedItems.has(item.id)) {
      this.state.expandedItems.add(item.id)
    }

    if (!item.items) {
      return
    }

    for (const childItem of item.items) {
      this.addItemFamilyToExpandedItems(childItem)
    }
  }

  handleRowExpandAllClick = (item: StandardTableItem) => {
    this.addItemFamilyToExpandedItems(item)

    this.updateExpandedRows()
  }

  removeItemFamilyFromExpandedItems = (item: StandardResponseItem) => {
    if (this.state.expandedItems.has(item.id)) {
      this.state.expandedItems.delete(item.id)
    }

    if (!item.items) {
      return
    }

    for (const childItem of item.items) {
      this.removeItemFamilyFromExpandedItems(childItem)
    }
  }

  handleRowCollapseAllClick = (item: StandardTableItem) => {
    this.removeItemFamilyFromExpandedItems(item)

    this.updateExpandedRows()
  }

  handleColumnExpand = (category: IStandardTableCategory) => {
    this.props.toggleExpandedCategory(category.id)
  }

  handleCellMouseEnter = (rowIndex: number, columnIndex: number) => {
    if (
      columnIndex === 0 || // always allows hover status on the first column
      !some([
        // blocks the hover style on the row if there are editable cells in the table
        this.findEditableColumnIndexes(0).first,
        this.findEditableColumnIndexes(0).last,
        this.findEditableColumnIndexes(0).next,
        this.findEditableColumnIndexes(0).prev
      ])
    ) {
      this.setState({
        hoverRow: rowIndex,
        hoverColumn: columnIndex
      })
    }
  }

  handleCellMouseLeave = (rowIndex: number, columnIndex: number) => {
    window.requestAnimationFrame(() => {
      if (
        this.state.hoverRow !== rowIndex ||
        this.state.hoverColumn !== columnIndex
      ) {
        return
      }

      this.setState({
        hoverRow: null,
        hoverColumn: null
      })
    })
  }

  handleGridMouseLeave = () => {
    this.setState({
      hoverRow: null,
      hoverColumn: null
    })
  }

  handleCellClick = this.props.onItemSelect
    ? (itemId: string) => {
        this.props.onItemSelect(itemId)
      }
    : undefined

  handleCellChange = this.props.onCellValueChange
    ? (
        item: StandardTableItem,
        category: IStandardTableCategory,
        value: any,
        key?: any
      ) => {
        this.props.onCellValueChange(item, category, value, key)
      }
    : undefined

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key !== 'Escape' || !this.props.onItemSelect) {
      return
    }

    this.props.onItemSelect(null)
  }

  handleBeginDrag = (rowIndex: number, item: StandardTableItem) => {
    this.setState({
      dragSourceRow: rowIndex,
      dragSourceItem: item
    })
  }

  handleEndDrag = (rowIndex: number) => {
    this.setState({
      dragSourceRow: null,
      dropTargetRow: null,
      dragSourceItem: null
    })
  }

  handleDragHover = throttle((rowIndex: number) => {
    if (
      rowIndex === this.state.dropTargetRow ||
      this.state.dragSourceRow === null
    ) {
      return
    }

    const indexAdjust = rowIndex === 0 && this.state.total ? 1 : 0

    this.setState({
      dropTargetRow: rowIndex + indexAdjust
    })
  }, 50)

  handleDrop = (rowIndex: number) => {
    let offsetRowIndex = rowIndex - this.state.expandedCategoriesDepth
    if (
      rowIndex === this.state.dragSourceRow ||
      rowIndex === this.state.dragSourceRow - 1
    ) {
      return
    }

    if (rowIndex < this.state.dragSourceRow) {
      offsetRowIndex += 1
    }

    this.props.onReorder(this.state.dragSourceItem, offsetRowIndex)
  }

  handleExpandAllRows = () => {
    this.state.expandedItems = new Set(this.props.table.allItemIds())
    this.state.tableIsFullyExpanded = true

    this.updateExpandedRows()
  }

  handleCollapseAllRows = () => {
    this.state.expandedItems = new Set()
    this.state.tableIsFullyExpanded = false

    this.updateExpandedRows()
  }

  getRenderCategory = (columnIndex: number): IStandardTableCategory =>
    this.state.renderCategories[
      this.reorderable() ? columnIndex - 1 : columnIndex
    ]

  getHideColumnIndicator = (columnIndex: number): boolean => {
    const nextCategory = this.getRenderCategory(columnIndex + 1)
    if (!nextCategory) {
      return false
    }

    return isFirstExpandedCategory(nextCategory)
  }

  handleCheckAllRows = () => {
    const leafIds = (() => {
      if (
        [CHECKED_STATE.CHECKED, CHECKED_STATE.PARTIAL].includes(
          this.getTableCheckedStatus()
        )
      ) {
        return []
      }

      return itemLeafIds(this.props.table.items)
    })()

    const checkedHierarchyIds = getCheckedIdsWithHierarchy(
      leafIds,
      this.props.table,
      this.props.checkedRowsPredicate
    )

    this.props.onCheckboxedRows(checkedHierarchyIds, leafIds)
  }

  // Returns a checked state for the table
  getTableCheckedStatus(): CHECKED_STATE {
    if (this.props.checkedRows.size === 0) {
      return CHECKED_STATE.EMPTY
    }

    const selected = []
    for (const [id, state] of this.props.checkedRows) {
      if (state === CHECKED_STATE.CHECKED) {
        selected.push(id)
      }
    }

    if (
      selected.length ===
      this.state.allTableRowsCount - (this.state.total ? 1 : 0)
    ) {
      return CHECKED_STATE.CHECKED
    }

    return CHECKED_STATE.PARTIAL
  }

  handleCheck = (
    checkedItem: StandardTableItem,
    rowIndex: number,
    event: React.MouseEvent,
    currentStatus: CHECKED_STATE
  ) => {
    const checkedLeafIds = getCheckedIds(
      checkedItem,
      this.props.table.items,
      this.props.checkedRows,
      {
        shiftKey: event.nativeEvent.shiftKey,
        singleSelect: this.props.singleSelect
      }
    )

    // Grouped by account/cashposition/positionholding ancestor nodes
    const checkedHierarchyIds = getCheckedIdsWithHierarchy(
      checkedLeafIds,
      this.props.table,
      this.props.checkedRowsPredicate
    )

    this.props.onCheckboxedRows(checkedHierarchyIds, checkedLeafIds)
  }

  handleUpdateColumnFilterFocusId = (categoryId: string) => {
    this.setState({columnFilterFocusedId: categoryId})
  }

  renderColumnFilterCell = (
    props: GridCellProps,
    category: IStandardTableCategory
  ) => {
    const {key, rowIndex, columnIndex, style} = props

    if (
      !category ||
      (category.id === CHECK_ROW_TYPE &&
        (!this.props.applyColumnFilterButton ||
          !this.props.applyButtonBasedColumnFilter))
    ) {
      return (
        <HeaderCellFiller
          key={key}
          style={style}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          dropTarget={this.state.dropTargetRow === rowIndex}
          onDragHover={this.handleDragHover}
          onDrop={this.handleDrop}
        />
      )
    }

    return (
      <ColumnFilterCell
        key={key}
        style={style}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        category={category}
        columnFilterFocusedId={this.state.columnFilterFocusedId}
        onUpdateColumnFilterFocusId={this.handleUpdateColumnFilterFocusId}
        value={this.props.externalColumnFilterInputState[category.id]}
        onChange={this.props.externalSetColumnFilterInputStateAtKey(
          category.id,
          !!category.options?.allowedValues ||
            category.valueType === CHART_VALUE_TYPE.BOOLEAN
        )}
        onDragHover={this.handleDragHover}
        onDrop={this.handleDrop}
        isCompactMode={this.props.isCompactMode}
        applyColumnFilterButton={this.props.applyColumnFilterButton}
        applyButtonBasedColumnFilter={this.props.applyButtonBasedColumnFilter}
        onApplyIntermediateColumnFilterState={
          this.props.onApplyIntermediateColumnFilterState
        }
      />
    )
  }

  handleOpenColumnFilterToggle = () => {
    this.setState({toggleFilterVisible: true})
  }

  handleHideColumnFilterToggle = () => {
    this.setState({toggleFilterVisible: false})
  }

  handleSetActiveEditingCellLocation = (cellLocation: ICellLocation) => {
    if (
      !cellLocation ||
      this.props.updatingItemIds === true ||
      (this.props.updatingItemIds as string[])?.includes(cellLocation.itemId)
    ) {
      this.setState({activeEditingCellLocation: null})

      return
    }

    const {categoryId, itemId} = cellLocation

    this.setState({
      activeEditingCellLocation: {
        categoryId,
        itemId
      }
    })
  }

  /**
   * Hides check all when:
   *
   * - singleSelect is true
   * - there is at least 1 row without an ID.
   * - more than 10,000 items.
   * - hideCheckAll is true
   *
   * Shows uncheck all if at least 1 item is checked and it is not a singleSelect
   */
  isCheckAllHidden = () => {
    if (this.props.singleSelect) {
      return true
    }
    if (!this.state.items) {
      return true
    }
    if (this.props.checkedRows.size !== 0) {
      return false
    }

    if (this.state.items.length > 10000) {
      return true
    }

    for (const item of this.state.items) {
      if (!item.id) {
        return true
      }
    }
    return this.props.hideCheckAll
  }

  renderHeaderCell = (
    props: GridCellProps,
    category: IStandardTableCategory
  ) => {
    const {key, rowIndex, columnIndex, style} = props
    if (!category) {
      return (
        <HeaderCellFiller
          key={key}
          style={style}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          dropTarget={this.state.dropTargetRow === rowIndex}
          onDragHover={this.handleDragHover}
          onDrop={this.handleDrop}
          onMouseEnter={this.handleOpenColumnFilterToggle}
          onMouseLeave={this.handleHideColumnFilterToggle}
        />
      )
    }

    let sortOrder = null
    if (this.props.sortedColumn.categoryId === category.id) {
      sortOrder = this.props.sortedColumn.sortOrder
    }

    return (
      <HeaderCell
        key={key}
        category={category}
        sortOrder={sortOrder}
        sortable={this.sortable()}
        style={style}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        resizingColumn={this.state.resizingColumn === columnIndex}
        dropTarget={this.state.dropTargetRow === rowIndex}
        hideColumnIndicator={this.getHideColumnIndicator(columnIndex)}
        hideCheckAll={this.isCheckAllHidden()}
        checkedColumnTitle={this.props.checkedColumnTitle}
        onCheckAllRows={this.handleCheckAllRows}
        checkedAllRows={this.getTableCheckedStatus()}
        checkedColumnHeaderOverride={this.props.checkedColumnHeader}
        categoryTooltipText={
          this.props.categoriesTooltipText &&
          this.props.categoriesTooltipText[category.id]
        }
        isFirstVisibleDataColumn={
          this.props.table.categories.find(
            (category) => !category.options?.hidden
          ).id === category.id
        }
        onDragHover={this.handleDragHover}
        onDrop={this.handleDrop}
        onSortClick={this.handleSortClick}
        onExpandClick={this.handleColumnExpand}
        onColumnResize={this.handleColumnResize}
        onColumnResizeStart={this.handleResizeStart}
        onColumnResizeEnd={this.handleResizeEnd}
        onRequestAutoResize={this.handleRequestAutoResizeColumn}
        onMouseEnter={this.handleOpenColumnFilterToggle}
        onMouseLeave={this.handleHideColumnFilterToggle}
        startAdornment={this.props.HeaderCellProps?.startAdornment}
        isCompactMode={this.props.isCompactMode}
      />
    )
  }

  renderExpandedHeaderCell = (
    props: GridCellProps,
    category: IStandardTableCategory
  ) => {
    const {key, style} = props
    if (!category) {
      return <div key={key} style={style} />
    }

    return (
      <ExpandedHeaderCell
        key={key}
        style={style}
        category={category}
        level={props.rowIndex}
      />
    )
  }

  /**
   * Custom cell range renderer augments the default implementation shipping
   * with `react-virtualized` to add the overlays for "sticky" expanded column
   * headers.
   */
  cellRangeRenderer: GridCellRangeRenderer = (params) => {
    const {
      parent // Grid
    } = params

    let renderedCells: React.ReactNode[] = []

    // Is the top-right grid and table has expanded categories
    if (
      parent.props.className.includes(CLASS_NAME_TOP_RIGHT_GRID) &&
      this.state.expandedCategoriesDepth > 0
    ) {
      performanceMarkStart('Expanded Column Nodes')

      renderedCells = renderedCells.concat(
        this.renderExpandedColumnHeaderRange(params)
      )

      performanceMeasureFromStartMark('Expanded Column Nodes')
    }

    renderedCells = renderedCells.concat(this.renderCellRange(params))

    return renderedCells
  }

  renderExpandedColumnHeaderRange = (
    params: GridCellRangeProps
  ): React.ReactNode[] => {
    const {
      scrollLeft,
      visibleColumnIndices,
      columnSizeAndPositionManager,
      horizontalOffsetAdjustment
    } = params

    const visibleColumnExpansions = findVisibleColumnExpansions(
      visibleColumnIndices,
      this.getRenderCategory,
      this.getFixedColumnCount()
    )

    // When do we render an expand header?
    // Show if:
    //   Expanded columns for a category are currently shown on the screen
    // This happens when:
    //   One of the categories in `visibleRowIndicies` is a currently expanded
    //   category -> id contains of `this.state.expandedCategories`

    const renderedCells = []

    for (const key in visibleColumnExpansions) {
      const {controlColumnIndex, endIndex, level, controlCategory} =
        visibleColumnExpansions[key]

      let maxLeft: number = null
      let left = scrollLeft
      let width = 240

      // If there is an ending column, we must restrict the how far left
      // the node can be positioned
      if (endIndex != null) {
        const columnDatum =
          columnSizeAndPositionManager.getSizeAndPositionOfCell(endIndex)

        const expansionEndedColumnLeftValue =
          columnDatum.offset + horizontalOffsetAdjustment
        maxLeft = Math.max(expansionEndedColumnLeftValue - width, 0)
        left = Math.min(left, maxLeft)
      }

      // If there is a "control" column, we must push the left position to
      // at least the beginning of the column
      if (controlColumnIndex != null) {
        const columnDatum =
          columnSizeAndPositionManager.getSizeAndPositionOfCell(
            controlColumnIndex
          )

        const columnLeftValue = columnDatum.offset + horizontalOffsetAdjustment

        // In the case where the "control" column is the not the first, move
        // position to match
        if (columnLeftValue > left) {
          left = columnLeftValue
        }

        // If the value for `maxLeft` is smaller then left, this is because
        // the total width of the expansion is smaller then the header, so
        // shrink the width to the minimum size
        if (maxLeft !== null && maxLeft < left) {
          width -= left - maxLeft
        }
      }

      let top = 0
      if (typeof this.props.expandedHeaderHeight === 'function') {
        for (let i = 0; i < level; i++) {
          top += this.props.expandedHeaderHeight({index: i})
        }
      } else if (typeof this.props.expandedHeaderHeight === 'number') {
        top = level * this.props.expandedHeaderHeight
      } else {
        const expandedHeaderHeight = !!controlCategory?.subName
          ? DEFAULT_EXPANDED_HEADER_HEIGHT * 2
          : DEFAULT_EXPANDED_HEADER_HEIGHT

        top = level * expandedHeaderHeight
      }

      const node = (
        <ExpandedControlCell
          key={key}
          level={level}
          category={controlCategory}
          style={{
            left,
            width,
            top,
            position: 'absolute',
            height: this.getExpandedHeaderHeight(level)
          }}
          onExpandClick={this.handleColumnExpand}
          startAdornment={this.props.ExpandedControlCellProps?.startAdornment}
        />
      )
      renderedCells.push(node)
    }

    return renderedCells
  }

  renderCellRange = (params: GridCellRangeProps): React.ReactNode[] => {
    const {
      rowStartIndex,
      rowStopIndex,
      visibleColumnIndices,
      columnSizeAndPositionManager,
      rowSizeAndPositionManager,
      horizontalOffsetAdjustment,
      columnStartIndex,
      columnStopIndex,
      visibleRowIndices,
      isScrolling,
      styleCache,
      verticalOffsetAdjustment,
      cellCache,
      cellRenderer,
      parent,
      isScrollingOptOut
    } = params

    // Browsers have native size limits for elements (eg Chrome 33M pixels, IE 1.5M pixes).
    // User cannot scroll beyond these size limitations.
    // In order to work around this, ScalingCellSizeAndPositionManager compresses offsets.
    // We should never cache styles for compressed offsets though as this can lead to bugs.
    // See issue #576 of react-virtualized for more.
    const areOffsetsAdjusted =
      columnSizeAndPositionManager.areOffsetsAdjusted() ||
      rowSizeAndPositionManager.areOffsetsAdjusted()

    const canCacheStyle = !isScrolling && !areOffsetsAdjusted

    const renderedCells: React.ReactNode[] = []

    for (let rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
      const rowDatum =
        rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex)

      for (
        let columnIndex = columnStartIndex;
        columnIndex <= columnStopIndex;
        columnIndex++
      ) {
        const columnDatum =
          columnSizeAndPositionManager.getSizeAndPositionOfCell(columnIndex)
        const isVisible =
          columnIndex >= visibleColumnIndices.start &&
          columnIndex <= visibleColumnIndices.stop &&
          rowIndex >= visibleRowIndices.start &&
          rowIndex <= visibleRowIndices.stop
        const key = `${rowIndex}-${columnIndex}`
        let style

        // Cache style objects so shallow-compare doesn't re-render unnecessarily.
        if (canCacheStyle && styleCache[key]) {
          style = styleCache[key]
        } else {
          style = {
            height: rowDatum.size,
            left: columnDatum.offset + horizontalOffsetAdjustment,
            position: 'absolute',
            top: rowDatum.offset + verticalOffsetAdjustment,
            width: columnDatum.size
          }

          styleCache[key] = style
        }

        const cellRendererParams = {
          columnIndex,
          isScrolling,
          isVisible,
          key,
          parent,
          rowIndex,
          style
        }

        let renderedCell

        // Avoid re-creating cells while scrolling.
        // This can lead to the same cell being created many times and can cause performance issues for "heavy" cells.
        // If a scroll is in progress- cache and reuse cells.
        // This cache will be thrown away once scrolling completes.
        // However if we are scaling scroll positions and sizes, we should also avoid caching.
        // This is because the offset changes slightly as scroll position changes and caching leads to stale values.
        // For more info refer to issue react-virtualized#395
        //
        // If isScrollingOptOut is specified, we always cache cells.
        // For more info refer to issue react-virtualized#1028
        if (
          (isScrollingOptOut || isScrolling) &&
          !horizontalOffsetAdjustment &&
          !verticalOffsetAdjustment
        ) {
          if (!cellCache[key]) {
            cellCache[key] = cellRenderer(cellRendererParams)
          }

          renderedCell = cellCache[key]

          // If the user is no longer scrolling, don't cache cells.
          // This makes dynamic cell content difficult for users and would also lead to a heavier memory footprint.
        } else {
          renderedCell = cellRenderer(cellRendererParams)
        }

        if (renderedCell == null || renderedCell === false) {
          continue
        }

        renderedCells.push(renderedCell)
      }
    }

    return renderedCells
  }

  /**
   * Normalize offset of cells to their index in the table data,
   * excluding total.
   */
  getRenderIndexOffset = (): number => {
    let indexOffset = this.state.expandedCategoriesDepth + 1
    if (this.state.total) {
      indexOffset += 1
    }

    if (this.props.externalColumnFilterInputState) {
      indexOffset += 1
    }

    return indexOffset
  }

  getComponentForCell = (categoryId: string) => {
    if (categoryId === CHECK_ROW_TYPE) {
      return CheckboxCell
    }

    return this.props.categoriesOverride[categoryId]
  }

  possibleAlterationOfCategory = (
    category: IStandardTableCategory,
    item: StandardTableItem
  ) => {
    if (!this.props.modelNameCategoryOptionsOverride) {
      return category
    }

    const overrideOptions = this.props.modelNameCategoryOptionsOverride.get(
      item.getValue(KNOWN_CATEGORY_IDS.MODEL_NAME)
    )

    if (!overrideOptions) {
      return category
    }

    return {
      ...category,
      options: {
        ...category.options,
        ...overrideOptions
      }
    }
  }

  /**
   * Main render method, called for all cells
   */
  renderCell = (props: GridCellProps) => {
    const {key, rowIndex, columnIndex, style} = props

    const category = this.getRenderCategory(columnIndex)
    const headerRowIndex = this.state.expandedCategoriesDepth
    const totalRowIndex =
      headerRowIndex + (this.props.externalColumnFilterInputState ? 2 : 1)

    if (rowIndex < this.state.expandedCategoriesDepth) {
      return this.renderExpandedHeaderCell(props, category)
    }

    if (rowIndex === headerRowIndex) {
      return this.renderHeaderCell(props, category)
    }

    const indexOffset = this.getRenderIndexOffset()
    const offsetRowIndex = rowIndex - indexOffset
    const item = this.state.items[offsetRowIndex]

    if (
      this.props.externalColumnFilterInputState &&
      rowIndex === headerRowIndex + 1
    ) {
      return this.renderColumnFilterCell(props, category)
    }

    if (this.state.items.length === 0) {
      if (
        columnIndex === 0 &&
        // use total row to display text if it exists
        offsetRowIndex === (this.state.total ? -1 : 0)
      ) {
        return (
          <Flex
            fullHeight
            alignCenter
            key={key}
            className={css.cellPadding}
            style={{...style, position: 'relative', width: '100%'}}
          >
            <Text
              style={
                this.props.isCompactMode
                  ? {
                      position: 'absolute',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '90%'
                    }
                  : {
                      position: 'absolute'
                    }
              }
            >
              {this.props.loading ? (
                <Translation>{(t, {i18n}) => t('Loading...')}</Translation>
              ) : (
                this.props.noResultsText || (
                  <Translation>{(t, {i18n}) => t('No results.')}</Translation>
                )
              )}
            </Text>
          </Flex>
        )
      }

      return <div key={key} style={style} />
    }

    const isTotalRow = this.state.total && rowIndex === totalRowIndex

    if (this.reorderable() && columnIndex === 0 && !isTotalRow) {
      return (
        <DragCell
          key={key}
          style={style}
          rowIndex={rowIndex}
          rowNumber={offsetRowIndex + 1}
          columnIndex={columnIndex}
          item={item}
          hoverRow={
            !this.props.singleCellEditor && this.state.hoverRow === rowIndex
          }
          rowStyleState={this.state.rowsIndexesInStyleState.get(rowIndex)}
          dropTarget={this.state.dropTargetRow === rowIndex}
          dragActive={this.state.dragSourceRow !== null}
          onMouseEnter={this.handleCellMouseEnter}
          onBeginDrag={this.handleBeginDrag}
          onEndDrag={this.handleEndDrag}
          onDragHover={this.handleDragHover}
          onDrop={this.handleDrop}
        />
      )
    }

    if (!category) {
      // Filler Cell
      return (
        <DataCell
          key={key}
          style={style}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          item={isTotalRow ? this.state.total : item}
          category={null}
          hoverRow={
            !this.props.singleCellEditor && this.state.hoverRow === rowIndex
          }
          hoverColumn={false}
          rowStyleState={this.state.rowsIndexesInStyleState.get(rowIndex)}
          selectable={this.selectable()}
          resizingColumn={false}
          totalRow={isTotalRow}
          dragSource={this.state.dragSourceRow === rowIndex}
          dropTarget={this.state.dropTargetRow === rowIndex}
          disableCheckedRows={this.props.disableCheckedRows}
          checkRowOnCellClick={this.props.checkRowOnCellClick}
          onDragHover={this.handleDragHover}
          onDrop={this.handleDrop}
          onCheck={this.handleCheck}
          onMouseEnter={this.handleCellMouseEnter}
          onClick={this.handleCellClick}
          getFormatter={this.props.getFormatter}
          isCompactMode={this.props.isCompactMode}
        />
      )
    }

    if (isTotalRow) {
      // Total cell
      return (
        <DataCell
          totalRow
          key={key}
          style={style}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          item={this.state.total}
          category={category}
          categories={this.props.table.categories}
          component={this.props.categoriesTotalOverride[category.id]}
          hoverRow={
            !this.props.singleCellEditor && this.state.hoverRow === rowIndex
          }
          hoverColumn={this.state.hoverColumn === columnIndex}
          rowStyleState={this.state.rowsIndexesInStyleState.get(rowIndex)}
          selectable={this.selectable() || !!this.props.onTotalRowClick}
          dragSource={false}
          dropTarget={this.state.dropTargetRow === rowIndex}
          resizingColumn={this.state.resizingColumn === columnIndex}
          onDragHover={this.handleDragHover}
          onDrop={this.handleDrop}
          onMouseEnter={this.handleCellMouseEnter}
          onMouseLeave={this.handleCellMouseLeave}
          onTotalRowClick={this.props.onTotalRowClick}
          expandableRows={this.state.expandable}
          expandAllDisabled={this.props.disableExpandAll}
          onExpandAllRows={this.handleExpandAllRows}
          onCollapseAllRows={this.handleCollapseAllRows}
          tableIsFullyExpanded={this.state.tableIsFullyExpanded}
          getCellValue={this.props.getCellValue}
          getCellError={this.props.getCellError}
          getFormatter={this.props.getFormatter}
          isCompactMode={this.props.isCompactMode}
        />
      )
    }

    if (!item.item) {
      // Unloaded items
      return (
        <DataCell
          key={key}
          style={style}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          item={item}
          category={null}
          hoverRow={
            !this.props.singleCellEditor && this.state.hoverRow === rowIndex
          }
          hoverColumn={false}
          rowStyleState={null}
          selectable={false}
          resizingColumn={false}
          totalRow={false}
          dragSource={false}
          dropTarget={false}
          onDragHover={null}
          onDrop={null}
          onMouseEnter={this.handleCellMouseEnter}
          onClick={this.handleCellClick}
          getFormatter={this.props.getFormatter}
          isCompactMode={this.props.isCompactMode}
        />
      )
    }

    return (
      <DataCell
        key={key}
        style={style}
        actions={this.props.actions}
        categories={this.props.table.categories}
        category={this.possibleAlterationOfCategory(category, item)}
        checked={this.props.checkedRows.get(item.id)}
        activeEditingCellLocation={this.state.activeEditingCellLocation}
        setActiveEditingCellLocation={
          this.props.singleCellEditor && this.handleSetActiveEditingCellLocation
        }
        checkRowOnCellClick={this.props.checkRowOnCellClick}
        columnIndex={columnIndex}
        component={this.getComponentForCell(category.id)}
        debounceDelayTime={this.props.debounceDelayTime}
        dragSource={this.state.dragSourceRow === rowIndex}
        dropTarget={this.state.dropTargetRow === rowIndex}
        expandableRows={this.state.expandable}
        expandAllDisabled={this.props.disableExpandAll}
        findEditableColumnIndexes={this.findEditableColumnIndexes}
        hoverColumn={this.state.hoverColumn === columnIndex}
        hoverRow={
          !this.props.singleCellEditor && this.state.hoverRow === rowIndex
        }
        item={item}
        nameAdornment={this.props.nameAdornment}
        adornmentTooltip={this.props.adornmentTooltip}
        disableCheckedRows={this.props.disableCheckedRows}
        onChange={this.handleCellChange}
        onCheck={this.handleCheck}
        onClick={this.handleCellClick}
        onCollapseAllClick={this.handleRowCollapseAllClick}
        onDragHover={this.handleDragHover}
        onDrop={this.handleDrop}
        onExpandAllClick={this.handleRowExpandAllClick}
        onExpandClick={this.handleRowExpandClick}
        onMouseEnter={this.handleCellMouseEnter}
        resizingColumn={this.state.resizingColumn === columnIndex}
        rowIndex={rowIndex}
        scrollTo={this.scrollTo}
        selectable={this.selectable()}
        rowStyleState={this.state.rowsIndexesInStyleState.get(rowIndex)}
        showIcon={this.props.showIcons}
        singleSelect={this.props.singleSelect}
        colourCallout={this.props.colourCallout}
        allowZeroForCell={this.props.allowZeroForCell}
        getCellValue={this.props.getCellValue}
        getCellError={this.props.getCellError}
        getFormatter={this.props.getFormatter}
        externalColumnFilterInputState={
          this.props.externalColumnFilterInputState
        }
        linkOverride={this.props.linkOverride}
        isCompactMode={this.props.isCompactMode}
      />
    )
  }

  measureCellWidth = ({
    rowIndex,
    columnIndex
  }: {
    rowIndex: number
    columnIndex: number
  }): Promise<number> => {
    const category = this.getRenderCategory(columnIndex)
    const indexOffset = this.state.expandedCategoriesDepth + 1
    const offsetRowIndex = rowIndex - indexOffset

    const style = {
      position: 'absolute' as any,
      top: 0,
      left: 0,
      width: 'auto',
      height: this.props.rowHeight
    }

    const renderNode = (
      <DataCell
        renderStatic
        key={`${columnIndex}-${rowIndex}`}
        style={style}
        actions={this.props.actions}
        categories={this.props.table.categories}
        category={category}
        columnIndex={columnIndex}
        component={this.props.categoriesOverride[category.id]}
        dragSource={false}
        dropTarget={false}
        hoverColumn={false}
        hoverRow={false}
        item={this.state.items[offsetRowIndex]}
        nameAdornment={this.props.nameAdornment}
        adornmentTooltip={this.props.adornmentTooltip}
        onClick={null}
        onDragHover={null}
        onDrop={null}
        onExpandClick={null}
        onMouseEnter={null}
        resizingColumn={false}
        rowIndex={rowIndex}
        selectable={false}
        rowStyleState={null}
        getFormatter={this.props.getFormatter}
        linkOverride={this.props.linkOverride}
        isCompactMode={this.props.isCompactMode}
      />
    )

    return getRenderedWidth(renderNode)
  }

  measureColumnWidth = async (columnIndex: number) => {
    const rowIndexStart = this.state.expandedCategoriesDepth + 1
    const rowIndexEnd = this.getItemsRowCount() + 1 - rowIndexStart

    const measurements = []
    for (let i = rowIndexStart; i <= rowIndexEnd; i++) {
      measurements.push(
        this.measureCellWidth({
          columnIndex,
          rowIndex: i
        })
      )
    }

    const widths = await Promise.all(measurements)

    return Math.max(...widths) + 1
  }

  calculateColumnWidth = ({index}) => {
    if (index === 0 && this.reorderable()) {
      return this.props.reorderableColumnWidth
    }

    const category = this.getRenderCategory(index)

    if (!category) {
      // is the table height less then maxHeight
      let verticalScrollbarSize = 0
      if (getScrollbarWidth() !== 0) {
        const height = this.getTableHeight(
          this.getItemsRowCount(),
          this.getBenchmarksSubrowCount()
        )
        if (height === this.props.maxHeight) {
          verticalScrollbarSize = getScrollbarWidth()
        }
      }

      return (
        this.props.containerWidth -
        (this.state.totalWidth + verticalScrollbarSize)
      )
    }

    return category.width
  }

  getExpandedHeaderHeight = (index: number) => {
    if (typeof this.props.expandedHeaderHeight === 'function') {
      return this.props.expandedHeaderHeight({index})
    }

    if (typeof this.props.expandedHeaderHeight === 'number') {
      return this.props.expandedHeaderHeight
    }

    const hasCategoriesWithSubName = this.state.renderCategories.some(
      (category) => !!category?.parent?.subName
    )

    return hasCategoriesWithSubName
      ? DEFAULT_EXPANDED_HEADER_HEIGHT * 2
      : DEFAULT_EXPANDED_HEADER_HEIGHT
  }

  calculateRowHeight = ({index}) => {
    const offsetIndex = index - this.getRenderIndexOffset()
    if (this.state.items[offsetIndex]?.benchmarks) {
      return (
        this.props.rowHeight *
        (1 + this.state.items[offsetIndex].benchmarks.length)
      )
    }

    if (index < this.state.expandedCategoriesDepth) {
      return this.getExpandedHeaderHeight(index)
    }

    if (index === this.state.expandedCategoriesDepth) {
      return this.props.headerHeight
    }

    return this.props.rowHeight
  }

  renderEmptyState() {
    if (typeof this.props.emptyStateElement === 'string') {
      return (
        <>
          <Spacer xxs />
          <P data-empty style={{whiteSpace: 'nowrap'}}>
            {this.props.emptyStateElement}
          </P>
        </>
      )
    }

    return this.props.emptyStateElement
  }

  /**
   * Returns the number of item rows in the table.
   */
  getItemsRowCount = (): number => {
    if (this.props.rowsCount) {
      return this.props.rowsCount
    }

    let count = this.state.items.length || 1
    if (this.state.total) {
      count += 1
    }

    if (this.props.externalColumnFilterInputState) {
      count += 1
    }

    return count
  }

  /**
   * Similar to `getItemsRowCount` but returns the number of
   * benchmark sub rows in the table's items.
   *
   * A sub row is contained within a row, it increases the row's height.
   * A sub row impacts the table's height, and its parent row's height.
   * It does not increase or decrease the count of "real rows"
   */
  getBenchmarksSubrowCount = () => {
    let count = 0
    for (const item of this.state.items) {
      if (item.benchmarks) {
        count += item.benchmarks.length
      }
    }
    return count
  }

  /**
   * Get row count, taking into account headers
   *   - normal header
   *   - expansion headers
   *   - filter header
   *
   * DOES NOT include benchmark "sub rows" they are considered part
   * of another row whose height is increased appropriately.
   */
  getTableRowCount = (itemsRowCount: number): number => {
    return (
      itemsRowCount +
      1 + // header
      this.state.expandedCategoriesDepth // expanded headers
    )
  }

  getTableColumnCount = (): number => {
    let columnCount = this.state.renderCategories.length

    if (this.reorderable()) {
      columnCount += 1
    }

    if (this.state.totalWidth < this.props.containerWidth) {
      columnCount += 1
    }

    return columnCount
  }

  /**
   * Get pixel height of table taking into account scrollbars and headers
   */
  getTableHeight = (
    itemRowsCount: number,
    benchmarkRowCount: number
  ): number => {
    /*
     * Minimum height is caluculated as:
     *   Count of (item rows + benchmark sub-rows) * row height
     *   + height of expanded column headers
     *   + height of header
     *   + buffer for scrollbar
     *   + 1 for additional border
     */
    const expandedHeight = (() => {
      if (typeof this.props.expandedHeaderHeight === 'function') {
        let height = 0
        for (let i = 0; i < this.state.expandedCategoriesDepth; i++) {
          height += this.props.expandedHeaderHeight({index: i})
        }

        return height
      }

      if (typeof this.props.expandedHeaderHeight === 'number') {
        return (
          this.state.expandedCategoriesDepth * this.props.expandedHeaderHeight
        )
      }

      const hasCategoriesWithSubName = this.state.renderCategories.some(
        (category) => !!category?.parent?.subName
      )

      const expandedHeaderHeight = hasCategoriesWithSubName
        ? DEFAULT_EXPANDED_HEADER_HEIGHT * 2
        : DEFAULT_EXPANDED_HEADER_HEIGHT

      return this.state.expandedCategoriesDepth * expandedHeaderHeight
    })()

    const computedHeight =
      this.props.rowHeight * (itemRowsCount + benchmarkRowCount) +
      expandedHeight +
      this.props.headerHeight +
      getScrollbarWidth() +
      1

    return Math.min(computedHeight, this.props.maxHeight || computedHeight)
  }

  getFixedColumnCount = (): number => {
    if (
      this.props.headerHeight === 0 &&
      this.state.totalWidth <= this.props.containerWidth
    ) {
      return 0
    }

    let count = this.props.anchoredColumns ?? 1

    if (this.reorderable()) {
      count += 1
    }

    if (
      this.state.renderCategories[0] &&
      (this.state.renderCategories[0].id === CHECK_ROW_TYPE ||
        this.state.renderCategories[0].id === APPLY_COLUMN_FILTER_BUTTON_COLUMN)
    ) {
      count += 1
    }

    return count
  }

  getFixedRowCount = (): number => {
    let fixedRowCount = 1 + this.state.expandedCategoriesDepth

    if (this.state.total) {
      fixedRowCount += 1
    }

    if (this.props.externalColumnFilterInputState) {
      fixedRowCount += 1
    }

    return fixedRowCount
  }

  wantToPrint = (html: HTMLElement) => {
    // Used to make sure resources such as logo load before print preview
    // https://stackoverflow.com/questions/31725373/google-chrome-not-showing-image-in-print-preview
    printHtmlElement.printElement(html)
    this.setState({print: false})
  }

  printComponent = () => {
    this.setState({print: true})
  }

  /**
   * Calls `props.onEndReached` when we have reached the bottom of the table
   */
  maybeCallOnEndReached = (indexBox: IndexBox) => {
    if (typeof this.props.onEndReached !== 'function') {
      return
    }

    const endIndex = this.getItemsRowCount() - 1
    if (
      indexBox.rowStopIndex >=
      endIndex - (this.props.onEndReachedRowCountThreshold + 1)
    ) {
      this.props.onEndReached()
    }
  }

  /**
   * In MultiGrid, this method is called whenever the "main" (bottom-right) grid
   * renders a range of cells, the indexes are based on this grid, not the
   * entire table
   */
  handleSectionRendered = (indexBox: IndexBox) => {
    this.maybeCallOnEndReached(indexBox)
    this.setState({lastRenderedSection: indexBox})
    this.updateItemsRendered(indexBox, this.state.itemPageIds)
  }

  updateItemsRendered = (
    indexBox: IndexBox,
    itemPageIdsByChartIndex: IItemPageId[]
  ) => {
    if (
      typeof this.props.onItemsRendered === 'function' &&
      itemPageIdsByChartIndex.length
    ) {
      this.props.onItemsRendered(
        range(
          indexBox.rowOverscanStartIndex,
          indexBox.rowOverscanStopIndex + 1
        ).map((index) => itemPageIdsByChartIndex[index])
      )
    }
  }

  /**
   * Get the width of the left grid
   * @remarks TODO: cache value and invalidate when input properties change
   */
  getLeftGridWidth = () => {
    const fixedColumnCount = this.getFixedColumnCount()
    if (fixedColumnCount === 0) {
      return this.props.containerWidth
    }

    let leftGridWidth = 0
    for (let i = 0; i < fixedColumnCount; i++) {
      leftGridWidth += this.calculateColumnWidth({index: i})
    }

    return leftGridWidth
  }

  /**
   * Get the width of the right grid
   */
  getRightGridWith = () => {
    return this.props.containerWidth - this.getLeftGridWidth()
  }

  getTotalExpandedCellHeight = () => {
    return Array(this.state.expandedCategoriesDepth)
      .fill(null)
      .reduce((prev, _, index) => prev + this.getExpandedHeaderHeight(index), 0)
  }

  render() {
    if (!this.props.table) {
      return null
    }

    if (this.props.table.categories.length === 0) {
      return (
        <div style={{width: this.props.containerWidth}}>
          {this.renderEmptyState()}
        </div>
      )
    }

    const itemsRowCount = this.getItemsRowCount()
    const height = this.getTableHeight(
      itemsRowCount,
      this.getBenchmarksSubrowCount()
    )

    const rowCount = this.getTableRowCount(itemsRowCount)
    const columnCount = this.getTableColumnCount()

    return (
      <div
        data-container-grid
        onMouseLeave={this.handleGridMouseLeave}
        ref={this.setContainerRef}
        style={{position: 'relative', paddingTop: 4}}
      >
        {!this.props.disableColumnFilter && (
          <PopoutColumnFilterToggle
            columnFilterVisible={this.state.toggleFilterVisible}
            columnFilterActive={!!this.props.externalColumnFilterInputState}
            tableHeaderHeight={
              this.getTotalExpandedCellHeight() + this.props.headerHeight
            }
            toggleColumnFilterActive={
              this.props.externalToggleColumnFilterActive
            }
            onMouseEnter={this.handleOpenColumnFilterToggle}
            onMouseLeave={this.handleHideColumnFilterToggle}
          />
        )}
        <MultiGrid
          enableFixedColumnScroll
          enableFixedRowScroll
          hideTopRightGridScrollbar
          hideBottomLeftGridScrollbar
          className={css.multiGrid}
          classNameBottomRightGrid={css.noOutline}
          classNameBottomLeftGrid={css.hideScrollBar}
          classNameTopRightGrid={classNames(
            css.hideScrollBar,
            CLASS_NAME_TOP_RIGHT_GRID
          )}
          ref={this.setMultiGridRef}
          cellRenderer={this.renderCell}
          cellRangeRenderer={this.cellRangeRenderer}
          columnCount={columnCount}
          columnWidth={this.calculateColumnWidth}
          estimatedColumnSize={this.props.defaultColumnWidth}
          rowCount={rowCount}
          rowHeight={this.calculateRowHeight}
          width={this.props.containerWidth}
          height={height}
          fixedRowCount={this.getFixedRowCount()}
          fixedColumnCount={this.getFixedColumnCount()}
          onSectionRendered={this.handleSectionRendered}
          // Additional props passed to trigger
          // rendering when these values change
          hideCheckAll={this.props.hideCheckAll}
          renderCategories={this.state.renderCategories}
          hoverRow={!this.props.singleCellEditor && this.state.hoverRow}
          hoverColumn={!this.props.singleCellEditor && this.state.hoverColumn}
          rowsIndexesInStyleState={this.state.rowsIndexesInStyleState}
          expandedItems={this.state.expandedItems}
          singleCellEditActive={this.state.activeEditingCellLocation}
          dragSource={this.state.dragSourceRow}
          dropTarget={this.state.dropTargetRow}
          horizontalScrollbarSize={this.state.horizontalScrollbarSize}
          verticalScrollbarSize={this.state.verticalScrollbarSize}
          totalWidth={this.state.totalWidth}
          resizingColumn={this.state.resizingColumn}
          checkedRows={this.props.checkedRows}
          checkedAllRows={this.getTableCheckedStatus()}
          columnFilterInputState={this.props.externalColumnFilterInputState}
          onApplyIntermediateColumnFilterState={
            this.props.onApplyIntermediateColumnFilterState
          }
          getCellValue={this.props.getCellValue}
          sortedColumn={this.props.sortedColumn}
          actions={this.props.actions}
        />
        {itemsRowCount === 0 && this.renderEmptyState()}
        {this.state.print && (
          <div className={css.hidden}>
            <PrintableTable
              rowCount={rowCount}
              columnCount={columnCount}
              renderCell={this.renderCell}
              header={this.props.printVersion.header}
              footer={this.props.printVersion.footer}
              didMount={this.wantToPrint}
            />
          </div>
        )}
      </div>
    )
  }
}

const StandardTableWrapper: React.FC<IStandardTablePublicProps> = ({
  containerWidth,
  maxHeight,
  forwardRef,
  onSortClick: externalOnSortClick,
  sortedColumn: externalSortedColumn,
  table: chartTableOrStandardResponse,
  initializeColumnsFullyExpanded,
  defaultColumnWidthTypes,
  pagePeriodMetrics,
  externalSetColumnFilterInputStateAtKey,
  externalColumnFilterInputState,
  externalToggleColumnFilterActive,
  externalOverwriteColumnFilterInputState,
  ...props
}) => {
  const [userProfile] = useUserProfile()
  const isCompactMode = userProfile.profile?.isCompactMode

  const table =
    chartTableOrStandardResponse instanceof StandardResponse
      ? chartTableOrStandardResponse
      : new StandardResponse(chartTableOrStandardResponse)

  const externallyControlledSorting = typeof externalOnSortClick === 'function'
  const internalSortControl = useTableSortControl(
    externallyControlledSorting ? null : props.id
  )
  const [sortedColumn, updateSorting]: ReturnType<typeof useTableSortControl> =
    externallyControlledSorting
      ? [externalSortedColumn, externalOnSortClick]
      : internalSortControl

  const sortedTable = useMemo(() => {
    // When sorting is controlled externally, do not update the table
    if (
      !table ||
      externallyControlledSorting ||
      !sortedColumn ||
      !isSortable(props)
    ) {
      return table
    }

    return produce(table, (draftTable) =>
      draftTable.applySortTableItems(
        sortedColumn.categoryId,
        sortedColumn.sortOrder
      )
    )
  }, [
    table?.toChartTable().items,
    table?.toChartTable().categories,
    externallyControlledSorting,
    sortedColumn
  ])

  const externallyControlledColumnFilter =
    typeof externalToggleColumnFilterActive === 'function'

  /**
   * View key generated by joining category ids to reset column filters
   * Converts to a set and back to array to remove duplicates.
   */
  const viewKey = [
    ...new Set(sortedTable.categories.map((category) => category.id))
  ].join('_')

  const {
    columnFilterState: columnFilterStateRecoil,
    setColumnFilterStateAtKey,
    showColumnFilter: showRecoilColumnFilter,
    toggleShowColumnFilter: toggleShowColumnFilterRecoil,
    overwriteColumnFilterState: overwriteColumnFilterStateRecoil
  } = useTableColumnFilterControlRecoil(props.id, viewKey)

  const {
    columnFilterState: columnFilterStateLocal,
    setColumnFilterState: setColumnFilterStateLocal,
    showColumnFilter: showLocalColumnFilter,
    toggleShowColumnFilter: toggleShowColumnFilterLocal,
    overwriteColumnFilterState: overwriteColumnFilterStateLocal
  } = useTableColumnFilterControl(viewKey)

  const [
    columnFilterState,
    setColumnFilterState,
    showColumnFilter,
    toggleColumnFilterActive
  ] = (() => {
    if (externallyControlledColumnFilter) {
      return [
        externalColumnFilterInputState,
        externalSetColumnFilterInputStateAtKey,
        !!externalColumnFilterInputState,
        externalToggleColumnFilterActive
      ]
    }

    if (props.id) {
      return [
        columnFilterStateRecoil,
        setColumnFilterStateAtKey,
        showRecoilColumnFilter,
        toggleShowColumnFilterRecoil
      ]
    }

    return [
      columnFilterStateLocal,
      setColumnFilterStateLocal,
      showLocalColumnFilter,
      toggleShowColumnFilterLocal
    ]
  })()

  const [intermediateColumnFilterState, setIntermediateColumnFilterState] =
    useIntermediateColumnFilterControl(columnFilterState || EMPTY_OBJECT)

  const handleApplyIntermediateColumnFilter = useCallback(() => {
    if (externallyControlledColumnFilter) {
      externalOverwriteColumnFilterInputState(intermediateColumnFilterState)

      return
    }

    if (props.id) {
      overwriteColumnFilterStateRecoil(intermediateColumnFilterState)

      return
    }

    overwriteColumnFilterStateLocal(intermediateColumnFilterState)
  }, [intermediateColumnFilterState])

  const debouncedColumnFilterState = useDebouncedValue(
    columnFilterState,
    props.debounceDelayTime
  )

  const sortedAndColumnFilteredTable = useMemo(() => {
    if (
      !sortedTable ||
      externallyControlledColumnFilter ||
      !showColumnFilter ||
      !columnFilterState ||
      props.disableColumnFilter
    ) {
      return sortedTable
    }

    return sortedTable.columnFilter(debouncedColumnFilterState)
  }, [
    sortedTable,
    externallyControlledColumnFilter,
    showColumnFilter,
    columnFilterState,
    props.disableColumnFilter,
    debouncedColumnFilterState
  ])

  const setRecoilTable = useSetRecoilState(standardTableAtomFamily(props.id))
  // Saves the SR to Recoil atom family, using the StandardTable's ID as key
  useEffect(() => {
    if (props.id) {
      setRecoilTable(sortedAndColumnFilteredTable)
    }
  }, [sortedAndColumnFilteredTable, props.id])

  useEffect(() => {
    // Cleanup
    return () => {
      if (props.id) {
        setRecoilTable(null)
      }
    }
  }, [props.id])

  useEffect(() => {
    if (
      props.autoSelectFirstItem &&
      props.singleSelect &&
      props.checkedRows &&
      !props.checkedRows[0] &&
      sortedAndColumnFilteredTable.itemAtIndex(0)
    ) {
      const firstItemId = sortedAndColumnFilteredTable.itemAtIndex(0).id
      if (firstItemId && props.onCheckboxedRows) {
        props.onCheckboxedRows([firstItemId])
      }
    }
  }, [
    sortedAndColumnFilteredTable,
    props.autoSelectFirstItem,
    props.singleSelect,
    props.checkedRows
  ])

  const columnWidthControl = useInternalTableColumnWidthControl(
    props.id,
    pagePeriodMetrics
  )
  const {expandedCategories, toggleExpandedCategory} =
    useColumnExpansionControl(
      props.id,
      sortedAndColumnFilteredTable && sortedAndColumnFilteredTable.categories,
      initializeColumnsFullyExpanded
    )

  const mergedDefaultColumnWidthTypes = useMemo(() => {
    return {
      ...DEFAULT_COLUMN_WIDTH_TYPES,
      ...defaultColumnWidthTypes
    }
  }, [defaultColumnWidthTypes])

  const getFormatter = useChartValueCachedFormatter()

  // Wait for column expansion loaded from global settings
  if (!expandedCategories) {
    return null
  }

  // Wait for sorting loaded from global settings if sorting is controlled internally
  if (!externallyControlledSorting && !sortedColumn) {
    return null
  }

  // Wait for column widths loaded from global settings
  if (!columnWidthControl.columnWidths) {
    return null
  }

  const intermediateOrTrueColumnFilterInputState = (() => {
    if (props.disableColumnFilter || !showColumnFilter) {
      return
    }

    if (props.applyColumnFilterButton || props.applyButtonBasedColumnFilter) {
      return intermediateColumnFilterState as IColumnFilterState
    }

    return columnFilterState
  })()

  // Checks if there is a difference between currently applied column filter and
  // the current one shown in the input fields.
  const enableApplyButton = Object.keys(intermediateColumnFilterState).some(
    (key) => {
      if (isEqual(intermediateColumnFilterState[key], columnFilterState[key])) {
        return false
      }

      if (
        isEmpty(intermediateColumnFilterState[key]) &&
        isEmpty(columnFilterState[key])
      ) {
        return false
      }

      return true
    }
  )

  return (
    <AutoSizer disableHeight={maxHeight > 0}>
      {({height, width}) => (
        <>
          {props.updatingItemIds && (
            <LinearProgress style={{width, position: 'absolute'}} />
          )}
          <TableCheckedControl
            table={sortedAndColumnFilteredTable.toChartTable()}
            checkedRows={props.checkedRows}
            onCheckboxedRows={props.onCheckboxedRows}
          >
            {(checkedRows) => (
              <StandardTableComponent
                ref={forwardRef}
                maxHeight={maxHeight || height}
                containerWidth={containerWidth || width}
                table={sortedAndColumnFilteredTable}
                sortedColumn={sortedColumn || sortedColumn}
                onSortClick={updateSorting}
                columnWidths={columnWidthControl.columnWidths}
                onColumnWidthsChange={columnWidthControl.updateColumnWidths}
                commitColumnWidthChanges={columnWidthControl.commitColumnWidths}
                defaultColumnWidthTypes={mergedDefaultColumnWidthTypes}
                // Repeating comment above about why the cast was needed.
                // Need to cast types as for some reason typescript is inferring `columnFilterState`
                // as true | ((nextState?: any) => any) | IColumnFilterState | ((key: string, enumerator: boolean) => (eventOrValue: any[] | ChangeEvent<HTMLInputElement>) => void)' is not assignable to parameter of type 'Dictionary<string | any[]>'
                // Even though when you hover over `columnFilterState` it states it is of type `IColumnFilterState`
                // I believe it has something to do with tuple type inferences.
                // Tried casting above at creation or adding typing but it just meant more casting was necessary.
                // So felt this way would be the least verbose.
                externalColumnFilterInputState={
                  intermediateOrTrueColumnFilterInputState
                }
                externalSetColumnFilterInputStateAtKey={
                  props.applyColumnFilterButton ||
                  props.applyButtonBasedColumnFilter
                    ? setIntermediateColumnFilterState
                    : setColumnFilterState
                }
                externalToggleColumnFilterActive={
                  toggleColumnFilterActive as () => void
                }
                onApplyIntermediateColumnFilterState={
                  enableApplyButton && handleApplyIntermediateColumnFilter
                }
                {...props}
                checkedRows={checkedRows}
                expandedCategories={expandedCategories}
                toggleExpandedCategory={toggleExpandedCategory}
                getFormatter={getFormatter}
                rowHeight={isCompactMode ? 24 : props.rowHeight}
                headerHeight={isCompactMode ? 24 : props.headerHeight}
                isCompactMode={isCompactMode}
              />
            )}
          </TableCheckedControl>
        </>
      )}
    </AutoSizer>
  )
}

StandardTableWrapper.defaultProps = {
  rowHeight: 36,
  headerHeight: 36,
  defaultColumnWidth: 140,
  defaultNameColumnWidth: 300,
  reorderableColumnWidth: 32,
  checkedColumnWidth: 40,
  applyColumnFilterColumnWidth: 40,
  minColumnWidth: 60,
  maxColumnWidth: 600,
  hideTotalRow: false,
  emptyStateElement: 'Table is Empty',
  categoriesOverride: {},
  categoriesTotalOverride: {},
  disableSorting: false,
  onEndReachedRowCountThreshold: 0,
  debounceDelayTime: 500
}

export const StandardTable = React.forwardRef<
  StandardTableComponent,
  Omit<IStandardTablePublicProps, 'forwardRef'>
>((props, ref) => (
  <>
    <ErrorBoundary resetId={props.id}>
      <StandardTableWrapper {...props} forwardRef={ref} />
    </ErrorBoundary>
  </>
))
