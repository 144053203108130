import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {useApi} from 'fairlight'
import produce from 'immer'
import {isEmpty, isNil} from 'lodash'
import {useRecoilCallback, useRecoilState} from 'recoil'
import useAsyncCall from 'use-async-call'

import DownloadIcon from '@material-ui/icons/CloudDownload'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  AccountEndpoints,
  AccountGroupEndpoints,
  InstrumentEndpoints,
  PortfolioRebalanceEndpoints,
  TradingApprovalsEndpoints
} from '@d1g1t/api/endpoints'
import {
  ALL_MODELS,
  IAccountGroup,
  ITradingApprovalsAccountData,
  ITradingApprovalsChangedValue,
  ITradingApprovalsInstrumentInput,
  UI_PERMISSIONS
} from '@d1g1t/api/models'

import {useToggleState} from '@d1g1t/lib/hooks'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {Card} from '@d1g1t/shared/components/card'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from '@d1g1t/shared/components/expansion-panel'
import {H2} from '@d1g1t/shared/components/typography'
import {useSelectedEntities} from '@d1g1t/shared/containers/select-entities'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {downloadBase64} from '@d1g1t/shared/wrappers/download-base-64'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {withPermissions} from '@d1g1t/shared/wrappers/permissions'

import {Page} from '@d1g1t/advisor/components/page'
import {PageContent} from '@d1g1t/advisor/components/page-content'
import {PageTitleBar} from '@d1g1t/advisor/containers/page-title-bar'
import {RecommendedTrades} from '@d1g1t/advisor/containers/recommended-trades'
import {SelectEntities} from '@d1g1t/advisor/containers/select-entities'
import {RELATED_COMPONENT} from '@d1g1t/advisor/containers/trade-preview'

import {
  checkedAccountsIdsAtom,
  tradeDirectiveApprovalReportSelector
} from './atoms'
import {AccountAllocation} from './components/account-allocation'
import {ExpansionPanelSummaryChips} from './components/expansion-panel-summary-chips'
import {
  ITargetAllocationProps,
  TargetAllocation
} from './components/target-allocation'
import {useChangeAllocation} from './hook'
import {ITradeDirectivePageSelectedClientsProps} from './typings'

/**
 * "Trade Directive" page used to be called "Bulk Change Allocation"
 * so in the codebase, both refer to the same page.
 */
export const TradeDirectivePageContent: React.FC = () => {
  const api = useApi()
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()
  const [calculationSettings] = useCalculationSettings()
  const [checkedAccountsIds] = useRecoilState(checkedAccountsIdsAtom)
  const [recommendTradesClicked, setRecommendTradesClicked] = useState<
    'clicked' | 'loaded'
  >(null)

  const [modelPortfolioSelected, setModelPortfolioSelected] =
    useState<string>(undefined)

  const handleGenerateApprovalReport = useRecoilCallback(
    ({snapshot}) =>
      async (approvalInput: 'account' | 'bulk') => {
        if (isEmpty(checkedAccountsIds)) {
          showSnackbar({variant: 'error', message: 'No accounts present.'})

          return
        }

        const approvalReportTables = await snapshot.getPromise(
          tradeDirectiveApprovalReportSelector
        )

        const targetAllocationItems = approvalReportTables[0].data?.items
        const accountAllocationItems = approvalReportTables[1].data?.items

        const instruments: ITradingApprovalsInstrumentInput[] = []

        const accountsData: ITradingApprovalsAccountData[] = checkedAccountsIds
          .map((checkedAccountId, index) => {
            const targetValues: ITradingApprovalsChangedValue[] = []

            for (const targetAllocationItem of targetAllocationItems) {
              const quantityChange = accountAllocationItems
                .find(
                  (accountAllocationItem) =>
                    accountAllocationItem.id === checkedAccountId
                )
                .getValue(`${targetAllocationItem.id}:quantity_change`)

              if (isNil(quantityChange)) {
                continue
              }

              // Since we are looping through the target allocation items already
              // might as well build the instruments list property for the request body.
              // Just needs to be done once hence the if index === 0.
              if (index === 0) {
                instruments.push({
                  instrument: api.buildUrl(
                    InstrumentEndpoints.pathToResource(targetAllocationItem.id)
                  ),
                  targetWeight: targetAllocationItem.getValue('target_weight'),
                  commission: targetAllocationItem.getValue('commission'),
                  commissionType:
                    targetAllocationItem.getValue('commission_type'),
                  marketPriceOverride:
                    targetAllocationItem.getValue('market_price')
                })
              }

              targetValues.push({
                instrument: api.buildUrl(
                  InstrumentEndpoints.pathToResource(targetAllocationItem.id)
                ),
                quantityChange
              })
            }

            return {
              targetValues,
              account: api.buildUrl(
                AccountEndpoints.pathToResource(checkedAccountId)
              )
            }
          })
          .filter((accountData) =>
            accountData.targetValues.some(
              (targetValue) => targetValue.quantityChange !== 0
            )
          )
        // Above filter is because only accounts with at least one security
        // with nonzero quantity change in the allocation per account table
        // should be sent in the request.

        // do not make api call if there is no instrument
        if (isEmpty(instruments)) {
          showSnackbar({
            variant: 'error',
            message: 'Missing instruments.'
          })
          return
        }

        // do not make api call if target weight of any instrument is missing
        if (
          Object.values(targetAllocationItems).some((item) =>
            isNil(item.getValue('target_weight'))
          )
        ) {
          showSnackbar({
            variant: 'error',
            message: 'Missing the target weight field.'
          })
          return
        }

        if (isEmpty(accountsData)) {
          showSnackbar({
            variant: 'error',
            message: 'No quantity changes present.'
          })

          return
        }

        try {
          const approvalRequestBody = {
            accountsData,
            instruments,
            accounts: accountsData.map((accountData) => accountData.account),
            asOfDate: calculationSettings.date.date,
            modelPortfolio: modelPortfolioSelected
          }
          const snackbarApprovalMessagePrefix =
            approvalInput === 'bulk' ? 'Bulk' : 'Account'
          const approvals = await (approvalInput === 'bulk'
            ? api.request(TradingApprovalsEndpoints.bulk(approvalRequestBody))
            : api.request(
                TradingApprovalsEndpoints.account(approvalRequestBody)
              ))

          for (const approvalFile of approvals.flat()) {
            downloadBase64(approvalFile)
          }

          showSnackbar({
            variant: 'success',
            message: `${snackbarApprovalMessagePrefix} approval file(s) created successfully`
          })
        } catch (error) {
          handleUnexpectedError(
            error,
            'Could not generate account approval file(s).'
          )
        }
      }
  )
  return (
    <>
      <PageTitleBar
        noBottomMargin
        showBackToListNavigationButton
        showSelectEntitiesPageNavigation
        title='Trade Directive'
      />
      {calculationSettings && (
        <SelectEntities
          disableBasicInfo
          disableCalculationSettingsOverride
          EntityChipProps={{
            filterByAccountsOnly: true,
            disableRuleBasedFiltering: true,
            hideInvestmentMandatesSubmenu: true
          }}
          SearchProps={{
            searchBy: [
              ALL_MODELS.INDIVIDUAL,
              ALL_MODELS.HOUSEHOLD,
              ALL_MODELS.CORPORATION,
              ALL_MODELS.FOUNDATION,
              ALL_MODELS.TRUST,
              ALL_MODELS.ACCOUNTGROUP,
              ALL_MODELS.ACCOUNT
            ],
            placeholder: 'Search for clients, accounts and account groups',
            isAccountSpecificNot: true
          }}
          BarProps={{
            stickyTopBar: true
          }}
          overflowMenuOptions={[
            {
              disabled: recommendTradesClicked !== 'loaded',
              icon: <DownloadIcon />,
              label: 'Generate Account Approval Report',
              onClick: () => {
                handleGenerateApprovalReport('account')
              }
            },
            {
              disabled: recommendTradesClicked !== 'loaded',
              icon: <DownloadIcon />,
              label: 'Generate Bulk Approval Report',
              onClick: () => {
                handleGenerateApprovalReport('bulk')
              }
            }
          ]}
        >
          <TradeDirectivePageSelectedClients
            recommendTradesClicked={recommendTradesClicked}
            setRecommendTradesClicked={setRecommendTradesClicked}
            modelPortfolioSelected={modelPortfolioSelected}
            setModelPortfolioSelected={setModelPortfolioSelected}
          />
        </SelectEntities>
      )}
    </>
  )
}

const TradeDirectivePageSelectedClients: React.FC<
  ITradeDirectivePageSelectedClientsProps
> = (props) => {
  const api = useApi()
  const {control} = useSelectedEntities()

  const [
    targetAllocationExpanded,
    toggleTargetAllocationExpanded,
    ,
    collapseTargetAllocation
  ] = useToggleState(true)

  const fetchAccountGroupResults = useCallback(async () => {
    if (!control?.selectedEntities?.accountGroups) {
      return null
    }

    const accountGroups: Promise<IAccountGroup>[] = []
    for (const accountGroupEntityId of control.selectedEntities.accountGroups) {
      accountGroups.push(
        api.request(AccountGroupEndpoints.findById(accountGroupEntityId))
      )
    }

    const accountGroupDetails = await Promise.all(accountGroups)
    return accountGroupDetails
  }, [control])
  const [accountGroupResults] = useAsyncCall(fetchAccountGroupResults)

  /**
   * Expand known account-groups to accounts, ignore the account-group entity.
   * This is necessary because `TradeDirectivePageSelectedClients` sends a list of account to back-end.
   */
  const expandedControl = useMemo(() => {
    if (!accountGroupResults.data || !control) {
      return control
    }

    return produce(control, (draft) => {
      // Expand account-group entity IDs into accounts
      for (const accountGroup of accountGroupResults.data) {
        draft.selectedEntities.accountsOrPositions =
          draft.selectedEntities.accountsOrPositions || []

        draft.selectedEntities.accountsOrPositions.push(
          accountGroup.accounts.map(extractIdFromUrl)
        )
      }
    })
  }, [accountGroupResults.loading, control])

  const [
    {
      accountAllocationChart,
      warningsByAccountId,
      isChangedByAccountId,
      bulkRebalanceParams,
      changes,
      securities,
      checkedSecurityUrls,
      checkedAccountsIds,
      directiveInputsBySecurityEntityId,
      loadingSecuritiesForModelPortfolio,
      selectedModelPortfolio,
      excludingPendingTrades,
      excludingCashEquivalents,
      editedValueErrorsByAccountAndSecurityId
    },
    changeAllocationActions
  ] = useChangeAllocation(expandedControl)

  const handleRecommendTradesChange = useCallback<
    ITargetAllocationProps['onRecommendTrades']
  >((newBulkRebalanceParams) => {
    changeAllocationActions.recommendTrades(newBulkRebalanceParams)
    props.setRecommendTradesClicked('clicked')
    collapseTargetAllocation()
  }, [])

  // If select entities are changed should clear model portfolio selected
  // and reset recommended trades clicked to null
  useEffect(() => {
    if (props.recommendTradesClicked) {
      props.setRecommendTradesClicked(null)
    }

    if (props.modelPortfolioSelected) {
      props.setModelPortfolioSelected(undefined)
    }
  }, [expandedControl])

  useEffect(() => {
    if (
      props.recommendTradesClicked === 'clicked' &&
      !accountAllocationChart.loading
    ) {
      props.setRecommendTradesClicked('loaded')
    }
  }, [accountAllocationChart])

  if (!expandedControl) {
    return null
  }

  return (
    <Page>
      <PageContent
        recommendedTradesBarBottomPadding
        style={{marginTop: '20px'}}
      >
        <ExpansionPanel
          expanded={targetAllocationExpanded}
          onChange={toggleTargetAllocationExpanded}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='target-allocation-content'
            id='target-allocation'
          >
            <H2>Target Allocation</H2>
            <ExpansionPanelSummaryChips
              securities={securities.filter((e) =>
                checkedSecurityUrls.includes(e.url)
              )}
              directiveInputsBySecurityEntityId={
                directiveInputsBySecurityEntityId
              }
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{
              display: 'block',
              paddingBottom: 0
            }}
          >
            <TargetAllocation
              bulkRebalanceParams={bulkRebalanceParams}
              onInitializeTradeParameters={
                changeAllocationActions.recommendTrades
              }
              onRecommendTrades={handleRecommendTradesChange}
              onAddSecurity={changeAllocationActions.addSecurity}
              securities={securities}
              checkedSecurityUrls={checkedSecurityUrls}
              directiveInputsBySecurityEntityId={
                directiveInputsBySecurityEntityId
              }
              loadingSecuritiesForModelPortfolio={
                loadingSecuritiesForModelPortfolio
              }
              selectedModelPortfolio={selectedModelPortfolio}
              loadSecuritiesForPortfolioRequest={
                changeAllocationActions.loadSecuritiesForPortfolioRequest
              }
              loadSecuritiesForPortfolioSuccess={
                changeAllocationActions.loadSecuritiesForPortfolioSuccess
              }
              loadSecuritiesForPortfolioFailure={
                changeAllocationActions.loadSecuritiesForPortfolioFailure
              }
              clearSecurities={() => {
                props.setRecommendTradesClicked(null)
                props.setModelPortfolioSelected(undefined)
                changeAllocationActions.clearSecurities()
              }}
              setDirectiveInputValue={
                changeAllocationActions.setDirectiveInputValue
              }
              setInitialMarketPrices={
                changeAllocationActions.setInitialMarketPrices
              }
              setCheckedSecurityUrls={
                changeAllocationActions.setCheckedSecurityUrls
              }
              setModelPortfolioSelected={props.setModelPortfolioSelected}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <Card flexColumn noMinHeight>
          <AccountAllocation
            chart={accountAllocationChart}
            warningsByAccountId={warningsByAccountId}
            isChangedByAccountId={isChangedByAccountId}
            editedValueErrorsByAccountAndSecurityId={
              editedValueErrorsByAccountAndSecurityId
            }
            onValueChange={
              changeAllocationActions.updateAccountAllocationChartValueRequest
            }
            excludingPendingTrades={excludingPendingTrades}
            excludingCashEquivalents={excludingCashEquivalents}
            includePendingTrades={changeAllocationActions.includePendingTrades}
            excludePendingTrades={changeAllocationActions.excludePendingTrades}
            includeCashEquivalents={
              changeAllocationActions.includeCashEquivalents
            }
            excludeCashEquivalents={
              changeAllocationActions.excludeCashEquivalents
            }
            checkedAccountsIds={checkedAccountsIds}
            onAccountChecked={changeAllocationActions.setCheckedAccountsIds}
          />
        </Card>
      </PageContent>
      <RecommendedTrades
        manualRefreshButton
        relatedComponent={RELATED_COMPONENT.TRADE_DIRECTIVE}
        requestParams={
          changes?.accountsData?.length &&
          PortfolioRebalanceEndpoints.bulkTradePreview(changes)
        }
        directiveInputsBySecurityEntityId={directiveInputsBySecurityEntityId}
      />
    </Page>
  )
}

export const TradeDirectivePage = withPermissions(
  UI_PERMISSIONS.TRADE_CHANGE_ALLOCATION
)(TradeDirectivePageContent)
