import {mapValues, orderBy, take} from 'lodash'

import {PORTFOLIO_TYPE} from '@d1g1t/api/models'

import {ISearchResult} from '@d1g1t/shared/containers/search/typings'

import {MODEL_DISPLAY_GROUP_ORDER} from './constants'

/**
 * Filters out funds
 */
export const filterByModelPortfolio = (result: ISearchResult) =>
  result.portfolioType !== PORTFOLIO_TYPE.FUND

interface IGroupedSearchResult {
  [modelName: string]: ISearchResult[]
}

/**
 * Returns re-ordered MODEL_DISPLAY_GROUP_ORDER with highest search matching group at top
 *
 * @param groupedSearchResults - filtered search results, grouped by modelName property
 */
export const modelDisplayGroupOrderedBySearchScore = (
  groupedSearchResults: IGroupedSearchResult
) => {
  // Assigns a search score to each group based on top n items' average score
  const modelScoresByTopN = mapValues(groupedSearchResults, (values) => {
    const topNItems = take(values, 3)

    return {
      score:
        topNItems.reduce((scoreSum, currentItem) => {
          return scoreSum + currentItem.score
        }, 0) / topNItems.length
    }
  })

  // Orders MODEL_DISPLAY_GROUP_ORDER based on search query match score
  return orderBy(
    MODEL_DISPLAY_GROUP_ORDER,
    (model) => {
      return modelScoresByTopN[model] ? modelScoresByTopN[model].score : 0
    },
    'desc'
  )
}

/**
 * Ranks search results by score and group.
 *
 * Re-orders search results to group by model name, then orders the groups
 * by average score of the top 3 items.
 *
 * @param groupedSearchResults - filtered search results, grouped by modelName property
 */
export const rankedSearchResults = (
  groupedSearchResults: IGroupedSearchResult
): ISearchResult[] => {
  return modelDisplayGroupOrderedBySearchScore(groupedSearchResults)
    .map((modelName) => {
      return groupedSearchResults[modelName]
    })
    .flat()
}
