import React, {useMemo} from 'react'

import {Options, SeriesBarOptions, SeriesLineOptions} from 'highcharts'
import {merge} from 'lodash'

import {BarChart} from '@d1g1t/shared/components/charts/bar-chart'
import {DonutChart} from '@d1g1t/shared/components/charts/donut-chart'
import {SeriesLineDataOptions} from '@d1g1t/shared/components/charts/time-series-chart/lib'

import {withStandardChart} from '../'
import {IChartProps, IStandardChartProps} from '../typings'

const DEFAULT_CONCENTRATION_CONFIG: Options = {
  title: {
    verticalAlign: 'top'
  },
  plotOptions: {
    pie: {
      dataLabels: {
        format: '{point.y:.1f}%'
      }
    },
    bar: {
      centerInCategory: true,
      dataLabels: {
        enabled: true,
        format: '{point.y:.1f}%'
      },
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.1f}%</b><br/>'
      }
    }
  }
}

function hasNegativeData(
  series: (SeriesBarOptions | SeriesLineOptions)[]
): boolean {
  if (series.length !== 1) {
    return false
  }

  const data = series[0].data
  for (const dataPoint of data) {
    if (typeof dataPoint !== 'number' && 'y' in dataPoint && dataPoint.y < 0) {
      return true
    }
  }

  return false
}

// Was removed when Highcharts v8 was released...
interface ISeriesBarDataOptions {
  data: SeriesLineDataOptions
  name: string
}

const ConcentrationStandardChart: React.FC<IChartProps> = React.memo(
  (props) => {
    const config = useMemo(() => {
      if (hasNegativeData(props.series)) {
        const series = props.series[0].data as ISeriesBarDataOptions[]

        return merge(
          {
            series: series.map((data) => ({
              data: [data],
              name: data.name
            })),
            legend: {enabled: true},
            xAxis: {
              type: 'category'
            }
          },
          DEFAULT_CONCENTRATION_CONFIG,
          props.config
        )
      }
      return merge(
        {
          series: props.series
        },
        DEFAULT_CONCENTRATION_CONFIG,
        props.config
      )
    }, [props.series, props.config])

    return hasNegativeData(props.series) ? (
      <BarChart config={config} />
    ) : (
      <DonutChart config={config} />
    )
  }
)

export const ConcentrationChart = withStandardChart(
  ConcentrationStandardChart
) as React.ComponentClass<IStandardChartProps>
