import React, {useContext} from 'react'

import {KeyboardDatePicker} from '@d1g1t/shared/components/mui/keyboard-date-picker'

import {MetricsFormContext} from '../../context'

export const MetricsCustomDate: React.FC = () => {
  const {metricItem, latestAvailableData, onDateRangeChange} =
    useContext(MetricsFormContext)

  const handleDateChange = (date: string) => {
    onDateRangeChange(date, date)
  }

  return (
    <KeyboardDatePicker
      margin='dense'
      value={metricItem.dateRange.startDate}
      onChange={handleDateChange}
      maxDate={latestAvailableData}
    />
  )
}
