import React, {forwardRef} from 'react'

import {capitalize} from 'lodash'

import {alpha, makeStyles} from '@material-ui/core/styles'
import MuiToggleButton, {ToggleButtonProps} from '@material-ui/lab/ToggleButton'

import {classNames} from '@d1g1t/lib/class-names'

import * as css from './styles.scss'

export interface IToggleButtonProps
  extends Omit<ToggleButtonProps, 'classes'> {}

export const ToggleButton: React.FC<IToggleButtonProps> = forwardRef(
  (props, ref) => {
    const classes = {
      root: css.root
    }
    return <MuiToggleButton ref={ref} classes={classes} {...props} />
  }
)

const useSegmentStyles = makeStyles((theme) => {
  return {
    root: {
      ...theme.typography.button,
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      height: 48,
      padding: '0px 12px',
      border: `1px solid ${alpha(theme.palette.action.active, 0.12)}`,
      color: alpha(theme.palette.action.active, 0.38)
    },
    label: {
      width: '100%', // Ensure the correct width for iOS Safari
      display: 'inherit',
      alignItems: 'inherit',
      justifyContent: 'inherit'
    },
    /* Styles applied to the root element if `size="small"`. */
    sizeSmall: {
      height: 40,
      padding: '0px 8px',
      fontSize: theme.typography.pxToRem(13)
    },
    /* Styles applied to the root element if `size="large"`. */
    sizeLarge: {
      height: 56,
      padding: '0px 16px',
      fontSize: theme.typography.pxToRem(15)
    }
  }
})

export interface IToggleSegmentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  ref?: React.Ref<HTMLDivElement>
  /**
   * @defaultValue 'medium'
   */
  size?: 'small' | 'medium' | 'large'
}

/**
 * Use as a child of `<ToggleButtonGroup>` to add non-interactive segments
 * as info, labels, etc.
 */
export const ToggleSegment: React.FC<IToggleSegmentProps> = forwardRef(
  (props, ref) => {
    const {children, className, size, ...other} = props
    const classes = useSegmentStyles(props)

    return (
      <div
        className={classNames(
          'MuiToggleButton-root',
          classes.root,
          {
            [classes[`size${capitalize(size)}`]]: size !== 'medium'
          },
          className
        )}
        ref={ref}
        {...other}
      >
        <span className={classes.label}>{children}</span>
      </div>
    )
  }
)
