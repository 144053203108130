import React, {useContext} from 'react'

import {Checkbox} from '@d1g1t/shared/components/mui/checkbox'
import {FormControlLabel} from '@d1g1t/shared/components/mui/form-control-label'
import {Spacer} from '@d1g1t/shared/components/spacer'

import {MetricsFormContext} from '../../context'
import {ContributionDimension} from './contribution-dimension'
import {supportedSecondaryDimensionsForPrimaryDimension} from './lib'

export const ExpandableMetricColumns: React.FC = () => {
  const {
    expandableColumn,
    metricItem,
    oncontributionDimensionChange,
    onContributionDimension2Change,
    onShowExpandableColumnChange,
    displayTotalWhenExpanded1,
    displayTotalWhenExpanded2,
    onDisplayTotalWhenExpanded1Change,
    onDisplayTotalWhenExpanded2Change,
    onShowConditionResults1Change,
    onShowConditionResults2Change,
    conditionResults1,
    conditionResults2,
    contributionDimensions
  } = useContext(MetricsFormContext)

  const supportedSecondaryContributions =
    supportedSecondaryDimensionsForPrimaryDimension(
      contributionDimensions,
      metricItem.contributionDimension
    )

  return (
    <>
      <Spacer xxs />
      <FormControlLabel
        label='Expand Column'
        control={
          <Checkbox
            checked={expandableColumn}
            onChangeValue={onShowExpandableColumnChange}
          />
        }
      />
      {expandableColumn && (
        <>
          <ContributionDimension
            label='Contribution Dimension'
            contributionDimensionId={metricItem.contributionDimension}
            onContributionDimensionChange={oncontributionDimensionChange}
            options={contributionDimensions.map((option) => ({
              value: option.id,
              label: option.name
            }))}
            displayTotalWhenExpanded={displayTotalWhenExpanded1}
            onDisplayTotalWhenExpandedChanged={
              onDisplayTotalWhenExpanded1Change
            }
            conditionResults={conditionResults1}
            onConditionResultsChanged={onShowConditionResults1Change}
            contributionDimensionKey='contributionDimension'
          />

          {supportedSecondaryContributions.length > 0 && (
            <ContributionDimension
              label='Contribution Dimension 2'
              contributionDimensionId={metricItem.contributionDimension2}
              onContributionDimensionChange={onContributionDimension2Change}
              options={supportedSecondaryContributions.map((option) => ({
                value: option.id,
                label: option.name
              }))}
              displayTotalWhenExpanded={displayTotalWhenExpanded2}
              onDisplayTotalWhenExpandedChanged={
                onDisplayTotalWhenExpanded2Change
              }
              conditionResults={conditionResults2}
              onConditionResultsChanged={onShowConditionResults2Change}
              contributionDimensionKey='contributionDimension2'
            />
          )}
        </>
      )}
    </>
  )
}
