import React, {useMemo} from 'react'

import {Text} from '@d1g1t/shared/components/typography'
import {highlightMatch} from '@d1g1t/shared/containers/search/search-suggestion-item'
import {ISearchResult} from '@d1g1t/shared/containers/search/typings'

import * as css from './style.scss'

interface ISecuritySearchOptionProps {
  option: ISearchResult
  text: string
}

export const SecuritySearchOption: React.FC<ISecuritySearchOptionProps> = (
  props
) => {
  const highlightedMatch = useMemo(() => {
    return (
      <div className={css.row}>
        <div className={css.calculatedSymbol}>
          <Text>
            {highlightMatch(props.option.calculatedSymbol, props.text)}
          </Text>
        </div>
        <div className={css.name}>
          <Text>{highlightMatch(props.option.name, props.text)}</Text>
        </div>
        <div className={css.marketPrice}>
          <Text>
            {props.option.marketPrice
              ? Math.round(parseFloat(props.option.marketPrice) * 100) / 100
              : ''}{' '}
            {props.option.tradingCurrency}
          </Text>
        </div>
        <div className={css.cusip}>
          <Text>{highlightMatch(props.option.cusip, props.text)}</Text>
        </div>
        <div className={css.isin}>
          <Text>{highlightMatch(props.option.isin, props.text)}</Text>
        </div>
      </div>
    )
  }, [props.option, props.text])

  return highlightedMatch
}
