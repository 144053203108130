import {Store} from 'redux'
import promiseMiddleware from 'redux-promise'
import thunkMiddleware from 'redux-thunk'

import {performanceMeasureWrap} from '@d1g1t/lib/performance'
import {createAsyncInjectStore} from '@d1g1t/lib/redux-inject-async'

import {createApi} from '@d1g1t/shared/api'

import {rootSaga} from './sagas'
import {ISagaConext} from './typings'

const initialState = {}

export const api = createApi()

export const createStore = (sagaContext: ISagaConext) => {
  const middlewares = [
    thunkMiddleware.withExtraArgument(sagaContext),
    promiseMiddleware
  ]

  if (__DEVELOPMENT__) {
    const reduxDebugMiddleware = (_: Store<any>) => (next) => (action) => {
      if (window.DEBUG_REDUX) {
        console.group('Redux Debug')
        console.log(action) // eslint-disable-line no-console
        console.groupEnd()
      }

      return next(action)
    }

    const actionErrorLogger = (_: Store<any>) => (next) => (action) => {
      if (action.error) {
        console.group('ActionErrorLogger')
        console.error(action.payload && action.payload.message)
        console.error(action.payload && action.payload.stack)
        console.error(action)
        console.groupEnd()
      }

      return next(action)
    }

    const performanceMarker = (_: Store<any>) => (next) => (action) => {
      let name = action.type
      if (!name) {
        name = 'Thunk Action'
      }
      if (action.meta && action.meta.id) {
        name += ` (${action.meta.id})`
      }
      name = `Reducer: ${name}`

      return performanceMeasureWrap(name, () => next(action))
    }

    middlewares.unshift(reduxDebugMiddleware)
    middlewares.unshift(actionErrorLogger)
    middlewares.unshift(performanceMarker)
  }
  const store = createAsyncInjectStore({}, initialState, middlewares)

  store.runSaga(rootSaga, sagaContext)

  if (process.env.NODE_ENV === 'development') {
    window.store = store
  }
  return store
}
