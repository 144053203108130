import {combineReducers} from 'redux'

import produce from 'immer'
import {ActionType, getType} from 'typesafe-actions'

import {COMMISSION_TYPE} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {changeAllocationActions} from './actions'
import {getInitialTargetWeightForModelPortfolioSecurity} from './lib'
import {
  BulkChartEditableFieldName,
  IAccountAllocationState,
  IDirectiveInputs
} from './typings'

const INITIAL_DIRECTIVE_INPUT_VALUES: IDirectiveInputs = {
  target_weight: null,
  lower_bound: 0,
  upper_bound: 1,
  commission: 0,
  commission_type: COMMISSION_TYPE.AMOUNT
}

export const changeAllocationReducer = combineReducers<
  IAccountAllocationState,
  ActionType<typeof changeAllocationActions>
>({
  securities: (prev = [], action) => {
    switch (action.type) {
      case getType(changeAllocationActions.addSecurity):
        return produce(prev, (draft) => {
          draft.push({
            url: action.payload.url,
            label:
              // symbol is same as (fundCode || tickerShort)
              action.payload.fundCode ||
              action.payload.tickerShort ||
              action.payload.name,
            shouldRecommend: false,
            initialMarketPrice: null
          })
        })
      case getType(changeAllocationActions.setInitialMarketPrices):
        return produce(prev, (draft) => {
          for (const item of draft) {
            item.initialMarketPrice = action.payload[extractIdFromUrl(item.url)]
          }
        })
      case getType(changeAllocationActions.loadSecuritiesForPortfolioSuccess):
        return action.payload.instruments.map((security) => ({
          url: security.url,
          label:
            security.calculatedSymbol || security.nameOverride || security.name,
          shouldRecommend: false,
          initialMarketPrice: security.marketPrice
        }))
      case getType(changeAllocationActions.recommendTrades):
        return produce(prev, (draft) => {
          for (const item of draft) {
            item.shouldRecommend = true
          }
        })
      case getType(changeAllocationActions.clearSecurities):
        return []
      default:
        return prev
    }
  },

  checkedSecurityUrls: (prev = [], action) => {
    switch (action.type) {
      case getType(changeAllocationActions.setCheckedSecurityUrls):
        return action.payload
      case getType(changeAllocationActions.addSecurity):
        return produce(prev, (draft) => {
          draft.push(action.payload.url)
        })
      case getType(changeAllocationActions.loadSecuritiesForPortfolioSuccess):
        return action.payload.instruments.map((security) => security.url)
      case getType(changeAllocationActions.clearSecurities):
        return []
      default:
        return prev
    }
  },

  loadingSecuritiesForModelPortfolio: (prev = false, action) => {
    switch (action.type) {
      case getType(changeAllocationActions.loadSecuritiesForPortfolioRequest):
        return true
      case getType(changeAllocationActions.loadSecuritiesForPortfolioSuccess):
      case getType(changeAllocationActions.loadSecuritiesForPortfolioFailure):
        return false
      default:
        return prev
    }
  },

  directiveInputsBySecurityEntityId: (prev = {}, action) => {
    switch (action.type) {
      case getType(changeAllocationActions.loadSecuritiesForPortfolioSuccess):
        return action.payload.instruments.reduce((prev, instrument) => {
          prev[extractIdFromUrl(instrument.url)] = {
            lower_bound: INITIAL_DIRECTIVE_INPUT_VALUES.lower_bound,
            target_weight: getInitialTargetWeightForModelPortfolioSecurity(
              instrument,
              action.payload.draft
            ),
            market_price_override: INITIAL_DIRECTIVE_INPUT_VALUES.market_price,
            upper_bound: INITIAL_DIRECTIVE_INPUT_VALUES.upper_bound,
            commission:
              instrument.defaultCommission ||
              INITIAL_DIRECTIVE_INPUT_VALUES.commission,
            commission_type:
              instrument.defaultCommissionType ||
              INITIAL_DIRECTIVE_INPUT_VALUES.commission_type
          }
          return prev
        }, {})
      case getType(changeAllocationActions.addSecurity):
        return produce(prev, (draft) => {
          draft[action.payload.entityId] = {
            ...INITIAL_DIRECTIVE_INPUT_VALUES,
            commission:
              action.payload.defaultCommission ||
              INITIAL_DIRECTIVE_INPUT_VALUES.commission,
            commission_type: (action.payload.defaultCommissionType ||
              INITIAL_DIRECTIVE_INPUT_VALUES.commission_type) as COMMISSION_TYPE
          }
        })
      case getType(changeAllocationActions.setDirectiveInputValue):
        return produce(prev, (draft) => {
          const {item, category, value, key} = action.payload
          draft[item.id] = draft[item.id] || INITIAL_DIRECTIVE_INPUT_VALUES
          draft[item.id][category.id] = key ? {value, key} : value
        })
      default:
        return prev
    }
  },

  selectedModelPortfolio: (prev = false, action) => {
    switch (action.type) {
      case getType(changeAllocationActions.loadSecuritiesForPortfolioSuccess):
        return true
      default:
        return prev
    }
  },

  bulkRebalanceParams: (prev = null, action) => {
    if (action.type === getType(changeAllocationActions.recommendTrades)) {
      return action.payload
    }

    if (!prev) {
      return prev
    }

    switch (action.type) {
      case getType(changeAllocationActions.addSecurity):
        return produce(prev, (draft) => {
          draft.instruments.push({
            instrument: action.payload.url,
            lowerBound: INITIAL_DIRECTIVE_INPUT_VALUES.lower_bound,
            targetWeight: INITIAL_DIRECTIVE_INPUT_VALUES.target_weight,
            upperBound: INITIAL_DIRECTIVE_INPUT_VALUES.upper_bound,
            allowTrading: true,
            marketPriceOverride: INITIAL_DIRECTIVE_INPUT_VALUES.market_price,
            commission: INITIAL_DIRECTIVE_INPUT_VALUES.commission,
            commissionType: INITIAL_DIRECTIVE_INPUT_VALUES.commission_type
          })
        })
      case getType(changeAllocationActions.setCheckedSecurityUrls):
        return produce(prev, (draft) => {
          for (const instrument of draft.instruments) {
            instrument.allowTrading = action.payload.includes(
              instrument.instrument
            )
          }
        })
      case getType(changeAllocationActions.loadSecuritiesForPortfolioSuccess):
        return produce(prev, (draft) => {
          draft.instruments = action.payload.instruments.map((instrument) => {
            return {
              targetWeight: getInitialTargetWeightForModelPortfolioSecurity(
                instrument,
                action.payload.draft
              ),
              instrument: instrument.url,
              lowerBound: INITIAL_DIRECTIVE_INPUT_VALUES.lower_bound,
              upperBound: INITIAL_DIRECTIVE_INPUT_VALUES.upper_bound,
              allowTrading: true,
              marketPriceOverride: INITIAL_DIRECTIVE_INPUT_VALUES.market_price,
              commission: INITIAL_DIRECTIVE_INPUT_VALUES.commission,
              commissionType: INITIAL_DIRECTIVE_INPUT_VALUES.commission_type
            }
          })
        })
      case getType(changeAllocationActions.clearSecurities):
        return produce(prev, (draft) => {
          draft.instruments = []
        })
      default:
        return prev
    }
  },

  recommendedAccountAllocationChart: (prev = null, action) => {
    switch (action.type) {
      case getType(changeAllocationActions.loadAccountAllocationChartSuccess):
        return action.payload.response
      default:
        return prev
    }
  },

  accountAllocationChart: (
    prev = {data: null, loading: false, error: null},
    action
  ) => {
    switch (action.type) {
      case getType(changeAllocationActions.loadAccountAllocationChartRequest):
        return produce(prev, (draft) => {
          draft.loading = true
          draft.error = null
        })
      case getType(changeAllocationActions.loadAccountAllocationChartSuccess):
        return produce(prev, (draft) => {
          draft.data = action.payload.response
          draft.loading = false
          draft.error = null
        })
      case getType(changeAllocationActions.loadAccountAllocationChartFailure):
        return produce(prev, (draft) => {
          draft.data = null
          draft.loading = false
          draft.error = action.payload
        })
      case getType(changeAllocationActions.updateAccountAllocationChart):
        return produce(prev, (draft) => {
          draft.data = action.payload
        })
      default:
        return prev
    }
  },

  fxRates: (prev = null, action) => {
    switch (action.type) {
      case getType(changeAllocationActions.loadAccountAllocationChartSuccess):
        return action.payload.fxRates
      default:
        return prev
    }
  },

  baseCurrency: (prev = null, action) => {
    switch (action.type) {
      case getType(changeAllocationActions.loadAccountAllocationChartSuccess):
        return action.payload.baseCurrency
      default:
        return prev
    }
  },

  editedValuesBySecurityAndAccount: (prev = {}, action) => {
    switch (action.type) {
      case getType(
        changeAllocationActions.updateAccountAllocationChartValueRequest
      ): {
        const {
          payload: {accountId, categoryId, value}
        } = action

        const [securityId, editableFieldName] = categoryId.split(':')

        return produce(prev, (draft) => {
          if (
            Object.values(BulkChartEditableFieldName).includes(
              editableFieldName as BulkChartEditableFieldName
            )
          ) {
            draft[accountId] = draft[accountId] || {}
            draft[accountId][securityId] = {
              editableFieldName:
                editableFieldName as BulkChartEditableFieldName,
              value
            }
          }
        })
      }
      case getType(changeAllocationActions.loadAccountAllocationChartSuccess):
      case getType(changeAllocationActions.loadAccountAllocationChartFailure):
        return {}
      default:
        return prev
    }
  }
})
