import {combineReducers} from 'redux'

import produce from 'immer'
import {ActionType, createReducer} from 'typesafe-actions'

import {StandardResponse} from '@d1g1t/lib/standard-response'

import {PaginatorAction, paginatorActions} from './actions'
import {
  applyItemsForLoadedRange,
  updateLoadingIndexMapForLoadedRange,
  updateLoadingIndexMapForLoadingRange
} from './lib'
import {IIndexLoadingMap, IPaginatorResponseReducerState} from './typings'

const paginatorResponseReducer =
  combineReducers<IPaginatorResponseReducerState>({
    data: createReducer<StandardResponse, PaginatorAction>(null)
      .handleAction(paginatorActions.loadInitialPageSuccess, (_, action) => {
        return new StandardResponse(action.payload)
      })
      .handleAction(paginatorActions.loadRangeSuccess, (prev, action) => {
        const {chart, range} = action.payload

        return produce(prev, (draft) => {
          const nextChartItems = applyItemsForLoadedRange(
            draft.toChartTable().items,
            chart.items,
            range
          )
          draft.applyReplaceItems(nextChartItems)
        })
      })
      .handleAction(
        paginatorActions.setResponseData,
        (_, action) => action.payload
      )
      .handleAction(paginatorActions.loadInitialPageRequest, (prev) => {
        if (prev) {
          return prev.replaceItems([], 0)
        }
        return prev
      }),

    loading: createReducer<boolean>(false)
      .handleAction(paginatorActions.loadInitialPageRequest, () => true)
      .handleAction(
        [
          paginatorActions.loadInitialPageSuccess,
          paginatorActions.loadInitialPageFailure
        ],
        () => false
      ),

    updatingItemIds: createReducer<string[]>(null)
      .handleAction(
        paginatorActions.updatingItemsRequest,
        (_, action) => action.payload
      )
      .handleAction(
        [
          paginatorActions.updatingItemsSuccess,
          paginatorActions.updatingItemsFailure
        ],
        () => null
      ),

    error: createReducer<Error | null, ActionType<typeof paginatorActions>>(
      null
    )
      .handleAction(
        paginatorActions.loadInitialPageFailure,
        (_, action) => action.payload
      )
      .handleAction(
        [
          paginatorActions.loadInitialPageRequest,
          paginatorActions.loadInitialPageSuccess
        ],
        () => null
      )
  })

const loadingIndexMapReducer = createReducer<IIndexLoadingMap, PaginatorAction>(
  {}
)
  .handleAction(paginatorActions.loadRangeRequest, (prev, action) =>
    updateLoadingIndexMapForLoadingRange(prev, action.payload)
  )
  .handleAction(
    [paginatorActions.loadRangeSuccess, paginatorActions.loadRangeFailure],
    (prev, action) =>
      updateLoadingIndexMapForLoadedRange(prev, action.payload.range)
  )
  .handleAction(paginatorActions.loadInitialPageRequest, () => ({}))

export const paginatorReducer = combineReducers<{
  response: ReturnType<typeof paginatorResponseReducer>
  loadingIndexMap: ReturnType<typeof loadingIndexMapReducer>
}>({
  response: paginatorResponseReducer,
  loadingIndexMap: loadingIndexMapReducer
})
