import React from 'react'

import {useApiQuery} from 'fairlight'

import {PeopleEndpoints} from '@d1g1t/api/endpoints'
import {IProvince} from '@d1g1t/api/typings'

import {
  IValueLabelSelectFieldProps,
  ValueLabelSelectField
} from '../value-select-field'

interface IProvinceSelectFieldProps
  extends Omit<IValueLabelSelectFieldProps, 'valueLabelSelectProps'> {
  /**
   * If set, will filter province options belonging to the country.
   */
  country?: string
  valueLabelSelectProps?: Omit<
    IValueLabelSelectFieldProps['valueLabelSelectProps'],
    'options'
  >
}

/**
 * `formik`-compatible `ValueLabelSelectField` field
 */
export const ProvinceSelectField: React.FC<IProvinceSelectFieldProps> =
  function ProvincesField(props) {
    const {country, ...restProps} = props
    const [provinces] = useApiQuery(PeopleEndpoints.provinces(), {
      fetchPolicy: 'cache-first'
    })

    const provinceOptions = provinces.data
      ? filterProvinces(provinces.data, country)
      : []

    if (provinceOptions.length === 0) {
      return null
    }

    return (
      <ValueLabelSelectField
        {...restProps}
        disabled={restProps.disabled || provinceOptions.length === 0}
        valueLabelSelectProps={{
          ...(restProps.valueLabelSelectProps || {}),
          options: provinceOptions
        }}
      />
    )
  }

function filterProvinces(
  provinces: IProvince[],
  country?: string
): IProvince[] {
  return country
    ? provinces.filter((province) => province.country === country)
    : provinces
}
