import React from 'react'

import {CircularProgress} from '@material-ui/core'

import {CALCULATION_CODES} from '@d1g1t/api/models'

import {Flex} from '@d1g1t/shared/components/flex'
import {StandardStatList} from '@d1g1t/shared/components/standard-stat'
import {
  IUseCalculationDataParams,
  useCalculationData
} from '@d1g1t/shared/wrappers/calculations'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'

import {GROUPS} from './constants'
import {IBasicInfoProps} from './typings'

import * as css from './style.scss'

export * from './constants'
export * from './typings'

export const BasicInfo: React.FC<IBasicInfoProps> = (props) => {
  const calculationParams: IUseCalculationDataParams = {
    control: props.control,
    waitFor: [props.control],
    calculationCode: props.useBasicInfoCode
      ? CALCULATION_CODES.BASIC_INFORMATION
      : CALCULATION_CODES.CPH_TABLE,
    useCachedData: true,
    groups: GROUPS,
    metricViews: props.metrics,
    defaultMetrics: props.defaultMetrics,
    singleResult: props.singleResult
  }

  const [, {calculationId}] = useCalculationData(calculationParams)

  return (
    <Flex alignCenter shrink={0} data-testid='container-basic-info'>
      <ErrorBoundary resetId={calculationId}>
        <BasicInfoContent {...props} calculationParams={calculationParams} />
      </ErrorBoundary>
    </Flex>
  )
}

interface IBasicInfoContentProps extends IBasicInfoProps {
  calculationParams: IUseCalculationDataParams
}

const BasicInfoContent: React.FC<IBasicInfoContentProps> = (props) => {
  const [calculationData] = useCalculationData({
    ...props.calculationParams,
    useErrorBoundary: true
  })

  const response = (() => {
    if (props.responseFilter) {
      return props.responseFilter(calculationData)
    }
    return calculationData.data
  })()

  if (calculationData.loading || !calculationData.data) {
    return <CircularProgress />
  }

  return <StandardStatList response={response} className={css.statContainer} />
}
