import {useMemo} from 'react'

import {useApiQuery} from 'fairlight'

import {FirmSecurityTypeEndpoints} from '@d1g1t/api/endpoints'

import {extractIdFromUrl} from '@d1g1t/lib/url'
import {
  mapModelNameToValueLabel,
  mapModelToValueLabel
} from '@d1g1t/lib/value-label'

export const useFirmSecurirtyTypes = () => {
  const [firmSecurityTypes] = useApiQuery(FirmSecurityTypeEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })

  const firmSecurityTypeOptions = useMemo(
    () => mapModelToValueLabel(firmSecurityTypes.data?.results),
    [firmSecurityTypes.data?.results]
  )

  const firmSecurityTypeOptionsNames = useMemo(
    () => mapModelNameToValueLabel(firmSecurityTypes.data?.results),
    [firmSecurityTypes.data?.results]
  )

  const firmSecurityTypeOptionsSlugs = useMemo(
    () =>
      firmSecurityTypeOptions.map((option) => ({
        ...option,
        value: extractIdFromUrl(option.value)
      })),
    [firmSecurityTypeOptions]
  )

  return {
    firmSecurityTypes,
    firmSecurityTypeOptions,
    firmSecurityTypeOptionsNames,
    firmSecurityTypeOptionsSlugs
  }
}
