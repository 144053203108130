import React, {useMemo} from 'react'

import {useApi, useApiQuery} from 'fairlight'
import {Formik} from 'formik'
import * as Yup from 'yup'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  AssetClassEndpoints,
  InstrumentEndpoints,
  InstrumentStrategyEndpoints,
  TradingEndpoints
} from '@d1g1t/api/endpoints'
import {
  FIRMSECURITYTYPE_INSTRUMENT_MODEL_TYPE,
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  IAssetClass,
  ICurrency,
  IInstrumentStrategy,
  IMarket
} from '@d1g1t/api/models'

import {useToggleState} from '@d1g1t/lib/hooks'
import {mapModelToValueLabel} from '@d1g1t/lib/value-label'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from '@d1g1t/shared/components/expansion-panel'
import {FormActions} from '@d1g1t/shared/components/form-actions'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {ValueLabelSelectField} from '@d1g1t/shared/components/form-field/value-select-field'
import {FormUnsavedPrompt} from '@d1g1t/shared/components/form-unsaved-prompt'
import {SixDecimalInput} from '@d1g1t/shared/components/formatted-input'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Modal, ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Alert} from '@d1g1t/shared/components/mui/alert'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {H2, P} from '@d1g1t/shared/components/typography'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {
  ErrorBoundary,
  ModalContentsErrorFallback
} from '@d1g1t/shared/wrappers/error-boundary'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {useCurrencyList} from '@d1g1t/shared/wrappers/use-currency-list/use-currency-list'
import {useFirmSecurirtyTypes} from '@d1g1t/shared/wrappers/use-firm-security-types/use-firm-security-types'

import css from './style.scss'

interface IAddSecurityModalProps {
  onCancel(): void
  onCreate(): void
}

export const AddSecurityModal: React.FC<IAddSecurityModalProps> = (props) => {
  return (
    <Modal open onClose={props.onCancel} title='Create Security'>
      <ErrorBoundary
        resetId='no-reset'
        fallback={<ModalContentsErrorFallback onClose={props.onCancel} />}
      >
        <AddSecurityModalContents {...props} />
      </ErrorBoundary>
    </Modal>
  )
}

enum ADD_SECURITY_FORM_FIELDS {
  name = 'name',
  firmProvidedKey = 'firmProvidedKey',
  firmSecurityType = 'firmSecurityType',
  currencyUrl = 'currencyUrl',
  marketPrice = 'marketPrice',
  tickerShort = 'tickerShort',
  primaryMarketUrl = 'primaryMarketUrl',
  cusip = 'cusip',
  isin = 'isin',
  assetClassUrl = 'assetClassUrl',
  strategyUrl = 'strategyUrl',
  fundCode = 'fundCode'
}

interface IAddSecurityFormValues {
  [ADD_SECURITY_FORM_FIELDS.name]: string
  [ADD_SECURITY_FORM_FIELDS.firmProvidedKey]: string
  [ADD_SECURITY_FORM_FIELDS.firmSecurityType]: string
  [ADD_SECURITY_FORM_FIELDS.currencyUrl]: string
  [ADD_SECURITY_FORM_FIELDS.marketPrice]: number
  [ADD_SECURITY_FORM_FIELDS.tickerShort]: string
  [ADD_SECURITY_FORM_FIELDS.primaryMarketUrl]: string
  [ADD_SECURITY_FORM_FIELDS.cusip]: string
  [ADD_SECURITY_FORM_FIELDS.isin]: string
  [ADD_SECURITY_FORM_FIELDS.assetClassUrl]: string
  [ADD_SECURITY_FORM_FIELDS.strategyUrl]: string
  [ADD_SECURITY_FORM_FIELDS.fundCode]: string
}

const ADD_SECURITY_VALIDATION_SCHEMA = Yup.object<IAddSecurityFormValues>({
  [ADD_SECURITY_FORM_FIELDS.name]: Yup.string().required().label('Name'),
  [ADD_SECURITY_FORM_FIELDS.firmProvidedKey]: Yup.string()
    .required()
    .label('Security ID'),
  [ADD_SECURITY_FORM_FIELDS.firmSecurityType]: Yup.string()
    .required()
    .label('Security Type'),
  [ADD_SECURITY_FORM_FIELDS.currencyUrl]: Yup.string()
    .required()
    .label('Currency'),
  [ADD_SECURITY_FORM_FIELDS.marketPrice]: Yup.number()
    .nullable()
    .label('Market Price'),
  [ADD_SECURITY_FORM_FIELDS.tickerShort]: Yup.string().label('Ticker'),
  [ADD_SECURITY_FORM_FIELDS.primaryMarketUrl]: Yup.string().label('Market'),
  [ADD_SECURITY_FORM_FIELDS.cusip]: Yup.string().label('CUSIP'),
  [ADD_SECURITY_FORM_FIELDS.isin]: Yup.string().label('ISIN'),
  [ADD_SECURITY_FORM_FIELDS.assetClassUrl]: Yup.string().label('Asset Class'),
  [ADD_SECURITY_FORM_FIELDS.strategyUrl]: Yup.string().label('Strategy'),
  [ADD_SECURITY_FORM_FIELDS.fundCode]: Yup.string().label('Fund Code')
})

const AddSecurityModalContents: React.FC<IAddSecurityModalProps> = (props) => {
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()

  const [securityIdsExpanded, toggleSecurityIdsExpanded] = useToggleState(false)
  const [securityTagsExpanded, toggleSecurityTagsExpanded] =
    useToggleState(false)

  const {currencyOptions, currencies} = useCurrencyList()

  const {firmConfiguration} = useFirmConfiguration()

  const [assetClasses] = useApiQuery(AssetClassEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })
  const assetClassOptions = useMemo(
    () => mapModelToValueLabel(assetClasses.data?.results),
    [assetClasses.data]
  )

  const [markets] = useApiQuery(TradingEndpoints.markets(), {
    fetchPolicy: 'cache-first'
  })
  const marketOptions = useMemo(
    () =>
      (markets.data?.results || []).map((market) => ({
        value: market.url,
        label: market.country
      })),
    [markets.data]
  )
  const [strategies] = useApiQuery(InstrumentStrategyEndpoints.list(), {
    fetchPolicy: 'cache-first'
  })
  const strategyOptions = useMemo(
    () => mapModelToValueLabel(strategies.data?.results),
    [strategies.data]
  )

  const {firmSecurityTypeOptions, firmSecurityTypes} = useFirmSecurirtyTypes()

  const firmSecurityTypesWithFundInstrument = useMemo(() => {
    return firmSecurityTypes.data?.results
      .filter(
        (firmSecurityType) =>
          firmSecurityType.instrumentTradingType ===
          FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND
      )
      .map((item) => item.url)
  }, [firmSecurityTypes.data])

  const api = useApi()

  const queries = [
    firmConfiguration,
    currencies,
    assetClasses,
    markets,
    strategies,
    firmSecurityTypes
  ]

  return (
    <LoadingContainer loading={queries.some((query) => query.loading)}>
      {queries.every((query) => query.data) && (
        <Formik
          validationSchema={ADD_SECURITY_VALIDATION_SCHEMA}
          initialValues={{
            name: '',
            firmProvidedKey: '',
            firmSecurityType: firmSecurityTypes.data.results.find(
              (firmSecurityType) =>
                firmSecurityType.instrumentModelType ===
                FIRMSECURITYTYPE_INSTRUMENT_MODEL_TYPE.EQUITY
            ).url,
            currencyUrl: firmConfiguration.data?.baseCurrency,
            tickerShort: '',
            marketPrice: null,
            primaryMarketUrl: '',
            cusip: '',
            isin: '',
            assetClassUrl: '',
            strategyUrl: ''
          }}
          onSubmit={async (values) => {
            const {
              currencyUrl,
              assetClassUrl,
              primaryMarketUrl,
              strategyUrl,
              firmSecurityType,
              ...basicValues
            } = values

            try {
              const newSecurity = await api.request(
                InstrumentEndpoints.create({
                  ...basicValues,
                  firmSecurityType,
                  currency: currencyUrl
                    ? ({url: currencyUrl} as ICurrency)
                    : undefined,
                  assetClass: assetClassUrl
                    ? ({url: assetClassUrl} as IAssetClass)
                    : undefined,
                  primaryMarket: primaryMarketUrl
                    ? ({url: primaryMarketUrl} as IMarket)
                    : undefined,
                  strategy: strategyUrl
                    ? ({url: strategyUrl} as IInstrumentStrategy)
                    : undefined
                })
              )
              showSnackbar({
                variant: 'success',
                message: `Security '${newSecurity.name}' has been created.`
              })
              props.onCreate()
              props.onCancel()
            } catch (error) {
              handleUnexpectedError(
                error,
                'An unexpected error occurred while creating the security.'
              )
            }
          }}
        >
          {({handleSubmit, isSubmitting, values}) => (
            <ControlStateProvider loading={isSubmitting}>
              <FormUnsavedPrompt />
              <form onSubmit={handleSubmit} className={css.form}>
                <ModalContent>
                  <Grid container>
                    <Grid item xs={12}>
                      {!values.marketPrice && (
                        <Alert severity='warning'>
                          Please enter a price if you want to use this security
                          for trading
                        </Alert>
                      )}
                      <P>
                        Please provide some basic details for your security.
                        Additonal information can be added to the security after
                        it is created.
                      </P>
                    </Grid>
                    <Grid item xs={6}>
                      <OutlinedInputField
                        name={ADD_SECURITY_FORM_FIELDS.name}
                        label='Name'
                        outlinedInputProps={{autoFocus: true}}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <OutlinedInputField
                        name={ADD_SECURITY_FORM_FIELDS.firmProvidedKey}
                        label='Security ID'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ValueLabelSelectField
                        name={ADD_SECURITY_FORM_FIELDS.firmSecurityType}
                        label='Security Type'
                        valueLabelSelectProps={{
                          options: firmSecurityTypeOptions
                        }}
                      />
                    </Grid>
                    {/* only show the field if instrument trading type is FUND */}
                    {firmSecurityTypesWithFundInstrument.includes(
                      values.firmSecurityType
                    ) && (
                      <Grid item xs={6}>
                        <OutlinedInputField
                          name={ADD_SECURITY_FORM_FIELDS.fundCode}
                          label='Fund Code'
                        />
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      <ValueLabelSelectField
                        name={ADD_SECURITY_FORM_FIELDS.currencyUrl}
                        label='Currency'
                        valueLabelSelectProps={{
                          options: currencyOptions
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <OutlinedInputField
                        name={ADD_SECURITY_FORM_FIELDS.marketPrice}
                        label='Market Price'
                        outlinedInputProps={{
                          inputComponent: SixDecimalInput
                        }}
                      />
                    </Grid>
                  </Grid>

                  <ExpansionPanel
                    noBottomMargin
                    expanded={securityIdsExpanded}
                    onChange={toggleSecurityIdsExpanded}
                  >
                    <ExpansionPanelSummary
                      noSidePadding
                      testId='button-show-additional-security'
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <H2>Security IDs (optional)</H2>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails noSidePadding>
                      <Grid container>
                        <Grid item xs={6}>
                          <OutlinedInputField
                            name={ADD_SECURITY_FORM_FIELDS.tickerShort}
                            label='Ticker'
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <ValueLabelSelectField
                            name={ADD_SECURITY_FORM_FIELDS.primaryMarketUrl}
                            label='Market'
                            valueLabelSelectProps={{
                              options: marketOptions
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OutlinedInputField
                            name={ADD_SECURITY_FORM_FIELDS.cusip}
                            label='CUSIP'
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <OutlinedInputField
                            name={ADD_SECURITY_FORM_FIELDS.isin}
                            label='ISIN'
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel
                    noBottomMargin
                    expanded={securityTagsExpanded}
                    onChange={toggleSecurityTagsExpanded}
                  >
                    <ExpansionPanelSummary
                      noSidePadding
                      testId='button-show-security-tags'
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <H2>Security Tags (optional)</H2>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails noSidePadding>
                      <Grid container>
                        <Grid item xs={6}>
                          <ValueLabelSelectField
                            name={ADD_SECURITY_FORM_FIELDS.assetClassUrl}
                            label='Asset Class'
                            valueLabelSelectProps={{
                              options: assetClassOptions
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <ValueLabelSelectField
                            name={ADD_SECURITY_FORM_FIELDS.strategyUrl}
                            label='Strategy'
                            valueLabelSelectProps={{
                              options: strategyOptions
                            }}
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </ModalContent>
                <ModalActions>
                  <FormActions formType='create' onCancel={props.onCancel} />
                </ModalActions>
              </form>
            </ControlStateProvider>
          )}
        </Formik>
      )}
    </LoadingContainer>
  )
}
