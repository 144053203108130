import React from 'react'

import {
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  TRADEORDER_QTY_TYPE
} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'

import {DataCellValueFormatted} from '@d1g1t/shared/containers/standard-table/components/data-cell/data-cell-value'
import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'

import {CATEGORY_IDS} from '@d1g1t/advisor/containers/trade-preview'

import * as dataCellCss from '@d1g1t/shared/containers/standard-table/components/data-cell/styles.scss'
import * as commonCss from '@d1g1t/shared/containers/standard-table/styles.scss'

/**
 * Custom Cell to render Quantity depending on Quantity Type in Rebalance
 * Strategies Trade Drawer
 */
export const RebalanceStrategiesTradeOrderQuantity: React.FC<IDataCellProps> = (
  props
) => {
  const qtyType = props.item.getKey(CATEGORY_IDS.QUANTITY_TYPE)

  if (
    props.item.getValue(CATEGORY_IDS.IS_SELL_ALL) &&
    props.item.getKey(CATEGORY_IDS.TRADING_TYPE) ===
      FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND
  ) {
    return (
      <div className={classNames(commonCss.innerCell, dataCellCss.number)}>
        ALL
      </div>
    )
  }

  return (
    <DataCellValueFormatted
      getFormatter={props.getFormatter}
      value={props.item.getValue(
        qtyType === TRADEORDER_QTY_TYPE.UNITS
          ? CATEGORY_IDS.QUANTITY
          : CATEGORY_IDS.TRADE_VALUE
      )}
      valueType={props.category.valueType}
      valueOptions={props.category.options}
    />
  )
}
