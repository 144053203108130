import React from 'react'

import {INVESTORPORTALSECTION_NAME} from '@d1g1t/api/models'

import {Flex} from '@d1g1t/shared/components/flex'
import {Checkbox} from '@d1g1t/shared/components/mui/checkbox'
import {FormControlLabel} from '@d1g1t/shared/components/mui/form-control-label'
import {ALLOWED_INVESTOR_VIEW_TABLE_KEYS} from '@d1g1t/shared/containers/view-options/constants'

import {IInvestorPortalSections} from '../typings'

import css from './styles.scss'

interface IAdminOptionsProps {
  isGlobal: boolean
  isAvailableToExternalProfiles: boolean
  investorPortalSections: IInvestorPortalSections
  onViewIsGlobalChange(isGlobal: boolean): void
  onAvailableToExternalProfilesChange(
    isAvailableToExternalProfiles: boolean
  ): void
  onInvestorPortalSectionsChange(
    section: INVESTORPORTALSECTION_NAME,
    value: boolean
  ): void
  tableKey: string
}

export const AdminOptions: React.FC<IAdminOptionsProps> = (props) => {
  return (
    <Flex column wrap>
      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            checked={props.isGlobal}
            onChange={(isGlobal) => {
              props.onViewIsGlobalChange(!props.isGlobal)
            }}
          />
        }
        label='Share with internal users as global view'
      />
      {Object.values(ALLOWED_INVESTOR_VIEW_TABLE_KEYS).includes(
        props.tableKey
      ) && (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={props.isAvailableToExternalProfiles}
              onChange={() => {
                props.onAvailableToExternalProfilesChange(
                  !props.isAvailableToExternalProfiles
                )
              }}
            />
          }
          label='Share with investors in the investor portal'
        />
      )}
      {props.isAvailableToExternalProfiles &&
        props.tableKey ===
          ALLOWED_INVESTOR_VIEW_TABLE_KEYS.cphMonitorOverviewSingleClient && (
          <>
            <FormControlLabel
              className={css.investorPortalSection}
              control={
                <Checkbox
                  color='primary'
                  checked={props.investorPortalSections.home}
                  onChange={() => {
                    props.onInvestorPortalSectionsChange(
                      INVESTORPORTALSECTION_NAME.HOME,
                      !props.investorPortalSections.home
                    )
                  }}
                />
              }
              label='Home'
            />
            <FormControlLabel
              className={css.investorPortalSection}
              control={
                <Checkbox
                  color='primary'
                  checked={props.investorPortalSections.investments}
                  onChange={() => {
                    props.onInvestorPortalSectionsChange(
                      INVESTORPORTALSECTION_NAME.INVESTMENTS,
                      !props.investorPortalSections.investments
                    )
                  }}
                />
              }
              label='Investments'
            />
            {/* Commented out as currently no views are being used from
               advisor in these investor portal sections */}
            {/* <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={props.investorPortalSections.activity}
                    onChange={() => {
                      props.onInvestorPortalSectionsChange({
                        activity: !props.investorPortalSections.activity
                      })
                    }}
                  />
                }
                label='Activity'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={props.investorPortalSections.documents}
                    onChange={() => {
                      props.onInvestorPortalSectionsChange({
                        documents: !props.investorPortalSections.documents
                      })
                    }}
                  />
                }
                label='Documents'
              /> */}
          </>
        )}
    </Flex>
  )
}
