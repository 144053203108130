import * as Yup from 'yup'

export enum ADDRESS_FIELD_NAMES {
  name = 'name',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  stateOrProvince = 'stateOrProvince',
  country = 'country',
  zipOrPostalCode = 'zipOrPostalCode',
  addressType = 'addressType',
  fromDate = 'fromDate',
  toDate = 'toDate'
}

const fieldsToTrackNonEmptyValue = [
  ADDRESS_FIELD_NAMES.name,
  ADDRESS_FIELD_NAMES.address1,
  ADDRESS_FIELD_NAMES.address2,
  ADDRESS_FIELD_NAMES.city,
  ADDRESS_FIELD_NAMES.stateOrProvince,
  ADDRESS_FIELD_NAMES.country,
  ADDRESS_FIELD_NAMES.zipOrPostalCode
]

function getLabeledRequiredMessage({label}: {label: string}): string {
  return `${label} is a required field`
}

const allOrNothingValidation = {
  name: 'is-some-address-field-not-empty',
  message: getLabeledRequiredMessage,
  test: (value, {parent}) => {
    if (!!value) {
      return true
    }

    return !fieldsToTrackNonEmptyValue.some((fieldName) => parent[fieldName])
  }
}

const strictRequiredValidation = {
  name: 'is-required',
  message: getLabeledRequiredMessage,
  test: (value) => !!value
}

export const getAddressValidationSchema = (isStrictRequired?: boolean) => {
  const validation = isStrictRequired
    ? strictRequiredValidation
    : allOrNothingValidation

  return {
    [ADDRESS_FIELD_NAMES.address1]: Yup.string()
      .nullable()
      .label('Street Name and Number')
      .test(validation),
    [ADDRESS_FIELD_NAMES.address2]: Yup.string().nullable().label('Unit'),
    [ADDRESS_FIELD_NAMES.country]: Yup.string()
      .nullable()
      .label('Country')
      .test(validation),
    [ADDRESS_FIELD_NAMES.city]: Yup.string()
      .nullable()
      .label('City')
      .test(validation),
    [ADDRESS_FIELD_NAMES.stateOrProvince]: Yup.string()
      .nullable()
      .label('Provice / State / Territory'),
    [ADDRESS_FIELD_NAMES.zipOrPostalCode]: Yup.string()
      .nullable()
      .label('Postal or Zip Code')
      .test(validation),
    [ADDRESS_FIELD_NAMES.addressType]: Yup.string()
      .nullable()
      .label('Address Type'),
    [ADDRESS_FIELD_NAMES.fromDate]: Yup.string().nullable().label('From Date'),
    [ADDRESS_FIELD_NAMES.toDate]: Yup.string().nullable().label('To Date')
  }
}

export const ADDRESS_NAME_VALIDATION_SCHEMA = {
  [ADDRESS_FIELD_NAMES.name]: Yup.string()
    .nullable()
    .label('Address Name')
    .test(allOrNothingValidation)
}

export const ADDRESS_INITIAL_VALUES = {
  [ADDRESS_FIELD_NAMES.address1]: '',
  [ADDRESS_FIELD_NAMES.address2]: '',
  [ADDRESS_FIELD_NAMES.country]: null,
  [ADDRESS_FIELD_NAMES.city]: '',
  [ADDRESS_FIELD_NAMES.stateOrProvince]: '',
  [ADDRESS_FIELD_NAMES.zipOrPostalCode]: ''
}
