import React from 'react'

import {isEqual, isUndefined} from 'lodash'

import {DraggableItem} from '@d1g1t/shared/components/draggable-item'
import {ListEditorEmptyState} from '@d1g1t/shared/components/list-editor-empty-state'
import {OrderableListItem} from '@d1g1t/shared/components/orderable-list-item'
import {OrderableListItemText} from '@d1g1t/shared/components/orderable-list-item-text'

export interface IDraggableItem {
  id: string | number
  child: React.ReactNode
  /**
   * Disable draggability of item
   */
  disableDrag?: boolean
  [x: string]: any
}

interface IDraggableListProps {
  items: IDraggableItem[]
  emptyText: React.ReactNode
  itemSelected?: number
  onRemoveItem?(id)
  onItemClick?(id)
  onReorder?(items: IDraggableItem[])
  /**
   * @param iteam - Reordered items that have been passed
   * @param id - ID passed in from props
   * @param index - Drop index of the item: IE index = 2 means item has been moved to the third position
   */
  onReorderDrop?(items: IDraggableItem[], id: number, index: number)
}

interface IDraggableListState {
  items: IDraggableItem[]
}

export class DraggableList extends React.Component<
  IDraggableListProps,
  IDraggableListState
> {
  state = {
    items: [...this.props.items]
  }

  componentDidUpdate(prevProps: IDraggableListProps) {
    if (isEqual(this.props.items, prevProps.items)) {
      return
    }

    this.setState({items: [...this.props.items]})
  }

  handleMove = (dragIndex: number, hoverIndex: number) => {
    this.setState(({items}) => {
      const dragItem = items[dragIndex]

      items.splice(dragIndex, 1)
      items.splice(hoverIndex, 0, dragItem)

      if (this.props.onReorder) {
        this.props.onReorder(items)
      }

      return {
        items
      }
    })
  }

  handleDrop = (id: number, index: number, result: object) => {
    if (!this.props.onReorderDrop) {
      return
    }

    this.props.onReorderDrop(this.state.items, id, index)
  }

  onItemClick = (item) => {
    if (!this.props.onItemClick) {
      return
    }

    this.props.onItemClick(item)
  }

  renderItems() {
    const {emptyText, itemSelected} = this.props
    const {items} = this.state

    if (!items || items.length === 0) {
      return <ListEditorEmptyState>{emptyText}</ListEditorEmptyState>
    }

    return items.map((item, i) => {
      if (item.disableDrag) {
        return (
          <div key={item.id}>
            <OrderableListItem
              onClick={() => this.onItemClick}
              onDelete={
                this.props.onRemoveItem
                  ? () => this.props.onRemoveItem
                  : undefined
              }
            >
              <OrderableListItemText>{item.child}</OrderableListItemText>
            </OrderableListItem>
          </div>
        )
      }

      return (
        <div key={item.id}>
          <DraggableItem
            index={i}
            id={item.id}
            isSelected={
              !isUndefined(itemSelected) ? itemSelected === item.id : false
            }
            onMove={this.handleMove}
            onRemove={this.props.onRemoveItem}
            onClick={this.onItemClick}
            onDrop={this.handleDrop}
          >
            {item.child}
          </DraggableItem>
          {item.expandedChild}
        </div>
      )
    })
  }

  render() {
    return <div>{this.renderItems()}</div>
  }
}
