import React, {useEffect, useMemo, useState} from 'react'

import {useApiQuery} from 'fairlight'
import {useField} from 'formik'

import {EntityEndpoints, InstrumentEndpoints} from '@d1g1t/api/endpoints'
import {
  ALL_MODELS,
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE
} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {SearchInputField} from '@d1g1t/shared/components/form-field/search-input-field'
import {ISearchResult} from '@d1g1t/shared/containers/search'
import {useSelectedEntities} from '@d1g1t/shared/containers/select-entities'

import {EQUITIES_ETFS_FIELD_NAMES} from '../../typings'
import {getKnownRestrictions, restrictionsMessage} from './lib'

/**
 * A search field for an instrument.
 *
 * Displays an error message if the instrument has a known trading restriction.
 */
export const EquitiesEtfsInstrumentField: React.FC = () => {
  const [fieldInput, fieldMeta] = useField<string>(
    EQUITIES_ETFS_FIELD_NAMES.instrumentUrl
  )

  const [, , {setValue: setInstrumentName}] = useField<string>(
    EQUITIES_ETFS_FIELD_NAMES.instrumentName
  )

  const [restrictionsError, setRestrictionsError] = useState<string>(null)

  const selectedEntities = useSelectedEntities()

  const {value: instrumentUrl} = fieldInput

  const [instrument] = useApiQuery(
    instrumentUrl &&
      InstrumentEndpoints.findById(extractIdFromUrl(instrumentUrl)),
    {
      fetchPolicy: 'cache-and-fetch'
    }
  )

  const [entityRelationsResults] = useApiQuery(
    selectedEntities?.selection.selected &&
      EntityEndpoints.entityRelationships(
        selectedEntities?.selection.selected[0].entityId
      ),
    {
      fetchPolicy: 'cache-and-fetch'
    }
  )

  const restrictions = useMemo(
    () =>
      getKnownRestrictions({
        selection: selectedEntities?.selection,
        instrument: instrument.data,
        entityRelationsResults: entityRelationsResults.data
      }),
    [selectedEntities?.selection, instrument.data, entityRelationsResults.data]
  )

  useEffect(() => {
    if (restrictions.restricted) {
      setRestrictionsError(restrictionsMessage(restrictions))
    } else {
      setRestrictionsError(null)
    }
  }, [restrictions])

  useEffect(() => {
    setInstrumentName(instrument.data?.name)
  }, [instrument.data?.name])

  return (
    <SearchInputField
      name={EQUITIES_ETFS_FIELD_NAMES.instrumentUrl}
      label='Ticker'
      getOptionLabelOverride={(searchResult) => searchResult.displayText}
      searchProps={{
        models: [ALL_MODELS.INSTRUMENT],
        filterBy: filterTradeType,
        isTradable: true
      }}
      data-testid='search-security'
      {...fieldInput}
      {...fieldMeta}
      error={fieldMeta.error || restrictionsError}
    />
  )
}

function filterTradeType(searchResult: ISearchResult) {
  return (
    searchResult.tradingType === FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY
  )
}
