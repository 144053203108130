import {isNaN} from 'lodash'
import * as Yup from 'yup'

import {
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  ITradeOrder,
  TRADEORDER_OPERATION
} from '@d1g1t/api/models'

import {EQUITIES_ETFS_VALIDATION_SCHEMA} from './equities-etfs/constants'
import {
  getInitialCreateEquitiesEtfsFormInitialValues,
  getInitialEditEquitiesEtfsFormInitialValues
} from './equities-etfs/lib'
import {FIXED_INCOME_VALIDATION_SCHEMA} from './fixed-income/constants'
import {getInitialEditFixedIncomeFormInitialValues} from './fixed-income/lib'
import {MUTUAL_FUND_VALIDATION_SCHEMA} from './mutual-funds/constants'
import {
  getInitialCreateMutualFundFormInitialValues,
  getInitialEditMutualFundFormInitialValues
} from './mutual-funds/lib'
import {QTY_TYPE} from './mutual-funds/typings'
import {ITradeOrderFormValues, TRADE_FORM_TYPE} from './typings'

export class UnsupportedTradeTypeError extends Error {}

export function getInitialCreateTradeOrderFormValues(
  tradeType: TRADE_FORM_TYPE,
  initialInstrumentUrl?: string
): ITradeOrderFormValues {
  switch (tradeType) {
    case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY:
      return getInitialCreateEquitiesEtfsFormInitialValues(initialInstrumentUrl)
    case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND:
      return getInitialCreateMutualFundFormInitialValues(initialInstrumentUrl)
    default:
      throw new UnsupportedTradeTypeError()
  }
}

export function getInitialEditTradeOrderFormValues(
  tradeOrder: ITradeOrder,
  accountUrl: string
): ITradeOrderFormValues {
  switch (
    tradeOrder.securityType as unknown as FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE
  ) {
    case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY:
      return getInitialEditEquitiesEtfsFormInitialValues(tradeOrder, accountUrl)
    case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND:
      return getInitialEditMutualFundFormInitialValues(
        tradeOrder as ITradeOrder,
        accountUrl
      )
    case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FIXED_INCOME:
      return getInitialEditFixedIncomeFormInitialValues(
        tradeOrder as ITradeOrder,
        accountUrl
      )
    default:
      throw new UnsupportedTradeTypeError()
  }
}

export function getTradeOrderValidationSchema(
  tradeType: TRADE_FORM_TYPE
): Yup.Schema<unknown> {
  switch (tradeType) {
    case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY:
      return EQUITIES_ETFS_VALIDATION_SCHEMA
    case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND:
      return MUTUAL_FUND_VALIDATION_SCHEMA
    case FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FIXED_INCOME:
      return FIXED_INCOME_VALIDATION_SCHEMA
    default:
      throw new UnsupportedTradeTypeError()
  }
}

/**
 * Checks if the qtyChange is a valid number
 * @param qty - can be a number or a string depending on order details
 * @returns is qty is valid number and narrows down the qty type
 */
export function qtyChangeIsNumber(qty: number | string): qty is number {
  if (qty === null) {
    return false
  }
  // eslint-disable-next-line no-new-wrappers
  return !isNaN(new Number(qty))
}

export function isDSCFree(qtyType: QTY_TYPE): boolean {
  if (qtyType === QTY_TYPE.DSC_FREE) {
    return true
  }
  return false
}

/**
 * Sets the value of the quantity as negative for SELL operations
 * @param qty - instrument quantity to be traded
 * @param operation - order operation value
 * @returns - properly signed quantity value
 */
export function qtyChangeOperationSign(
  qty: number,
  operation: TRADEORDER_OPERATION
): number {
  return [
    TRADEORDER_OPERATION.SELL,
    TRADEORDER_OPERATION.SELL_SHORT,
    TRADEORDER_OPERATION.SELL_ALL
  ].includes(operation)
    ? -Math.abs(qty)
    : Math.abs(qty)
}
