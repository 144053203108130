import {createAction} from 'typesafe-actions'

import {
  IAccountsRebalancingRequest,
  IAccountsRebalancingResponse
} from '@d1g1t/api/models'

export const actions = {
  rebalanceAccountsRequest:
    createAction('REBALANCE_REQUEST')<IAccountsRebalancingRequest>(),
  rebalanceAccountsSuccess:
    createAction('REBALANCE_SUCCESS')<IAccountsRebalancingResponse>(),
  rebalanceAccountsFailure: createAction('REBALANCE_FAILURE')<Error>(),
  clearDraftRequest: createAction(
    'CLEAR_DRAFT_REQUEST'
  )<IAccountsRebalancingRequest>(),
  clearDraftSuccess: createAction(
    'CLEAR_DRAFT_SUCCESS'
  )<IAccountsRebalancingResponse>(),
  clearDraftFailure: createAction('CLEAR_DRAFT_FAILURE')<Error>(),
  updateData: createAction('UPDATE_DATA')<IAccountsRebalancingResponse>(),
  rebalanceAccountsCancel: createAction('REBALANCE_CANCEL')()
}
