import React, {useState} from 'react'

import {useApi} from 'fairlight'

import GroupIcon from '@material-ui/icons/Group'
import PublicIcon from '@material-ui/icons/Public'

import {IApiListResponse, RuleFilterEndpoints} from '@d1g1t/api/endpoints'
import {IFilterRule} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Button} from '@d1g1t/shared/components/mui/button'
import {OrderableListItem} from '@d1g1t/shared/components/orderable-list-item'
import {OrderableListItemText} from '@d1g1t/shared/components/orderable-list-item-text'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'

import * as css from './style.scss'

interface IRuleFilterListProps {
  ruleFilters: Loadable<IApiListResponse<IFilterRule>>
  onSelectRuleFilter(filterRule: IFilterRule)
  onAddRuleFilter()
  onDeleteRuleFilter(filterRule: IFilterRule)
}

export const RuleFilterList: React.FC<IRuleFilterListProps> = (props) => {
  const api = useApi()
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()
  const [deletingRuleFilter, setDeletingRuleFilter] = useState(false)

  return (
    <ControlStateProvider loading={deletingRuleFilter}>
      <ModalContent>
        {props.ruleFilters.data.results.map((filter) => (
          <OrderableListItem
            key={filter.url}
            onClick={() => props.onSelectRuleFilter(filter)}
            onDelete={async () => {
              try {
                setDeletingRuleFilter(true)

                await api.request(
                  RuleFilterEndpoints.destroy(extractIdFromUrl(filter.url))
                )

                showSnackbar({
                  variant: 'success',
                  message: `Rule filter '${filter.name}' has been deleted.`
                })

                props.onDeleteRuleFilter(filter)
              } catch (error) {
                handleUnexpectedError(error)
              } finally {
                setDeletingRuleFilter(false)
              }
            }}
          >
            <OrderableListItemText>
              {filter.name}
              {filter.isAvailableToExternalProfiles && (
                <GroupIcon className={css.globalIcon} />
              )}
              {filter.isShared && <PublicIcon className={css.globalIcon} />}
            </OrderableListItemText>
          </OrderableListItem>
        ))}
      </ModalContent>
      <ModalActions>
        <Button primary contained onClick={props.onAddRuleFilter}>
          Add a rule filter
        </Button>
      </ModalActions>
    </ControlStateProvider>
  )
}
