import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {useApiQuery} from 'fairlight'

import {FirmConfigurationEndpoints} from '@d1g1t/api/endpoints'

import {PARAMS} from '@d1g1t/lib/url'

import {useLoginRedirectIfLoggedOut} from '@d1g1t/shared/wrappers/auth'
import {useDatadogConfig} from '@d1g1t/shared/wrappers/datadog/hook'

import {AdvisorAppNotFoundPage} from '@d1g1t/advisor/components/not-found-page'
import {Frame} from '@d1g1t/advisor/containers/frame'
import {
  ENTITY_REDIRECT_PATH,
  EntityRedirect
} from '@d1g1t/advisor/containers/frame/components/entity-redirect'
import {
  AdministrationLocations,
  BillingLocations,
  ClientsLocations,
  ContactGroupsLocations,
  ContactsLocations,
  DMSDocumentsLocations,
  DocumentsLocations,
  drilldownFuturePath,
  drilldownPastPath,
  drilldownPresentPath,
  ExploreLocations,
  GlobalFilterLocations,
  InvestmentProgramLocations,
  ManageFundLocations,
  monitorActivityPath,
  monitorPath,
  securitiesListPath,
  SecurityModelLocations,
  SingleClientLocations,
  TrackComplianceLocations,
  TradeLocations
} from '@d1g1t/advisor/locations'
import {AccountGroupsPage} from '@d1g1t/advisor/pages/administration/account-management/account-groups/list/loadable'
import {AccountGroupSinglePage} from '@d1g1t/advisor/pages/administration/account-management/account-groups/single/loadable'
import {AccountManagementPage} from '@d1g1t/advisor/pages/administration/account-management/accounts/loadable'
import {CustodianAccountManagementPage} from '@d1g1t/advisor/pages/administration/account-management/custodian-accounts/loadable'
import {BenchmarksPage} from '@d1g1t/advisor/pages/administration/benchmarks/loadable'
import {ClientAssignmentsListPage} from '@d1g1t/advisor/pages/administration/clients/client-assignments/list/loadable'
import {CollectionsListPage} from '@d1g1t/advisor/pages/administration/collections/list/loadable'
import {CollectionsSinglePage} from '@d1g1t/advisor/pages/administration/collections/single/loadable'
import {DatasetsListPage} from '@d1g1t/advisor/pages/administration/datasets/list/loadable'
import {DatasetsSinglePage} from '@d1g1t/advisor/pages/administration/datasets/single/loadable'
import {HouseholdsListPage} from '@d1g1t/advisor/pages/administration/households/list/loadable'
import {HouseholdSinglePage} from '@d1g1t/advisor/pages/administration/households/single/loadable'
import {ManageSecurityListPage} from '@d1g1t/advisor/pages/administration/manage-security/loadable'
import {FileProcessingListPage} from '@d1g1t/advisor/pages/administration/processes/file-processing/list/loadable'
import {FileProcessingSinglePage} from '@d1g1t/advisor/pages/administration/processes/file-processing/single/loadable'
import {TradeLocationRulesPage} from '@d1g1t/advisor/pages/administration/trade-location-rules/loadable'
import {AllocationExclusionsPage} from '@d1g1t/advisor/pages/administration/trade-restrictions/allocation-exclusions/loadable'
import {TradeRestrictionsPage} from '@d1g1t/advisor/pages/administration/trade-restrictions/trade-restrictions/loadable'
import {TransactionsListPage} from '@d1g1t/advisor/pages/administration/transactions/list/loadable'
import {ClientManagementRolesPage} from '@d1g1t/advisor/pages/administration/users/client-management-roles/loadable'
import {ClientManagementTeamListPage} from '@d1g1t/advisor/pages/administration/users/client-management-teams/loadable'
import {UserGroupsPage} from '@d1g1t/advisor/pages/administration/users/user-groups/list/loadable'
import {UserGroupSinglePage} from '@d1g1t/advisor/pages/administration/users/user-groups/single/loadable'
import {UsersPage} from '@d1g1t/advisor/pages/administration/users/user/list/loadable'
import {UserProfilePage} from '@d1g1t/advisor/pages/administration/users/user/single'
import {BillingGroupsPage} from '@d1g1t/advisor/pages/billing/configure-billing/billing-groups/loadable'
import {BillingGroupSinglePage} from '@d1g1t/advisor/pages/billing/configure-billing/billing-groups/single/loadable'
import {BillingPropertiesPage} from '@d1g1t/advisor/pages/billing/configure-billing/billing-properties/loadable'
import {BillingPropertiesSinglePage} from '@d1g1t/advisor/pages/billing/configure-billing/billing-properties/single/loadable'
import {FeeSchedulesPage} from '@d1g1t/advisor/pages/billing/configure-billing/fee-schedules/loadable'
import {FeeScheduleSinglePage} from '@d1g1t/advisor/pages/billing/configure-billing/fee-schedules/single/loadable'
import {FeeSharingRulesPage} from '@d1g1t/advisor/pages/billing/configure-billing/fee-sharing-rules/loadable'
import {FeeSpecsPage} from '@d1g1t/advisor/pages/billing/configure-billing/fee-specs/loadable'
import {FeeSpecSinglePage} from '@d1g1t/advisor/pages/billing/configure-billing/fee-specs/single/loadable'
import {CalculatedFeesSinglePage} from '@d1g1t/advisor/pages/billing/manage-billing/calculated-fees-single'
import {ManageBillingPage} from '@d1g1t/advisor/pages/billing/manage-billing/loadable'
import {LoadableClientListPage} from '@d1g1t/advisor/pages/client-list/loadable'
import {CompareStrategiesLoadable} from '@d1g1t/advisor/pages/compare-strategies/loadable'
import {ContactGroupListPage} from '@d1g1t/advisor/pages/contact/contact-groups/list/loadable'
import {ContactGroupSinglePage} from '@d1g1t/advisor/pages/contact/contact-groups/single/loadable'
import {ContactListPage} from '@d1g1t/advisor/pages/contact/contacts/list/loadable'
import {ContactSingleEditPage} from '@d1g1t/advisor/pages/contact/contacts/single/edit/loadable'
import {ContactSingleViewPage} from '@d1g1t/advisor/pages/contact/contacts/single/view/loadable'
import {DMSDocumentsPage} from '@d1g1t/advisor/pages/dms/documents/loadable'
import {DocumentsPage} from '@d1g1t/advisor/pages/documents/loadable'
import {LoadableFundListPage} from '@d1g1t/advisor/pages/fund-list/loadable'
import {GlobalFiltersListPage} from '@d1g1t/advisor/pages/global-filters/list/loadable'
import {GlobalFiltersSinglePage} from '@d1g1t/advisor/pages/global-filters/single/loadable'
import {InvestmentProgramListPage} from '@d1g1t/advisor/pages/investment-programs/list/loadable'
import {InvestmentProgramSinglePage} from '@d1g1t/advisor/pages/investment-programs/single/loadable'
import {ManageClientLoadable} from '@d1g1t/advisor/pages/manage-client/loadable'
import {ManageFundPage} from '@d1g1t/advisor/pages/manage-fund/loadable'
import {MonitorActivity} from '@d1g1t/advisor/pages/monitor/activity/loadable'
import {MonitorDrilldownFuture} from '@d1g1t/advisor/pages/monitor/future/loadable'
import {Overview} from '@d1g1t/advisor/pages/monitor/overview/loadable'
import {MonitorDrilldownPast} from '@d1g1t/advisor/pages/monitor/past/loadable'
import {MonitorDrilldownPresent} from '@d1g1t/advisor/pages/monitor/present/loadable'
import {LoadableNewClient} from '@d1g1t/advisor/pages/new-client/loadable'
import {RebalancePage} from '@d1g1t/advisor/pages/rebalance'
import {SecuritiesPage} from '@d1g1t/advisor/pages/securities'
import {ModelPortfolioListPage} from '@d1g1t/advisor/pages/security-model/list/loadable'
import {SecurityModelPage} from '@d1g1t/advisor/pages/security-model/loadable'
import {TrackMandatesPage} from '@d1g1t/advisor/pages/track-mandates/loadable'
import {TradeDirectivePage} from '@d1g1t/advisor/pages/trade-directive'
import {LoadableBuySellPage} from '@d1g1t/advisor/pages/trade/buy-sell/loadable'
import {ManageOrdersPage} from '@d1g1t/advisor/pages/trade/manage-orders'
import {TrackStrategiesPage} from '@d1g1t/advisor/pages/trade/track-portfolios/list/loadable'
import {RebalanceStrategiesPage} from '@d1g1t/advisor/pages/trade/track-portfolios/rebalance/loadable'

export const AuthenticatedRoutes: React.FC = function AuthenticatedRoutes() {
  const {redirecting} = useLoginRedirectIfLoggedOut()

  if (redirecting) {
    return null
  }

  return (
    <Frame>
      <AuthenticatedRoutesSwitch />
    </Frame>
  )
}

export const AuthenticatedRoutesSwitch: React.FC = () => {
  useDatadogConfig()

  const [firmPreconfiguration] = useApiQuery(
    FirmConfigurationEndpoints.preLogin(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  if (firmPreconfiguration.loading) {
    return null
  }

  return (
    <Switch>
      <Redirect
        exact
        from='/'
        to={
          firmPreconfiguration.data.advisorRedirectOnLogInPage || monitorPath()
        }
      />
      <Redirect exact from='/monitor' to={monitorPath()} />

      <Route path={monitorPath()}>
        <Overview />
      </Route>
      <Route exact path={drilldownPastPath()}>
        <MonitorDrilldownPast />
      </Route>
      <Route exact path={drilldownPresentPath()}>
        <MonitorDrilldownPresent />
      </Route>
      <Route exact path={drilldownFuturePath()}>
        <MonitorDrilldownFuture />
      </Route>
      <Route exact path={monitorActivityPath()}>
        <MonitorActivity />
      </Route>

      <Route path={ClientsLocations.path()}>
        <Switch>
          <Route
            path={[
              ClientsLocations.portfolios(),
              ClientsLocations.holdings(),
              ClientsLocations.households(),
              ClientsLocations.clients(),
              ClientsLocations.accounts()
            ]}
          >
            <LoadableClientListPage />
          </Route>
          <Route exact path={SingleClientLocations.new()}>
            <LoadableNewClient />
          </Route>
          <Route path={SingleClientLocations.path()}>
            <ManageClientLoadable />
          </Route>
          <Route path='*' component={AdvisorAppNotFoundPage} />
        </Switch>
      </Route>

      <Route
        exact
        path={[
          BillingLocations.billingGroups(),
          BillingLocations.billingGroupCreate()
        ]}
      >
        <BillingGroupsPage />
      </Route>

      <Route
        exact
        path={[
          BillingLocations.billingGroupSingleEdit(PARAMS.SELECTION),
          BillingLocations.billingGroupSingle(PARAMS.SELECTION)
        ]}
      >
        <BillingGroupSinglePage />
      </Route>

      <Route
        exact
        path={[BillingLocations.feeSpecs(), BillingLocations.feeSpecsCreate()]}
      >
        <FeeSpecsPage />
      </Route>

      <Route
        exact
        path={[
          BillingLocations.feeSpecSingleEdit(PARAMS.SELECTION),
          BillingLocations.feeSpecSingle(PARAMS.SELECTION)
        ]}
      >
        <FeeSpecSinglePage />
      </Route>

      <Route
        exact
        path={[
          BillingLocations.feeSchedules(),
          BillingLocations.feeScheduleCreate()
        ]}
      >
        <FeeSchedulesPage />
      </Route>
      <Route
        exact
        path={[
          BillingLocations.feeSharingRules(),
          BillingLocations.feeSharingRuleCreate()
        ]}
      >
        <FeeSharingRulesPage />
      </Route>
      <Route
        exact
        path={[
          BillingLocations.billingProperties(),
          BillingLocations.billingPropertiesCreate()
        ]}
      >
        <BillingPropertiesPage />
      </Route>
      <Route
        exact
        path={[BillingLocations.billingPropertiesSingleEdit(PARAMS.SELECTION)]}
      >
        <BillingPropertiesSinglePage />
      </Route>

      <Route
        exact
        path={[
          BillingLocations.feeScheduleSingle(PARAMS.SELECTION),
          BillingLocations.feeScheduleSingleEdit(PARAMS.SELECTION)
        ]}
      >
        <FeeScheduleSinglePage />
      </Route>
      <Route exact path={[BillingLocations.calculatedFees()]}>
        <ManageBillingPage />
      </Route>

      <Route
        exact
        path={BillingLocations.calculatedFeeSingle(PARAMS.SELECTION)}
      >
        <CalculatedFeesSinglePage />
      </Route>

      <Route path={TrackComplianceLocations.path()}>
        <TrackMandatesPage />
      </Route>

      <Route exact path={securitiesListPath()}>
        <SecuritiesPage />
      </Route>

      <Route path={TrackComplianceLocations.path()}>
        <TrackMandatesPage />
      </Route>

      <Route exact path={securitiesListPath()}>
        <SecuritiesPage />
      </Route>

      <Route
        exact
        path={[
          SecurityModelLocations.list(),
          SecurityModelLocations.portfolioCreate('/list')
        ]}
      >
        <ModelPortfolioListPage />
      </Route>
      <Route path={SecurityModelLocations.path()}>
        <SecurityModelPage />
      </Route>

      <Route exact path={ManageFundLocations.path()}>
        <LoadableFundListPage />
      </Route>
      <Route path={ManageFundLocations.overview()}>
        <ManageFundPage />
      </Route>

      <Route
        exact
        path={[
          InvestmentProgramLocations.path(),
          InvestmentProgramLocations.create(),
          InvestmentProgramLocations.copy(PARAMS.SELECTION)
        ]}
      >
        <InvestmentProgramListPage />
      </Route>
      <Route exact path={InvestmentProgramLocations.single(PARAMS.SELECTION)}>
        <InvestmentProgramSinglePage />
      </Route>

      <Route exact path={TradeLocations.rebalance()}>
        <RebalancePage />
      </Route>

      <Route exact path={TradeLocations.managePortfolios()}>
        <TrackStrategiesPage />
      </Route>
      <Route exact path={TradeLocations.rebalancePortfolios()}>
        <RebalanceStrategiesPage />
      </Route>

      <Route exact path={TradeLocations.bulkChangeAllocation()}>
        <TradeDirectivePage />
      </Route>
      <Route exact path={TradeLocations.manageOrders()}>
        <ManageOrdersPage />
      </Route>
      <Route
        path={[TradeLocations.equitiesEtfs(), TradeLocations.mutualFunds()]}
      >
        <LoadableBuySellPage />
      </Route>

      <Route exact path={ExploreLocations.compare()}>
        <CompareStrategiesLoadable />
      </Route>

      <Route path={DocumentsLocations.basePath}>
        <DocumentsPage />
      </Route>

      <Route path={DMSDocumentsLocations.basePath}>
        <DMSDocumentsPage />
      </Route>

      <Route
        exact
        path={[ContactsLocations.basePath, ContactsLocations.contactCreate()]}
      >
        <ContactListPage />
      </Route>

      <Route exact path={ContactsLocations.contactSingleEdit(PARAMS.SELECTION)}>
        <ContactSingleEditPage />
      </Route>
      <Route exact path={ContactsLocations.contactSingleView(PARAMS.SELECTION)}>
        <ContactSingleViewPage />
      </Route>

      <Route
        exact
        path={[
          ContactGroupsLocations.basePath,
          ContactGroupsLocations.contactGroupCreate()
        ]}
      >
        <ContactGroupListPage />
      </Route>

      <Route
        exact
        path={[
          ContactGroupsLocations.contactGroupSingleEdit(PARAMS.SELECTION),
          ContactGroupsLocations.contactGroupSingleView(PARAMS.SELECTION)
        ]}
      >
        <ContactGroupSinglePage />
      </Route>

      <Route
        exact
        path={[
          AdministrationLocations.userCreate(),
          AdministrationLocations.userList()
        ]}
      >
        <UsersPage />
      </Route>
      <Route exact path={AdministrationLocations.userSingle(PARAMS.SELECTION)}>
        <UserProfilePage />
      </Route>
      <Route exact path={AdministrationLocations.userGroups()}>
        <UserGroupsPage />
      </Route>
      <Route
        exact
        path={AdministrationLocations.userGroupsSingle(PARAMS.SELECTION)}
      >
        <UserGroupSinglePage />
      </Route>
      <Route
        exact
        path={[
          AdministrationLocations.clientManagementTeamList(),
          AdministrationLocations.clientManagementTeamCreate()
        ]}
      >
        <ClientManagementTeamListPage />
      </Route>
      <Route
        exact
        path={[
          AdministrationLocations.clientManagementRoles(),
          AdministrationLocations.clientManagementRolesCreate()
        ]}
      >
        <ClientManagementRolesPage />
      </Route>
      <Route
        exact
        path={[
          AdministrationLocations.clientAssignments(),
          AdministrationLocations.clientAssignmentsRolesEdit()
        ]}
      >
        <ClientAssignmentsListPage />
      </Route>
      <Route
        exact
        path={[
          AdministrationLocations.householdsList(),
          AdministrationLocations.householdsCreate()
        ]}
      >
        <HouseholdsListPage />
      </Route>
      <Route
        exact
        path={AdministrationLocations.householdSingle(PARAMS.SELECTION)}
      >
        <HouseholdSinglePage />
      </Route>
      <Route
        exact
        path={AdministrationLocations.userSettings(PARAMS.SELECTION)}
      >
        <UserProfilePage />
      </Route>

      <Route
        exact
        path={[
          AdministrationLocations.accountManagement(),
          AdministrationLocations.accountManagementCreate()
        ]}
      >
        <AccountManagementPage />
      </Route>

      <Route
        exact
        path={[
          AdministrationLocations.custodianAccountManagement(),
          AdministrationLocations.custodianAccountManagementCreate()
        ]}
      >
        <CustodianAccountManagementPage />
      </Route>

      <Route exact path={AdministrationLocations.accountGroups()}>
        <AccountGroupsPage />
      </Route>
      <Route
        exact
        path={AdministrationLocations.accountGroupsSingle(PARAMS.SELECTION)}
      >
        <AccountGroupSinglePage />
      </Route>
      <Route path={AdministrationLocations.transactionsList()}>
        <TransactionsListPage />
      </Route>
      <Route
        exact
        path={[
          AdministrationLocations.manageSecurities(),
          AdministrationLocations.manageSecuritiesCreate()
        ]}
      >
        <ManageSecurityListPage />
      </Route>

      <Route
        exact
        path={[
          AdministrationLocations.tradeRestrictions(),
          AdministrationLocations.tradeRestrictionsCreate()
        ]}
      >
        <TradeRestrictionsPage />
      </Route>

      <Route
        exact
        path={[
          AdministrationLocations.allocationExclusions(),
          AdministrationLocations.allocationExclusionsCreate()
        ]}
      >
        <AllocationExclusionsPage />
      </Route>

      <Route
        exact
        path={[
          AdministrationLocations.benchmarks(),
          AdministrationLocations.benchmarksCreate()
        ]}
      >
        <BenchmarksPage />
      </Route>

      <Route exact path={AdministrationLocations.tradeLocationRules()}>
        <TradeLocationRulesPage />
      </Route>

      <Route exact path={AdministrationLocations.fileProcessing()}>
        <FileProcessingListPage />
      </Route>

      <Route
        exact
        path={AdministrationLocations.fileProcessingSingle(PARAMS.SELECTION)}
      >
        <FileProcessingSinglePage />
      </Route>

      <Route exact path={AdministrationLocations.datasetsList()}>
        <DatasetsListPage />
      </Route>

      <Route
        exact
        path={[
          AdministrationLocations.datasetsCreate(),
          AdministrationLocations.datasetsSingle(PARAMS.SELECTION)
        ]}
      >
        <DatasetsSinglePage />
      </Route>

      <Route exact path={AdministrationLocations.collectionsList()}>
        <CollectionsListPage />
      </Route>

      <Route
        exact
        path={[
          AdministrationLocations.collectionsCreate(),
          AdministrationLocations.collectionsSingle(PARAMS.SELECTION)
        ]}
      >
        <CollectionsSinglePage />
      </Route>

      <Route exact path={GlobalFilterLocations.path()}>
        <GlobalFiltersListPage />
      </Route>
      <Route
        exact
        path={[
          GlobalFilterLocations.single(PARAMS.SELECTION),
          GlobalFilterLocations.create()
        ]}
      >
        <GlobalFiltersSinglePage />
      </Route>
      <Route path={ENTITY_REDIRECT_PATH}>
        <EntityRedirect />
      </Route>
      <Route path='*'>
        <AdvisorAppNotFoundPage />
      </Route>
    </Switch>
  )
}
