import React from 'react'

import {TRADEORDER_OPERATION} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'

import {Flex} from '@d1g1t/shared/components/flex'
import {MULTIPLE_VALUES} from '@d1g1t/shared/components/form-field/constants'
import {ValueLabelSelectField} from '@d1g1t/shared/components/form-field/value-select-field'

import {
  BUY_SELL_ALL_OPTIONS,
  BUY_SELL_OPTIONS,
  BUY_SELL_SWITCH_OPTIONS,
  ITradeSelectFieldProps
} from './typings'

import * as css from './style.scss'

export const TradeSelectField: React.FC<ITradeSelectFieldProps> = (props) => {
  const tradeOptions = (() => {
    if (props.buySellOnly) {
      return BUY_SELL_OPTIONS
    }

    if (props.showSwitch) {
      return BUY_SELL_SWITCH_OPTIONS
    }

    return BUY_SELL_ALL_OPTIONS
  })()

  return (
    <ValueLabelSelectField
      label='Operation'
      name={props.name}
      disabled={props.disabled}
      valueLabelSelectProps={{
        'data-testid': 'select-trade-operation',
        options: tradeOptions,
        renderValue: (operation, label) => {
          if (operation === MULTIPLE_VALUES) {
            return '{Multiple Values}'
          }

          if (Object.values(TRADEORDER_OPERATION).includes(operation)) {
            const [dotColor, textClass] = (() => {
              switch (operation) {
                case TRADEORDER_OPERATION.BUY:
                  return [css.backgroundBuy, css.buyText]
                case TRADEORDER_OPERATION.SELL:
                case TRADEORDER_OPERATION.SELL_ALL:
                  return [css.backgroundSell, css.sellText]
                case TRADEORDER_OPERATION.SWITCH:
                case TRADEORDER_OPERATION.SWITCH_ALL:
                  return [css.backgroundSwitch, css.switchText]
                default:
                  return ['', '']
              }
            })()

            return (
              <Flex alignCenter>
                <div className={classNames(css.dot, dotColor)} />
                <span className={textClass}>{label}</span>
              </Flex>
            )
          }

          return label || operation
        }
      }}
    />
  )
}
