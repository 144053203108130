import React from 'react'

import {CHART_VALUE_TYPE, IChartTableCategory} from '@d1g1t/api/models'

import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'
import {CATEGORY_IDS} from '@d1g1t/lib/standard-response/constants'

import {Flex} from '@d1g1t/shared/components/flex'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {Text} from '@d1g1t/shared/components/typography'
import {FormattedChartValue} from '@d1g1t/shared/wrappers/formatter'

import css from './style.scss'

interface IStandardStatProps extends React.HTMLAttributes<HTMLDivElement> {
  category: IChartTableCategory
  value: number | string
  secondary?: boolean
}

export const StandardStat: React.SFC<IStandardStatProps> = ({
  category,
  value,
  secondary,
  ...props
}) => {
  const decimalPlaces: Nullable<number> = [
    CHART_VALUE_TYPE.DECIMAL,
    CHART_VALUE_TYPE.DECIMAL_LONG,
    CHART_VALUE_TYPE.INTEGER,
    CHART_VALUE_TYPE.MONEY_ABBREVIATED
  ].includes(category.valueType as CHART_VALUE_TYPE)
    ? 0
    : null

  return (
    <div data-testid={`container-standard-stat-${category.id}`} {...props}>
      <Flex column>
        <Flex>
          <Text className={css.label}>{category.name}</Text>
        </Flex>
        <Flex justifyFlexStart alignStart>
          <Text semiBold className={css.placeholder}>
            {value ? (
              <FormattedChartValue
                value={value}
                valueType={category.valueType}
                valueOptions={{
                  delta: category.options?.hidden,
                  decimals: decimalPlaces
                }}
              />
            ) : (
              <Text>-</Text>
            )}
          </Text>
        </Flex>
      </Flex>
    </div>
  )
}

export interface IStandardStatListProps
  extends React.HTMLAttributes<HTMLDivElement> {
  response: StandardResponse
  secondary?: boolean
  nameOverrides?: string[]
}

const getValue = ({
  category,
  stringValueItem,
  numberValueItem
}: {
  category: IChartTableCategory
  stringValueItem: StandardResponseItem
  numberValueItem: StandardResponseItem
}): number | string => {
  if (!stringValueItem || !numberValueItem) {
    return ''
  }

  const item =
    category.valueType === 'string' ? stringValueItem : numberValueItem

  return item.getValue(category.id)
}

export const StandardStatList: React.SFC<IStandardStatListProps> = ({
  response,
  secondary,
  nameOverrides,
  ...props
}) => {
  const stats: React.ReactElement<IStandardStatProps>[] = []

  if (response.isEmpty()) {
    return (
      <>
        <Text>No Stats Data</Text>
        <Spacer vertical sm />
      </>
    )
  }

  const items = response.allItems()

  const numberValueItem = items[0]
  const stringValueItem = items[0].isTotalItem ? items[1] : items[0]
  let statIndex = 0

  for (const category of response.categories) {
    if (
      category.options?.hidden ||
      Object.values(CATEGORY_IDS).includes(category.id as CATEGORY_IDS)
    ) {
      // Don't show hidden columns or non-metric categories
      continue
    }

    if (nameOverrides && nameOverrides[statIndex]) {
      category.name = nameOverrides[statIndex]
      statIndex += 1
    }

    const value = getValue({
      category,
      stringValueItem,
      numberValueItem
    })

    stats.push(
      <StandardStat
        key={category.id}
        category={category}
        value={value}
        secondary={secondary}
        {...props}
      />
    )
  }
  return stats.length > 0 ? (
    <>{stats}</>
  ) : (
    <>
      <Text>No Stats Data</Text>
      <Spacer vertical sm />
    </>
  )
}
