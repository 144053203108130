import React, {useEffect, useState} from 'react'

import {useApi} from 'fairlight'

import {
  ITradeOrderPreviewResponse,
  TradingOrderEndpoints
} from '@d1g1t/api/endpoints'
import {
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  TRADEORDER_OPERATION,
  TRADEORDER_TYPE
} from '@d1g1t/api/models'

import {classNames} from '@d1g1t/lib/class-names'
import {SUPPORTED_CURRENCY} from '@d1g1t/lib/currency'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {FlexGridItem} from '@d1g1t/shared/components/flex'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {useCalculationSettings} from '@d1g1t/shared/wrappers/calculation-settings'
import {useNumberFormatter} from '@d1g1t/shared/wrappers/formatter'

import {StatVertical} from '@d1g1t/advisor/components/stat-vertical'
import {qtyChangeIsNumber} from '@d1g1t/advisor/containers/trade-order-form/lib'

import {ITransformPayload, transformPreviewRequestPayload} from './lib'

import * as tradePanelCss from './style.scss'

export interface ITradeStatsProps extends Omit<ITransformPayload, 'asOfDate'> {
  tradeType: FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE
  headerTitle: string
  instrumentName: string
}

export const TradeStats: React.FC<ITradeStatsProps> = ({
  headerTitle,
  instrumentName,
  tradeType,
  ...requestBody
}) => {
  const [{date}] = useCalculationSettings()

  const api = useApi()

  const [preview, setPreview] = useState<{
    data: ITradeOrderPreviewResponse
    loading: boolean
  }>({data: null, loading: false})

  const {
    account,
    instrument,
    operation,
    quantityChange,
    isSellAll,
    expectedPrice,
    type
  } = requestBody

  const previewPayload = {...requestBody, asOfDate: date.date}

  useEffect(() => {
    /**
     * This will avoid that the latest request be overwritten
     * by a older request that took longer to complete
     */
    let requestCancelled = false

    ;(async () => {
      let previewResponse: ITradeOrderPreviewResponse = null

      const hasQtyValue = (() => {
        if (
          [TRADEORDER_OPERATION.SELL, TRADEORDER_OPERATION.SELL_ALL].includes(
            operation
          )
        ) {
          return (
            isSellAll ||
            (qtyChangeIsNumber(quantityChange) && quantityChange !== 0)
          )
        }
        return !!quantityChange
      })()

      if (
        account &&
        instrument &&
        hasQtyValue &&
        // Checks if there is an expected price entered, but it is not required:
        // - if the instrument is an equity
        // - if the trade order type is a limit order
        // Conditions are used to prevent the api request below which will
        // cause an error if there is not an expected price in the payload
        !!(
          expectedPrice ||
          tradeType !== FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.EQUITY ||
          type === TRADEORDER_TYPE.LIMIT
        )
      ) {
        setPreview((s) => ({...s, loading: true}))

        /**
         * Necessary to use `api.request` instead of `useApiQuery`
         * because this is a endpoint with a `POST` method
         */
        previewResponse = await api.request(
          TradingOrderEndpoints.preview(
            transformPreviewRequestPayload(previewPayload)
          )
        )
      }

      if (!requestCancelled) {
        setPreview({data: previewResponse, loading: false})
      }
    })()

    return () => {
      requestCancelled = true
    }
  }, Object.values(previewPayload))

  const accountCurrency = extractIdFromUrl(
    preview.data?.accountCurrency
  ) as SUPPORTED_CURRENCY

  const instrumentCurrency = extractIdFromUrl(
    preview.data?.instrumentCurrency
  ) as SUPPORTED_CURRENCY

  const currencyFormatter = useNumberFormatter({
    style: 'currency',
    currencyDisplay: 'code',
    decimalPlaces: 2,
    ...(accountCurrency ? {accountCurrency} : {})
  })
  const currencyAllowZeroFormatter = useNumberFormatter({
    style: 'currency',
    currencyDisplay: 'code',
    decimalPlaces: 2,
    allowZero: true,
    ...(accountCurrency ? {accountCurrency} : {})
  })

  const showFx =
    instrumentCurrency &&
    accountCurrency !== instrumentCurrency &&
    !!preview.data?.fxRate

  return (
    <FlexGridItem
      col='1/3'
      className={classNames(tradePanelCss.tradeSections, {
        [tradePanelCss.buyStats]: operation === TRADEORDER_OPERATION.BUY,
        [tradePanelCss.sellStats]: [
          TRADEORDER_OPERATION.SELL,
          TRADEORDER_OPERATION.SELL_ALL
        ].includes(operation),
        [tradePanelCss.switchStats]: [
          TRADEORDER_OPERATION.SWITCH,
          TRADEORDER_OPERATION.SWITCH_ALL
        ].includes(operation)
      })}
    >
      <Spacer xs />
      <LoadingContainer loading={preview.loading}>
        <StatVertical
          noSpaceUnderStatName
          list={[
            {
              name: headerTitle,
              value: instrumentName || '-',
              strong: true
            },
            {
              name: 'Expected Trade Value',
              value: currencyFormatter.format(preview.data?.expectedTradeValue),
              strong: true
            },
            {
              name: 'Cash Available After Trade',
              value: currencyAllowZeroFormatter.format(
                preview.data?.fundsAvailablePostTrade
              ),
              strong: true
            },
            ...(showFx
              ? [
                  {
                    name: `FX as of ${date.date}`,
                    value: `${preview.data.fxRate} ${accountCurrency} - ${instrumentCurrency}`,
                    strong: true
                  }
                ]
              : [])
          ]}
        />
      </LoadingContainer>
    </FlexGridItem>
  )
}
