import React, {forwardRef, RefObject} from 'react'

import produce from 'immer'

import {useDeepMemo, useStableCallback} from '@d1g1t/lib/hooks'

import {
  FormControl,
  IFormControlProps
} from '@d1g1t/shared/components/mui/form-control'
import {FormHelperText} from '@d1g1t/shared/components/mui/form-helper-text'
import {InputLabel} from '@d1g1t/shared/components/mui/input-label'
import {
  IValueLabelSelectProps,
  ValueLabelSelect
} from '@d1g1t/shared/components/value-label-select'

import {MULTIPLE_VALUES} from '../constants'
import {useFormFieldControl, useMuiLabelWidth} from '../hook'
import {getFormFieldErrorState} from '../lib'
import {IFormFieldProps} from '../typings'

export interface IValueLabelSelectFieldProps extends IFormFieldProps {
  disableFullWidth?: boolean
  disableHelperText?: boolean
  disabled?: boolean
  hideLabel?: boolean
  smallHeight?: boolean
  /**
   * Transforms the ValueLabelSelect modal origin to be on the left of the element
   */
  originRight?: boolean
  valueLabelSelectProps: Omit<
    IValueLabelSelectProps,
    'smallHeight' | 'value' | 'onChange' | 'onBlur' | 'name' | 'disabled'
  > & {'data-testid'?: string}
  FormControlProps?: Partial<IFormControlProps>
}

/**
 * `formik`-compatible `Select` field
 */
export const ValueLabelSelectField: React.FC<IValueLabelSelectFieldProps> =
  forwardRef((props, ref: RefObject<HTMLDivElement>) => {
    const {label, hideLabel: labelHidden} = props

    const [field, meta] = useFormFieldControl(props)
    const {hasError, errorText} = getFormFieldErrorState(meta)

    const [labelWidth, labelRef] = useMuiLabelWidth()

    const id = props.id || props.name
    const errorTextId = `${id}-error-text`

    const handleChange = useStableCallback(
      (value, child, event: React.ChangeEvent) => field.onChange(event)
    )
    const handleBlur = useStableCallback(field.onBlur)

    const isMultipleValues = field.value === MULTIPLE_VALUES

    return useDeepMemo(() => {
      return (
        <FormControl
          error={hasError}
          variant='outlined'
          fullWidth={!props.disableFullWidth}
          disabled={props.disabled}
          {...props.FormControlProps}
          ref={ref}
        >
          <InputLabel
            smallHeight={props.smallHeight}
            innerRef={labelRef}
            htmlFor={id}
            hidden={labelHidden}
          >
            {label}
          </InputLabel>
          <ValueLabelSelect
            id={id}
            originRight={props.originRight}
            aria-describedby={errorTextId}
            labelWidth={labelHidden ? 0 : labelWidth}
            {...produce(props.valueLabelSelectProps, (draft) => {
              if (!isMultipleValues) {
                return
              }

              draft.options = [
                {value: MULTIPLE_VALUES, label: '{Multiple Values}'},
                ...draft.options
              ]

              draft.style = {
                fontWeight: 'bold',
                fontStyle: 'italic'
              }
            })}
            name={field.name}
            value={field.value}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={props.disabled}
            smallHeight={props.smallHeight}
          />
          {!props.disableHelperText && (
            <FormHelperText id={errorTextId}>{errorText}</FormHelperText>
          )}
        </FormControl>
      )
    }, [props, labelWidth, field.value, field.name, meta, ref])
  })
