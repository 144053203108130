import * as Yup from 'yup'

import {TRADEORDER_OPERATION} from '@d1g1t/api/models'

import {MUTUAL_FUND_FIELD_NAMES, QTY_TYPE} from './typings'

export const MUTUAL_FUND_VALIDATION_SCHEMA = Yup.object({
  [MUTUAL_FUND_FIELD_NAMES.instrumentUrl]: Yup.string()
    .nullable()
    .required()
    .label('Fund Code'),
  [MUTUAL_FUND_FIELD_NAMES.fundCodeUrl]: Yup.string()
    .label('Switch into Fund Code')
    .when(MUTUAL_FUND_FIELD_NAMES.operation, {
      is: TRADEORDER_OPERATION.SWITCH,
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().nullable()
    })
    .test(
      'fund_info',
      'This Fund does not have Fund Code information',
      function validateinstrumentUrl(value) {
        const {fundCode, fundCodeUrl} = this.parent
        if (!fundCode && fundCodeUrl) {
          return false
        }
        return true
      }
    ),
  [MUTUAL_FUND_FIELD_NAMES.operation]: Yup.string()
    .required()
    .label('Operation'),
  [MUTUAL_FUND_FIELD_NAMES.fundCode]: Yup.string()
    .nullable()
    .label('Fund Code'),
  [MUTUAL_FUND_FIELD_NAMES.qty]: Yup.mixed()
    .when(
      [MUTUAL_FUND_FIELD_NAMES.isSellAll, MUTUAL_FUND_FIELD_NAMES.qtyType],
      {
        is: (isSellAll: boolean, qtyType: QTY_TYPE) =>
          isSellAll || qtyType === QTY_TYPE.DSC_FREE,
        then: Yup.string().nullable(),
        otherwise: Yup.number().nullable().required().min(0.000001)
      }
    )
    .required()
    .label('Quantity'),
  [MUTUAL_FUND_FIELD_NAMES.qtyType]: Yup.string()
    .required()
    .label('Quantity Type'),
  [MUTUAL_FUND_FIELD_NAMES.notes]: Yup.string(),
  [MUTUAL_FUND_FIELD_NAMES.instructions]: Yup.string(),
  [MUTUAL_FUND_FIELD_NAMES.dividendTreatment]: Yup.string()
    .nullable()
    .label('Dividend Treatment'),
  [MUTUAL_FUND_FIELD_NAMES.type]: Yup.string().required().label('Order Type'),
  [MUTUAL_FUND_FIELD_NAMES.commission]: Yup.number()
    .nullable()
    .label('Commission'),
  [MUTUAL_FUND_FIELD_NAMES.commissionType]: Yup.string(),
  [MUTUAL_FUND_FIELD_NAMES.proStatus]: Yup.string(),
  [MUTUAL_FUND_FIELD_NAMES.isSolicited]: Yup.boolean()
})
