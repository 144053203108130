import {ITradeOrder} from '@d1g1t/api/models'

export enum BULK_EDIT_ORDER_FIELD_NAMES {
  instrumentUrl = 'instrumentUrl',
  accountUrl = 'accountUrl',
  operation = 'operation',
  broker = 'broker',
  commission = 'commission',
  commissionType = 'commissionType',
  notes = 'notes',
  qty = 'qty',
  isInsider = 'isInsider',
  traded = 'traded',
  settled = 'settled'
}

export interface IBulkEditOrderFormValues {
  [BULK_EDIT_ORDER_FIELD_NAMES.instrumentUrl]: ITradeOrder['instrument']
  [BULK_EDIT_ORDER_FIELD_NAMES.accountUrl]: string
  [BULK_EDIT_ORDER_FIELD_NAMES.operation]: ITradeOrder['operation']
  [BULK_EDIT_ORDER_FIELD_NAMES.broker]: ITradeOrder['broker']
  [BULK_EDIT_ORDER_FIELD_NAMES.commission]: ITradeOrder['commission']
  [BULK_EDIT_ORDER_FIELD_NAMES.commissionType]: ITradeOrder['commissionType']
  [BULK_EDIT_ORDER_FIELD_NAMES.notes]: ITradeOrder['notes']
  [BULK_EDIT_ORDER_FIELD_NAMES.qty]: ITradeOrder['qty']
  [BULK_EDIT_ORDER_FIELD_NAMES.isInsider]: ITradeOrder['isInsider']
  [BULK_EDIT_ORDER_FIELD_NAMES.traded]: ITradeOrder['traded']
  [BULK_EDIT_ORDER_FIELD_NAMES.settled]: ITradeOrder['settled']
}

export const IS_INSIDER_DROPDOWN_OPTIONS = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
]
