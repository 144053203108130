import React, {useMemo} from 'react'

import {useApi} from 'fairlight'
import {isEmpty} from 'lodash'
import {useRecoilCallback} from 'recoil'

import DownloadIcon from '@material-ui/icons/CloudDownload'

import {TradingApprovalsEndpoints} from '@d1g1t/api/endpoints'
import {
  ALL_MODELS,
  ITradingApprovalsChangedValue,
  ITradingApprovalsInstrumentInput
} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {
  calculationDateFromDateString,
  useCalculationSettings
} from '@d1g1t/shared/wrappers/calculation-settings'
import {downloadBase64} from '@d1g1t/shared/wrappers/download-base-64'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'

import {PageTitleBar} from '@d1g1t/advisor/containers/page-title-bar'
import {Rebalance} from '@d1g1t/advisor/containers/rebalance'
import {rebalanceApprovalReportSelector} from '@d1g1t/advisor/containers/rebalance/atoms'
import {SelectEntities} from '@d1g1t/advisor/containers/select-entities'

import {groupByAccount} from './lib'

export const RebalancePage: React.FC = () => {
  const api = useApi()
  const {showSnackbar} = useSnackbar()
  const {handleUnexpectedError} = useErrorHandler()

  const [calculationSettings] = useCalculationSettings()
  const {firmConfiguration} = useFirmConfiguration()

  const staticCalculationSettings = useMemo(() => {
    if (!firmConfiguration.data) {
      return null
    }
    return {
      date: calculationDateFromDateString(
        firmConfiguration.data.latestDataAvailable
      ),
      currency: extractIdFromUrl(firmConfiguration.data.firm.currency)
    }
  }, [firmConfiguration.data])

  const handleGenerateApprovalReport = useRecoilCallback(
    ({snapshot}) =>
      async () => {
        const rebalanceApprovalReport = await snapshot.getPromise(
          rebalanceApprovalReportSelector
        )

        if (isEmpty(rebalanceApprovalReport[1].data?.items)) {
          showSnackbar({
            variant: 'error',
            message: 'Missing recommended trades'
          })
          return
        }

        const recommendedTradesData = groupByAccount(
          rebalanceApprovalReport[1].data.items
        )
        // do not make api call if there are no recommended trades

        try {
          const accountApprovalsArr = await Promise.all(
            Object.entries(recommendedTradesData).map(
              ([accountUrl, instrumentsInputWithQuantityChange]) => {
                const instruments: ITradingApprovalsInstrumentInput[] = []
                const targetValues: ITradingApprovalsChangedValue[] = []

                for (const {
                  quantityChange,
                  ...instrumentInput
                } of instrumentsInputWithQuantityChange) {
                  instruments.push(instrumentInput)
                  targetValues.push({
                    quantityChange,
                    instrument: instrumentInput.instrument
                  })
                }

                return api.request(
                  TradingApprovalsEndpoints.account({
                    instruments,
                    accountsData: [{targetValues, account: accountUrl}],
                    asOfDate: calculationSettings.date.date
                  })
                )
              }
            )
          )

          for (const accountApprovals of accountApprovalsArr) {
            for (const approvalFile of accountApprovals) {
              downloadBase64(approvalFile)
            }
          }

          showSnackbar({
            variant: 'success',
            message: 'Account approval file(s) created successfully'
          })
        } catch (error) {
          handleUnexpectedError(
            error,
            'Could not generate account approval file(s).'
          )
        }
      }
  )

  return (
    <div>
      <PageTitleBar
        title='Rebalance'
        noBottomMargin
        showBackToListNavigationButton
        showSelectEntitiesPageNavigation
      />
      {calculationSettings && staticCalculationSettings && (
        <SelectEntities
          staticCalculationSettings={staticCalculationSettings}
          EntityChipProps={{
            filterByAccountsOnly: true,
            disableRuleBasedFiltering: true
          }}
          SearchProps={{
            searchBy: [
              ALL_MODELS.INDIVIDUAL,
              ALL_MODELS.HOUSEHOLD,
              ALL_MODELS.INVESTMENTMANDATE,
              ALL_MODELS.CORPORATION,
              ALL_MODELS.FOUNDATION,
              ALL_MODELS.TRUST
            ],
            placeholder: 'Search for clients'
          }}
          BarProps={{
            stickyTopBar: true
          }}
          overflowMenuOptions={[
            {
              icon: <DownloadIcon />,
              label: 'Generate Account Approval Report',
              onClick: handleGenerateApprovalReport
            }
          ]}
        >
          <Rebalance />
        </SelectEntities>
      )}
    </div>
  )
}
