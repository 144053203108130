import React, {useEffect, useMemo, useState} from 'react'

import {useApiQuery} from 'fairlight'
import {FormikHelpers, FormikProvider, setIn, useFormik} from 'formik'
import produce from 'immer'
import * as Yup from 'yup'

import Alert from '@material-ui/lab/Alert'

import {
  Formats,
  GroupingCriteriaEndpoints,
  MetricEndpoints,
  Types
} from '@d1g1t/api/endpoints'
import {
  FIRMCONFIGURATION_LOOK_THROUGH_TYPE,
  GROUPING_NODE_TYPE,
  IGrouping
} from '@d1g1t/api/models'

import {parseFilterValue} from '@d1g1t/lib/filters'
import {useIsFirstRender} from '@d1g1t/lib/hooks'
import {filterItemSchema} from '@d1g1t/lib/rule-filter-validation'

import {Flex} from '@d1g1t/shared/components/flex'
import {FormActions} from '@d1g1t/shared/components/form-actions'
import {FormUnsavedPrompt} from '@d1g1t/shared/components/form-unsaved-prompt'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {LabeledRadio} from '@d1g1t/shared/components/mui/radio'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H2, P} from '@d1g1t/shared/components/typography'
import {FilterEditorContainer} from '@d1g1t/shared/containers/filter-editor'
import {nodeIdFromFilterItem} from '@d1g1t/shared/containers/filter-editor/lib'
import {DisplayData} from '@d1g1t/shared/containers/view-options/components/display-options/display-data'
import {MetricsTab} from '@d1g1t/shared/containers/view-options/components/display-options/metrics-tab'
import {IViewOptionsConfiguration} from '@d1g1t/shared/containers/view-options/typings'
import {useFilterCriteria} from '@d1g1t/shared/wrappers/filter-criteria'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'

import {AdminOptions} from './admin-options'
import {DISPLAY_OPTIONS_TAB, DisplayOptionsTabs} from './display-options-tabs'
import {GroupingsList} from './groupings-list'
import {IDisplayOptions, IDisplayOptionsProps} from './typings'

import * as css from './style.scss'

const getStartupTab = (
  config: IViewOptionsConfiguration
): DISPLAY_OPTIONS_TAB => {
  if (config.controlFilter) {
    return DISPLAY_OPTIONS_TAB.ROWS
  }
  if (config.metrics) {
    return DISPLAY_OPTIONS_TAB.METRICS
  }

  if (config.groups) {
    return DISPLAY_OPTIONS_TAB.GROUP
  }

  if (config.filters) {
    return DISPLAY_OPTIONS_TAB.FILTER
  }

  if (
    config.showDisplayData ||
    config.showInternalTransactionsControl ||
    config.showTradesAsUngroupedItems
  ) {
    return DISPLAY_OPTIONS_TAB.DISPLAY_DATA
  }
}

interface IDisplayOptionsFormValues extends IDisplayOptions {}

enum VIEW_OPTIONS_FIELD_NAMES {
  displayData = 'displayData',
  groups = 'groups',
  filter = 'filter',
  metrics = 'metrics',
  isHierarchicalGrouping = 'isHierarchicalGrouping',
  isGlobal = 'isGlobal',
  controlFilter = 'controlFilter',
  title = 'title',
  isAvailableToExternalProfiles = 'isAvailableToExternalProfiles',
  investorPortalSections = 'investorPortalSections'
}

enum FILTER_FIELD_NAMES {
  items = 'items'
}

/**
 * Renders the four view tabs.
 */
export const DisplayOptions: React.FC<IDisplayOptionsProps> =
  function DisplayOptions(props) {
    const [filterCriteria] = useFilterCriteria(
      props.configuration.filters?.types,
      props.configuration.filters?.hideOptions
    )

    const initialValues: IDisplayOptionsFormValues = useMemo(() => {
      const filter = (() => {
        if (!props.displayOptions.filter || !filterCriteria.data) {
          return null
        }

        return {
          ...props.displayOptions.filter,
          items: props.displayOptions.filter.items.map((item) => {
            const {paths} = filterCriteria.data
            const {type, format} = paths[nodeIdFromFilterItem(item)]

            const isNumber = [
              Formats.int,
              Formats.decimal,
              Formats.percentage
            ].includes(format)

            const isMultiple = [
              Types.oneOf,
              Types.dateBetween,
              Types.in,
              Types.between
            ].includes(type)

            return {
              ...item,
              value: parseFilterValue(item.value as string, {
                isNumber,
                isMultiple
              })
            }
          })
        }
      })()

      const controlFilter = (() => {
        if (!props.configuration.controlFilter) {
          return
        }

        return props.displayOptions.displayData?.controlFilter ?? 0
      })()

      return {
        title: props.viewTitle,
        tableKey: props.displayOptions.tableKey,
        filter,
        displayData: {
          ...props.displayOptions.displayData,
          controlFilter
        },
        groups: props.displayOptions.groups || null,
        metrics: props.displayOptions.metrics,
        isGlobal: props.isGlobal,
        isAvailableToExternalProfiles: props.isAvailableToExternalProfiles,
        investorPortalSections: props.investorPortalSections
      }
    }, [
      props.displayOptions,
      filterCriteria.data,
      props.isGlobal,
      props.isAvailableToExternalProfiles,
      props.investorPortalSections
    ])

    const handleSubmit = (
      values: IDisplayOptionsFormValues,
      formikBag: FormikHelpers<IDisplayOptionsFormValues>
    ) => {
      props.onSetDisplayOptions(values, formikBag.setSubmitting)
    }

    const validationSchema = useMemo(
      () =>
        Yup.object({
          [VIEW_OPTIONS_FIELD_NAMES.groups]: Yup.array().min(
            props.minimumRequiredGroups ?? 0
          ),
          [VIEW_OPTIONS_FIELD_NAMES.filter]: Yup.object({
            [FILTER_FIELD_NAMES.items]: Yup.array().of(
              Yup.lazy(filterItemSchema(filterCriteria.data))
            )
          })
        }),
      [filterCriteria.data, props.minimumRequiredGroups]
    )

    const formik = useFormik<IDisplayOptionsFormValues>({
      initialValues,
      enableReinitialize: true,
      onSubmit: handleSubmit,
      validationSchema
    })

    const isFirstRender = useIsFirstRender()

    // Reset to empty metrics when user selects between manage portfolios
    // for mandates or accounts.
    useEffect(() => {
      if (isFirstRender) {
        return
      }

      formik.setFieldValue(VIEW_OPTIONS_FIELD_NAMES.metrics, [])
      formik.setFieldValue(VIEW_OPTIONS_FIELD_NAMES.filter, {
        items: [],
        joinOperator: 'OR'
      })
    }, [formik.values.displayData.controlFilter])

    useEffect(() => {
      formik.setFieldValue(
        VIEW_OPTIONS_FIELD_NAMES.title,
        props.updatedViewTitleToBeSaved
      )
    }, [props.updatedViewTitleToBeSaved])

    const [selectedTab, setSelectedTab] = useState<DISPLAY_OPTIONS_TAB>(
      props.selectedTab || getStartupTab(props.configuration)
    )

    const [
      investmentProgramGroupingSelected,
      setInvestmentProgramGroupingSelected
    ] = useState<boolean>(formik.values.displayData.isHierarchicalGrouping)

    const [availableMetrics] = useApiQuery(MetricEndpoints.tree(), {
      fetchPolicy: 'cache-first'
    })

    const [groupings] = useApiQuery(GroupingCriteriaEndpoints.list(), {
      fetchPolicy: 'cache-first'
    })

    const {firmConfiguration} = useFirmConfiguration()

    const firmlookThroughDisabled =
      firmConfiguration.data.lookThroughType ===
      FIRMCONFIGURATION_LOOK_THROUGH_TYPE.DISABLED

    const handleTabSelected = (selectedTab: DISPLAY_OPTIONS_TAB) => {
      setSelectedTab(selectedTab)
    }

    const getAvailableGroupings = (): IGrouping[] => {
      let availableGroupings = groupings.data.results.filter(
        (grouping) => grouping.isVisible
      )

      if (!firmConfiguration.data?.useCustodianAccounts) {
        // hide all custodian account groupings if not visible
        availableGroupings = availableGroupings.filter(
          (grouping) =>
            grouping.nodeType !== GROUPING_NODE_TYPE.CUSTODIAN_ACCOUNT
        )
      }

      if (props.configuration.groups?.types) {
        return availableGroupings.filter((group) =>
          props.configuration.groups.types.includes(group.nodeType)
        )
      }

      if (props.configuration.groups?.groupsFilterPredicate) {
        return availableGroupings.filter(
          props.configuration.groups.groupsFilterPredicate
        )
      }

      return availableGroupings
    }

    const renderSelectedTab = () => {
      switch (selectedTab) {
        case DISPLAY_OPTIONS_TAB.GROUP:
          return (
            <>
              <H2>Groups</H2>
              <P mediumLight>
                Groups allow customization of the subtotal and roll up behaviour
                for the data. The top of the list are the top level group; drag
                to reorder as needed.
              </P>
              {props.configuration.groups
                .showInvestmentProgramGroupingControl && (
                <Flex alignCenter>
                  <LabeledRadio
                    label='Investment Hierarchy'
                    color='primary'
                    onChange={() => {
                      setInvestmentProgramGroupingSelected(true)
                      formik.setFieldValue(
                        VIEW_OPTIONS_FIELD_NAMES.displayData,
                        setIn(
                          formik.values.displayData,
                          VIEW_OPTIONS_FIELD_NAMES.isHierarchicalGrouping,
                          true
                        )
                      )
                    }}
                    checked={!!formik.values.displayData.isHierarchicalGrouping}
                  />
                  <LabeledRadio
                    label='Dynamic Grouping'
                    color='primary'
                    onChange={() => {
                      setInvestmentProgramGroupingSelected(false)
                      formik.setFieldValue(
                        VIEW_OPTIONS_FIELD_NAMES.displayData,
                        setIn(
                          formik.values.displayData,
                          VIEW_OPTIONS_FIELD_NAMES.isHierarchicalGrouping,
                          false
                        )
                      )
                    }}
                    checked={!formik.values.displayData.isHierarchicalGrouping}
                  />
                </Flex>
              )}
              {!investmentProgramGroupingSelected && (
                <GroupingsList
                  availableItems={getAvailableGroupings()}
                  selectedItems={formik.values.groups}
                  onUpdateSelectedItems={(groups) =>
                    formik.setFieldValue(
                      VIEW_OPTIONS_FIELD_NAMES.groups,
                      groups
                    )
                  }
                  data-testid='groupings-list'
                />
              )}
            </>
          )
        case DISPLAY_OPTIONS_TAB.ROWS: {
          return (
            <Flex alignCenter>
              <P>List by:</P>
              <Spacer xxs vertical />
              {props.configuration.controlFilter.map(
                (controlFilterOption, index) => (
                  <LabeledRadio
                    key={controlFilterOption.label}
                    label={controlFilterOption.label}
                    color='primary'
                    onChange={() => {
                      formik.setFieldValue(
                        VIEW_OPTIONS_FIELD_NAMES.displayData,
                        setIn(
                          formik.values.displayData,
                          VIEW_OPTIONS_FIELD_NAMES.controlFilter,
                          index
                        )
                      )
                    }}
                    checked={formik.values.displayData.controlFilter === index}
                  />
                )
              )}
            </Flex>
          )
        }
        case DISPLAY_OPTIONS_TAB.FILTER: {
          return (
            <>
              <H2>Filters</H2>
              <P mediumLight>
                Filters can be created to restrict the data presented in the
                view.
              </P>
              <FilterEditorContainer
                name={VIEW_OPTIONS_FIELD_NAMES.filter}
                types={
                  props.configuration.controlFilter?.[
                    formik.values.displayData.controlFilter
                  ].filterTypes || props.configuration.filters?.types
                }
                hideOptions={props.configuration.filters.hideOptions}
              />
            </>
          )
        }
        case DISPLAY_OPTIONS_TAB.METRICS: {
          return (
            <>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item xs={6}>
                  <H2>Columns</H2>
                  <P mediumLight>
                    Customize which columns are present in the table by
                    selecting metrics.
                  </P>
                </Grid>
                <Grid item xs={6}>
                  {props.isAvailableToExternalProfiles && (
                    <Alert severity='info'>
                      View exposed to the investor portal
                    </Alert>
                  )}
                </Grid>
              </Grid>
              <MetricsTab
                selected={formik.values.metrics}
                onChange={(metrics) => {
                  formik.setFieldValue(
                    VIEW_OPTIONS_FIELD_NAMES.metrics,
                    metrics
                  )
                }}
                hideAddMetricModal={props.hideAddMetricModal}
                singleLevel={props.configuration.metrics?.singleLevel}
                includeRelatedModels={
                  props.configuration.controlFilter?.[
                    formik.values.displayData.controlFilter
                  ].metricRelatedModels ||
                  props.configuration.metrics?.includeRelatedModels
                }
                includeCategoryKeys={
                  props.configuration.controlFilter?.[
                    formik.values.displayData.controlFilter
                  ].metricIncludeCategoryKeys ||
                  props.configuration.metrics?.includeCategoryKeys
                }
                excludeCategoryKeys={
                  props.configuration.metrics?.excludeCategoryKeys
                }
                useCustodianAccounts={
                  firmConfiguration.data?.useCustodianAccounts
                }
                customMetrics={props.configuration.metrics?.customMetrics}
                maxMetrics={props.configuration.maxMetrics}
                metricsFilter={
                  props.configuration.metrics.metricsFilterPredicate
                }
              />
            </>
          )
        }
        case DISPLAY_OPTIONS_TAB.DISPLAY_DATA:
          return (
            <>
              <H2>Layout</H2>
              <DisplayData
                firmlookThroughDisabled={firmlookThroughDisabled}
                displayData={formik.values.displayData}
                configuration={props.configuration}
                onChange={(displayData) =>
                  formik.setFieldValue(
                    VIEW_OPTIONS_FIELD_NAMES.displayData,
                    displayData
                  )
                }
              />
            </>
          )
        case DISPLAY_OPTIONS_TAB.OPTIONS:
          return (
            <>
              <H2>Admin Options</H2>
              <AdminOptions
                tableKey={formik.values.tableKey}
                isGlobal={formik.values.isGlobal}
                isAvailableToExternalProfiles={
                  formik.values.isAvailableToExternalProfiles
                }
                investorPortalSections={formik.values.investorPortalSections}
                onViewIsGlobalChange={(isGlobal) => {
                  formik.setFieldValue(
                    VIEW_OPTIONS_FIELD_NAMES.isGlobal,
                    isGlobal
                  )
                }}
                onAvailableToExternalProfilesChange={(
                  isAvailableToExternalProfiles
                ) => {
                  // Reset all investor portal sections to unchecked
                  // when unchecking `isAvailableToExternalProfiles`
                  formik.setValues({
                    ...formik.values,
                    isAvailableToExternalProfiles,
                    investorPortalSections: {}
                  })
                }}
                onInvestorPortalSectionsChange={(section, value) => {
                  const investorPortalSections = produce(
                    formik.values.investorPortalSections,
                    (draft) => {
                      if (value) {
                        draft[section] = value

                        return
                      }

                      delete draft[section]
                    }
                  )

                  formik.setFieldValue(
                    VIEW_OPTIONS_FIELD_NAMES.investorPortalSections,
                    investorPortalSections
                  )
                }}
              />
            </>
          )
      }
    }

    return (
      <div style={{display: 'contents'}}>
        <LoadingContainer
          loading={
            groupings.loading ||
            firmConfiguration.loading ||
            filterCriteria.loading
          }
        >
          {availableMetrics.data && groupings.data && firmConfiguration.data && (
            <FormikProvider value={formik}>
              <FormUnsavedPrompt />
              <form
                onSubmit={formik.handleSubmit}
                style={{display: 'contents'}}
              >
                <ModalContent
                  noPadding
                  permaBottomBorder
                  data-testid='display-options'
                >
                  <Flex>
                    <DisplayOptionsTabs
                      selectedTab={selectedTab}
                      onTabSelected={handleTabSelected}
                      configuration={props.configuration}
                      filter={formik.values.filter}
                      selectedGroupings={formik.values.groups}
                      selectedMetrics={formik.values.metrics}
                    />
                    <div className={css.tabContent}>{renderSelectedTab()}</div>
                  </Flex>
                </ModalContent>
                <ModalActions>
                  <FormActions showFormErrorCount onCancel={props.onCancel} />
                </ModalActions>
              </form>
            </FormikProvider>
          )}
        </LoadingContainer>
      </div>
    )
  }
