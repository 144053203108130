import {ActionType, createAction} from 'typesafe-actions'

import {IChartTable} from '@d1g1t/api/models'

import {StandardResponse} from '@d1g1t/lib/standard-response'

import {IRangeToLoad} from './lib'

export const paginatorActions = {
  initialize: createAction('INITIALIZE')(),
  loadInitialPageRequest: createAction(
    'LOAD_INITIAL_PAGE_REQUEST'
  )<undefined>(),
  loadInitialPageSuccess: createAction(
    'LOAD_INITIAL_PAGE_SUCCESS'
  )<IChartTable>(),
  loadInitialPageFailure: createAction('LOAD_INITIAL_PAGE_FAILURE')<any>(),
  updatingItemsRequest: createAction('UPDATING_ITEMS_REQUEST')<string[]>(),
  updatingItemsSuccess: createAction('UPDATING_ITEMS_SUCCESS')(),
  updatingItemsFailure: createAction('UPDATING_ITEMS_FAILURE')(),
  loadRangeRequest: createAction('LOAD_RANGE_REQUEST')<IRangeToLoad>(),
  loadRangeSuccess: createAction('LOAD_RANGE_SUCCESS')<{
    chart: IChartTable
    range: IRangeToLoad
  }>(),
  loadRangeFailure: createAction('LOAD_RANGE_FAILURE')<{
    error: Error
    range: IRangeToLoad
  }>(),
  setResponseData: createAction('SET_RESPONSE_DATA')<StandardResponse>()
}

export type PaginatorAction = ActionType<typeof paginatorActions>
