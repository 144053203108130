import React, {useMemo} from 'react'

import {IChartTable} from '@d1g1t/api/models'

import {StandardResponse} from '@d1g1t/lib/standard-response'

import {CHECKED_STATE} from '@d1g1t/shared/containers/standard-table/constants'

import {checkedStateMapFromCheckedRowIds} from './lib'

interface ITableCheckedControlProps {
  table: IChartTable
  checkedRows: string[]
  onCheckboxedRows(ids: string[]): void
  children(checkedMap: Map<string, CHECKED_STATE>): JSX.Element
}

const EMPTY_MAP = new Map()

export const TableCheckedControl: React.SFC<ITableCheckedControlProps> = ({
  table,
  checkedRows,
  onCheckboxedRows,
  children
}) => {
  const checked = useMemo(() => {
    if (typeof onCheckboxedRows !== 'function') {
      return EMPTY_MAP
    }

    const checkedSet = new Set(checkedRows || [])
    const response = new StandardResponse(table)

    return checkedStateMapFromCheckedRowIds(checkedSet, response.items)
  }, [checkedRows, table, onCheckboxedRows])

  return children(checked)
}

TableCheckedControl.displayName = 'TableCheckedControl'
