import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useDrop} from 'react-dnd'

import {debounce} from 'lodash'

import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'

import {CHART_VALUE_TYPE, IChartTableAllowedValue} from '@d1g1t/api/models'

import {Flex} from '@d1g1t/shared/components/flex'
import {TextInput} from '@d1g1t/shared/components/form/text-input'
import {Button} from '@d1g1t/shared/components/mui/button'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {ValueLabelSelect} from '@d1g1t/shared/components/value-label-select'
import {DRAG_ROW_TYPE} from '@d1g1t/shared/containers/standard-table/constants'
import {
  IDropSpecProps,
  IStandardTableCategory
} from '@d1g1t/shared/containers/standard-table/typings'

import {BOOLEAN_ALLOWED_VALUES} from './constants'

import * as css from './style.scss'

interface IColumnFilterCellProps extends IDropSpecProps {
  columnIndex: number
  category: IStandardTableCategory
  columnFilterFocusedId: string
  value: string | any[]
  style: React.CSSProperties
  /**
   * Renders apply column filter button on left side of table
   */
  applyColumnFilterButton: boolean
  /**
   * Renders an arrow icon on the right side of cell that has filter to apply
   */
  applyButtonBasedColumnFilter: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onUpdateColumnFilterFocusId: (categoryId: string) => void
  /**
   * Applies intermediate column filter to true
   * column filter state (disables APPLY button if `undefined`)
   */
  onApplyIntermediateColumnFilterState: () => void
  /**
   * If `true` will use compact mode style for filter cell. Currently used for standard table compact mode.
   */
  isCompactMode?: boolean
}

export const ColumnFilterCell: React.FC<IColumnFilterCellProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleFocus = () => {
    props.onUpdateColumnFilterFocusId(props.category.id)
  }

  useEffect(() => {
    if (props.columnFilterFocusedId === props.category.id) {
      inputRef.current.focus()
    }
  }, [])

  const renderApply =
    props.columnIndex === 0 &&
    props.applyColumnFilterButton &&
    !props.applyButtonBasedColumnFilter

  const renderApplyFiltersButton =
    props.value !== undefined &&
    !!props.onApplyIntermediateColumnFilterState &&
    props.applyButtonBasedColumnFilter

  const columnFilterInput = (() => {
    if (
      (props.columnIndex === 0 && props.applyColumnFilterButton) ||
      (props.columnIndex === 0 && props.applyButtonBasedColumnFilter)
    ) {
      return null
    }

    if (
      props.category.options?.allowedValues ||
      props.category.valueType === CHART_VALUE_TYPE.BOOLEAN
    ) {
      const allowedValues: IChartTableAllowedValue[] = props.category.options
        ?.allowedValues
        ? props.category.options.allowedValues
        : BOOLEAN_ALLOWED_VALUES

      const multiSelectValue = (() => {
        const getKeyValuePair = (singleValue) =>
          allowedValues.find((allowedValue) => allowedValue.key === singleValue)

        const labelList = ((props.value as any[]) || []).map(
          (singleValue) => getKeyValuePair(singleValue).value
        )

        return labelList.join(', ')
      })()

      return (
        <ValueLabelSelect
          style={{
            width: renderApplyFiltersButton ? '85%' : '100%'
          }}
          multiple
          fullWidth
          size={props.isCompactMode ? 'compact' : 'medium'}
          options={allowedValues.map((allowedValue) => ({
            value: allowedValue.key,
            label: allowedValue.value
          }))}
          value={props.value || []}
          renderValue={multiSelectValue}
          onChange={props.onChange}
          data-testid='select-column-filter'
        />
      )
    }

    if (!props.category.options?.allowedValues) {
      return (
        <TextInput
          inputRef={inputRef}
          onFocus={handleFocus}
          autoComplete='off'
          value={props.value || ''}
          onChange={props.onChange}
          isCompactMode={props.isCompactMode}
          data-testid='textfield-column-filter'
        />
      )
    }
  })()

  const [, dropRef] = useDrop({
    accept: DRAG_ROW_TYPE,
    hover() {
      props.onDragHover(props.rowIndex)
    },
    drop() {
      props.onDrop(props.rowIndex)
    }
  })

  const [tooltipOpen, setTooltipOpen] = useState(false)

  // Hide tooltip after 5 seconds from last input
  const debounceCloseTooltip = useCallback(
    debounce(() => {
      setTooltipOpen(false)
    }, 5000),
    []
  )

  useEffect(() => {
    setTooltipOpen(!!props.onApplyIntermediateColumnFilterState)
    if (props.onApplyIntermediateColumnFilterState && tooltipOpen) {
      debounceCloseTooltip()
    }
  }, [props.onApplyIntermediateColumnFilterState])

  return (
    <div ref={dropRef}>
      <Flex
        style={props.style}
        className={css.columnFilterCell}
        alignCenter
        justifyFlexEnd={!renderApply}
        justifyCenter={renderApply}
        justifyFlexStart
      >
        {renderApply && (
          <Tooltip
            arrow
            placement='bottom'
            open={tooltipOpen}
            // Seems the title prop gets passed down to button and will stay rendered.
            // This is to prevent bug where title tag is rendered and will stay on page while
            // scrolling etc.
            title={tooltipOpen && 'Click to Apply Column Filter'}
          >
            <Button
              primary
              noMinWidth
              noPadding
              small
              onMouseOver={() => {
                // Hide after 1 second `onMouseOver` of apply button
                // (the 1 second delay is in cases where the user quickly hovers
                // over the apply button but did not get enough time to read what
                // the tooltip has said)
                setTimeout(() => setTooltipOpen(false), 1000)
              }}
              onClick={
                props.onApplyIntermediateColumnFilterState
                  ? props.onApplyIntermediateColumnFilterState
                  : undefined
              }
              disabled={!props.onApplyIntermediateColumnFilterState}
            >
              Apply
            </Button>
          </Tooltip>
        )}
        {columnFilterInput}
        {renderApplyFiltersButton && (
          <Tooltip placement='bottom-end' title='Apply column filters'>
            <IconButton
              size='small'
              onClick={
                props.onApplyIntermediateColumnFilterState
                  ? props.onApplyIntermediateColumnFilterState
                  : undefined
              }
            >
              <CheckCircleOutlineOutlinedIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        )}
      </Flex>
    </div>
  )
}
