import React from 'react'

import {intersection, partition, remove} from 'lodash'

import AssignmentIcon from '@material-ui/icons/Assignment'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import ApproveIcon from '@material-ui/icons/Check'
import RejectIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import MergeTypeIcon from '@material-ui/icons/MergeType'
import CancelIcon from '@material-ui/icons/NoSim'
import SendIcon from '@material-ui/icons/Send'

import {
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  IFirmConfiguration,
  ITokenizedFilter,
  METRIC_T_ALLOCATION_STATUS,
  METRIC_T_STATUS,
  TOKENIZEDEXPRESSION_OPERATOR,
  TOKENIZEDFILTER_FORMAT,
  TOKENIZEDFILTER_JOIN_OPERATOR,
  TRADEORDER_DISPLAY_STATUS,
  TRADEORDER_STATUS,
  TRADINGTYPEPLATFORM_TRADE_EXECUTION_PLATFORM
} from '@d1g1t/api/models'

import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'

import {MULTIPLE_VALUES} from '@d1g1t/shared/components/form-field/constants'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {StandardTableNameAdornment} from '@d1g1t/shared/containers/standard-table'

import {BLOTTER_CATEGORY_IDS} from './constants'

// Should be replaced by similar mapping coming as part of the request
export const DEPENDENT_CATEGORY_IDS_LIST = [
  ['t-expiry-type', 't-expiry-date'],
  [
    't-limit-price',
    't-market',
    't-type',
    't-qty-type',
    't-trade-value-gross',
    't-trade-value-net',
    't-expected-trade-value-gross',
    't-expected-trade-value-net',
    't-qty',
    't-total-commission',
    't-tax-withheld',
    't-accrued-interest',
    't-commission-type',
    't-expected-price',
    't-expected-trade-value-gross',
    't-expected-trade-value-net',
    't-modified'
  ],
  ['t-settled', 't-expected-settled'],
  ['t-expected-traded', 't-traded']
]

export const TRADE_ORDER_CHANGE_STATUS = 'change-status'

export enum EXECUTABLE_TRADE_ACTION_TYPE {
  edit,
  aggregate,
  aggregateAll,
  disaggregate,
  allocate
}

export type ITradeOrderAction = {
  label: string
  icon: React.ReactElement<any>
  ignoreZeroCount?: boolean
} & (
  | {
      type: typeof TRADE_ORDER_CHANGE_STATUS
      transitionStatus: TRADEORDER_STATUS
    }
  | {
      type: EXECUTABLE_TRADE_ACTION_TYPE
    }
)

type TRADE_ORDER_ACTION_KEY =
  | 'APPROVE'
  | 'REJECT'
  | 'CANCEL'
  | 'SEND'
  | 'EDIT'
  | 'BULK_EDIT'
  | 'AGGREGATE'
  | 'AGGREGATE_ALL'
  | 'DISAGGREGATE'
  | 'ALLOCATE'

export const TRADE_ORDER_ACTIONS: Record<
  TRADE_ORDER_ACTION_KEY,
  ITradeOrderAction
> = {
  APPROVE: {
    label: 'Approve',
    type: TRADE_ORDER_CHANGE_STATUS,
    transitionStatus: TRADEORDER_STATUS.OPEN,
    icon: <ApproveIcon />
  },
  REJECT: {
    label: 'Reject',
    type: TRADE_ORDER_CHANGE_STATUS,
    transitionStatus: TRADEORDER_STATUS.REJECTED,
    icon: <RejectIcon />
  },
  CANCEL: {
    label: 'Cancel',
    type: TRADE_ORDER_CHANGE_STATUS,
    transitionStatus: TRADEORDER_STATUS.CANCELLED,
    icon: <CancelIcon />
  },
  SEND: {
    label: 'Send',
    type: TRADE_ORDER_CHANGE_STATUS,
    transitionStatus: TRADEORDER_STATUS.PENDING_EXECUTION,
    icon: <SendIcon />
  },
  EDIT: {
    label: 'Edit',
    type: EXECUTABLE_TRADE_ACTION_TYPE.edit,
    icon: <EditIcon />
  },
  BULK_EDIT: {
    label: 'Bulk Edit',
    type: EXECUTABLE_TRADE_ACTION_TYPE.edit,
    icon: <EditIcon />
  },
  AGGREGATE: {
    label: 'Aggregate',
    type: EXECUTABLE_TRADE_ACTION_TYPE.aggregate,
    icon: <MergeTypeIcon />
  },
  AGGREGATE_ALL: {
    label: 'Aggregate All',
    type: EXECUTABLE_TRADE_ACTION_TYPE.aggregateAll,
    icon: <MergeTypeIcon />
  },
  DISAGGREGATE: {
    label: 'Disaggregate',
    type: EXECUTABLE_TRADE_ACTION_TYPE.disaggregate,
    icon: <CallSplitIcon />
  },
  ALLOCATE: {
    label: 'Allocate',
    type: EXECUTABLE_TRADE_ACTION_TYPE.allocate,
    icon: <AssignmentIcon />
  }
}

export const getActionsForItem = (
  item: StandardResponseItem,
  tradingTypePlatforms: IFirmConfiguration['tradingTypePlatforms']
): ITradeOrderAction[] => {
  const actions: ITradeOrderAction[] = []

  const instrumentTradingType = item.getKey(
    BLOTTER_CATEGORY_IDS.INSTRUMENT_TRADING_TYPE
  )
  const status = item.getKey(METRIC_T_STATUS.slug) as TRADEORDER_DISPLAY_STATUS

  if (status === TRADEORDER_DISPLAY_STATUS.PENDING_APPROVAL) {
    actions.push(TRADE_ORDER_ACTIONS.APPROVE, TRADE_ORDER_ACTIONS.REJECT)
  }

  if (status === TRADEORDER_DISPLAY_STATUS.OPEN) {
    actions.push(TRADE_ORDER_ACTIONS.SEND)
  }

  if (
    [
      TRADEORDER_DISPLAY_STATUS.PENDING_APPROVAL,
      TRADEORDER_DISPLAY_STATUS.OPEN
    ].includes(status) ||
    ([
      TRADEORDER_DISPLAY_STATUS.WORKING,
      TRADEORDER_DISPLAY_STATUS.WORKING_CONFIRMED
    ].includes(status) &&
      tradingTypePlatforms?.find(
        (tradingTypePlatform) =>
          [
            TRADINGTYPEPLATFORM_TRADE_EXECUTION_PLATFORM.EMSX,
            TRADINGTYPEPLATFORM_TRADE_EXECUTION_PLATFORM.FLYER
          ].includes(tradingTypePlatform.tradeExecutionPlatform) &&
          tradingTypePlatform.instrumentTradingType === instrumentTradingType
      ))
  ) {
    actions.push(TRADE_ORDER_ACTIONS.CANCEL)
  }

  actions.push(TRADE_ORDER_ACTIONS.EDIT)

  if (item.getValue(BLOTTER_CATEGORY_IDS.IS_AGGREGATED_PARENT)) {
    if (
      [
        TRADEORDER_DISPLAY_STATUS.PENDING_APPROVAL,
        TRADEORDER_DISPLAY_STATUS.OPEN
      ].includes(status)
    ) {
      actions.push(TRADE_ORDER_ACTIONS.DISAGGREGATE)
    }
  }

  if (
    [
      TRADEORDER_DISPLAY_STATUS.WORKING_CONFIRMED,
      TRADEORDER_DISPLAY_STATUS.CLOSED
    ].includes(status) &&
    item.getValue(METRIC_T_ALLOCATION_STATUS.slug) !== 'Not Filled'
  ) {
    actions.push(TRADE_ORDER_ACTIONS.ALLOCATE)
  }

  return actions
}

export function getBulkActions(
  table: StandardResponse,
  selectedIds: string[],
  tradingTypePlatforms: IFirmConfiguration['tradingTypePlatforms']
): ITradeOrderAction[][] {
  if (!table || table.isEmpty()) {
    return []
  }

  const selectedItems = selectedIds.map((rowId) => table.findItemById(rowId))

  const intersectedActions = intersection<ITradeOrderAction>(
    ...selectedItems.map((item) =>
      getActionsForItem(item, tradingTypePlatforms)
    )
  )

  const partitionedActions = partition(intersectedActions, {
    type: TRADE_ORDER_CHANGE_STATUS
  })

  if (selectedIds.length > 1) {
    // remove actions not supported in bulk mode
    remove(partitionedActions[1], TRADE_ORDER_ACTIONS.EDIT)

    partitionedActions[1].unshift(TRADE_ORDER_ACTIONS.AGGREGATE)
    partitionedActions[1].unshift(TRADE_ORDER_ACTIONS.BULK_EDIT)
  }

  if (selectedIds.length === 0) {
    partitionedActions[0][0] = {
      ...TRADE_ORDER_ACTIONS.AGGREGATE_ALL,
      ignoreZeroCount: true
    }
  }

  return partitionedActions
}

export function tradeOrderNameAdornment(
  tooltip = 'This trade is aggregated from multiple other trades.'
): StandardTableNameAdornment {
  return (item: StandardResponseItem) => {
    if (item.getValue(BLOTTER_CATEGORY_IDS.IS_AGGREGATED_PARENT)) {
      return (
        <Tooltip data-testid='name-adornment-tooltip' title={tooltip}>
          <MergeTypeIcon />
        </Tooltip>
      )
    }

    return null
  }
}

export function displaySellAllForItem(item: StandardResponseItem): boolean {
  return (
    item.getValue(BLOTTER_CATEGORY_IDS.IS_SELL_ALL) &&
    item.getKey(BLOTTER_CATEGORY_IDS.INSTRUMENT_TRADING_TYPE) ===
      FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND
  )
}

export function displayDscFreeForItem(item: StandardResponseItem): boolean {
  return (
    item.getValue(BLOTTER_CATEGORY_IDS.IS_DSC_FREE) &&
    item.getKey(BLOTTER_CATEGORY_IDS.INSTRUMENT_TRADING_TYPE) ===
      FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FUND
  )
}

/**
 * Validation to ensure that the date passed is in the YYYY-MM-DD format
 */
export function bulkEditValidateDate(date: string): boolean {
  if (!date || date === MULTIPLE_VALUES) {
    // accepts empty values and ignores the MULTIPLE_VALUES sent by the backend
    return true
  }

  if (new Date(date).toString() === 'Invalid Date') {
    return false
  }

  // ensures the date is in the YYYY-MM-DD format
  return new RegExp(/^(\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/).test(
    date.toString()
  )
}

/**
 * Takes the current filter tokens for the `METRIC_T_QTY` field on `manage-orders`
 * page and returns additional filters to apply for the request if the filter tokens
 * are `all` or `dsc free`
 *
 * @param tokens - the current tokens for the `METRIC_T_QTY` field on `manage-orders` page
 *
 * @returns a dictionary containing additional filters to be applied
 */
export function manageOrdersQuantityFieldFilterGenerator(
  tokens: string[]
): Dictionary<ITokenizedFilter> {
  const DEFAULT_BOOLEAN_FILTER = {
    joinOperator: TOKENIZEDFILTER_JOIN_OPERATOR.AND,
    format: TOKENIZEDFILTER_FORMAT.BOOLEAN,
    expressions: [
      {
        operator: TOKENIZEDEXPRESSION_OPERATOR.IS_,
        value: true
      }
    ]
  }

  if (tokens.map((token) => token.toLocaleLowerCase()).includes('all')) {
    return {
      [BLOTTER_CATEGORY_IDS.IS_SELL_ALL]: DEFAULT_BOOLEAN_FILTER
    }
  }

  if (tokens.map((token) => token.toLocaleLowerCase()).includes('dsc free')) {
    return {
      [BLOTTER_CATEGORY_IDS.IS_DSC_FREE]: DEFAULT_BOOLEAN_FILTER
    }
  }

  return {}
}
