import React from 'react'

import {isNil} from 'lodash'

import {
  BlockLoader,
  IBlockLoaderProps
} from '@d1g1t/shared/components/block-loader'
import {ErrorBoundary} from '@d1g1t/shared/wrappers/error-boundary'

import {ErrorDisplay} from './error-display'

export interface ILoaderDisplayProps {
  loading: boolean
  error: Nullable<Error>
  data: Nullable<any>
  renderNullData?: boolean
  loaderProps?: IBlockLoaderProps
  absolutePosition?: IBlockLoaderProps['absolutePosition']
  autoHeight?: IBlockLoaderProps['autoHeight']
  resizeOnUpdate?: boolean
  renderOnError?: boolean
  hideError?: boolean
}

/**
 * @deprecated - use `/shared/components/loading-container` instead
 *   with error boundaries to catch errors
 */
export class LoaderDisplay extends React.Component<ILoaderDisplayProps> {
  static defaultProps: Partial<ILoaderDisplayProps> = {
    renderNullData: false,
    loaderProps: {},
    resizeOnUpdate: false,
    autoHeight: true,
    renderOnError: false,
    hideError: false
  }

  componentDidUpdate(prevProps: ILoaderDisplayProps) {
    if (prevProps.data === this.props.data) {
      return
    }

    window.dispatchEvent(new Event('resize'))
  }

  renderFailure = () => {
    return (
      !this.props.hideError && (
        <>
          <ErrorDisplay error={this.props.error} />
          {this.props.renderOnError && this.props.children}
        </>
      )
    )
  }

  render() {
    return (
      <ErrorBoundary resetId='no-reset'>
        {this.props.loading && (
          <BlockLoader
            data-testid='loading-container'
            overlay
            absolutePosition={this.props.absolutePosition}
            autoHeight={this.props.autoHeight}
            {...this.props.loaderProps}
          />
        )}
        {
          // eslint-disable-next-line no-nested-ternary
          this.props.error
            ? !this.props.loading && this.renderFailure()
            : !isNil(this.props.data) || this.props.renderNullData
            ? this.props.children
            : null
        }
      </ErrorBoundary>
    )
  }
}
