import {atom} from 'recoil'

import {IColumnFilterState} from './'

/**
 * Holds the column filter states of different standard tables
 * (keyed by standard table ID)
 */
export const columnFilterAtom = atom<{
  [id: string]: {
    showColumnFilter: boolean
    state: IColumnFilterState
  }
}>({
  key: 'columnFilterAtom',
  default: EMPTY_OBJECT
})
