import React, {useState} from 'react'

import {TOUCH_SENSITIVITY, TOUCH_START_X_DEFAULT_VALUE} from './constants'

/**
 * Offers state and helpers that allow primary menu to
 * open/close on swipe and/or hover events.
 */
export const usePrimaryMenuVisibility = (): {
  /**
   * Menu visibility
   */
  openPrimaryMenu: boolean
  setOpenPrimaryMenu: (open: boolean) => void
  onTouchStart: (e: React.TouchEvent) => void
  onTouchEnd: () => void
  onTouchMove: (e: React.TouchEvent) => void
} => {
  const [openPrimaryMenu, setOpenPrimaryMenu] = useState(false)

  const [touchStart, setTouchStart] = useState(TOUCH_START_X_DEFAULT_VALUE)
  const [touchEnd, setTouchEnd] = useState(TOUCH_START_X_DEFAULT_VALUE)

  // Save the start position
  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX)
  }

  // Save the end position
  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  // Open/close menu if a swipe was detected based on start and end positions
  const handleTouchEnd = () => {
    if (
      touchStart !== TOUCH_START_X_DEFAULT_VALUE &&
      touchEnd !== TOUCH_START_X_DEFAULT_VALUE &&
      touchStart - touchEnd > TOUCH_SENSITIVITY
    ) {
      setOpenPrimaryMenu(false)

      setTouchStart(TOUCH_START_X_DEFAULT_VALUE)
      setTouchEnd(TOUCH_START_X_DEFAULT_VALUE)
    }

    if (
      touchStart !== TOUCH_START_X_DEFAULT_VALUE &&
      touchEnd !== TOUCH_START_X_DEFAULT_VALUE &&
      touchStart - touchEnd < -TOUCH_SENSITIVITY
    ) {
      setOpenPrimaryMenu(true)

      setTouchStart(TOUCH_START_X_DEFAULT_VALUE)
      setTouchEnd(TOUCH_START_X_DEFAULT_VALUE)
    }
  }

  return {
    openPrimaryMenu,
    setOpenPrimaryMenu,
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd
  }
}
