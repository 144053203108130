import React from 'react'
import {DraggableCore, DraggableData} from 'react-draggable'

import {classNames} from '@d1g1t/lib/class-names'

import {IResizeHandleProps} from './typings'

import * as css from './styles.scss'

interface IResizeHandleState {
  dragging: boolean
}

export class ResizeHandle extends React.PureComponent<
  IResizeHandleProps,
  IResizeHandleState
> {
  state = {
    dragging: false
  }

  handleDragStart = (_, data: DraggableData) => {
    this.setState({
      dragging: true
    })
    this.props.onDragStart(data)
  }

  handleDragEnd = (_, data: DraggableData) => {
    this.setState({
      dragging: false
    })
    this.props.onDragEnd(data)
  }

  handleDrag = (_, data: DraggableData) => {
    this.props.onDrag(data)
  }

  render() {
    const {className, onDrag, onDragEnd, onDragStart, hideIndicator, ...props} =
      this.props

    return (
      <DraggableCore
        onStart={this.handleDragStart}
        onStop={this.handleDragEnd}
        onDrag={this.handleDrag}
      >
        <div
          className={classNames(css.resize, className, {
            [css.dragging]: this.state.dragging
          })}
          {...props}
        >
          {!hideIndicator && <div className={css.indicator} />}
        </div>
      </DraggableCore>
    )
  }
}
