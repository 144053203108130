import React from 'react'

import {isFunction, isNil} from 'lodash'

import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'

import {classNames} from '@d1g1t/lib/class-names'

import {IErrorMessageProps} from '@d1g1t/shared/components/form/error'
import {Button} from '@d1g1t/shared/components/mui/button'

import * as form from '@d1g1t/shared/components/form/style.scss'

export interface ITextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  hideBorderOnBlur?: boolean
  hideBorder?: boolean
  standard?: boolean
  /**
   * If `true` will use compact mode style for input. Currently used for standard table compact mode.
   */
  isCompactMode?: boolean

  /**
   * Adds search styling to the text input (expands the side of the box, rounds
   * sides) in addition to rendering a search icon on the right side of the
   * text box. Note: The icon will not render if `hasLeftSearchIcon` is set.
   */
  search?: boolean

  /**
   * Render a search icon on the left side of the text box.
   */
  hasLeftSearchIcon?: boolean

  /**
   * Apply styling that allows for merging an icon on the left side of this
   * element.
   */
  mergedLeftIconStyle?: boolean

  /**
   * An optional handler for providing a button on the right side of the text
   * box for clearing the input value. This will not render if the `search`
   * option is true because they both need the right side of the text box.
   *
   * @param event - event
   */
  onClear?(event): void
  multiline?: boolean
  cols?: number
  rows?: number
  wrap?: 'hard' | 'soft' | 'off'
  resize?: 'none' | 'vertical' | 'horizontal' | 'both'
  rootProps?: React.HTMLAttributes<HTMLDivElement>
  errorProps?: IErrorMessageProps
  inputRef?: React.Ref<HTMLInputElement>
}

interface ITextInputComponentProps extends ITextInputProps {}

const TextInputComponent: React.SFC<ITextInputComponentProps> = ({
  hideBorderOnBlur,
  hideBorder,
  standard,
  search,
  hasLeftSearchIcon,
  onClear,
  multiline,
  resize,
  className,
  rootProps: {className: rootClassName = null, ...rootProps} = {},
  errorProps: {className: errorClassName = null, ...errorProps} = {},
  inputRef,
  mergedLeftIconStyle,

  ...props
}) => {
  // Workaround for narrowing of callable JSX types in TS 3.2
  const Component = (multiline
    ? 'textarea'
    : 'input') as unknown as React.ComponentType<any>

  return (
    <div className={classNames(form.wrapper, rootClassName)} {...rootProps}>
      {hasLeftSearchIcon && <SearchIcon className={form.searchIconLeft} />}
      <Component
        className={classNames(form.input, className, {
          [form.hideBorderOnBlur]: hideBorderOnBlur,
          [form.hideBorder]: hideBorder,
          [form.standard]: standard,
          [form.search]: search,
          [form.hasLeftSearchIcon]: hasLeftSearchIcon,
          [form.hasClearBtn]: !!onClear,
          [form.mergedLeftIconStyle]: mergedLeftIconStyle,
          [form.inputCompactMode]: props.isCompactMode
        })}
        style={multiline && resize ? {resize} : undefined}
        ref={inputRef}
        {...props}
      />
      {search && !hasLeftSearchIcon && (
        <SearchIcon className={form.searchIconRight} />
      )}
      {!search && onClear && (
        <Button
          className={form.clearBtn}
          style={{
            padding: '0px',
            position: 'absolute',
            top: 7,
            right: 7,
            minWidth: 'inherit',
            justifyContent: 'flex-end'
          }}
          onClick={onClear}
        >
          <ClearIcon style={{marginRight: 0, marginLeft: 0}} />
        </Button>
      )}
    </div>
  )
}

interface ITextInputState {
  value: string | number | readonly string[]
}

interface ITextInputClassProps {
  focusOnMount?: boolean
}

export class TextInput extends React.Component<
  ITextInputComponentProps & ITextInputClassProps,
  ITextInputState
> {
  state = {
    value: ''
  }

  inputEl: HTMLInputElement

  setInputEl = (node) => {
    this.inputEl = node
    if (typeof this.props.inputRef === 'function') {
      this.props.inputRef(node)
    }

    if (this.props.inputRef && 'current' in this.props.inputRef) {
      // @ts-ignore
      this.props.inputRef.current = node
    }
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({
        value: this.props.defaultValue
      })
    }

    if (!this.props.focusOnMount) {
      return
    }

    this.inputEl.focus()
    this.inputEl.selectionStart = this.inputEl.value.length
    this.inputEl.selectionEnd = this.inputEl.value.length

    if (document.activeElement === this.inputEl) {
      return
    }

    setTimeout(() => {
      this.inputEl.focus()
      this.inputEl.selectionStart = this.inputEl.value.length
      this.inputEl.selectionEnd = this.inputEl.value.length
    })
  }

  defaultHandleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      value: event.target.value
    })
  }

  render() {
    const {focusOnMount, inputRef, ...props} = this.props

    return (
      <TextInputComponent
        value={isNil(this.props.value) ? this.state.value : this.props.value}
        onChange={
          isFunction(this.props.onChange)
            ? this.props.onChange
            : this.defaultHandleOnChange
        }
        inputRef={this.setInputEl}
        {...props}
      />
    )
  }
}
