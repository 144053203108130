import {isEmpty} from 'lodash'

import {
  IContributionDimention,
  IContributionDimentionOption
} from '@d1g1t/api/endpoints'
import {ALL_MODELS, IViewMetricItem} from '@d1g1t/api/models'

import {
  CONDITION_RESULTS_KEY,
  conditionResultsOptionKey,
  conditionResultsOptionModel,
  ConditionResultsValue,
  CONTRIBUTION_DIMENSION_FIELD_NAME,
  MappedConditionResultsValue,
  optionResultsKeyFromResultsKey
} from '@d1g1t/lib/metrics'

import {ISearchResult} from '@d1g1t/shared/containers/search'

import {ICategory} from '../../typings'
import {ContributionDimensionKey} from './typings'

export const findConditionResultsKey = (
  contributionDimensions: IContributionDimention[],
  dimensionId: number
): CONDITION_RESULTS_KEY => {
  if (!dimensionId) {
    return null
  }

  const {fieldName} = contributionDimensions.find(
    (dimentions) => dimentions.id === dimensionId
  )

  switch (fieldName) {
    case CONTRIBUTION_DIMENSION_FIELD_NAME.ASSET_CLASSES:
      return CONDITION_RESULTS_KEY.ASSET_CLASSES
    case CONTRIBUTION_DIMENSION_FIELD_NAME.CURRENCIES:
      return CONDITION_RESULTS_KEY.CURRENCIES
    case CONTRIBUTION_DIMENSION_FIELD_NAME.STRATEGIES:
      return CONDITION_RESULTS_KEY.STRATEGIES
  }
}

export const conditionResults = (
  item: IViewMetricItem,
  contributionDimensions: IContributionDimention[],
  contributionDimensionKey: ContributionDimensionKey,
  contributionDimensionOptions: {
    assetClassOptions: IContributionDimentionOption[]
    currencyOptions: IContributionDimentionOption[]
    strategyOptions: IContributionDimentionOption[]
  }
): {
  options: IContributionDimentionOption[]
  value: ConditionResultsValue[]
  conditionResultsKey: CONDITION_RESULTS_KEY
} => {
  const conditionResultsKey = findConditionResultsKey(
    contributionDimensions,
    item[contributionDimensionKey]
  )

  if (!conditionResultsKey) {
    return null
  }

  switch (conditionResultsKey) {
    case CONDITION_RESULTS_KEY.ASSET_CLASSES:
      return {
        conditionResultsKey,
        options: contributionDimensionOptions.assetClassOptions,
        value: item.assetClasses
      }
    case CONDITION_RESULTS_KEY.CURRENCIES:
      return {
        conditionResultsKey,
        options: contributionDimensionOptions.currencyOptions,
        value: item.currencies
      }
    case CONDITION_RESULTS_KEY.STRATEGIES:
      return {
        conditionResultsKey,
        options: contributionDimensionOptions.strategyOptions,
        value: item.strategies
      }
  }
}

export const conditionResultValueFromUrls = (
  urls: string[],
  conditionResultsKey: CONDITION_RESULTS_KEY
): ConditionResultsValue[] => {
  const key = optionResultsKeyFromResultsKey(conditionResultsKey)

  return urls.map(
    (url) =>
      ({
        [key]: {url}
      } as unknown as ConditionResultsValue)
  )
}

export const isConditionResultsEnabled = (
  item: IViewMetricItem,
  contributionDimensions: IContributionDimention[],
  contributionDimensionKey: ContributionDimensionKey,
  contributionDimensionOptions: {
    assetClassOptions: IContributionDimentionOption[]
    currencyOptions: IContributionDimentionOption[]
    strategyOptions: IContributionDimentionOption[]
  }
): boolean => {
  const results = conditionResults(
    item,
    contributionDimensions,
    contributionDimensionKey,
    contributionDimensionOptions
  )

  if (!results) {
    return false
  }

  return !isEmpty(results.value)
}

export const cleanNoneValuesForCondtionResults = <
  K extends CONDITION_RESULTS_KEY
>(
  value: ConditionResultsValue[]
): MappedConditionResultsValue<K>[] => {
  const options: ConditionResultsValue[] = []
  for (const option of value) {
    const model = conditionResultsOptionModel(option)

    if (model.url) {
      options.push(option)

      continue
    }

    const key = conditionResultsOptionKey(option)
    const cleanedOption = {[key]: null} as unknown as ConditionResultsValue

    options.push(cleanedOption)
  }

  return options as MappedConditionResultsValue<K>[]
}

export const restoreNoneValuesForConditionResults = <
  K extends CONDITION_RESULTS_KEY
>(
  value: ConditionResultsValue[]
): MappedConditionResultsValue<K>[] => {
  if (isEmpty(value)) {
    return [] as any
  }

  const options: ConditionResultsValue[] = []
  for (const option of value) {
    const model = conditionResultsOptionModel(option)

    if (!model) {
      const key = conditionResultsOptionKey(option)
      const restoredOption = {
        [key]: {url: null}
      } as unknown as ConditionResultsValue
      options.push(restoredOption)

      continue
    }

    options.push(option)
  }

  return options as MappedConditionResultsValue<K>[]
}

/**
 * Get either children or metric leafs
 */
export const getChildren = (category: ICategory): ICategory[] => {
  return category.children || category.metrics
}

/**
 * Get sub-category or metric leafs of a category.
 * Returns null if there are no children
 */
export const getSubCategory = (category: ICategory): Nullable<ICategory> => {
  if (!category) {
    return null
  }

  if (!category.children) {
    return null
  }

  return getChildren(category)[0]
}

/**
 * Creates a list of search results that can be passed to `<Search />`
 * @param available - available metrics passed into form
 */
export const searchableMetrics = (available: ICategory): ISearchResult[] => {
  const results: ISearchResult<{metric: ICategory; category: ICategory}>[] = []
  for (const category of available.children) {
    const metrics = getChildren(category.children[0])
    for (const metric of metrics) {
      results.push({
        modelName: ALL_MODELS.INDIVIDUAL,
        displayText: metric.name,
        entityId: metric.id,
        data: {
          metric,
          category
        }
      })
    }
  }

  return results
}

/**
 * Returns a tuple containing a set of slugs which require a selected entity
 * and slugs which require a transaction as of date
 * @param available - available metrics passed into form
 */
export const getRelationalAndTransactionAsOfDateMetricSlugs = (
  available: ICategory
): [Set<string>, Set<string>] => {
  const relationalSlugs = new Set<string>()
  const transactionAsOfDateSlugs = new Set<string>()

  for (const category of available.children) {
    updateRelationalMetricSlugs(
      category,
      relationalSlugs,
      transactionAsOfDateSlugs
    )
  }

  return [relationalSlugs, transactionAsOfDateSlugs]
}

/**
 * Used internally by `getRelationalMetricSlugs`
 */
const updateRelationalMetricSlugs = (
  category: ICategory,
  relationalSlugs: Set<string>,
  transactionAsOfDateSlugs: Set<string>
): void => {
  if (category.displayPortfolios || category.displayTransactionAsOfDate) {
    const descendantMetrics = getDescendantMetrics(category)
    for (const metric of descendantMetrics) {
      if (category.displayPortfolios) {
        relationalSlugs.add(metric.metric)
      }

      if (category.displayTransactionAsOfDate) {
        transactionAsOfDateSlugs.add(metric.metric)
      }
    }

    return
  }

  if (category.children) {
    for (const child of category.children) {
      updateRelationalMetricSlugs(
        child,
        relationalSlugs,
        transactionAsOfDateSlugs
      )
    }
  }
}

/**
 * Returns a list of all metrics lower in the tree
 * @param category - Any non-leaf/metric category
 */
const getDescendantMetrics = (category: ICategory): ICategory[] => {
  const metrics: ICategory[] = []

  updateDescendentMetrics(category, metrics)

  return metrics
}

/**
 * Used internally by getDescendantMetrics
 */
const updateDescendentMetrics = (
  category: ICategory,
  metrics: ICategory[]
): void => {
  if (category.metrics) {
    for (const metric of category.metrics) {
      metrics.push(metric)
    }
  }

  if (category.children) {
    for (const child of category.children) {
      updateDescendentMetrics(child, metrics)
    }
  }
}

/**
 * Used by `childDefaultsPath` to find a default child for a category
 * @param category - maybe a category, potentially with children
 */
const getDefaultChild = (
  category: Nullable<ICategory>
): Nullable<ICategory> => {
  if (!category || !category.children) {
    return null
  }

  const subChildren =
    category.children[0].children || category.children[0].metrics

  return subChildren.find((child) => child.default) || subChildren[0]
}

/**
 * Given a category, recurse through the tree and find a path of all its
 * default values
 * @param category - a cateogry with children
 * @param path - used internally for recursion
 */
const childDefaultsPath = (
  category: ICategory,
  path: string[] = []
): string[] => {
  const child = getDefaultChild(category)

  if (!child) {
    return path
  }

  return childDefaultsPath(child, [...path, String(child.id)])
}

/**
 * Creates a new path from the given path, replacing values under `level` with
 * their default values - essentially "resets" the selections under the selected
 * value
 * @param level - index of the selection in a path
 * @param value - the category / metric selected
 * @param path - current path value
 *
 */
export const updatedPathWithDefaults = (
  level: number,
  value: ICategory,
  path: string[]
): string[] => {
  let updatedPath = path.slice(0, level)
  updatedPath[level] = String(value.id)

  if (getChildren(value)) {
    const defaultPath = childDefaultsPath(value)
    updatedPath = [...updatedPath, ...defaultPath]
  }

  return updatedPath
}

/**
 * Given available metrics and a path, returns the category or metric at that path
 * @param category - available categories
 * @param path - path to metric or category
 * @param topLevel - used internally for recursion
 */
export const categoryAtPath = (
  category: ICategory,
  path: string[],
  topLevel = true
): ICategory => {
  if (path.length === 0) {
    return category
  }

  const subPath = [...path]
  const id = subPath.shift()

  if (topLevel) {
    const subCategory = category.children.find((child) => child.id === id)

    return categoryAtPath(subCategory, subPath, false)
  }

  const subChildren =
    category.children[0].children || category.children[0].metrics
  const subCategory = subChildren.find((child) => child.id === id)

  return categoryAtPath(subCategory, subPath, false)
}

/**
 * Given the inital metric view item, fills out the remaining default path
 * if it does not exist (new values added after view was saved)
 * @param value  - inital metric view item
 * @param available - available categories
 */
export const defaultMetricItem = (
  value: IViewMetricItem,
  available: ICategory
): IViewMetricItem => {
  const conditionResultsProperties = {
    assetClasses:
      restoreNoneValuesForConditionResults<CONDITION_RESULTS_KEY.ASSET_CLASSES>(
        value.assetClasses
      ),
    currencies:
      restoreNoneValuesForConditionResults<CONDITION_RESULTS_KEY.CURRENCIES>(
        value.currencies
      ),
    strategies:
      restoreNoneValuesForConditionResults<CONDITION_RESULTS_KEY.STRATEGIES>(
        value.strategies
      )
  }

  if (isEmpty(value.path)) {
    const defaultTopLevelCategory =
      available.children.find((child) => child.default) || available.children[0]
    const defaultPath = [
      defaultTopLevelCategory.id,
      ...childDefaultsPath(defaultTopLevelCategory)
    ]
    const defaultMetric = categoryAtPath(available, defaultPath)

    return {
      ...value,
      ...conditionResultsProperties,
      path: defaultPath,
      metric: defaultMetric.metric,
      displayName: value.displayName || defaultMetric.columnTitle || ''
    }
  }

  const deepestCategory = categoryAtPath(available, value.path)

  if (!getChildren(deepestCategory)) {
    return {
      ...value,
      ...conditionResultsProperties,
      metric: value.path[value.path.length - 1]
    }
  }

  const path = [...value.path, ...childDefaultsPath(deepestCategory)]

  const metric = categoryAtPath(available, path)

  return {
    ...value,
    ...conditionResultsProperties,
    path,
    metric: metric.metric,
    displayName: value.displayName || metric.columnTitle || ''
  }
}
