import React from 'react'

import {isEqual} from 'lodash'

import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight'

import {Flex} from '@d1g1t/shared/components/flex'
import {IconButton} from '@d1g1t/shared/components/mui/icon-button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {
  ISelectEntitiesActions,
  ISelectEntitiesState
} from '@d1g1t/shared/containers/select-entities'

import * as css from './style.scss'

export interface IFocusNavigationControlBoxProps {
  selectEntitiesState: Partial<ISelectEntitiesState>
  selectEntitiesActions: ISelectEntitiesActions
}

export const FocusNavigationControlBox: React.FC<
  IFocusNavigationControlBoxProps
> = (props) => {
  // Only available when there are at least 2 entities selected
  if (
    !props.selectEntitiesState.selected ||
    props.selectEntitiesState.selected.length < 2
  ) {
    return null
  }

  const getEntityByIndex = (index: number) =>
    props.selectEntitiesState.selected[index]

  const focusedIndex = props.selectEntitiesState.selected.findIndex((entity) =>
    isEqual(entity, props.selectEntitiesState.focused)
  )

  const totalSelected = props.selectEntitiesState.selected.length
  const focusedPosition = 1 + focusedIndex

  return (
    <Flex alignCenter justifyCenter justifySpaceBetween className={css.chip}>
      <IconButton
        small
        disabled={[0, 1].includes(focusedPosition)}
        onClick={() =>
          props.selectEntitiesActions.focusEntity(
            getEntityByIndex(focusedIndex - 1)
          )
        }
      >
        <Tooltip title='Previous' placement='bottom'>
          <LeftArrowIcon />
        </Tooltip>
      </IconButton>
      <Flex grow alignCenter justifyCenter className={css.textDiv}>
        <div className={css.text}>
          {focusedPosition === 0 ? '-' : focusedPosition} of {totalSelected}
        </div>
      </Flex>
      <IconButton
        small
        disabled={focusedPosition === totalSelected}
        onClick={() =>
          props.selectEntitiesActions.focusEntity(
            getEntityByIndex(focusedIndex + 1)
          )
        }
      >
        <Tooltip title='Next' placement='bottom'>
          <RightArrowIcon />
        </Tooltip>
      </IconButton>
    </Flex>
  )
}
