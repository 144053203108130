import {useMemo} from 'react'

import {useApiQuery} from 'fairlight'
import {useFormikContext} from 'formik'
import produce from 'immer'
import {last} from 'lodash'

import {IInstrument} from '@d1g1t/api/models'

import {StandardResponseItem} from '@d1g1t/lib/standard-response'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {IStandardTableCategory} from '@d1g1t/shared/containers/standard-table'
import {useStandardResponseQuery} from '@d1g1t/shared/wrappers/standard-response-query'

import {SECURITY_KEYS} from '../../constants'
import {
  INSTRUMENT_PROPERTY_KEY_TO_ENDPOINT_CLASS_MAP,
  INSTRUMENT_PROPERTY_KEY_TO_EXPOSURE_KEYS_MAP,
  IUseVectorEditorDataHookReturnType,
  IVectorizedPropertyEditorFormikContext,
  VECTORIZED_EXPOSURE_COMMON_KEYS
} from './typings'

/**
 * Provides the `StandardReponse`, and helper methods for editing a
 * vectorized property supported by `VectorizedPropertyEditModal`.
 */
export function useVectorEditorData(
  /**
   * The security being edited.
   */
  instrument: IInstrument,
  /**
   * The vectorized property of `IInstrument` being edited.
   */
  instrumentPropertyKey:
    | SECURITY_KEYS.ASSET_CLASS_VECTORIZED
    | SECURITY_KEYS.ASSET_CLASS_L2_VECTORIZED
    | SECURITY_KEYS.ASSET_CLASS_L3_VECTORIZED
    | SECURITY_KEYS.CURRENCY_VECTORIZED
    | SECURITY_KEYS.INDUSTRY_VECTORIZED
    | SECURITY_KEYS.REGION_VECTORIZED
    | SECURITY_KEYS.STRATEGY_VECTORIZED
    | SECURITY_KEYS.SECTOR_VECTORIZED
): IUseVectorEditorDataHookReturnType {
  const formik = useFormikContext<IVectorizedPropertyEditorFormikContext>()

  const [vectorChart, vectorChartActions] = useStandardResponseQuery(
    INSTRUMENT_PROPERTY_KEY_TO_ENDPOINT_CLASS_MAP[
      instrumentPropertyKey
    ].vectorEndpointClass.chart({
      instrument: extractIdFromUrl(instrument.url),
      date: null
    }),
    {
      fetchPolicy: 'cache-first',
      dontReinitialize: true
    }
  )

  const [vectorList] = useApiQuery(
    INSTRUMENT_PROPERTY_KEY_TO_ENDPOINT_CLASS_MAP[
      instrumentPropertyKey
    ].listEndpointClass.list(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  const updateTotalItem = (item: StandardResponseItem, value: any) => {
    // Update Total to be the sum of all weights
    vectorChartActions.setData((prev) =>
      prev.updateValue(
        VECTORIZED_EXPOSURE_COMMON_KEYS.WEIGHT,
        'Total',
        vectorChart.data.items
          .map((chartItem) =>
            chartItem.id === item.id
              ? value
              : chartItem.getValue(VECTORIZED_EXPOSURE_COMMON_KEYS.WEIGHT)
          )
          .reduce((prev, curr) => prev + curr, 0)
      )
    )
  }

  const handleCellValueChange = (
    item: StandardResponseItem,
    category: IStandardTableCategory,
    value: any
  ) => {
    if (category.id === VECTORIZED_EXPOSURE_COMMON_KEYS.NAME) {
      // When name property changes:

      const newOption = vectorList.data.results.find(
        (vectorOption) => vectorOption.url === value // VectorPropertyDropdownCell passes this url value
      )

      vectorChartActions.setData((prev) =>
        prev.updateValue(
          VECTORIZED_EXPOSURE_COMMON_KEYS.NAME,
          item.id,
          newOption.name,
          newOption.url
        )
      )

      updateTotalItem(item, value)

      formik.setFieldValue(
        instrumentPropertyKey,
        produce(formik.values[instrumentPropertyKey], (draft) => {
          const index = draft.findIndex(
            (vector) => vector.url.includes(`/${item.id}/`) // Assumption: item.id is the number at the end of the `url` property
          )
          draft[index][
            INSTRUMENT_PROPERTY_KEY_TO_EXPOSURE_KEYS_MAP[instrumentPropertyKey]
          ] = newOption.url
        })
      )
    } else if (category.id === VECTORIZED_EXPOSURE_COMMON_KEYS.WEIGHT) {
      // When weight property changes:
      vectorChartActions.setData((prev) =>
        prev.updateValue(VECTORIZED_EXPOSURE_COMMON_KEYS.WEIGHT, item.id, value)
      )

      updateTotalItem(item, value)

      formik.setFieldValue(
        instrumentPropertyKey,
        produce(formik.values[instrumentPropertyKey], (draft) => {
          const index = draft.findIndex(
            (vector) => vector.url.includes(`/${item.id}/`) // Assumption: item.id is the number at the end of the `url` property
          )
          draft[index].weight = value // value is the new weight passed by VectorPropertyDropdownCell
        })
      )
    }
  }

  const handleAddVectorRow = () => {
    if (!vectorList.data?.results) {
      return null
    }
    // NOTE: We use the "new_" prefix here so that the numbers do not collide
    // with existing ids coming back from the API. When a collision happens we
    // end up with rows sharing the same ID. The backend ignores the `url` value
    // that we send, so it doesn't have to be valid, and we only use the id as
    // the row id, which doesn't need to be an integer.
    const newVectorId = `new_${
      vectorChart.data?.items?.length
        ? parseInt(last(last(vectorChart.data.items).id.split('_')), 10) + 1
        : 0
    }`
    const newVectorUrl = `vector/${newVectorId}/`

    // URLs of picked vectors, these should be filtered out
    const instrumentVectorUrls =
      formik.values[instrumentPropertyKey].map(
        (exposure) =>
          exposure[
            INSTRUMENT_PROPERTY_KEY_TO_EXPOSURE_KEYS_MAP[instrumentPropertyKey]
          ]
      ) ?? []

    const filteredVectorList = vectorList.data.results.filter(
      (vectorOption) => !instrumentVectorUrls.includes(vectorOption.url)
    )

    const vectorDropdownOptions = filteredVectorList.map((vectorOption) => {
      return {key: vectorOption.url, value: vectorOption.name}
    })

    const defaultVector = filteredVectorList[0]

    if (defaultVector) {
      vectorChartActions.setData((prev) =>
        prev.pushItem({
          id: newVectorId,
          data: [
            {
              categoryId: 'url',
              value: defaultVector.url
            },
            {
              categoryId: VECTORIZED_EXPOSURE_COMMON_KEYS.NAME,
              key: defaultVector.url,
              value: defaultVector.name,
              options: {
                editable: true,
                allowedValues: vectorDropdownOptions
              }
            },
            {
              categoryId: VECTORIZED_EXPOSURE_COMMON_KEYS.WEIGHT,
              value: 0
            }
          ]
        })
      )

      formik.setFieldValue(
        instrumentPropertyKey,
        produce(formik.values[instrumentPropertyKey], (draft) => {
          draft.push({
            url: newVectorUrl,
            weight: 0,
            [INSTRUMENT_PROPERTY_KEY_TO_EXPOSURE_KEYS_MAP[
              instrumentPropertyKey
            ]]: defaultVector.url
          })
        })
      )
    }
  }

  const handleRemoveVectorRow = (item: StandardResponseItem) => {
    vectorChartActions.setData((prev) => prev.removeItemById(item.id))
    updateTotalItem(item, 0)
    formik.setFieldValue(
      instrumentPropertyKey,
      produce(formik.values[instrumentPropertyKey], (draft) => {
        const index = draft.findIndex(
          (vector) => vector.url.includes(`/${item.id}/`) // Assumption: item.id is the number at the end of the `url` property
        )
        draft.splice(index, 1)
      })
    )
  }

  // Edit the decimals to 2 for improved precision
  const vectorChartUpdated = useMemo(() => {
    return produce(vectorChart, (draftChart) => {
      if (!draftChart.data) {
        return
      }
      draftChart.data.applyCategoryOptions(
        [VECTORIZED_EXPOSURE_COMMON_KEYS.WEIGHT],
        {decimals: 2}
      )
    })
  }, [vectorChart.data])

  return [
    vectorChartUpdated,
    vectorList.data.count,
    {
      onAddVector: handleAddVectorRow,
      onRemoveVector: handleRemoveVectorRow,
      onCellValueChange: handleCellValueChange,
      refetchVectorChart: vectorChartActions.refetch,
      setVectorChartData: vectorChartActions.setData
    }
  ]
}
