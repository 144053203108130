import React, {useEffect, useState} from 'react'

import {useApiQuery} from 'fairlight'
import qs from 'qs'

import {FirmConfigurationEndpoints} from '@d1g1t/api/endpoints'

import {Card} from '@d1g1t/shared/components/card'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H2, P} from '@d1g1t/shared/components/typography'
import {useAuth} from '@d1g1t/shared/wrappers/auth'
import {getErrorDisplayMessage} from '@d1g1t/shared/wrappers/error-boundary'

interface IAzureError {
  code: string
  description: string
  uri: string
}

export const AzureAdForm: React.FC = function AzureAdForm() {
  const [error, setError] = useState(null)
  const {loginError, loginAzureAd} = useAuth()

  const [firmPreConfiguration] = useApiQuery(
    FirmConfigurationEndpoints.preLogin(),
    {
      fetchPolicy: 'cache-first'
    }
  )

  useEffect(() => {
    const callbackToken: string = getCallbackToken()
    const azureError: IAzureError = getAzureError()

    if (azureError) {
      setError(azureError.description)
      return
    }

    if (callbackToken) {
      loginDigit()
      return
    }

    if (error) {
      return
    }

    redirectToAzureLogin()
  }, [])

  const redirectToAzureLogin = () => {
    const redirectTo: string =
      (getParams().redirectTo as string) ||
      firmPreConfiguration.data?.advisorRedirectOnLogInPage
    let azureUrl = firmPreConfiguration.data?.azureAdLoginUrl

    if (!azureUrl) {
      setError(
        'Cannot redirect to Single Sign-On page: Missing Azure AD Login URL'
      )
      return
    }

    if (redirectTo) {
      const tmpUrl = new URL(azureUrl)
      tmpUrl.searchParams.set('state', decodeURIComponent(redirectTo))
      azureUrl = tmpUrl.toString()
    }

    window.location.href = azureUrl
  }

  const getParams = () => {
    return qs.parse(window.location.search, {ignoreQueryPrefix: true})
  }

  const loginDigit = () => {
    const query: any = getParams()
    loginAzureAd({
      code: query.code,
      session_state: query.session_state,
      redirectTo: query.state
    })
  }

  const onRetry = () => {
    redirectToAzureLogin()
  }

  // After a user successfully authenticates on the Azure SSO login page, they
  // will redirected back here with a token in the URL.
  const getCallbackToken = () => {
    if (!window.location.search) {
      return
    }

    return getParams().code as string
  }

  // If there is an error with SSO, we will get that information in the
  // callback URL.
  const getAzureError = (): IAzureError => {
    if (!window.location.search) {
      return
    }
    const params = getParams()
    return params.error
      ? {
          code: params.error as string,
          description: params.error_description as string,
          uri: params.error_uri as string
        }
      : null
  }

  const derivedError = error || loginError

  if (!derivedError) {
    return null
  }

  return (
    <Card>
      <H2>Error</H2>
      <Spacer xs />
      <P>{getErrorDisplayMessage(derivedError)}</P>
      <div>
        <Button primary onClick={onRetry}>
          Retry
        </Button>
      </div>
    </Card>
  )
}
