import React, {forwardRef} from 'react'

import {ListItemIcon} from '@material-ui/core'
import MuiButtonGroup, {ButtonGroupProps} from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuList from '@material-ui/core/MenuList'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import {classNames} from '@d1g1t/lib/class-names'
import {getFirstTruthyObjectKey} from '@d1g1t/lib/first-truthy-key'
import {useToggleState} from '@d1g1t/lib/hooks'

import {Button} from '@d1g1t/shared/components/mui/button'
import {MenuItem} from '@d1g1t/shared/components/mui/menu-item'
import {Paper} from '@d1g1t/shared/components/mui/paper'
import {Popper} from '@d1g1t/shared/components/mui/popper'

import * as css from './style.scss'

export interface IDropdownOption {
  id: string
  label: string
  onClick: () => void
  icon?: React.ReactElement
  disabled?: boolean
}

interface IButtonGroupVariantCustomProps {
  contained?: boolean
  outlined?: boolean
}
interface IButtonGroupProps
  extends ButtonGroupProps,
    IButtonGroupVariantCustomProps {
  primary?: boolean
  roundedCorners?: boolean
}

export interface IButtonGroupSettings extends Pick<IDropdownOption, 'id'> {}

interface IButtonGroupDropdownProps extends ButtonGroupProps {
  /**
   * When passed, use the primary variant
   */
  primary?: boolean
  contained?: boolean
  outlined?: boolean
  loading: boolean
  roundedCorners?: boolean
  /**
   * ID of the main button group action saved in settings
   */
  mainOptionId: string
  dropdownOptions: IDropdownOption[]
  /**
   * Min width of main button dropdown.
   */
  dropdownMinWidth?: number
  dataTestId?: string
  /**
   * Saves the last selected button dropdown action in global settings.
   */
  onDropdownActionClicked?(setting: IButtonGroupSettings): void
  onClickCancel: () => void
  /**
   * Manually controls the open state of the dropdown
   */
  open?: boolean
  toggleOpen?: () => void
}

const muiPropsFromCustomProps = (customProps: IButtonGroupProps) => {
  const {primary, contained, outlined, roundedCorners, disabled} = customProps

  const variant = getFirstTruthyObjectKey({
    contained,
    outlined
  })
  const color = getFirstTruthyObjectKey({
    primary
  })
  const classes = {
    root: classNames({
      [css.roundedCorners]: roundedCorners
    })
  }

  return {
    disabled,
    variant,
    color,
    classes
  }
}

export const ButtonGroup: React.FC<IButtonGroupProps> = (props) => {
  return (
    <ButtonGroupComponent {...muiPropsFromCustomProps(props)}>
      {props.children}
    </ButtonGroupComponent>
  )
}

export const ButtonGroupDropdown: React.FC<IButtonGroupDropdownProps> =
  React.forwardRef((props, _) => {
    const anchorRef = React.useRef<HTMLDivElement>(null)
    const [dropdownOpen, toggleDropdownOpen, , closeDropdown] =
      useToggleState(false)

    // handle the case when id was renamed/removed from dropdownOptions
    const mainOptionId =
      props.dropdownOptions.find((item) => item.id === props.mainOptionId)
        ?.id || props.dropdownOptions[0].id

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
      // checks if user clicks the dropdown when it is already open
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return
      }
      closeDropdown()
      if (props.toggleOpen) {
        props.toggleOpen()
      }
    }

    // The main action of dropdown button
    const handleMainRequest = () => {
      if (!props.loading) {
        return props.dropdownOptions
          .find((item) => item.id === mainOptionId)
          .onClick()
      }
      return props.onClickCancel()
    }

    return (
      <>
        <ButtonGroupComponent
          ref={anchorRef}
          {...muiPropsFromCustomProps(props)}
        >
          <Button
            disabled={
              props.dropdownOptions.find((item) => item.id === mainOptionId)
                ?.disabled
            }
            onClick={() => {
              handleMainRequest()
              closeDropdown()
            }}
            style={
              props.dropdownMinWidth && {
                minWidth: `${props.dropdownMinWidth}px`
              }
            }
            data-testid={props.dataTestId || 'button-group'}
          >
            {
              props.dropdownOptions.find((item) => item.id === mainOptionId)
                ?.icon
            }
            {props.loading
              ? 'Cancel'
              : props.dropdownOptions.find((item) => item.id === mainOptionId)
                  .label}
          </Button>
          {props.dropdownOptions.length > 1 && (
            <Button
              small
              noPadding
              onClick={props.toggleOpen || toggleDropdownOpen}
              data-testid={
                props.dataTestId
                  ? `${props.dataTestId}-select`
                  : 'button-group-select'
              }
            >
              <ArrowDropDownIcon />
            </Button>
          )}
        </ButtonGroupComponent>
        <Popper
          open={props.open || dropdownOpen}
          anchorEl={anchorRef.current}
          transition
          style={{zIndex: 9999, minWidth: 'fit-content'}} // guarantees that the MenuList will be rendered above the ChartMaximizer component
        >
          {({TransitionProps}) => (
            <Grow {...TransitionProps}>
              <Paper
                style={{
                  minWidth: 'fit-content',
                  width: document.getElementById('buttonGroup').clientWidth
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {props.dropdownOptions
                      .filter((option) => option.id !== mainOptionId)
                      .map((option) => (
                        <MenuItem
                          disabled={option.disabled}
                          key={option.label}
                          onClick={() => {
                            if (props.onDropdownActionClicked) {
                              props.onDropdownActionClicked({
                                id: option.id
                              })
                            }
                            option.onClick()
                            closeDropdown()
                            if (props.toggleOpen) {
                              props.toggleOpen()
                            }
                          }}
                          data-testid={option.label}
                          title={option.label}
                        >
                          {!!option?.icon && (
                            <ListItemIcon>{option.icon}</ListItemIcon>
                          )}
                          {option.label}
                        </MenuItem>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    )
  })

const ButtonGroupComponent: React.FC<ButtonGroupProps> = forwardRef(
  ({...props}, ref) => {
    return <MuiButtonGroup id='buttonGroup' ref={ref} {...props} />
  }
)
