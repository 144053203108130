import React, {useMemo} from 'react'

import {useApi} from 'fairlight'

import {MetricEndpoints} from '@d1g1t/api/endpoints'
import {IViewMetricItem} from '@d1g1t/api/models'

import {DraggableAutocomplete} from '@d1g1t/shared/components/draggable-autocomplete'
import {DraggableList} from '@d1g1t/shared/components/draggable-list'
import {ListEditorEmptyStateButton} from '@d1g1t/shared/components/list-editor-empty-state'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Tooltip} from '@d1g1t/shared/components/mui/tooltip'
import {generateCustomMetricUrl} from '@d1g1t/shared/containers/view-options/helpers'

import {ICategory} from '../../typings'

interface IDraggableItem {
  id: number
  child: React.ReactElement<any>
}

interface IMetricsListProps {
  selectedItems: IViewMetricItem[]
  available: ICategory
  maxMetrics?: number
  onReorder(orderedSelectedItems: IViewMetricItem[])
  removeColumn(id)
  addColumn(column: IViewMetricItem)
  onChange(columnToUpdate: IViewMetricItem, indexToUpdate: number)
}

export const MetricsSingleLevelForm: React.FC<IMetricsListProps> =
  function MetricsSingleLevelForm(props) {
    const api = useApi()

    const selectedMetricIds: Set<string> = useMemo(
      () =>
        props.selectedItems.reduce<Set<string>>(
          (prev, value) => prev.add(value.path[1]),
          new Set()
        ),
      [props.selectedItems]
    )

    const addItem = () => {
      props.addColumn({
        path: [],
        displayName: '',
        metric: '',
        contributionDimension: null
      })
    }

    const handleReorder = (itemsReordered: IDraggableItem[]) => {
      if (!props.onReorder) {
        return
      }

      const orderedSelectedItems = itemsReordered.map(
        (item) => props.selectedItems[item.id]
      )
      props.onReorder(orderedSelectedItems)
    }

    const onChange = (index: number, category: ICategory) => {
      const column: IViewMetricItem = {
        path: [props.available.id, category.id],
        metric: category.metric
          ? api.buildUrl(MetricEndpoints.pathToResource(category.metric))
          : generateCustomMetricUrl(category.id),
        displayName: category.name,
        contributionDimension: 0
      }

      props.onChange(column, index)
    }

    const metrics = ((): IDraggableItem[] => {
      return props.selectedItems.map((item: IViewMetricItem, i) => {
        const options =
          props.available?.children[0].metrics.filter(
            (metric) =>
              metric.id === item.path[1] || !selectedMetricIds.has(metric.id)
          ) || []

        return {
          id: i,
          child: (
            <DraggableAutocomplete
              value={
                options.find((option) => option.id === item.path[1]) || null
              }
              onChange={(value: ICategory) => {
                onChange(i, value)
              }}
              options={options}
              getOptionLabel={(category: ICategory) => category.name}
              placeholder='Select a metric...'
            />
          )
        }
      })
    })()

    const metricsLimitExceeded: boolean =
      props.maxMetrics && props.maxMetrics <= metrics.length

    return (
      <div>
        <DraggableList
          items={metrics}
          onReorderDrop={handleReorder}
          onRemoveItem={props.removeColumn}
          emptyText={
            <>
              No metrics have been added yet.{' '}
              <ListEditorEmptyStateButton onClick={addItem}>
                Add a metric
              </ListEditorEmptyStateButton>{' '}
              to get started.
            </>
          }
        />
        <Tooltip title={metricsLimitExceeded ? 'Metrics limit exceeded.' : ''}>
          <div>
            <Button
              fullWidth
              outlined
              onClick={addItem}
              data-testid='button-add-metric'
              disabled={metricsLimitExceeded}
            >
              {metrics.length === 0 ? 'Add a metrics' : 'Add another metric'}
            </Button>
          </div>
        </Tooltip>
      </div>
    )
  }
