import {ALL_MODELS} from '@d1g1t/api/models'

const DEFAULT_SUPPORTED_MODELS = [
  ALL_MODELS.ACCOUNT,
  ALL_MODELS.CLASSSERIES,
  ALL_MODELS.CORPORATION,
  ALL_MODELS.FOUNDATION,
  ALL_MODELS.HOUSEHOLD,
  ALL_MODELS.INDIVIDUAL,
  ALL_MODELS.INVESTMENTMANDATE,
  ALL_MODELS.PERSON,
  ALL_MODELS.PORTFOLIO,
  ALL_MODELS.PRIVATEEQUITYHOLDING,
  ALL_MODELS.TRUST
]

export const ENTITY_SUPPORTED_MODELS: Set<ALL_MODELS> = new Set(
  DEFAULT_SUPPORTED_MODELS
)

export const ENTITY_EXTENDED_SUPPORTED_MODELS: Set<ALL_MODELS> = new Set([
  ...DEFAULT_SUPPORTED_MODELS,
  ALL_MODELS.POSITIONHOLDING
])
