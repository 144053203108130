import React from 'react'

import {classNames} from '@d1g1t/lib/class-names'

import {Spacer} from '@d1g1t/shared/components/spacer'
import {H5} from '@d1g1t/shared/components/typography'

import * as css from './style.scss'

export enum STAT_DECORATION {
  disabled = 'disabled',
  green = 'green',
  red = 'red'
}

interface IStatVertical {
  name: string
  value: any
  type?: STAT_DECORATION
  strong?: boolean
}

export interface IStatVerticalProps {
  list: IStatVertical[]
  noSpaceUnderStatName?: boolean
}

export const StatVertical: React.SFC<IStatVerticalProps> = (props) => {
  return (
    <>
      {props.list.map((stat, index) => (
        <div key={index}>
          <H5>{stat.name}</H5>
          {!props.noSpaceUnderStatName && <Spacer xs />}
          <div
            className={classNames(
              stat.type && css[`decoration_${stat.type}`],
              css.paddingValue,
              {
                [css.strong]: stat.strong
              }
            )}
          >
            {stat.value}
          </div>
          <Spacer xs />
        </div>
      ))}
    </>
  )
}
