import React from 'react'

import {useApiQuery} from 'fairlight'
import {useFormikContext} from 'formik'

import {InstrumentEndpoints} from '@d1g1t/api/endpoints'
import {
  ALL_MODELS,
  FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE,
  TRADEORDER_QTY_TYPE,
  TRADEORDER_QTY_TYPE_OPTIONS
} from '@d1g1t/api/models'

import {extractIdFromUrl} from '@d1g1t/lib/url'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {FlexGridItem} from '@d1g1t/shared/components/flex'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {SearchInputField} from '@d1g1t/shared/components/form-field/search-input-field'
import {ValueLabelSelectField} from '@d1g1t/shared/components/form-field/value-select-field'
import {
  CurrencyInput,
  FourDecimalInput
} from '@d1g1t/shared/components/formatted-input'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {ISearchResult} from '@d1g1t/shared/containers/search/typings'

import {TradeSelectField} from '../../trade-select-field'
import {FIXED_INCOME_FIELD_NAMES, IFixedIncomeFormValues} from '../typings'

import * as css from '../../style.scss'

function fixedIncomeInstrumentFilter(searchResult: ISearchResult) {
  return (
    searchResult.tradingType ===
    FIRMSECURITYTYPE_INSTRUMENT_TRADING_TYPE.FIXED_INCOME
  )
}

interface IEditFixedIncomeFormProps {
  editMode?: boolean
}

export const FixedIncomeForm: React.FC<IEditFixedIncomeFormProps> = (props) => {
  const {values, isSubmitting} = useFormikContext<IFixedIncomeFormValues>()

  const [instrument] = useApiQuery(
    values.instrumentUrl &&
      InstrumentEndpoints.findById(extractIdFromUrl(values.instrumentUrl))
  )

  const switchOrBuySellFields = (
    <>
      <Grid container>
        <Grid item xs={6}>
          <ControlStateProvider loading={isSubmitting || !values.accountUrl}>
            <SearchInputField
              label='Ticker'
              name={FIXED_INCOME_FIELD_NAMES.instrumentUrl}
              getOptionLabelOverride={(searchResult) =>
                `${searchResult.fundCode} - ${searchResult.name}`
              }
              searchProps={{
                models: [ALL_MODELS.INSTRUMENT],
                filterBy: fixedIncomeInstrumentFilter
              }}
              data-testid='search-fund'
              disabled={props.editMode}
            />
          </ControlStateProvider>
        </Grid>
        <Grid item xs={2}>
          <OutlinedInputField
            name={FIXED_INCOME_FIELD_NAMES.qty}
            label='Quantity'
            outlinedInputProps={{
              ...(values.qtyType === TRADEORDER_QTY_TYPE.AMOUNT && {
                inputComponent: FourDecimalInput
              }),
              ...(values.qtyType === TRADEORDER_QTY_TYPE.UNITS && {
                inputComponent: CurrencyInput
              })
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <ValueLabelSelectField
            name={FIXED_INCOME_FIELD_NAMES.qtyType}
            valueLabelSelectProps={{
              options: TRADEORDER_QTY_TYPE_OPTIONS
            }}
          />
        </Grid>
      </Grid>
      <Spacer xxs />
    </>
  )

  return (
    <ControlStateProvider
      loading={isSubmitting || !instrument.data || !values.accountUrl}
    >
      <FlexGridItem col='2/3' className={css.tradeSections}>
        <Grid container>
          <Grid item xs={3}>
            <ControlStateProvider loading={isSubmitting || !values.accountUrl}>
              <TradeSelectField
                name={FIXED_INCOME_FIELD_NAMES.operation}
                showSwitch
                disabled={props.editMode}
              />
            </ControlStateProvider>
          </Grid>
          {props.editMode && (
            <Grid item xs={4}>
              <SearchInputField
                name={FIXED_INCOME_FIELD_NAMES.accountUrl}
                label='Account'
                disabled
              />
            </Grid>
          )}
        </Grid>
        <Spacer xxs />
        {switchOrBuySellFields}
        <Grid container>
          <Grid item xs={6}>
            <OutlinedInputField
              name={FIXED_INCOME_FIELD_NAMES.notes}
              label='Notes'
              outlinedInputProps={{
                multiline: true,
                minRows: 4,
                maxRows: 4,
                placeholder: 'Write a note about this order ...'
              }}
            />
          </Grid>
        </Grid>
      </FlexGridItem>
    </ControlStateProvider>
  )
}
