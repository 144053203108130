import React from 'react'

import {Card} from '@d1g1t/shared/components/card'
import {Flex} from '@d1g1t/shared/components/flex'
import {RouterLink} from '@d1g1t/shared/components/router-link'
import {H4, Span} from '@d1g1t/shared/components/typography'

import * as css from './style.scss'

export interface IEmptyStateCardProps {
  text?: React.ReactNode
  modelText?: string
  icon: React.ComponentType<{className?: string}>
  createLink?: string
  onCreateClick?()
}

const renderLink = (props: IEmptyStateCardProps) => {
  if (props.createLink) {
    return (
      <RouterLink to={props.createLink}>
        <Span active onClick={props.onCreateClick} className={css.activeText}>
          create
        </Span>
      </RouterLink>
    )
  }

  return (
    <Span active onClick={props.onCreateClick} className={css.activeText}>
      create
    </Span>
  )
}

export const EmptyStateCard: React.SFC<IEmptyStateCardProps> = (props) => (
  <Card flexColumn>
    <Flex grow column justifyCenter alignCenter>
      <props.icon className={css.portfolioIcon} />
      <H4 className={css.text}>
        {props.text || (
          <>
            Please select existing or {renderLink(props)} a new{' '}
            {props.modelText} to continue.
          </>
        )}
      </H4>
    </Flex>
  </Card>
)
