import React from 'react'

import SvgIcon from '@material-ui/core/SvgIcon'

export const ExploreIcon = (props: {className?: string; colour?: string}) => (
  <SvgIcon className={props.className}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-262.000000, -124.000000)'>
        <g transform='translate(262.000000, 125.000000)'>
          <g
            transform='translate(12.000000, 0.500000)'
            strokeLinecap='round'
            stroke={props.colour || '#fff'}
            strokeLinejoin='round'
          >
            <g>
              <path d='M5.57142857,0 C8.18586164,3.15463859 8.57043457,7.52209473 5.96716309,11.133667' />
              <path
                d='M2.57142857,0 C5.18586164,3.15463859 5.57043457,7.52209473 2.96716309,11.133667'
                transform='translate(3.654132, 5.566833) scale(-1, 1) translate(-3.654132, -5.566833)'
              />
              <path d='M3.5,8 L9.88333333,8' />
              <path d='M0,3 L10.2645264,3' />
            </g>
          </g>
          <path
            d='M17,12 C20.3137085,12 23,9.3137085 23,6 C23,2.6862915 20.3137085,0 17,0 C14.2699735,0 11.9658017,1.82330049 11.238782,4.31860403 C11.1632062,4.57799817 13.0267694,6.68562231 14.0200195,8.49047852 C14.6532914,9.64121037 14.8405543,10.6409985 15.4487305,11.2944336 C16.0569066,11.9478687 16.3829584,12 17,12 Z'
            stroke={props.colour || '#fff'}
          />
          <path
            d='M15.8605274,16.9572641 L14.8581421,16.9572641 L14.5028666,16.6146761 C15.7463314,15.1681968 16.4949485,13.2903097 16.4949485,11.2474743 C16.4949485,6.69233058 12.8026179,3 8.24747425,3 C3.69233058,3 1.77635684e-15,6.69233058 1.77635684e-15,11.2474743 C1.77635684e-15,15.8026179 3.69233058,19.4949485 8.24747425,19.4949485 C10.2903097,19.4949485 12.1681968,18.7463314 13.6146761,17.5028666 L13.9572641,17.8581421 L13.9572641,18.8605274 L17.4560207,22.3522862 L18.9311481,23.8244633 L20.8612913,21.9680499 L19.4640973,20.5680558 L15.8605274,16.9572641 Z M8.24747425,16.9572641 C5.08805747,16.9572641 2.53768439,14.4068909 2.53768439,11.2474743 C2.53768439,8.08805747 5.08805747,5.53768439 8.24747425,5.53768439 C11.4068909,5.53768439 13.9572641,8.08805747 13.9572641,11.2474743 C13.9572641,14.4068909 11.4068909,16.9572641 8.24747425,16.9572641 Z'
            fill={props.colour || '#fff'}
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)
