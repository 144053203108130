import React, {useContext, useImperativeHandle, useRef, useState} from 'react'

import {useTheme} from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import {Flex} from '@d1g1t/shared/components/flex'
import {ListItemIcon} from '@d1g1t/shared/components/mui/list-item-icon'
import {Menu} from '@d1g1t/shared/components/mui/menu'
import {IMenuItemProps, MenuItem} from '@d1g1t/shared/components/mui/menu-item'
import {MenuStateContext} from '@d1g1t/shared/components/mui/menu/context'

type MenuItemProps = Omit<
  IMenuItemProps,
  'button' | 'ref' | 'onMouseEnter' | 'onMouseLeave'
>

interface INestedMenuItemProps extends MenuItemProps {
  label: React.ReactNode
}

/**
 * Wraps `<MenuItem>` with nested menu functionality.
 * - Manages nested menu open flag on menu item hover
 * - Renders children within a nested `<Menu>`
 * - Renders an arrow icon (if `children` exists)
 */
export const NestedMenuItem = React.forwardRef<
  HTMLLIElement | null,
  INestedMenuItemProps
>((props, ref) => {
  const theme = useTheme()
  const {open: parentMenuOpen} = useContext(MenuStateContext)
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const menuItemRef = useRef<HTMLLIElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => menuItemRef.current)

  const {label, icon, children, ...menuItemProps} = props

  return (
    <MenuItem
      {...menuItemProps}
      style={{
        ...(menuItemProps.style || {}),
        // keep hover styling while sub menu is open
        backgroundColor: isSubMenuOpen ? theme.palette.action.hover : undefined
      }}
      button={!!menuItemProps.onClick}
      disableRipple={isSubMenuOpen}
      ref={menuItemRef}
      onMouseEnter={() => setIsSubMenuOpen(true)}
      onMouseLeave={() => setIsSubMenuOpen(false)}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <Flex grow>{label}</Flex>
      {children && (
        <>
          <Flex alignEnd>
            <ArrowRightIcon fontSize='small' />
          </Flex>
          <Menu
            ref={menuRef}
            // Automatically close if parent is no longer open
            open={isSubMenuOpen && parentMenuOpen}
            onClose={() => setIsSubMenuOpen(false)}
            // Anchor position relative to the menu item
            anchorEl={menuItemRef.current}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            // Set pointer events to keep nested menu open on hover
            style={{pointerEvents: 'none'}}
            PaperProps={{
              style: {
                pointerEvents: 'auto'
              },
              // Prevent propagating nested menu clicks to parent menu item
              onClick: (e) => e.stopPropagation()
            }}
          >
            {children}
          </Menu>
        </>
      )}
    </MenuItem>
  )
})
