import React, {Fragment, useMemo} from 'react'

import {useApi} from 'fairlight'
import {Formik, FormikHelpers} from 'formik'
import * as Yup from 'yup'

import {RebalancingRuleEndpoints} from '@d1g1t/api/endpoints'
import {
  IMinTradeRule,
  IRebalancingRule,
  MINTRADERULE_SEVERITY_OPTIONS,
  MINTRADERULE_THRESHOLD_TYPE,
  REBALANCINGRULE_CASH_BALANCE_NEGATIVE_RULE,
  REBALANCINGRULE_MINIMUM_TRADE_AMOUNT_TYPE,
  REBALANCINGRULE_MINIMUM_TRADE_AMOUNT_TYPE_OPTIONS,
  REBALANCINGRULE_TRADE_RECOMMENDATION_RULE
} from '@d1g1t/api/models'

import {ControlStateProvider} from '@d1g1t/shared/components/control-state'
import {CheckboxField} from '@d1g1t/shared/components/form-field/checkbox-field'
import {OutlinedInputField} from '@d1g1t/shared/components/form-field/outlined-input-field'
import {ValueLabelSelectField} from '@d1g1t/shared/components/form-field/value-select-field'
import {
  DecimalNumberInput,
  PercentageInput,
  SixDecimalInput,
  WholeNumberInput
} from '@d1g1t/shared/components/formatted-input'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {ModalActions, ModalContent} from '@d1g1t/shared/components/modal'
import {Button} from '@d1g1t/shared/components/mui/button'
import {Checkbox} from '@d1g1t/shared/components/mui/checkbox'
import {FormControlLabel} from '@d1g1t/shared/components/mui/form-control-label'
import {Grid} from '@d1g1t/shared/components/mui/grid'
import {Spacer} from '@d1g1t/shared/components/spacer'
import {H2, Text} from '@d1g1t/shared/components/typography'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useFirmSecurirtyTypes} from '@d1g1t/shared/wrappers/use-firm-security-types/use-firm-security-types'

import css from './style.scss'

enum REBALANCE_RULES_FIELD_NAME {
  tradeRecommendationRule = 'tradeRecommendationRule',
  minTradeRules = 'minTradeRules',
  allowCashBalanceNegative = 'allowCashBalanceNegative',
  cashBalanceNegativeRule = 'cashBalanceNegativeRule',
  enforceAccountLevelThreshold = 'enforceAccountLevelThreshold',
  maximumTradeCount = 'maximumTradeCount',
  minimumTradeAmount = 'minimumTradeAmount',
  minimumTradeAmountType = 'minimumTradeAmountType'
}

interface IEditRuleFormValues {
  [REBALANCE_RULES_FIELD_NAME.tradeRecommendationRule]: REBALANCINGRULE_TRADE_RECOMMENDATION_RULE
  [REBALANCE_RULES_FIELD_NAME.minTradeRules]: IMinTradeRule[]
  [REBALANCE_RULES_FIELD_NAME.allowCashBalanceNegative]: boolean
  [REBALANCE_RULES_FIELD_NAME.cashBalanceNegativeRule]: REBALANCINGRULE_CASH_BALANCE_NEGATIVE_RULE
  [REBALANCE_RULES_FIELD_NAME.enforceAccountLevelThreshold]: boolean
  [REBALANCE_RULES_FIELD_NAME.maximumTradeCount]: number
  [REBALANCE_RULES_FIELD_NAME.minimumTradeAmount]: number
  [REBALANCE_RULES_FIELD_NAME.minimumTradeAmountType]: REBALANCINGRULE_MINIMUM_TRADE_AMOUNT_TYPE
}

const EDIT_RULE_VALIDATION_SCHEMA = Yup.object({
  [REBALANCE_RULES_FIELD_NAME.maximumTradeCount]: Yup.number()
    .nullable()
    .min(0)
    .label('Maximum trade size'),
  [REBALANCE_RULES_FIELD_NAME.minimumTradeAmount]: Yup.number()
    .nullable()
    .min(0)
    .label('Minimum trade amount'),

  [REBALANCE_RULES_FIELD_NAME.tradeRecommendationRule]: Yup.string()
    .required()
    .label('Target'),
  [REBALANCE_RULES_FIELD_NAME.minTradeRules]: Yup.array().of(
    Yup.object({
      threshold: Yup.number()
        .nullable()
        .when('thresholdType', {
          is: REBALANCINGRULE_MINIMUM_TRADE_AMOUNT_TYPE.PCT,
          then: Yup.number()
            .min(0, 'Must not be negative')
            .max(1, 'Must not be greater than 100%')
        })
        .when('thresholdType', {
          is: REBALANCINGRULE_MINIMUM_TRADE_AMOUNT_TYPE.UNITS,
          then: Yup.number().min(0, 'Must not be negative')
        })
        .label('Threshold'),
      thresholdType: Yup.string().required().label('Threshold Type')
    })
  )
})
const getInputType = (
  minTradeRuleThesholdType: REBALANCINGRULE_MINIMUM_TRADE_AMOUNT_TYPE
) => {
  switch (minTradeRuleThesholdType) {
    case REBALANCINGRULE_MINIMUM_TRADE_AMOUNT_TYPE.UNITS:
      return DecimalNumberInput
    case REBALANCINGRULE_MINIMUM_TRADE_AMOUNT_TYPE.PCT:
      return PercentageInput
    default:
      return WholeNumberInput
  }
}

interface IEditRuleProps {
  rule: IRebalancingRule
  onSave(rule: IRebalancingRule)
  /**
   * Name of the currently selected view
   */
  selectedRuleName: string
  /**
   * Modal used in Trade directive page should not show Cash Management or Maximum Trade Size rules
   */
  isTradeDirectivePage: boolean
}

export const EditRule: React.FC<IEditRuleProps> = function EditRule(props) {
  const api = useApi()
  const {handleFormError} = useErrorHandler()
  const {showSnackbar} = useSnackbar()
  const {firmSecurityTypeOptions, firmSecurityTypes} = useFirmSecurirtyTypes()

  const initialValues = useMemo((): IEditRuleFormValues => {
    if (!firmSecurityTypes.data) {
      return null
    }

    return {
      tradeRecommendationRule: props.rule.tradeRecommendationRule,
      allowCashBalanceNegative: props.rule.allowCashBalanceNegative,
      enforceAccountLevelThreshold: props.rule.enforceAccountLevelThreshold,
      cashBalanceNegativeRule: props.rule.cashBalanceNegativeRule,
      maximumTradeCount: props.rule.maximumTradeCount,
      minimumTradeAmountType: REBALANCINGRULE_MINIMUM_TRADE_AMOUNT_TYPE.CCY,
      minimumTradeAmount: props.rule.minimumTradeAmount,
      minTradeRules: firmSecurityTypeOptions.map((option) => {
        const ruleDetails = props.rule.minTradeRules.find(
          (minTradeRule) => minTradeRule.firmSecurityType === option.value
        )

        return {
          firmSecurityType: option.value,
          threshold: ruleDetails?.threshold,
          severity: ruleDetails?.severity,
          thresholdType:
            ruleDetails?.thresholdType || MINTRADERULE_THRESHOLD_TYPE.UNITS
        }
      })
    }
  }, [props.rule, firmSecurityTypes.data])
  const handleSubmit = async (
    values: IEditRuleFormValues,
    formikBag: FormikHelpers<IEditRuleFormValues>
  ) => {
    values.maximumTradeCount =
      values.maximumTradeCount === 0 ? 0 : values.maximumTradeCount || null
    try {
      const updatedRule = await api.request(
        RebalancingRuleEndpoints.partialUpdate(props.rule.id, {
          ...values,
          /**
           * filter out trade rules with no threshold
           */
          minTradeRules: values.minTradeRules.filter(
            (minTradeRule) => minTradeRule.threshold
          ),
          name: props.selectedRuleName
        })
      )
      showSnackbar({
        variant: 'success',
        message: `Rule '${updatedRule.name}' has been updated.`
      })
      props.onSave(updatedRule)
    } catch (error) {
      handleFormError(
        error,
        formikBag,
        initialValues,
        'An unexpected error occurred while updating the rule.'
      )
    }
  }

  return (
    <LoadingContainer loading={firmSecurityTypes.loading}>
      {firmSecurityTypes.data && (
        <Formik
          initialValues={initialValues}
          validationSchema={EDIT_RULE_VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, handleSubmit, isSubmitting}) => (
            <form onSubmit={handleSubmit} style={{display: 'contents'}}>
              <ControlStateProvider loading={isSubmitting}>
                <ModalContent>
                  {!props.isTradeDirectivePage && (
                    <>
                      <H2>Cash management</H2>
                      <Spacer xs />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.allowCashBalanceNegative}
                            onChange={() => {
                              setFieldValue(
                                'allowCashBalanceNegative',
                                !values.allowCashBalanceNegative
                              )
                              setFieldValue(
                                'cashBalanceNegativeRule',
                                REBALANCINGRULE_CASH_BALANCE_NEGATIVE_RULE.TOTAL
                              )
                            }}
                          />
                        }
                        label='Allow cash balances to be negative'
                      />
                      <Spacer xs />
                      {values.allowCashBalanceNegative && (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  values.cashBalanceNegativeRule ===
                                  REBALANCINGRULE_CASH_BALANCE_NEGATIVE_RULE.SOME
                                }
                                onChange={(event) => {
                                  setFieldValue(
                                    'cashBalanceNegativeRule',
                                    event.target.checked
                                      ? REBALANCINGRULE_CASH_BALANCE_NEGATIVE_RULE.SOME
                                      : REBALANCINGRULE_CASH_BALANCE_NEGATIVE_RULE.TOTAL
                                  )
                                }}
                              />
                            }
                            label='Allow some currencies to have negative balances, but enforce total cash to be positive'
                          />
                          <Spacer xs />
                        </>
                      )}
                    </>
                  )}
                  <CheckboxField
                    name={
                      REBALANCE_RULES_FIELD_NAME.enforceAccountLevelThreshold
                    }
                    label='Enforce account-level minimum, target and maximum cash thresholds (i.e. cash reserves)'
                  />
                  <Spacer xs />
                  <H2>Minimum trade sizes</H2>
                  <Text>
                    These limits will be used to adjust the final trade sizes
                    recommended by any security
                  </Text>
                  <Spacer xs />
                  <Grid container alignItems='center'>
                    <Grid item xs={6}>
                      <OutlinedInputField
                        name={REBALANCE_RULES_FIELD_NAME.minimumTradeAmount}
                        label='Minimum trade amount (in account currency)'
                        outlinedInputProps={{
                          inputComponent: SixDecimalInput
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Text>Minimum trade size per security type</Text>
                  <Spacer xs />
                  <div className={css.minTradeRuleFields}>
                    {values.minTradeRules.map((minTradeRule, index) => (
                      <Fragment key={minTradeRule.firmSecurityType}>
                        <Text>
                          {
                            firmSecurityTypeOptions.find(
                              (option) =>
                                option.value === minTradeRule.firmSecurityType
                            ).label
                          }
                        </Text>
                        <OutlinedInputField
                          name={`${REBALANCE_RULES_FIELD_NAME.minTradeRules}.${index}.threshold`}
                          label='Threshold'
                          smallHeight
                          outlinedInputProps={{
                            inputComponent: getInputType(
                              minTradeRule.thresholdType as unknown as REBALANCINGRULE_MINIMUM_TRADE_AMOUNT_TYPE
                            )
                          }}
                        />
                        <ValueLabelSelectField
                          name={`${REBALANCE_RULES_FIELD_NAME.minTradeRules}.${index}.thresholdType`}
                          label='Threshold Type'
                          smallHeight
                          valueLabelSelectProps={{
                            options:
                              REBALANCINGRULE_MINIMUM_TRADE_AMOUNT_TYPE_OPTIONS
                          }}
                        />
                        <ValueLabelSelectField
                          name={`${REBALANCE_RULES_FIELD_NAME.minTradeRules}.${index}.severity`}
                          label='Rule severity'
                          smallHeight
                          valueLabelSelectProps={{
                            options: MINTRADERULE_SEVERITY_OPTIONS
                          }}
                        />
                      </Fragment>
                    ))}
                  </div>
                  {!props.isTradeDirectivePage && (
                    <>
                      <H2>Maximum trade size </H2>
                      <Spacer xs />
                      <OutlinedInputField
                        name={REBALANCE_RULES_FIELD_NAME.maximumTradeCount}
                        label='Maximum number of trades'
                        outlinedInputProps={{
                          inputComponent: WholeNumberInput
                        }}
                      />
                    </>
                  )}
                </ModalContent>
                <ModalActions>
                  <Button primary contained type='submit'>
                    Save and Use Rule
                  </Button>
                </ModalActions>
              </ControlStateProvider>
            </form>
          )}
        </Formik>
      )}
    </LoadingContainer>
  )
}
