import {useEffect, useMemo} from 'react'

import escapeStringRegexp from 'escape-string-regexp'
import {useApiQuery} from 'fairlight'
import {isEmpty} from 'lodash'

import {SearchEndpoints} from '@d1g1t/api/endpoints'
import {ACCOUNTGETSINGLE_STATUS, ALL_MODELS} from '@d1g1t/api/models'

import {useDebouncedValue} from '@d1g1t/lib/hooks'

import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'

import {AvailableCustomFilter, ISearchResult} from './typings'

interface IUseSearchDataOptions {
  /**
   * will not kick off search until minimum is met, defaults to 2 if null/undefined
   */
  searchCharacterMinimum?: number
  /**
   * restrict search to models, defaults to all models if empty array or undefined/null
   */
  models?: ALL_MODELS[]
  /**
   * Sends offset param to search for pagination support
   */
  offset?: number
  /**
   * Sends limit param to search to limit number of search results
   */
  limit?: number
  /**
   * When passed, will filter this data for search instead of calling search API
   */
  preloadedData?: ISearchResult[]
  /**
   * When passed, will only return search results matching one of the provided entity ids
   * (will only return passed entities if they are in the search result)
   */
  entityIdIn?: string[]
  /**
   * When passed, will only return search results matching one of the statuses
   */
  status?: ACCOUNTGETSINGLE_STATUS[]
  /**
   * When passed, will only return search results matching isTradable status
   */
  isTradable?: boolean
  /**
   * When passed, will always include provided entity ID into search results (search results + this entity)
   */
  includedEntityId?: string
  /**
   * Controls including system generated model porfolios or not
   */
  isAccountSpecificNot?: boolean
  /**
   * Any additional filters to include
   */
  filters?: AvailableCustomFilter
  /**
   * sets isPortfolioInstrument in query param
   */
  isPortfolioInstrument?: boolean
}

/**
 * Wrapper around useApiQuery for search
 *
 * @param text - Text to search
 * @param searchCharacterMinimumParam - will not kick off search until minimum is met, defaults to 2 if null/undefined
 * @param models - restrict search to models, defaults to all models if empty array or undefined/null
 */
export function useSearchData(
  text: string,
  {
    searchCharacterMinimum: searchCharacterMinimumOption,
    models,
    status,
    isTradable,
    entityIdIn,
    offset,
    limit,
    preloadedData,
    includedEntityId,
    isAccountSpecificNot,
    filters,
    isPortfolioInstrument
  }: IUseSearchDataOptions = {}
): [Loadable<ISearchResult<any>[]>] {
  const {handleUnexpectedError} = useErrorHandler()
  const searchCharacterMinimum = Number.isFinite(searchCharacterMinimumOption)
    ? searchCharacterMinimumOption
    : 2

  const searchCharsBelowMinimum =
    searchCharacterMinimum && text.length < searchCharacterMinimum

  const preloadedSearchResults = useMemo(() => {
    if (searchCharsBelowMinimum || !preloadedData) {
      return null
    }

    const searchRegExp = new RegExp(escapeStringRegexp(text), 'i')

    return preloadedData.filter((data) => {
      return searchRegExp.test(data.displayText)
    })
  }, [searchCharsBelowMinimum, text, preloadedData])

  const [search] = useApiQuery(
    !searchCharsBelowMinimum &&
      !preloadedData &&
      SearchEndpoints.search({
        q: text,
        entityIds: entityIdIn || undefined,
        models: isEmpty(models) ? Object.values(ALL_MODELS) : models,
        status: status || undefined,
        isTradable,
        offset,
        limit,
        includedEntityId: includedEntityId || undefined,
        isAccountSpecificNot,
        filters,
        isPortfolioInstrument
      }),
    {dontReinitialize: true, useErrorBoundary: false}
  )

  const searchError = useDebouncedValue(search.error, 1000)
  useEffect(() => {
    if (searchError) {
      handleUnexpectedError(
        search.error,
        'The search unexpectedly failed. Please try again later.'
      )
    }
  }, [searchError])

  return useMemo(() => {
    if (preloadedSearchResults) {
      return [
        {
          loading: false,
          data: preloadedSearchResults,
          error: null
        }
      ]
    }

    return [
      {
        ...search,
        data: search.data?.results
      }
    ]
  }, [preloadedSearchResults, search])
}
