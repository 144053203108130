import React from 'react'
import {DropTargetConnector} from 'react-dnd'

import {
  ALL_MODELS,
  CHART_VALUE_TYPE,
  IChartTable,
  IChartTableCategory,
  IChartTableData,
  IChartTableItem
} from '@d1g1t/api/models'

import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'

import {ITooltipProps} from '@d1g1t/shared/components/mui/tooltip'
import {IDataCellProps} from '@d1g1t/shared/containers/standard-table/components/data-cell/typings'
import {useChartValueCachedFormatter} from '@d1g1t/shared/wrappers/formatter'

import {IMenuItemLinkProps} from '../../components/mui/menu-item'
import {ROW_STYLE_STATE, StandardTableComponent} from './'
import {IExpandedControlCellProps} from './components/expanded-control-cell/typings'
import {IHeaderCellProps} from './components/header-cell/typings'
import {CHECKED_STATE} from './constants'
import {IColumnFilterState} from './wrappers/table-filters-control'

export type IStandardTableAction =
  | IStandardTableClickAction
  | IStandardTableLinkAction

type IStandardTableActionBase = {
  label: React.ReactNode
  disabled?: boolean
  tooltip?: Omit<ITooltipProps, 'children'>
} & (
  | {
      quickAccess?: never

      linkedRowStyleState?: never
      icon?: React.ReactElement
    }
  | {
      /**
       * Marks the action that can be accessed on hovering the row
       */
      quickAccess: true
      /**
       * Maintains the icon visible when the row has a ROW_STYLE_STATE
       */
      linkedRowStyleState?: ROW_STYLE_STATE
      icon: React.ReactElement
    }
)

export type IStandardTableClickActionWithNestedOptions =
  IStandardTableActionBase & {
    onClick?: undefined
    nestedOptions: IStandardTableClickAction[]
  }

type IStandardTableClickActionWithoutNestedOptions =
  IStandardTableActionBase & {
    onClick: (event?: React.MouseEvent) => any
    nestedOptions?: undefined
  }
/**
 * Conditional Type using IStandardTableClickActionWithNestedOptions and IStandardTableClickActionWithoutNestedOptions. Based on presence of onClick or nestedOptions.
 *
 * If onClick is defined, nestedOptions should be undefined/not passed as a prop
 *
 * If the nestedOptions prop is defined, onClick should be undefined/not passed as a prop
 *
 */
export type IStandardTableClickAction =
  | IStandardTableClickActionWithNestedOptions
  | IStandardTableClickActionWithoutNestedOptions

type IStandardTableLinkActionWithoutNestedOptions = IStandardTableActionBase & {
  link: IMenuItemLinkProps['to']
  replace?: IMenuItemLinkProps['replace']
  nestedOptions?: undefined
}

type IStandardTableLinkActionWithNestedOptions = IStandardTableActionBase & {
  link?: undefined
  replace?: undefined
  nestedOptions: IStandardTableLinkAction[]
}

export type IStandardTableLinkAction =
  | IStandardTableLinkActionWithNestedOptions
  | IStandardTableLinkActionWithoutNestedOptions

export type StandardTableAction = (
  item: StandardTableItem,
  categories: IChartTableCategory[]
) => null | IStandardTableAction[]

/**
 * Type for name adornment callback function
 */
export type StandardTableNameAdornment = (
  item: StandardTableItem,
  categories: IChartTableCategory[]
) => JSX.Element
/**
 * Type for link override callback function.
 */
export type StandardTableCellLink = (item: StandardTableItem) => string

/**
 * Allows customizing the hiding of columns when expanded.
 *
 * If it's a boolean, all rows are hidden when expanded.
 *
 * If it's a function, will hide only hide columns that the callback
 * returns truthy for when expanded.
 */
export type CategoryIsHiddenWhenExpanded =
  | boolean
  | ((category: IStandardTableCategory) => boolean)

/**
 * Props passed to `StandardTable` by the the wrapping function
 */
export interface IStandardTableInternalProps {
  /**
   * Provides width overrides for each column
   */
  columnWidths: Dictionary<number>
  /**
   * Called when a column is resized
   */
  onColumnWidthsChange(columnWidths: Dictionary<number>): void
  /**
   * Called when the resizing drag handle has been dropped
   */
  commitColumnWidthChanges(): void
  /**
   * Provides a set of expanded columns
   */
  expandedCategories: Set<string>
  /**
   * Toggle the expansion state of a cateogry
   * @param categoryId - category id
   */
  toggleExpandedCategory(categoryId: string): void
  /**
   * Stable method which returns a formatter, to enable efficient lookup
   * of formatters for data cells
   */
  getFormatter: ReturnType<typeof useChartValueCachedFormatter>
  /**
   * Applies intermediate column filter to true column filter state
   */
  onApplyIntermediateColumnFilterState: () => void
}

export interface IStandardTableColumnWidthTypes {
  /**
   * @defaultValue 120
   */
  [CHART_VALUE_TYPE.BASIS_POINTS]?: number
  /**
   * @defaultValue 90
   */
  [CHART_VALUE_TYPE.BOOLEAN]?: number
  /**
   * @defaultValue 100
   */
  [CHART_VALUE_TYPE.DATE]?: number
  /**
   * @defaultValue 140
   */
  [CHART_VALUE_TYPE.DATETIME]?: number
  /**
   * @defaultValue 120
   */
  [CHART_VALUE_TYPE.DECIMAL]?: number
  /**
   * @defaultValue 130
   */
  [CHART_VALUE_TYPE.DECIMAL_LONG]?: number
  /**
   * @defaultValue 135
   */
  [CHART_VALUE_TYPE.DECIMAL_LONG_LONG]?: number
  /**
   * @defaultValue 100
   */
  [CHART_VALUE_TYPE.INTEGER]?: number
  /**
   * @defaultValue 140
   */
  [CHART_VALUE_TYPE.MIX]?: number
  /**
   * @defaultValue 120
   */
  [CHART_VALUE_TYPE.MONEY_ABBREVIATED]?: number
  /**
   * @defaultValue 100
   */
  [CHART_VALUE_TYPE.PERCENTAGE]?: number
  /**
   * @defaultValue 140
   */
  [CHART_VALUE_TYPE.STRING]?: number
}

export interface ICellLocation {
  categoryId: string
  itemId: string
}

export interface IStandardTableProps extends IStandardTableInternalProps {
  /**
   * A unique ID for Recoil atom, saving column widths, and sorting.
   */
  id?: string
  table: StandardResponse
  /**
   * Used to change the displayed text when the table has
   * previously loaded data, but is reloading new data
   */
  loading?: boolean
  /**
   * Disables expand/collapse all functionality. This is usually
   * desired when paginating the table data
   */
  disableExpandAll?: boolean
  /**
   * if `true`  will use compact mode style for standard table
   */
  isCompactMode?: boolean
  /**
   * Removes the check all box from the header, but leaves the row checkboxes.
   */
  hideCheckAll?: boolean
  /**
   * Initializes all columns to fully expanded
   *
   * If a function is passed in, the function acts as a filter
   * for which columns should be initially expanded.
   */
  initializeColumnsFullyExpanded?:
    | boolean
    | ((category: IChartTableCategory) => boolean)
  /**
   * If true, will hide 'Total' columns once expanded
   *
   * If a function, allows you to determine if specific
   * columns will show their 'Total' columns once expanded.
   */
  categoryIsHiddenWhenExpanded?: CategoryIsHiddenWhenExpanded
  /**
   * A map of category id to component. If a matching category id is found
   * at render time, it will render that component for the data cell
   * in the DATA ROWS
   */
  categoriesOverride?: {
    [key: string]: React.ComponentType<IDataCellProps>
  }
  /**
   * A map of category id to component. If a matching category id is found
   * at render time, it will render that component for the data cell
   * in the TOTALS ROWS
   */
  categoriesTotalOverride?: {
    [key: string]: React.ComponentType<IDataCellProps>
  }

  modelNameCategoryOptionsOverride?: Map<
    ALL_MODELS,
    IChartTableCategory['options']
  >
  /**
   * Object with keys as category ids that contain the tooltip
   * React Node as the value. Tooltip will display on hover of the
   * column title.
   */
  categoriesTooltipText?: {
    [key: string]: React.ReactNode
  }
  /**
   * If passed, this will use a debounced onChange with this time
   * to be used for debounce
   */
  debounceDelayTime?: number
  rowHeight?: number
  headerHeight?: number
  expandedHeaderHeight?: number | ((props: {index: number}) => number)
  rowsCount?: number
  maxHeight: number
  containerWidth: number
  defaultColumnWidth?: number
  defaultNameColumnWidth?: number
  reorderableColumnWidth?: number
  checkedColumnWidth?: number
  applyColumnFilterColumnWidth?: number
  /**
   * It will be applied only after user tries to change the width of a column
   * It will NOT be applied just on a page load
   */
  minColumnWidth?: number
  /**
   * It will be applied only after user tries to change the width of a column
   * It will NOT be applied just on a page load
   */
  maxColumnWidth?: number
  emptyStateElement?: React.ReactNode
  actions?: StandardTableAction
  // hides the `total` row of StandardTable
  hideTotalRow?: boolean
  disableSorting?: boolean
  /**
   * Disables the column filter
   */
  disableColumnFilter?: boolean
  /**
   * Adds button inside table to apply column filters only after
   * the apply button is clicked.
   * If column filter state is externally managed then need to also
   * pass prop `externalOverwriteColumnFilterInputState`
   * Can not be used together with {@link applyButtonBasedColumnFilter} (applyColumnFilterButton will be ignored)
   */
  applyColumnFilterButton?: boolean
  /**
   * Works similar to {@link applyColumnFilterButton} but instead of showing 1 button on the left
   * will show the apply buttons in all input fields that have something typed
   * @remarks
   * IMPORTANT! Has to be used with `minColumnWidth={100}`!
   */
  applyButtonBasedColumnFilter?: boolean
  showIcons?: boolean
  /**
   * set default column widths for certain category ids
   */
  defaultColumnWidthForIds?: {[id: string]: number}
  /**
   * set the default column widths for each data types
   */
  defaultColumnWidthTypes?: IStandardTableColumnWidthTypes
  /**
   * List of metrics/category ids that have the page period applied to them.
   * Used to avoid bug where column widths are not respected
   * when the page period is changed.
   */
  pagePeriodMetrics?: string[]
  /**
   * function that will render element to the right of data value in name column
   */
  nameAdornment?: StandardTableNameAdornment
  /**
   * If provided will override the default DataCell link
   */
  linkOverride?: StandardTableCellLink

  /**
   * How many columns from the left will not move on vertical scroll
   */
  anchoredColumns?: number
  adornmentTooltip?: React.FunctionComponent<{
    item: StandardTableItem
    category: IStandardTableCategory
  }>
  initializeItemsFullyExpanded?: boolean
  /**
   * External column filter input state. Is passed when controlled the column filter
   * outside the standard table component.
   * e.g. Paginated tables
   */
  externalColumnFilterInputState?: IColumnFilterState
  /**
   * Turns the row selection feature into a single select feature.
   * Uses radio button + returns only 1 ID.
   * Note: works only for non-nested tables.
   */
  singleSelect?: boolean
  /**
   * Enables single cell editor capability and disables the check row on cell click
   */
  singleCellEditor?: boolean
  /**
   * Disable checked rows by passing a list of item ids or a predicate function
   * to determine whether to disable the checkbox in the row
   */
  disableCheckedRows?: string[] | ((item: StandardTableItem) => boolean)
  checkedRows: Map<string, CHECKED_STATE>
  checkRowOnCellClick?: boolean
  /**
   * Removes (de)select all checkbox
   */
  checkedColumnHeader?: React.ReactNode
  /**
   * Adds a column title to the checkbox column header, keeps (de)select all checkbox.
   */
  checkedColumnTitle?: string
  printVersion?: {
    header: React.ReactNode
    footer: React.ReactNode
  }
  forwardRef?: React.Ref<StandardTableComponent>
  /**
   * External control for sorting properties
   */
  sortedColumn?: ISortColumnOptions
  /**
   * Map of rows style states possible in the table and the rowsIds that hold that state
   * @example
   * ```typescript
   * {
   *   [ROW_STYLE_STATE.HIGHLIGHTED}: [list_of_ids]
   * }
   * ```
   *
   * The order in the map determines what style state has precedence
   * @example
   * ```typescript
   * {
   *   [ROW_STYLE_STATE.FOR_DELETION}: [list_of_ids]
   *   [ROW_STYLE_STATE.HIGHLIGHTED}: [list_of_ids]
   * }
   * ```
   * In the example above whenever a row has both states the HIGHLIGHTED state will be shown
   */
  rowsInStyleState?: IRowsInStyleStates
  /**
   * List of item ids (rows) being updated, will make rows non-editable
   * and will add linear progress bar to top of table. Type is union with boolean
   * to start linear progress bar when single cell starts update request
   * (e.g. `mutating = true`)
   */
  updatingItemIds?: boolean | string[]
  /**
   * Used to not group selected rows by the ancestor node when all nestedOptions are selected.
   *
   * For example, when user checks an investment mandate, and you want a list of model IDs
   * of selected accounts rather than the model ID of the investment mandate that is the
   * ancestor node of the underlying accounts.
   */
  checkedRowsPredicate?: (item: StandardResponseItem) => boolean
  /**
   * Called when a user changes the sorting
   * @param value - next sorting value
   */
  onSortClick?(value: ISortColumnOptions): void
  onItemSelect?(id: string): void
  onTotalRowClick?(id: string): void
  onReorder?(item: StandardTableItem, order: number)
  onCellValueChange?(
    item: StandardTableItem,
    category: IStandardTableCategory,
    value: any,
    key?: any
  )

  /**
   * Autoselect the first row in the table when in singleSelect mode. This
   * allows us to side-step the issues with the initial table load having
   * nothing selected until the user interacts with it.
   *
   * @defaultValue false
   */
  autoSelectFirstItem?: boolean
  onCheckboxedRows?(ids: string[], leafIds?: string[])
  isRowSelectable?(item: StandardTableItem)
  /**
   * How many rows from the bottom before `onEndReached` is called
   * @defaultValue 0 - number of rows
   */
  onEndReachedRowCountThreshold?: number
  /**
   * Called when the table has been scrolled to the bottom
   */
  onEndReached?(): void
  /**
   * Called when items are rendered.
   * Used for incrementally loaded table responses. (Paginated)
   * @param chartRanges - Array of all ranges visible to the user
   */
  onItemsRendered?(itemPageIds: IItemPageId[]): void
  /**
   * Called when the toggle filter button is pressed in the header
   */
  externalToggleColumnFilterActive?(): void
  /**
   * Called when a user inputs a value for a column filter
   */
  externalSetColumnFilterInputStateAtKey?: (
    key: string,
    enumerator: boolean
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * Called when user hits apply column filter button
   * (used in cases where standard table has the prop `applyColumnFilterButton`)
   */
  externalOverwriteColumnFilterInputState?: (
    newColumnFilterState: IColumnFilterState
  ) => void
  /**
   * Callback to add a colour callout to the left of a row, this method should
   * be fast as it may get called often.
   */
  colourCallout?(item: StandardTableItem): string | string[]
  /**
   * Callback to optionally pass `allowZero: boolean` to the formatter of the cell
   */
  allowZeroForCell?(item: StandardTableItem): boolean
  /**
   * Optional override to externally control how we get the value for a cell.
   * If this method returns `undefined` (but not `null`), it will use the default
   * value getter.
   * @param options - Properties of this cell.
   */
  getCellValue?(options: {
    item: StandardTableItem
    category: IStandardTableCategory
    data: IChartTableData
  }): StrNum
  /**
   * Optionally return an error for a given cell. This will outline
   * the input if the field is editable
   * @param options -Properties of this cell.
   */
  getCellError?(options: {
    item: StandardTableItem
    category: IStandardTableCategory
  }): string
  /**
   * If no results, displays this text instead of the default text
   */
  noResultsText?: string
  nestedOptions?: never
  ExpandedControlCellProps?: Pick<IExpandedControlCellProps, 'startAdornment'>
  HeaderCellProps?: Pick<IHeaderCellProps, 'startAdornment'>
}

export type IRowsInStyleStates = Partial<Record<ROW_STYLE_STATE, string[]>>

export type ITableRowStyleState = Map<number, ROW_STYLE_STATE>

export interface IStandardTableDimentionProps
  extends Optional<IStandardTableProps, 'maxHeight' | 'containerWidth'> {}

export interface IStandardTablePublicProps
  extends Omit<
    IStandardTableDimentionProps,
    'checkedRows' | keyof IStandardTableInternalProps | 'table'
  > {
  checkedRows?: string[]
  table: IChartTable | StandardResponse
}

export interface IStandardTableState {
  items: StandardTableItem[]
  /**
   * An array of length `items.length` mapping each item
   * to the corresponding page id
   */
  itemPageIds: IItemPageId[]
  total: Nullable<StandardTableItem>
  expandedItems: Set<string>
  expandedCategoriesDepth: number
  allTableRowsCount: number
  hoverRow: Nullable<number>
  hoverColumn: Nullable<number>
  renderCategories: IStandardTableCategory[]
  totalWidth: number
  dragSourceRow: Nullable<number>
  dragSourceItem: Nullable<StandardTableItem>
  dropTargetRow: Nullable<number>
  horizontalScrollbarSize: number
  verticalScrollbarSize: number
  resizingColumn: Nullable<number>
  expandable: boolean
  deferUpdate: boolean
  tableIsFullyExpanded: boolean
  print: boolean
  columnFilterFocusedId: string

  itemsPropReference: IChartTableItem[]
  categoriesPropReference: IChartTableCategory[]
  rowsInStyleStatePropReference: Nullable<
    IStandardTableProps['rowsInStyleState']
  >
  /**
   * holds a list of row indexes in the table and the associated row style state for that row
   */
  rowsIndexesInStyleState: ITableRowStyleState
  expandedCategoriesPropReference: Set<string>
  categoryIsHiddenWhenExpandedPropReference: CategoryIsHiddenWhenExpanded
  /**
   * Tracking the last rendered section so we can
   * access it in instances other than when `MultiGrid#onSectionRendered`
   * is fired.
   */
  lastRenderedSection: IndexBox
  toggleFilterVisible: boolean
  /**
   * Location of active single cell edit. `null` if single cell editor is
   * not active.
   */
  activeEditingCellLocation: ICellLocation
}

/**
 * Enhances StandardResponseItem with table-specific context,
 * such as expansion/collapsed flags.
 */
export class StandardTableItem extends StandardResponseItem {
  constructor(item: StandardResponseItem) {
    super(item.item, item.originalResponse, item.itemParent)
  }

  expanded = false

  fullyExpanded?: boolean

  fullyCollapsed?: boolean

  group = false

  level = -1

  parent?: StandardTableItem
}

/**
 * A unique page identifier for pagination request purposes
 */
export interface IItemPageId {
  parentPath: string
  indexInLevel: number
}

export interface IStandardTableCategory extends IChartTableCategory {
  width?: number
  expanded: boolean
  level: number
  parent?: IStandardTableCategory
  hiddenWhenExpanded?: boolean
  categories?: IStandardTableCategory[]
}

export interface ICellInternalProps {
  columnKey: string
  height: number
  width: number
  rowIndex: number
}

/**
 * @deprecated IDropTargetCollect
 */
export interface IDropTargetCollect {
  connectDropTarget: ReturnType<DropTargetConnector['dropTarget']>
  isOver: boolean
  canDrop: boolean
}

export interface IDropSpecProps {
  rowIndex: number
  onDragHover(rowIndex: number)
  onDrop(rowIndex: number)
}

export interface ISortColumnOptions {
  categoryId?: string
  sortOrder?: SORT_ORDER
}

/**
 * An index box defines the bound of a "window" within a virtualized table
 */
export type IndexBox = {
  columnStartIndex: number
  columnOverscanStartIndex: number
  columnStopIndex: number
  columnOverscanStopIndex: number
  rowStartIndex: number
  rowOverscanStartIndex: number
  rowStopIndex: number
  rowOverscanStopIndex: number
}
