import React from 'react'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import {ButtonLink} from '@d1g1t/shared/components/router-link'

import {useNavigationSectionContext} from '@d1g1t/advisor/wrappers/navigation-section'
import {
  NavigationSection,
  NavigationSectionToLocationPathMap
} from '@d1g1t/advisor/wrappers/navigation-section/constants'

/**
 * Allows advisors to go back to the 3 main lists.
 */
export const BackToListButton: React.FC = () => {
  const {section} = useNavigationSectionContext()
  if (
    !section ||
    ![
      NavigationSection.CLIENT_PORTFOLIOS,
      NavigationSection.HOLDINGS,
      NavigationSection.HOUSEHOLDS,
      NavigationSection.CLIENTS,
      NavigationSection.ACCOUNTS,
      NavigationSection.CLIENT_MANDATES,
      NavigationSection.MANAGE_PORTFOLIOS,
      NavigationSection.SECURITY_MODELS
    ].includes(section)
  ) {
    return null
  }

  return (
    <ButtonLink to={NavigationSectionToLocationPathMap[section]}>
      <ArrowBackIcon />
      {`Back to ${section}`}
    </ButtonLink>
  )
}
