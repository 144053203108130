import {useRouteMatch} from 'react-router'

import {useApiQuery} from 'fairlight'

import {PrimedDatesEndpoints} from '@d1g1t/api/endpoints'
import {PRIMEDDATE_PAGE} from '@d1g1t/api/models'

import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'

import {
  ClientsLocations,
  monitorPath,
  TrackComplianceLocations
} from '@d1g1t/advisor/locations'

interface IUsePrimedDatesReturn {
  /**
   * Return the oldest day from PrimedDatesEndpoints if enabled,
   * otherwise from firmConfiguration
   */
  oldestPrimedDate: string
  /**
   * Return the latest day from PrimedDatesEndpoints if enabled,
   * otherwise from firmConfiguration
   */
  latestPrimedDate: string
  /**
   * Return array of Prime Dates in descending order
   */
  primedDates: string[]
}

/**
 * Used to get min prime date, max prime date and prime dates.
 * If `enablePrimedDates` is `false` will use data from `firmConfiguration`.
 *
 * @param enablePrimedDates - Controls if we should use primed dates in calculation request processing.
 * This allows us to turn it off for the investor application. If `false` will use `firmConfiguration`
 * instead of `primeDates`
 */
export const usePrimedDates = (
  enablePrimedDates = true
): IUsePrimedDatesReturn => {
  const {firmConfiguration} = useFirmConfiguration()

  const primedPage = (() => {
    const trackMandatesMatch = useRouteMatch(
      TrackComplianceLocations.trackMandates()
    )
    const clientsLocationsMatch = useRouteMatch(ClientsLocations.basePath)
    const monitorLocationsMatch = useRouteMatch(monitorPath())

    if (trackMandatesMatch) {
      return PRIMEDDATE_PAGE.TRACKMANDATESPAGE
    }
    if (clientsLocationsMatch) {
      return PRIMEDDATE_PAGE.MANAGECLIENTS
    }
    if (monitorLocationsMatch) {
      return PRIMEDDATE_PAGE.MONITORBUSINESS
    }
    return null
  })()

  const shouldUsePrimedDate = enablePrimedDates && primedPage !== null

  // Dates are returned in descending order
  const [primedDatesData] = useApiQuery(
    shouldUsePrimedDate &&
      PrimedDatesEndpoints.list({
        page: primedPage
      }),
    {
      fetchPolicy: 'cache-first'
    }
  )

  if (!!!shouldUsePrimedDate && !!firmConfiguration.data) {
    return {
      oldestPrimedDate: firmConfiguration.data.latestDataAvailable,
      latestPrimedDate: firmConfiguration.data.latestDataAvailable,
      primedDates: null
    }
  }

  const primedDates: string[] = primedDatesData.data?.results.map(
    (primedDate) => primedDate.date
  )
  return {
    latestPrimedDate: primedDates?.[0],
    oldestPrimedDate: primedDates?.[primedDates.length - 1],
    primedDates
  }
}
