import React, {useMemo, useState} from 'react'

import {ApiError, useApi, useApiMutation} from 'fairlight'
import FileSaver from 'file-saver'
import produce from 'immer'
import {isNil} from 'lodash'
import pluralize from 'pluralize'

import DownloadIcon from '@material-ui/icons/CloudDownload'
import UploadIcon from '@material-ui/icons/CloudUpload'
import TradeIcon from '@material-ui/icons/SwapVerticalCircle'

import {
  IAggregateResponse,
  IDownloadAllocation,
  ITradeOrderChartRequestBody,
  TradingEndpoints,
  TradingOrderEndpoints
} from '@d1g1t/api/endpoints'
import {
  FILTERCRITERION_NODE_TYPE,
  IChartTable,
  ITradeOrder,
  METRIC_T_CURRENCY,
  METRIC_T_INSTRUMENT_CLOSE,
  METRIC_T_LIMIT_PRICE,
  METRIC_T_OPERATION,
  METRIC_T_QTY,
  METRIC_T_QTY_FILLED_PERCENTAGE,
  METRIC_T_STATUS,
  METRIC_T_TYPE,
  METRICGROUP_RELATED_MODEL,
  TRADEORDER_SECONDARY_STATUS,
  TRADEORDER_STATUS,
  TRADEORDER_TYPE,
  UI_PERMISSIONS
} from '@d1g1t/api/models'
import {IPaginationOptions} from '@d1g1t/api/pagination-typings'

import {canUpdateSingleRow} from '@d1g1t/lib/chart-table/lib'
import {MIME_TYPE} from '@d1g1t/lib/constants'
import {
  DATE_OPTION_DAY,
  DEFAULT_TRANSACTION_DATE_OPTIONS
} from '@d1g1t/lib/date-range'
import {useToggleState} from '@d1g1t/lib/hooks'
import {mapMetricsToRequest} from '@d1g1t/lib/metrics'
import {
  StandardResponse,
  StandardResponseItem
} from '@d1g1t/lib/standard-response'
import {extractIdFromUrl} from '@d1g1t/lib/url'

import {Card} from '@d1g1t/shared/components/card'
import {confirm} from '@d1g1t/shared/components/confirmation'
import {Flex} from '@d1g1t/shared/components/flex'
import {LoadingContainer} from '@d1g1t/shared/components/loading-container'
import {IIconMenuMenuItemProps} from '@d1g1t/shared/components/mui/icon-menu'
import {DateRangeSelector} from '@d1g1t/shared/components/range-selector'
import {
  TableGrid,
  TableGridBody,
  TableGridHeader
} from '@d1g1t/shared/components/table-grid'
import {P} from '@d1g1t/shared/components/typography'
import {WidgetTitleBar} from '@d1g1t/shared/containers/calculation-widgets/widget-title-bar'
import {useSnackbar} from '@d1g1t/shared/containers/snackbar'
import {
  IStandardTableCategory,
  StandardTable,
  StandardTableItem,
  useTableColumnFilterControl,
  useTableSortControl
} from '@d1g1t/shared/containers/standard-table'
import {AllocationStatusCell} from '@d1g1t/shared/containers/standard-table/components/custom-types/allocation-status'
import {CurrencyCell} from '@d1g1t/shared/containers/standard-table/components/custom-types/currency'
import {FillCell} from '@d1g1t/shared/containers/standard-table/components/custom-types/fill'
import {
  IStandardTableAction,
  StandardTableAction
} from '@d1g1t/shared/containers/standard-table/typings'
import {useSelectedView} from '@d1g1t/shared/containers/view-options/hooks'
import {useChartPaginator} from '@d1g1t/shared/wrappers/chart-paginator'
import {useErrorHandler} from '@d1g1t/shared/wrappers/error-handler'
import {useExportExcel} from '@d1g1t/shared/wrappers/export-excel'
import {useFileUploader} from '@d1g1t/shared/wrappers/file-uploader'
import {useFirmConfiguration} from '@d1g1t/shared/wrappers/firm-configuration'
import {useGlobalSettings} from '@d1g1t/shared/wrappers/global-settings'
import {withPermissions} from '@d1g1t/shared/wrappers/permissions'

import {
  CheckedItemsActions,
  ICheckedItemAction,
  ICheckedItemActions,
  useSelectedIds
} from '@d1g1t/advisor/components/checked-items-actions'
import {PageTitleBar} from '@d1g1t/advisor/containers/page-title-bar'
import {OperationCell} from '@d1g1t/advisor/containers/standard-table/components/custom-types/operation'
import {TradeModal} from '@d1g1t/advisor/containers/trade-modal'

import {EditTradeModal} from './components/edit-trade-modal'
import {EditableTradeOrderQuantity} from './components/edit-trade-modal/editable-enums'
import {
  BLOTTER_CATEGORY_IDS,
  MANAGE_ORDERS_ADDITIONAL_FITLERS,
  PREFIX,
  proAndNonProErrorMsg,
  TRADE_ORDER_CATEGORY_ID_TO_KEY_MAP
} from './constants'
import {
  DEPENDENT_CATEGORY_IDS_LIST,
  displaySellAllForItem,
  EXECUTABLE_TRADE_ACTION_TYPE,
  getActionsForItem,
  getBulkActions,
  ITradeOrderAction,
  TRADE_ORDER_CHANGE_STATUS,
  tradeOrderNameAdornment
} from './lib'
import {ReviewAggregatedTradeModal} from './review-aggregated-trade-modal'

const ManageOrdersPageContent: React.FC = () => {
  const api = useApi()
  const {handleUnexpectedError} = useErrorHandler()
  const {showSnackbar} = useSnackbar()

  const [{displayOptions, viewKey}] = useSelectedView(PREFIX)
  const {firmConfiguration} = useFirmConfiguration()

  const [settings, {updateGlobalSettingsKeys}] = useGlobalSettings(
    'MANAGE_ORDERS_PAGE_SETTINGS',
    {
      period: DATE_OPTION_DAY
    }
  )

  const [sortOptions, setSortOptions] = useTableSortControl()

  const [tradeModalOpen, , setTradeModalOpen, setTradeModalClosed] =
    useToggleState(false)

  const {
    columnFilterState,
    setColumnFilterState,
    showColumnFilter,
    toggleShowColumnFilter,
    overwriteColumnFilterState
  } = useTableColumnFilterControl(viewKey)

  const marketPriceInSelectedMetrics = displayOptions?.metrics.find(
    (metric) =>
      extractIdFromUrl(metric.metric) === METRIC_T_INSTRUMENT_CLOSE.slug
  )

  const getRequestBody = (withMarketPrice: boolean) => {
    if (!displayOptions || !settings?.period) {
      return null
    }

    const selectedMetrics = mapMetricsToRequest(displayOptions.metrics)

    if (!marketPriceInSelectedMetrics && withMarketPrice) {
      selectedMetrics.push(METRIC_T_INSTRUMENT_CLOSE)
    }

    return {
      metrics: {
        selected: selectedMetrics
      },
      filter: displayOptions.filter,
      options: {
        dateRange: settings?.period,
        showTradesAsUngroupedItems:
          displayOptions.displayData.showTradesAsUngroupedItems
      }
    }
  }

  const apiRequestBody = useMemo((): ITradeOrderChartRequestBody => {
    return getRequestBody(true)
  }, [displayOptions, settings?.period])

  const exportExcel = useExportExcel(
    TradingOrderEndpoints.getExcel(getRequestBody(false)),
    'manage-orders-download'
  )

  const fetchTable = useMemo(() => {
    if (!apiRequestBody) {
      return null
    }
    return (pagination: IPaginationOptions) => {
      return api.request(
        TradingOrderEndpoints.chart({
          ...apiRequestBody,
          pagination
        })
      )
    }
  }, [apiRequestBody])

  const [manageOrdersTable, {fetchRanges, refresh: refreshTable, setResponse}] =
    useChartPaginator(fetchTable, {
      sortOptions,
      columnFilter: columnFilterState,
      disableDebounceColumnFilter: true,
      additionalFiltersForCategoryId: MANAGE_ORDERS_ADDITIONAL_FITLERS
    })

  const [uploadingTradeOrderUpdates, openFileUploader] = useFileUploader({
    mimeTypes: [MIME_TYPE.csv],
    upload: (selectedFiles) => {
      const data = new FormData()
      data.append('file_name', selectedFiles[0])
      return api.request(TradingEndpoints.events(data))
    },
    onSuccess: refreshTable
  })

  /**
   * When trade driver is set to manual download via UI, generates a CSV
   * with trade instructions when trades are "Sent for Execution"
   */
  const downloadEvents = (() => {
    if (firmConfiguration.data?.trading?.manualDriverUpload !== 'ui') {
      return NOOP
    }

    return async () => {
      try {
        const eventData = await api.request(
          TradingEndpoints.downloadEvents({trade_order_entity_ids: selectedIds})
        )

        if (!eventData?.length) {
          return
        }

        for (const eventSet of eventData) {
          FileSaver.saveAs(
            new Blob([eventSet.data], {type: MIME_TYPE.csv}),
            `${eventSet}_events.csv`
          )
        }
        showSnackbar({
          variant: 'success',
          // TODO: PS need to return the message to show, will be implemented in v5.0
          message: 'Allocation Generated'
        })
      } catch (error) {
        handleUnexpectedError(
          error,
          'An unexpected error occurred while generating trade execution files.'
        )
      }
    }
  })()

  // List of ids of checked items in the manage orders table
  const [selectedIds, setSelectedIds] = useSelectedIds(manageOrdersTable.data)

  // Ids that will be sent to single/bulk single/aggregated trade order edit
  // modals to know which data to load.
  const [editingIds, setEditingIds] = useState<string[]>([])

  const handleTradeOrderUpdate = (data: StandardResponse) => {
    setResponse(data)
  }

  const [handleUpdateOrderStatus, {mutating: updatingOrderStatus}] =
    useApiMutation({
      mutation: (tradeOrderId: string, status: TRADEORDER_STATUS) => (api) =>
        api.request(
          TradingOrderEndpoints.partialUpdate(
            tradeOrderId,
            // Trade order cancellation is now done by PATCHing the property secondary status
            // https://www.notion.so/d1g1t/Trading-3608ddb4629642288ee77cca39beb25b#c1448122e4c7403da70684dc4463a5be
            status === TRADEORDER_STATUS.CANCELLED
              ? {secondaryStatus: TRADEORDER_SECONDARY_STATUS.CANCELREQUESTED}
              : {status}
          )
        ),
      onSuccess: (tradeOrder, {mutationArgs: [, status]}) => {
        if (status === TRADEORDER_STATUS.PENDING_EXECUTION) {
          downloadEvents()
        }

        if (
          tradeOrder.secondaryStatus ===
          TRADEORDER_SECONDARY_STATUS.CANCELREJECTED
        ) {
          showSnackbar({
            variant: 'error',
            message: 'Trade order cancellation was rejected.'
          })
        }

        refreshTable()
      },
      onError: (error) =>
        handleUnexpectedError(
          error,
          'An unexpected error occurred while updating the trade order status.',
          true,
          true
        )
    })

  const [handleAggregateTrades, {mutating: aggregating}] = useApiMutation({
    mutation:
      (items: StandardResponseItem[], override = false) =>
      async (api) => {
        const itemIds = items.map((item) => item.id)
        const response = await api.request(
          TradingOrderEndpoints.aggregate({
            tradeOrders: itemIds,
            overrideBundleCheck: override
          })
        )

        return {...response, idsRequested: itemIds}
      },
    onSuccess: ({
      idsRequested,
      resultingParents,
      skipped
    }: IAggregateResponse & {idsRequested: string[]}) => {
      // check newly-aggregated ids
      setSelectedIds(resultingParents)
      refreshTable()

      const numOrdersAggregated = idsRequested.length - skipped.length

      confirm({
        title: 'Aggregate Results',
        disableCancel: true,
        content: (
          <>
            {resultingParents.length > 0 && (
              <P>
                {numOrdersAggregated} {pluralize('order', numOrdersAggregated)}{' '}
                have been aggregated into {resultingParents.length}{' '}
                {pluralize('order', resultingParents.length)}.
              </P>
            )}
            {skipped.length > 0 && (
              <P>
                {skipped.length} {pluralize('order', skipped.length)} could not
                be aggregated.
              </P>
            )}
          </>
        )
      })
    },
    onError: async (error: ApiError, context) => {
      // Show modal if user want to override aggregating pro and non pro orders
      if (
        error.status === 400 &&
        (error.responseBody as string[]).some(
          (errMsg) => errMsg === proAndNonProErrorMsg
        )
      ) {
        const confirmed = await confirm({
          title: 'Confirm Override',
          content: <p>{proAndNonProErrorMsg}</p>
        })
        if (confirmed) {
          handleAggregateTrades(context.mutationArgs[0], true)
        }
        return
      }
      // and not pro and non-pro error
      handleUnexpectedError(
        error,
        'An unexpected error occurred while aggregating the trades.',
        true,
        true
      )
    }
  })

  const [handleDisaggregateTrades, {mutating: disaggregating}] = useApiMutation(
    {
      mutation: (items: StandardResponseItem[]) => (api) => {
        return api.request(
          TradingOrderEndpoints.disaggregate({
            tradeOrders: items.map((item) => item.id)
          })
        )
      },
      onSuccess: (disaggregatedTradeOrders: ITradeOrder[]) => {
        showSnackbar({
          variant: 'success',
          message: 'The trades have been successfully been disaggregated.'
        })

        // check newly disaggregated ids
        setSelectedIds(
          disaggregatedTradeOrders.map((tradeOrder) =>
            extractIdFromUrl(tradeOrder.url)
          )
        )
        refreshTable()
      },
      onError: (error) => {
        handleUnexpectedError(
          error,
          'An unexpected error occurred while disaggregating one or more trades.',
          true,
          true
        )
      }
    }
  )

  const [handleAllocateTrades, {mutating: allocating}] = useApiMutation({
    mutation: (items: StandardResponseItem[]) => async (api) => {
      const tradeOrderIds: string[] = []
      const parentTradeChartPromiseList: Promise<IChartTable>[] = []

      // Need to get id of children from the aggregated trade order as the
      // bulk allocate endpoint does not accept aggregated parent trade
      // order ids
      for (const item of items) {
        if (item.getValue(BLOTTER_CATEGORY_IDS.IS_AGGREGATED_PARENT)) {
          parentTradeChartPromiseList.push(
            api.request(TradingOrderEndpoints.aggregateChart(item.id, {}))
          )

          continue
        }

        tradeOrderIds.push(item.id)
      }

      const parentTradeChartResponses = await Promise.all(
        parentTradeChartPromiseList
      )

      for (const parentTradeChartResponse of parentTradeChartResponses) {
        tradeOrderIds.push(
          ...new StandardResponse(parentTradeChartResponse)
            .allItemIds()
            .slice(1) // First item is id of parent
        )
      }

      const allocationData = await api.request(
        TradingOrderEndpoints.bulkAllocate(
          tradeOrderIds.map((id) => ({tradeOrder: id}))
        )
      )

      for (const allocationSet of allocationData) {
        FileSaver.saveAs(
          new Blob([allocationSet.data], {type: MIME_TYPE.csv}),
          `${allocationSet}.csv`
        )
      }

      return allocationData
    },
    onSuccess: (allocationData: IDownloadAllocation[]) => {
      showSnackbar({
        variant: 'success',
        // TODO: PS need to return the message to show, will be implemented in v5.0
        message: 'Allocation Generated'
      })
    },
    onError: (error) => {
      handleUnexpectedError(
        error,
        'An unexpected error occured while allocating the trades.',
        true,
        true
      )
    }
  })

  const handleExecuteAction = (
    action: EXECUTABLE_TRADE_ACTION_TYPE,
    items: StandardResponseItem[]
  ) => {
    switch (action) {
      case EXECUTABLE_TRADE_ACTION_TYPE.edit:
        setEditingIds(items.map((item) => item.id))

        break
      case EXECUTABLE_TRADE_ACTION_TYPE.aggregate:
        handleAggregateTrades(items)
        break
      case EXECUTABLE_TRADE_ACTION_TYPE.disaggregate:
        handleDisaggregateTrades(items)
        break
      case EXECUTABLE_TRADE_ACTION_TYPE.allocate:
        handleAllocateTrades(items)
    }
  }

  // Table row actions
  const rowActions: StandardTableAction = (item) => {
    const actions = getActionsForItem(
      item,
      firmConfiguration.data?.tradingTypePlatforms
    ).map((action: ITradeOrderAction): IStandardTableAction => {
      const handleActionClick = () => {
        if (action.type === TRADE_ORDER_CHANGE_STATUS) {
          handleUpdateOrderStatus(item.id, action.transitionStatus)
        } else {
          handleExecuteAction(action.type, [item])
        }
      }

      return {
        icon: action.icon,
        label: action.label,
        onClick: handleActionClick
      }
    })

    return actions.length === 0 ? null : actions
  }

  const [handleAggregateAll, {mutating: aggregatingAll}] = useApiMutation({
    mutation: () => (api) => {
      return api.request(TradingOrderEndpoints.aggregateAll(apiRequestBody))
    },
    onSuccess: (response: {totalCount: number}) => {
      showSnackbar({
        variant: 'success',
        message: `Submitted ${response.totalCount} items for aggregation`
      })
    },
    onError: (error) => {
      handleUnexpectedError(
        error,
        'An unexpected error occurred while aggregating all trades',
        true,
        true
      )
    }
  })

  const handleAggregateAllClick = async () => {
    if (
      !(await confirm({
        title: 'Confirm',
        content: (
          <P>
            Aggregating {manageOrdersTable.data.count} orders. You may continue
            to use the application while your orders are being aggregated
            asynchronously.
          </P>
        ),
        confirmLabel: 'Confirm'
      }))
    ) {
      return
    }
    handleAggregateAll()
  }

  const [handleMultipleStatusChanges, {mutating: changingMultipleStatuses}] =
    useApiMutation({
      mutation: (status: TRADEORDER_STATUS) => (api) => {
        return api.request(
          TradingOrderEndpoints.bulkUpdate(
            selectedIds.map((entityId) => ({
              entityId,
              ...(status === TRADEORDER_STATUS.CANCELLED
                ? {secondaryStatus: TRADEORDER_SECONDARY_STATUS.CANCELREQUESTED}
                : {status})
            }))
          )
        )
      },
      onSuccess: (tradeOrders, {mutationArgs: [status]}) => {
        if (status === TRADEORDER_STATUS.PENDING_EXECUTION) {
          downloadEvents()
        }

        if (
          tradeOrders.find(
            (tradeOrder) =>
              tradeOrder.secondaryStatus ===
              TRADEORDER_SECONDARY_STATUS.CANCELREJECTED
          )
        ) {
          showSnackbar({
            variant: 'error',
            message:
              'One or more of the trade order cancellations were rejected.'
          })
        }

        refreshTable()
      },
      onError: (error) => {
        handleUnexpectedError(
          error,
          'An unexpected error occurred while changing one or more order statuses.',
          true,
          true
        )
      }
    })

  // Action group at the top
  const checkedItemsActions: ICheckedItemActions = getBulkActions(
    manageOrdersTable.data,
    selectedIds,
    firmConfiguration.data?.tradingTypePlatforms
  ).map((actionGroup) =>
    actionGroup.map((tradeOrderAction): ICheckedItemAction => {
      const handleCheckedItemsActionClick = () => {
        if (tradeOrderAction.type === TRADE_ORDER_CHANGE_STATUS) {
          handleMultipleStatusChanges(tradeOrderAction.transitionStatus)
          return
        }

        if (
          tradeOrderAction.type === EXECUTABLE_TRADE_ACTION_TYPE.aggregateAll
        ) {
          handleAggregateAllClick()
          return
        }

        handleExecuteAction(
          tradeOrderAction.type,
          selectedIds.map((id) => manageOrdersTable.data.findItemById(id))
        )
      }

      return {
        icon: tradeOrderAction.icon,
        label: tradeOrderAction.label,
        onClick: handleCheckedItemsActionClick,
        ignoreZeroCount: tradeOrderAction.ignoreZeroCount
      }
    })
  )

  const table = useMemo(() => {
    if (!manageOrdersTable.data) {
      return null
    }

    return produce(manageOrdersTable.data, (draft) => {
      // set `editable: false` for QTY cells
      // so that the custom QTY cell is rendered
      draft.applyReplaceItems(
        manageOrdersTable.data.items.map((item) => {
          if (displaySellAllForItem(item)) {
            return produce(item.item, (draft) => {
              const datum = draft.data.find(
                ({categoryId}) => categoryId === METRIC_T_QTY.slug
              )

              if (datum) {
                datum.options = datum.options || {}
                datum.options.editable = false
              }
            })
          }

          return item.item
        })
      )

      if (!marketPriceInSelectedMetrics) {
        draft.applyCategoryOptions([METRIC_T_INSTRUMENT_CLOSE.slug], {
          hidden: true
        })

        draft.applyCategoryNames([
          {
            categoryId: METRIC_T_LIMIT_PRICE.slug,
            name: 'Limit Price'
          }
        ])
      }
    })
  }, [marketPriceInSelectedMetrics, manageOrdersTable.data])

  // Handles when the editable cell values change
  const [handleCellValueChange, {mutating: updatingCellValue}] = useApiMutation(
    {
      mutation:
        (
          item: StandardTableItem,
          category: IStandardTableCategory,
          value: any,
          key?: any
        ) =>
        async (api) => {
          const updateRequestData: Partial<ITradeOrder> = {
            [TRADE_ORDER_CATEGORY_ID_TO_KEY_MAP[category.id]]: !isNil(key)
              ? key
              : value
          }

          const isOrderTypeCategoryId = category.id === METRIC_T_TYPE.slug
          const tradeOrderTypeChangingToLimit = key === TRADEORDER_TYPE.LIMIT
          const tradeOrderTypeChangingToMarket = key === TRADEORDER_TYPE.MARKET
          const marketPrice = item.getValue(METRIC_T_INSTRUMENT_CLOSE.slug)

          if (isOrderTypeCategoryId && tradeOrderTypeChangingToLimit) {
            updateRequestData.limitPrice = marketPrice
          }

          if (isOrderTypeCategoryId && tradeOrderTypeChangingToMarket) {
            updateRequestData.limitPrice = null
          }

          await api.request(
            TradingOrderEndpoints.partialUpdate(item.id, updateRequestData)
          )

          return [item, category, value, key]
        },
      onSuccess: (
        values: [StandardTableItem, IStandardTableCategory, any, any]
      ) => {
        const [item, category, value, key] = values

        const isOrderTypeCategoryId = category.id === METRIC_T_TYPE.slug
        const tradeOrderTypeChangingToLimit = key === TRADEORDER_TYPE.LIMIT
        const tradeOrderTypeChangingToMarket = key === TRADEORDER_TYPE.MARKET
        const marketPrice = item.getValue(METRIC_T_INSTRUMENT_CLOSE.slug)

        const manualResponseUpdateValues = [
          {
            categoryId: category.id,
            itemId: item.id,
            value,
            key
          }
        ]

        if (isOrderTypeCategoryId && tradeOrderTypeChangingToLimit) {
          manualResponseUpdateValues.push({
            categoryId: METRIC_T_LIMIT_PRICE.slug,
            itemId: item.id,
            value: marketPrice,
            key: undefined
          })
        }

        if (isOrderTypeCategoryId && tradeOrderTypeChangingToMarket) {
          manualResponseUpdateValues.push({
            categoryId: METRIC_T_LIMIT_PRICE.slug,
            itemId: item.id,
            value: undefined,
            key: undefined
          })
        }

        let response = manageOrdersTable.data

        for (const responseUpdate of manualResponseUpdateValues) {
          response = response.updateValue(
            responseUpdate.categoryId,
            responseUpdate.itemId,
            responseUpdate.value,
            responseUpdate.key
          )
        }

        setResponse(response)

        // Starting off not sending dependentCategoryIds list at first as would should
        // find out from FEs which categories are dependent on each other.
        // This will disable single row update whenever sort is active on any column
        // for now to be safe.
        if (
          canUpdateSingleRow(
            category.id,
            sortOptions.categoryId,
            DEPENDENT_CATEGORY_IDS_LIST
          )
        ) {
          const index = manageOrdersTable.data.indexAtItem(item.id)

          fetchRanges(null, [
            {
              parentPath: 'root',
              startIndex: index,
              stopIndex: index
            }
          ])
        } else {
          refreshTable()
        }
      },
      onError: (error) => {
        handleUnexpectedError(
          error,
          'An unexpected error occurred while updating the order.'
        )
      }
    }
  )

  const overflowMenuOptions = (() => {
    const options: IIconMenuMenuItemProps[] = [
      {
        icon: <TradeIcon style={{transform: 'rotate(90deg)'}} />,
        label: 'Trade',
        onClick: setTradeModalOpen
      },
      {
        icon: <DownloadIcon />,
        label: 'Export Data to Excel',
        onClick: exportExcel
      }
    ]

    if (firmConfiguration.data?.trading?.manualDriverUpload === 'ui') {
      options.push({
        icon: <UploadIcon />,
        label: 'Import Trade Order Updates',
        onClick: openFileUploader
      })
    }

    return options
  })()

  return (
    <>
      <PageTitleBar title='Manage Orders' noBottomMargin />
      <Card fullPageHeight>
        <LoadingContainer
          loading={
            aggregating ||
            disaggregating ||
            uploadingTradeOrderUpdates ||
            allocating
          }
        >
          <TableGrid>
            <TableGridHeader>
              <Flex column>
                <WidgetTitleBar
                  heading={{
                    ViewOptionsProps: {
                      id: PREFIX,
                      configuration: {
                        metrics: {
                          includeRelatedModels: [
                            METRICGROUP_RELATED_MODEL.TRADE_ORDER
                          ]
                        },
                        filters: {
                          types: [FILTERCRITERION_NODE_TYPE.TRADE_ORDER]
                        },
                        showTradesAsUngroupedItems: true
                      }
                    }
                  }}
                  overflowMenuOptions={overflowMenuOptions}
                  secondaryControls={
                    <DateRangeSelector
                      dateRange={settings?.period}
                      onDateRangeChange={updateGlobalSettingsKeys.period}
                      filter={{
                        include: DEFAULT_TRANSACTION_DATE_OPTIONS
                      }}
                    />
                  }
                />
                <Flex alignCenter>
                  <CheckedItemsActions
                    belowWidgetTitleBar
                    disabled={
                      changingMultipleStatuses ||
                      updatingOrderStatus ||
                      aggregating ||
                      disaggregating ||
                      updatingCellValue ||
                      allocating ||
                      aggregatingAll
                    }
                    count={selectedIds.length}
                    actions={checkedItemsActions}
                    buttonTestIdPrefix='button-trade-order-'
                  />
                </Flex>
              </Flex>
            </TableGridHeader>
            <TableGridBody>
              <LoadingContainer
                loading={manageOrdersTable.loading || firmConfiguration.loading}
              >
                {manageOrdersTable.data && (
                  <StandardTable
                    minColumnWidth={100}
                    id={PREFIX}
                    categoriesOverride={{
                      [METRIC_T_OPERATION.slug]: OperationCell,
                      [METRIC_T_QTY_FILLED_PERCENTAGE.slug]: FillCell,
                      [METRIC_T_STATUS.slug]: AllocationStatusCell,
                      [METRIC_T_CURRENCY.slug]: CurrencyCell,
                      [METRIC_T_QTY.slug]: EditableTradeOrderQuantity
                    }}
                    actions={rowActions}
                    defaultColumnWidth={90}
                    singleCellEditor
                    table={table}
                    checkedRows={selectedIds}
                    onCheckboxedRows={setSelectedIds}
                    onCellValueChange={handleCellValueChange}
                    onItemsRendered={fetchRanges}
                    updatingItemIds={
                      updatingCellValue || manageOrdersTable.updatingItemIds
                    }
                    sortedColumn={sortOptions}
                    onSortClick={setSortOptions}
                    externalSetColumnFilterInputStateAtKey={
                      setColumnFilterState
                    }
                    externalToggleColumnFilterActive={toggleShowColumnFilter}
                    nameAdornment={tradeOrderNameAdornment()}
                    externalColumnFilterInputState={
                      showColumnFilter && columnFilterState
                    }
                    applyButtonBasedColumnFilter
                    externalOverwriteColumnFilterInputState={
                      overwriteColumnFilterState
                    }
                  />
                )}
              </LoadingContainer>
            </TableGridBody>
          </TableGrid>
        </LoadingContainer>
      </Card>
      {editingIds.length > 0 &&
        (editingIds.length === 1 &&
        manageOrdersTable.data
          ?.findItemById(editingIds[0])
          ?.getValue(BLOTTER_CATEGORY_IDS.IS_AGGREGATED_PARENT) ? (
          <ReviewAggregatedTradeModal
            tradeOrderId={editingIds[0]}
            onClose={() => setEditingIds([])}
            onSuccess={() => refreshTable()}
          />
        ) : (
          <EditTradeModal
            manageOrdersTable={manageOrdersTable.data}
            onClose={() => setEditingIds([])}
            selectedItemIds={editingIds}
            onUpdate={handleTradeOrderUpdate}
          />
        ))}
      {tradeModalOpen && (
        <TradeModal
          onClose={setTradeModalClosed}
          onSuccess={() => {
            refreshTable()
          }}
        />
      )}
    </>
  )
}

export const ManageOrdersPage = withPermissions(
  UI_PERMISSIONS.TRADE_TRADE_BLOTTER
)(ManageOrdersPageContent)

export default ManageOrdersPage
